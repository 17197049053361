import { Button, Space } from 'antd'
import moment from 'moment'
import { useContext } from 'react'

import { useMutation, useQueryClient } from 'react-query'
import { AppContext } from 'src/context/AppContext'
import { getInventory, getStocksAnalytics } from '../network/API'
import { getProductTypes, mergeStock } from '../network/Products'
import { formatNb } from '../utils/utils'

import { useTranslation } from 'react-i18next'
import DynamicStrings from '../components/DynamicStrings'
import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import { inventoryKeyMap } from 'src/constants/lang'

const Inventory = ({ history }) => {
    return (
        <PageWrap title="MENU_INVENTORY">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getInventory'}
                request={getInventory}
                stats={stats}
                statsRequest={getStocksAnalytics}
                pageSize={80}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push({
                                pathname: `/inventory/${record.product.id}`,
                                previousHash: window.location.hash
                            })
                        },
                    };
                }}
            />
        </PageWrap>
    )
}

const stats = () => [
    {
        stats: [
            {
                label: 'INVENTORY_TOTAL_STOCK_VOLUME',
                getValue: (e) => e?.totalStock || '-',
                key: 'stockVolume',
            },
            {
                label: 'INVENTORY_TOTAL_STOCK_VALUE',
                getValue: (e) =>
                    e?.totalValue ? '¥' + formatNb(e?.totalValue) : '-',
                key: 'stockValue',
            },
        ],
    },
]

const filters = (goTo, selectedKeys, isLoading) => ({
    fields: [
        {
            // type: 'select',
            type: 'multiselect',
            label: 'PRODUCT_LIST_PRODUCT_TYPE',
            // key: 'type',
            default: '',
            key: 'productType',
            options: getProductTypes,
        },
        {
            type: 'text',
            label: 'INVENTORY_PRODUCT',
            key: 'productName',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'PRODUCT_LIST_PRODUCT_ID',
            // key: 'id',
            key: 'productID',
            noRegex: true,
        },
    ],
    actions: [
        {
            label: 'ANALYTICS_EXPORT_CSV',
            isActive: !isLoading,
            csv: {
                header: [
                    'Product ID',
                    'Name (English)',
                    'Name (中文)',
                    'Product type',
                    'Actual stock',
                    'Purchased (not received)',
                    'On delivery',
                    'Forecast stock',
                    'Forecast stock',
                ],
                rowFilter: (order, i) => {
                    let rows = []
                    rows.push(order.product.productId)
                    rows.push(order.product.name.en)
                    rows.push(order.product.name.zh)
                    rows.push(order.product.productType.name)
                    rows.push(order.stock)
                    rows.push(order.purchasedStock)
                    rows.push(order.onDeliveryStock)
                    rows.push(
                        order.stock +
                        order.purchasedStock -
                        order.onDeliveryStock
                    )
                    rows.push(
                        ((order?.stock || 0) +
                            (order?.purchasedStock || 0) -
                            (order?.onDeliveryStock || 0)) *
                        order?.product?.price
                    )

                    return rows
                },
                name: 'Inventory ' + moment().format('YYYY-MM-DD'),
            },
        },
    ],
})

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="PRODUCT_LIST_PRODUCT_ID" />,
        dataIndex: 'product',
        key: 'product.productId',
        render: (product) => product?.productId,
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_NAME" suffix="(English)" />,
        dataIndex: 'product',
        key: 'name.en',
        render: (product) => product?.name?.en,
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_NAME" suffix="(中文)" />,
        dataIndex: 'product',
        key: 'name.zh',
        render: (product) => product?.name?.zh,
    },
    {
        title: <DynamicStrings id="SUPPLIERS_SUPPLIER_NAME" />,
        dataIndex: 'product',
        key: 'product.supplier',
        render: (product) => product?.supplier?.name,
        sorter: false,
    },
    {
        title: <DynamicStrings id="PRODUCT_LIST_PRODUCT_TYPE" />,
        dataIndex: 'product',
        key: 'product.productType.name',
        render: (product) => {
            if (product?.productType?.name === 'Others') {
                product.productType.name = `${product?.productType?.name}(${product?.productType?.category})`
            }
            return <DynamicStrings id={inventoryKeyMap[product?.productType?.name]} />
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="INVENTORY_PAID_STOCK" />,
        dataIndex: 'stock',
        key: 'stock',
        render: (stock) => (stock ? formatNb(stock) : 0),
        sorter: false,
    },
    {
        title: <DynamicStrings id="INVENTORY_FREE_STOCK" />,
        dataIndex: 'freeStock',
        key: 'freeStock',
        render: (freeStock) => (freeStock ? formatNb(freeStock) : 0),
        sorter: false,
    },
    {
        title: <DynamicStrings id="INVENTORY_TOTAL" />,
        render: (e) => formatNb((e.stock || 0) + (e.freeStock || 0)),
        sorter: false,
    },
    {
        title: <DynamicStrings id="purchased" />,
        dataIndex: 'purchasedStock',
        key: 'purchasedStock',
        render: (stock) => (stock ? formatNb(stock) : 0),
        sorter: false,
    },
    {
        title: <DynamicStrings id="on_delivery" />,
        dataIndex: 'onDeliveryStock',
        key: 'onDeliveryStock',
        render: (stock) => (stock ? formatNb(stock) : 0),
        sorter: false,
    },
    {
        title: <DynamicStrings id="INVENTORY_FORECAST_STOCK" />,
        dataIndex: '',
        render: (stock) =>
            formatNb(
                (stock?.stock || 0) +
                (stock?.freeStock || 0) +
                (stock?.purchasedStock || 0) -
                (stock?.onDeliveryStock || 0)
            ) || 0,
        // [Current stock]+[Arriving stock]-[Leaving stock]
        key: 'forcastStock',
        sorter: false,
    },
    {
        title: (
            <DynamicStrings id="INVENTORY_FORECAST_STOCK_VALUE" suffix="(¥)" />
        ),
        key: 'stockValue',
        render: (stock) =>
            formatNb(
                ((stock?.stock || 0) +
                    (stock?.freeStock || 0) +
                    (stock?.purchasedStock || 0) -
                    (stock?.onDeliveryStock || 0)) *
                stock?.product?.price
            ) || 0,
        sorter: false,
    },

    {
        title: '',
        width: 1,
        key: 'action',
        render: (e) => (
            <Space>
                <MergeButton id={e.id} keyRequest={keyRequest} />
                {/* <Button
                    // onClick={() => goTo(`/inventory/${e.product.id}`)}
                    onClick={() =>
                        goTo({
                            pathname: `/inventory/${e.product.id}`,
                            previousHash: window.location.hash,
                        })
                    }
                    type="primary"
                >
                    <DynamicStrings id="INVENTORY_VIEW_DETAILS" />
                </Button> */}
            </Space>
        ),
    },
]

const MergeButton = ({ id, keyRequest }) => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const mutation = useMutation(() => mergeStock(id, token.token), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e.id === data.id
                )

                oldData.list[oldDataIndex].stock = data.stock
                oldData.list[oldDataIndex].freeStock = data.freeStock

                return oldData
            })
        },
    })

    return (
        <Button
            onClick={(event) => {
                event.stopPropagation()
                mutation.mutate()
            }}
            loading={mutation.isLoading}
        >
            {t('INVENTORY_MERGE')}
        </Button>
    )
}

export default Inventory
