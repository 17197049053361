import {
    Layout,
    Typography,
    Space,
    Radio,
    Divider,
    PageHeader,
    Skeleton,
    Badge,
} from 'antd'
import {
    FlagFilled,
    ClockCircleOutlined,
    WifiOutlined,
} from '@ant-design/icons'
import { useTimer } from 'react-timer-hook'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const { Title, Text } = Typography
const { Content } = Layout

const PageWrap = ({
    title,
    subTitle,
    goBack,
    avatar,
    status,
    network,

    actionOptions = [],
    radioValue,
    radioOptions,
    handleRadioChange,

    isLoading,
    children,
    isFlag,
    offer,
    offerStats,
}) => {
    let history = useHistory()
    const { t } = useTranslation()

    const radioBtn = radioOptions ? (
        <Radio.Group
            value={radioValue}
            buttonStyle="solid"
            onChange={(e) =>
                handleRadioChange && handleRadioChange(e.target.value)
            }
        >
            {radioOptions.map((option) => (
                <Radio.Button
                    key={option.key}
                    value={option.key}
                    disabled={option.disabled}
                >
                    {t(option.label)}
                </Radio.Button>
            ))}
        </Radio.Group>
    ) : null

    return (
        <Layout className="content-layout">
            <PageHeader
                className="site-header"
                style={{
                    backgroundColor: '#fff',
                    padding: '0px 24px',
                    minHeight: 72,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                avatar={
                    avatar
                        ? {
                              src: avatar,
                          }
                        : null
                }
                onBack={
                    goBack
                        ? () => {
                              if (goBack) {
                                  if (typeof goBack === 'string') {
                                      history.push(goBack)
                                  } else {
                                      history.goBack()
                                  }
                              }
                          }
                        : null
                }
                title={
                    isLoading ? (
                        <Skeleton.Button style={{ width: 200 }} active={true} />
                    ) : (
                        <>
                            {status && <Badge status={status} />}
                            {network && (
                                <WifiOutlined
                                    style={{
                                        color: network,
                                        fontSize: 16,
                                        marginRight: 10,
                                    }}
                                />
                            )}
                            {t(title)}
                            {offer && (
                                <OfferStats
                                    offer={offer}
                                    offerStats={offerStats}
                                />
                            )}
                        </>
                    )
                }
                subTitle={subTitle}
                extra={[radioBtn, ...actionOptions]}
                tags={
                    isFlag ? (
                        <FlagFilled style={{ color: 'red', fontSize: 19 }} />
                    ) : null
                }
            ></PageHeader>
            {/* </Header> */}

            <Content>{children}</Content>
        </Layout>
    )
}

const OfferStats = ({ offer, offerStats }) => {
    const { t } = useTranslation()

    const endOffer = new Date(offer?.endingDate)

    const { days, seconds, minutes, hours } = useTimer({
        expiryTimestamp: endOffer,
        autoStart: true,
        onExpire: () => {},
    })

    // return <LoadingOutlined />

    return (
        <Space>
            <div>
                <Title
                    style={{
                        margin: 0,
                    }}
                    level={4}
                >
                    {offer?.name[i18next.language]}
                </Title>
                <Text
                    style={{ fontSize: 11, lineHeight: '11px' }}
                    type="secondary"
                    // disabled={true}
                >
                    {offer?.offerID}
                </Text>
            </div>
            {new Date(offer.startingTime) > new Date() && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -5,
                    }}
                >
                    <ClockCircleOutlined
                        onClick
                        style={{ fontSize: 19, marginBottom: -5 }}
                    />
                    <Title
                        style={{
                            marginBottom: -4,
                            marginLeft: 3,
                        }}
                        level={4}
                    >
                        {`${days * 24 + hours}${
                            minutes < 10 ? 'h0' : 'h'
                        }${minutes}${seconds < 10 ? 'm0' : 'm'}${seconds}s`}
                    </Title>
                </div>
            )}
            {offerStats && (
                <>
                    <Divider type="vertical" style={{ height: 29 }} />
                    <Space style={{ fontSize: 14, fontWeight: 400 }} size={14}>
                        <Space direction="vertical" size={0}>
                            <Text>{t('OFFER_LIVE_TARGET_RATE')}</Text>
                            <Text type="secondary">
                                {offerStats.targetRate}%
                            </Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                            {/*<Text>{t('OFFER_LIVE_TOTAL_SALES_DELIVERY')}</Text>*/}
                            <Text>{t('OFFER_ANALYTICS_TOTAL_SALES')}</Text>
                            <Text type="secondary">
                                ¥{offerStats.totalSales}
                            </Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                            <Text>{t('OFFER_LIVE_AVG_SPENDING')}</Text>
                            <Text type="secondary">
                                ¥{offerStats.avgSpending}
                            </Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                            <Text>{t('OFFERS_NB_OF_ORDERS')}</Text>
                            <Text type="secondary">{offerStats.orders}</Text>
                        </Space>
                    </Space>
                </>
            )}
        </Space>
    )
}

export default PageWrap
