import {useEffect, useState} from "react";

/**
 * Syncs a query param with a state value
 * @param name {string} - The name of the query param, should be unique
 * @returns {{}}
 */
export function useQuerySync(name) {
    const [value, setValue] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tmpValue = urlParams.get(name);
        setValue(tmpValue);
    }, [name])

    function onChange(value) {
        setValue(value);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set(name, value);
        window.history.pushState({}, "", `${window.location.pathname}?${urlParams.toString()}`);
    }

    return {
        value,
        onChange,
    }
}
