import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Form } from 'antd'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

import { EditProduct, getSingleProduct, getProductsList } from '../../network/Products'
import {
    Description,
    Media,
    ProductDetails,
    cleanBeforeSending,
} from '../../constants/forms/Products'

const CreateProduct = ({ history }) => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()
    const [products, setProducts] = useState([])

    useEffect(() => {
        getProductsList(
            token.token
        ).then((data) => {
            setProducts(data.list)
        })
    }, [])

    let { productId } = useParams()
    const productDetails = useQuery(
        [
            'getSingleProduct',
            {
                productId: productId,
                token: token.token,
            },
        ],
        getSingleProduct,
        {
            refetchOnWindowFocus: false,
        }
    )

    return (
        <PageWrap
            goBack
            isLoading={productDetails.isLoading}
            title={productDetails.data?.name?.en}
        >
            {productDetails.isError ? (
                <Error retry={() => productDetails.refetch()} />
            ) : productDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    title="create_offer"
                    request={EditProduct}
                    id={productId}
                    form={form}
                    onSuccess="/products"
                    onCancel="/products"
                    cleanBeforeSending={cleanBeforeSending}
                    data={productDetails.data}
                    invalidate={['getSingleProduct', 'getProducts']}
                    checkValidity={(values) => {
                        for (const i in products) {
                            if (productId !== products[i].id) {
                                if (values.status === 'discontinued') {
                                    return false
                                } else if (products[i].name?.en?.toLowerCase() === values.name?.en?.toLowerCase() && products[i].status === 'available') {
                                    return 'Product already exists'
                                }
                            }
                        }

                        if (
                            !values.mainPicture?.en?.uri ||
                            !values.mainPicture?.zh?.uri
                        ) {
                            return 'Please set main pictures'
                        }

                        // if (
                        //     !values.technicalSheet?.en?.uri ||
                        //     !values.technicalSheet?.zh?.uri
                        // ) {
                        //     return 'Please set technical sheets'
                        // }

                        return false
                    }}
                >
                    <div className="SectionHorizontalWrap">
                        <div
                            className="SectionVerticalWrap"
                            style={{ flex: 2 }}
                        >
                            <FormBlock form={form} formTemplate={Description} />
                            <FormBlock form={form} formTemplate={Media} />
                        </div>

                        <FormBlock form={form} formTemplate={ProductDetails} />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default CreateProduct
