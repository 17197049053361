import React, { useState, useContext } from 'react'
import { Space, Typography } from 'antd'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { AppContext } from '../context/AppContext'
import { downloadCSV, formatNb } from '../utils/utils'
import { getAnalytics } from '../network/API'

import PageWrap from '../components/layout/PageWrap'
import TableFilters from '../components/tables/TableFilters'
import Loader from '../components/Loader'
import Error from '../components/Error'
import StatCard from '../components/StatCard'
import ChartPie from '../components/ChartPie'

const { Title } = Typography

const Analytics = () => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const [tabFilter, setTabFilter] = useState({})
    const analytics = useQuery(
        [
            'getAnalytics',
            {
                ...tabFilter,
                token: token.token,
            },
        ],
        getAnalytics
    )

    const title = {
        marginTop: 15,
    }

    const today = new Date()
    const currentMonthStart  = new Date(today.getFullYear(), today.getMonth(), 1);
    const currentMonthEnd  = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const filters = () => ({
        fields: [
            {
                type: 'daterange',
                label: 'ANALYTICS_DATES',
                key: ['dateStart', 'dateEnd'],
                default: [moment(currentMonthStart, 'YYYY-MM-DD'), moment(currentMonthEnd, 'YYYY-MM-DD')]
            },
        ],
        actions: [
            {
                label: 'ANALYTICS_EXPORT_CSV',
                isActive: !analytics.isLoading,
                action: () => {
                    let num =
                        ((analytics.data?.totalOrder -
                            analytics.data?.totalClaim) /
                            analytics.data?.totalOrder) *
                        100
                    const rows = [
                        [
                            'Sales',
                            'Grand total sales',
                            'Total Zikoland service fee',
                            'Total supplier share',
                            'Gross Margin',
                        ],
                        [
                            '',
                            grandTotal(analytics.data?.revenuesByChannels),
                            formatNb(analytics.data?.totalZikolandServiceFee),
                            formatNb(analytics.data?.totalSupplierShare),
                            formatNb(analytics.data?.grossMargin),
                        ],
                        [],
                        ['', 'Sales Channel Ratio'],
                        ...analytics.data?.revenuesByChannels.map((e) => [
                            '',
                            e._id === 'wechat_group'
                                ? 'Groups'
                                : e._id === 'miniprogram'
                                ? 'Mini program'
                                : 'Daily',
                            formatNb(e.totalAmount),
                        ]),
                        [],
                        ['', 'Zikoland Service Fee by Community'],
                        ...analytics.data?.revenuesByCommunities.map((e) => [
                            '',
                            e._id.name,
                            formatNb(e.totalAmount),
                        ]),
                        [],
                        ['Orders', 'Total Offers', 'Total orders'],
                        [
                            '',
                            analytics.data?.totalOffer,
                            analytics.data?.totalOrder,
                        ],
                        [],
                        [
                            'Customer service',
                            'Customer satisfaction rate',
                            'Total claims',
                            'Total vouchers',
                            'Total vouchers (¥)',
                            'Total refund (¥)',
                            'Total refund',
                        ],
                        [
                            '',
                            Math.round((num + Number.EPSILON) * 100) / 100,
                            analytics.data?.totalClaim,
                            analytics.data?.totalVoucher,
                            analytics.data?.totalVoucherAmount,
                            analytics.data?.revenuesByPaymentStatus?.find(
                                (e) => e._id === 'rfd'
                            )?.totalAmount || 0,
                            analytics.data?.revenuesByPaymentStatus?.find(
                                (e) => e._id === 'rfd'
                            )?.count || 0,
                        ],
                        [],
                        ['Stock', 'Total stock', 'Stock value (¥)'],
                        [
                            '',
                            analytics.data?.totalStock,
                            analytics.data?.totalStockValue,
                        ],
                    ]

                    downloadCSV(
                        null,
                        rows,
                        `Analytics ${
                            tabFilter.dateStart && tabFilter.dateEnd
                                ? 'From ' +
                                  moment(tabFilter.dateStart).format(
                                      'YYYY-MM-DD'
                                  ) +
                                  ' To ' +
                                  moment(tabFilter.dateEnd).format('YYYY-MM-DD')
                                : 'Till ' + moment().format('YYYY-MM-DD')
                        }`
                    )
                },
            },
        ],
    })

    const grandTotal = (channels) => {
        let total = 0

        channels?.map((e) => (total += e.totalAmount))

        return formatNb(total)
    }

    return (
        <PageWrap title="ANALYTICS_GENERAL_ANALYTICS">
            <TableFilters
                config={filters()}
                // onFiltersChange={(filters) => setFilter(filters)}
                onTabChange={(e) => setTabFilter(e)}
            />
            {analytics.isError ? (
                <Error retry={() => analytics.refetch()} />
            ) : (
                <div style={{ backgroundColor: '#f0f2f5', paddingBottom: 24 }}>
                    {analytics.isLoading ? (
                        <Loader />
                    ) : (
                        <Space
                            direction="vertical"
                            style={{ padding: '24px 0px 0px 24px' }}
                        >
                            <Title level={4}>{t('ANALYTICS_SALES')}</Title>
                            <Space>
                                <ChartPie
                                    title={t('ANALYTICS_SALES_CHANNEL_RATIO')}
                                    data={analytics.data?.revenuesByChannels.sort(
                                        (a, b) => (a._id < b._id ? 1 : -1)
                                    )}
                                />

                                <ChartPie
                                    title={t(
                                        'ANALYTICS_ZIKOLAND_SERVICE_FEE_COMMUNITY'
                                    )}
                                    data={analytics.data?.revenuesByCommunities
                                        .map((line) => ({
                                            _id: line._id.name,
                                            totalAmount: line.totalAmount,
                                        }))
                                        .sort((a, b) =>
                                            a._id < b._id ? 1 : -1
                                        )}
                                />
                                <Space direction="vertical">
                                    <StatCard
                                        prefix="¥"
                                        title={t('ANALYTICS_GRAND_TOTAL_SALES')}
                                        value={grandTotal(
                                            analytics.data?.revenuesByChannels
                                        )}
                                    />
                                    <StatCard
                                        prefix="¥"
                                        title={t(
                                            'ANALYTICS_TOTAL_ZIKOLAND_SERVICE_FEE'
                                        )}
                                        value={formatNb(
                                            analytics.data
                                                ?.totalZikolandServiceFee
                                        )}
                                    />
                                    <StatCard
                                        prefix="¥"
                                        title={t(
                                            'ANALYTICS_TOTAL_SUPPLIER_SHARE'
                                        )}
                                        value={formatNb(
                                            analytics.data?.totalSupplierShare
                                        )}
                                    />
                                </Space>
                            </Space>
                            <StatCard
                                prefix="¥"
                                title={t('ANALYTICS_GROSS_MARGIN')}
                                value={formatNb(analytics.data?.grossMargin)}
                            />

                            <Title style={title} level={4}>
                                {t('MENU_ORDERS')}
                            </Title>
                            <Space>
                                <StatCard
                                    title={t('ANALYTICS_TOTAL_OFFERS')}
                                    value={formatNb(analytics.data?.totalOffer)}
                                />
                                <StatCard
                                    title={t('ANALYTICS_TOTAL_ORDERS')}
                                    value={formatNb(analytics.data?.totalOrder)}
                                />
                            </Space>
                            <Title style={title} level={4}>
                                {t('MENU_CUSTOMER_SERVICE')}
                            </Title>
                            <Space>
                                <Space direction="vertical">
                                    <Space>
                                        <StatCard
                                            title={t(
                                                'ANALYTICS_CUSTOMER_SATISFACTION_RATE'
                                            )}
                                            suffix="%"
                                            precision={2}
                                            value={formatNb(
                                                ((analytics.data?.totalOrder -
                                                    analytics.data
                                                        ?.totalClaim) /
                                                    analytics.data
                                                        ?.totalOrder) *
                                                    100
                                            )}
                                        />
                                        <StatCard
                                            title={t('ANALYTICS_TOTAL_CLAIMS')}
                                            value={formatNb(
                                                analytics.data?.totalClaim
                                            )}
                                        />
                                        <StatCard
                                            title={t(
                                                'ANALYTICS_TOTAL_VOUCHERS'
                                            )}
                                            value={formatNb(
                                                analytics.data?.totalVoucher
                                            )}
                                        />
                                        <StatCard
                                            title={
                                                t('ANALYTICS_TOTAL_VOUCHERS') +
                                                ' (¥)'
                                            }
                                            value={formatNb(
                                                analytics.data
                                                    ?.totalVoucherAmount
                                            )}
                                        />
                                    </Space>
                                    <Space>
                                        <StatCard
                                            title={
                                                t('ANALYTICS_TOTAL_REFUND') +
                                                ' (¥)'
                                            }
                                            value={formatNb(
                                                analytics.data?.revenuesByPaymentStatus?.find(
                                                    (e) => e._id === 'rfd'
                                                )?.totalAmount
                                            )}
                                        />
                                        <StatCard
                                            title={t('ANALYTICS_TOTAL_REFUND')}
                                            value={formatNb(
                                                analytics.data?.revenuesByPaymentStatus?.find(
                                                    (e) => e._id === 'rfd'
                                                )?.count
                                            )}
                                        />
                                    </Space>
                                </Space>
                            </Space>

                            <Title style={title} level={4}>
                                {t('ANALYTICS_STOCK')}
                            </Title>
                            <Space>
                                <StatCard
                                    title={t('ANALYTICS_TOTAL_STOCK')}
                                    value={formatNb(analytics.data?.totalStock)}
                                />
                                <StatCard
                                    title={t('ANALYTICS_STOCK_VALUE') + ' (¥)'}
                                    value={formatNb(
                                        analytics.data?.totalStockValue
                                    )}
                                />
                            </Space>
                        </Space>
                    )}
                </div>
            )}
        </PageWrap>
    )
}

export default Analytics
