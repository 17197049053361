import { Fragment } from 'react'
import { Form, Button, Space, Typography, Divider } from 'antd'
import { useTranslation } from 'react-i18next'

import FormField from './FormField'
import { numberToLetter } from '../../utils/utils'

const { Title } = Typography

const getArrayLenghtOf = (length) => {
    var data = []

    for (var i = 0; i < length; i++) {
        data.push(null)
    }
    return data
}

const FormSection = ({
    form,
    section,
    prev = [],
    sectionKey,
    dataRecieved,
    blockKey = [],
    channel = [],
}) => {
    const { t } = useTranslation()

    if (section.canAdd) {
        let suffixStart = 0
        if (section.contextualSuffix) {
            const selection = form.getFieldValue([
                ...channel,
                ...section.contextualSuffix,
            ])

            if (selection?.length) suffixStart = selection.length
        }

        return (
            <Form.List
                name={
                    sectionKey !== undefined
                        ? [...blockKey, sectionKey, ...section.key]
                        : [...blockKey, ...section.key]
                }
                initialValue={section.defaultOpen ? [null] : null}
            >
                {(fields, { add, remove }) => (
                    <>
                        {section.mainTitle && (
                            <Title level={5} style={{ marginBottom: 15 }}>
                                {t(section.mainTitle)}
                            </Title>
                        )}
                        {fields.map(
                            ({ key, name, fieldKey, ...restField }, i) => (
                                <Fragment key={key}>
                                    <Space
                                        className="title-row"
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: section.noMargin ? '0' : '24px',
                                        }}
                                        align="baseline"
                                    >
                                        {section.title && (
                                            <Title level={5}>
                                                {t(section.title)}
                                                {section.addSuffix &&
                                                    (section.addSuffix ===
                                                    'letter'
                                                        ? ` ${numberToLetter(
                                                              suffixStart + i
                                                          )}`
                                                        : ` ${
                                                              suffixStart +
                                                              i +
                                                              1
                                                          }`)}
                                            </Title>
                                        )}
                                        {section.deleteBtn.style ===
                                            'title' && (
                                            <Button
                                                onClick={() => remove(name)}
                                                type="link"
                                                disabled={
                                                    section.deleteBtn.isInactive
                                                }
                                            >
                                                {t(section.deleteBtn.label)}
                                            </Button>
                                        )}
                                    </Space>
                                    <Space direction="vertical">
                                        <div
                                            className={`form-field-wrapper nb-columns-${section.columns}`}
                                        >
                                            {section.fields.map((field, i) => (
                                                <div
                                                    key={i}
                                                    className={`col-${field.pos?.col} row-${field.pos?.row} size-${field.pos?.size} ${field.className}`}
                                                >
                                                    <FormField
                                                        {...restField}
                                                        key={field.key + i}
                                                        name={name}
                                                        fieldKey={fieldKey}
                                                        field={field}
                                                        sectionKey={section.key}
                                                        blockKey={[]}
                                                        channel={channel}
                                                        form={form}
                                                        prev={[
                                                            ...prev,
                                                            ...section.key,
                                                            name,
                                                        ]}
                                                    />
                                                </div>
                                            ))}
                                            {section.deleteBtn.style ===
                                                'line' && (
                                                <div
                                                    className={`form-delete-grib-button col-${section.deleteBtn.pos?.col} row-${section.deleteBtn.pos?.row} size-${section.deleteBtn.pos?.size}`}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            remove(name)
                                                        }
                                                        type="link"
                                                        disabled={
                                                            section.deleteBtn
                                                                .isInactive
                                                        }
                                                    >
                                                        {t(
                                                            section.deleteBtn
                                                                .label
                                                        )}
                                                    </Button>
                                                </div>
                                                // <a
                                                //     className={`form-delete-grib-button col-${section.deleteBtn.pos?.col} row-${section.deleteBtn.pos?.row} size-${section.deleteBtn.pos?.size}`}
                                                //     onClick={() => remove(name)}
                                                // >
                                                //     {t(section.deleteBtn.label)}
                                                // </a>
                                            )}
                                        </div>
                                    </Space>
                                    <>
                                        {section.sections?.map(
                                            (sect, index) => (
                                                <FormSection
                                                    key={`section${index}`}
                                                    section={sect}
                                                    sectionKey={[name]}
                                                    blockKey={[]}
                                                    channel={channel}
                                                    form={form}
                                                    prev={[
                                                        ...prev,
                                                        ...section.key,
                                                        name,
                                                    ]}
                                                />
                                            )
                                        )}
                                    </>
                                    {!section.noDivider && <Divider />}
                                </Fragment>
                            )
                        )}
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                JSON.stringify(prevValues) !==
                                JSON.stringify(currentValues)
                            }
                        >
                            {({ getFieldValue }) => {
                                let showBtn = true

                                if (section.addOnCondition) {
                                    let parentValue = getFieldValue(
                                        section.staticParentKey
                                    )

                                    showBtn =
                                        section.addOnCondition(parentValue)
                                }

                                return showBtn ? (
                                    <Button
                                        type={section.addBtn.style}
                                        onClick={() => add()}
                                        disabled={section.addBtn.isInactive}
                                        style={
                                            section.addBtn.style === 'dashed'
                                                ? {
                                                      borderColor: '#1890ff',
                                                      color: '#1890ff',
                                                  }
                                                : {}
                                        }
                                    >
                                        {t(section.addBtn.label)}
                                    </Button>
                                ) : null
                            }}
                        </Form.Item>
                    </>
                )}
            </Form.List>
        )
    }
    return (
        <>
            {section.title && <Title level={5}>{t(section.title)}</Title>}
            {section.isList ? (
                <Form.List
                    className="form-field-list-wrapper"
                    name={
                        sectionKey !== undefined
                            ? [...blockKey, sectionKey, ...section.key]
                            : [...blockKey, ...section.key]
                    }
                    initialValue={getArrayLenghtOf(section.length)}
                >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(
                                ({ key, name, fieldKey, ...restField }) => (
                                    <Fragment key={key}>
                                        <div
                                            className={`form-field-wrapper nb-columns-${section.columns}`}
                                        >
                                            {section.fields.map((field, i) => {
                                                return (
                                                    <div
                                                        key={field.key}
                                                        className={`col-${field.pos?.col} row-${field.pos?.row} size-${field.pos?.size} ${field.className}`}
                                                    >
                                                        <FormField
                                                            {...restField}
                                                            field={field}
                                                            labelNb={
                                                                section.addLabelNb
                                                                    ? ' #' +
                                                                      (key + 1)
                                                                    : null
                                                            }
                                                            dataRecieved={
                                                                dataRecieved
                                                            }
                                                            sectionKey={[name]}
                                                            blockKey={blockKey}
                                                            channel={channel}
                                                            form={form}
                                                            name={name}
                                                            fieldKey={fieldKey}
                                                            prev={prev}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {section.sections?.map(
                                            (sect, index) => (
                                                <FormSection
                                                    key={`section${index}`}
                                                    section={sect}
                                                    sectionKey={[name]}
                                                    blockKey={[]}
                                                    channel={channel}
                                                    form={form}
                                                />
                                            )
                                        )}
                                    </Fragment>
                                )
                            )}
                        </>
                    )}
                </Form.List>
            ) : (
                <>
                    <div
                        className={`form-field-wrapper nb-columns-${section.columns}`}
                    >
                        {section.fields.map((field, i) => (
                            <div
                                key={field.key}
                                className={`col-${field.pos?.col} row-${field.pos?.row} size-${field.pos?.size} ${field.className}`}
                            >
                                <FormField
                                    field={field}
                                    dataRecieved={dataRecieved}
                                    blockKey={blockKey}
                                    channel={channel}
                                    form={form}
                                    prev={prev}
                                />
                            </div>
                        ))}
                    </div>
                    {!section.noDivider && <Divider />}
                </>
            )}
            <>
                {section.sections?.map((section, index) => (
                    <FormSection key={index} section={section} form={form} />
                ))}
            </>
        </>
    )
}

export default FormSection
