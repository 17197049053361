import { INSTANCE, INSTANCELIST } from './API'

export const getPurchaseOrders = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/purchase-orders/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getDirectSalesList = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter, ...rest } = queryKey[1]
        filter.type = 'purchase_order'

        INSTANCELIST({
            method: 'get',
            url: `/purchase-orders/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
                ...rest,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getSinglePurchaseOrder = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { poId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/purchase-orders/${poId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

// export const getSinglePurchaseOrderDetail = ({ queryKey }) =>
//     new Promise((resolve, reject) => {
//         const { poId, token } = queryKey[1]
//
//         INSTANCE({
//             method: 'get',
//             url: `/purchase-orders/${poId}/details`,
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//             params: {},
//         })
//             .then(resolve)
//             .catch(reject)
//     })

export const getSinglePurchaseOrderDetailSort = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { poId, sort, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/purchase-orders/${poId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreatePurchaseOrder = (form, token) =>
    new Promise((resolve, reject) => {
        form.payment_status = 'unpaid'
        form.stock_status = 'pending'

        INSTANCE({
            method: 'post',
            url: '/purchase-orders',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const EditPurchaseOrderDetail = (form, token, poId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/purchase-orders/${poId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })
