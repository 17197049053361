/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useContext,
    useState,
    useReducer,
    useRef,
    useEffect,
} from 'react'
import {
    Input,
    Dropdown,
    InputNumber,
    Typography,
    Avatar,
    Select,
    Checkbox,
    Menu,
    AutoComplete,
    Badge,
    message,
    Tooltip,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useQuery } from 'react-query'

import Logo from '../../images/Ellipse.svg'

import { AppContext } from '../../context/AppContext'
import { WSOrders } from '../../context/WSOrders'
import { OfferLiveWX } from '../../context/OfferLiveWX'
import { SERVER } from '../../network/API'
import { CreateOrder, getDeliveryAreas } from '../../network/API'

import AutoCompleteName from './AutoCompleteName'
import AreaLoader from './AreaLoader'

const { Text } = Typography
const { Option } = Select

// const filterVouchers = (vouchers, community, order) => {
//     if (!vouchers) return vouchers
//     const newVouchers = []

//     for (const voucher of vouchers) {
//         if (
//             voucher.amount <= order.totalAmount &&
//             moment(voucher.expirationDate).diff() &&
//             voucher.communities?.find((e) => e === community) &&
//             voucher.status === 'validated'
//         ) {
//             newVouchers.push(voucher)
//         }
//     }
//     return newVouchers
// }

function orderReducer(state, action) {
    switch (action.type) {
        case 'update':
            return action.payload
        default:
            break
    }
}

function deliveryReducer(state, action) {
    switch (action.type) {
        case 'update':
            if (action.payload !== state) {
                return action.payload
            }
            break
        default:
            break
    }
}

const defaultOrder = {
    customerDaily: {
        customerName: '',
    },
    customerAddress: {
        type: 'temporary',
        needSave: false,
    },
    paymentStatus: 'pending',
    packs: [],
    singleItems: [],
    type: 'normal',
    channel: 'wechat_group',
    orderNumber: null,
    deliveryFee: null,
    customer: null,
    groupNumber: null,
    comment: null,
    deliveryDate: null,
    vouchers: [],
}

const OrderLine = ({
    deliveryDates,
    packs,
    items,
    offerId,
    feeAreas,
    supplierId,
    community,
    handleNewOrder,
    areaOptions,
    availableAreas,
    allAreas,
    orders,
}) => {
    const { token } = useContext(AppContext)
    const { wsOffer } = useContext(WSOrders)

    const [deliveryD, setDeliveryD] = useReducer(deliveryReducer, deliveryDates)
    // const communityMemoized = React.memo(() => community, [])
    // const packsMemoized = React.memo(() => packs, [])
    // const itemsMemoized = React.memo(() => items, [])
    const [communityMemoized, setCommunityMemoized] = useState(community)
    const [packsMemoized, setPacksMemoized] = useState(packs)
    const [itemsMemoized, setItemsMemoized] = useState(items)
    const [feesMemoized, setFeesMemoized] = useState(feeAreas)
    const [ordersMemoized, setOrdersMemoized] = useState(orders)
    const [isCreating, setIsCreating] = useState(false)
    const nameInput = useRef(null)

    // useEffect(() => {
    //     console.log('useEffect', orders)
    //     setOrdersMemoized(orders)
    // }, [orders])

    useEffect(() => {
        try {
            wsOffer.addEventListener('message', (message) => {
                let data = JSON.parse(message.data)
                // console.log('WebSocket - Message recieved line', data)

                switch (data?.type) {
                    case 'NEW_ORDER':
                    case 'UPDATE_ORDER':
                        const orderIndex = ordersMemoized.findIndex(
                            (e) => e.id === data.order.id
                        )

                        if (orderIndex === -1) {
                            setOrdersMemoized([data.order, ...ordersMemoized])
                        } else {
                            const newOrders = [...ordersMemoized]
                            newOrders[orderIndex] = data.order
                            setOrdersMemoized(newOrders)
                        }
                        break
                    default:
                        break
                }
            })
        } catch (error) {
            return error
        }
    }, [])

    const [order, updateLine] = useReducer(orderReducer, {
        ...defaultOrder,
        offer: offerId,
        customerAddress: {
            ...defaultOrder.customerAddress,
            // area: feesMemoized[0],
        },
        // supplier: supplierId,
    })

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (isCreating) return
            if (!order.customer?.id && !order.customerDaily?.customerName) {
                message.error('Need a customer')
                return
            }
            if (!order.packs.length && !order.singleItems.length) {
                message.error('Need at least a pack or an add-on')
                return
            }
            // if (!order.customerAddress.area) {
            //     message.error('Need to select area')
            //     return
            // }
            const newCusto = order.customer?.id ? order.customer?.id : null
            setIsCreating(true)
            CreateOrder(
                {
                    ...order,
                    customer: newCusto,
                    orderDate: new Date(),
                },
                token.token
            )
                .then((newOrder) => {
                    handleNewOrder({
                        ...order,
                        id: newOrder.id,
                        // customer: newOrder.customer ? newOrder.customer : null,
                        orderID: newOrder.orderID,
                        vouchers: newOrder.vouchers,
                        totalAmount: newOrder.totalAmount,
                        actualAmount: newOrder.actualAmount,
                        ticketAmount: newOrder.ticketAmount,
                        deliveryFee: newOrder.deliveryFee,
                        groupNumber: newOrder.groupNumber,
                        orderNumber: newOrder.orderNumber,
                        type: newOrder.type,
                    })
                    updateLine({
                        type: 'update',
                        payload: {
                            ...defaultOrder,
                            offer: offerId,
                            customerAddress: {
                                ...defaultOrder.customerAddress,
                                // area: feesMemoized[0],
                            },
                        },
                    })
                    setIsCreating(false)
                    if (nameInput) {
                        nameInput.current.focus()
                    }
                })
                .catch((error) => {
                    message.error(
                        error?.response?.data?.message || 'Error creating order'
                    )
                    setIsCreating(false)
                    if (nameInput) {
                        nameInput.current.focus()
                    }
                })
        }
    }

    // const [ setAvailableAreas] = useState([])

    // const areas = useQuery(
    //     ['getDeliveryAreas', { token: token.token }],
    //     getDeliveryAreas,
    //     {
    //         // staleTime: Infinity,
    //         cacheTime: Infinity,
    //         refetchIntervalInBackground: false,
    //         // refetchOnMount: false,
    //         refetchOnWindowFocus: false,
    //         refetchOnReconnect: false,
    //         onSuccess: (data) => {
    //             let areaList = [...data.list]
    //             let feeAreasCpy = [...feeAreas]

    //             // if (feeAreas && feeAreas.length) {
    //             //     let topParents = areaList.filter((area) => !area.parent)

    //             //     while (feeAreasCpy.length) {
    //             //         topParents.map((area) => {
    //             //             const inFeeId = feeAreasCpy.findIndex(
    //             //                 (e) => e === area.id
    //             //             )
    //             //             const inAreaId = areaList.findIndex(
    //             //                 (e) => e.id === area.id
    //             //             )

    //             //             if (inFeeId === -1) {
    //             //                 areaList = areaList.map((e) =>
    //             //                     area.id === e.parent
    //             //                         ? { ...e, parent: undefined }
    //             //                         : e
    //             //                 )

    //             //                 areaList.splice(inAreaId, 1)
    //             //             } else {
    //             //                 feeAreasCpy.splice(inFeeId, 1)
    //             //             }
    //             //         })

    //             //         topParents = areaList.filter((area) => !area.parent)
    //             //     }
    //             // }

    //             setAvailableAreas(areaList)
    //         },
    //     }
    // )

    const onChangeInput = (order) => {
        updateLine({
            type: 'update',
            payload: order,
        })
    }

    const columnsPart1 = [
        {
            width: 24.5,
            textWrap: 'word-break',
            key: '01',
            render: () => (isCreating ? <LoadingOutlined /> : null),
        },
        {
            width: 30,
            textWrap: 'word-break',
            key: '02',
            title: 'N',
            render: (order, t, i) => (
                <div className="cell-wrap">
                    <Text>0</Text>
                </div>
            ),
        },
        {
            width: 50,
            textWrap: 'word-break',
            key: '03',
            title: 'Order ID',
            render: (order) => null,
        },
        {
            width: 35,
            textWrap: 'word-break',
            key: '04',
            render: (order) => (
                <Badge size="large" dot={order?.customer?.giftNextOffer}>
                    <Avatar
                        size="small"
                        src={
                            order?.customer?.profilePicture?.uri
                                ? `${SERVER.baseURL}/storage/customer-picture/${order?.customer?.profilePicture?.uri}`
                                : Logo
                        }
                    />
                </Badge>
            ),
        },
        {
            width: 150,
            textWrap: 'word-break',
            key: 'name',
            title: 'Name',
            render: (order) => {
                const userList = order?.customer?.id
                    ? ordersMemoized?.filter((e) => {
                          return (
                              e?.customer?.id === order?.customer?.id &&
                              e?.paymentStatus === 'pending'
                          )
                      })
                    : []

                return (
                    <>
                        {!!userList?.length}
                        <Tooltip
                            visible={!!userList?.length && !isCreating}
                            title={
                                <span>{`This user already have the order ${userList
                                    ?.filter((e, i) => i < 3)
                                    .map((e) => `No${e?.orderNumber}`)
                                    .join(', ')}${
                                    userList?.length > 3 ? '...' : ''
                                }`}</span>
                            }
                        >
                            <AutoCompleteName
                                id={'new'}
                                canAutoUpdate={!order?.customer?.id}
                                type="text"
                                size="small"
                                myRef={nameInput}
                                // onKeyDown={handleKeyDown}
                                community={communityMemoized}
                                disabled={isCreating}
                                value={
                                    order?.customer?.id
                                        ? order.customer.name
                                        : order.customerDaily?.customerName
                                }
                                token={token.token}
                                availableAreas={availableAreas}
                                onSelect={(r) => {
                                    const e = JSON.parse(r)

                                    const availAreas = e?.addresses?.filter(
                                        (e) =>
                                            !!availableAreas?.find((a) => {
                                                return a.id === e?.area
                                            })
                                    )

                                    const customerAddress = availAreas.length
                                        ? {
                                              area: availAreas[0]?.area,
                                              city: availAreas[0]?.city,
                                              detailedAddress:
                                                  availAreas[0]
                                                      ?.detailedAddress,
                                              needSave: false,
                                            //   phone: order?.detailedAddress
                                            //       ?.phone,
                                              phone: availAreas[0]?.phone,
                                              contact: availAreas[0]?.contact,
                                              type: availAreas[0]?.type,
                                              zipCode: availAreas[0]?.zipCode,
                                          }
                                        : {
                                            //   area: feesMemoized[0],
                                              needSave: false,
                                              type: 'temporary',
                                          }

                                    let orderModif = {
                                        customerAddress,
                                        // customerAddress: {
                                        //     area: feesMemoized[0],
                                        //     needSave: false,
                                        //     type: 'temporary',
                                        // },
                                        vouchers: [],
                                        groupNumber: e.wechatGroup,
                                        customerDaily: null,
                                        customer: {
                                            id: e.id,
                                            name: e.name,
                                            profilePicture: {
                                                uri: e.profilePicture,
                                            },
                                            addresses: e.addresses,
                                            contacts: e.contacts,
                                            fapiaoEmails: e.fapiaoEmails,
                                        },
                                    }

                                    onChangeInput({
                                        ...order,
                                        ...orderModif,
                                    })
                                }}
                                onSearch={(e) => {
                                    let orderModif = {}
                                    if (order.customer) {
                                        orderModif.customerAddress = {
                                            // area: feesMemoized[0],
                                            type: 'temporary',
                                        }
                                        orderModif.vouchers = []
                                        orderModif.customer = null
                                        orderModif.groupNumber = ''
                                    }

                                    orderModif.customerDaily = {
                                        ...order.customerDaily,
                                        customerName: e,
                                    }
                                    onChangeInput({ ...order, ...orderModif })
                                }}
                            />
                        </Tooltip>
                    </>
                )
            },
        },
        {
            width: 37,
            textWrap: 'word-break',
            key: 'grp',
            title: 'Grp',
            render: (order) => (
                <InputNumber
                    type="text"
                    size="small"
                    disabled={isCreating}
                    min={1}
                    value={order.groupNumber}
                    onChange={(e) =>
                        onChangeInput({ ...order, groupNumber: e })
                    }
                    onKeyDown={handleKeyDown}
                />
            ),
        },
    ]
    const columnsPart2 = [
        {
            width: 107,
            textWrap: 'word-break',
            key: '07',
            title: 'Phone',
            // render: (order) => (
            //     <Input
            //         type="text"
            //         size="small"
            //         value={order.customerAddress?.phone}
            //         disabled={
            //             order.customerAddress.type !== 'temporary' || isCreating
            //         }
            //         onChange={(e) => {
            //             const modifAddress = {
            //                 ...order.customerAddress,
            //                 phone: e.target.value,
            //             }
            //             onChangeInput({
            //                 ...order,
            //                 customerAddress: modifAddress,
            //             })
            //         }}
            //         onKeyDown={handleKeyDown}
            //     />
            // ),

            //


            render: (order) => {
                const menu = (
                    <Menu>
                        {order?.customer?.contacts?.map((contact) => (
                            <Menu.Item
                                onClick={() =>
                                    onChangeInput({
                                        ...order,
                                        customerAddress: {
                                            ...order.customerAddress,
                                            phone: contact.phone,
                                        },
                                    })
                                }
                            >
                                {`${contact.phone} (${contact.name})`}
                            </Menu.Item>
                        ))}
                    </Menu>
                )

                return (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Input
                            type="text"
                            size="small"
                            onKeyDown={handleKeyDown}
                            // className={`${
                            //     order?.customerAddress?.phone ? '' : ' is-low'
                            // }`}
                            value={order?.customerAddress?.phone}
                            // bordered={false}
                            onChange={(e) =>
                                onChangeInput({
                                    ...order,
                                    customerAddress: {
                                        ...order.customerAddress,
                                        phone: e.target.value,
                                    },
                                })
                            }
                        />
                    </Dropdown>
                )
            },
            
        },
        {
            width: 134,
            textWrap: 'word-break',
            key: 'address',
            title: 'Delivery address',
            render: (order) => (
                <Dropdown
                    overlay={
                        <Menu>
                            {order?.customer?.addresses?.map((address, i) => (
                                <Menu.Item
                                    key={i}
                                    disabled={
                                        availableAreas?.find(
                                            (a) =>
                                                a.id === address?.area ||
                                                address?.area?.id
                                        )
                                            ? false
                                            : true
                                    }
                                    onClick={() => {
                                        const newAdd = {
                                            city: address?.city,
                                            detailedAddress:
                                                address?.detailedAddress,
                                            // phone: order?.detailedAddress
                                            //     ?.phone,
                                            phone: address?.phone,
                                            contact: address?.contact,
                                            type: address?.type,
                                            zipCode: address?.zipCode,
                                            needSave: false,
                                            area:
                                                address?.area?.id ||
                                                address?.area ||
                                                feeAreas[0],
                                        }

                                        onChangeInput({
                                            ...order,
                                            customerAddress: newAdd,
                                        })
                                    }}
                                >
                                    {`${address.type} (${
                                        allAreas?.find(
                                            (a) =>
                                                a.id === address?.area ||
                                                address?.area?.id
                                        ).name
                                    })`}
                                </Menu.Item>
                            ))}
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Input
                        type="text"
                        size="small"
                        value={order.customerAddress.detailedAddress}
                        disabled={isCreating}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                            console.log(e.target.value)
                            const newOrder = { ...order }
                            if (newOrder.customerAddress.type === 'temporary') {
                                newOrder.customerAddress.detailedAddress =
                                    e.target.value
                            } else {
                                newOrder.customerAddress = {
                                    detailedAddress: e.target.value,
                                    city: '',
                                    zipCode: '',
                                    phone: newOrder.detailedAddress?.phone,
                                    type: 'temporary',
                                    // area: feesMemoized[0],
                                }
                            }
                            onChangeInput(newOrder)
                        }}
                    />
                </Dropdown>
            ),
        },
        {
            width: 95,
            textWrap: 'word-break',
            key: '09',
            title: 'City',
            render: (order) => (
                <Input
                    type="text"
                    size="small"
                    onKeyDown={handleKeyDown}
                    value={order.customerAddress?.city}
                    disabled={
                        order.customerAddress.type !== 'temporary' || isCreating
                    }
                    onChange={(e) => {
                        const modifAddress = {
                            ...order.customerAddress,
                            city: e.target.value,
                        }
                        onChangeInput({
                            ...order,
                            customerAddress: modifAddress,
                        })
                    }}
                />
            ),
        },
        {
            width: 90,
            textWrap: 'word-break',
            key: 'area',
            title: 'OFFER_LIVE_AREA',
            render: (order) => (
                <AreaLoader
                    feeAreas={feesMemoized}
                    disabled={
                        order.customerAddress.type !== 'temporary' || isCreating
                    }
                    areaOptions={areaOptions}
                    availableAreas={availableAreas}
                    bordered={true}
                    onChange={(e) => {
                        const modifAddress = {
                            ...order.customerAddress,
                            area: e,
                        }
                        onChangeInput({
                            ...order,
                            customerAddress: modifAddress,
                        })
                    }}
                    value={order.customerAddress?.area}
                />
            ),
        },
        {
            width: 134,
            textWrap: 'word-break',
            key: 'comment',
            title: 'Comment',
            render: (order) => (
                <Input
                    type="text"
                    size="small"
                    value={order.comment}
                    disabled={isCreating}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        onChangeInput({ ...order, comment: e.target.value })
                    }}
                />
            ),
        },
        {
            width: 55,
            textWrap: 'word-break',
            key: '11',
            title: 'Delivery date',
            render: (order) => (
                <AutoComplete
                    type="text"
                    size="small"
                    onKeyDown={handleKeyDown}
                    value={
                        order.deliveryDate &&
                        moment(order.deliveryDate).isValid()
                            ? moment(order.deliveryDate).format('ddd MM.DD')
                            : order.deliveryDate
                    }
                    disabled={isCreating}
                    token={token.token}
                    onSelect={(e) => {
                        onChangeInput({ ...order, deliveryDate: e })
                    }}
                    onSearch={(e) => {
                        onChangeInput({ ...order, deliveryDate: e })
                    }}
                >
                    {deliveryD?.map((date) => (
                        <Option key={date} value={date}>
                            {date && moment(date).isValid()
                                ? moment(date).format('ddd MM.DD')
                                : date}
                        </Option>
                    ))}
                </AutoComplete>
            ),
        },
        {
            width: 55,
            textWrap: 'word-break',
            key: '12',
            title: 'Delivery fee',
            render: (order) => (
                <InputNumber
                    min={0}
                    type="text"
                    size="small"
                    disabled={isCreating}
                    value={order.deliveryFee}
                    onKeyDown={handleKeyDown}
                    onChange={(e) =>
                        onChangeInput({ ...order, deliveryFee: e })
                    }
                />
            ),
        },
        // {
        //     width: 35,
        //     textWrap: 'word-break',
        //     key: 'fapiao',
        //     title: 'Fapiao',
        //     render: (order) => (
        //         <div className={'cell-wrap'}>
        //             <Checkbox
        //                 disabled={isCreating}
        //                 onChange={(e) => {
        //                     onChangeInput({
        //                         ...order,
        //                         fapiao: e.target.checked,
        //                     })
        //                 }}
        //                 checked={order.fapiao}
        //             />
        //         </div>
        //     ),
        // },
        {
            width: 62,
            textWrap: 'word-break',
            key: 'voucher',
            title: 'Voucher',
            render: (order) => null,
        },
        {
            width: 29,
            textWrap: 'word-break',
            key: '15',
            title: 'TK.',
            render: (order) => null,
        },
        {
            width: 58,
            textWrap: 'word-break',
            title: 'Total (¥)',
            key: '16',
            className: (order) =>
                order.paymentMethod === 'alipay' ? ' is-alipay' : '',
            render: (order) => null,
        },
        {
            width: 66,
            textWrap: 'word-break',
            key: '17',
            title: 'Payments Status',
            render: (order) => null,
        },
        {
            width: 36,
            textWrap: 'word-break',
            title: 'QR',
            key: '18',
            render: (order) => null,
        },
        {
            width: 90,
            textWrap: 'word-break',
            key: 'fapiaoEmail',
            title: 'Fapiao',
            render: (order) => {
                const menu = (
                    <Menu>
                        {order?.customer?.fapiaoEmails?.map((email) => (
                            <Menu.Item
                                onClick={() =>
                                    onChangeInput({
                                        ...order,
                                        fapiao: true,
                                        fapiaoEmail: email.email
                                    })
                                }
                            >
                                {`${email.email}`}
                            </Menu.Item>
                        ))}
                    </Menu>
                )

                return (
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                    >
                        <Input
                            type="text"
                            size="small"
                            value={order?.fapiaoEmail}
                            disabled={isCreating}
                            onKeyDown={handleKeyDown}
                            onChange={(e) =>
                                onChangeInput({
                                    ...order,
                                    fapiao: e.target.value !== '' ? true : false,
                                    fapiaoEmail: e.target.value
                                })
                            }
                        />
                    </Dropdown>
                )
            },
        },
    ]

    return (
        <>
            <tr className="line">
                {columnsPart1.map((column) => (
                    <td
                        key={order.id + '-' + column.key}
                        style={{ width: column.width }}
                        className="cell"
                    >
                        {/* <CellWrap col={column.key} id={order.id}> */}
                        {column.render ? column.render(order) : null}
                        {/* </CellWrap> */}
                    </td>
                ))}
                {packsMemoized.map((pack) => {
                    const currentPackIndex = order?.packs?.findIndex(
                        (e) => e._id === pack._id
                    )

                    return (
                        <td
                            key={order.id + '-' + pack.shortName}
                            className="cell"
                            style={{ width: 37 }}
                        >
                            {/* <CellWrap col={pack.shortName} id={order.id}> */}
                            <InputNumber
                                disabled={isCreating}
                                type="text"
                                size="small"
                                min={0}
                                onKeyDown={handleKeyDown}
                                // max={pack?.actualStock}
                                value={
                                    currentPackIndex >= 0
                                        ? order?.packs[currentPackIndex].amount
                                        : ''
                                }
                                onChange={(value) => {
                                    let newPacks = order?.packs
                                        ? [...order.packs]
                                        : []
                                    if (currentPackIndex >= 0) {
                                        if (value === 0) {
                                            newPacks.splice(currentPackIndex, 1)
                                        } else {
                                            newPacks[currentPackIndex].amount =
                                                value
                                        }
                                    } else {
                                        if (value !== 0) {
                                            newPacks.push({
                                                ...pack,
                                                amount: value,
                                            })
                                        }
                                    }

                                    onChangeInput({
                                        ...order,
                                        packs: newPacks,
                                    })
                                }}
                            />
                            {/* </CellWrap> */}
                        </td>
                    )
                })}
                {itemsMemoized.map((item) => {
                    const currentItemIndex = order?.singleItems?.findIndex(
                        (e) => e._id === item._id
                    )

                    return (
                        <td
                            key={order.id + '-' + item.shortName}
                            className="cell"
                            style={{ width: 37 }}
                        >
                            {/* <CellWrap col={item.shortName} id={order.id}> */}
                            <InputNumber
                                disabled={isCreating}
                                type="text"
                                size="small"
                                min={0}
                                onKeyDown={handleKeyDown}
                                // max={pack?.actualStock}
                                value={
                                    currentItemIndex >= 0
                                        ? order?.singleItems[currentItemIndex]
                                              .amount
                                        : ''
                                }
                                onChange={(value) => {
                                    let newItems = order?.singleItems
                                        ? [...order.singleItems]
                                        : []
                                    if (currentItemIndex >= 0) {
                                        if (value === 0) {
                                            newItems.splice(currentItemIndex, 1)
                                        } else {
                                            newItems[currentItemIndex].amount =
                                                value
                                        }
                                    } else {
                                        if (value !== 0) {
                                            newItems.push({
                                                ...item,
                                                product: item.product.id,
                                                amount: value,
                                            })
                                        }
                                    }

                                    onChangeInput({
                                        ...order,
                                        singleItems: newItems,
                                    })
                                }}
                            />
                            {/* </CellWrap> */}
                        </td>
                    )
                })}
                {columnsPart2.map((column) => (
                    <td
                        key={order.id + '-' + column.key}
                        className="cell"
                        style={{ width: column.width }}
                    >
                        {/* <CellWrap col={column.key} id={order.id}> */}
                        {column.render ? column.render(order) : null}
                        {/* </CellWrap> */}
                    </td>
                ))}
            </tr>
        </>
    )
}

export default OrderLine

// const PopUpQR = ({ url }) => {
//     const [isModalVisible, setIsModalVisible] = useState(false)
//     const qr = useRef()

//     const showModal = () => {
//         setIsModalVisible(true)
//     }

//     const handleOk = () => {
//         setIsModalVisible(false)
//     }

//     const handleCancel = () => {
//         setIsModalVisible(false)
//     }

//     return (
//         <>
//             <img src={QR} onClick={showModal} alt="qr" className="qr-image" />
//             <Modal
//                 title="Wechat Pay QR code"
//                 visible={isModalVisible}
//                 onOk={handleOk}
//                 onCancel={handleCancel}
//                 footer={false}
//                 className="qr-modal"
//             >
//                 <QRCode
//                     ref={qr}
//                     value={url}
//                     // renderAs="svg"
//                     size={350}
//                     // bgColor	string (CSS color)	"#FFFFFF"
//                     // fgColor	string (CSS color)	"#000000"
//                     level="H"
//                 />
//                 <Button onClick={() => console.log('qr', qr)}>
//                     <DynamicStrings id="DIRECT_SALES_DOWNLOAD" />
//                 </Button>
//             </Modal>
//         </>
//     )
// }

// const CellWrap = ({ children, col, id }) => {
//     const { selectedLine, setSelectedLine, selectedColumn, setSelectedColumn } =
//         useContext(OfferLiveWX)

//     // const isSelectted = col === selectedColumn || id === selectedLine

//     return (
//         <div
//             onClick={() => {
//                 setSelectedLine(id)
//                 // setSelectedColumn(col)
//             }}
//             // onMouseEnter={() => {
//             //     setSelectedLine(id)
//             //     setSelectedColumn(col)
//             // }}
//             // className={id === selectedLine ? 'cell-selected' : 'cell-wrap'}
//             className={'cell-wrap'}
//         >
//             <div>{children}</div>
//         </div>
//     )
// }
