import { login } from '../actions/User'
import { switchLanguage } from '../actions/Settings'
import { setToken } from '../actions/Token'

const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'

const SWITCH = 'SWITCH'

const TOKEN_SET = 'TOKEN_SET'
const TOKEN_RESET = 'TOKEN_RESET'

const userReducer = (state, action) => {
    switch (action.type) {
        case LOGIN:
            return login(action.payload)
        case LOGOUT:
            localStorage.clear()
            return null
        default:
            throw new Error()
    }
}

const settingsReducer = (state, action) => {
    switch (action.type) {
        case SWITCH:
            return switchLanguage(state)
        default:
            throw new Error()
    }
}

const tokenReducer = (state, action) => {
    switch (action.type) {
        case TOKEN_SET:
            return setToken(action.payload)
        case TOKEN_RESET:
            return null
        default:
            throw new Error()
    }
}

export { userReducer, settingsReducer, tokenReducer }
export { LOGIN, LOGOUT, SWITCH, TOKEN_SET, TOKEN_RESET }
