import { getCommunities } from '../../network/API'

const Voucher = {
    title: 'voucher_information',
    key: [],
    sections: [
        {
            // title: 'voucher_information',
            columns: 6,
            fields: [
                {
                    label: 'ROLES_TEAM_MEMBER',
                    isInactive: true,
                    // type: 'text',
                    key: ['teamMember'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_VOUCHER_ID',
                    isInactive: true,
                    // type: 'text',
                    key: ['VoucherID'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_VOUCHER_REASON',
                    type: 'select',
                    isRequired: true,
                    options: [
                        {
                            key: 'ziko_special',
                            label: 'CREATE_OFFER_ZIKO_SPECIALS',
                        },
                        {
                            key: 'claim',
                            label: 'claim',
                        },
                        {
                            key: 'lottery',
                            label: 'lottery',
                        },
                        {
                            key: 'gift',
                            label: 'gift',
                        },
                        {
                            key: 'cancel_order',
                            label: 'CUSTOMERS_CANCELLED_ORDER',
                        },
                        {
                            key: 'other',
                            label: 'other',
                        },
                    ],
                    key: ['reason'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_COMMENT',
                    type: 'text',
                    key: ['comment'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    label: 'amount',
                    suffixLabel: '¥',
                    type: 'number',
                    isRequired: true,
                    key: ['amount'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_EXPIRATION_DATE',
                    type: 'date',
                    isRequired: true,
                    key: ['expirationDate'],
                    pos: {
                        col: 2,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'COMMUNITY_COMMUNITY',
                    key: ['communities'],
                    isRequired: true,
                    type: 'multicheck',
                    requestOption: getCommunities,
                    pos: {
                        col: 1,
                        row: 5,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const VoucherRefund = {
    title: 'voucher_information',
    key: [],
    sections: [
        {
            // title: 'voucher_information',
            columns: 6,
            fields: [
                {
                    label: 'ROLES_TEAM_MEMBER',
                    isInactive: true,
                    // type: 'text',
                    key: ['teamMember'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_VOUCHER_ID',
                    isInactive: true,
                    // type: 'text',
                    key: ['VoucherID'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_VOUCHER_REASON',
                    type: 'select',
                    isInactive: true,
                    options: [
                        {
                            key: 'refund',
                            label: 'refund',
                        },
                    ],
                    key: ['reason'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_COMMENT',
                    type: 'text',
                    key: ['comment'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    label: 'amount',
                    suffixLabel: '¥',
                    type: 'number',
                    isRequired: true,
                    key: ['amount'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_EXPIRATION_DATE',
                    type: 'date',
                    isRequired: true,
                    key: ['expirationDate'],
                    pos: {
                        col: 2,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'COMMUNITY_COMMUNITY',
                    key: ['communities'],
                    isRequired: true,
                    type: 'multicheck',
                    requestOption: getCommunities,
                    pos: {
                        col: 1,
                        row: 5,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

export { Voucher, VoucherRefund }
