import { Typography } from 'antd'
import { useTimer } from 'react-timer-hook'

const { Text } = Typography

const TimeToEnd = ({ from, to }) => {
    const { days, seconds, minutes, hours } = useTimer({
        expiryTimestamp: from,
        autoStart: true,
    })

    return (
        <Text>
            {`${days * 24 + hours}${minutes < 10 ? ':0' : ':'}${minutes}${
                seconds < 10 ? ':0' : ':'
            }${seconds}`}
        </Text>
    )
}

export default TimeToEnd
