import { useContext } from 'react'
import { Button, Avatar, Tooltip, Menu, Dropdown, Space } from 'antd'
import { CheckOutlined, MoreOutlined } from '@ant-design/icons'
import i18next from 'i18next'

import Logo from '../images/Ellipse.svg'

import { AppContext } from '../context/AppContext'

import { formatNb, formatDate, displayOrderDetails } from '../utils/utils'
import { getOrders, getCommunities, SERVER } from '../network/API'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import { OrderStatus } from '../components/tables/cells/Status'
import DynamicStrings from '../components/DynamicStrings'
import {formatTimeStr} from "antd/es/statistic/utils";
import moment from "moment";

const ServicesOrder = ({ history }) => {
    const { user } = useContext(AppContext)

    const commuFilter = (key) =>
        user.role === 'admin' || user.role === 'super-admin'
            ? {}
            : { [key]: { $in: user.communities } }

    return (
        <PageWrap title="MENU_SERVICES_ORDER_LIST">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getOrders'}
                request={getOrders}
                otherFilters={{
                    // status: { $ne: 'discontinued' },
                    type: 'service',
                    valid: true,
                    ...commuFilter('community'),
                }}
                pageId={commuFilter('id')}
                pageSize={80}
            />
        </PageWrap>
    )
}

const filters = (goTo, lineSelect, loading, id) => ({
    fields: [
        // {
        //     type: 'text',
        //     label: 'SERVICES_ORDER_LIST_TRANSACTION_NUMBER',
        //     key: 'name',
        // },
        {
            type: 'select',
            label: 'CREATE_OFFER_CHANNEL',
            default: '',
            key: 'channel',
            options: [
                { key: 'miniprogram', label: 'miniprogram' },
                { key: 'wechat_group', label: 'wechat_group' },
            ],
        },
        {
            type: 'select',
            label: 'COMMUNITY_COMMUNITY',
            default: '',
            key: 'community',
            options: getCommunities,
            otherParams: id,
        },
        {
            type: 'spe',
            label: 'ROLES_NAME',
            key: 'customer.name',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'OFFER_LIVE_ORDER_ID',
            key: 'orderID',
            onCell: () => {
                return {
                    onClick: (e) => e.stopPropagation(),
                }
            },
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    tabs: {
        key: 'paymentStatus',
        default: '',
        options: [
            { key: '', label: 'OFFERS_ALL' },
            { key: 'pending', label: 'pending' },
            { key: 'paid', label: 'paid' },
            { key: 'ccl', label: 'ccl' },
            { key: 'refunding', label: 'refund' },
            { key: 'rfd', label: 'refunded' },
        ],
    },
})

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        dataIndex: 'orderID',
        key: 'orderID',
    },
    {
        key: 'customer',
        width: 40,
        render: (order) => (
            <Avatar
                size="small"
                src={
                    order?.customer?.profilePicture?.uri
                        ? `${SERVER.baseURL}/storage/customer-picture/${order?.customer?.profilePicture?.uri}`
                        : Logo
                }
            />
        ),
    },
    {
        title: <DynamicStrings id="ROLES_NAME" />,
        key: 'customer',
        render: (order) =>
            order?.customer?.id
                ? order.customer.name
                : order.customerDaily?.customerName,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'community',
        render: (community) => <DynamicStrings id={community?.name} />,
        key: 'community',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CREATE_OFFER_CHANNEL" />,
        dataIndex: 'channel',
        render: (e) => <DynamicStrings id={e} />,
        key: 'channel',
        sorter: true,
    },
    {
        title: <DynamicStrings id="DIRECT_SALES_PHONE_NUMBER" />,
        render: (e) => e?.customerAddress?.phone,
        key: 'customer',
        sorter: false,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_DATE" />,
        dataIndex: 'orderDate',
        render: (date) => formatDate(date),
        key: 'orderDate',
        sorter: true,
    },
    {
        title: <DynamicStrings id={"SERVICES_ORDER_TIME"} />,
        dataIndex: 'orderDate',
        render: (date) => moment(date).format('HH:mm'),
        key: 'orderDate',
        // sorter: true,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_ORDER_DETAILS" />,
        elipsis: true,
        dataIndex: '',
        render: (e) => {
            const toDisplay = [
                ...e.packs
                    ?.filter((e) => e.amount)
                    ?.map((pack, i) => `${pack.amount} ${pack.shortName}`),
                ...e.singleItems
                    ?.filter((e) => e.amount)
                    ?.map(
                        (item) =>
                            `${item.amount} ${
                                item.shortName ||
                                item?.product?.name[i18next.language]
                            }`
                    ),
            ].join(', ')

            return <Tooltip title={displayOrderDetails(e)}>{toDisplay}</Tooltip>
        },
        key: 'packs',
    },
    {
        title: <DynamicStrings id="amount" suffix="(¥)" />,
        render: ({ actualAmount }) => formatNb(actualAmount),
        onCell: (record, rowIndex) => {
            if (record.paymentMethod === "alipay") {
                return {
                    className: "is-alipay",
                }
            }
        },
        key: 'actualAmount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_VOUCHER" suffix="(¥)" />,
        dataIndex: 'vouchers',
        render: (vouchers) =>
            vouchers.length
                ? vouchers?.map(
                      (vouchers, i) => (i === 0 ? '' : ', ') + vouchers.amount
                  )
                : '0',
        key: 'vouchers',
        sorter: false,
    },
    // {
    //     title: <DynamicStrings id="SERVICES_ORDER_LIST_TRANSACTION_NUMBER" />,
    //     dataIndex: 'transactionID',
    //     key: 'transactionID',
    //     sorter: true,
    // },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        render: ({ status, paymentStatus, trackingStatus, id, actualAmount, ...order }) => (
            <OrderStatus
                status={status}
                paymentStatus={paymentStatus}
                trackingStatus={trackingStatus}
                id={id}
                keyRequest={keyRequest}
                actualAmount={actualAmount}
                order={order}
            />
        ),
        key: 'paymentStatus',
        sorter: true,
    },
    {
        width: 50,
        render: (e) => {
            const menu = (
                <Menu>
                    <Menu.Item
                        key="0"
                        disabled={
                            e.paymentStatus === 'rfd' ||
                            !!e.refundVouchers?.length ||
                            !e.customer
                        }
                    >
                        <a
                            onClick={() =>
                                goTo({
                                    pathname: '/vouchers/create',
                                    customer: e.customer,
                                    refundOrder: e,
                                })
                            }
                        >
                            <DynamicStrings
                                id={
                                    !!e.refundVouchers?.length
                                        ? 'SERVICES_ORDER_GOT_VOUCHER'
                                        : 'SERVICES_ORDER_LIST_CREATE_VOUCHER'
                                }
                            />
                        </a>
                    </Menu.Item>

                    {e.channel === 'wechat_group' && (
                        <Menu.Item key="1">
                            <a onClick={() => goTo(`/offers/${e.offer?.id}/orders`)}>
                                <DynamicStrings id="ROLES_EDIT" />
                            </a>
                        </Menu.Item>
                    )}
                </Menu>
            )

            return (
                <Space className="centerMe">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                        >
                            <MoreOutlined />
                        </a>
                    </Dropdown>
                </Space>
            )
        },
    },
    // {
    //     title: '',

    //     render: (e) => {
    //         return (
    //             <Button
    //                 disabled={e.paymentStatus === 'rfd' || !e.customer}
    //                 style={{
    //                     backgroundColor: !!e.refundVouchers?.length
    //                         ? '#156bbb'
    //                         : undefined,
    //                 }}
    //                 onClick={() =>
    //                     !!e.refundVouchers?.length
    //                         ? null
    //                         : goTo({
    //                               pathname: '/vouchers/create',
    //                               customer: e.customer,
    //                               refundOrder: e,
    //                           })
    //                 }
    //                 type="primary"
    //             >
    //                 {!!e.refundVouchers?.length ? (
    //                     <>
    //                         <CheckOutlined />
    //                         <DynamicStrings id="SERVICES_ORDER_GOT_VOUCHER" />
    //                     </>
    //                 ) : (
    //                     <DynamicStrings id="SERVICES_ORDER_LIST_CREATE_VOUCHER" />
    //                 )}
    //             </Button>
    //         )
    //     },
    // },
]

export default ServicesOrder
