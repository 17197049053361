import { INSTANCE, INSTANCELIST } from './API'

export const getOffers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/offers/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getOffersServices = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/offers/services',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getSimpleOffers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/offers',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateAnOffer = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/offers',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const DeleteOffer = (offerId, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/offers/${offerId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                status: 'discontinued',
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleOffer = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { offerId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/offers/${offerId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const EditOffer = (form, token, offerId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/offers/${offerId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getOfferLottery = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { offerId, channel, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/lottery-draws/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                filter: {
                    offer: offerId,
                    channel,
                },
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getOfferAnalytics = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { offerId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/offers/${offerId}/analytics`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })
