import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'

import {
    DescriptionCreate,
    cleanBeforeSending,
    checkValidity,
} from '../../constants/forms/Users'
import { CreateUser as request } from '../../network/Users'

const CreateUser = () => {
    return (
        <PageWrap goBack title="ROLES_CREATE_TEAM_MEMBER">
            <FormWrap
                title="create_user"
                request={request}
                onSuccess={'/users'}
                onCancel={'/users'}
                invalidate={['getUsers']}
                cleanBeforeSending={cleanBeforeSending}
                checkValidity={checkValidity}
            >
                <div className="SectionHorizontalWrap">
                    <FormBlock formTemplate={DescriptionCreate} />
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateUser
