import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Button, Space, Badge, Select } from 'antd'

import { formatDate, formatNb } from '../utils/utils'
import { getVouchers } from '../network/API'
import { ValidateVoucher, EditVoucher } from '../network/Vouchers'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'
import { AppContext } from 'src/context/AppContext'

const VoucherManagment = () => {
    return (
        <PageWrap title="MENU_VOUCHER_MANAGEMENT">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getVouchers'}
                request={getVouchers}
                // statsRequest={}
                pageSize={80}
            />
        </PageWrap>
    )
}

const filters = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'CUSTOMERS_VOUCHER_ID',
            key: 'voucherID',
        },
        {
            type: 'text',
            label: 'CUSTOMERS_CUSTOMER_NAME',
            key: 'customer.name',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'ROLES_TEAM_MEMBER',
            key: 'teamMember.name',
            noRegex: true,
        },
        {
            type: 'select',
            label: 'CUSTOMERS_VOUCHER_REASON',
            default: '',
            key: 'reason',
            options: [
                // {
                //     key: 'refund',
                //     label: 'rfd',
                // },
                {
                    key: 'ziko_special',
                    label: 'CREATE_OFFER_ZIKO_SPECIALS',
                },
                {
                    key: 'claim',
                    label: 'claim',
                },
                {
                    key: 'lottery',
                    label: 'lottery',
                },
                {
                    key: 'gift',
                    label: 'gift',
                },
                {
                    key: 'cancel_order',
                    label: 'CUSTOMERS_CANCELLED_ORDER',
                },
                {
                    key: 'other',
                    label: 'other',
                },
            ],
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
})

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="CUSTOMERS_VOUCHER_ID" />,
        dataIndex: 'voucherID',
        key: 'voucherID',
    },
    {
        title: <DynamicStrings id="CUSTOMERS_CUSTOMER_NAME" />,
        dataIndex: 'customer',
        render: (customer) => customer?.name,
        key: 'customer',
        sorter: false,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_VOUCHER_REASON" />,
        dataIndex: 'reason',
        render: (e) => <DynamicStrings id={e} />,
        key: 'reason',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'communities',
        key: 'communities',
        render: (communities) => (
            <span>
                {communities.map((com, i) => (
                    <span>
                        <DynamicStrings id={com.name} />
                        {i < communities.length - 1 && ', '}
                    </span>
                ))}
            </span>
        ),
        sorter: false,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_TOTAL" suffix="(¥)" />,
        dataIndex: 'amount',
        render: (amount) => formatNb(amount),
        key: 'amount',
        sorter: true,
    },
    {
        title: <DynamicStrings id={'ZIKO_SPECIALS_CREATION_DATE'} />,
        dataIndex: 'createdAt',
        render: (date) => (date ? formatDate(date) : null),
        key: 'createdAt',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ZIKO_SPECIALS_EXPIRATION_DATE" />,
        dataIndex: 'expirationDate',
        render: (date) => (date ? formatDate(date) : null),
        key: 'expirationDate',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
        dataIndex: 'comment',
        key: 'comment',
        width: 300,
    },
    {
        title: <DynamicStrings id="ROLES_TEAM_MEMBER" />,
        dataIndex: 'teamMember',
        render: (teamMember) => teamMember?.name,
        key: 'teamMember.name',
        sorter: false,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        // dataIndex: 'status',
        // render: (e) => {
            // const color = {
            //     pending: 'default',
            //     validated: 'green',
            //     declined: 'red',
            //     used: 'blue',
            // }

            // return <Badge color={color[e]} text={<DynamicStrings id={e} />} />
        // },
        render: ({ status, id }) => (
            <VoucherStatus
                status={status}
                id={id}
                keyRequest={keyRequest}
            />
        ),
        key: 'status',
        sorter: true,
    },
    {
        title: '',
        key: 'action',
        width: 1,
        render: (e) =>
            e.status !== 'pending' && !e.status ? (
                <ButtonStatus voucher={e} keyRequest={keyRequest} />
            ) : null,
    },
]

const ButtonStatus = ({ voucher, keyRequest }) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()

    const mutation = useMutation(
        (value) => ValidateVoucher(value, token.token, voucher.id),
        {
            onSuccess: (data, variables) => {
                queryClient.setQueryData(keyRequest, (oldData) => {
                    let oldDataIndex = oldData.list.findIndex(
                        (e) => e.id === data.id
                    )
                    oldData.list[oldDataIndex].status = data.status
                    return oldData
                })
            },
        }
    )

    return (
        <Space>
            <Button
                onClick={() => mutation.mutate('decline')}
                style={{
                    background: '#FF4D4F',
                    borderColor: '#FF4D4F',
                    color: '#fff',
                }}
            >
                <DynamicStrings id="CUSTOMER_SERVICE_DECLINE" />
            </Button>
            <Button
                onClick={() => mutation.mutate('validate')}
                style={{
                    background: '#27AE60',
                    borderColor: '#27AE60',
                    color: '#fff',
                }}
            >
                <DynamicStrings id="CUSTOMER_SERVICE_VALIDATE" />
            </Button>
        </Space>
    )
}

const VoucherStatus = ({ status, id, keyRequest }) => {
    const { token, user } = useContext(AppContext)
    const isAdmin = user?.role === 'admin' || user?.role === 'super-admin'
    const queryClient = useQueryClient()

    const mutation = useMutation((values) => EditVoucher(...values), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e.id === data.id
                )

                oldData.list[oldDataIndex].status = data.status
                return oldData
            })
        },
    })

    const color = {
        pending: 'default',
        validated: 'green',
        declined: 'red',
        used: 'blue',
        ccl: 'red',
    }

    return (
        <Select
            disabled={!isAdmin}
            bordered={false}
            value={status}
            onChange={(e) => {
                let newVoucher = { status: e }

                mutation.mutate([
                    {
                        ...newVoucher,
                    },
                    token.token,
                    id,
                ])
            }}
        >
            <Select.Option key={status}>
                <Badge color={color[status]} text={<DynamicStrings id={status} />} />
            </Select.Option>
            {status === 'validated' && (
                <Select.Option key={'ccl'}>
                    <Badge color={'red'} text={<DynamicStrings id="ccl" />} />
                </Select.Option>
            )}
        </Select>
    )
}


export default VoucherManagment
