import { Controller } from 'react-hook-form'
import { Input, Form, Card } from 'antd'

const Clipboard = ({ control }) => {
    return (
        <Card bordered={false} style={{ flex: 1 }} title="Clipboard">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
                <div key={e} style={{ display: 'flex', gap: 8 }}>
                    <Form.Item label={`Shortcut #${e + 1}`}>
                        <Controller
                            name={`clipboard.${e}.shortcut`}
                            control={control}
                            // defaultValue=""
                            render={({ value, onChange }) => (
                                <Input value={value} onChange={onChange} />
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label={`Shortcut text #${e + 1}`}
                        style={{ flex: 1 }}
                    >
                        <Controller
                            name={`clipboard.${e}.text`}
                            control={control}
                            // defaultValue=""
                            render={({ value, onChange }) => (
                                <Input value={value} onChange={onChange} />
                            )}
                        />
                    </Form.Item>
                </div>
            ))}
        </Card>
    )
}

export default Clipboard
