import {
    LineChartOutlined,
    ShopOutlined,
    UnorderedListOutlined,
    TeamOutlined,
    SolutionOutlined,
    TagsOutlined,
    InboxOutlined,
    CommentOutlined,
    BookOutlined,
    TrophyOutlined,
    TransactionOutlined,
    UserOutlined,
} from '@ant-design/icons'

import Analytics from '../pages/Analytics'
import OfferList from '../pages/OfferList'
import CreateOffer from '../pages/create/CreateOffer'
import OfferDetails from '../pages/details/OfferDetails'
import DeliveryTracking from '../pages/DeliveryTracking'
import OfferOrders from '../pages/OrdersLive/OfferOrders'
import DeliveryTemplates from '../pages/DeliveryTemplates'
import CreateDeliveryTemplate from '../pages/create/CreateDeliveryTemplate'
import DeliveryTemplateDetails from '../pages/details/DeliveryTemplateDetails'
import CreateArea from '../pages/create/CreateArea'
import AreaDetails from '../pages/details/AreaDetails'
import ServicesOrder from '../pages/ServicesOrder'
import DirectSalesOrder from '../pages/DirectSalesOrder'
import CreateDailyOrder from '../pages/create/CreateDailyOrder'
import DailyOrderDetails from '../pages/details/DailyOrderDetails'
import Customers from '../pages/Customers'
import CustomersDetails from '../pages/CustomerDetails/CustomersDetails'
import CreateCustomer from '../pages/create/CreateCustomer'
import Suppliers from '../pages/Suppliers'
import CreateSupplier from '../pages/create/CreateSupplier'
import SupplierDetails from '../pages/details/SupplierDetails'
import Products from '../pages/Products'
import CreateProduct from '../pages/create/CreateProduct'
import ProductDetails from '../pages/details/ProductDetails'
import ProductAnalytics from '../pages/ProductAnalytics'
import Inventory from '../pages/Inventory'
import InventoryDetails from '../pages/details/InventoryDetails'
import Claims from '../pages/Claims'
import CreateClaim from '../pages/create/CreateClaim'
import ClaimDetail from '../pages/details/ClaimDetail'
import VoucherManagement from '../pages/VoucherManagement'
import CreateVoucher from '../pages/create/CreateVoucher'
import Refunds from '../pages/Refunds'
import CreateRefund from '../pages/create/CreateRefund'
import RefundDetail from '../pages/details/RefundDetail'
import Recipes from '../pages/Recipes'
import CreateRecipe from '../pages/create/CreateRecipe'
import RecipeDetails from '../pages/details/RecipeDetails'
import LotteryManagement from '../pages/LotteryManagement'
import Overview from '../pages/Overview'
import DirectSales from '../pages/DirectSales'
import CreatePurchaseOrder from '../pages/create/CreatePurchaseOrder'
import FinanceDetails from '../pages/details/FinanceDetails'
import EditPurchaseOrder from '../pages/edit/EditPurchaseOrder'
import Services from '../pages/Services'
import ZikoTeam from '../pages/ZikoTeam'
import CreateUser from '../pages/create/CreateUser'
import UserDetails from '../pages/details/UserDetails'

const menuList = [
    {
        label: 'MENU_ANALYTICS',
        slug: '/analytics',
        icon: <LineChartOutlined />,
        sub: [],
        roles: ['super-admin', 'admin', 'product-manager', 'finance'],
        showInMenu: true,
        component: Analytics,
    },
    //
    {
        label: 'MENU_OFFERS',
        slug: '/offers',
        icon: <ShopOutlined />,
        sub: [],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'customer-hero',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        component: OfferList,
        ext: [
            {
                slug: '/offers/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                ],
                component: CreateOffer,
            },
            {
                slug: '/offers/:offerId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                ],
                component: OfferDetails,
            },
            {
                slug: '/offers/:offerId/delivery',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                    'logistic',
                ],
                component: DeliveryTracking,
            },
            {
                slug: '/offers/:offerId/orders',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'logistic',
                    'customer-hero',
                ],
                component: OfferOrders,
            },
        ],
    },
    {
        label: 'MENU_DELIVERY_TEMPLATES',
        slug: '/delivery',
        icon: <ShopOutlined />,
        sub: [],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'logistic',
        ],
        showInMenu: true,
        component: DeliveryTemplates,
        ext: [
            {
                slug: '/delivery/template/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'logistic',
                ],
                component: CreateDeliveryTemplate,
            },
            {
                slug: '/delivery/template/:templateId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'logistic',
                ],
                component: DeliveryTemplateDetails,
            },
            {
                slug: '/delivery/areas/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'logistic',
                ],
                component: CreateArea,
            },
            {
                slug: '/delivery/areas/:areaId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'logistic',
                ],
                component: AreaDetails,
            },
        ],
    },
    //
    // {
    //     label: 'MENU_OFFERS',
    //     icon: <ShopOutlined />,
    //     roles: [
    //         'super-admin',
    //         'admin',
    //         'product-manager',
    //         'community-manager',
    //         'customer-hero',
    //         'logistic',
    //         'finance',
    //     ],
    //     showInMenu: true,
    //     sub: [
    //         {
    //             label: 'MENU_OFFER_LIST',
    //             slug: '/offers',
    //             roles: [
    //                 'super-admin',
    //                 'admin',
    //                 'product-manager',
    //                 'community-manager',
    //                 'customer-hero',
    //                 'logistic',
    //                 'finance',
    //             ],
    //             showInMenu: true,
    //             component: OfferList,
    //         },
    //         // {
    //         //     label: 'MENU_CREATE_AN_OFFER',
    //         //     slug: '/offers/create',
    //         //     roles: [
    //         //         'super-admin',
    //         //         'admin',
    //         //         'product-manager',
    //         //         'community-manager',
    //         //     ],
    //         //     showInMenu: true,
    //         //     component: CreateOffer,
    //         // },
    //         {
    //             label: 'MENU_DELIVERY_TEMPLATES',
    //             slug: '/delivery',
    //             roles: [
    //                 'super-admin',
    //                 'admin',
    //                 'product-manager',
    //                 'community-manager',
    //                 'logistic',
    //             ],
    //             showInMenu: true,
    //             component: DeliveryTemplates,
    //         },
    //     ],
    //     ext: [

    //     ],
    // },
    {
        label: 'MENU_ORDERS',
        icon: <UnorderedListOutlined />,
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'customer-hero',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        sub: [
            {
                label: 'MENU_SERVICES_ORDER_LIST',
                slug: '/orders-services',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                    'finance',
                ],
                showInMenu: true,
                component: ServicesOrder,
            },
            {
                label: 'MENU_DIRECT_SALES_ORDER_LIST',
                slug: '/orders-direct-sales',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                    'logistic',
                    'finance',
                ],
                showInMenu: true,
                component: DirectSalesOrder,
            },
        ],
        ext: [
            {
                slug: '/daily-orders/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                    'logistic',
                    'finance',
                ],
                component: CreateDailyOrder,
            },
            {
                slug: '/daily-orders/:orderId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                    'logistic',
                    'finance',
                ],
                component: DailyOrderDetails,
            },
        ],
    },
    {
        label: 'MENU_CUSTOMERS',
        slug: '/customers',
        icon: <TeamOutlined />,
        sub: [],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'customer-hero',
            'logistic',
            'finance',
        ],
        ext: [
            {
                slug: '/customers/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                    'logistic',
                    'finance',
                ],
                component: CreateCustomer,
            },
            {
                slug: '/customers/:customerId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                    'logistic',
                    'finance',
                ],
                component: CustomersDetails,
            },
        ],
        showInMenu: true,
        component: Customers,
    },
    {
        label: 'MENU_SUPPLIERS',
        slug: '/suppliers',
        icon: <SolutionOutlined />,
        sub: [],
        ext: [
            {
                slug: '/suppliers/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'logistic',
                    'finance',
                ],
                component: CreateSupplier,
            },
            {
                slug: '/suppliers/:supplierId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'logistic',
                    'finance',
                ],
                component: SupplierDetails,
            },
        ],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        component: Suppliers,
    },
    {
        label: 'MENU_PRODUCTS',
        slug: '/products',
        icon: <TagsOutlined />,
        sub: [],
        ext: [
            {
                slug: '/products/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'designer',
                    'logistic',
                ],
                component: CreateProduct,
            },
            {
                slug: '/products/:productId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'designer',
                    'logistic',
                ],
                component: ProductDetails,
            },
            {
                slug: '/products/:productId/analytics',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'finance',
                ],
                component: ProductAnalytics,
            },
        ],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'customer-hero',
            'designer',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        component: Products,
    },
    {
        label: 'ANALYTICS_STOCK',
        slug: '/inventory',
        icon: <InboxOutlined />,
        sub: [
            {
                label: 'MENU_INVENTORY',
                slug: '/inventory',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'logistic',
                    'finance',
                ],
                showInMenu: true,
                component: Inventory,
            },

            // {
            //     label: 'Purchase orders',
            //     slug: '/direct-sales',
            //     roles: [
            //         'super-admin',
            //         'admin',
            //         'product-manager',
            //         'logistic',
            //         'finance',
            //     ],
            //     showInMenu: true,
            //     component: DirectSales,
            // },
        ],
        ext: [
            {
                slug: '/inventory/:id',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'logistic',
                    'finance',
                ],
                component: InventoryDetails,
            },
        ],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        // component: Inventory,
    },
    {
        label: 'MENU_PURCHASE_ORDERS',
        slug: '/direct-sales',
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        icon: <UnorderedListOutlined />,
        component: DirectSales,
    },
    {
        label: 'MENU_CUSTOMER_SERVICE',
        slug: '/customer-services',
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'customer-hero',
            'finance',
        ],
        showInMenu: true,
        icon: <CommentOutlined />,
        sub: [
            {
                label: 'MENU_CLAIMS',
                slug: '/claims',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                ],
                showInMenu: true,
                component: Claims,
            },
            {
                label: 'MENU_VOUCHER_MANAGEMENT',
                slug: '/vouchers',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'customer-hero',
                ],
                showInMenu: true,
                component: VoucherManagement,
            },
            {
                label: 'MENU_REFUND',
                slug: '/refunds',
                roles: [
                    'super-admin',
                    'admin',
                    'finance',
                    'community-manager',
                    'customer-hero',
                ],
                showInMenu: true,
                component: Refunds,
            },
        ],
        ext: [
            {
                slug: '/claims/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                ],
                component: CreateClaim,
            },
            {
                slug: '/claims/:claimId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                ],
                component: ClaimDetail,
            },
            {
                slug: '/vouchers/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'customer-hero',
                ],
                component: CreateVoucher,
            },
            // {
            //     slug: '/vouchers/:voucherId',
            //     roles: [
            //         'super-admin',
            //         'admin',
            //         'product-manager',
            //         'community-manager',
            //         'customer-hero',
            //     ],
            //     component: CreateVoucher,
            // },
            {
                slug: '/refunds/create',
                roles: [
                    'super-admin',
                    'admin',
                    // 'finance',
                    'community-manager',
                    'customer-hero',
                ],
                component: CreateRefund,
            },
            {
                slug: '/refunds/:refundId',
                roles: [
                    'super-admin',
                    'admin',
                    'finance',
                    'community-manager',
                    'customer-hero',
                ],
                component: RefundDetail,
            },
        ],
    },
    {
        label: 'MENU_RECIPES',
        slug: '/recipes',
        icon: <BookOutlined />,
        sub: [],
        ext: [
            {
                slug: '/recipes/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'designer',
                ],
                component: CreateRecipe,
            },
            {
                slug: '/recipes/:recipeId',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'designer',
                ],
                component: RecipeDetails,
            },
        ],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'designer',
        ],
        showInMenu: true,
        component: Recipes,
    },
    {
        label: 'MENU_LOTTERY_MANAGEMENT',
        slug: '/lottery-management',
        icon: <TrophyOutlined />,
        sub: [],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'customer-hero',
        ],
        showInMenu: true,
        component: LotteryManagement,
    },
    {
        label: 'MENU_FINANCE',
        slug: '/finance',
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        icon: <TransactionOutlined />,
        sub: [
            {
                label: 'MENU_OVERVIEW',
                slug: '/overview',
                roles: ['super-admin', 'admin', 'finance'],
                showInMenu: true,
                component: Overview,
            },
            {
                label: 'MENU_SERVICES',
                slug: '/services',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'logistic',
                    'finance',
                ],
                showInMenu: true,
                component: Services,
            },
        ],
        ext: [
            {
                slug: '/finance/:id',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'community-manager',
                    'finance',
                    'logistic',
                ],
                component: FinanceDetails,
            },
            {
                slug: '/purchase/create',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'logistic',
                    'finance',
                ],
                component: CreatePurchaseOrder,
            },
            {
                slug: '/finance/:id/edit',
                roles: [
                    'super-admin',
                    'admin',
                    'product-manager',
                    'logistic',
                    'finance',
                ],
                component: EditPurchaseOrder,
            },
        ],
    },
    {
        label: 'MENU_ZIKO_TEAM',
        slug: '/users',
        icon: <UserOutlined />,
        sub: [],
        ext: [
            {
                slug: '/users/create',
                roles: ['super-admin', 'admin', 'product-manager'],
                component: CreateUser,
            },
            {
                slug: '/users/:userId',
                roles: ['super-admin', 'admin', 'product-manager'],
                component: UserDetails,
            },
        ],
        roles: [
            'super-admin',
            'admin',
            'product-manager',
            'community-manager',
            'customer-hero',
            'logistic',
            'finance',
        ],
        showInMenu: true,
        component: ZikoTeam,
    },
]

export default menuList
