import { useForm, FormProvider } from 'react-hook-form'
import { Tabs, Space, Button, Typography, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useEffect, useState, useContext, useCallback } from 'react'
import { useMutation } from 'react-query'

import Packs from './Packs'
import Items from './Items'
import Bourses from './Bourses'
import Specials from './Specials'
import Infos from './Infos'
import Description from './Description'
import Clipboard from './Clipboard'
import Details from './Details'
import Lottery from './Lottery'
import { CreateAnOffer, DeleteOffer, EditOffer } from '../../../network/Offers'
import { AppContext } from '../../../context/AppContext'
import { useParams } from 'react-router-dom'
import { getSingleOffer } from '../../../network/Offers'
import { defaultValues } from './defaultValues'
import formatData from './formatData'
import { useQuery } from 'react-query'
import { getProductsBasic, getProductsList } from '../../../network/Products'
import PageWrap from '../../../components/layout/PageWrap'
import DeleteButton from '../../../components/DeleteButton'
import checkValidity from './checkValidity'
import cleanBeforeSending from './cleanBeforeSending'
import { Prompt } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs
const { Text } = Typography

export const EditOfferNew = (props) => {
    const { token } = useContext(AppContext)
    let { offerId } = useParams()
    const [offerDetails, setOfferDetails] = useState()

    const mutation = useMutation(
        () =>
            getSingleOffer({ queryKey: ['', { offerId, token: token.token }] }),
        {
            onSuccess: (data) => setOfferDetails(data),
        }
    )

    const editMutation = useMutation(
        (data) => EditOffer(cleanBeforeSending(data), token.token, offerId),
        {
            onSuccess: () => props.history.push('/offers'),
        }
    )

    useEffect(() => {
        mutation.mutate()
    }, [])

    const canDelete = new Date(offerDetails?.startingDate) > new Date()

    if (!mutation.isError && !mutation.isSuccess) return <div>Loading...</div>

    return (
        <CreateOfferNew
            {...props}
            offerId={offerId}
            offerValues={offerDetails}
            editMutation={editMutation}
            canDelete={canDelete}
        />
    )
}

const CreateOfferNew = ({
    history,
    offerId,
    offerValues,
    editMutation,
    canDelete = false,
}) => {
    const isEdit = !!offerId
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const [tabKey, setTabKey] = useState(
        history?.location?.defaultValue?.channel === 'miniprogram'
            ? 'miniprogram'
            : 'wechat_group'
    )
    const [isPrompt, setIsPrompt] = useState(false)
    const [isLeaving, setIsLeaving] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [products, setProducts] = useState({})
    const [initialValues, setInitialValues] = useState({})

    // const products = useQuery(
    //     ['getProductsBasic', { token: token.token }],
    //     getProductsBasic,
    //     {
    //         refetchOnWindowFocus: false,
    //         refetchOnMount: false,
    //         refetchOnReconnect: false,
    //     }
    // )

    const methods = useForm({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultValues,
            ...(formatData(offerValues) || history?.location?.defaultValue),
        },
    })

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        errors,
        setError,
        clearErrors,
    } = methods

    useEffect(() => {
        setInitialValues(getValues())
        getProductsList(token.token).then((data) => {
            let tempProducts = {}
            for (const i in data.list) {
                tempProducts[data.list[i].id] = data.list[i].status
            }
            setProducts(tempProducts)
        })
    }, [])

    const channelValue = watch('channel')
    const typeValue = watch('type')

    const groupPacksCount = watch('wechatGroup.packs')?.length || 0
    const groupItemsCount = watch('wechatGroup.items')?.length || 0
    const mpPacksCount = watch('miniprogram.packs')?.length || 0
    const mpItemsCount = watch('miniprogram.items')?.length || 0

    const alertUser = (e) => {
        e.preventDefault()
        e.returnValue = ''
    }

    const mutation = useMutation(
        (data) => CreateAnOffer(cleanBeforeSending(data), token.token),
        {
            onSuccess: () => {
                setIsPrompt(false)
                history.push('/offers')
            },
            onError: () => message.error('An error occurs'),
        }
    )

    const formValues = watch()

    useEffect(() => {
        if (isLeaving) {
            return
        }

        if (JSON.stringify(formValues) !== JSON.stringify(initialValues)) {
            setIsPrompt(true)
        } else {
            setIsPrompt(false)
        }
    }, [formValues, initialValues])

    useEffect(() => {
        if (
            (isEdit && editMutation.isSuccess) ||
            (!isEdit && mutation.isSuccess)
        ) {
            return
        }

        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [mutation.isSuccess, editMutation?.isSuccess])

    const onSubmit = (data) => {
        const hasError = checkValidity(data, products)
        console.log('kkkk hasError', !!hasError.length)
        if (!!hasError.length) {
            message.error('Please fix the errors before submitting')
            hasError.forEach((e) =>
                setError(e.key, { type: 'custom', message: e.message })
            )

            return
        }

        setIsLeaving(true)
        setIsPrompt(false)

        if (isEdit) {
            return editMutation?.mutate(data)
        } else {
            return mutation.mutate(data)
        }
    }

    useEffect(() => {
        if (channelValue !== 'all') {
            setTabKey(channelValue)
        }
    }, [channelValue])

    const canEdit = isEdit && !canDelete

    const getProps = (channel, name) => ({
        control,
        channel: channel === 'wechat_group' ? 'wechatGroup' : 'miniprogram',
        isEdit: canEdit,
        setValue,
        errors: errors?.[
            channel === 'wechat_group' ? 'wechatGroup' : 'miniprogram'
        ]?.[name],
        clearErrors,
        packs: channel === 'wechat_group' ? groupPacksCount : mpPacksCount,
        items: channel === 'wechat_group' ? groupItemsCount : mpItemsCount,
    })

    const copyValues = useCallback(() => {
        const nowValues = getValues()

        methods.reset(
            {
                ...nowValues,
                [tabKey === 'wechat_group' ? 'wechatGroup' : 'miniprogram']:
                    nowValues?.[
                        tabKey === 'wechat_group'
                            ? 'miniprogram'
                            : 'wechatGroup'
                    ],
            },
            { isDirty: true }
        )
    }, [tabKey])

    const labels = {
        miniprogram: 'Miniprogram',
        wechat_group: 'Group',
        wechatGroup: 'Group',
    }

    console.log('kkkk errors', errors)

    const getDeleteResult = (result) => {
        setIsDeleted(result)
    }

    return (
        <>
            <PageWrap
                goBack
                title={isEdit ? offerValues?.internalName : 'Create an offer'}
                actionOptions={
                    isEdit
                        ? [
                              <DeleteButton
                                  onSuccess="/offers"
                                  request={DeleteOffer}
                                  id={offerId}
                                  invalidate={[
                                      'getOffers',
                                      'getOffersServices',
                                  ]}
                                  disabled={!canDelete}
                                  isDeleted={getDeleteResult}
                              />,
                              <Button
                                  onClick={() => {
                                      history.push({
                                          pathname: '/offers/create-new',
                                          defaultValue: {
                                              ...formatData(offerValues),
                                              ghostID: offerValues?.offerID,
                                              offerID: undefined,
                                              startingDate: undefined,
                                              endingDate: undefined,
                                          },
                                      })
                                  }}
                                  type="primary"
                              >
                                  Duplicate offer
                              </Button>,
                          ]
                        : undefined
                }
            >
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="ant-form-vertical"
                        style={{
                            padding: 8,
                            display: 'flex',
                            gap: 8,
                            flexDirection: 'column',
                        }}
                    >
                        <Infos
                            control={control}
                            isEdit={canEdit}
                            errors={errors}
                        />

                        <div
                            style={{
                                display: 'flex',
                                gap: 8,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Description control={control} isEdit={canEdit} />
                            {channelValue !== 'miniprogram' && (
                                <Clipboard control={control} />
                            )}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                gap: 8,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Tabs
                                activeKey={tabKey}
                                onChange={(key) => setTabKey(key)}
                                className="customerDetailTab"
                                style={{
                                    backgroundColor: '#fff',
                                    padding: '0px',
                                    flex: 2,
                                }}
                                tabBarExtraContent={
                                    <Button
                                        size="small"
                                        disabled={
                                            channelValue !== 'all' && canEdit
                                        }
                                        onClick={copyValues}
                                    >
                                        {`Copy from ${
                                            labels[
                                                tabKey === 'wechat_group'
                                                    ? 'miniprogram'
                                                    : 'wechat_group'
                                            ]
                                        }`}
                                    </Button>
                                }
                            >
                                {['wechat_group', 'miniprogram'].map(
                                    (channel) => (
                                        <TabPane
                                            forceRender
                                            tab={
                                                !!errors?.[
                                                    'wechat_group' === channel
                                                        ? 'wechatGroup'
                                                        : 'miniprogram'
                                                ] ? (
                                                    <Text type="danger">
                                                        {labels[channel]}
                                                    </Text>
                                                ) : (
                                                    labels[channel]
                                                )
                                            }
                                            key={channel}
                                            disabled={
                                                channelValue !== 'all' &&
                                                channelValue !== channel
                                            }
                                        >
                                            <div
                                                style={{
                                                    flex: 2,
                                                    display: 'flex',
                                                    gap: 8,
                                                    flexDirection: 'column',
                                                    backgroundColor: '#f0f2f5',
                                                }}
                                            >
                                                <Packs
                                                    type={typeValue}
                                                    {...getProps(
                                                        channel,
                                                        'packs'
                                                    )}
                                                />
                                                <Items
                                                    type={typeValue}
                                                    {...getProps(
                                                        channel,
                                                        'items'
                                                    )}
                                                />
                                                <Bourses
                                                    {...getProps(
                                                        channel,
                                                        'bourses'
                                                    )}
                                                />
                                                <Specials
                                                    {...getProps(
                                                        channel,
                                                        'zikoSpecials'
                                                    )}
                                                />
                                                <Lottery
                                                    {...getProps(
                                                        channel,
                                                        'lottery'
                                                    )}
                                                />
                                            </div>
                                        </TabPane>
                                    )
                                )}
                            </Tabs>

                            <Details
                                control={control}
                                setValue={setValue}
                                setFees={(fees) => setValue('fees', fees)}
                                isEdit={canEdit}
                                errors={errors}
                                clearErrors={clearErrors}
                            />
                        </div>

                        <Space>
                            <Button
                                loading={
                                    editMutation?.isLoading ||
                                    mutation.isLoading
                                }
                                type="primary"
                                htmlType="submit"
                            >
                                Save
                            </Button>
                            <Button
                                type="secondary"
                                onClick={() => {
                                    history.push('/offers')
                                }}
                            >
                                Cancel
                            </Button>
                        </Space>
                    </form>
                </FormProvider>
            </PageWrap>

            <Prompt
                when={isDeleted ? false : isPrompt}
                message={(location) => {
                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        okText: t('CONFIRM'),
                        cancelText: t('CANCEL'),
                        content: t('LEAVE_PAGE'),
                        onOk() {
                            setIsLeaving(true)
                            setIsPrompt(false)
                            setTimeout(() => {
                                history.push(location)
                            })
                        },
                    })
                    return false
                }}
            />
        </>
    )
}

export default CreateOfferNew
