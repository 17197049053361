import { useState, useContext, Fragment, useEffect, useRef } from 'react'
import {
    Tabs,
    Spin,
    Form,
    Space,
    Input,
    Button,
    Select,
    DatePicker,
    Cascader,
} from 'antd'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import { AppContext } from '../../context/AppContext'
import { downloadCSV } from '../../utils/utils'
import { getAreasFromParent } from '../../network/API'

import LoadingSelect from '../LoadingSelect'
import moment from 'moment'

const { TabPane } = Tabs
const { Option } = Select
const { RangePicker } = DatePicker

const TableFilter = ({
    config,
    onFiltersChange,
    elemId,
    onTabChange,
    dataList,
    selectedRowKeys,
    requestKeys,
    resetSelected,
}) => {
    const { token } = useContext(AppContext)
    const [filters, setFilters] = useState({})
    const [tabFilters, setTabFilters] = useState({})
    const [isHydrated, setIsHydrated] = useState(false)
    const queryClient = useQueryClient()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const sendLines = (request) => {
        request(selectedRowKeys, token.token).then((e) => {
            queryClient.invalidateQueries('getOffersServices')
            queryClient.invalidateQueries('getInvoices')
            resetSelected()
        })
    }

    const handleChange = (key, value, type, noRegex) => {
        // TODO: add support for input search
        let tempFilters = { ...filters }
        if (value) {
            tempFilters[key] = (noRegex || type === 'select' || type === 'multiselect')
                ? value
                : { $regex: String(value), $options: 'i' }
            if (type === 'multiselect') {
                if (value.indexOf('') > -1 || value.length === 0) {
                    delete tempFilters[key]
                }
            }
        } else {
            delete tempFilters[key]
        }
        setFilters(tempFilters)
        if (onFiltersChange) onFiltersChange(tempFilters)
    }

    const handleTabChange = (key, value, type) => {
        let tempTabFilters = { ...tabFilters }

        console.log('handleTabChange', key, value, type)
        if (value) {
            if (type === 'daterange') {
                if (value[0] && value[1]) {
                    tempTabFilters[key[0]] = new Date(
                        value[0].toDate().setHours(0, 0, 0, 0)
                    )

                    tempTabFilters[key[1]] = new Date(
                        value[1].toDate().setHours(23, 59, 59, 999)
                    )
                } else {
                    delete tempTabFilters[key[0]]
                    delete tempTabFilters[key[1]]
                }
            } else {
                tempTabFilters[key] = type === 'date' ? value.toDate() : value
            }
        } else {
            if (type === 'daterange') {
                delete tempTabFilters[key[0]]
                delete tempTabFilters[key[1]]
            }
            delete tempTabFilters[key]
        }

        console.log('handleTabChange', tempTabFilters)
        setTabFilters(tempTabFilters)
        onTabChange(tempTabFilters)
    }

    /**
     * Function to add query parameter to query string without trigger refresh
     * @param {object} filter content of the filter
     * @param {object} tabFilter  content of the tab filter
     */
    const syncQueryParameters = (filter, tabFilter) => {
        const queryParams = new URLSearchParams(location.search)

        filter
            ? queryParams.set('filter', JSON.stringify(filter))
            : queryParams.delete('filter')
        tabFilter
            ? queryParams.set('tabFilter', JSON.stringify(tabFilter))
            : queryParams.delete('tabFilter')

        // Create a new location object with updated query string
        const newLocation = {
            pathname: location.pathname,
            search:
                Array.from(queryParams.keys()).length > 0
                    ? `?${queryParams.toString()}`
                    : '',
        }

        // Sync the key-value object into query parameters without refreshing the page
        history.replace(newLocation)
    }

    const rehydrateFilters = () => {
        const queryParams = new URLSearchParams(location.search)
        // Restore query params to the local state if the local state is empty
        const localFilterIsEmpty = Object.keys(filters).length === 0
        const localTabFilterIsEmpty = Object.keys(tabFilters).length === 0
        if (
            (localFilterIsEmpty || localTabFilterIsEmpty) &&
            queryParams.toString()
        ) {
            try {
                const dryTabFilter = JSON.parse(queryParams.get('tabFilter'))
                const dryFilter = JSON.parse(queryParams.get('filter'))

                if (onTabChange) onTabChange(dryTabFilter)
                if (onFiltersChange) onFiltersChange(dryFilter)

                setTabFilters(dryTabFilter)
                setFilters(dryFilter)
            } catch (error) {
                console.error(error)
            }
        }
    }

    useEffect(() => {
        // Set router query according to tab filters
        console.debug('filters', filters)
        if (isHydrated && onTabChange && onFiltersChange)
            syncQueryParameters(filters, tabFilters)
    }, [filters, tabFilters, isHydrated])

    useEffect(() => {
        rehydrateFilters()
        setIsHydrated(true)
    }, [])

    useEffect(() => {
        if (!location.search) {
            console.log('============', location.search)
        }
    }, [location.search])

    return (
        <>
            <Form layout={'vertical'}>
                <Space
                    direction="vertical"
                    size={0}
                    style={{
                        display: 'flex',
                        padding: '0px 24px',
                        backgroundColor: '#fff',
                    }}
                >
                    <Space
                        style={{
                            display: 'flex',
                            paddingBottom: 15,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Space style={{ flex: 1 }}>
                            {config.fields?.map((cell) => (
                                <FieldType
                                    key={cell.key}
                                    field={cell}
                                    value={filters[cell.key]}
                                    tabValue={tabFilters}
                                    onChange={(value) =>
                                        handleChange(
                                            cell.key,
                                            value,
                                            cell.type,
                                            cell.noRegex
                                        )
                                    }
                                    def={cell.default}
                                    onTabChange={(value) =>
                                        handleTabChange(
                                            cell.key,
                                            value,
                                            cell.type
                                        )
                                    }
                                />
                            ))}
                        </Space>
                        <Space>
                            {config.actions?.map((btn, i) =>
                                btn.component ? (
                                    <Fragment key={i}>
                                        {btn.component(elemId, requestKeys)}
                                    </Fragment>
                                ) : (
                                    <Button
                                        key={i}
                                        disabled={!btn.isActive}
                                        onClick={() =>
                                            btn.action
                                                ? btn.action()
                                                : btn.lineAction
                                                ? sendLines(
                                                      btn.lineAction,
                                                      requestKeys
                                                  )
                                                : downloadCSV(
                                                      btn.csv.header,
                                                      dataList.map((line) =>
                                                          btn.csv.rowFilter(
                                                              line
                                                          )
                                                      ),
                                                      btn.csv.name
                                                  )
                                        }
                                        type="primary"
                                    >
                                        {t(btn.label)}
                                    </Button>
                                )
                            )}
                        </Space>
                    </Space>
                    {config.tabs && (
                        <Tabs
                            defaultActiveKey={config.tabs.default}
                            className="TableTabsFilters"
                            activeKey={tabFilters[config.tabs.key]}
                            value={tabFilters[config.tabs.key]}
                            onChange={(value) => {
                                handleTabChange(config.tabs.key, value, null)
                            }}
                        >
                            {config.tabs.options.map((tab) => (
                                <TabPane key={tab.key} tab={t(tab.label)} />
                            ))}
                        </Tabs>
                    )}
                </Space>
            </Form>
        </>
    )
}

const FieldType = ({ field, value, tabValue, onChange, onTabChange, def }) => {
    const { t } = useTranslation()
    const inputRef = useRef(null)

    useEffect(() => {
        onChange(def)
    }, [])

    useEffect(() => {
        if (inputRef?.current && field.autoFocus) {
            inputRef.current.focus({
                cursor: 'start',
            })
        }
    }, [])

    switch (field.type) {
        case 'selectSearch':
        case 'select':
            // check if select is static or dinamic
            return Array.isArray(field.options) ? (
                <Form.Item label={t(field.label)} style={{ margin: 0 }}>
                    <Select
                        // showSearch
                        // filterOption={(input, option) =>
                        //     option.children
                        //         .toLowerCase()
                        //         .indexOf(input.toLowerCase()) >= 0
                        // }
                        ref={inputRef}
                        value={value}
                        defaultValue={field.default}
                        onChange={(e) =>
                            field.isTab ? onTabChange(e) : onChange(e)
                        }
                        style={{ width: 190 }}
                    >
                        <Option key={''} value={''}>
                            {t('OFFERS_ALL')}
                        </Option>
                        {field.options.map((option) => (
                            <Option key={option.key} value={option.key}>
                                {t(option.label)}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (
                <LoadingSelect
                    speKey={field.key}
                    defaultValue={field.default}
                    value={value}
                    isSearch={field.type === 'selectSearch'}
                    label={t(field.label)}
                    request={field.options}
                    noAll={field.noAll}
                    autoFocus={field.autoFocus}
                    otherParams={field.otherParams}
                    onChange={(e) =>
                        field.isTab ? onTabChange(e) : onChange(e)
                    }
                />
            )
        case 'multiselect':
            // check if select is static or dinamic
            return Array.isArray(field.options) ? (
                <Form.Item label={t(field.label)} style={{ margin: 0 }}>
                    <Select
                        mode="multiple"
                        ref={inputRef}
                        value={value}
                        defaultValue={[field.default]}
                        onChange={(e) =>
                            field.isTab ? onTabChange(e) : onChange(e)
                        }
                        style={{ width: 285 }}
                    >
                        <Option key={''} value={''}>
                            {t('OFFERS_ALL')}
                        </Option>
                        {field.options.map((option) => (
                            <Option key={option.key} value={option.key}>
                                {t(option.label)}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (
                <LoadingSelect
                    multiple={true}
                    speKey={field.key}
                    defaultValue={field.default}
                    value={value}
                    // isSearch={field.type === 'selectSearch'}
                    isSearch
                    label={t(field.label)}
                    request={field.options}
                    noAll={field.noAll}
                    autoFocus={field.autoFocus}
                    otherParams={field.otherParams}
                    onChange={(e) =>
                        field.isTab ? onTabChange(e) : onChange(e)
                    }
                />
            )
        case 'date':
            return (
                <Form.Item label={t(field.label)} style={{ margin: 0 }}>
                    <DatePicker
                        value={
                            tabValue?.date ? moment(tabValue.date) : undefined
                        }
                        onChange={(e) => {
                            onTabChange(e)
                        }}
                        style={{ width: 190 }}
                    />
                </Form.Item>
            )
        case 'daterange':
            return (
                <Form.Item label={t(field.label)} style={{ margin: 0 }}>
                    <RangePicker
                        defaultValue={field.default}
                        value={
                            tabValue && tabValue.dateStart && tabValue.dateEnd
                                ? [
                                      moment(tabValue.dateStart),
                                      moment(tabValue.dateEnd),
                                  ]
                                : undefined
                        }
                        onCalendarChange={(e) => {
                            onTabChange(e)
                        }}
                        style={{ width: 285 }}
                    />
                </Form.Item>
            )
        case 'areas':
            return (
                <AreaLoader
                    field={field}
                    onChange={(e) => onChange(e)}
                    style={{ width: 190 }}
                />
            )
        default:
            // text default
            let label = ''
            if (Array.isArray(field.label)) {
                for (var i = 0; i < field.label.length; i++) {
                    label += t(field.label[i])
                    if (i < field.label.length - 1) {
                        label += ' / '
                    }
                }
            } else {
                label = t(field.label)
            }
            return (
                <Form.Item label={label} style={{ margin: 0 }}>
                    <Input
                        ref={inputRef}
                        allowClear
                        style={{ width: 190 }}
                        // value={value}
                        defaultValue={def}
                        value={value?.['$regex'] || value || ''}
                        onChange={(e) => {
                            field.isTab
                                ? onTabChange(e.target.value)
                                : onChange(e.target.value)
                        }}
                    />
                </Form.Item>
            )
    }
}

const AutocompleteSelect = ({ field }) => {
    const { token } = useContext(AppContext)
    const [text, setText] = useState(false)
    const { t } = useTranslation()
    const result = useQuery(
        [
            // format naming to find request in react query
            `get${
                field.key.charAt(0).toUpperCase() + field.key.slice(1)
            }Select`,
            { token: token.token },
        ],
        field.options
    )

    return (
        <Form.Item label={t(field.label)} style={{ margin: 0 }}>
            <Select
                labelInValue
                onSelect={() => {}}
                filterOption={false}
                // showSearch={true}
                // onSearch={(e) => console.log('e', e)}
                notFoundContent={
                    result.isLoading ? <Spin size="small" /> : null
                }
                // {...props}
                // options={result?.data}
            />
        </Form.Item>
    )
}

const AreaLoader = ({ field, onChange }) => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const [value, setValue] = useState([])
    const [inputValue, setInputValue] = useState([])
    const [options, setOptions] = useState([])

    const onHandleChange = (value, selectedOptions) => {
        setInputValue(selectedOptions.map((o) => o.label).join(', '))
        setValue(value)
        onChange(value[value.length - 1])
    }

    useEffect(() => {
        getAreasFromParent({
            queryKey: [
                'getAreasFromParent',
                {
                    token: token.token,
                    parentId: null,
                },
            ],
        })
            .then((data) => {
                setOptions(
                    data.map((area) => ({
                        isLeaf: false,
                        value: area.id,
                        label: area.name,
                    }))
                )
            })
            .catch()
    }, [])

    const loadData = (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1]
        targetOption.loading = true

        // selectedOptions.forEach((element) => console.log(element))

        getAreasFromParent({
            queryKey: [
                '',
                {
                    token: token.token,
                    parentId: targetOption.value,
                },
            ],
        }).then((value) => {
            targetOption.loading = false
            if (value.length) {
                targetOption.children = value.map((area) => ({
                    isLeaf: false,
                    value: area.id,
                    label: area.name,
                }))
            } else {
                targetOption.isLeaf = true
            }
            setOptions([...options])
        })
    }

    const displayRender = (labels, selectedOptions) =>
        labels.length ? <span>{t(labels[labels.length - 1])}</span> : null

    return (
        <Form.Item label={t(field.label)} style={{ margin: 0 }}>
            <Cascader
                options={options}
                loadData={loadData}
                placeholder=""
                onChange={onHandleChange}
                value={value}
                displayRender={displayRender}
                changeOnSelect
            />
        </Form.Item>
    )
}

export default TableFilter
