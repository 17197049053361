/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useReducer } from 'react'
import { Button, Space, Badge } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'
import i18next from 'i18next'
import { useQuery } from 'react-query'

import { AppContext } from '../context/AppContext'

import {
    formatNb,
    formatDate,
    noInfinite,
    downloadCSV,
    keepTwoDecimals,
} from '../utils/utils'
import { getOffers, getOfferAnalytics } from '../network/Offers'
import { getCommunities, getOrders, getOrderExcel } from '../network/API'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import TimeToEnd from '../components/TimeToEnd'
import DynamicStrings from '../components/DynamicStrings'

const OfferList = () => {
    const { user } = useContext(AppContext)

    const commuFilter = (key) =>
        user.role === 'admin' || user.role === 'super-admin'
            ? {}
            : { [key]: { $in: user.communities } }

    return (
        <PageWrap title="MENU_OFFERS">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getOffers'}
                request={getOffers}
                otherFilters={{
                    status: 'available',
                    ...commuFilter('community'),
                }}
                pageId={commuFilter('id')}
                pageSize={80}
                tableLayout={'fixed'}
            />
        </PageWrap>
    )
}

const filters = (history, lineSelect, loading, id) => ({
    fields: [
        {
            type: 'select',
            label: 'ROLES_COMMUNITY',
            default: '',
            key: 'community',
            options: getCommunities,
            otherParams: id,
        },
        {
            type: 'text',
            label: 'OFFERS_OFFER_NAME',
            key: 'name.en',
        },
        {
            type: 'text',
            label: 'OFFERS_OFFER_ID',
            key: 'offerID',
        },
        {
            type: 'date',
            label: 'OFFERS_OFFER_DATE',
            key: 'date',
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
        {
            type: 'select',
            label: 'CREATE_OFFER_CHANNEL',
            default: '',
            key: 'channel',
            options: [
                { key: 'miniprogram', label: 'miniprogram' },
                { key: 'wechat_group', label: 'wechat_group' },
            ],
        },
    ],
    tabs: {
        key: 'statusDate',
        default: '',
        options: [
            { key: 'live', label: 'OFFERS_LIVE' },
            { key: '', label: 'OFFERS_ALL' },
            { key: 'coming', label: 'OFFERS_COMING' },
            { key: 'over', label: 'OFFERS_OVER' },
        ],
    },
    actions: [
        {
            label: 'ROLES_CREATE_TEAM_MEMBER',
            component: () => <BtnCreate history={history} />,
        },
    ],
})

const BtnCreate = ({ history }) => {
    const { user } = useContext(AppContext)

    if (user.role === 'logistic') {
        return null
    }

    return (
        <Space direction="vertical">
            <Button
                type="primary"
                onClick={() => history.push('/offers/create')}
            >
                <DynamicStrings id={'MENU_CREATE_AN_OFFER'} />
            </Button>
            <Button
                type="primary"
                onClick={() => history.push('/offers/create-new')}
            >
                <DynamicStrings id={'NEW Create an offer'} />
            </Button>
        </Space>
    )
}

const columns = (goTo) => [
    {
        title: <DynamicStrings id="COMMUNITY_COMMUNITY" />,
        dataIndex: 'community',
        key: 'community',
        render: (community) =>
            community?.name ? <DynamicStrings id={community.name} /> : '',
        width: 95,
    },
    {
        title: <DynamicStrings id="CREATE_OFFER_CHANNEL" />,
        dataIndex: 'channel',
        key: 'channel',
        render: (channel) => (
            <DynamicStrings id={channel == 'all' ? 'OFFERS_ALL' : channel} />
        ),
        width: 100,
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_NAME" />,
        dataIndex: 'name',
        key: 'name',
        render: (name) => name[i18next.language],
        sorter: true,
        width: 110,
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_ID" />,
        dataIndex: 'offerID',
        key: 'offerID',
        width: 90,
    },
    {
        title: [<DynamicStrings id="OFFERS_OFFER_NAME" />, ' (internal)'],
        dataIndex: 'internalName',
        key: 'internalName',
        width: 110,
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_DATE" />,
        dataIndex: 'startingDate',
        key: 'startingDate',
        render: (date) => formatDate(date),
        sorter: true,
        width: 78,
    },
    {
        title: <DynamicStrings id="OFFERS_NB_OF_ORDERS" />,
        render: (e) =>
            e.orders || e.numberOfOrders
                ? formatNb(e.numberOfOrders) || formatNb(e.orders)
                : '-',
        // render: (e) => formatNb(e.morningOrderNum) || '-',
        key: 'numberOfOrders',
        // key: 'morningOrderNum',
        sorter: true,
        width: 70,
    },
    {
        // title: <DynamicStrings id="OFFERS_TARGET_INCOME_RATE" />,
        title: <DynamicStrings id="OFFERS_SALES_TARGET" suffix="(¥)" />,
        dataIndex: 'targetSales',
        render: (target) => (target ? formatNb(target) : '-'),
        key: 'targetSales',
        sorter: true,
        width: 85,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_TARGET_RATE" suffix="(%)" />,
        render: (e) => {
            // e.targetSales
            // e.actualIncome

            const target = e.targetSales || 0
            const actual = e.actualIncome || 0

            if (!actual || !target) return 0

            return `${keepTwoDecimals((actual / target) * 100, 0, 0)}%`
        },
        key: 'targetSales',
        // sorter: true,
        width: 60,
    },
    // {
    //     title: <DynamicStrings id="OFFERS_ACTUAL_INCOME" />,
    //     // dataIndex: 'actualIncomePack',
    //     render: (e) => formatNb(e.actualIncome) || 0,
    //     key: 'actualIncomePack',
    //     sorter: true,
    // },
    // {
    //     title: (
    //         <DynamicStrings id="OFFER_LIVE_TOTAL_SALES_DELIVERY" suffix="(¥)" />
    //     ),
    //     render: (e) => formatNb(e.actualIncome - e.actualIncomeDelivery) || 0,
    //     key: 'totalSales',
    //     sorter: true,
    // },
    {
        title: <DynamicStrings id="OFFER_ANALYTICS_TOTAL_SALES" suffix="(¥)" />, // Total actual income (Only paid, cancelled orders)
        render: (e) => formatNb(e.totalSales) || formatNb(e.actualIncome) || 0,
        // render: (e) => formatNb(e.morningTotalSales) || 0,
        className: 'bold',
        key: 'totalSales',
        // key: 'morningTotalSales',
        sorter: true,
        width: 80,
    },
    {
        title: <DynamicStrings id="OFFERS_PENDING_ORDERS" suffix="(¥)" />,
        render: (e) => formatNb(e.totalPending) || 0,
        key: 'totalPending',
        // sorter: true,
        width: 70,
    },
    {
        title: <DynamicStrings id="ANALYTICS_TOTAL_REFUND" suffix="(¥)" />,
        render: (e) => formatNb(e.totalRefund) || 0,
        key: 'totalRefund',
        width: 60,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_WECHAT_TOTAL" suffix="(¥)" />,
        render: (e) => formatNb(e.actualIncomeWechat) || 0,
        key: 'actualIncomeWechat',
        width: 66,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_ALIPAY_TOTAL" suffix="(¥)" />,
        render: (e) => formatNb(e.actualIncomeAlipay) || 0,
        key: 'actualIncomeAlipay',
        width: 57,
    },
    {
        title: <DynamicStrings id="MENU_CLAIMS" />,
        dataIndex: 'claims',
        // dataIndex: 'morningClaimNum',
        render: (claims) => (claims ? formatNb(claims) : '-'),
        key: 'claims',
        // key: 'morningClaimNum',
        // sorter: true,
        width: 61,
    },
    {
        title: <DynamicStrings id="OFFERS_TIME_LEFT" />,
        key: 'endingDate',
        render: (e) => {
            const nowDate = new Date()

            if (
                nowDate > new Date(e.startingDate) &&
                nowDate < new Date(e.endingDate)
            ) {
                return <TimeToEnd from={new Date(e.endingDate)} />
            }
            return '-'
        },
        sorter: true,
        width: 85,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        key: 'endingDate',

        render: (e) => {
            const nowDate = new Date()
            let status = {
                color: 'default',
                text: <DynamicStrings id="OFFERS_OVER" />,
            }

            if (nowDate < new Date(e.startingDate)) {
                status = {
                    color: 'warning',
                    text: <DynamicStrings id="OFFERS_COMING" />,
                }
            } else if (nowDate < new Date(e.endingDate)) {
                status = {
                    color: 'success',
                    text: <DynamicStrings id="OFFERS_LIVE" />,
                }
            }

            return <Badge status={status.color} text={status.text} />
        },
        // sorter: true,
        width: 80,
    },
    {
        key: 'action',
        // width: 1,
        width: 530,
        render: (e) => <Actions e={e} goTo={goTo} />,
    },
]

export default OfferList

const Actions = ({ e, goTo }) => {
    const { token } = useContext(AppContext)
    const communities = useQuery(
        ['getCommunitySelect', { token: token.token }],
        getCommunities
    )
    const nowDate = new Date()
    let status = 'over'

    if (nowDate < new Date(e.startingDate)) {
        status = 'coming'
    } else if (nowDate < new Date(e.endingDate)) {
        status = 'live'
    }
    return (
        <Space size="small" className="btnToTheEnd">
            <Space direction="vertical">
                <a
                    className="ant-dropdown-link"
                    // onClick={() => goTo(`/offers/${e.id}`)}
                    onClick={() =>
                        goTo({
                            pathname: `/offers/${e.id}`,
                            previousHash: window.location.hash,
                        })
                    }
                >
                    <DynamicStrings id="OFFERS_EDIT_OFFER" />
                </a>
                <a
                    className="ant-dropdown-link"
                    // onClick={() => goTo(`/offers/${e.id}`)}
                    onClick={() =>
                        goTo({
                            pathname: `/offers/${e.id}/edit-new`,
                            previousHash: window.location.hash,
                        })
                    }
                >
                    <DynamicStrings id="NEW Edit offer" />
                </a>
            </Space>

            {status === 'over' && (
                <>
                    <a
                        className="ant-dropdown-link"
                        onClick={() => {
                            getOrderExcel(e.id, token.token).then((file) => {
                                const url = window.URL.createObjectURL(
                                    new Blob([file])
                                )
                                const link = document.createElement('a')
                                link.href = url
                                link.setAttribute(
                                    'download',
                                    `${e.offerID}_recap.xlsx`
                                )
                                document.body.appendChild(link)
                                link.click()
                            })
                        }}
                    >
                        <Space size="small">
                            <DownloadOutlined />
                            <DynamicStrings id="MENU_ORDERS" />
                        </Space>
                    </a>
                    <DownloadStatsCSVComplete
                        offer={e}
                        cellar={
                            communities?.data?.list?.find(
                                (c) => c.name.toLowerCase() === 'cellar'
                            )?.id
                        }
                    />
                </>
            )}
            <AnalyticsButton
                status={status}
                onClick={() =>
                    goTo({
                        pathname: `/offers/${e.id}/orders`,
                        defaultTab: 'analytics',
                    })
                }
            />

            <OrdersButton pathname={`/offers/${e.id}/orders`} />
            <Button
                // onClick={() => goTo(`/offers/${e.id}/delivery`)}
                onClick={() =>
                    goTo({
                        pathname: `/offers/${e.id}/delivery`,
                        previousHash: window.location.hash,
                    })
                }
                type="primary"
            >
                <DynamicStrings id="OFFERS_DELIVERY" />
            </Button>
        </Space>
    )
}

const OrdersButton = ({ pathname }) => {
    const { user } = useContext(AppContext)

    if (
        user.role !== 'super-admin' &&
        user.role !== 'admin' &&
        user.role !== 'product-manager' &&
        user.role !== 'community-manager' &&
        user.role !== 'customer-hero' &&
        user.role !== 'logistic'
    ) {
        return null
    }

    return (
        <Button href={pathname} type="primary">
            <DynamicStrings id="OFFER_LIVE" />
        </Button>
    )
}
//TO DO clean comma to dots
const AnalyticsButton = ({ status, onClick }) => {
    const { user } = useContext(AppContext)

    if (
        (user.role !== 'super-admin' &&
            user.role !== 'admin' &&
            user.role !== 'product-manager' &&
            user.role !== 'community-manager' &&
            user.role !== 'finance') ||
        status === 'coming'
    ) {
        return null
    }

    return (
        <Button onClick={onClick}>
            <DynamicStrings id="MENU_ANALYTICS" />
        </Button>
    )
}

const DownloadOfferCSVComplete = ({ offer }) => {
    const { token } = useContext(AppContext)
    const keyOrdersQuery = [
        'getWSOrders',
        {
            token: token.token,
            filter: {
                offer: offer.id,
            },
            sort: ['orderDate', 'DESC'],
            unlimited: true,
        },
    ]

    const handleDownload = (orders) => {
        let csvContent = 'data:text/csv;charset=utf-8,'

        let nbTickets = 0
        let nbOrders = 0
        let totalDelivery = 0
        let totalQty = 0
        let totalPrice = 0

        csvContent += `Supplier,${offer.supplier.name}\r\n`
        csvContent += '\r\n'

        csvContent += `Area,Rule,Qty,Fee\r\n`
        for (const fee of offer?.fees) {
            for (let i = 0; i < fee?.rules?.length; i++) {
                csvContent += `${i === 0 ? fee?.area?.name : ''},${fee.rules[
                    i
                ].type.replaceAll('_', ' ')},${
                    fee.rules[i].quantity ? fee.rules[i].quantity : ''
                },${
                    fee.rules[i].fee &&
                    fee.rules[i].type !== 'free_after_value' &&
                    fee.rules[i].type !== 'free_after_quantity'
                        ? fee.rules[i].fee
                        : ''
                }\r\n`
            }
        }

        csvContent += '\r\n'

        for (const order of orders) {
            if (order.status !== 'discontinued') {
                nbOrders += 1
                nbTickets += order.actualAmount
                totalDelivery += order.deliveryFee
                totalPrice += order.actualAmount
                if (order.packs) {
                    for (const pack of order.packs) {
                        totalQty += pack.amount
                    }
                }
                if (order.singleItems) {
                    for (const item of order.singleItems) {
                        totalQty += item.amount
                    }
                }
            }
        }
        csvContent += `Total Sales (with delivery),${noInfinite(
            totalPrice
        )}\r\n`
        csvContent += `Total Sales (without delivery),${noInfinite(
            totalPrice - totalDelivery
        )}\r\n`
        csvContent += `Total Quantity 总数量,${totalQty}\r\n`
        csvContent += `Total Delivery Fee 总运费,${totalDelivery}\r\n`
        csvContent += `Average ticket 平均值",${noInfinite(
            nbTickets / nbOrders
        )}\r\n`
        csvContent += `Zikoland Comission,${noInfinite(
            (totalPrice - totalDelivery) * (offer?.commissionRate / 100)
        )}\r\n`
        csvContent += `To pay Supplier,${noInfinite(
            totalPrice -
                (totalPrice - totalDelivery) * (offer?.commissionRate / 100)
        )}\r\n`
        csvContent += '\r\n'

        const offerHeader = [
            'Group', //1
            'Pack Description 每包明细', //2
            'Price per pack 每包价格', //3
            'Quantity Available 可用数量', //4
            'Quantity Sold 卖出数量', //5
            'Quantity Left 剩余数量', //6
            '% of total Sale', //7
            'Potential Ca', //8
            'Actual Ca', //9
        ]
        offerHeader.join(',')
        csvContent += offerHeader + '\r\n'

        let totalPotentialCA = 0
        offer.wechatGroup?.packs?.forEach(function (pack) {
            let row = []
            row.push(pack.shortName) //1
            row.push(pack.name.en) //2
            row.push(pack.price) //3
            row.push(pack.stock) //4
            row.push(pack.stock - pack.actualStock) //5
            row.push(pack.actualStock) //6
            row.push(
                noInfinite(
                    (pack.price * (pack.stock - pack.actualStock)) /
                        (totalPrice - totalDelivery)
                )
            ) //7
            totalPotentialCA += pack.price * pack.stock
            row.push(pack.price * pack.stock) //8

            // totalPotentialCA += pack.price * (pack.stock - pack.actualStock)
            row.push(pack.price * (pack.stock - pack.actualStock)) //9

            row.join(',')
            csvContent += row + '\r\n'
        })

        offer.wechatGroup?.items?.forEach(function (pack) {
            let row = []
            row.push(pack.shortName) //1
            row.push(pack?.product?.name?.en) //2
            row.push(pack.price) //3
            row.push(pack.stock) //4
            row.push(pack.stock - pack.actualStock) //5
            row.push(pack.actualStock) //6
            row.push(
                noInfinite(
                    (pack.price * (pack.stock - pack.actualStock)) /
                        (totalPrice - totalDelivery)
                )
            ) //7
            totalPotentialCA += pack.price * pack.stock
            row.push(pack.price * pack.stock) //8

            // totalPotentialCA += pack.price * (pack.stock - pack.actualStock)
            row.push(pack.price * (pack.stock - pack.actualStock)) //9

            row.join(',')
            csvContent += row + '\r\n'
        })

        csvContent += '\r\n'

        const offerHeaderMP = [
            'Miniprogram', //1
            'Pack Description 每包明细', //2
            'Price per pack 每包价格', //3
            'Quantity Available 可用数量', //4
            'Quantity Sold 卖出数量', //5
            'Quantity Left 剩余数量', //6
            '% of total Sale', //7
            'Potential Ca', //8
            'Actual Ca', //9
        ]
        offerHeaderMP.join(',')
        csvContent += offerHeaderMP + '\r\n'

        let totalPotentialCaMP = 0
        offer.miniprogram?.packs?.forEach(function (pack) {
            let row = []
            row.push(pack.shortName) //1
            row.push(pack.name.en) //2
            row.push(pack.price) //3
            row.push(pack.stock) //4
            row.push(pack.stock - pack.actualStock) //5
            row.push(pack.actualStock) //6
            row.push(
                noInfinite(
                    (pack.price * (pack.stock - pack.actualStock)) /
                        (totalPrice - totalDelivery)
                )
            ) //7
            totalPotentialCaMP += pack.price * pack.stock
            row.push(pack.price * pack.stock) //8

            // totalPotentialCA += pack.price * (pack.stock - pack.actualStock)
            row.push(pack.price * (pack.stock - pack.actualStock)) //9

            row.join(',')
            csvContent += row + '\r\n'
        })

        offer.miniprogram?.items?.forEach(function (pack) {
            let row = []
            row.push(pack.shortName) //1
            row.push(pack?.product?.name?.en) //2
            row.push(pack.price) //3
            row.push(pack.stock) //4
            row.push(pack.stock - pack.actualStock) //5
            row.push(pack.actualStock) //6
            row.push(
                noInfinite(
                    (pack.price * (pack.stock - pack.actualStock)) /
                        (totalPrice - totalDelivery)
                )
            ) //7
            totalPotentialCaMP += pack.price * pack.stock
            row.push(pack.price * pack.stock) //8

            // totalPotentialCA += pack.price * (pack.stock - pack.actualStock)
            row.push(pack.price * (pack.stock - pack.actualStock)) //9

            row.join(',')
            csvContent += row + '\r\n'
        })

        csvContent += '\r\n'

        csvContent += `Total Potential Revenue,${
            totalPotentialCA + totalPotentialCaMP
        }\r\n\r\n`
        csvContent += `% Target (Sold out),${
            noInfinite(
                ((totalPrice - totalDelivery) /
                    (totalPotentialCA + totalPotentialCaMP)) *
                    100
            ) + '%'
        }\r\n\r\n`

        const availPacks = []
        if (offer.wechatGroup?.packs)
            for (const pack of offer.wechatGroup?.packs) {
                availPacks.push(`${pack.shortName} (${pack.actualStock})`)
            }

        if (offer.wechatGroup?.items)
            for (const item of offer.wechatGroup?.items) {
                availPacks.push(`${item.shortName} (${item.actualStock})`)
            }

        const availPacksMP = []
        if (offer.miniprogram?.packs)
            for (const pack of offer.miniprogram?.packs) {
                availPacksMP.push(`${pack.shortName} (${pack.actualStock})`)
            }

        if (offer.miniprogram?.items)
            for (const item of offer.miniprogram?.items) {
                availPacksMP.push(`${item.shortName} (${item.actualStock})`)
            }

        const petTitle =
            offer.community.name === 'Pet'
                ? [
                      'Pets typology', //4
                      'Pets name', //5
                  ]
                : []

        const ordersHeader = [
            'Order ID', //1
            'Special Comment', //2
            'Client Name 客户名称', //3

            ...petTitle,
            'ziko group', //6
            ...availPacks,

            'Lottery gifts', // 11
            'Ziko specials', // 12
            'Used voucher', // 13
            'Tickets', // 14

            'Delivery Fee 运输费', //15
            'Delivery Time 送货时间', //16
            'Total Price 总价', //17
            'Payment Status 付款状态', //18
            'City 城市', //19
            'Address 地址', //20
            'Phone number 电话', //21
            'Need fapiao', // 22
        ]
        const ordersHeaderMP = [
            'Order ID', //1
            'Special Comment', //2
            'Client Name 客户名称', //3

            ...petTitle,
            'ziko group', //6
            ...availPacksMP,

            'Lottery gifts', // 11
            'Ziko specials', // 12
            'Used voucher', // 13
            'Tickets', // 14

            'Delivery Fee 运输费', //15
            'Delivery Time 送货时间', //16
            'Total Price 总价', //17
            'Payment Status 付款状态', //18
            'City 城市', //19
            'Address 地址', //20
            'Phone number 电话', //21
            'Need fapiao', // 22
        ]

        const ordersGR = []
        const ordersMP = []

        orders.forEach(function (order) {
            if (order.status === 'discontinued') return
            let row = []
            row.push(order.orderID) //1
            row.push(
                order.comment
                    ?.toString()
                    ?.replaceAll(',', '.')
                    ?.replaceAll(/(\r\n|\n|\r)/gm, '')
            ) //2
            row.push(
                order.customer
                    ? order.customer.name
                    : order.customerDaily?.customerName
            ) //3

            if (offer.community.name === 'Pet') {
                row.push(order.customer?.pets.map((e) => e.type).join('. ')) //4
                row.push(order.customer?.pets.map((e) => e.name).join('. ')) //5
            }
            row.push(order.groupNumber) //6

            if (order.channel === 'miniprogram') {
                if (offer.miniprogram?.packs)
                    for (const pack of offer.miniprogram?.packs) {
                        const exist = order.packs?.find(
                            (p) => p.shortName === pack.shortName
                        )
                        row.push(exist ? exist.amount : '')
                    }

                if (offer.miniprogram?.items)
                    for (const item of offer.miniprogram?.items) {
                        const exist = order.singleItems?.find(
                            (p) => p.shortName === item.shortName
                        )
                        row.push(exist ? exist.amount : '')
                    }
            } else {
                if (offer.wechatGroup?.packs)
                    for (const pack of offer.wechatGroup?.packs) {
                        const exist = order.packs?.find(
                            (p) => p.shortName === pack.shortName
                        )
                        row.push(exist ? exist.amount : '')
                    }

                if (offer.wechatGroup?.items)
                    for (const item of offer.wechatGroup?.items) {
                        const exist = order.singleItems?.find(
                            (p) => p.shortName === item.shortName
                        )
                        row.push(exist ? exist.amount : '')
                    }
            }

            row.push(
                order.gifts
                    .filter((e) => e.origin === 'lottery')
                    .map((gift) => {
                        switch (gift.type) {
                            case 'custom':
                                return gift.custom?.en
                            case 'free_delivery':
                                return 'Free delivery'
                            case 'pack':
                                return `Pack ${gift.pack}`
                            case 'add_on':
                                return `Add-on ${gift.singleItem}`
                            case 'discount':
                                return `Discount (${gift.discountAmount} %)`
                            case 'voucher':
                                return `Voucher (${gift.voucherValue} ¥)`
                            default:
                                return gift.type
                        }
                    })
                    .join('. ')
            )
            row.push(
                order.gifts
                    .filter((e) => e.origin === 'ziko_special')
                    .map((gift) => {
                        switch (gift.type) {
                            case 'custom':
                                return gift.custom?.en
                            case 'free_delivery':
                                return 'Free delivery'
                            case 'pack':
                                return `Pack ${gift.pack}`
                            case 'add_on':
                                return `Add-on ${gift.singleItem}`
                            case 'discount':
                                return `Discount (${gift.discountAmount} %)`
                            case 'voucher':
                                return `Voucher (${gift.voucherValue} ¥)`
                            default:
                                return gift.type
                        }
                    })
                    .join('. ')
            )

            row.push(order.vouchers.map((e) => e.amount).join('. ')) // 13
            row.push(order.ticketAmount) // 14

            row.push(
                order.deliveryFee
                    ?.toString()
                    ?.replaceAll(',', '.')
                    ?.replaceAll(/(\r\n|\n|\r)/gm, '')
            ) //15
            row.push(
                order.deliveryDate && moment(order.deliveryDate).isValid()
                    ? moment(order.deliveryDate).format('DD/MM/YYYY')
                    : order.deliveryDate
                          ?.toString()
                          ?.replaceAll(',', '.')
                          ?.replaceAll(/(\r\n|\n|\r)/gm, '')
            ) //16
            row.push(noInfinite(order.actualAmount)) //17
            row.push(order.paymentStatus) //18
            row.push(
                order.customerAddress.city
                    ?.toString()
                    ?.replaceAll(',', '.')
                    ?.replaceAll(/(\r\n|\n|\r)/gm, '')
            ) //19
            row.push(
                `${order.customerAddress?.type
                    ?.toString()
                    ?.replaceAll(',', '.')
                    ?.replaceAll(/(\r\n|\n|\r)/gm, '')} ${
                    order.customerAddress?.detailedAddress
                        ? order.customerAddress?.detailedAddress
                              ?.toString()
                              ?.replaceAll(',', '.')
                              ?.replaceAll(/(\r\n|\n|\r)/gm, '')
                        : ''
                } ${(order.customerAddress?.zipCode
                    ? order.customerAddress?.zipCode
                    : ''
                )
                    ?.toString()
                    ?.replaceAll(',', '.')
                    ?.replaceAll(/(\r\n|\n|\r)/gm, '')} ${(order
                    ?.customerAddress?.city
                    ? order.customerAddress.city
                    : ''
                )
                    ?.toString()
                    ?.replaceAll(',', '.')
                    ?.replaceAll(/(\r\n|\n|\r)/gm, '')}`
            ) //20
            row.push(order.customerAddress.phone) //21
            row.push(order.fapiao ? 'Yes' : 'No') // 22

            row.join(',').replaceAll(' ', ' ')
            // csvContent += row + '\r\n'

            if (order.channel === 'miniprogram') {
                ordersMP.push(row)
            } else {
                ordersGR.push(row.join())
            }
        })

        csvContent += 'Miniprogram orders\r\n'
        ordersHeaderMP.join(',')
        csvContent += ordersHeaderMP + '\r\n'
        csvContent += ordersMP.join('\r\n')
        csvContent += '\r\n\r\n'

        csvContent += 'Group orders\r\n'
        ordersHeader.join(',')
        csvContent += ordersHeader + '\r\n'
        csvContent += ordersGR.join('\r\n')

        // csvContent += ordersHeader + '\r\n'
        // csvContent += ordersHeaderMP + '\r\n'

        var hiddenElement = document.createElement('a')
        hiddenElement.href = '' + encodeURI(csvContent)
        hiddenElement.target = '_blank'

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = `Offer ${offer.offerID} Orders.csv`
        hiddenElement.click()
    }

    return (
        <a
            className="ant-dropdown-link"
            onClick={(ev) => {
                ev.preventDefault()
                getOrders({ queryKey: keyOrdersQuery })
                    .then((orders) => handleDownload(orders.list))
                    .catch()
            }}
        >
            <Space size="small">
                <DownloadOutlined />
                <DynamicStrings id="MENU_ORDERS" />
            </Space>
        </a>
    )
}

const DownloadStatsCSVComplete = ({ offer, cellar }) => {
    const { token } = useContext(AppContext)
    const keyStatsQuery = [
        'getOfferAnalytics',
        {
            offerId: offer?.id,
            token: token.token,
        },
    ]

    const handleDownload = (analytics) => {
        console.log(
            'analytics',
            analytics,
            analytics?.orderByCity.map((stat) => [
                stat._id || '-',
                stat.totalAmount,
            ])
        )

        let longestPack = []
        if (offer.channel === 'all') {
            let oldMP = []
            if (offer.miniprogram?.packs?.length) {
                oldMP.concat(offer.miniprogram?.packs)
            }
            if (offer.miniprogram?.items?.length) {
                oldMP.concat(offer.miniprogram?.items)
            }

            let oldWX = []
            if (offer.wechatGroup?.packs?.length) {
                oldMP.concat(offer.wechatGroup?.packs)
            }
            if (offer.wechatGroup?.items?.length) {
                oldMP.concat(offer.wechatGroup?.items)
            }

            longestPack = oldMP.length > oldWX.length ? oldMP : oldWX
        } else if (offer.channel === 'miniprogram') {
            longestPack = []
            if (offer.miniprogram?.packs?.length) {
                longestPack.concat(offer.miniprogram?.packs)
            }
            if (offer.miniprogram?.items?.length) {
                longestPack.concat(offer.miniprogram?.items)
            }
        } else {
            let longestPack = []
            if (offer.wechatGroup?.packs?.length) {
                longestPack.concat(offer.wechatGroup?.packs)
            }
            if (offer.wechatGroup?.items?.length) {
                longestPack.concat(offer.wechatGroup?.items)
            }
        }

        let prodValue = {}
        Object.keys(analytics?.orderByProductChannel.wechat_group).map(
            (key) => {
                const price =
                    offer.wechatGroup.packs?.find((e) => e.shortName === key)
                        ?.price ||
                    offer.wechatGroup.items?.find((e) => e.shortName === key)
                        ?.price
                const amount =
                    analytics?.orderByProductChannel.wechat_group[key]
                if (prodValue[key]) {
                    prodValue[key].Volume += amount
                    prodValue[key].Value += amount * price
                } else {
                    prodValue[key] = {
                        Volume: amount,
                        Value: amount * price,
                    }
                }
            }
        )
        Object.keys(analytics?.orderByProductChannel.miniprogram).map((key) => {
            const price =
                offer.miniprogram.packs?.find((e) => e.shortName === key)
                    ?.price ||
                offer.miniprogram.items?.find((e) => e.shortName === key)?.price
            const amount = analytics?.orderByProductChannel.miniprogram[key]
            if (prodValue[key]) {
                prodValue[key].Volume += amount
                prodValue[key].Value += amount * price
            } else {
                prodValue[key] = {
                    Volume: amount,
                    Value: amount * price,
                }
            }
        })

        const compPackWX = offer.wechatGroup
            ? offer.wechatGroup?.packs?.map((pack) =>
                  pack?.products
                      ?.map(
                          (e) =>
                              `${e.quantity}${
                                  e.weight ? 'x' + e.weight + 'g' : ''
                              } ${e.product?.name?.en}`
                      )
                      .join(', ')
              )
            : []
        const compItemsWX = offer.wechatGroup
            ? offer.wechatGroup?.items?.map(
                  (e) =>
                      `${e.quantity}${e.weight ? 'x' + e.weight + 'g' : ''} ${
                          e.product?.name?.en
                      }`
              )
            : []
        const compPackMP = offer.miniprogram
            ? offer.miniprogram?.packs?.map((pack) =>
                  pack?.products
                      ?.map(
                          (e) =>
                              `${e.quantity}${
                                  e.weight ? 'x' + e.weight + 'g' : ''
                              } ${e.product?.name?.en}`
                      )
                      .join(', ')
              )
            : []
        const compItemsMP = offer.miniprogram
            ? offer.miniprogram?.items?.map(
                  (e) =>
                      `${e.quantity}${e.weight ? 'x' + e.weight + 'g' : ''} ${
                          e.product?.name?.en
                      }`
              )
            : []
        console.log(
            'uuuu',
            offer,
            ...(offer.wechatGroup?.packs?.map((pack) => pack.shortName) || []),
            offer.wechatGroup?.items?.map((pack) => pack.shortName) || []
        )

        downloadCSV(
            ['sales'],
            [
                [
                    'Total Sales',
                    'Total Sales (W/o Delivery)',
                    'Average Spending',
                    'Total Zikoland Service Fee',
                    'Total Supplier Share',
                    offer?.community?.id === cellar ? 'Gross Marging' : '',
                ],
                [
                    analytics?.orderTotalAmount?.length &&
                    analytics?.orderTotalAmount[0]?.totalAmount
                        ? formatNb(analytics?.orderTotalAmount[0]?.totalAmount)
                        : '-',
                    analytics?.orderTotalAmount?.length &&
                    analytics?.orderTotalAmount[0]?.totalAmount &&
                    analytics?.orderTotalAmount[0]?.totalAmountDelivery
                        ? formatNb(
                              analytics?.orderTotalAmount[0]?.totalAmount -
                                  analytics?.orderTotalAmount[0]
                                      ?.totalAmountDelivery
                          )
                        : '-',
                    analytics?.orderTotalAmount?.length &&
                    analytics?.orderTotalAmount[0]?.averageSpending
                        ? formatNb(
                              analytics?.orderTotalAmount[0]?.averageSpending
                          )
                        : '-',
                    analytics?.orderTotalAmount?.length &&
                    analytics?.offer?.commissionRate &&
                    analytics?.orderTotalAmount[0]?.totalAmount &&
                    analytics?.orderTotalAmount[0]?.totalAmountDelivery
                        ? formatNb(
                              (analytics?.orderTotalAmount[0]?.totalAmount -
                                  analytics?.orderTotalAmount[0]
                                      ?.totalAmountDelivery) *
                                  (analytics?.offer?.commissionRate / 100)
                          )
                        : '-',
                    analytics?.orderTotalAmount?.length &&
                    analytics?.offer?.commissionRate &&
                    analytics?.orderTotalAmount[0]?.totalAmount &&
                    analytics?.orderTotalAmount[0]?.totalAmountDelivery
                        ? formatNb(
                              analytics?.orderTotalAmount[0]?.totalAmount -
                                  (analytics?.orderTotalAmount[0]?.totalAmount -
                                      analytics?.orderTotalAmount[0]
                                          ?.totalAmountDelivery) *
                                      (analytics?.offer?.commissionRate / 100)
                          )
                        : '-',
                    offer?.community?.id === cellar
                        ? analytics?.grossMargin
                            ? formatNb(analytics?.grossMargin)
                            : '-'
                        : '',
                ],
                [],
                ['Orders'],
                [
                    'Reorder Rate',
                    'Customer Satisfaction',
                    'Total Orders',
                    'New Customers',
                    'Total Claims',
                ],
                [
                    analytics?.orderNumber && analytics?.ghostStats?.orderNumber
                        ? noInfinite(
                              (analytics?.orderNumber /
                                  analytics?.ghostStats?.orderNumber) *
                                  100
                          ) + '%'
                        : '-',
                    analytics?.claims && analytics?.orderNumber
                        ? noInfinite(
                              100 -
                                  (100 * analytics?.claims) /
                                      analytics?.orderNumber
                          ) + '%'
                        : '-',
                    analytics?.orderNumber || '-',
                    analytics?.newCustomerCount || '-',
                    analytics?.claims || '-',
                ],
                [],
                ['Comparison'],
                ['Sales Channel Ratio'],
                ['Channel', 'Amount'],
                ...analytics?.orderByChannel.map((stat) => [
                    stat._id,
                    formatNb(stat.totalAmount),
                ]),
                [],
                ['Sales per City'],
                ['City', 'Amount'],
                ...analytics?.orderByCity.map((stat) => [
                    stat._id || 'Others',
                    formatNb(stat.totalAmount),
                ]),
                [],
                ['Sales per Group N'],
                ['Group N', 'Volume', 'Value'],
                ...Object.keys(analytics?.orderByGroups)
                    .filter((e) => e !== 'undefined')
                    .map((key) => [
                        key,
                        analytics?.orderByGroups[key],
                        formatNb(analytics?.orderByGroupsValue[key]),
                    ]),
                [],
                ['Comparison'],
                ['Item', ...longestPack.map((e) => e.shortName)],
                ['desc WX', ...compPackWX, ...compItemsWX],
                ['desc MP', ...compPackMP, ...compItemsMP],
                [],
                ['Products Revenue'],
                ['Pack/Item', 'Potential', 'Actual'],
                ...(offer.wechatGroup?.packs?.map((pack) => [
                    pack.shortName,
                    formatNb(pack.stock * pack.price),
                    formatNb((pack.stock - pack.actualStock) * pack.price),
                ]) || []),
                ...(offer.wechatGroup?.items?.map((pack) => [
                    pack.shortName,
                    formatNb(pack.stock * pack.price),
                    formatNb((pack.stock - pack.actualStock) * pack.price),
                ]) || []),
                [],
                ...(offer.miniprogram?.packs?.map((pack) => [
                    pack.shortName,
                    formatNb(pack.stock * pack.price),
                    formatNb((pack.stock - pack.actualStock) * pack.price),
                ]) || []),
                ...(offer.miniprogram?.items?.map((pack) => [
                    pack.shortName,
                    formatNb(pack.stock * pack.price),
                    formatNb((pack.stock - pack.actualStock) * pack.price),
                ]) || []),
                [''],
                ['Products Volume/Value'],
                ['Pack/Item', 'Volume', 'Value'],
                ...Object.keys(prodValue).map((key) => [
                    key,
                    prodValue[key].Volume,
                    prodValue[key].Value,
                ]),
                [''],
                ['Products Sales Channel Ratio'],
                ['Pack/Item', 'Volume', 'Value'],
                ...Object.keys(
                    analytics?.orderByProductChannel.wechat_group
                ).map((key) => [
                    key,
                    analytics?.orderByProductChannel.wechat_group[key],
                    analytics?.orderByProductChannel.wechat_group[key] *
                        (offer?.wechatGroup?.packs?.find(
                            (e) => e.shortName === key
                        )?.price ||
                            offer?.wechatGroup?.items?.find(
                                (e) => e.shortName === key
                            )?.price),
                ]),
            ],
            'Analytics ' + offer.offerID
        )
    }

    return (
        <a
            className="ant-dropdown-link"
            onClick={(ev) => {
                ev.preventDefault()
                getOfferAnalytics({ queryKey: keyStatsQuery })
                    .then((orders) => handleDownload(orders))
                    .catch()
            }}
        >
            <Space size="small">
                <DownloadOutlined />
                <DynamicStrings id="MENU_ANALYTICS" />
            </Space>
        </a>
    )
}

function wxOrderReducer(state, action) {
    let tmpOrders = [...state]

    switch (action.type) {
        case 'init':
            return action.payload
        case 'line-edit':
            const lineIndex = state.findIndex((e) => e.id === action.payload.id)
            tmpOrders[lineIndex] = action.payload
            return tmpOrders
        case 'ws-edit':
            const wsIndex = state.findIndex((e) => e.id === action.payload.id)
            tmpOrders[wsIndex] = action.payload
            return tmpOrders
        case 'ws-add':
            const newWsIndex = state.findIndex(
                (e) => e.id === action.payload.id
            )
            if (newWsIndex > -1) {
                tmpOrders[newWsIndex] = action.payload
                return tmpOrders
            }
            return [action.payload, ...state]
        case 'line-add':
            return [action.payload, ...state]
        default:
            break
    }
}

function mpOrderReducer(state, action) {
    let tmpOrders = [...state]

    switch (action.type) {
        case 'init':
            return action.payload
        case 'ws-edit':
            const wsIndex = state.findIndex((e) => e.id === action.payload.id)
            tmpOrders[wsIndex] = action.payload
            return tmpOrders
        case 'ws-add':
            return [action.payload, ...state]
        default:
            break
    }
}

const OrdersToStats = (offer) => {
    // const target = offer.targetSales
    let target
    if (offer.targetSales && offer.targetSales !== null) {
        target = offer.targetSales
    }

    const { token } = useContext(AppContext)
    const [wxOrders, dispatchWXOrders] = useReducer(wxOrderReducer, [])
    const [mpOrders, dispatchMPOrders] = useReducer(mpOrderReducer, [])

    const keyOrdersQuery = (channel) => [
        'getWSOrders',
        {
            token: token.token,
            filter: {
                channel,
                offer: offer.id,
            },
            sort: ['orderDate', 'DESC'],
            unlimited: true,
            vouchers: true,
        },
    ]

    useEffect(() => {
        getOrders({ queryKey: keyOrdersQuery('wechat_group') }).then(
            (orders) => {
                dispatchWXOrders({ type: 'init', payload: orders.list })
            }
        )

        getOrders({ queryKey: keyOrdersQuery('miniprogram') }).then(
            (orders) => {
                dispatchMPOrders({ type: 'init', payload: orders.list })
            }
        )
    }, [])

    let stats = {
        targetRate: '-',
        totalSales: '-',
        avgSpending: '-',
    }

    let totalMP = 0
    let totalWX = 0
    let totalOrders = 0
    let totalDelivery = 0
    for (const order of wxOrders) {
        if (order.status !== 'discontinued') {
            let totalDiscount = 0
            for (const gift of order.gifts) {
                if (gift.type === 'discount')
                    totalDiscount += gift.discountAmount
                if (totalDiscount > 100) break
            }
            if (totalDiscount > 100) totalDiscount = 100

            totalOrders++
            // totalWX += order.totalAmount
            totalWX += order.actualAmount
            totalDelivery += order.deliveryFee * (1 - totalDiscount / 100)
        }
    }
    for (const order of mpOrders) {
        if (order.status !== 'discontinued') {
            let totalDiscount = 0
            for (const gift of order.gifts) {
                if (gift.type === 'discount')
                    totalDiscount += gift.discountAmount
                if (totalDiscount > 100) break
            }
            if (totalDiscount > 100) totalDiscount = 100

            totalOrders++
            // totalMP += order.totalAmount
            totalMP += order.actualAmount
            totalDelivery += order.deliveryFee * (1 - totalDiscount / 100)
        }
    }

    stats.totalSales = formatNb(totalMP + totalWX - totalDelivery) || '0'
    stats.avgSpending = formatNb((totalMP + totalWX) / totalOrders) || '0'
    stats.targetRate =
        formatNb(((totalMP + totalWX - totalDelivery) * 100) / target) || '0'

    return stats
}
