import { useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import {
    Space,
    Menu,
    Dropdown,
    Badge,
    Select,
    Popover,
    message,
    Modal,
    Typography,
} from 'antd'
import {
    MoreOutlined,
    ExclamationCircleOutlined,
    EnterOutlined,
} from '@ant-design/icons'

import { downloadCSV, formatDate, formatNb } from '../utils/utils'
import {
    getRefunds,
    getRefundsAnalytics,
    EditRefund,
    getSingleRefund,
} from '../network/Refunds'
import { AppContext } from 'src/context/AppContext'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'

const { Title, Text } = Typography

let userRole = ''
let unreadedRefunds = []

const Refunds = ({ history }) => {
    const { user, token } = useContext(AppContext)

    userRole = user.role

    const commuFilter = (key) =>
        user.role === 'admin' || user.role === 'super-admin'
            ? {}
            : { [key]: { $in: user.communities } }

    useEffect(() => {
        if (userRole === 'community-manager' || userRole === 'customer-hero') {
            getRefunds({
                queryKey: [
                    'getRefunds',
                    {
                        token: token.token,
                        filter: {
                            ...commuFilter('community'),
                            status: { $in: ['refunded', 'refused'] },
                        },
                    },
                ],
            })
                .then((refunds) => {
                    let prevReadedRefundsCount =
                        localStorage.getItem('readedRefundsCount') || 0

                    if (refunds?.list?.length !== prevReadedRefundsCount) {
                        let prevReadedRefunds =
                            JSON.parse(localStorage.getItem('readedRefunds')) ||
                            []
                        for (const i in refunds?.list) {
                            if (
                                prevReadedRefunds?.some(
                                    (prevItem) =>
                                        prevItem.id === refunds.list[i].id
                                )
                            ) {
                                console.log('Already exist')
                            } else {
                                console.log("Doesn't exist")
                                unreadedRefunds.push(refunds.list[i])
                            }
                        }

                        localStorage.setItem(
                            'readedRefundsCount',
                            refunds?.list?.length
                        )
                        localStorage.setItem(
                            'readedRefunds',
                            JSON.stringify(refunds?.list)
                        )
                    }
                })
                .catch()
        }
    })

    return (
        <PageWrap title="MENU_REFUND">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getRefunds'}
                request={getRefunds}
                className="table-refunds"
                stats={stats}
                statsRequest={getRefundsAnalytics}
                rowSelection={(refund) => ({})}
                pageSize={80}
                otherFilters={{
                    ...commuFilter('community'),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (
                                record?.status !== 'refunded' &&
                                record?.status !== 'refused'
                            ) {
                                history.push(`/refunds/${record?.id}`)
                            } else {
                                return false
                            }
                        },
                    }
                }}
            />
        </PageWrap>
    )
}

const filters = (history, selectedLines, loading, id, list) => ({
    fields: [
        {
            type: 'select',
            label: 'OFFERS_STATUS',
            default: '',
            key: 'status',
            options: [
                { key: 'new', label: 'new' },
                { key: 'validated', label: 'validated' },
                { key: 'refused', label: 'refused' },
                { key: 'refunded', label: 'refunded' },
            ],
        },
        {
            type: 'text',
            label: 'SUPPLIERS_SUPPLIER_NAME',
            key: 'supplier.name',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'CUSTOMERS_CUSTOMER_NAME',
            key: 'customer.name',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'ROLES_TEAM_MEMBER',
            key: 'teamMember.name',
            noRegex: true,
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    actions: [
        {
            label: 'REFUNDS_EXPORT_REFUNDS',
            isActive: selectedLines,
            lineAction: async () => {
                const toExport = list.filter((e) =>
                    selectedLines.includes(e?.id)
                )

                downloadCSV(
                    [
                        'Order ID',
                        'Team member',
                        'Community',
                        'Date',
                        'Customer name',
                        'Supplier name',
                        'Responsability',
                        'Issue',
                        'Issue detail',
                        'Order total (¥)',
                        'Refund amount (¥)',
                        'Action ETA',
                        'Comment',
                        'Status',
                    ],
                    [
                        ...toExport.map((e) => [
                            e?.order?.orderID,
                            e?.teamMember?.name,
                            e?.community?.name,
                            formatDate(e?.date, 'long'),
                            e?.customer?.name,
                            e?.supplier?.name,
                            e?.resp,
                            e?.issue,
                            e?.issueDetail,
                            formatNb(e?.order?.actualAmount),
                            formatNb(e?.refundAmount),
                            formatDate(e?.actionETA),
                            e?.comment,
                            e?.status,
                        ]),
                    ],
                    `Refunds ${formatDate(new Date())}`
                )
            },
        },
        {
            label: 'REFUNDS_CREATE_REFUND',
            isActive: true,
            action: () => history.push('/refunds/create'),
        },
    ],
})

const stats = () => [
    // {
    //     isWrap: true,
    //     stats: [
    //         {
    //             label: 'REFUNDS_REFUND_RATE',
    //             colored: true,
    //             getValue: (e) => e?.refundRate || 0,
    //             isSpe: true,
    //             suffix: '%',
    //         },
    //     ],
    // },
    {
        title: 'MENU_ANALYTICS',
        stats: [
            {
                label: 'REFUNDS_TOTAL_REFUNDS',
                getValue: (e) => e?.totalAmount,
            },
            // {
            //     label: 'Total amount',
            //     getValue: (e) => 0,
            //     suffix: '¥',
            // },
            {
                label: 'REFUNDS_REFUNDED_RATIO',
                getValue: (e) => {
                    const onGoingIndex = e?.refundsByStatus?.findIndex(
                        (e) => e?._id === 'refunded'
                    )

                    if (
                        !e?.refundsByStatus[onGoingIndex]?.amount ||
                        !e?.totalAmount
                    )
                        return '0'

                    return onGoingIndex || onGoingIndex > -1
                        ? (e?.refundsByStatus[onGoingIndex]?.amount /
                              e?.totalAmount) *
                              100
                        : '0'
                },
                suffix: '%',
            },
            {
                label: 'REFUNDS_ONGOING_REFUNDS',
                getValue: (e) => {
                    const newIndex = e?.refundsByStatus?.findIndex(
                        (e) => e?._id === 'new'
                    )
                    const validatedIndex = e?.refundsByStatus?.findIndex(
                        (e) => e?._id === 'validated'
                    )

                    if (
                        !e?.refundsByStatus[newIndex]?.amount ||
                        !e?.refundsByStatus[validatedIndex]?.amount ||
                        !e?.totalAmount
                    )
                        return '0'

                    const amount =
                        e?.refundsByStatus[newIndex]?.amount +
                        e?.refundsByStatus[validatedIndex]?.amount

                    return (amount / e?.totalAmount) * 100
                },
                suffix: '%',
            },
            {
                label: 'REFUNDS_REFUSED_RATIO',
                getValue: (e) => {
                    const onGoingIndex = e?.refundsByStatus?.findIndex(
                        (e) => e?._id === 'refused'
                    )

                    if (
                        !e?.refundsByStatus[onGoingIndex]?.amount ||
                        !e?.totalAmount
                    )
                        return '0'

                    return onGoingIndex || onGoingIndex > -1
                        ? (e?.refundsByStatus[onGoingIndex]?.amount /
                              e?.totalAmount) *
                              100
                        : '0'
                },
                suffix: '%',
            },
            {
                label: 'CUSTOMER_SERVICE_TOTAL_VOUCHERS',
                getValue: (e) => e?.voucherCount,
            },
        ],
    },
    {
        title: 'CUSTOMER_SERVICE_RESPONSIBILITY',
        stats: [
            {
                label: 'ziko',
                getValue: (e) => {
                    const zikoIndex = e?.refundByResp?.findIndex(
                        (e) => e?._id === 'ziko'
                    )

                    return zikoIndex || zikoIndex > -1
                        ? e?.refundByResp[zikoIndex]?.amount
                        : 0
                },
            },
            {
                label: 'customer',
                getValue: (e) => {
                    const deliveryIndex = e?.refundByResp?.findIndex(
                        (e) => e?._id === 'customer'
                    )

                    return deliveryIndex || deliveryIndex > -1
                        ? e?.refundByResp[deliveryIndex]?.amount
                        : 0
                },
            },
            {
                label: 'CUSTOMER_SERVICE_DELIVERY_SERVICE',
                getValue: (e) => {
                    const deliveryIndex = e?.refundByResp?.findIndex(
                        (e) => e?._id === 'delivery'
                    )

                    return deliveryIndex || deliveryIndex > -1
                        ? e?.refundByResp[deliveryIndex]?.amount
                        : 0
                },
            },
            {
                label: 'supplier',
                getValue: (e) => {
                    const deliveryIndex = e?.refundByResp?.findIndex(
                        (e) => e?._id === 'supplier'
                    )

                    return deliveryIndex || deliveryIndex > -1
                        ? e?.refundByResp[deliveryIndex]?.amount
                        : 0
                },
            },
        ],
    },
]

const isRed = (refund) => {
    if (
        ((userRole === 'admin' || userRole === 'super-admin') &&
            refund.status === 'new') ||
        ((userRole === 'super-admin' ||
            userRole === 'admin' ||
            userRole === 'finance') &&
            refund.status === 'validated')
    ) {
        return true
    }

    if (
        (userRole === 'community-manager' || userRole === 'customer-hero') &&
        (refund.status === 'refunded' || refund.status === 'refused') &&
        unreadedRefunds?.some((item) => item.id === refund.id)
    ) {
        return true
    }

    return false
}

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        key: 'orderID',
        render: (refund) => refund.order?.orderID,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
    },
    {
        title: <DynamicStrings id="ROLES_TEAM_MEMBER" />,
        key: 'teamMember',
        render: (refund) => refund.teamMember?.name,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        key: 'community',
        render: (refund) => <DynamicStrings id={refund.community?.name} />,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_DATE" />,
        key: 'createdAt',
        render: (refund) => formatDate(refund.date, 'long'),
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_CUSTOMER_NAME" />,
        key: 'customerName',
        render: (refund) => refund.customer?.name,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="SUPPLIERS_SUPPLIER_NAME" />,
        key: 'supplierName',
        render: (refund) => refund.supplier?.name,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_RESPONSIBILITY" />,
        key: 'resp',
        render: (refund) => <DynamicStrings id={refund.resp} />,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ISSUE" />,
        key: 'issue',
        render: (refund) => <DynamicStrings id={refund.issue} />,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ISSUE_DETAIL" />,
        key: 'issueDetail',
        render: (refund) => refund.issueDetail,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="REFUNDS_ORDER_TOTAL" />,
        key: 'order.actualAmount',
        render: (refund) => formatNb(refund.order?.actualAmount),
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="REFUNDS_PAYMENT_MERCHANT_ID" />,
        key: 'outTradeNo',
        render: (refund) => refund.outTradeNo,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="REFUNDS_PAYMENT" />,
        key: 'order.paymentMethod',
        render: (refund) => <DynamicStrings id={refund.order?.paymentMethod} />,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        sorter: false,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
        key: 'comment',
        render: (refund) => refund.comment,
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        ellipsis: true,
        sorter: false,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        render: (refund) => (
            <RefundStatus
                statusProps={{
                    status: refund.status,
                    requestName: 'getRefunds',
                    fieldKey: 'status',
                    keyRequest: keyRequest,
                    editRequest: EditRefund,
                    id: refund.id,
                    refundAmount: refund.refundAmount,
                    refundInfo: refund,
                    config: [
                        { key: 'new', color: '#D06A1F' },
                        { key: 'validated', color: '#FAAD14' },
                        { key: 'refused', color: '#949494' },
                        { key: 'refunded', color: '#BB4DFF' },
                    ],
                }}
            />
        ),
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
        key: 'status',
        sorter: false,
        width: 140,
    },
    {
        title: '',
        key: 'action',
        width: 50,
        render: (e) => {
            const menu = (
                <Menu>
                    <Menu.Item key="0">
                        <a onClick={() => goTo(`/customers/${e?.customer.id}`)}>
                            <DynamicStrings id="DIRECT_SALES_CUSTOMER_INFORMATION" />
                        </a>
                    </Menu.Item>
                    <Menu.Item
                        key="1"
                        disabled={!!e.refundVouchers?.length || !e.customer}
                    >
                        <a
                            onClick={() =>
                                goTo({
                                    pathname: '/vouchers/create',
                                    customer: e?.customer,
                                    refund: e,
                                    refundOrder: e?.order,
                                    reason: 'refund',
                                })
                            }
                        >
                            <DynamicStrings
                                id={
                                    !!e.refundVouchers?.length
                                        ? 'SERVICES_ORDER_GOT_VOUCHER'
                                        : 'SERVICES_ORDER_LIST_CREATE_VOUCHER'
                                }
                            />
                        </a>
                    </Menu.Item>
                    {/* {(e.status !== "refunded" && e.status !== "refused") && (
                        <Menu.Item key="2">
                            <a onClick={() => goTo(`/refunds/${e?.id}`)}>
                                <DynamicStrings id="ROLES_EDIT" />
                            </a>
                        </Menu.Item>
                    )} */}
                </Menu>
            )
            return (
                <div className={isRed(e)} onClick={(e) => e?.stopPropagation()}>
                    <Space className="btnCenter">
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a
                                className="ant-dropdown-link"
                                onClick={(e) => e?.preventDefault()}
                            >
                                <MoreOutlined />
                            </a>
                        </Dropdown>
                    </Space>
                </div>
            )
        },
        onCell: (record, rowIndex) => {
            if (isRed(record)) {
                return {
                    className: 'urgent-refund',
                }
            }
        },
    },
]

const RefundStatus = ({ statusProps }) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const [showSelect, setShowSelect] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    let refund = statusProps.refundInfo

    const badgeColor = (key) =>
        statusProps.config.find((e) => e.key === key)?.color || ''

    const mutation = useMutation((values) => EditRefund(...values), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(statusProps.keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e?.id === statusProps.id
                )
                oldData.list[oldDataIndex] = data
                return oldData
            })
            queryClient.invalidateQueries('getRefunds')
            queryClient.invalidateQueries('getRefundsAnalytics')
            queryClient.invalidateQueries('CustomerRefunds')
        },
        onError: (error, variables) => {
            getSingleRefund({
                queryKey: [
                    'getSingleRefund',
                    {
                        token: token.token,
                        refundId: statusProps.id,
                    },
                ],
            })
                .then((updatedRefund) => {
                    refund = updatedRefund

                    queryClient.setQueryData(
                        statusProps.keyRequest,
                        (oldData) => {
                            let oldDataIndex = oldData.list.findIndex(
                                (e) => e?.id === statusProps.id
                            )
                            oldData.list[oldDataIndex].paymentInfoMissing =
                                updatedRefund?.paymentInfoMissing
                            return oldData
                        }
                    )

                    message.error(
                        t(error?.response?.data?.message) || 'An error occurs'
                    )
                })
                .catch((err) => {
                    message.error(
                        error?.response?.data?.message || 'An error occurs'
                    )
                })
        },
    })

    if (statusProps.status === 'new') {
        if (userRole === 'admin' || userRole === 'super-admin') {
            statusProps.config = [
                { key: 'new', color: '#D06A1F' },
                { key: 'validated', color: '#FAAD14' },
                { key: 'refused', color: '#949494' },
            ]
        } else {
            return (
                <Popover
                    placement="bottom"
                    content={() => {
                        return (
                            <>
                                <EnterOutlined
                                    style={{
                                        transform: 'scale(-1, 1)',
                                        color: '#BBBBBB',
                                        marginRight: 5,
                                    }}
                                />
                                {formatNb(statusProps.refundAmount)}
                            </>
                        )
                    }}
                >
                    <Badge color={'#D06A1F'} text={t('new')} />
                </Popover>
            )
        }
    }

    if (statusProps.status === 'validated') {
        if (
            userRole === 'super-admin' ||
            userRole === 'admin' ||
            userRole === 'finance'
        ) {
            statusProps.config = [
                { key: 'validated', color: '#FAAD14' },
                { key: 'refunded', color: '#BB4DFF' },
            ]
        } else {
            return (
                <Popover
                    placement="bottom"
                    content={() => {
                        return (
                            <>
                                <EnterOutlined
                                    style={{
                                        transform: 'scale(-1, 1)',
                                        color: '#BBBBBB',
                                        marginRight: 5,
                                    }}
                                />
                                {formatNb(statusProps.refundAmount)}
                            </>
                        )
                    }}
                >
                    <Badge color={'#FAAD14'} text={t('validated')} />
                </Popover>
            )
        }
    }

    if (statusProps.status === 'refused')
        return (
            <Popover
                placement="bottom"
                content={() => {
                    return (
                        <>
                            <EnterOutlined
                                style={{
                                    transform: 'scale(-1, 1)',
                                    color: '#BBBBBB',
                                    marginRight: 5,
                                }}
                            />
                            {formatNb(statusProps.refundAmount)}
                        </>
                    )
                }}
            >
                <Badge color={'#949494'} text={t('refused')} />
            </Popover>
        )

    if (statusProps.status === 'refunded')
        return (
            <Popover
                placement="bottom"
                content={() => {
                    return (
                        <>
                            <EnterOutlined
                                style={{
                                    transform: 'scale(-1, 1)',
                                    color: '#BBBBBB',
                                    marginRight: 5,
                                }}
                            />
                            {formatNb(statusProps.refundAmount)}
                        </>
                    )
                }}
            >
                <Badge color={'#BB4DFF'} text={t('refunded')} />
            </Popover>
        )

    return (
        <>
            <Select
                bordered={false}
                className="status-select"
                loading={mutation.isLoading}
                value={statusProps.status}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    if (
                        e === 'refunded' &&
                        (refund?.order?.paymentMethod === 'alipay' ||
                            (refund?.order?.paymentMethod === 'wechat' &&
                                refund?.paymentInfoMissing === true))
                    ) {
                        setShowModal(true)
                        return false
                    }

                    mutation.mutate([
                        { status: e },
                        token.token,
                        statusProps.id,
                    ])
                }}
                onDropdownVisibleChange={() => setShowSelect(true)}
                onBlur={() => setShowSelect(false)}
            >
                {statusProps.config?.map((status) => (
                    <Select.Option key={status.key} value={status.key}>
                        <Popover
                            placement="bottom"
                            content={() => {
                                return (
                                    <>
                                        <EnterOutlined
                                            style={{
                                                transform: 'scale(-1, 1)',
                                                color: '#BBBBBB',
                                                marginRight: 5,
                                            }}
                                        />
                                        {formatNb(statusProps.refundAmount)}
                                    </>
                                )
                            }}
                            visible={!showSelect && showPopover}
                            onMouseEnter={() => setShowPopover(true)}
                            onMouseLeave={() => setShowPopover(false)}
                        >
                            <Badge
                                color={badgeColor(status.key)}
                                text={t(status.key)}
                            />
                        </Popover>
                    </Select.Option>
                ))}
            </Select>
            <Modal
                title={null}
                visible={showModal}
                okText={<DynamicStrings id="OFFER_LIVE_YES" />}
                cancelText={<DynamicStrings id="OFFER_LIVE_NO" />}
                onOk={(e) => {
                    e.stopPropagation()
                    mutation.mutate([
                        { status: 'refunded' },
                        token.token,
                        statusProps.id,
                    ])
                }}
                confirmLoading={mutation.isLoading}
                onCancel={(e) => {
                    e.stopPropagation()
                    setShowModal(false)
                }}
            >
                <Space size="large" align="start">
                    <ExclamationCircleOutlined
                        style={{ color: '#FAAD14', fontSize: 24, marginTop: 5 }}
                    />
                    <Space direction="vertical">
                        <Text type="secondary">
                            <DynamicStrings id="REFUNDS_SURE_TO_VALIDATE_REFUND" />
                        </Text>
                    </Space>
                </Space>
            </Modal>
        </>
    )
}

export default Refunds
