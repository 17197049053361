const checkValidity = (values, products) => {
    const errors = []

    if (
        (values.channel === 'all' || values.channel === 'miniprogram') &&
        !values?.miniprogram?.packs?.length &&
        !values?.miniprogram?.items?.length
    ) {
        errors.push({
            type: 'custom',
            key: 'miniprogram.packs.size',
            message: 'Need a least one pack or item',
        })
    }

    if (
        (values.channel === 'all' || values.channel === 'wechat_group') &&
        !values?.wechatGroup?.packs?.length &&
        !values?.wechatGroup?.items?.length
    ) {
        errors.push({
            type: 'custom',
            key: 'wechatGroup.packs.size',
            message: 'Need a least one pack or item',
        })
    }

    values?.miniprogram?.packs?.forEach((pack, idx) => {
        pack?.products?.forEach((product, jdx) => {
            if (products[product.product] !== 'available') {
                errors.push({
                    type: 'custom',
                    key: `miniprogram.packs.${idx}.products.${jdx}.product`,
                    message: 'Product is not available',
                })
            }
        })
    })

    values?.miniprogram?.items?.forEach((item, idx) => {
        if (products[item.product] !== 'available') {
            errors.push({
                type: 'custom',
                key: `miniprogram.items.${idx}.product`,
                message: 'Product is not available',
            })
        }
    })

    values?.wechatGroup?.packs?.forEach((pack, idx) => {
        pack?.products?.forEach((product, jdx) => {
            if (products[product.product] !== 'available') {
                errors.push({
                    type: 'custom',
                    key: `wechatGroup.packs.${idx}.products.${jdx}.product`,
                    message: 'Product is not available',
                })
            }
        })
    })

    values?.wechatGroup?.items?.forEach((item, idx) => {
        if (products[item.product] !== 'available') {
            errors.push({
                type: 'custom',
                key: `wechatGroup.items.${idx}.product`,
                message: 'Product is not available',
            })
        }
    })

    values?.miniprogram?.packs?.forEach((e, idx) => {
        if (!e.products || !e.products.length) {
            errors.push({
                type: 'custom',
                key: `miniprogram.packs.${idx}.products.size`,
                message: 'Selections need at least one item',
            })
        }
        // if (e.formerPrice && e.formerPrice <= e.price) {
        //     errors.push({
        //         type: 'custom',
        //         key: `miniprogram.packs.${idx}.formerPrice`,
        //         message:
        //             "Original price shouldn't be equal or less than the current price",
        //     })
        // }
        // if (e.stock < 0) {
        //     errors.push({
        //         type: 'custom',
        //         key: `miniprogram.packs.${idx}.stock`,
        //         message: 'Stock can not be empty',
        //     })
        // }
    })
    values?.wechatGroup?.packs?.forEach((e, idx) => {
        if (!e.products || !e.products.length) {
            errors.push({
                type: 'custom',
                key: `wechatGroup.packs.${idx}.products.size`,
                message: 'Selections need at least one item',
            })
        }
        // if (e.formerPrice && e.formerPrice <= e.price) {
        //     errors.push({
        //         type: 'custom',
        //         key: `wechatGroup.packs.${idx}.formerPrice`,
        //         message:
        //             "Original price shouldn't be equal or less than the current price",
        //     })
        // }
        // if (e.stock < 0) {
        //     errors.push({
        //         type: 'custom',
        //         key: `wechatGroup.packs.${idx}.stock`,
        //         message: 'Stock can not be empty',
        //     })
        // }
    })
    // values?.miniprogram?.items?.forEach((e, idx) => {
    //     if (e.formerPrice && e.formerPrice <= e.price) {
    //         errors.push({
    //             type: 'custom',
    //             key: `miniprogram.items.${idx}.formerPrice`,
    //             message:
    //                 "Original price shouldn't be equal or less than the current price",
    //         })
    //     }
    //     if (e.stock < 0) {
    //         errors.push({
    //             type: 'custom',
    //             key: `miniprogram.items.${idx}.stock`,
    //             message: 'Stock can not be empty',
    //         })
    //     }
    // })
    // values?.wechatGroup?.items?.forEach((e, idx) => {
    //     if (e.formerPrice && e.formerPrice <= e.price) {
    //         errors.push({
    //             type: 'custom',
    //             key: `wechatGroup.items.${idx}.formerPrice`,
    //             message:
    //                 "Original price shouldn't be equal or less than the current price",
    //         })
    //     }
    //     if (e.stock < 0) {
    //         errors.push({
    //             type: 'custom',
    //             key: `wechatGroup.items.${idx}.stock`,
    //             message: 'Stock can not be empty',
    //         })
    //     }
    // })

    if (!values.fees || !values.fees?.length) {
        errors.push({
            type: 'custom',
            key: 'fees.size',
            message: 'Rules need a least one area',
        })
    } else {
        values.fees?.forEach((fee, idx) => {
            if (!fee.rules || !fee.rules.length) {
                errors.push({
                    type: 'custom',
                    key: `fees.${idx}.rules.size`,
                    message: 'Rules need a least one rules on each fees area',
                })
            }
        })
    }

    // if (!values.deliveryDates || !values.deliveryDates.length) {
    //     errors.push({
    //         type: 'custom',
    //         key: 'deliveryDates',
    //         message: 'Need a least one area on delivery date',
    //     })
    // }

    if (
        values?.wechatGroup?.lotteryEnable === true &&
        values?.wechatGroup?.lottery
    ) {
        values?.wechatGroup?.lottery?.draws?.forEach((e, idx) => {
            if (!e?.gifts?.length) {
                errors.push({
                    type: 'custom',
                    key: `wechatGroup.lottery.draws.${idx}.size`,
                    message: 'Need to add lottery gifts',
                })
            }
        })
    }
    if (
        values?.miniprogram?.lotteryEnable === true &&
        values?.miniprogram?.lottery
    ) {
        values?.miniprogram?.lottery?.draws?.forEach((e, idx) => {
            if (!e?.gifts?.length) {
                errors.push({
                    type: 'custom',
                    key: `miniprogram.lottery.draws.${idx}.size`,
                    message: 'Need to add lottery gifts',
                })
            }
        })
    }

    // values?.miniprogram?.zikoSpecials?.forEach((e, idx) => {
    //     if (
    //         e.gift &&
    //         e.gift?.type === 'custom' &&
    //         JSON.stringify(e.gift?.customImage) === '{}'
    //     ) {
    //         errors.push({
    //             type: 'custom',
    //             key: `miniprogram.zikoSpecials.${idx}.gift.customImage`,
    //             message: 'Need to upload illustration',
    //         })
    //     }
    // })

    // if (
    //     values?.miniprogram?.lotteryEnable === true &&
    //     values?.miniprogram?.lottery
    // ) {
    //     values?.miniprogram?.lottery?.draws?.forEach((draw, idx) => {
    //         draw?.gifts?.forEach((gift, jdx) => {
    //             if (gift?.type === 'custom') {
    //                 errors.push({
    //                     type: 'custom',
    //                     key: `miniprogram.lottery.draws.${idx}.gifts.${jdx}.customImage`,
    //                     message: 'Need to upload illustration',
    //                 })
    //             }
    //         })
    //     })
    // }

    return errors
}

export default checkValidity
