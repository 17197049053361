import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Form } from 'antd'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

import {
    CustomerInformation,
    DailyOrderInformationEdit,
    DeliveryEdit,
    cleanBeforeSending,
    DailyOrderProducts,
    DailyOrderProductsEdit,
    Comment,
} from '../../constants/forms/DailyOrders'
import { EditOrders, getSingleOrder } from '../../network/API'
import moment from 'moment'

const DailyOrderDetails = () => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()
    let { orderId } = useParams()

    const orderDetails = useQuery(
        [
            'getSingleOrder',
            {
                orderId,
                token: token.token,
            },
        ],
        getSingleOrder,
        {
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (oldData) => {
        oldData.deliveryDate = moment(oldData.deliveryDate)
        oldData.orderDate = moment(oldData.orderDate)

        let newCities = []
        let cities = { ...oldData.customerAddress.area }
        while (cities) {
            newCities.push(cities.id)
            cities = cities.parent
        }
        newCities.reverse()

        return {
            ...oldData,
            customerDaily: {
                ...oldData.customerDaily,
                customerName: oldData.customer?.name || '',
            },
            customer: oldData.customer?.id || '',
            customerAddress: { ...oldData.customerAddress, area: newCities },
        }
    }

    return (
        <PageWrap
            goBack
            isLoading={orderDetails.isLoading}
            title={'Edit daily order'}
            // actionOptions={[
            //     <DeleteButton
            //         onSuccess="/delivery"
            //         request={DeleteArea}
            //         id={areaId}
            //         invalidate={['getSingleArea']}
            //     />,
            // ]}
        >
            {orderDetails.isError ? (
                <Error retry={() => orderDetails.refetch()} />
            ) : orderDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    form={form}
                    title="create_user"
                    request={EditOrders}
                    // request={() => {}}
                    onSuccess="/orders-direct-sales"
                    onCancel="/orders-direct-sales"
                    id={orderId}
                    cleanBeforeSending={cleanBeforeSending}
                    data={formatData(orderDetails.data)}
                    invalidate={['getOrders', 'getSingleOrder']}
                >
                    <div className="SectionHorizontalWrap">
                        <div
                            className="SectionVerticalWrap"
                            style={{ flex: 2 }}
                        >
                            <FormBlock
                                form={form}
                                formTemplate={DailyOrderInformationEdit}
                            />
                            <FormBlock
                                form={form}
                                formTemplate={CustomerInformation}
                            />
                            <FormBlock
                                form={form}
                                formTemplate={
                                    orderDetails?.data?.paymentStatus !==
                                    'pending'
                                        ? DailyOrderProductsEdit
                                        : DailyOrderProducts
                                }
                            />
                        </div>
                        <div
                            className="SectionVerticalWrap"
                            style={{ flex: 1 }}
                        >
                            <FormBlock
                                form={form}
                                formTemplate={DeliveryEdit}
                            />
                            <FormBlock form={form} formTemplate={Comment} />
                        </div>
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default DailyOrderDetails
