import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { Form } from 'antd'

import {
    Description,
    Media,
    ProductDetails,
    cleanBeforeSending,
} from '../../constants/forms/Products'
import { CreateProduct as request, getProductsList } from '../../network/Products'

import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'

const CreateProduct = () => {
    const [form] = Form.useForm()
    const { token } = useContext(AppContext)
    const [products, setProducts] = useState([])

    useEffect(() => {
        getProductsList(
            token.token
        ).then((data) => {
            setProducts(data.list)
        })
    }, [])


    return (
        <PageWrap goBack title="PRODUCT_LIST_CREATE_PRODUCT">
            <FormWrap
                form={form}
                title="create_product"
                request={request}
                onSuccess={'/products'}
                onCancel={'/products'}
                cleanBeforeSending={cleanBeforeSending}
                invalidate={['getProducts']}
                checkValidity={(values) => {
                    for (const i in products) {
                        if (products[i].name?.en?.toLowerCase() === values.name?.en?.toLowerCase() && products[i].status === 'available') {
                            return 'Product already exists'
                        }
                    }

                    if (
                        !values.mainPicture?.en?.uri ||
                        !values.mainPicture?.zh?.uri
                    ) {
                        return 'Please set main pictures'
                    }

                    // if (
                    //     !values.technicalSheet?.en?.uri ||
                    //     !values.technicalSheet?.zh?.uri
                    // ) {
                    //     return 'Please set technical sheets'
                    // }

                    return false
                }}
            >
                <div className="SectionHorizontalWrap">
                    <div className="SectionVerticalWrap" style={{ flex: 2 }}>
                        <FormBlock form={form} formTemplate={Description} />
                        <FormBlock form={form} formTemplate={Media} />
                    </div>

                    <FormBlock form={form} formTemplate={ProductDetails} />
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateProduct
