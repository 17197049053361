import { getSuppliers } from '../../network/Suppliers'
import { getCustomersWithAreas } from '../../network/Customers'
import { getProductsBasic, getSingleProduct } from '../../network/Products'

import set from 'lodash.set'

import moment from "moment/moment";
import { getCommunities } from "../../network/API";

const CustomerInformation = {
    title: 'DIRECT_SALES_CUSTOMER_INFORMATION',
    key: [],
    sections: [
        {
            title: 'DIRECT_SALES_CUSTOMER_INFORMATION',
            columns: 4,
            fields: [
                {
                    label: 'CUSTOMERS_CUSTOMER_NAME',
                    key: ['customerDaily', 'customerName'],
                    isRequired: true,

                    type: 'search&update',
                    options: getCustomersWithAreas,
                    searchKey: 'name',
                    formatOnRecieved: (user) => {
                        let newData = {
                            customerDaily: {
                                customerName: user.name,
                                fapiaoInformation: user.fapiaoInformation,
                            },
                            customer: user.id,
                        }
                        if (user.addresses.length) {
                            newData.customerAddress = {
                                phone: user.addresses[0]?.phone || user.contacts[0]?.phone,
                                type: user.addresses[0].type,
                                city: user.addresses[0].city,
                                zipCode: user.addresses[0].zipCode,
                                detailedAddress:
                                user.addresses[0].detailedAddress,
                            }

                            let newCities = []
                            let cities = { ...user.addresses[0].area }
                            while (cities) {
                                newCities.push(cities.id)
                                cities = cities.parent
                            }

                            newCities.reverse()
                            newData.customerAddress.area = newCities
                        }

                        return newData
                    },
                    OnType: {
                        customer: undefined,
                    },
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    key: ['customer'],
                    type: 'hidden',
                },
                {
                    label: 'DIRECT_SALES_PHONE_NUMBER',
                    key: ['customerAddress', 'phone'],
                    isRequired: true,
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_ADDRESS_NAME',
                    isRequired: true,
                    key: ['customerAddress', 'type'],
                    type: 'select',
                    options: [
                        { label: 'DIRECT_SALES_OFFICE', key: 'office' },
                        { label: 'DIRECT_SALES_HOME', key: 'home' },
                        { label: 'other', key: 'other' },
                    ],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_CITY',
                    key: ['customerAddress', 'city'],
                    isRequired: true,
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_ZIPCODE',
                    key: ['customerAddress', 'zipCode'],
                    // isRequired: true,
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_AREA',
                    type: 'area',
                    key: ['customerAddress', 'area'],
                    isRequired: true,
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_DETAILED_ADDRESS',
                    type: 'text',
                    key: ['customerAddress', 'detailedAddress'],
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 3,
                        size: 4,
                    },
                },
            ],
        },
        {
            title: 'DIRECT_SALES_FAPIAO_INFORMATION',
            columns: 4,
            fields: [
                {
                    label: 'DIRECT_SALES_FAPIAO_INFORMATION',
                    type: 'text',
                    key: ['customerDaily', 'fapiaoInformation'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 4,
                    },
                },
            ],
        },
    ],
}

const DailyOrderProducts = {
    title: 'DIRECT_SALES_PRODUCTS',
    key: [],
    sections: [
        {
            mainTitle: 'DIRECT_SALES_PRODUCTS',
            canAdd: true,
            key: ['singleItems'],
            noDivider: true,
            columns: 4,
            isRequired: true,
            deleteBtn: {
                label: 'DIRECT_SALES_DELETE_PRODUCT',
                style: 'line',
                pos: {
                    col: 3,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: {
                label: 'CREATE_OFFER_ADD_PRODUCT',
                style: 'primary',
            },
            fields: [
                // {
                //     label: 'CREATE_OFFER_SELECT_PRODUCT',
                //     type: 'select',
                //     requestOption: getProductsBasic,
                //     isRequired: true,
                //     key: ['product'],
                //     pos: {
                //         col: 1,
                //         row: 1,
                //         size: 2,
                //     },
                // },
                {
                    key: ['product'],
                    type: 'select&update',
                    options: getProductsBasic,
                    update: getSingleProduct,
                    updateKey: 'productId',
                    // formatOnRecieved: (oldData, fieldKey, form) => {
                    //     console.log('oldData', fieldKey)
                    //     let singleItems = form.getFieldValue('singleItems')
                    //     let supplier = form.getFieldValue('supplier')
                    //
                    //     if (fieldKey[0] === 0) {
                    //         supplier = oldData.supplier
                    //     }
                    //
                    //     fieldKey.pop()
                    //     // set(singleItems, [...fieldKey, 'price'], oldData.price)
                    //     set(singleItems, [...fieldKey, 'price'], oldData.price)
                    //     return {
                    //         supplier,
                    //         singleItems,
                    //     }
                    //     // return {
                    //     //     fees: oldData?.areas?.map((fee) => {
                    //     //         let newCities = []
                    //     //         let cities = { ...fee.area }
                    //     //         while (cities) {
                    //     //             newCities.push(cities.id)
                    //     //             cities = cities.parent
                    //     //         }
                    //     //         newCities.reverse()
                    //     //         return {
                    //     //             ...fee,
                    //     //             area: newCities,
                    //     //         }
                    //     //     }),
                    //     // }
                    // },
                    formatOnRecieved: (oldData, fieldKey, form) => {
                        let singleItems = form.getFieldValue('singleItems')
                        let supplier = form.getFieldValue('supplier')

                        if (fieldKey[0] === 0) {
                            supplier = oldData.supplier
                        }

                        fieldKey.pop()
                        set(singleItems, [...fieldKey, 'price'], oldData.retailPrice)
                        set(singleItems, [...fieldKey, 'supplier'], oldData.supplier)
                        set(singleItems, [...fieldKey, 'weight'], oldData.weight)
                        set(singleItems, [...fieldKey, 'weightType'], oldData.weightType)

                        return {
                            supplier,
                            singleItems,
                        }
                    },
                    pos: {
                        col: 1,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'DIRECT_SALES_WEIGHT_PER_UNIT',
                    type: 'number',
                    isInt: true,
                    key: ['weight'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_UNIT',
                    key: ['weightType'],
                    isRequired: true,
                    type: 'select',
                    options: [
                        { label: 'DIRECT_SALES_GRAM', key: 'g' },
                        { label: 'DIRECT_SALES_ML', key: 'ml' },
                        { label: 'DIRECT_SALES_PIECES', key: 'piece' },
                    ],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_QUANTITY',
                    type: 'number',
                    isInt: true,
                    key: ['amount'],
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_RETAIL_PRICE',
                    type: 'number',
                    key: ['price'],
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'supplier',
                    type: 'select',
                    requestOption: getSuppliers,
                    key: ['supplier'],
                    isInactive: true,
                    pos: {
                        col: 2,
                        row: 3,
                        size: 2,
                    },
                },
            ],
        },
    ],
}

const DailyOrderProductsEdit = {
    title: 'Products',
    key: [],
    sections: [
        {
            mainTitle: 'DIRECT_SALES_PRODUCTS',
            canAdd: true,
            key: ['singleItems'],
            noDivider: true,
            columns: 4,
            isRequired: true,
            deleteBtn: {
                label: 'DIRECT_SALES_DELETE_PRODUCT',
                style: 'line',
                isInactive: true,
                pos: {
                    col: 3,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: {
                label: 'CREATE_OFFER_ADD_PRODUCT',
                style: 'primary',
                isInactive: true,
            },
            fields: [
                {
                    key: ['product'],
                    type: 'select&update',
                    options: getProductsBasic,
                    isInactive: true,
                    update: getSingleProduct,
                    updateKey: 'productId',
                    formatOnRecieved: (oldData, fieldKey, form) => {
                        let singleItems = form.getFieldValue('singleItems')
                        let supplier = form.getFieldValue('supplier')

                        if (fieldKey[0] === 0) {
                            supplier = oldData.supplier
                        }

                        fieldKey.pop()
                        set(singleItems, [...fieldKey, 'price'], oldData.retailPrice)
                        set(singleItems, [...fieldKey, 'supplier'], oldData.supplier)
                        return {
                            supplier,
                            singleItems,
                        }
                    },
                    pos: {
                        col: 1,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'DIRECT_SALES_WEIGHT_PER_UNIT',
                    type: 'number',
                    isInt: true,
                    isInactive: true,
                    key: ['weight'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_UNIT',
                    key: ['weightType'],
                    isInactive: true,
                    isRequired: true,
                    type: 'select',
                    options: [
                        { label: 'DIRECT_SALES_GRAM', key: 'g' },
                        { label: 'DIRECT_SALES_ML', key: 'ml' },
                        { label: 'DIRECT_SALES_PIECES', key: 'piece' },
                    ],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_QUANTITY',
                    type: 'number',
                    isInactive: true,
                    isInt: true,
                    key: ['amount'],
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_RETAIL_PRICE',
                    type: 'number',
                    key: ['price'],
                    isInactive: true,
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'supplier',
                    type: 'select',
                    requestOption: getSuppliers,
                    key: ['supplier'],
                    isInactive: true,
                    pos: {
                        col: 2,
                        row: 3,
                        size: 2,
                    },
                },
            ],
        },
    ],
}

const DailyOrderInformation = {
    title: 'DIRECT_SALES_DAILY_ORDER_INFORMATION',
    key: [],
    sections: [
        // {
        //     mainTitle: 'DIRECT_SALES_PRODUCTS',
        //     canAdd: true,
        //     key: ['singleItems'],
        //     noDivider: true,
        //     columns: 4,
        //     isRequired: true,
        //     deleteBtn: {
        //         label: 'DIRECT_SALES_DELETE_PRODUCT',
        //         style: 'line',
        //         pos: {
        //             col: 3,
        //             row: 1,
        //             size: 1,
        //         },
        //     },
        //     addBtn: {
        //         label: 'CREATE_OFFER_ADD_PRODUCT',
        //         style: 'primary',
        //     },
        //     fields: [
        //         // {
        //         //     label: 'CREATE_OFFER_SELECT_PRODUCT',
        //         //     type: 'select',
        //         //     requestOption: getProductsBasic,
        //         //     isRequired: true,
        //         //     key: ['product'],
        //         //     pos: {
        //         //         col: 1,
        //         //         row: 1,
        //         //         size: 2,
        //         //     },
        //         // },
        //         {
        //             key: ['product'],
        //             type: 'select&update',
        //             options: getProductsBasic,
        //             update: getSingleProduct,
        //             updateKey: 'productId',
        //             formatOnRecieved: (oldData, fieldKey, form) => {
        //                 console.log('oldData', oldData.supplier)
        //                 let singleItems = form.getFieldValue('singleItems')
        //                 let supplier = form.getFieldValue('supplier')

        //                 if (!supplier) {
        //                     supplier = oldData.supplier
        //                 }

        //                 fieldKey.pop()
        //                 // set(singleItems, [...fieldKey, 'price'], oldData.price)
        //                 set(singleItems, [...fieldKey, 'price'], oldData.price)
        //                 return {
        //                     supplier,
        //                     singleItems,
        //                 }
        //                 // return {
        //                 //     fees: oldData?.areas?.map((fee) => {
        //                 //         let newCities = []
        //                 //         let cities = { ...fee.area }
        //                 //         while (cities) {
        //                 //             newCities.push(cities.id)
        //                 //             cities = cities.parent
        //                 //         }
        //                 //         newCities.reverse()
        //                 //         return {
        //                 //             ...fee,
        //                 //             area: newCities,
        //                 //         }
        //                 //     }),
        //                 // }
        //             },
        //             pos: {
        //                 col: 1,
        //                 row: 1,
        //                 size: 2,
        //             },
        //         },
        //         {
        //             label: 'CREATE_OFFER_QUANTITY',
        //             type: 'number',
        //             isInt: true,
        //             key: ['amount'],
        //             isRequired: true,
        //             pos: {
        //                 col: 1,
        //                 row: 2,
        //                 size: 1,
        //             },
        //         },
        //         {
        //             label: 'DIRECT_SALES_COST_PRICE',
        //             type: 'number',
        //             key: ['price'],
        //             isRequired: true,
        //             pos: {
        //                 col: 2,
        //                 row: 2,
        //                 size: 1,
        //             },
        //         },
        //     ],
        // },
        {
            title: 'DIRECT_SALES_GENERAL_INFORMATION',
            columns: 4,
            fields: [
                {
                    label: 'COMMUNITY_COMMUNITY',
                    key: ['community'],
                    isRequired: true,
                    type: 'select',
                    requestOption: getCommunities,
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'SERVICES_ORDER_LIST_DATE',
                    type: 'date',
                    default: moment(),
                    key: ['orderDate'],
                    // isRequired: true,
                    allowClear: false,
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'supplier',
                    type: 'select',
                    requestOption: getSuppliers,
                    key: ['supplier'],
                    isRequired: true,
                    isInactive: true,
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        // {
        //     title: 'DIRECT_SALES_CUSTOMER_INFORMATION',
        //     columns: 4,
        //     fields: [
        //         {
        //             label: 'CUSTOMERS_CUSTOMER_NAME',
        //             key: ['customerDaily', 'customerName'],
        //             isRequired: true,
        //
        //             type: 'search&update',
        //             options: getCustomersWithAreas,
        //             searchKey: 'name',
        //             formatOnRecieved: (user) => {
        //                 let newData = {
        //                     customerDaily: {
        //                         customerName: user.name,
        //                         fapiaoInformation: user.fapiaoInformation,
        //                     },
        //                     customer: user.id,
        //                 }
        //                 if (user.addresses.length) {
        //                     newData.customerAddress = {
        //                         phone: user.addresses[0].phone,
        //                         type: user.addresses[0].type,
        //                         city: user.addresses[0].city,
        //                         zipCode: user.addresses[0].zipCode,
        //                         detailedAddress:
        //                             user.addresses[0].detailedAddress,
        //                     }
        //
        //                     let newCities = []
        //                     let cities = { ...user.addresses[0].area }
        //                     while (cities) {
        //                         newCities.push(cities.id)
        //                         cities = cities.parent
        //                     }
        //
        //                     newCities.reverse()
        //                     newData.customerAddress.area = newCities
        //                 }
        //
        //                 return newData
        //             },
        //             OnType: {
        //                 customer: undefined,
        //             },
        //             pos: {
        //                 col: 1,
        //                 row: 1,
        //                 size: 1,
        //             },
        //         },
        //         {
        //             key: ['customer'],
        //             type: 'hidden',
        //         },
        //         {
        //             label: 'DIRECT_SALES_PHONE_NUMBER',
        //             key: ['customerAddress', 'phone'],
        //             isRequired: true,
        //             pos: {
        //                 col: 2,
        //                 row: 1,
        //                 size: 1,
        //             },
        //         },
        //         {
        //             label: 'DIRECT_SALES_ADDRESS_NAME',
        //             isRequired: true,
        //             key: ['customerAddress', 'type'],
        //             type: 'select',
        //             options: [
        //                 { label: 'Office', key: 'office' },
        //                 { label: 'Home', key: 'home' },
        //                 { label: 'other', key: 'other' },
        //             ],
        //             pos: {
        //                 col: 1,
        //                 row: 2,
        //                 size: 1,
        //             },
        //         },
        //         {
        //             label: 'ROLES_CITY',
        //             key: ['customerAddress', 'city'],
        //             isRequired: true,
        //             pos: {
        //                 col: 2,
        //                 row: 2,
        //                 size: 1,
        //             },
        //         },
        //         {
        //             label: 'DIRECT_SALES_ZIPCODE',
        //             key: ['customerAddress', 'zipCode'],
        //             isRequired: true,
        //             pos: {
        //                 col: 3,
        //                 row: 2,
        //                 size: 1,
        //             },
        //         },
        //         {
        //             label: 'OFFER_LIVE_AREA',
        //             type: 'area',
        //             key: ['customerAddress', 'area'],
        //             isRequired: true,
        //             pos: {
        //                 col: 4,
        //                 row: 2,
        //                 size: 1,
        //             },
        //         },
        //         {
        //             label: 'DIRECT_SALES_DETAILED_ADDRESS',
        //             type: 'text',
        //             key: ['customerAddress', 'detailedAddress'],
        //             isRequired: true,
        //             pos: {
        //                 col: 1,
        //                 row: 3,
        //                 size: 4,
        //             },
        //         },
        //     ],
        // },
        // {
        //     title: 'DIRECT_SALES_FAPIAO_INFORMATION',
        //     columns: 4,
        //     fields: [
        //         {
        //             label: 'DIRECT_SALES_FAPIAO_INFORMATION',
        //             type: 'text',
        //             key: ['customerDaily', 'fapiaoInformation'],
        //             pos: {
        //                 col: 1,
        //                 row: 1,
        //                 size: 4,
        //             },
        //         },
        //     ],
        // },
    ],
}

const DailyOrderInformationEdit = {
    title: 'DIRECT_SALES_DAILY_ORDER_INFORMATION',
    key: [],
    sections: [
        {
            title: 'DIRECT_SALES_GENERAL_INFORMATION',
            columns: 4,
            fields: [
                {
                    label: 'COMMUNITY_COMMUNITY',
                    key: ['community'],
                    isInactive: true,
                    isRequired: true,
                    type: 'select',
                    requestOption: getCommunities,
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'SERVICES_ORDER_LIST_DATE',
                    type: 'date',
                    default: moment(),
                    key: ['orderDate'],
                    // isRequired: true,
                    allowClear: false,
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'supplier',
                    type: 'select',
                    requestOption: getSuppliers,
                    key: ['supplier'],
                    isRequired: true,
                    isInactive: true,
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Delivery = {
    title: 'DIRECT_SALES_DELIVERY',
    key: [],
    sections: [
        {
            // title: 'DIRECT_SALES_DELIVERY',
            columns: 2,
            fields: [
                {
                    label: 'OFFER_LIVE_DELIVERY_FEE',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['deliveryFee'],
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_DELIVERY_DATE',
                    type: 'date',
                    key: ['deliveryDate'],
                    isRequired: true,
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Comment = {
    title: 'OFFER_LIVE_COMMENT',
    key: [],
    sections: [
        {
            // title: 'OFFER_LIVE_COMMENT',
            columns: 2,
            fields: [
                {
                    label: 'OFFER_LIVE_COMMENT',
                    type: 'text',
                    key: ['comment'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 2,
                    },
                },
            ],
        },
    ],
}

const DeliveryEdit = {
    title: 'DIRECT_SALES_DELIVERY',
    key: [],
    sections: [
        {
            // title: 'DIRECT_SALES_DELIVERY',
            columns: 2,
            fields: [
                {
                    label: 'OFFER_LIVE_DELIVERY_FEE',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['deliveryFee'],
                    isRequired: true,
                    // isInactive: true,
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_DELIVERY_DATE',
                    type: 'date',
                    key: ['deliveryDate'],
                    isRequired: true,
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },

                {
                    label: 'Delivery status',
                    isRequired: true,
                    key: ['trackingStatus'],
                    type: 'select',
                    options: [
                        { key: 'prepared', label: 'prepared' },
                        { key: 'on_the_way', label: 'on_the_way' },
                        { key: 'delayed', label: 'delayed' },
                        { key: 'delivered', label: 'delivered' },
                    ],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 2,
                    },
                },
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    if (!oldData.orderDate) {
        oldData = {
            ...oldData,
            orderDate: moment(),
        }
    }
    if (oldData.customer) {
        return {
            ...oldData,
            customerDaily: {
                ...oldData.customerDaily,
                customerName: undefined,
            },
        }
    }

    return oldData
}

export {
    CustomerInformation,
    DailyOrderInformation,
    DailyOrderInformationEdit,
    Delivery,
    DeliveryEdit,
    cleanBeforeSending,
    DailyOrderProducts,
    DailyOrderProductsEdit,
    Comment,
}
