import { useState, useEffect, useContext } from 'react'
import { Tabs, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import PageWrap from '../../components/layout/PageWrap'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { CreateAnOffer } from '../../network/Offers'
import { getProductsList } from '../../network/Products'

import { AppContext } from '../../context/AppContext'


import {
    Settings,
    Description,
    ClipboardForm,
    AddSelectionForm,
    AddSingleItem,
    ZokiSpecials,
    Lottery,
    OfferDetails,
    BourseRule,
    cleanBeforeSending,
    // checkValidity,
} from '../../constants/forms/Offers'

const { TabPane } = Tabs

const CreateOffer = ({ history }) => {
    const [tabKey, setTabKey] = useState('wechat_group')
    const [channel, setChannel] = useState(null)
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const { token, user } = useContext(AppContext)
    const [products, setProducts] = useState({})

    const handleChannel = (chan) => {
        if (chan === 'wechat_group' && tabKey === 'miniprogram') {
            setTabKey('wechat_group')
        } else if (chan === 'miniprogram' && tabKey === 'wechat_group') {
            setTabKey('miniprogram')
        }
        setChannel(chan)
    }

    useEffect(() => {
        if (history?.location?.defaultValue) {
            form.setFieldsValue(history?.location?.defaultValue)

            if (history?.location?.defaultValue?.channel) {
                handleChannel(history?.location?.defaultValue?.channel)
            }
        }

        if (user.communities && user.communities.length == 1) {
            form.setFieldsValue({ community: user.communities[0] })
        }
    }, [])

    useEffect(() => {
        getProductsList(
            token.token
        ).then((data) => {
            let tempProducts = {}
            for (const i in data.list) {
                tempProducts[data.list[i].id] = data.list[i].status
            }
            setProducts(tempProducts)
        })
    }, [])

    const checkValidity = (values) => {
        if (
            !values?.miniprogram?.packs?.length &&
            !values?.miniprogram?.items?.length &&
            !values?.wechatGroup?.packs?.length &&
            !values?.wechatGroup?.items?.length
        ) {
            return 'Need a least one pack or item'
        }

        let isProductsAvailable = true
        values?.miniprogram?.packs?.forEach((e) => {
            e?.products?.forEach((product) =>
                products[product.product] !== 'available' ? (isProductsAvailable = false) : null
            )
        })
        values?.miniprogram?.items?.forEach((e) =>
            products[e.product] !== 'available' ? (isProductsAvailable = false) : null
        )
        values?.wechatGroup?.packs?.forEach((e) => {
            e?.products?.forEach((product) =>
                products[product.product] !== 'available' ? (isProductsAvailable = false) : null
            )
        })
        values?.wechatGroup?.items?.forEach((e) =>
            products[e.product] !== 'available' ? (isProductsAvailable = false) : null
        )
        if (!isProductsAvailable) {
            Modal.info({
                icon: <ExclamationCircleOutlined />,
                content: t('OFFERS_UNAVAILABLE_PRODUCT'),
                okText: t('OFFERS_GOT_IT'),
                onOk() {},
            });

            return {
                action: 'Open Modal'
            }
        }

        let isOkProductsInPack = true
        values?.miniprogram?.packs?.forEach((e) =>
            !e.products || !e.products.length ? (isOkProductsInPack = false) : null
        )
        values?.wechatGroup?.packs?.forEach((e) =>
            !e.products || !e.products.length ? (isOkProductsInPack = false) : null
        )
        if (!isOkProductsInPack) {
            return 'Selections need at least one item'
        }

        let isOkPrice = true
        values?.miniprogram?.items?.forEach((e) =>
            e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
        )
        values?.miniprogram?.packs?.forEach((e) =>
            e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
        )
        values?.wechatGroup?.packs?.forEach((e) =>
            e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
        )
        values?.wechatGroup?.items?.forEach((e) =>
            e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
        )
        if (!isOkPrice) {
            return 'CREATE_OFFER_CHECK_PRICE'
        }

        let isOkStock = true
        values?.miniprogram?.items?.forEach((e) =>
            e.stock < 0 ? (isOkStock = false) : null
        )
        values?.miniprogram?.packs?.forEach((e) =>
            e.stock < 0 ? (isOkStock = false) : null
        )
        values?.wechatGroup?.packs?.forEach((e) =>
            e.stock < 0 ? (isOkStock = false) : null
        )
        values?.wechatGroup?.items?.forEach((e) =>
            e.stock < 0 ? (isOkStock = false) : null
        )
        if (!isOkStock) {
            return 'Stock can not be empty'
        }

        if (!values.fees || !values.fees.length) {
            return 'Need a least one area on delivery fees'
        }
        for (const fee of values.fees) {
            if (!fee.rules || !fee.rules.length) {
                return 'Rules need a least one rules on each fees area'
            }
        }
        if (!values.deliveryDates || !values.deliveryDates.length) {
            return 'Need a least one area on delivery date'
        }

        let isOkLotteryGifts = true
        if (values?.wechatGroup?.lotteryEnable === true && values?.wechatGroup?.lottery) {
            values?.wechatGroup?.lottery?.draws?.forEach((e) =>
                e?.gifts?.length > 0 ? null : (isOkLotteryGifts = false)
            )
        }
        if (values?.miniprogram?.lotteryEnable === true && values?.miniprogram?.lottery) {
            values?.miniprogram?.lottery?.draws?.forEach((e) =>
                e?.gifts?.length > 0 ? null : (isOkLotteryGifts = false)
            )
        }
        if (!isOkLotteryGifts) {
            return 'Need to add lottery gifts'
        }

        let isOkIllustration = true
        values?.miniprogram?.zikoSpecials?.forEach((e) =>
            e.gift && e.gift?.type === 'custom' && JSON.stringify(e.gift?.customImage) === '{}' ? (isOkIllustration = false) : null
        )
        if (values?.miniprogram?.lotteryEnable === true && values?.miniprogram?.lottery) {
            values?.miniprogram?.lottery?.draws?.forEach((draw) =>
                draw?.gifts?.forEach((gift) =>
                    gift?.type === 'custom' && JSON.stringify(gift?.customImage) === '{}' ? (isOkIllustration = false) : null
                )
            )
        }
        if (!isOkIllustration) {
            return 'Need to upload illustration'
        }

        return false
    }

    return (
        <PageWrap goBack title="MENU_CREATE_AN_OFFER">
            <FormWrap
                form={form}
                onSuccess={'/offers'}
                onCancel={'/offers'}
                title="create_offer"
                request={CreateAnOffer}
                onValuesChange={(e) => {
                    if (e.channel) {
                        handleChannel(e.channel)
                    }
                }}
                data={history?.location?.defaultValue}
                cleanBeforeSending={cleanBeforeSending}
                invalidate={['getOffers', 'getOffersServices']}
                checkValidity={checkValidity}
            >
                <div className="SectionVerticalWrap">
                    <div className="SectionHorizontalWrap">
                        <FormBlock.Basic formTemplate={Settings} />
                    </div>
                    <div className="SectionHorizontalWrap">
                        <FormBlock form={form} formTemplate={Description} />
                        {form?.getFieldValue('channel') !== 'miniprogram' &&
                            <FormBlock formTemplate={ClipboardForm} />
                        }
                    </div>
                    <div className="SectionHorizontalWrap">
                        <Tabs
                            activeKey={tabKey}
                            onChange={(key) => setTabKey(key)}
                            className="customerDetailTab"
                            style={{
                                backgroundColor: '#fff',
                                padding: '0px',
                                flex: 2,
                            }}
                        >
                            <TabPane
                                tab={t('wechat_group')}
                                key="wechat_group"
                                disabled={channel === 'miniprogram'}
                            >
                                <div
                                    className="SectionVerticalWrap"
                                    style={{
                                        flex: 2,
                                    }}
                                >
                                    <FormBlock
                                        form={form}
                                        blockKey={['wechatGroup']}
                                        formTemplate={AddSelectionForm}
                                    />
                                    <FormBlock
                                        form={form}
                                        blockKey={['wechatGroup']}
                                        formTemplate={AddSingleItem}
                                    />
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                            prevValues,
                                            currentValues
                                        ) =>
                                            JSON.stringify(prevValues) !==
                                            JSON.stringify(currentValues)
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('type') ===
                                            'bourse' ? (
                                                <FormBlock
                                                    blockKey={['wechatGroup']}
                                                    formTemplate={BourseRule}
                                                />
                                            ) : null
                                        }
                                    </Form.Item>
                                    <FormBlock
                                        form={form}
                                        blockKey={['wechatGroup']}
                                        formTemplate={ZokiSpecials}
                                    />
                                    <FormBlock
                                        form={form}
                                        blockKey={['wechatGroup']}
                                        formTemplate={Lottery}
                                    />
                                </div>
                            </TabPane>
                            <TabPane
                                tab={t('miniprogram')}
                                key="miniprogram"
                                disabled={channel === 'wechat_group'}
                            >
                                <div
                                    className="SectionVerticalWrap"
                                    style={{
                                        flex: 2,
                                    }}
                                >
                                    <FormBlock
                                        form={form}
                                        blockKey={['miniprogram']}
                                        formTemplate={AddSelectionForm}
                                    />
                                    <FormBlock
                                        form={form}
                                        blockKey={['miniprogram']}
                                        formTemplate={AddSingleItem}
                                    />
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                            prevValues,
                                            currentValues
                                        ) =>
                                            JSON.stringify(prevValues) !==
                                            JSON.stringify(currentValues)
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('type') ===
                                            'bourse' ? (
                                                <FormBlock
                                                    blockKey={['miniprogram']}
                                                    formTemplate={BourseRule}
                                                />
                                            ) : null
                                        }
                                    </Form.Item>
                                    <FormBlock
                                        form={form}
                                        blockKey={['miniprogram']}
                                        formTemplate={ZokiSpecials}
                                    />
                                    <FormBlock
                                        form={form}
                                        blockKey={['miniprogram']}
                                        formTemplate={Lottery}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                        <FormBlock
                            className="offer-create"
                            form={form}
                            formTemplate={OfferDetails}
                        />
                    </div>
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateOffer
