/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react'
import { Cascader } from 'antd'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../context/AppContext'
import { getDeliveryAreas } from '../../network/API'
import { values } from '@antv/util'

const AreaLoader = React.memo(
    ({
        value,
        onChange,
        feeAreas,
        areaOptions,
        availableAreas,
        ...itemProps
    }) => {
        const { token } = useContext(AppContext)
        const { t } = useTranslation()
        // const [options, setOptions] = useState([])
        // const [availableAreas, setAvailableAreas] = useState([])

        // const areas = useQuery(
        //     ['getDeliveryAreas', { token: token.token }],
        //     getDeliveryAreas,
        //     {
        //         // staleTime: Infinity,
        //         cacheTime: Infinity,
        //         refetchIntervalInBackground: false,
        //         // refetchOnMount: false,
        //         refetchOnWindowFocus: false,
        //         refetchOnReconnect: false,
        //         onSuccess: (data) => {
        //             let areaList = [...data.list]
        //             let feeAreasCpy = [...feeAreas]

        //             // if (feeAreas && feeAreas.length) {
        //             //     let topParents = areaList.filter((area) => !area.parent)

        //             //     while (feeAreasCpy.length) {
        //             //         topParents.map((area) => {
        //             //             const inFeeId = feeAreasCpy.findIndex(
        //             //                 (e) => e === area.id
        //             //             )
        //             //             const inAreaId = areaList.findIndex(
        //             //                 (e) => e.id === area.id
        //             //             )

        //             //             if (inFeeId === -1) {
        //             //                 areaList = areaList.map((e) =>
        //             //                     area.id === e.parent
        //             //                         ? { ...e, parent: undefined }
        //             //                         : e
        //             //                 )

        //             //                 areaList.splice(inAreaId, 1)
        //             //             } else {
        //             //                 feeAreasCpy.splice(inFeeId, 1)
        //             //             }
        //             //         })

        //             //         topParents = areaList.filter((area) => !area.parent)
        //             //     }
        //             // }

        //             setAvailableAreas(areaList)
        //             getAreas(undefined, areaList).then((e) => setOptions(e))
        //         },
        //     }
        // )

        const displayRender = (labels, selectedOptions) =>
            labels.length ? <span>{t(labels[labels.length - 1])}</span> : null

        // const getAreas = async (parentId, allAreas) => {
        //     let newAreas = []
        //     const filteredAreas = allAreas.filter((e) => e.parent === parentId)

        //     if (filteredAreas.length) {
        //         for (const line of filteredAreas) {
        //             let childs = await getAreas(line.id, allAreas)

        //             newAreas.push({
        //                 value: line.id,
        //                 label: line.name,
        //                 children: childs.length ? childs : undefined,
        //             })
        //         }
        //     }

        //     return newAreas
        // }

        const filterValue = (value) => {
            if (!availableAreas) return []
            const newArea = []
            // const allAreas = areas.data?.list

            let cur = availableAreas.find((e) => e.id === value)

            while (cur) {
                let parent = cur.parent
                newArea.push(cur.id)
                cur = availableAreas.find((e) => e.id === parent)
            }

            newArea.reverse()

            return newArea
        }

        return (
            <Cascader
                {...itemProps}
                options={areaOptions}
                size="small"
                displayRender={displayRender}
                changeOnSelect
                disabled={itemProps.disabled}
                allowClear={false}
                onChange={(e) => onChange(e[e.length - 1])}
                value={filterValue(value)}
            />
        )
    }
)

export default AreaLoader
