import { useCallback, useContext, useState } from 'react'
import PageWrap from '../components/layout/PageWrap'
import { Tabs, Space, Button, Badge, Input, Modal, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getOffersServices } from '../network/Offers'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { AppContext } from '../context/AppContext'

import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'
import {
    downloadCSV,
    formatDate,
    formatNb,
    keepTwoDecimals,
} from '../utils/utils'
import { poToInvoice, getInvoices, EditInvoice } from '../network/API'
import { getServicesSuppliers } from '../network/Suppliers'

import debounce from 'lodash.debounce'

const { TabPane } = Tabs
const { Title, Text } = Typography

const Services = ({ history }) => {
    const { t } = useTranslation()

    return (
        <PageWrap title="MENU_SERVICES">
            <Tabs
                className="tableTabs"
                defaultActiveKey={history?.location?.defaultTab || 'offers'}
                onChange={() => {
                    const params = new URLSearchParams({
                        filter: '{}',
                        tabFilter: '{}',
                    })
                    history.push({
                        pathname: '/services',
                        search: params.toString(),
                    })
                }}
            >
                <TabPane tab={t('MENU_OFFERS')} key="offers">
                    <TableBlock
                        columns={configOfferColumns}
                        filters={configOfferFilter}
                        name={'getOffersServices'}
                        request={getOffersServices}
                        rowSelection={(offer) => ({
                            disabled: !!offer?.relatedInvoice,
                        })}
                        pageSize={80}
                    />
                </TabPane>
                <TabPane tab={t('SERVICES_INVOICES')} key="invoices">
                    <TableBlock
                        columns={configInvoicesColumns}
                        filters={configInvoicesFilter}
                        name={'getInvoices'}
                        request={getInvoices}
                        pageSize={80}
                    />
                </TabPane>
            </Tabs>
        </PageWrap>
    )
}

const configOfferFilter = (history, selectedLines) => ({
    fields: [
        {
            type: 'selectSearch',
            label: 'SUPPLIERS_SUPPLIER_NAME',
            options: getServicesSuppliers,
            key: 'supplier',
            autoFocus: true,
            isTab: true,
            noAll: true,
            isSearch: true,
        },
        {
            type: 'text',
            label: 'OFFERS_OFFER_ID',
            key: 'offerID',
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    actions: [
        {
            label: 'SERVICES_GENERATE_INVOICE',
            isActive: selectedLines,
            lineAction: poToInvoice,
        },
    ],
})

const configOfferColumns = (goTo) => [
    {
        title: <DynamicStrings id="OFFERS_OFFER_ID" />,
        dataIndex: 'offerID',
        key: 'offerID',
        textWrap: 'word-break',
        // ellipsis: true,
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_NAME" />,
        dataIndex: 'name',
        render: (name) => name?.en,
        key: 'name',
        sorter: true,
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_DATE" />,
        dataIndex: 'endingDate',
        render: (date) => formatDate(date),
        key: 'endingDate',
        sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="supplier" />,
        dataIndex: 'supplier',
        key: 'supplier',
        render: (supplier) => supplier?.name,
        sorter: true,
        ellipsis: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'community',
        render: (commu) => commu.name,
        key: 'community',
        sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="ANALYTICS_TOTAL_ORDERS" />,
        // dataIndex: 'orders',
        key: 'orders',
        render: (e) => formatNb(e.orders),
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="SERVICES_TTL_SALES_W_DELIVERY" />,
        key: 'totalWDelivery',
        render: (e) =>
            // formatNb(
            //     (e.actualIncomePack || 0) +
            //         (e.actualIncomeSingleItem || 0) +
            //         (e.actualIncomeDelivery || 0)
            // ),
            keepTwoDecimals(
                (e.actualIncomePack || 0) +
                    (e.actualIncomeSingleItem || 0) +
                    (e.actualIncomeDelivery || 0)
            ),
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="SERVICES_TTL_SALES_WO_DELIVERY" />,
        key: 'totalWODelivery',
        render: (e) =>
            // formatNb(
            //     (e.actualIncomePack || 0) + (e.actualIncomeSingleItem || 0)
            // ),
            keepTwoDecimals(
                (e.actualIncomePack || 0) + (e.actualIncomeSingleItem || 0)
            ),
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="SERVICES_ZIKOLAND_COMISSION" suffix="(%)" />,
        dataIndex: 'commissionRate',
        key: 'commissionRate',
        render: (commissionRate) =>
            commissionRate ? commissionRate + '%' : '-',
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="SERVICES_ZIKOLAND_COMISSION" suffix="(¥)" />,
        key: 'commissionTotal',
        render: (e) =>
            // formatNb(
            //     ((e.actualIncomePack || 0) + (e.actualIncomeSingleItem || 0)) *
            //         (e?.commissionRate / 100)
            // ),
            keepTwoDecimals(
                ((e.actualIncomePack || 0) + (e.actualIncomeSingleItem || 0)) *
                    (e?.commissionRate / 100)
            ),
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="rfd" suffix="(¥)" />,
        dataIndex: 'refundAmount',
        render: (refundAmount) => keepTwoDecimals(refundAmount),
        key: 'refundAmount',
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_VOUCHER" suffix="(¥)" />,
        dataIndex: 'voucherAmount',
        render: (voucherAmount) => keepTwoDecimals(voucherAmount),
        key: 'voucherAmount',
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="paid" />,
        dataIndex: 'relatedInvoice',
        render: (e) => {
            let status = { color: 'default', text: 'unpaid' }

            if (e?.payment_status === 'paid') {
                status = { color: 'success', text: 'paid' }
            }

            return (
                <Badge
                    status={status.color}
                    text={<DynamicStrings id={status.text} />}
                />
            )
        },
        key: 'relatedInvoice.payment_status',
        // sorter: true,
        textWrap: 'word-break',
    },
    {
        title: <DynamicStrings id="SERVICES_INVOICE_ID" />,
        dataIndex: 'relatedInvoice',
        render: (e) => e?.poId,
        key: 'relatedInvoice',
        textWrap: 'word-break',
    },
    {
        key: 'action',
        width: 220,
        render: (e) => {
            return (
                <Space size="small" className="btnToTheEnd">
                    <Button
                        onClick={() =>
                            downloadCSV(
                                [
                                    'Offer date',
                                    'Supplier',
                                    'Fapiao to issue',
                                    'Pay to vendors',
                                ],

                                //                     e.actualIncomePack ||
                                // e.actualIncomeSingleItem ||
                                // e.actualIncomeDelivery
                                [
                                    [
                                        formatDate(e.startingDate),
                                        e.supplier?.name,
                                        // formatNb(
                                        //     (e.actualIncomePack +
                                        //         e.actualIncomeSingleItem) *
                                        //         (e?.commissionRate / 100)
                                        // ),
                                        // formatNb(
                                        //     e.actualIncomePack +
                                        //         e.actualIncomeSingleItem -
                                        //         (e.actualIncomePack +
                                        //             e.actualIncomeSingleItem) *
                                        //             (e?.commissionRate / 100)
                                        // ),

                                        // keepTwoDecimals(
                                        //     (e.actualIncomePack +
                                        //         e.actualIncomeSingleItem) *
                                        //         (e?.commissionRate / 100)
                                        // ),

                                        keepTwoDecimals(
                                            (e.actualIncome -
                                                e.actualIncomeDelivery) *
                                                (e?.commissionRate / 100)
                                        ),

                                        // keepTwoDecimals(
                                        //     e.actualIncomePack +
                                        //         e.actualIncomeSingleItem -
                                        //         (e.actualIncomePack +
                                        //             e.actualIncomeSingleItem) *
                                        //             (e?.commissionRate / 100)
                                        // ),

                                        // keepTwoDecimals(
                                        //     e.actualIncomePack +
                                        //         e.actualIncomeSingleItem -
                                        //         (e.actualIncomePack +
                                        //             e.actualIncomeSingleItem) *
                                        //             (e?.commissionRate / 100) +
                                        //         e?.actualIncomeDelivery
                                        // ),

                                        keepTwoDecimals(
                                            e.actualIncome -
                                                (e.actualIncome -
                                                    e.actualIncomeDelivery) *
                                                    (e?.commissionRate / 100)
                                        ),
                                    ],
                                ],
                                `Offer ${e.offerID} ${formatDate(
                                    e.startingDate
                                )}`
                            )
                        }
                    >
                        <DynamicStrings id="ANALYTICS_EXPORT_CSV" />
                    </Button>
                    <Button
                        onClick={() => goTo(`/offers/${e.id}/orders`)}
                        type="primary"
                    >
                        <DynamicStrings id="SERVICES_SEE_OFFER" />
                    </Button>
                </Space>
            )
        },
    },
]

const configInvoicesFilter = (history) => ({
    fields: [
        {
            type: 'selectSearch',
            label: 'SUPPLIERS_SUPPLIER_NAME',
            options: getServicesSuppliers,
            key: 'supplier',
            isSearch: true,
            default: history?.location?.supplier || '',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'SERVICES_INVOICE_ID',
            key: 'poId',
        },
        {
            type: 'text',
            label: 'OFFERS_OFFER_ID',
            key: 'offerID',
            noRegex: true,
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
})

const configInvoicesColumns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="SERVICES_INVOICE_ID" />,
        dataIndex: 'poId',
        key: 'poId',
        textWrap: 'word-break',
        // ellipsis: true,
        width: 109,
    },
    {
        title: <DynamicStrings id="supplier" />,
        dataIndex: 'supplier',
        key: 'supplier',
        render: (supplier) => supplier.name,
        textWrap: 'word-break',
        // ellipsis: true,
        width: 242,
    },
    {
        title: <DynamicStrings id="SERVICES_TTL_SALES_W_DELIVERY" suffix="¥" />,
        // dataIndex: 'totalSales',
        key: 'totalSales',
        render: (invoice) => {
            let total = 0
            for (const offer of invoice.relatedOffers) {
                total += offer.stats[0]?.totalAmount || 0
            }
            // return formatNb(total)
            return keepTwoDecimals(total)
        },
        textWrap: 'word-break',
        // ellipsis: true,
        width: 128,
    },
    {
        title: (
            <DynamicStrings id="SERVICES_TTL_SALES_WO_DELIVERY" suffix="¥" />
        ),
        render: (invoice) => {
            let total = 0
            for (const offer of invoice.relatedOffers) {
                total +=
                    (offer.stats[0]?.totalAmount || 0) -
                    (offer.stats[0]?.deliveryFee || 0)
            }
            // return formatNb(total)
            return keepTwoDecimals(total)
        },
        key: 'totalSales',
        textWrap: 'word-break',
        // ellipsis: true,
        width: 131,
    },
    {
        title: <DynamicStrings id={'SERVICES_FEE_PERCENT'} />,
        render: (invoice) => {
            let total = []
            for (const offer of invoice.relatedOffers) {
                if (!total.find((e) => e === offer.commissionRate))
                    formatNb(total.push(offer.commissionRate))
            }
            return total.join(', ')
        },
        key: 'serviceFee',
        textWrap: 'word-break',
        // ellipsis: true,
        width: 87,
    },
    {
        title: <DynamicStrings id={'SERVICES_FEE_RMB'} />,
        // dataIndex: 'serviceFee',

        render: (invoice) => {
            let total = 0
            for (const offer of invoice.relatedOffers) {
                total +=
                    ((offer.stats[0]?.totalAmount || 0) -
                        (offer.stats[0]?.deliveryFee || 0)) *
                    (offer.commissionRate / 100)
            }
            // return formatNb(total)
            return keepTwoDecimals(total)
        },
        key: 'serviceFee',
        textWrap: 'word-break',
        // ellipsis: true,
        width: 127,
    },
    {
        title: <DynamicStrings id="rfd" suffix="¥" />,
        dataIndex: 'totalRefund',
        key: 'totalRefund',
        // render: (e) => formatNb(e) || 0,
        render: (e) => keepTwoDecimals(e) || '0.00',
        textWrap: 'word-break',
        // ellipsis: true,
        width: 101,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_VOUCHER" suffix="¥" />,
        dataIndex: 'totalVouchers',
        key: 'totalVouchers',
        // render: (e) => formatNb(e),
        render: (e) => keepTwoDecimals(e),
        textWrap: 'word-break',
        // ellipsis: true,
        width: 110,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        key: 'payment_status',
        render: (e) => {
            let status = { color: 'default', text: 'unpaid' }

            if (e?.payment_status === 'paid') {
                status = { color: 'success', text: 'paid' }
            }

            return (
                <Badge
                    status={status.color}
                    text={<DynamicStrings id={status.text} />}
                />
            )
        },
        width: 115,
    },
    {
        title: <DynamicStrings id="SERVICES_BANK_TRANSFER_ID" />,
        dataIndex: '',
        key: 'bankTransferID',
        render: (order) => (
            <BankInput
                invoiceId={order.id}
                defaultValue={order.bankTransferID}
            />
        ),
        textWrap: 'word-break',
        ellipsis: true,
        width: 164,
    },
    {
        // title: '',
        // dataIndex: '',
        key: 'action',
        width: 220,
        render: (order) => (
            <Space size="small" className="btnToTheEnd">
                <PaidButton
                    invoiceId={order.id}
                    disabled={order.payment_status === 'paid'}
                    keyRequest={keyRequest}
                />
                <Button
                    onClick={() => {
                        let header = [
                            'Offer date',
                            'Supplier',
                            'Zikoland Service Fee-Fapiao To issue',
                            'Pay to Vendors',
                        ]
                        let offers = []
                        let totalFee = 0
                        let totalGlobal = 0

                        for (const offer of order.relatedOffers) {
                            let fee =
                                ((offer.stats[0]?.totalAmount || 0) -
                                    (offer.stats[0]?.deliveryFee || 0)) *
                                (offer.commissionRate / 100)
                            totalFee += fee
                            totalGlobal +=
                                (offer.stats[0]?.totalAmount || 0) - fee

                            offers.push([
                                formatDate(offer.startingDate),
                                order.supplier?.name,
                                // `${formatNb(fee)}`,
                                // `${formatNb(
                                //     (offer.stats[0]?.totalAmount || 0) - fee
                                // )}`,
                                `${keepTwoDecimals(fee)}`,
                                `${keepTwoDecimals(
                                    (offer.stats[0]?.totalAmount || 0) - fee
                                )}`,
                            ])
                        }

                        offers.push([
                            '',
                            'Total:',
                            // `${formatNb(totalFee)}`,
                            // `${formatNb(totalGlobal)}`,
                            `${keepTwoDecimals(totalFee)}`,
                            `${keepTwoDecimals(totalGlobal)}`,
                        ])

                        downloadCSV(header, offers, order.poId)
                    }}
                    type="primary"
                >
                    <DynamicStrings id="SERVICES_DOWNLOAD_INVOICE" />
                </Button>
            </Space>
        ),
        // textWrap: 'word-break',
        // ellipsis: true,
    },
]

export default Services

const BankInput = ({ defaultValue, invoiceId }) => {
    const { token } = useContext(AppContext)

    const debounced = useCallback(
        debounce((value) => {
            EditInvoice(value, token.token, invoiceId)
        }, 1500),
        [] // will be created only once initially
    )

    return (
        <Input
            size="small"
            onChange={(e) => debounced(e.target.value)}
            defaultValue={defaultValue}
        />
    )
}

const PaidButton = ({ disabled, invoiceId, keyRequest }) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()
    const [showModal, setShowModal] = useState(false)
    const mutation = useMutation(
        (value) => EditInvoice(value, token.token, invoiceId),
        {
            onSuccess: (data, variables, context) => {
                queryClient.setQueryData(keyRequest, (oldData) => {
                    let oldDataIndex = oldData.list.findIndex(
                        (e) => e.id === invoiceId
                    )
                    oldData.list[oldDataIndex].payment_status = 'paid'
                    return oldData
                })
                queryClient.invalidateQueries('getOffersServices')
                setShowModal(false)
            },
        }
    )

    return (
        <>
            <Button
                loading={mutation.isLoading}
                onClick={() => setShowModal(true)}
                disabled={disabled}
                type="primary"
            >
                <DynamicStrings id="paid" />
            </Button>
            <Modal
                title={null}
                visible={showModal}
                okText={<DynamicStrings id="OFFER_LIVE_YES" />}
                cancelText={<DynamicStrings id="OFFER_LIVE_NO" />}
                onOk={() => mutation.mutate({ payment_status: 'paid' })}
                confirmLoading={mutation.isLoading}
                onCancel={() => setShowModal(false)}
            >
                <Space size="large" align="start">
                    <ExclamationCircleOutlined
                        style={{ color: '#FAAD14', fontSize: 24, marginTop: 5 }}
                    />
                    <Space direction="vertical">
                        <Title level={5}>
                            {<DynamicStrings id="DIRECT_SALES_PAYMENT_DONE" />}
                        </Title>
                        <Text type="secondary">
                            {
                                <DynamicStrings id="DIRECT_SALES_SURE_TO_VALIDATE_PAYMENT" />
                            }
                        </Text>
                    </Space>
                </Space>
            </Modal>
        </>
    )
}
