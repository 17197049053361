import { useContext, useState } from 'react'
import { Space, Table, Button, Typography } from 'antd'
import { useQuery } from 'react-query'
import { formatDate, formatNb } from '../../utils/utils'

import { getCustomersVoucher } from '../../network/Customers'
import { AppContext } from '../../context/AppContext'

import DynamicStrings from '../../components/DynamicStrings'

const { Text } = Typography

const CustomersVouchers = ({ userId, user, history }) => {
    const { token, user: userR } = useContext(AppContext)
    const [unclaimedSort, setUnclaimedSort] = useState(['createdAt', 'DESC'])
    const [claimedSort, setClaimedSort] = useState(['createdAt', 'DESC'])

    const pending = useQuery(
        [
            'getCustomersVoucher',
            {
                filter: { customer: userId, status: { $ne: 'used' } },
                token: token.token,
                sort: unclaimedSort,
            },
        ],
        getCustomersVoucher
    )

    const used = useQuery(
        [
            'getCustomersVoucher',
            {
                filter: {
                    customer: userId,
                    status: 'used',
                },
                token: token.token,
                sort: claimedSort,
            },
        ],
        getCustomersVoucher
    )

    const handleClaimedChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setClaimedSort([
                sorter.columnKey,
                sorter.order === 'ascend' ? 'ASC' : 'DESC',
            ])
        } else {
            setClaimedSort(['createdAt', 'DESC'])
        }
    }

    const handleUnclaimedChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setUnclaimedSort([
                sorter.columnKey,
                sorter.order === 'ascend' ? 'ASC' : 'DESC',
            ])
        } else {
            setUnclaimedSort(['createdAt', 'DESC'])
        }
    }

    return (
        <Space
            direction="vertical"
            size={15}
            style={{ width: '100%', paddingBottom: 24 }}
        >
            <div className="customer-voucher-button-wrap">
                {userR.role !== 'logistic' && (
                    <Button
                        onClick={() =>
                            history.push({
                                pathname: '/vouchers/create',
                                customer: user,
                            })
                        }
                        type="primary"
                    >
                        <DynamicStrings id="SERVICES_ORDER_LIST_CREATE_VOUCHER" />
                    </Button>
                )}
            </div>
            <div>
                <div className="customer-voucher-title">
                    <Text>
                        <DynamicStrings id="CUSTOMERS_UNCLAIMED_VOUCHERS" />
                    </Text>
                </div>
                <Table
                    dataSource={pending.data?.list}
                    columns={unclaimedColumn}
                    loading={pending.isLoading}
                    className="tableWrap"
                    style={{
                        padding: '0px 24px',
                    }}
                    onChange={handleUnclaimedChange}
                    pagination={false}
                />
            </div>
            <div>
                <div className="customer-voucher-title">
                    <Text>
                        <DynamicStrings id="CUSTOMERS_USED_VOUCHERS" />
                    </Text>
                </div>
                <Table
                    dataSource={used.data?.list}
                    columns={usedColumn}
                    loading={used.isLoading}
                    style={{
                        padding: '0px 24px',
                    }}
                    onChange={handleClaimedChange}
                    className="tableWrap"
                    pagination={false}
                />
            </div>
        </Space>
    )
}

export default CustomersVouchers

const unclaimedColumn = [
    {
        title: <DynamicStrings id="CUSTOMERS_VOUCHER_ID" />,
        dataIndex: 'voucherID',
        key: 'voucherID',
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_VOUCHER_REASON" />,
        dataIndex: 'reason',
        render: (e) => <DynamicStrings id={e} />,
        key: 'reason',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'communities',
        render: (communities) => (
            <span>
                {communities.map((com, i) => (
                    <span>
                        <DynamicStrings id={com.name} />
                        {i < communities.length - 1 && ', '}
                    </span>
                ))}
            </span>
        ),
        key: 'communities',
        sorter: true,
    },
    {
        title: <DynamicStrings id="amount" />,
        dataIndex: 'amount',
        render: (amount) => formatNb(amount),
        key: 'amount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ZIKO_SPECIALS_EXPIRATION_DATE" />,
        dataIndex: 'expirationDate',
        render: (date) => formatDate(date),
        key: 'expirationDate',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_TEAM_MEMBER" />,
        dataIndex: 'teamMember',
        render: (teamMember) => teamMember?.name,
        key: 'teamMember',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        dataIndex: 'status',
        render: (e) => <DynamicStrings id={e} />,
        key: 'status',
        sorter: true,
    },
]

const usedColumn = [
    {
        title: <DynamicStrings id="CUSTOMERS_VOUCHER_ID" />,
        dataIndex: 'voucherID',
        key: 'voucherID',
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_NAME" />,
        dataIndex: 'order',
        render: (order) => order?.offer?.internalName,
        key: '',
    },
    {
        title: <DynamicStrings id="CUSTOMERS_ORDER_NUMBER" />,
        dataIndex: 'order',
        render: (order) => order?.orderID,
        key: '',
    },
    {
        title: <DynamicStrings id="CUSTOMERS_VOUCHER_REASON" />,
        dataIndex: 'reason',
        render: (e) => <DynamicStrings id={e} />,
        key: 'reason',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'communities',
        render: (communities) => (
            <span>
                {communities.map((com, i) => (
                    <span>
                        <DynamicStrings id={com.name} />
                        {i < communities.length - 1 && ', '}
                    </span>
                ))}
            </span>
        ),
        key: 'communities',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_VOUCHER_AMOUNT" />,
        dataIndex: 'amount',
        render: (amount) => formatNb(amount),
        key: 'amount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_USE_DATE" />,
        dataIndex: 'order',
        render: (order) => formatDate(order?.createdAt),
        key: '',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_TEAM_MEMBER" />,
        dataIndex: 'teamMember',
        render: (teamMember) => teamMember?.name,
        key: 'teamMember',
        sorter: true,
    },
]
