export const inventoryKeyMap = {
  'Dry': 'DRY_FOOD',
  'Seafood & fish': 'SEAFOOD_AND_FISH_FOOD',
  'Meat': 'MEAT_FOOD',
  'Dairy': 'DAIRY_FOOD',
  'Bakery and Pastry': 'BAKERY_AND_PASTRY_FOOD',
  'Vegetables & Fruits': 'VEGETABLES_AND_FRUITS_FOOD',
  'Snack & Dip': 'SNACK_AND_DIP_FOOD',
  'Sweets': 'SWEETS_FOOD',
  'Cold Cut': 'COLD_CUT_FOOD',
  'Seasonings': 'SEASONINGS_FOOD',
  'Poultry': 'POULTRY_FOOD',
  'Others(food)': 'OTHERS_FOOD',
  'Beverage': 'Beverage',
  'Wine': 'WINE_BEVERAGE',
  'Liquor & Spirits': 'LIQUOR_SPIRITS_BEVERAGE',
  'Beer & Cider': 'BEER_CIDER_BEVERAGE',
  'Soft & energy drinks': 'SOFT_ENERGY_DRINKS_BEVERAGE',
  'Sirup': 'SIRUP_BEVERAGE',
  'Others(beverage)': 'OTHERS_BEVERAGE',
  'Fresh food': 'FRESH_FOOD_PET',
  'Dry food': 'DRY_FOOD_PET',
  'Wet food': 'WET_FOOD_PET',
  'Treats': 'TREATS_PET',
  'Skin care': 'SKIN_CARE_PET',
  'Accessories': 'ACCESSORIES_PET',
  'Litter': 'LITTER_PET',
  'Clothes': 'CLOTHES_PET'
}
