import moment from 'moment'

const formatData = (oldData) => {
    if (!oldData) return
    // let oldData = { ...oldDatas }
    const times = (n) => (f) => {
        let iter = (i) => {
            if (i === n) return
            f(i)
            iter(i + 1)
        }
        return iter(0)
    }

    let clipboard = []
    times(10)((i) => {
        clipboard.push(
            i < oldData.clipboard.length
                ? oldData.clipboard[i]
                : { shortcut: undefined, text: undefined }
        )
    })

    oldData.clipboard = clipboard

    // Format date to moment() for datePickers
    if (oldData.startingDate) {
        oldData.startingDate = moment(oldData.startingDate)
        oldData.startingTime = moment(oldData.startingDate)
    }

    if (oldData.endingDate) {
        oldData.endingDate = moment(oldData.endingDate)
        oldData.endingTime = moment(oldData.endingDate)
    }

    let newWx = { ...oldData.wechatGroup }
    if (oldData.wechatGroup) {
        for (
            let drawsIndex = 0;
            drawsIndex < oldData.wechatGroup.lottery?.draws?.length;
            drawsIndex++
        ) {
            for (
                let giftsIndex = 0;
                giftsIndex <
                oldData.wechatGroup.lottery.draws[drawsIndex].gifts?.length;
                giftsIndex++
            ) {
                const currentValue =
                    oldData.wechatGroup.lottery.draws[drawsIndex].gifts[
                        giftsIndex
                    ]

                if (currentValue.voucherExpiration) {
                    newWx.lottery.draws[drawsIndex].gifts[
                        giftsIndex
                    ].voucherExpiration = moment(currentValue.voucherExpiration)
                }
            }
        }

        newWx.packs = newWx.packs?.map((pack) => ({
            ...pack,
            products: pack.products.map((product) => ({
                ...product,
                product: product.product.id,
            })),
        }))

        newWx.items = newWx.items?.map((item) => ({
            ...item,
            product: item.product.id,
        }))

        newWx.zikoSpecials = newWx?.zikoSpecials?.map((special) => ({
            ...special,
            gift: {
                ...special.gift,
                voucherExpiration: special?.gift?.voucherExpiration
                    ? moment(special.gift.voucherExpiration)
                    : null,
            },
        }))
    }

    let newMP = { ...oldData.miniprogram }
    if (oldData.miniprogram) {
        for (
            let drawsIndex = 0;
            drawsIndex < oldData.miniprogram.lottery?.draws?.length;
            drawsIndex++
        ) {
            for (
                let giftsIndex = 0;
                giftsIndex <
                oldData.miniprogram.lottery.draws[drawsIndex].gifts?.length;
                giftsIndex++
            ) {
                const currentValue =
                    oldData.miniprogram.lottery.draws[drawsIndex].gifts[
                        giftsIndex
                    ]

                if (currentValue.voucherExpiration) {
                    newMP.lottery.draws[drawsIndex].gifts[
                        giftsIndex
                    ].voucherExpiration = moment(currentValue.voucherExpiration)
                }
            }
        }

        newMP.packs = newMP.packs?.map((pack) => ({
            ...pack,
            products: pack.products.map((product) => ({
                ...product,
                product: product.product.id,
            })),
        }))

        newMP.items = newMP.items?.map((item) => ({
            ...item,
            product: item.product.id,
        }))

        newMP.zikoSpecials = newMP?.zikoSpecials?.map((special) => ({
            ...special,
            gift: {
                ...special.gift,
                voucherExpiration: special?.gift?.voucherExpiration
                    ? moment(special.gift.voucherExpiration)
                    : null,
            },
        }))
    }

    let newFees = oldData.fees?.map((fee) => {
        let newCities = []
        let cities = { ...fee.area }
        while (cities) {
            newCities.push(cities.id)
            cities = cities.parent
        }

        newCities.reverse()
        return { ...fee, area: newCities }
    })

    return {
        ...oldData,
        miniprogram: newMP,
        wechatGroup: newWx,
        fees: newFees,
    }
}

export default formatData
