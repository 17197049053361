/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useContext,
    useState,
    useCallback,
    useReducer,
    useEffect,
    useMemo,
    useRef,
} from 'react'
import mergeImages from 'merge-images'
import kitchenqrbg from '../../images/kitchenqrbg.png'
import cellarqrbg from '../../images/cellarqrbg.png'
import gardenqrbg from '../../images/gardenqrbg.png'
import farmqrbg from '../../images/farmqrbg.png'
import petqrbg from '../../images/petqrbg.png'
import {
    Input,
    Dropdown,
    InputNumber,
    Typography,
    Avatar,
    Space,
    Select,
    Checkbox,
    Menu,
    Tag,
    Popover,
    Badge,
    Modal,
    message,
    Tooltip,
    Form,
    DatePicker,
} from 'antd'
import moment from 'moment'
import debounce from 'lodash.debounce'
import {
    CloseCircleFilled,
    CheckOutlined,
    ExclamationCircleOutlined,
    EnterOutlined,
} from '@ant-design/icons'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import KeyboardEventHandler from 'react-keyboard-event-handler'

import { WSOrders } from '../../context/WSOrders'

import Logo from '../../images/Ellipse.svg'

import { AppContext } from '../../context/AppContext'
import { OfferLiveWX } from '../../context/OfferLiveWX'
import {
    EditOrders,
    CustomerQuickInfos,
    SERVER,
    getDeliveryAreas,
} from '../../network/API'
import { CreateRefund } from '../../network/Refunds'
import { formatNb } from '../../utils/utils'

import AutoCompleteName from './AutoCompleteName'
import AreaLoader from './AreaLoader'
import ImageCustomer from './ImageCustomer'
import DynamicStrings from '../../components/DynamicStrings'

const { Text, Link } = Typography
const { Option } = Select
const { confirm } = Modal

const isFieldDisabled = (order) =>
    (order.paymentStatus !== 'pending' && order.paymentStatus !== 'paid') ||
    order.status === 'discontinued' ||
    order.paymentStatus === 'refunding' ||
    order.paymentStatus === 'rfd'

const filterVouchers = (vouchers, community, order) => {
    if (!vouchers) return vouchers
    const newVouchers = []

    for (const voucher of vouchers) {
        if (
            voucher.amount <= order.totalAmount &&
            moment(voucher.expirationDate).diff() &&
            voucher.communities?.find((e) => e === community) &&
            voucher.status === 'validated'
        ) {
            newVouchers.push(voucher)
        }
    }
    return newVouchers
}

function orderReducer(state, action) {
    switch (action.type) {
        case 'update':
            return action.payload
        default:
            break
    }
}

function deliveryReducer(state, action) {
    switch (action.type) {
        case 'update':
            if (action.payload !== state) return action.payload
            break

        default:
            break
    }
}

function useTraceUpdate(props) {
    const prev = useRef(props)
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v]
            }
            return ps
        }, {})
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps)
        }
        prev.current = props
    })
}

// let count = 0
const OrderLine = (props) => {
    const {
        lineOrder,
        deliveryDates,
        packs,
        items,
        community,
        feeAreas,
        // ws,
        minimumCartItems,
        minimumOrderAmount,
        areaOptions,
        allAreas,
        availableAreas,
    } = props
    useTraceUpdate(props)
    const { token, user } = useContext(AppContext)
    const isAdmin = user?.role === 'admin' || user?.role === 'super-admin'
    const { t } = useTranslation()
    const { setSelectedLine, setSelectedColumn } = useContext(OfferLiveWX)
    const [order, updateLine] = useReducer(orderReducer, lineOrder)
    const [oldOrder, updateOld] = useState(lineOrder)

    const [deliveryD, setDeliveryD] = useReducer(deliveryReducer, deliveryDates)
    const [communityMemoized, setCommunityMemoized] = useState(community)
    const [packsMemoized, setPacksMemoized] = useState(packs)
    const [itemsMemoized, setItemsMemoized] = useState(items)
    const [feesMemoized, setFeesMemoized] = useState(feeAreas)

    const [visible, setVisible] = useState(false)
    const [refundAmount, setRefundAmount] = useState(0)
    const [actionETA, setActionETA] = useState('')
    const [resp, setResp] = useState('')
    const [issue, setIssue] = useState('')
    const [issueDetail, setIssueDetail] = useState('')
    const [comment, setComment] = useState('')

    const [lineIsSelected, setLineIsSelected] = useState(false)
    const { wsOffer } = useContext(WSOrders)

    // count++
    // console.log(order?.orderNumber, 'component render number: ', count)

    useEffect(() => {
        try {
            wsOffer.addEventListener('message', (message) => {
                let data = JSON.parse(message.data)
                // console.log('WebSocket - Message recieved line', data)

                switch (data?.type) {
                    case 'NEW_ORDER':
                    case 'UPDATE_ORDER':
                        if (data.order.id === order.id) {
                            updateLine({ type: 'update', payload: data.order })
                        }
                        break
                    default:
                        break
                }
            })
        } catch (error) {
            return error
        }
    }, [])

    const onChangeInput = (order) => {
        updateLine({ type: 'update', payload: order })
        debouncedSave(order)
    }

    // highlight-starts
    const debouncedSave = useCallback(
        debounce((nextValue) => {
            EditOrders(
                {
                    ...nextValue,
                    customer: nextValue.customer ? nextValue.customer.id : null,
                    offer: undefined,
                    supplier: undefined,
                    community: undefined,
                    packs: undefined,
                    singleItems: undefined,
                    vouchers: undefined,
                    paymentStatus: undefined,
                    status: undefined,
                    paymentQRCode: undefined,
                    paymentH5: undefined,
                    wechatPayment: undefined,
                    paymentRefund: undefined,
                },
                token.token,
                nextValue.id
            )
        }, 1000),
        [] // will be created only once initially
    )

    const getAreaName = (id) => {
        const name = allAreas?.find((a) => {
            return a.id === id || a.id === id?.id
        })?.name

        return name ? `(${name})` : ''
    }

    const debouncedPack = useCallback(
        debounce((nextValue) => {
            EditOrders(
                {
                    ...nextValue,
                    offer: undefined,
                    supplier: undefined,
                    community: undefined,
                    customer: undefined,
                    vouchers: undefined,
                    paymentStatus: undefined,
                    status: undefined,
                    paymentQRCode: undefined,
                    paymentH5: undefined,
                    wechatPayment: undefined,
                    paymentRefund: undefined,
                },
                token.token,
                nextValue.id
            )
                .then((newValue) =>
                    updateLine({
                        type: 'update',
                        payload: {
                            ...nextValue,
                            totalAmount: newValue.totalAmount,

                            deliveryFee: newValue.deliveryFee,
                            actualAmount: newValue.actualAmount,
                            vouchers: newValue.vouchers,

                            // paymentQRCode: newValue.paymentQRCode,
                        },
                    })
                )
                .catch((error) => {
                    message.error(
                        error?.response?.data?.message || 'Error updating order'
                    )
                    updateLine({
                        type: 'update',
                        payload: oldOrder,
                    })
                })
        }, 1500),
        [] // will be created only once initially
    )

    const debouncedShortcut = useCallback(
        debounce((newOrder, oldOrder) => {
            EditOrders(
                {
                    ...newOrder,
                    offer: undefined,
                    supplier: undefined,
                    community: undefined,
                    customer: undefined,
                    vouchers: undefined,
                    paymentStatus: undefined,
                    status: undefined,
                    paymentQRCode: undefined,
                    paymentH5: undefined,
                    wechatPayment: undefined,
                    paymentRefund: undefined,
                },
                token.token,
                newOrder.id
            )
                .then()
                .catch(() =>
                    updateLine({
                        type: 'update',
                        payload: oldOrder,
                    })
                )
        }, 1000),
        [] // will be created only once initially
    )

    const debouncedName = useCallback(
        debounce((newOrder, oldOrder) => {
            EditOrders(
                {
                    ...newOrder,
                    customer: newOrder.customer ? newOrder.customer.id : null,
                    offer: undefined,
                    supplier: undefined,
                    community: undefined,
                    packs: undefined,
                    singleItems: undefined,
                    vouchers: undefined,
                    paymentStatus: undefined,
                    status: undefined,
                    paymentQRCode: undefined,
                    paymentH5: undefined,
                    wechatPayment: undefined,
                    paymentRefund: undefined,
                },
                token.token,
                order.id
            )
                .then((recievedOrder) =>
                    updateLine({
                        type: 'update',
                        payload: {
                            ...newOrder,
                            vouchers: recievedOrder.vouchers,
                            totalAmount: recievedOrder.totalAmount,
                            actualAmount: recievedOrder.actualAmount,
                        },
                    })
                )
                .catch(() =>
                    updateLine({
                        type: 'update',
                        payload: oldOrder,
                    })
                )
        }, 1000),
        [] // will be created only once initially
    )

    const totalPacks = useMemo(() => {
        let total = 0

        for (const pack of order.packs) {
            total += pack.amount
        }

        return total
    }, [order.packs])
    const totalItems = useMemo(() => {
        let total = 0

        for (const item of order.singleItems) {
            total += item.amount
        }

        return total
    }, [order.singleItems])

    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const columnsPart1 = [
        {
            width: 24.5,
            textWrap: 'word-break',
            key: '01',
            render: () => (
                <div style={{ margin: 5 }}>
                    <CloseCircleFilled
                        onClick={() =>
                            !isFieldDisabled(order)
                                ? confirm({
                                      centered: true,
                                      title: 'Cancel order',
                                      icon: <ExclamationCircleOutlined />,
                                      content:
                                          'Are you sure delete this order?',
                                      okText: 'Yes',
                                      cancelText: 'No',
                                      onOk() {
                                          let newPack = {}

                                          if (
                                              order.paymentStatus === 'pending'
                                          ) {
                                              newPack.packs = []
                                              newPack.singleItems = []
                                          } else if (
                                              order.paymentStatus === 'paid'
                                          ) {
                                              newPack.paymentStatus = 'ccl'
                                          }

                                          const modifOrder = {
                                              status: 'discontinued',
                                              ...newPack,
                                              voucherSelection: 'manual',
                                              vouchers: [],
                                          }
                                          updateLine({
                                              type: 'update',
                                              payload: {
                                                  ...order,
                                                  ...modifOrder,
                                              },
                                          })
                                          EditOrders(
                                              {
                                                  ...order,
                                                  ...modifOrder,
                                                  offer: undefined,
                                                  supplier: undefined,
                                                  community: undefined,
                                                  customer: undefined,
                                              },
                                              token.token,
                                              order.id
                                          )
                                      },
                                  })
                                : null
                        }
                        style={{
                            color: isFieldDisabled(order) ? '#ccc' : '#FF4040',
                        }}
                    />
                </div>
            ),
        },
        {
            width: 30,
            textWrap: 'word-break',
            key: '02',
            title: 'N',
            render: (order, t, i) => (
                <Text ellipsis={true} disabled={isFieldDisabled(order)}>
                    {order?.orderNumber}
                </Text>
            ),
        },
        {
            width: 50,
            textWrap: 'word-break',
            key: '03',
            title: 'Order ID',
            render: (order) => (
                <Tooltip placement="right" title={order.orderID}>
                    <Text
                        ellipsis={true}
                        disabled={isFieldDisabled(order)}
                        style={{ width: 50 }}
                    >
                        {order.orderID}
                    </Text>
                </Tooltip>
            ),
        },
        {
            width: 35,
            textWrap: 'word-break',
            key: '04',
            render: (order) => (
                <ImageCustomer
                    order={order}
                    onUserCreated={(e) => {
                        let orderModif = {
                            customerAddress: {
                                area: e.addresses[0]?.area,
                                city: e.addresses[0]?.city,
                                detailedAddress:
                                    e.addresses[0]?.detailedAddress,
                                needSave: false,
                                // phone: e.contacts[0].phone,
                                phone: e.addresses[0]?.phone,
                                contact: e.addresses[0]?.contact,
                                type: e.addresses[0]?.type,
                                zipCode: e.addresses[0]?.zipCode,
                            },
                            // customerAddress: {
                            //     area: feesMemoized[0],
                            //     needSave: false,
                            //     type: 'temporary',
                            // },
                            vouchers: [],
                            // groupNumber: e.wechatGroup,
                            addresses: e.addresses,
                            customerDaily: null,
                            customer: {
                                id: e.id,
                                name: e.name,
                                profilePicture: {},
                            },
                        }

                        updateLine({
                            type: 'update',
                            payload: {
                                ...order,
                                ...orderModif,
                                // customer: {
                                //     id: e.id,
                                //     name: e.name,
                                //     profilePicture: { uri: e.profilePicture },
                                // },
                            },
                        })
                        debouncedName({ ...order, ...orderModif }, order)
                    }}
                />
            ),
        },
        {
            width: 150,
            textWrap: 'word-break',
            key: 'name',
            title: 'Name',
            render: (order) => (
                <AutoCompleteName
                    id={order.id}
                    canAutoUpdate={!order?.customer?.id}
                    type="text"
                    size="small"
                    onFocus={() => setLineIsSelected(true)}
                    onBlur={() => setLineIsSelected(false)}
                    community={communityMemoized}
                    className={
                        order.paymentStatus === 'ccl'
                            ? undefined
                            : `${
                                  order.paymentMethod === 'alipay'
                                      ? 'is-alipay'
                                      : ''
                              }${
                                  // !order.groupNumber ||
                                  !order.customerAddress?.phone ||
                                  !order.customerAddress?.detailedAddress ||
                                  !order.customerAddress?.city ||
                                  !order.deliveryDate
                                      ? ' is-low'
                                      : ''
                              }
                        ${
                            !order?.customer?.name &&
                            !order?.customerDaily?.customerName
                                ? ' is-low'
                                : ''
                        }`
                    }
                    bordered={false}
                    disabled={
                        isFieldDisabled(order) || order.paymentStatus === 'paid'
                    }
                    value={
                        order?.customer?.id
                            ? order.customer.name
                            : order.customerDaily?.customerName
                    }
                    token={token.token}
                    availableAreas={availableAreas}
                    onSelect={(r) => {
                        const e = JSON.parse(r)

                        const availAreas = e?.addresses?.filter(
                            (e) =>
                                !!availableAreas?.find((a) => {
                                    return a.id === e?.area
                                })
                        )

                        const customerAddress = availAreas.length
                            ? {
                                  area: availAreas[0]?.area,
                                  city: availAreas[0]?.city,
                                  detailedAddress:
                                      availAreas[0]?.detailedAddress,
                                  needSave: false,
                                  //   phone: '',
                                  phone: availAreas[0]?.phone,
                                  contact: availAreas[0]?.contact,
                                  type: availAreas[0]?.type,
                                  zipCode: availAreas[0]?.zipCode,
                              }
                            : {
                                  //   area: feesMemoized[0],
                                  needSave: false,
                                  type: 'temporary',
                              }

                        let orderModif = {
                            customerAddress,
                            // customerAddress: {
                            //     area: feesMemoized[0],
                            //     needSave: false,
                            //     type: 'temporary',
                            // },
                            vouchers: [],
                            addresses: e.addresses,
                            groupNumber: e.wechatGroup,
                            customerDaily: null,
                            customer: {
                                id: e.id,
                                name: e.name,
                                profilePicture: { uri: e.profilePicture },
                            },
                        }

                        updateLine({
                            type: 'update',
                            payload: {
                                ...order,
                                ...orderModif,
                                // customer: {
                                //     id: e.id,
                                //     name: e.name,
                                //     profilePicture: { uri: e.profilePicture },
                                // },
                            },
                        })
                        debouncedName({ ...order, ...orderModif }, order)
                    }}
                    onSearch={(e) => {
                        let orderModif = {}
                        if (order.customer) {
                            orderModif.customerAddress = {
                                // area: feesMemoized[0],
                                type: 'temporary',
                            }
                            orderModif.vouchers = []
                            orderModif.customer = null
                            orderModif.groupNumber = undefined
                        }

                        orderModif.customerDaily = {
                            ...order.customerDaily,
                            customerName: e,
                        }

                        updateLine({
                            type: 'update',
                            payload: { ...order, ...orderModif },
                        })
                        debouncedName({ ...order, ...orderModif }, order)
                    }}
                />
            ),
        },
        {
            width: 37,
            textWrap: 'word-break',
            key: 'grp',
            title: 'Grp',
            render: (order) => (
                <InputNumber
                    type="text"
                    size="small"
                    // className={`${order.groupNumber ? '' : ' is-low'}`}
                    min={1}
                    bordered={false}
                    onFocus={() => setLineIsSelected(true)}
                    onBlur={() => setLineIsSelected(false)}
                    disabled={isFieldDisabled(order)}
                    value={order.groupNumber}
                    onChange={(e) =>
                        onChangeInput({ ...order, groupNumber: e })
                    }
                />
            ),
        },
    ]
    const columnsPart2 = [
        {
            width: 107,
            textWrap: 'word-break',
            key: '07',
            title: 'Phone',
            render: (order) => {
                const menu = (
                    <Menu>
                        {order?.customer?.contacts?.map((contact) => (
                            <Menu.Item
                                onClick={() =>
                                    onChangeInput({
                                        ...order,
                                        customerAddress: {
                                            ...order.customerAddress,
                                            phone: contact.phone,
                                        },
                                    })
                                }
                            >
                                {`${contact.phone} (${contact.name})`}
                            </Menu.Item>
                        ))}
                    </Menu>
                )

                return (
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        disabled={isFieldDisabled(order)}
                    >
                        <Input
                            type="text"
                            size="small"
                            className={
                                order.paymentStatus === 'ccl'
                                    ? undefined
                                    : `${
                                          order.customerAddress?.phone
                                              ? order?.customer?.contacts
                                                    ?.length > 1
                                                  ? ' green'
                                                  : ''
                                              : ' is-low'
                                      }${
                                          !!order?.customer?.contacts?.find(
                                              (e) =>
                                                  e.phone ===
                                                  order?.customerAddress?.phone
                                          )
                                              ? ''
                                              : ' is-highlight'
                                      }`
                            }
                            onFocus={() => setLineIsSelected(true)}
                            onBlur={() => setLineIsSelected(false)}
                            value={order?.customerAddress?.phone}
                            bordered={false}
                            disabled={isFieldDisabled(order)}
                            onChange={(e) =>
                                onChangeInput({
                                    ...order,
                                    customerAddress: {
                                        ...order.customerAddress,
                                        phone: e.target.value,
                                    },
                                })
                            }
                        />
                    </Dropdown>
                )
            },
        },
        {
            width: 134,
            textWrap: 'word-break',
            key: 'address',
            title: 'Delivery address', //iciici
            render: (order) => (
                <Dropdown
                    disabled={isFieldDisabled(order)}
                    overlay={
                        <Menu>
                            {order?.customer?.addresses?.map((address, i) => (
                                <Menu.Item
                                    key={i}
                                    // disabled={
                                    //     availableAreas?.find(
                                    //         (a) => a.id === address?.area?.id
                                    //     )
                                    //         ? false
                                    //         : true
                                    // }

                                    disabled={
                                        availableAreas?.find(
                                            (a) =>
                                                a.id === address?.area ||
                                                address?.area?.id
                                        )
                                            ? false
                                            : true
                                    }
                                    onClick={() => {
                                        const newAdd = {
                                            city: address?.city,
                                            detailedAddress:
                                                address?.detailedAddress,
                                            // phone: order?.customerAddress
                                            //     ?.phone,
                                            phone: address?.phone,
                                            contact: address?.contact,
                                            type: address?.type,
                                            zipCode: address?.zipCode,
                                            needSave: false,
                                            area:
                                                address?.area?.id ||
                                                address?.area,
                                        }

                                        updateLine({
                                            type: 'update',
                                            payload: {
                                                ...order,
                                                customerAddress: newAdd,
                                            },
                                        })

                                        EditOrders(
                                            {
                                                ...order,
                                                customer: order?.customer.id,
                                                customerAddress: newAdd,
                                                packs: undefined,
                                                items: undefined,
                                                offer: undefined,
                                                supplier: undefined,
                                                community: undefined,
                                                vouchers: undefined,
                                                paymentStatus: undefined,
                                                status: undefined,
                                                paymentQRCode: undefined,
                                                paymentH5: undefined,
                                                wechatPayment: undefined,
                                                paymentRefund: undefined,
                                            },
                                            token.token,
                                            order.id
                                        )
                                    }}
                                >
                                    {`${address.type} ${getAreaName(
                                        address?.area
                                    )}`}
                                </Menu.Item>
                            ))}
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Input
                        type="text"
                        disabled={isFieldDisabled(order)}
                        bordered={false}
                        className={
                            order.paymentStatus === 'ccl'
                                ? undefined
                                : `${
                                      order.customerAddress?.detailedAddress
                                          ? order?.customer?.addresses?.length >
                                            1
                                              ? ' green'
                                              : ''
                                          : ' is-low'
                                  }${
                                      !!order?.customer?.addresses?.find(
                                          (e) =>
                                              e?.detailedAddress ===
                                              order.customerAddress
                                                  ?.detailedAddress
                                      )
                                          ? ''
                                          : ' is-highlight'
                                  }`
                        }
                        size="small"
                        value={order.customerAddress?.detailedAddress}
                        onFocus={() => setLineIsSelected(true)}
                        onBlur={() => setLineIsSelected(false)}
                        onChange={(e) => {
                            const newOrder = { ...order }
                            if (
                                newOrder.customerAddress?.type === 'temporary'
                            ) {
                                newOrder.customerAddress.detailedAddress =
                                    e.target.value
                            } else {
                                newOrder.customerAddress = {
                                    detailedAddress: e.target.value,
                                    city: '',
                                    zipCode: '',
                                    phone: newOrder.customerAddress.phone,
                                    type: 'temporary',
                                    area: feeAreas[0],
                                }
                            }
                            onChangeInput(newOrder)
                        }}
                    />
                </Dropdown>
            ),
        },
        {
            width: 95,
            textWrap: 'word-break',
            key: '09',
            title: 'City',
            render: (order) => (
                <Input
                    type="text"
                    size="small"
                    className={
                        order.paymentStatus === 'ccl'
                            ? undefined
                            : `${order.customerAddress?.city ? '' : ' is-low'}`
                    }
                    bordered={false}
                    disabled={
                        isFieldDisabled(order) ||
                        order.customerAddress?.type !== 'temporary'
                    }
                    value={order.customerAddress?.city}
                    onFocus={() => setLineIsSelected(true)}
                    onBlur={() => setLineIsSelected(false)}
                    onChange={(e) => {
                        const modifAddress = {
                            ...order.customerAddress,
                            city: e.target.value,
                        }
                        onChangeInput({
                            ...order,
                            customerAddress: modifAddress,
                        })
                    }}
                />
            ),
        },
        {
            width: 90,
            textWrap: 'word-break',
            key: 'area',
            title: 'Area',
            render: (order) => (
                <AreaLoader
                    feeAreas={feesMemoized}
                    disabled={
                        // isFieldDisabled(order) ||
                        // order.customerAddress?.type !== 'temporary'
                        isFieldDisabled(order) || order.paymentStatus === 'paid'
                    }
                    areaOptions={areaOptions}
                    availableAreas={availableAreas}
                    bordered={false}
                    onFocus={() => setLineIsSelected(true)}
                    onBlur={() => setLineIsSelected(false)}
                    onChange={(e) => {
                        const modifAddress = {
                            ...order.customerAddress,
                            area: e,
                        }
                        onChangeInput({
                            ...order,
                            customerAddress: modifAddress,
                        })
                    }}
                    value={order.customerAddress?.area}
                />
            ),
        },
        {
            width: 134,
            textWrap: 'word-break',
            key: 'comment',
            title: 'Comment',
            render: (order) => (
                <Input
                    type="text"
                    size="small"
                    // className={`${order?.comment ? '' : ' is-low'}`}
                    bordered={false}
                    disabled={isFieldDisabled(order)}
                    value={order.comment}
                    onFocus={() => setLineIsSelected(true)}
                    onBlur={() => setLineIsSelected(false)}
                    onChange={(e) => {
                        onChangeInput({ ...order, comment: e.target.value })
                    }}
                />
            ),
        },
        {
            width: 55,
            textWrap: 'word-break',
            key: '11',
            title: 'Delivery date',
            render: (order) => {
                const menu = (
                    <Menu>
                        {deliveryD?.map((date) => (
                            <Menu.Item
                                onClick={() =>
                                    onChangeInput({
                                        ...order,
                                        // deliveryDate:
                                        //     moment(date).format('ddd MM.DD'),
                                        deliveryDate: date,
                                    })
                                }
                            >
                                {moment(date).format('ddd MM.DD')}
                            </Menu.Item>
                        ))}
                    </Menu>
                )

                return (
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        disabled={isFieldDisabled(order)}
                    >
                        <Input
                            type="text"
                            size="small"
                            // className={
                            //     order.paymentStatus === 'ccl'
                            //         ? undefined
                            //         : `${order?.deliveryDate ? '' : ' is-low'}${
                            //               !!deliveryD?.find(
                            //                   (e) =>
                            //                       moment(e).format(
                            //                           'ddd MM.DD'
                            //                       ) === order?.deliveryDate
                            //               )
                            //                   ? ''
                            //                   : ' is-highlight'
                            //           }`
                            // }
                            className={
                                order.paymentStatus === 'ccl'
                                    ? undefined
                                    : `${order?.deliveryDate ? '' : ' is-low'}${
                                          !!deliveryD?.find(
                                              (e) =>
                                                  moment(e).format(
                                                      'ddd MM.DD'
                                                  ) === order?.deliveryDate ||
                                                  moment(e).format(
                                                      'ddd MM.DD'
                                                  ) ===
                                                      moment(
                                                          order?.deliveryDate
                                                      ).format('ddd MM.DD')
                                          )
                                              ? ''
                                              : ' is-highlight'
                                      }`
                            }
                            onFocus={() => setLineIsSelected(true)}
                            onBlur={() => setLineIsSelected(false)}
                            // value={order?.deliveryDate}
                            value={
                                order?.deliveryDate &&
                                moment(order?.deliveryDate).isValid()
                                    ? moment(order?.deliveryDate).format(
                                          'ddd MM.DD'
                                      )
                                    : order?.deliveryDate
                            }
                            bordered={false}
                            disabled={isFieldDisabled(order)}
                            onChange={(e) =>
                                onChangeInput({
                                    ...order,
                                    deliveryDate: e.target.value,
                                })
                            }
                        />
                    </Dropdown>
                )
            },
        },
        {
            width: 55,
            textWrap: 'word-break',
            key: '12',
            title: 'Delivery fee',
            render: (order) => (
                <InputNumber
                    min={0}
                    type="text"
                    size="small"
                    bordered={false}
                    disabled={
                        isFieldDisabled(order) || order.paymentStatus === 'paid'
                    }
                    onFocus={() => setLineIsSelected(true)}
                    onBlur={() => setLineIsSelected(false)}
                    value={order.deliveryFee}
                    onChange={(e) =>
                        onChangeInput({
                            ...order,
                            deliveryFee: e,
                            deliverySelection:
                                !e && e !== 0 ? 'auto' : 'manual',
                        })
                    }
                />
            ),
        },
        // {
        //     width: 35,
        //     textWrap: 'word-break',
        //     key: 'fapiao',
        //     title: 'Fapiao',
        //     // className: (order) =>
        //     //     order.fapiao && !isFieldDisabled(order)
        //     //         ? ' checked'
        //     //         : ' unchecked',
        //     render: (order) => (
        //         <Checkbox
        //             disabled={isFieldDisabled(order)}
        //             onFocus={() => setLineIsSelected(true)}
        //             onBlur={() => setLineIsSelected(false)}
        //             onChange={(e) => {
        //                 updateLine({
        //                     type: 'update',
        //                     payload: { ...order, fapiao: e.target.checked },
        //                 })
        //                 debouncedShortcut(
        //                     {
        //                         ...order,
        //                         fapiao: e.target.checked,
        //                     },
        //                     order
        //                 )
        //             }}
        //             checked={order.fapiao}
        //         />
        //     ),
        // },
        {
            width: 62,
            textWrap: 'word-break',
            key: 'voucher',
            title: 'Voucher',
            render: (order) => (
                //  {
                //     console.log('vouvou', order?.customer?.vouchers)
                //     return null
                // },
                // filterVouchers(
                //     order?.customer?.vouchers,
                //     order?.community,
                //     order
                // )?.length ? (
                <Select
                    disabled={
                        isFieldDisabled(order) || order.paymentStatus === 'paid'
                    }
                    bordered={false}
                    // className={`${
                    //     !order.vouchers?.length ? '' : ' is-highlight'
                    // }`}
                    className={`${
                        !filterVouchers(
                            order?.customer?.vouchers,
                            community,
                            order
                        )?.length
                            ? ''
                            : order.vouchers?.length
                            ? ' green'
                            : ' is-highlight'
                    }`}
                    size="small"
                    onFocus={() => setLineIsSelected(true)}
                    onBlur={() => setLineIsSelected(false)}
                    value={
                        !!order.vouchers?.length
                            ? `${order.vouchers[0]?.amount}(${order.vouchers[0]?.voucherID})`
                            : ''
                    }
                    onChange={(e) => {
                        let voucherValue = null
                        if (e !== '') {
                            voucherValue = JSON.parse(e)
                        }
                        updateLine({
                            type: 'update',
                            payload: {
                                ...order,
                                vouchers: voucherValue ? [voucherValue] : [],
                            },
                        })
                        EditOrders(
                            {
                                ...order,
                                vouchers: voucherValue ? [voucherValue.id] : [],
                                voucherSelection: 'manual',
                                packs: undefined,
                                items: undefined,
                                offer: undefined,
                                supplier: undefined,
                                community: undefined,
                                customer: undefined,
                                paymentStatus: undefined,
                                status: undefined,
                                paymentQRCode: undefined,
                                paymentH5: undefined,
                                wechatPayment: undefined,
                                paymentRefund: undefined,
                            },
                            token.token,
                            order.id
                        )
                            .then((newOrder) =>
                                updateLine({
                                    type: 'update',
                                    payload: {
                                        ...order,
                                        vouchers: voucherValue
                                            ? [voucherValue]
                                            : [],
                                        totalAmount: newOrder.totalAmount,
                                        actualAmount: newOrder.actualAmount,
                                        // paymentQRCode: newOrder.paymentQRCode,
                                    },
                                })
                            )
                            .catch(() =>
                                updateLine({
                                    type: 'update',
                                    payload: order,
                                })
                            )
                    }}
                >
                    <Option value={''}>-</Option>
                    {filterVouchers(
                        order?.customer?.vouchers,
                        community,
                        order
                    )?.map((voucher) => (
                        <Option
                            key={voucher.id}
                            value={JSON.stringify(voucher)}
                        >
                            {`${voucher.amount}(${voucher.voucherID})`}
                        </Option>
                    ))}
                </Select>
            ),
            // ) : (
            //     <Text
            //         ellipsis={true}
            //         disabled={
            //             isFieldDisabled(order) ||
            //             order.paymentStatus === 'paid'
            //         }
            //     >
            //         {order.vouchers.length ? order.vouchers[0]?.amount : ''}
            //     </Text>
            // ),
        },
        {
            width: 29,
            textWrap: 'word-break',
            key: '15',
            title: 'TK.',
            render: (order) => (
                <Text ellipsis={true} disabled={isFieldDisabled(order)}>
                    {order.ticketAmount}
                </Text>
            ),
        },
        {
            width: 58,
            textWrap: 'word-break',
            title: 'Total (¥)',
            key: 'total',
            // className: (order) =>
            //     order.paymentMethod === 'alipay' ? ' is-alipay' : '',
            render: (order) => (
                <>
                    {/* {`${totalPacks} && ${minimumCartItems} > ${totalItems}`} */}
                    <Text
                        className={
                            (!totalPacks && minimumCartItems > totalItems) ||
                            (minimumOrderAmount &&
                                minimumOrderAmount >=
                                    order.totalAmount - order.deliveryFee)
                                ? 'red-text'
                                : order.paymentStatus === 'paid'
                                ? 'green-text'
                                : ''
                        }
                        ellipsis={true}
                        style={
                            order.paymentStatus === 'paid'
                                ? {
                                      fontWeight: 'bold',
                                      fontSize: 15,
                                  }
                                : {}
                        }
                        disabled={isFieldDisabled(order)}
                    >
                        {formatNb(order.actualAmount)}
                    </Text>
                </>
            ),
        },
        {
            width: 66,
            textWrap: 'word-break',
            key: '17',
            title: 'Payments Status',
            render: (order) => {
                // <Select
                //     disabled={isFieldDisabled(order) && !isAdmin}
                //     bordered={false}
                //     size="small"
                //     onFocus={() => setLineIsSelected(true)}
                //     onBlur={() => setLineIsSelected(false)}
                //     value={order.paymentStatus}
                //     onChange={(e) => {
                //         let newOrder = { paymentStatus: e }
                //         if (e === 'ccl' || e === 'rfd') {
                //             if (order.paymentStatus === 'pending') {
                //                 if (e === 'ccl') {
                //                     newOrder.paymentStatus = 'pending'
                //                 }

                //                 newOrder.packs = []
                //                 newOrder.singleItems = []
                //                 newOrder.deliveryFee = 0
                //                 newOrder.deliverySelection = 'manual'
                //             }
                //             newOrder.status = 'discontinued'
                //         } else if (e === 'paid') {
                //             newOrder.status = 'available'
                //         }

                //         updateLine({
                //             type: 'update',
                //             payload: { ...order, ...newOrder },
                //         })

                //         if (e !== 'ccl') {
                //             newOrder.packs = undefined
                //             newOrder.singleItems = undefined
                //         }
                //         EditOrders(
                //             {
                //                 ...order,
                //                 ...newOrder,
                //                 offer: undefined,
                //                 supplier: undefined,
                //                 community: undefined,
                //                 customer: undefined,
                //                 vouchers: undefined,
                //             },
                //             token.token,
                //             order.id
                //         )
                //     }}
                // >
                //     {order.paymentStatus === 'pending' && (
                //         <Option key={'pending'}>{t('pending')}</Option>
                //     )}
                //     {(order.paymentStatus === 'paid' ||
                //         order.paymentStatus === 'rfd') && (
                //         <Option key={'rfd'}>{t('rfd')}</Option>
                //     )}
                //     {(order.paymentStatus === 'pending' ||
                //         order.paymentStatus === 'paid' ||
                //         isAdmin) && (
                //         <Option key={'paid'}>
                //             <CheckOutlined
                //                 style={{ color: '#52C41A', fontSize: 22 }}
                //             />
                //         </Option>
                //     )}

                //     <Option key={'ccl'}>{t('ccl')}</Option>

                //     {/* <Option key={'pending'}>pending</Option> */}
                // </Select>

                if (order.paymentStatus === 'refunding') {
                    return (
                        <Text
                            className="option-refund ant-select-selector"
                            style={{ textAlign: 'left' }}
                        >
                            {t('rfd')}
                        </Text>
                    )
                }
                if (order.paymentStatus === 'rfd') {
                    return (
                        <Popover
                            placement="bottom"
                            content={() => {
                                return (
                                    <>
                                        <EnterOutlined
                                            style={{
                                                transform: 'scale(-1, 1)',
                                                color: '#BBBBBB',
                                                marginRight: 5,
                                            }}
                                        />
                                        {order.refundAmount}
                                    </>
                                )
                            }}
                        >
                            <Text
                                className="ant-select-selector"
                                style={{ textAlign: 'left' }}
                            >
                                {t('refunded')}
                            </Text>
                        </Popover>
                    )
                }

                return (
                    <>
                        <Select
                            disabled={isFieldDisabled(order) && !isAdmin}
                            bordered={false}
                            size="small"
                            onFocus={() => setLineIsSelected(true)}
                            onBlur={() => setLineIsSelected(false)}
                            value={order.paymentStatus}
                            onChange={(e) => {
                                let newOrder = { paymentStatus: e }
                                if (
                                    e === 'ccl' ||
                                    e === 'refunding' ||
                                    e === 'rfd'
                                ) {
                                    if (order.paymentStatus === 'pending') {
                                        if (e === 'ccl') {
                                            newOrder.paymentStatus = 'pending'
                                        }
                                        newOrder.packs = []
                                        newOrder.singleItems = []
                                        newOrder.deliveryFee = 0
                                        newOrder.deliverySelection = 'manual'
                                    }

                                    newOrder.status = 'discontinued'
                                } else if (e === 'paid') {
                                    newOrder.status = 'available'
                                }

                                if (e === 'refunding') {
                                    setRefundAmount(
                                        order.actualAmount.toFixed(2)
                                    )
                                    showModal()
                                    return
                                }

                                updateLine({
                                    type: 'update',
                                    payload: { ...order, ...newOrder },
                                })

                                if (e !== 'ccl') {
                                    newOrder.packs = undefined
                                    newOrder.singleItems = undefined
                                }
                                EditOrders(
                                    {
                                        ...order,
                                        ...newOrder,
                                        offer: undefined,
                                        supplier: undefined,
                                        community: undefined,
                                        customer: undefined,
                                        vouchers: undefined,
                                    },
                                    token.token,
                                    order.id
                                )
                            }}
                        >
                            {order.paymentStatus === 'pending' && (
                                <Option key={'pending'}>{t('pending')}</Option>
                            )}
                            {(user.role === 'super-admin' ||
                                user.role === 'admin' ||
                                user.role === 'finance' ||
                                user.role === 'community-manager' ||
                                user.role === 'customer-hero') &&
                                (order.paymentStatus === 'paid' ||
                                    order.paymentStatus === 'refunding') && (
                                    <Option
                                        key={'refunding'}
                                        className="option-refund"
                                    >
                                        {t('rfd')}
                                    </Option>
                                )}
                            {order.paymentStatus !== 'rfd' &&
                                order.paymentStatus !== 'refunding' &&
                                (order.paymentStatus === 'pending' ||
                                    order.paymentStatus === 'paid' ||
                                    isAdmin) && (
                                    <Option key={'paid'}>
                                        <CheckOutlined
                                            style={{
                                                color: '#52C41A',
                                                fontSize: 22,
                                            }}
                                        />
                                    </Option>
                                )}
                            {order.paymentStatus !== 'rfd' &&
                                order.paymentStatus !== 'refunding' && (
                                    <Option key={'ccl'}>{t('ccl')}</Option>
                                )}

                            {order.paymentStatus === 'rfd' && (
                                <Option key={'rfd'}>
                                    <Popover
                                        placement="bottom"
                                        content={() => {
                                            return (
                                                <>
                                                    <EnterOutlined
                                                        style={{
                                                            transform:
                                                                'scale(-1, 1)',
                                                            color: '#BBBBBB',
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                    {order.refundAmount}
                                                </>
                                            )
                                        }}
                                    >
                                        {t('refunded')}
                                    </Popover>
                                </Option>
                            )}
                        </Select>
                        <Modal
                            title={
                                <DynamicStrings id={'REFUNDS_CREATE_REFUND'} />
                            }
                            visible={visible}
                            onOk={() => {
                                CreateRefund(
                                    {
                                        order: order.id,
                                        customer: order.customer?.id,
                                        teamMember: user.id,
                                        community: order.community,
                                        offer: order.offer,
                                        supplier: order.supplier,

                                        date: moment(),
                                        actionETA,
                                        refundAmount,
                                        resp,
                                        issue,
                                        issueDetail,
                                        comment,
                                        status: 'new',
                                    },
                                    token.token
                                )
                                    .then((entity) => {
                                        updateLine({
                                            type: 'update',
                                            payload: {
                                                ...order,
                                                paymentStatus: 'refunding',
                                            },
                                        })

                                        setRefundAmount(0)
                                        setResp('')
                                        setIssue('')
                                        setIssueDetail('')
                                        setComment('')
                                        setActionETA('')
                                        setVisible(false)
                                    })
                                    .catch((err) => {
                                        message.error('An error occurs')

                                        setRefundAmount(0)
                                        setResp('')
                                        setIssue('')
                                        setIssueDetail('')
                                        setComment('')
                                        setActionETA('')
                                        setVisible(false)
                                    })
                            }}
                            okText={
                                <DynamicStrings id={'REFUNDS_SAVE_REFUND'} />
                            }
                            onCancel={handleCancel}
                            okButtonProps={{
                                disabled:
                                    !refundAmount ||
                                    !resp ||
                                    !issue ||
                                    !actionETA,
                            }}
                        >
                            <Form
                                layout={'vertical'}
                                className="form-wrapper-card"
                            >
                                <div className="form-field-wrapper nb-columns-2">
                                    <Form.Item
                                        label={t(
                                            'CUSTOMER_SERVICE_RESPONSIBILITY'
                                        )}
                                        className="col-1 size-1"
                                    >
                                        <Select
                                            value={resp}
                                            onChange={(value) => setResp(value)}
                                            style={{ width: '100%' }}
                                        >
                                            <Select.Option value="supplier">
                                                {t('supplier')}
                                            </Select.Option>
                                            <Select.Option value="delivery">
                                                {t(
                                                    'CUSTOMER_SERVICE_DELIVERY_SERVICE'
                                                )}
                                            </Select.Option>
                                            <Select.Option value="customer">
                                                {t('customer')}
                                            </Select.Option>
                                            <Select.Option value="ziko">
                                                {t('ziko')}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={t('CUSTOMER_SERVICE_ISSUE')}
                                        className="col-2 row-1 size-1"
                                    >
                                        <Select
                                            value={issue}
                                            onChange={(value) =>
                                                setIssue(value)
                                            }
                                            style={{ width: '100%' }}
                                        >
                                            <Select.Option value="wrong_address">
                                                {t('wrong_address')}
                                            </Select.Option>
                                            <Select.Option value="packaging">
                                                {t('packaging')}
                                            </Select.Option>
                                            <Select.Option value="defrost">
                                                {t('defrost')}
                                            </Select.Option>
                                            <Select.Option value="product_default">
                                                {t('product_default')}
                                            </Select.Option>
                                            <Select.Option value="item_missing">
                                                {t('item_missing')}
                                            </Select.Option>
                                            <Select.Option value="delay">
                                                {t('delay')}
                                            </Select.Option>
                                            <Select.Option value="customer_mistake">
                                                {t('customer_mistake')}
                                            </Select.Option>
                                            <Select.Option value="wrong_time">
                                                {t('wrong_time')}
                                            </Select.Option>
                                            <Select.Option value="wrong_item">
                                                {t('wrong_item')}
                                            </Select.Option>
                                            <Select.Option value="fapiao">
                                                {t('fapiao')}
                                            </Select.Option>
                                            <Select.Option value="others">
                                                {t('others')}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={t('CUSTOMER_SERVICE_ACTION_ETA')}
                                        className="col-1 row-2 size-1"
                                    >
                                        <DatePicker
                                            value={actionETA}
                                            format={'YYYY-MM-DD'}
                                            allowClear={true}
                                            onChange={(e) => {
                                                setActionETA(e)
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t('REFUNDS_REFUND_AMOUNT')}
                                        className="col-2 row-2 size-1"
                                    >
                                        <InputNumber
                                            value={refundAmount}
                                            onChange={(e) => {
                                                setRefundAmount(e)
                                            }}
                                            min={0}
                                            max={order.actualAmount.toFixed(2)}
                                            defaultValue={order.actualAmount.toFixed(
                                                2
                                            )}
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    label={t('CUSTOMER_SERVICE_ISSUE_DETAIL')}
                                    className="row-3 size-2"
                                >
                                    <Input.TextArea
                                        value={issueDetail}
                                        onChange={(e) =>
                                            setIssueDetail(e.target.value)
                                        }
                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t('INVENTORY_COMMENT')}
                                    className="row-4 size-2"
                                >
                                    <Input.TextArea
                                        value={comment}
                                        onChange={(e) =>
                                            setComment(e.target.value)
                                        }
                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>
                )
            },
        },
        {
            width: 36,
            textWrap: 'word-break',
            title: 'QR',
            key: '18',
            render: (order) => {
                // if (
                //     !order?.paymentQRCode?.length ||
                //     order.paymentMethod === 'alipay'
                // )
                //     return null

                // let lastQR = order?.paymentQRCode[0]
                // for (const qr of order?.paymentQRCode) {
                //     if (new Date(qr.date) > new Date(lastQR.date)) {
                //         lastQR = qr
                //     }
                // }

                // If there is no area or area is 'Mainland China', we don't generate QR code
                if (
                    !order.customerAddress.area ||
                    order.customerAddress.area === '60dd98d4cb4986fb25ca11cd'
                ) {
                    return null
                }

                return (
                    <PopUpQR
                        url={`${process.env.REACT_APP_PAYMENT_URL}/${order.id}`}
                        community={communityMemoized}
                    />
                )
                //     ,
                //     lastQR.totalFee / 100,
                // ]
            },
        },
        {
            width: 90,
            textWrap: 'word-break',
            key: 'fapiaoEmail',
            title: 'Fapiao',
            render: (order) => {
                const menu = (
                    <Menu>
                        {order?.customer?.fapiaoEmails?.map((email) => (
                            <Menu.Item
                                onClick={() =>
                                    onChangeInput({
                                        ...order,
                                        fapiao: true,
                                        fapiaoEmail: email.email,
                                    })
                                }
                            >
                                {`${email.email}`}
                            </Menu.Item>
                        ))}
                    </Menu>
                )

                return (
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        disabled={isFieldDisabled(order)}
                    >
                        <Input
                            type="text"
                            size="small"
                            onFocus={() => setLineIsSelected(true)}
                            onBlur={() => setLineIsSelected(false)}
                            value={order?.fapiaoEmail}
                            disabled={isFieldDisabled(order)}
                            onChange={(e) =>
                                onChangeInput({
                                    ...order,
                                    fapiao:
                                        e.target.value !== '' ? true : false,
                                    fapiaoEmail: e.target.value,
                                })
                            }
                        />
                    </Dropdown>
                )
            },
        },
    ]

    if (order.status === 'discontinued' && order.paymentStatus === 'pending') {
        return null
    }

    return (
        <>
            <tr
                className={lineIsSelected ? 'line-selected' : 'line'}
                onMouseEnter={() => setLineIsSelected(true)}
                onMouseLeave={() => setLineIsSelected(false)}
            >
                {columnsPart1?.map((column) => (
                    <td
                        key={order.id + '-' + column.key}
                        style={{ width: column.width }}
                        onMouseEnter={() => setSelectedColumn(column.key)}
                        onMouseLeave={() => setSelectedColumn(null)}
                    >
                        <div
                            // style={{ width: column.width }}
                            className={`cell-wrap${
                                column.key === 'name' &&
                                order.customerAddress?.needSave
                                    ? ' need-save'
                                    : ''
                            }`}
                        >
                            {column.render ? column.render(order) : null}
                        </div>
                    </td>
                ))}
                {packsMemoized.map((pack) => {
                    const currentPackIndex = order?.packs?.findIndex(
                        (e) => e._id === pack._id
                    )

                    return (
                        <td
                            key={order.id + '-' + pack.shortName}
                            // className="cell"
                            style={{ width: 37 }}
                            onMouseEnter={() =>
                                setSelectedColumn(pack.shortName)
                            }
                            onMouseLeave={() => setSelectedColumn(null)}
                        >
                            <div className="cell-wrap">
                                <InputNumber
                                    type="text"
                                    size="small"
                                    min={0}
                                    // max={pack?.actualStock}
                                    bordered={false}
                                    disabled={
                                        isFieldDisabled(order) ||
                                        order.paymentStatus === 'paid'
                                        // || (!pack?.actualStock && currentPack)
                                    }
                                    value={
                                        currentPackIndex >= 0
                                            ? order?.packs[currentPackIndex]
                                                  .amount
                                            : null
                                    }
                                    // onFocus={() => {
                                    //     setSelectedColumn(pack.shortName)
                                    //     setSelectedLine(order.id)
                                    // }}
                                    onChange={(value) => {
                                        updateOld(order)
                                        let newPacks = order?.packs
                                            ? [...order.packs]
                                            : []
                                        if (currentPackIndex >= 0) {
                                            if (value === 0) {
                                                newPacks.splice(
                                                    currentPackIndex,
                                                    1
                                                )
                                            } else {
                                                newPacks[
                                                    currentPackIndex
                                                ].amount = value
                                            }
                                        } else {
                                            if (value !== 0) {
                                                newPacks.push({
                                                    // ...pack,
                                                    // amount: value,

                                                    _id: pack._id,
                                                    amount: value,
                                                    shortName: pack.shortName,
                                                })
                                            }
                                        }

                                        updateLine({
                                            type: 'update',
                                            payload: {
                                                ...order,
                                                packs: newPacks,
                                            },
                                        })
                                        debouncedPack({
                                            ...order,
                                            packs: newPacks,
                                        })
                                    }}
                                />
                            </div>
                        </td>
                    )
                })}
                {itemsMemoized?.map((item) => {
                    const currentItemIndex = order?.singleItems?.findIndex(
                        (e) => e._id === item._id
                    )

                    return (
                        <td
                            key={order.id + '-' + item.shortName}
                            // className="cell"
                            style={{ width: 37 }}
                            onMouseEnter={() =>
                                setSelectedColumn(item.shortName)
                            }
                            onMouseLeave={() => setSelectedColumn(null)}
                        >
                            <div className="cell-wrap">
                                <InputNumber
                                    type="text"
                                    size="small"
                                    min={0}
                                    // max={pack?.actualStock}
                                    bordered={false}
                                    disabled={
                                        isFieldDisabled(order) ||
                                        order.paymentStatus === 'paid'
                                        // || (!pack?.actualStock && currentPack)
                                    }
                                    value={
                                        currentItemIndex >= 0
                                            ? order?.singleItems[
                                                  currentItemIndex
                                              ].amount
                                            : null
                                    }
                                    // onFocus={() => {
                                    //     setSelectedColumn(item.shortName)
                                    //     setSelectedLine(order.id)
                                    // }}
                                    onChange={(value) => {
                                        updateOld(order)
                                        let newItems = order?.singleItems
                                            ? [...order.singleItems]
                                            : []
                                        if (currentItemIndex >= 0) {
                                            if (value === 0) {
                                                newItems.splice(
                                                    currentItemIndex,
                                                    1
                                                )
                                            } else {
                                                newItems[
                                                    currentItemIndex
                                                ].amount = value
                                            }
                                        } else {
                                            if (value !== 0) {
                                                newItems.push({
                                                    // ...item,
                                                    // product: item.product.id,
                                                    _id: item._id,
                                                    amount: value,
                                                    shortName: item.shortName,
                                                })
                                            }
                                        }

                                        updateLine({
                                            type: 'update',
                                            payload: {
                                                ...order,
                                                singleItems: newItems,
                                            },
                                        })
                                        debouncedPack({
                                            ...order,
                                            singleItems: newItems,
                                        })
                                    }}
                                />
                            </div>
                        </td>
                    )
                })}
                {columnsPart2?.map((column) => (
                    <td
                        key={order.id + '-' + column.key}
                        className="cell"
                        style={{ width: column.width }}
                        onMouseEnter={() => setSelectedColumn(column.key)}
                        onMouseLeave={() => setSelectedColumn(null)}
                    >
                        <div
                            // style={{ width: column.width }}
                            className={`cell-wrap ${
                                column.key === 'total' &&
                                order.paymentMethod === 'alipay'
                                    ? 'is-alipay'
                                    : ''
                            }${
                                (column.key === 'address' ||
                                    column.key === '09' ||
                                    column.key === 'area') &&
                                order.customerAddress?.needSave
                                    ? 'need-save'
                                    : ''
                            }`}
                        >
                            {column.render ? column.render(order) : null}
                        </div>
                    </td>
                ))}
            </tr>

            <KeyboardEventHandler
                //If the buyer chose the new address - highlight only name, phonedelivery address, city, area.

                //If the buyer chose the temporary address - highlight only name, phone, delivery address, city, area.

                //If the buyer chose to pay with Alipay - highlight only total amount.
                handleKeys={['ctrl+r', 'ctrl+y', 'ctrl+b']}
                handleFocusableElements={true}
                onKeyEvent={(key, e) => {
                    if (lineIsSelected) {
                        // let selectedOrder = orders.find(
                        //     (e) => e.id === selectedLine
                        // )
                        switch (key) {
                            case 'ctrl+r':
                                message.info('ctrl+r')
                                break
                            case 'ctrl+y':
                                message.info('ctrl+y')
                                updateLine({
                                    type: 'update',
                                    payload: {
                                        ...order,
                                        customerAddress: {
                                            ...order.customerAddress,
                                            needSave:
                                                !order.customerAddress
                                                    ?.needSave,
                                        },
                                    },
                                })
                                debouncedShortcut(
                                    {
                                        ...order,
                                        customerAddress: {
                                            ...order.customerAddress,
                                            needSave:
                                                !order.customerAddress
                                                    ?.needSave,
                                        },
                                    },
                                    order
                                )
                                break
                            case 'ctrl+b':
                                message.info('ctrl+b')
                                updateLine({
                                    type: 'update',
                                    payload: {
                                        ...order,
                                        paymentMethod:
                                            order.paymentMethod === 'wechat'
                                                ? 'alipay'
                                                : 'wechat',
                                    },
                                })
                                debouncedShortcut(
                                    {
                                        ...order,
                                        paymentMethod:
                                            order.paymentMethod === 'wechat'
                                                ? 'alipay'
                                                : 'wechat',
                                    },
                                    order
                                )
                                break
                            default:
                                break
                        }
                    }
                }}
            />
        </>
    )
}

export default OrderLine
const QRCode = require('qrcode')

const PopUpQR = ({ url, community }) => {
    const [visible, setVisible] = useState(false)
    const [qr, setQr] = useState()
    const [img, setImg] = useState()
    var opts = {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 1,
        width: 79,
        qzone: 4,
        margin: 0,
        color: {
            dark: '#000',
            light: '#fff',
        },
    }

    useEffect(() => {
        QRCode.toDataURL(url, opts, function (err, uri) {
            if (err) throw err

            setQr(uri)
        })

        let srcIMG
        switch (community) {
            case '60541eab7b87f1126ecf53e1':
                srcIMG = kitchenqrbg
                break
            case '60541eb37b87f1126ecf53e2':
                srcIMG = farmqrbg
                break
            case '60541eb87b87f1126ecf53e3':
                srcIMG = cellarqrbg
                break

            default:
                srcIMG = petqrbg
                break
        }

        if (srcIMG && qr) {
            mergeImages(
                [
                    {
                        src: srcIMG,
                    },

                    {
                        src: qr,
                        x: 155,
                        y: 438,
                    },
                ],
                { format: 'image/jpeg' }
            ).then((b64) => setImg(b64))
        }
    })

    // return fee
    return (
        <div
            style={{
                height: 32,
                width: 32,
                borderRadius: 3,
                backgroundColor: '#ccc',
            }}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {img && visible ? <Avatar shape="square" src={img} /> : null}
        </div>
    )
}
