import {
    useFieldArray,
    Controller,
    useWatch,
    useFormContext,
} from 'react-hook-form'
import {
    Input,
    Select,
    Form,
    Card,
    Typography,
    Divider,
    Button,
    Space,
    InputNumber,
} from 'antd'
import { Fragment } from 'react'

import numberToLetter from './numberToLetter'
import UploadField from './Upload'
import { defaultPacks } from './defaultValues'
import ProductsSelect from './ProductsSelect'
import HiddenFields from './HiddenFields'

const { Title, Text } = Typography

const Packs = ({
    control,
    channel,
    isEdit,
    errors,
    setValue,
    clearErrors,
    type,
}) => {
    const { getValues, reset } = useFormContext()
    const name = `${channel}.packs`
    const { fields, append, remove } = useFieldArray({
        // control,
        name,
    })

    const onRemove = (index) => {
        const values = getValues()
        values[channel].packs.splice(index, 1)
        reset(values, { isDirty: true })
    }

    return (
        <Card
            bordered={false}
            title="Add selection"
            bodyStyle={{ paddingTop: 12 }}
        >
            {fields.map((field, index) => (
                <PackLine
                    type={type}
                    key={field.id}
                    packIndex={index}
                    control={control}
                    channel={channel}
                    isEdit={isEdit}
                    errors={errors?.[index]}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    onRemove={() => onRemove(index)}
                    // onRemove={() => remove(index)}
                />
            ))}
            <Button
                type="primary"
                onClick={() => append(defaultPacks)}
                style={{ marginTop: 12 }}
                disabled={isEdit}
            >
                Add selection
            </Button>
            {!!errors?.size && (
                <div style={{ marginTop: 12 }}>
                    <Text type="danger">{errors?.size?.message}</Text>
                </div>
            )}
        </Card>
    )
}

const PackLine = ({
    packIndex,
    control,

    channel,
    isEdit,
    errors,
    setValue,
    clearErrors,
    onRemove,
    type,
}) => {
    const name = `${channel}.packs.${packIndex}`
    const { getValues } = useFormContext()

    const hiddenFields = [
        {
            field: '_id',
            type: 'string',
        },
        {
            field: 'actualStock',
            type: 'number',
        },
        {
            field: 'shortName',
            type: 'string',
        },
    ]

    return (
        <div style={{ marginTop: 12 }}>
            <Space align="center" style={{ marginBottom: 12 }}>
                <Title level={5} style={{ margin: 0 }}>
                    {`Selection ${numberToLetter(packIndex)}`}
                </Title>
                <Button type="link" onClick={onRemove} disabled={isEdit}>
                    Delete selection
                </Button>
            </Space>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridGap: 8,
                }}
            >
                {channel === 'wechatGroup' ? (
                    <Form.Item
                        label="Thumbnail (Payment)"
                        style={{ gridRow: 1, marginBottom: 0 }}
                    >
                        <Controller
                            name={`${name}.mainPicture.en`}
                            control={control}
                            // defaultValue={null}
                            render={({ value, onChange }) => (
                                <UploadField
                                    value={value}
                                    onChange={onChange}
                                    storage="pack-illustration"
                                />
                            )}
                        />
                    </Form.Item>
                ) : (
                    <>
                        <Form.Item
                            label="Thumbnail (English)"
                            style={{
                                gridRow: 1,
                                marginBottom: 0,
                            }}
                        >
                            <Controller
                                name={`${name}.mainPicture.en`}
                                control={control}
                                // defaultValue={null}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="pack-illustration"
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Thumbnail (中文)"
                            style={{
                                gridRow: 1,
                                marginBottom: 0,
                            }}
                        >
                            <Controller
                                name={`${name}.mainPicture.zh`}
                                control={control}
                                // defaultValue={null}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="pack-illustration"
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Pack pictures"
                            style={{
                                gridRow: 1,
                                marginBottom: 0,
                            }}
                        >
                            <Controller
                                name={`${name}.otherMedia`}
                                control={control}
                                // defaultValue={[]}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="pack-illustration"
                                        multiple
                                    />
                                )}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    label="English name"
                    style={{ gridRow: 2, marginBottom: 0 }}
                >
                    <Controller
                        name={`${name}.name.en`}
                        control={control}
                        render={({ value, onChange }) => (
                            <Input
                                autoFocus
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Chinese name"
                    style={{ gridRow: 2, marginBottom: 0 }}
                >
                    <Controller
                        name={`${name}.name.zh`}
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Stock available"
                    style={{ gridRow: 2, marginBottom: 0 }}
                    validateStatus={!!errors?.['stock'] ? 'error' : undefined}
                    help={errors?.['stock']?.message}
                >
                    <Controller
                        name={`${name}.stock`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                min={1}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    label="Actual price (¥)"
                    style={{ gridRow: 3, marginBottom: 0 }}
                    validateStatus={!!errors?.['price'] ? 'error' : undefined}
                    help={errors?.['price']?.message}
                >
                    <Controller
                        name={`${name}.price`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Crossed original price (¥)"
                    style={{ gridRow: 3, marginBottom: 0 }}
                    validateStatus={
                        !!errors?.['formerPrice'] ? 'error' : undefined
                    }
                    help={errors?.['formerPrice']?.message}
                >
                    <Controller
                        name={`${name}.formerPrice`}
                        control={control}
                        rules={{
                            validate: {
                                tooLow: (value) => {
                                    const values = getValues()
                                    const priceValue =
                                        values?.[channel]?.packs?.[packIndex]
                                            ?.price

                                    if (
                                        !!priceValue &&
                                        !!value &&
                                        value <= priceValue
                                    )
                                        return "Original price shouldn't be equal or less than the current price"
                                },
                            },
                        }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Target sales (¥)"
                    style={{ gridRow: 3, marginBottom: 0 }}
                >
                    <Controller
                        name={`${name}.targetSales`}
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>

                {type === 'free_fall' && (
                    <>
                        <Form.Item
                            label="Price drop (¥)"
                            style={{
                                gridRow: 4,
                            }}
                        >
                            <Controller
                                name={`${name}.freeFall.dropAmount`}
                                control={control}
                                // defaultValue=""
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Per items sold (Qt)"
                            style={{
                                gridRow: 4,
                            }}
                        >
                            <Controller
                                name={`${name}.freeFall.quantityTrigger`}
                                control={control}
                                // defaultValue=""
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Lowest price limit (¥)"
                            style={{
                                gridRow: 4,
                            }}
                            validateStatus={
                                !!errors?.['freeFall']?.['lowestPrice']
                                    ? 'error'
                                    : undefined
                            }
                            help={
                                errors?.['freeFall']?.['lowestPrice']?.message
                            }
                        >
                            <Controller
                                name={`${name}.freeFall.lowestPrice`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                    </>
                )}
            </div>
            <PackProducts
                packIndex={packIndex}
                control={control}
                channel={channel}
                isEdit={isEdit}
                errors={errors?.['products']}
                setValue={setValue}
                clearErrors={clearErrors}
            />
            {isEdit &&
                hiddenFields.map((field) => (
                    <HiddenFields
                        control={control}
                        name={name}
                        field={field}
                    ></HiddenFields>
                ))}
            {!!errors?.products?.size && (
                <div style={{ marginTop: 12 }}>
                    <Text type="danger">{errors?.products?.size?.message}</Text>
                </div>
            )}
        </div>
    )
}

const PackProducts = ({
    packIndex,
    control,
    channel,
    isEdit,
    errors,
    setValue,
    clearErrors,
}) => {
    const name = `${channel}.packs.${packIndex}.products`
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    })

    const hiddenFields = [
        {
            field: '_id',
            type: 'string',
        },
    ]

    return (
        <>
            {fields.map((field, index) => (
                <Fragment key={field.id}>
                    <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                            gridGap: 8,
                        }}
                    >
                        <Form.Item
                            label="Select product"
                            style={{
                                gridRow: 1,
                                gridColumn: '1 / 4',
                                marginBottom: 0,
                            }}
                            validateStatus={
                                !!errors?.[index]?.['product']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.[index]?.['product']?.message}
                        >
                            <Controller
                                name={`${name}.${index}.product`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <ProductsSelect
                                        value={value}
                                        onChange={([id, product]) => {
                                            onChange(id)
                                            setValue(
                                                `${name}.${index}.weight`,
                                                product.weight
                                            )
                                            setValue(
                                                `${name}.${index}.weightType`,
                                                product.weightType
                                            )
                                        }}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Button
                            type="link"
                            onClick={() => remove(index)}
                            style={{ margin: 'auto auto auto 0' }}
                            disabled={isEdit}
                        >
                            Delete
                        </Button>

                        <Form.Item
                            label="Quantity"
                            style={{ gridRow: 2, marginBottom: 0 }}
                            validateStatus={
                                !!errors?.[index]?.['quantity']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.[index]?.['quantity']?.message}
                        >
                            <Controller
                                name={`${name}.${index}.quantity`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Weight / unit"
                            style={{ gridRow: 2, marginBottom: 0 }}
                        >
                            <Controller
                                name={`${name}.${index}.weight`}
                                control={control}
                                // defaultValue=""
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Unit"
                            style={{ gridRow: 2, marginBottom: 0 }}
                            validateStatus={
                                !!errors?.[index]?.['weightType']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.[index]?.['weightType']?.message}
                        >
                            <Controller
                                name={`${name}.${index}.weightType`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    >
                                        <Select.Option value="g">
                                            Gram
                                        </Select.Option>
                                        <Select.Option value="ml">
                                            Milliliter
                                        </Select.Option>
                                        <Select.Option value="piece">
                                            Pieces
                                        </Select.Option>
                                    </Select>
                                )}
                            />
                        </Form.Item>

                        {isEdit &&
                            hiddenFields.map((field) => (
                                <HiddenFields
                                    control={control}
                                    name={`${name}.${index}`}
                                    field={field}
                                ></HiddenFields>
                            ))}
                    </div>
                </Fragment>
            ))}
            <Button
                type="dashed"
                onClick={() => {
                    clearErrors(`${name}.size`)
                    append({})
                }}
                disabled={isEdit}
                style={{ marginTop: 12 }}
            >
                Add product
            </Button>
        </>
    )
}

export default Packs
