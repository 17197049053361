import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { getProductsAnalytics } from '../network/Products'
import { AppContext } from 'src/context/AppContext'
import PageWrap from '../components/layout/PageWrap'
import TableFilters from '../components/tables/TableFilters'
import Loader from '../components/Loader'
import Error from '../components/Error'
import StatCard from '../components/StatCard'
import ChartPie from '../components/ChartPie'
import ChartColumn from '../components/ChartColumn'
import { formatNb } from 'src/utils/utils'
import DynamicStrings from "../components/DynamicStrings";

const { Title } = Typography

const ProductAnalytics = () => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    let { productId } = useParams()
    const [tabFilter, setTabFilter] = useState({})

    const productAnalytics = useQuery(
        [
            'getProductAnalytics',
            {
                productId: productId,
                ...tabFilter,
                token: token.token,
            },
        ],
        getProductsAnalytics
    )

    const filters = () => ({
        fields: [
            {
                type: 'daterange',
                label: 'ANALYTICS_DATES',
                key: ['dateStart', 'dateEnd'],
            },
        ],
        actions: [
            // {
            //     label: 'ANALYTICS_EXPORT_CSV',
            //     isActive: !analytics.isLoading,
            //     action: () => {
            //         let num =
            //             ((analytics.data?.totalOrder -
            //                 analytics.data?.totalClaim) /
            //                 analytics.data?.totalOrder) *
            //             100
            //         const rows = [
            //             [
            //                 'Sales',
            //                 'Grand total sales',
            //                 'Total Zikoland service fee',
            //                 'Total supplier share',
            //             ],
            //             ['', '-', '-', '-'],
            //             ['Orders', 'Total Offers', 'Total orders'],
            //             [
            //                 '',
            //                 analytics.data?.totalOffer,
            //                 analytics.data?.totalOrder,
            //             ],
            //             [
            //                 'Customer service',
            //                 'Customer satisfaction rate',
            //                 'Total claims',
            //                 'Total vouchers',
            //                 'Total vouchers (¥)',
            //                 'Total refund (¥)',
            //                 'Total refund',
            //             ],
            //             [
            //                 '',
            //                 Math.round((num + Number.EPSILON) * 100) / 100,
            //                 analytics.data?.totalClaim,
            //                 analytics.data?.totalVoucher,
            //                 analytics.data?.totalVoucherAmount,
            //                 '-',
            //                 '-',
            //             ],
            //             ['Stock', 'Total stock', 'Stock value (¥)'],
            //             [
            //                 '',
            //                 analytics.data?.totalStock,
            //                 analytics.data?.totalStockValue,
            //             ],
            //         ]
            //         downloadCSV(
            //             null,
            //             rows,
            //             `analytics-${
            //                 tabFilter.dateStart && tabFilter.dateEnd
            //                     ? 'from' +
            //                       moment(tabFilter.dateStart).format(
            //                           'YYYY-MM-DD'
            //                       ) +
            //                       'to' +
            //                       moment(tabFilter.dateEnd).format('YYYY-MM-DD')
            //                     : 'till' + moment().format('YYYY-MM-DD')
            //             }`
            //         )
            //     },
            // },
        ],
    })

    const cities = (cities) => {
        if (!cities) return []
        let total = 0

        const res = Object.keys(cities).map((key) => {
            total += cities[key]
            return {
                _id: key,
                amount: cities[key] * productAnalytics?.data?.product?.price,
            }
        })

        return total ? res : []
    }

    return (
        <PageWrap
            title={productAnalytics.data?.product?.name?.en}
            subTitle={
                productAnalytics.isLoading
                    ? null
                    : t('PRODUCT_LIST_PRODUCT_ID') + `: ${productAnalytics?.data?.product?.productId} | ` +
                      '          ' + t('supplier') +
                      `: ${productAnalytics?.data?.product?.supplier?.name}`
            }
        >
            <TableFilters
                config={filters()}
                // onFiltersChange={(filters) => setFilter(filters)}
                onTabChange={(e) => setTabFilter(e)}
            />
            {productAnalytics.isError ? (
                <Error retry={() => productAnalytics.refetch()} />
            ) : (
                <div style={{ backgroundColor: '#f0f2f5', paddingBottom: 24 }}>
                    {productAnalytics.isLoading ? (
                        <Loader />
                    ) : (
                        <Space
                            direction="vertical"
                            style={{ padding: '24px 0px 0px 24px' }}
                        >
                            <Title level={4}>{t('ANALYTICS_SALES')}</Title>
                            <Space>
                                <ChartPie
                                    title={t('ANALYTICS_SALES_CHANNEL_RATIO')}
                                    data={
                                        !productAnalytics?.data
                                            ?.miniprogramProductSold *
                                            productAnalytics?.data?.product
                                                ?.price &&
                                        !productAnalytics?.data
                                            ?.wechatGroupProductSold *
                                            productAnalytics?.data?.product
                                                ?.price
                                            ? []
                                            : [
                                                  {
                                                      _id: 'miniprogram',
                                                      totalAmount:
                                                          productAnalytics?.data
                                                              ?.miniprogramProductSold *
                                                              productAnalytics
                                                                  ?.data
                                                                  ?.product
                                                                  ?.price || 0,
                                                  },
                                                  {
                                                      _id: 'wechat_group',
                                                      totalAmount:
                                                          productAnalytics?.data
                                                              ?.wechatGroupProductSold *
                                                              productAnalytics
                                                                  ?.data
                                                                  ?.product
                                                                  ?.price || 0,
                                                  },
                                              ]
                                    }
                                />
                                <ChartColumn
                                    title={t("PRODUCT_LIST_SALES_PER_CITY")}
                                    data={cities(
                                        productAnalytics.data?.productByCities
                                    )}
                                />
                                <Space direction="vertical">
                                    <StatCard
                                        title={t('ANALYTICS_TOTAL_ORDERS')}
                                        value={
                                            productAnalytics.data?.orderAmount
                                        }
                                    />
                                    <StatCard
                                        title={t('OFFER_ANALYTICS_TOTAL_SALES')}
                                        value={
                                            productAnalytics.data
                                                ?.productSold &&
                                            productAnalytics?.data?.product
                                                ?.price
                                                ? '¥ ' +
                                                  formatNb(
                                                      productAnalytics.data
                                                          ?.productSold *
                                                          productAnalytics?.data
                                                              ?.product?.price
                                                  )
                                                : '¥ 0'
                                        }
                                    />
                                    <StatCard
                                        title={t('PRODUCT_LIST_TOTAL_WEIGHT')}
                                        value={
                                            productAnalytics?.data?.totalWeight
                                                ? (productAnalytics.data
                                                      ?.totalWeight > 10000
                                                      ? formatNb(
                                                            productAnalytics
                                                                .data
                                                                ?.totalWeight /
                                                                1000
                                                        )
                                                      : formatNb(
                                                            productAnalytics
                                                                .data
                                                                ?.totalWeight
                                                        )) +
                                                  (productAnalytics.data
                                                      ?.totalWeight > 10000
                                                      ? ' kg'
                                                      : ' g')
                                                : '0 g'
                                        }
                                    />
                                </Space>
                            </Space>
                        </Space>
                    )}
                </div>
            )}
        </PageWrap>
    )
}

export default ProductAnalytics
