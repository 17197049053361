import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import DeleteButton from '../../components/DeleteButton'

import {
    Description,
    cleanBeforeSending,
    checkValidity,
} from '../../constants/forms/Users'
import { EditUser, getSingleUser, DeleteUser } from '../../network/Users'

const CreateUser = () => {
    const { token, user } = useContext(AppContext)
    const [isDeleted, setIsDeleted] = useState(false)

    let { userId } = useParams()
    const userDetails = useQuery(
        [
            'getSingleUser',
            {
                userId: userId,
                token: token.token,
            },
        ],
        getSingleUser,
        {
            refetchOnWindowFocus: false,
        }
    )

    const getDeleteResult = (result) => {
        setIsDeleted(result)
    }

    return (
        <PageWrap
            goBack
            isLoading={userDetails.isLoading}
            title={userDetails.data?.name}
            actionOptions={user.role === 'super-admin' || user.role === 'admin' ? [
                <DeleteButton
                    onSuccess="/users"
                    request={DeleteUser}
                    id={userId}
                    invalidate={['getSingleUser', 'getUsers']}
                    isDeleted={getDeleteResult}
                />,
            ] : []}
        >
            {userDetails.isError ? (
                <Error retry={() => userDetails.refetch()} />
            ) : userDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    title="create_user"
                    request={EditUser}
                    id={userId}
                    onSuccess="/users"
                    onCancel="/users"
                    data={userDetails.data}
                    cleanBeforeSending={cleanBeforeSending}
                    checkValidity={checkValidity}
                    isDeleted={isDeleted}
                >
                    <div className="SectionHorizontalWrap">
                        <FormBlock formTemplate={Description} />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default CreateUser
