import {
    Controller,
    useWatch,
    useFieldArray,
    useFormContext,
} from 'react-hook-form'
import {
    Select,
    Form,
    Card,
    Typography,
    Divider,
    Radio,
    DatePicker,
    TimePicker,
    Tag,
    InputNumber,
    Cascader,
    Space,
    Button,
} from 'antd'
import { useQuery } from 'react-query'
import { getDeliveryTemplates } from '../../../network/API'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../context/AppContext'
import moment from 'moment'
import { getDeliveryAreas } from '../../../network/API'

const { Title, Text } = Typography

const Details = ({
    control,
    setFees,
    setValue,
    isEdit,
    errors,
    clearErrors,
}) => {
    const { token } = useContext(AppContext)
    const methods = useFormContext()
    const deliveryTemplates = useQuery(
        ['getDeliveryTemplates', { token: token.token }],
        getDeliveryTemplates,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    )

    const disabledDate = useCallback((date) => {
        const dateNow = moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        })

        const currentDate = moment(date).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 1,
        })

        return dateNow > currentDate
    }, [])

    const onTemplateChange = (e) => {
        const matchingTemplate = deliveryTemplates.data?.list?.find(
            (t) => t.id === e
        )
        if (!matchingTemplate) return

        const fees = matchingTemplate.areas?.map((fee, idx) => {
            let newCities = []
            let cities = { ...fee.area }
            while (cities) {
                newCities.push(cities.id)
                cities = cities.parent
            }

            newCities.reverse()

            const newFee = {
                area: newCities,
                rules: fee.rules.map((e) => ({
                    fee: e.fee,
                    type: e.type,
                    quantity: e.quantity,
                })),
            }
            delete newFee._id

            return newFee
        })

        const nowValues = methods.getValues()
        methods.reset({ ...nowValues, fees, feeTemplate: e }, { isDirty: true })
    }

    return (
        <Card
            bordered={false}
            title="Offer details"
            style={{ flex: 1, minWidth: 510 }}
        >
            <Title level={5}>Time and Duration</Title>

            <div style={{ display: 'flex', gap: 8 }}>
                <Form.Item
                    label="Starting date"
                    style={{ flex: 1 }}
                    validateStatus={
                        !!errors?.['startingDate'] ? 'error' : undefined
                    }
                    help={errors?.['startingDate']?.message}
                >
                    <Controller
                        name="startingDate"
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <DatePicker
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabledDate={disabledDate}
                                format={'YYYY-MM-DD'}
                                allowClear
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Starting time"
                    style={{ flex: 1 }}
                    validateStatus={
                        !!errors?.['startingDate'] ? 'error' : undefined
                    }
                    help={errors?.['startingDate']?.message}
                >
                    <StartingTime control={control} />
                </Form.Item>
                <Form.Item
                    label="Ending date"
                    style={{ flex: 1 }}
                    validateStatus={
                        !!errors?.['endingDate'] ? 'error' : undefined
                    }
                    help={errors?.['endingDate']?.message}
                >
                    <EndingDate control={control} />
                </Form.Item>
                <Form.Item
                    label="Ending time"
                    style={{ flex: 1 }}
                    validateStatus={
                        !!errors?.['endingDate'] ? 'error' : undefined
                    }
                    help={errors?.['endingDate']?.message}
                >
                    <EndingTime control={control} />
                </Form.Item>
            </div>

            <Divider style={{ marginTop: 12 }} />

            <Title level={5}>Price rule</Title>

            <Controller
                name="type"
                control={control}
                // // defaultValue="regular"
                render={({ onChange, value }) => (
                    <Radio.Group
                        buttonStyle="solid"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        disabled={isEdit}
                    >
                        <Radio.Button value="regular">Regular</Radio.Button>
                        <Radio.Button value="free_fall">Free fall</Radio.Button>
                        <Radio.Button value="multiple_items">
                            Multiple items
                        </Radio.Button>
                        <Radio.Button value="bourse">Bourse</Radio.Button>
                        <Radio.Button value="cave">Clearance</Radio.Button>
                    </Radio.Group>
                )}
            />

            <Divider />

            <Title level={5}>Offer targets</Title>

            <div style={{ display: 'flex', gap: 8 }}>
                <Form.Item label="Target sales (¥)" style={{ flex: 1 }}>
                    <Controller
                        name="targetSales"
                        control={control}
                        // // defaultValue=""
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item label="Comission rate (%)" style={{ flex: 1 }}>
                    <Controller
                        name="commissionRate"
                        control={control}
                        // // defaultValue=""
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <Divider style={{ marginTop: 12 }} />

            <Title level={5}>Minimum orders</Title>

            <div style={{ display: 'flex', gap: 8 }}>
                <Form.Item label="Cart amount (¥)" style={{ flex: 1 }}>
                    <Controller
                        name="minimumOrderAmount"
                        control={control}
                        // // defaultValue=""
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item label="Minimum add ons" style={{ flex: 1 }}>
                    <Controller
                        name="minimumCartItems"
                        control={control}
                        // // defaultValue=""
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <Divider style={{ marginTop: 12 }} />

            <Title level={5}>Delivery type</Title>

            <Controller
                name="deliveryType"
                control={control}
                // // defaultValue=""
                render={({ onChange, value }) => (
                    <Radio.Group
                        buttonStyle="solid"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        disabled={isEdit}
                    >
                        <Radio.Button value="manual">Manual</Radio.Button>
                        <Radio.Button value="shunfeng">Shunfeng</Radio.Button>
                    </Radio.Group>
                )}
            />

            <Divider />

            <Title level={5}>Delivery templates</Title>

            <div style={{ display: 'flex', gap: 8 }}>
                <Controller
                    name="feeTemplate"
                    control={control}
                    // // defaultValue=""
                    render={({ value, onChange }) => (
                        <Select
                            value={value}
                            onSelect={onTemplateChange}
                            style={{ flex: 1 }}
                            disabled={isEdit}
                        >
                            {deliveryTemplates.data?.list.map((e) => (
                                <Select.Option key={e.id} value={e.id}>
                                    {e.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                />
                <div style={{ flex: 1 }} />
            </div>

            <Divider style={{ marginBottom: 12 }} />

            <Fees
                control={control}
                isEdit={isEdit}
                errors={errors?.['fees']}
                clearErrors={clearErrors}
            />

            <Divider />

            <Title level={5}>Delivery date</Title>

            <Form.Item
                validateStatus={
                    !!errors?.['deliveryDates'] ? 'error' : undefined
                }
                help={errors?.['deliveryDates']?.message}
            >
                <DeliveryDate control={control} clearErrors={clearErrors} />
            </Form.Item>
        </Card>
    )
}

const StartingTime = ({ control }) => {
    const startingDate = useWatch({
        control,
        name: 'startingDate',
    })

    const endingDate = useWatch({
        control,
        name: 'endingDate',
    })

    const disabledHours = useCallback(() => {
        const timeNow = moment().set({
            minute: 0,
            second: 0,
            millisecond: 0,
        })

        let disabledHoursList = []
        for (let i = 0; i < 24; i++) {
            if (!startingDate) {
                disabledHoursList.push(i)
            } else {
                const startDate = moment(startingDate).set({
                    hour: i,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                })
                const endDate = moment(endingDate).set({
                    minute: 1,
                    second: 0,
                    millisecond: 0,
                })

                let date = moment(startingDate).set({
                    hour: i,
                    minute: 1,
                    second: 0,
                    millisecond: 0,
                })

                if (timeNow > date || (!!endingDate && startDate >= endDate)) {
                    disabledHoursList.push(i)
                }
            }
        }

        return disabledHoursList
    }, [startingDate, endingDate])

    const disabledMinutes = useCallback(
        (selectedHour) => {
            const timeNow = moment().set({
                second: 0,
                millisecond: 0,
            })

            let disabledMinutesList = []
            for (let i = 0; i < 60; i = i + 5) {
                if (!startingDate) {
                    disabledMinutesList.push(i)
                } else {
                    const startDate = moment(startingDate).set({
                        hour: selectedHour,
                        minute: i,
                        second: 1,
                        millisecond: 0,
                    })
                    const endDate = moment(endingDate).set({
                        second: 0,
                        millisecond: 0,
                    })

                    let date = moment(startingDate).set({
                        hour: selectedHour,
                        minute: i,
                        second: 1,
                        millisecond: 0,
                    })

                    if (
                        timeNow > date ||
                        (endingDate && startDate >= endDate)
                    ) {
                        disabledMinutesList.push(i)
                    }
                }
            }

            return disabledMinutesList
        },
        [startingDate, endingDate]
    )

    return (
        <Controller
            name="startingDate"
            control={control}
            rules={{ required: 'Required' }}
            render={({ onChange }) => (
                <TimePicker
                    changeOnSelect
                    style={{ width: '100%' }}
                    value={startingDate}
                    onChange={onChange}
                    disabled={!startingDate}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    minuteStep={5}
                    format={'HH:mm'}
                />
            )}
        />
    )
}

const EndingDate = ({ control }) => {
    const startingDate = useWatch({
        control,
        name: 'startingDate',
    })

    const disabledDate = useCallback(
        (date) => {
            if (!startingDate) return true

            const dateStart = moment(startingDate).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            })

            const currentDate = moment(date).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 1,
            })

            return dateStart > currentDate
        },
        [startingDate]
    )

    return (
        <Controller
            name="endingDate"
            control={control}
            rules={{ required: 'Required' }}
            render={({ value, onChange }) => (
                <DatePicker
                    style={{ width: '100%' }}
                    value={value}
                    onChange={onChange}
                    disabledDate={disabledDate}
                    format={'YYYY-MM-DD'}
                    allowClear
                />
            )}
        />
    )
}

const EndingTime = ({ control }) => {
    const startingDate = useWatch({
        control,
        name: 'startingDate',
    })

    const endingDate = useWatch({
        control,
        name: 'endingDate',
    })

    const disabledHours = useCallback(() => {
        if (!startingDate || !endingDate)
            return [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                18, 19, 20, 21, 22, 23,
            ]

        let disabledHoursList = []
        for (let i = 0; i < 24; i++) {
            const startDate = moment(startingDate).set({
                minute: 0,
                second: 0,
                millisecond: 0,
            })
            const endDate = moment(endingDate).set({
                hour: i,
                minute: 1,
                second: 0,
                millisecond: 0,
            })

            if (startDate >= endDate) {
                disabledHoursList.push(i)
            }
        }

        return disabledHoursList
    }, [startingDate, endingDate])

    const disabledMinutes = useCallback(
        (selectedHour) => {
            if (!startingDate || !endingDate)
                return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]

            let disabledMinutesList = []
            for (let i = 0; i < 60; i = i + 5) {
                const endDate = moment(endingDate).set({
                    hour: selectedHour,
                    minute: i,
                    second: 0,
                    millisecond: 0,
                })

                if (startingDate >= endDate) {
                    disabledMinutesList.push(i)
                }
            }

            return disabledMinutesList
        },
        [startingDate, endingDate]
    )

    return (
        <Controller
            name="endingDate"
            control={control}
            rules={{ required: 'Required' }}
            render={({ onChange }) => (
                <TimePicker
                    changeOnSelect
                    style={{ width: '100%' }}
                    value={endingDate}
                    onChange={onChange}
                    disabled={!endingDate}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    minuteStep={5}
                    format={'HH:mm'}
                />
            )}
        />
    )
}

const DeliveryDate = ({ control, clearErrors }) => {
    return (
        <Controller
            name="deliveryDates"
            control={control}
            rules={{
                validate: {
                    atLeastOne: (value) => {
                        if (!value || !value.length)
                            return 'Need a least one delivery date'
                    },
                },
            }}
            render={({ value, onChange }) => (
                <div>
                    <DatePicker
                        value={null}
                        placeholder="Add delivery date"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            clearErrors('deliveryDates')
                            onChange([
                                ...(value || []),
                                moment.utc(e).set({
                                    hour: 0,
                                    minute: 0,
                                    second: 0,
                                    millisecond: 0,
                                }),
                            ])
                        }}
                        disabledDate={(date) =>
                            !!value?.find((e) => moment(date).isSame(e, 'day'))
                        }
                    />

                    <div style={{ marginTop: 6 }}>
                        {value?.map((date, index) => (
                            <Tag
                                key={index}
                                color="blue"
                                closable
                                style={{ marginTop: 5 }}
                                onClose={(e) => {
                                    e.preventDefault()
                                    const newDates = [...value]
                                    newDates.splice(index, 1)
                                    onChange(newDates)
                                }}
                            >
                                {moment(date).format('YYYY-MM-DD')}
                            </Tag>
                        ))}
                    </div>
                </div>
            )}
        />
    )
}

const Fees = ({ control, isEdit, errors, clearErrors }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fees',
    })

    return (
        <>
            {fields.map((field, index) => (
                <div key={field.id}>
                    <Space
                        align="center"
                        style={{ marginTop: 12, marginBottom: 12 }}
                    >
                        <Title level={5} style={{ margin: 0 }}>{`Area ${
                            index + 1
                        }`}</Title>
                        <Button
                            type="link"
                            onClick={() => remove(index)}
                            style={{ margin: 'auto auto auto 0' }}
                            disabled={isEdit}
                        >
                            Delete area
                        </Button>
                    </Space>
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Form.Item
                            label="Area name"
                            style={{ flex: 1, marginBottom: 12 }}
                            validateStatus={
                                !!errors?.[index]?.['area']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.[index]?.['area']?.message}
                        >
                            <AreaLoader
                                control={control}
                                feeIndex={index}
                                disabled={isEdit}
                            />
                        </Form.Item>
                        <div style={{ flex: 1 }} />
                    </div>

                    <Rules
                        feeIndex={index}
                        control={control}
                        isEdit={isEdit}
                        errors={errors?.[index]?.rules}
                        clearErrors={clearErrors}
                    />
                    {!!errors?.[index]?.rules?.size && (
                        <div style={{ marginTop: 12 }}>
                            <Text type="danger">
                                {errors?.[index]?.rules?.size?.message}
                            </Text>
                        </div>
                    )}
                </div>
            ))}
            <Button
                type="primary"
                onClick={() => {
                    clearErrors('fees.size')
                    append({})
                }}
                style={{ marginTop: 12 }}
                disabled={isEdit}
            >
                Add area
            </Button>
            {!!errors?.size && (
                <div style={{ marginTop: 12 }}>
                    <Text type="danger">{errors?.size?.message}</Text>
                </div>
            )}
        </>
    )
}

const Rules = ({ feeIndex, control, isEdit, errors, clearErrors }) => {
    const name = `fees.${feeIndex}.rules`
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    })

    return (
        <>
            {fields.map((field, index) => (
                <div
                    key={field.id}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gridGap: 8,
                    }}
                >
                    <RuleLine
                        feeIndex={feeIndex}
                        ruleIndex={index}
                        control={control}
                        isEdit={isEdit}
                        errors={errors?.[index]}
                        onDelete={() => remove(index)}
                    />

                    {/* <Button
                        type="link"
                        onClick={() => remove(index)}
                        style={{ margin: 'auto auto auto 0', gridRow: 2 }}
                        disabled={isEdit}
                    >
                        Delete
                    </Button> */}
                </div>
            ))}
            <Button
                type="dashed"
                onClick={() => {
                    clearErrors(`${name}.size`)
                    append({})
                }}
                disabled={isEdit}
            >
                Add rule
            </Button>
        </>
    )
}

const RuleLine = ({
    control,
    feeIndex,
    ruleIndex,
    isEdit,
    errors,
    onDelete,
}) => {
    const type = useWatch({
        control,
        name: `fees.${feeIndex}.rules.${ruleIndex}.type`,
    })

    return (
        <>
            <Form.Item
                label={
                    <Space size="large">
                        Rule
                        <Button
                            type="link"
                            onClick={onDelete}
                            disabled={isEdit}
                            size="small"
                        >
                            Delete
                        </Button>
                    </Space>
                }
                style={{ gridColumn: '1 / 3', marginBottom: 12 }}
                validateStatus={!!errors?.['type'] ? 'error' : undefined}
                help={errors?.['type']?.message}
            >
                <Controller
                    name={`fees.${feeIndex}.rules.${ruleIndex}.type`}
                    control={control}
                    required={{ required: 'Required' }}
                    render={({ value, onChange }) => (
                        <Select
                            value={value}
                            onChange={onChange}
                            disabled={isEdit}
                        >
                            <Select.Option value="flat">Flat</Select.Option>
                            <Select.Option value="up_to">
                                Up to (items quantity)
                            </Select.Option>
                            <Select.Option value="free_after_quantity">
                                Free after (items quantity)
                            </Select.Option>
                            <Select.Option value="free_after_value">
                                Free after (order value)
                            </Select.Option>
                        </Select>
                    )}
                />
            </Form.Item>
            {(type === 'up_to' ||
                type === 'free_after_quantity' ||
                type === 'free_after_value') && (
                <Form.Item
                    label={
                        type === 'free_after_value' ? 'Order value' : 'Quantity'
                    }
                    validateStatus={
                        !!errors?.['quantity'] ? 'error' : undefined
                    }
                    help={errors?.['quantity']?.message}
                >
                    <Controller
                        name={`fees.${feeIndex}.rules.${ruleIndex}.quantity`}
                        control={control}
                        required={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            )}
            {(type === 'up_to' || type === 'flat') && (
                <Form.Item
                    label="Delivery fee (¥)"
                    validateStatus={!!errors?.['fee'] ? 'error' : undefined}
                    help={errors?.['fee']?.message}
                >
                    <Controller
                        name={`fees.${feeIndex}.rules.${ruleIndex}.fee`}
                        control={control}
                        required={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            )}
        </>
    )
}

const AreaLoader = ({ control, feeIndex, disabled }) => {
    const { token } = useContext(AppContext)
    const [options, setOptions] = useState([])

    const areas = useQuery(
        ['getDeliveryAreas', { token: token.token }],
        getDeliveryAreas,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    )

    const getAreas = (parentId, allAreas) => {
        let newAreas = []
        const filteredAreas = allAreas.filter((e) => e.parent === parentId)

        if (filteredAreas.length) {
            for (const line of filteredAreas) {
                let childs = getAreas(line.id, allAreas)

                newAreas.push({
                    value: line.id,
                    label: line.name,
                    children: childs.length ? childs : undefined,
                })
            }
        }

        return newAreas
    }

    useEffect(() => {
        if (!areas.data) return

        const newAreas = getAreas(undefined, areas.data.list)

        setOptions(newAreas)
    }, [areas.data])

    const displayRender = (labels, selectedOptions) =>
        labels.length && options.length ? (
            <span>{labels[labels.length - 1]}</span>
        ) : null

    return (
        <Controller
            name={`fees.${feeIndex}.area`}
            control={control}
            rules={{ required: 'Required' }}
            render={({ value, onChange }) => (
                <Cascader
                    value={value}
                    onChange={onChange}
                    options={options}
                    displayRender={displayRender}
                    changeOnSelect
                    disabled={disabled}
                />
            )}
        />
    )
}

export default Details
