import { Form } from 'antd'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'

import {
    PurchaseOrdersCreate,
    cleanBeforeSending,
    Product,
} from '../../constants/forms/PurchaseOrders'
import { CreatePurchaseOrder as request } from '../../network/PurchaseOrders'

const CreatePurchaseOrder = () => {
    const [form] = Form.useForm()

    return (
        <PageWrap
            goBack
            title="PURCHASE_ORDER_DETAIL_CREATE_PURCHASE_ORDER"
        >
            <FormWrap
                form={form}
                title="create_purchase_order"
                request={request}
                // cleanBeforeSending={cleanBeforeSending}
                onSuccess={'/direct-sales'}
                onCancel={'/direct-sales'}
                invalidate={['getDirectSalesList']}
            >
                <div className="SectionHorizontalWrap">
                    <div className="SectionVerticalWrap" style={{ flex: 1 }}>
                        <FormBlock
                            form={form}
                            formTemplate={PurchaseOrdersCreate}
                        />
                        <FormBlock form={form} formTemplate={Product} />
                    </div>
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreatePurchaseOrder
