import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'

import {
    Description,
    Media,
    Tags,
    RecipeDetails,
    cleanBeforeSending,
} from '../../constants/forms/Recipes'
import { CreateRecipe as request } from '../../network/Recipes'

const CreateRecipe = () => {
    return (
        <PageWrap goBack title="RECIPES_CREATE_RECIPE">
            <FormWrap
                title="create_supplier"
                request={request}
                onSuccess={'/recipes'}
                onCancel={'/recipes'}
                cleanBeforeSending={cleanBeforeSending}
                invalidate={['getRecipes']}
                checkValidity={(values) => {
                    if (
                        !values.mainPicture?.en?.uri ||
                        !values.mainPicture?.zh?.uri
                    ) {
                        return 'Please set main pictures'
                    }

                    return false
                }}
            >
                <div className="SectionHorizontalWrap">
                    <div className="SectionVerticalWrap" style={{ flex: 2 }}>
                        <FormBlock formTemplate={Description} />
                        <FormBlock formTemplate={Media} />
                    </div>

                    <div className="SectionVerticalWrap" style={{ flex: 1 }}>
                        <FormBlock formTemplate={Tags} />
                        <FormBlock formTemplate={RecipeDetails} />
                    </div>
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateRecipe
