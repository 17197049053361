import { INSTANCE, INSTANCELIST } from './API'
import { inventoryKeyMap } from '../constants/lang'

export const getProducts = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/products/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getProductsBasic = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/products/details?stock=true',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify({
                    ...filter,
                    status: { $ne: 'discontinued' },
                }),
            },
        })
            // .then(resolve)
            .then((results) => {
                results?.list.sort(function (a, b) {
                    if (a?.name?.en.toLowerCase() > b?.name?.en.toLowerCase()) return 1;
                    if (a?.name?.en.toLowerCase() < b?.name?.en.toLowerCase()) return -1;
                    return 0;
                });
                resolve(results)
            })
            .catch(reject)
    })

export const getProductsDirectSales = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, filter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            // url: '/products',
            url: '/products/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                filter: { ...filter, status: { $ne: 'discontinued' } },
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getProductTypes = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/product-types',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            // .then(resolve)
            .then((results) => {
                results?.list?.map(result => {
                    if (result?.name === 'Others') {
                        result.name = `${result.name}(${result.category})`
                    }
                    result.name = inventoryKeyMap[result.name]
                })
                results?.list.sort(function (a, b) {
                    if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) return 1;
                    if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) return -1;
                    return 0;
                });
                resolve(results)
            })
            .catch(reject)
    })

export const CreateProduct = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/products',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleProduct = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { productId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/products/${productId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const EditProduct = (form, token, productsId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/products/${productsId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getProductsAnalytics = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { productId, token, ...tabFilter } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/products/${productId}/analytics`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getStocksWithProductDetails = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { productId, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/products/${productId}/stocks`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const mergeStock = (id, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: `/product-stocks/${id}/merge`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getProductsList = (token) =>
    new Promise((resolve, reject) => {
        INSTANCELIST({
            method: 'get',
            url: '/products',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })