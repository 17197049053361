import { useContext, useMemo, useCallback, useState, useEffect } from 'react'
import {
    Space,
    Avatar,
    Input,
    Badge,
    Popover,
    Button,
    Menu,
    Dropdown,
} from 'antd'
import {
    LoadingOutlined,
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
} from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import moment from 'moment'
import { PDFDownloadLink } from '@react-pdf/renderer'
import MyPDFDocument from 'src/components/MyPDF'
import debounce from 'lodash.debounce'

import Logo from '../images/Ellipse.svg'

import { formatDate, downloadCSV } from '../utils/utils'
import { getSingleOffer } from '../network/Offers'
import { getOrders, SERVER, EditOrdersStatus, EditOrders } from '../network/API'

import { AppContext } from '../context/AppContext'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import Status from '../components/tables/cells/Status'
import DynamicStrings from 'src/components/DynamicStrings'

//put the packs inside the columns config (replace 'packs' column)
const newColumns = (offer, col, keys) => {
    let columns = col(keys)
    if (!offer) return columns

    let wxPackList = []
    if (offer?.wechatGroup) {
        if (offer?.wechatGroup?.packs?.length)
            wxPackList = [...offer?.wechatGroup?.packs]
        if (offer?.wechatGroup?.items?.length)
            wxPackList = [...wxPackList, ...offer?.wechatGroup?.items]
    }

    let mpPackList = []
    if (offer.miniprogram) {
        if (offer?.wechatGroup?.packs?.length)
            mpPackList = [...offer?.wechatGroup?.packs]
        if (offer?.wechatGroup?.items?.length)
            mpPackList = [...mpPackList, ...offer?.wechatGroup?.items]
    }
    let packList = wxPackList > mpPackList ? wxPackList : mpPackList

    for (var i = 0; i < packList.length; i++) {
        let mp = i < mpPackList.length ? mpPackList[i].actualStock : 0
        let wx = i < wxPackList.length ? wxPackList[i].actualStock : 0

        packList[i].actualStock = mp + wx
    }

    const newPacksCol = packList.map((pack) => ({
        title: (
            <>
                {pack.shortName}
                {/* <br />({pack.actualStock}) */}
            </>
        ),
        width: 50,
        render: (e) => {
            let ordered = []
            if (e.packs) ordered = [...e.packs]
            if (e.singleItems) ordered = [...ordered, ...e.singleItems]

            let curPackId = ordered.findIndex(
                (e) => e.shortName === pack.shortName
            )

            return curPackId > -1 ? ordered[curPackId].amount : '-'
        },
    }))

    const packIndex = columns.findIndex((e) => e.key === 'packs')
    let newCols = [...columns]
    newCols.splice(packIndex, 1, ...newPacksCol)
    return newCols
}

const DeliveryTracking = ({ history }) => {
    const { token } = useContext(AppContext)
    let { offerId } = useParams()

    const offerDetails = useQuery(
        [
            'getSingleOffer',
            {
                offerId,
                token: token.token,
            },
        ],
        getSingleOffer
    )

    const getPackList = (offer) => {
        if (!offer) return []

        const mp = [
            ...(offer?.miniprogram?.packs?.length
                ? offer?.miniprogram?.packs?.map((e) => e.shortName)
                : []),
            ...(offer?.miniprogram?.items?.length
                ? offer?.miniprogram?.items?.map((e) => e.shortName)
                : []),
        ]
        const wx = [
            ...(offer?.wechatGroup?.packs?.length
                ? offer?.wechatGroup?.packs?.map((e) => e.shortName)
                : []),
            ...(offer?.wechatGroup?.items?.length
                ? offer?.wechatGroup?.items?.map((e) => e.shortName)
                : []),
        ]

        return mp.length > wx.length ? mp : wx
    }

    const getPackListMemo = useMemo(
        () => getPackList(offerDetails.data),
        [offerDetails.data]
    )

    const getOtherParams = () => {
        let otherParams

        if (offerDetails.data?.channel === 'wechat_group') {
            otherParams = {
                offer: offerId,
                unlimited: true,
                status: 'available',
                fields: { paymentStatus: { $in: ['paid', 'pending'] } },
            }
        } else {
            otherParams = {
                offer: offerId,
                unlimited: true,
                status: 'available',
                paymentStatus: 'paid',
            }
        }

        return otherParams
    }

    return (
        <PageWrap goBack title="DELIVERY_DELIVERY_TRACKING">
            <TableBlock
                columns={(goTo, keyRequest) =>
                    newColumns(offerDetails.data, columns, keyRequest)
                }
                filters={filtersConfig}
                name={'getOrders'}
                request={getOrders}
                // otherParams={{
                //     offer: offerId,
                //     unlimited: true,
                //     status: 'available',
                //     paymentStatus: 'paid',
                // }}
                otherParams={getOtherParams()}
                pageId={{
                    packs: getPackListMemo,
                    offerId: offerDetails?.data?.offerID,
                }}
                infinite
            />
        </PageWrap>
    )
}

const filtersConfig = (history, selectedKeys, isLoading, pageId, data) => ({
    fields: [
        {
            type: 'select',
            label: 'OFFERS_STATUS',
            key: 'trackingStatus',
            default: '',
            options: [
                { key: 'delivered', label: 'delivered' },
                { key: 'prepared', label: 'prepared' },
                { key: 'on_the_way', label: 'on_the_way' },
                { key: 'delayed', label: 'delayed' },
            ],
        },
        {
            type: 'spe',
            label: 'customer',
            key: 'customer.name',
        },
        {
            type: 'text',
            label: 'OFFER_LIVE_ORDER_ID',
            key: 'orderID',
        },
        {
            type: 'areas',
            label: 'OFFER_LIVE_AREA',
            key: 'customerAddress.area',
        },
    ],
    actions: [
        {
            label: 'DELIVERY_EXPORT_ORDER_ID',
            isActive: !isLoading,
            action: () => {
                let lines = []

                for (const line of data) {
                    const packs = pageId.packs.map(
                        (e) =>
                            line?.packs?.find((p) => p.shortName === e)
                                ?.amount ||
                            line?.singleItems?.find((p) => p.shortName === e)
                                ?.amount ||
                            '-'
                    )

                    lines.push([
                        line.orderNumber,
                        line.orderID,
                        line?.customer?.id
                            ? line.customer.name
                            : line.customerDaily?.customerName,
                        ...packs,
                        line.customerAddress?.phone,
                        line.customerAddress?.detailedAddress,
                        line.customerAddress?.city,
                        line.customerAddress?.area?.name,
                        line.comment,
                        line.deliveryDate && moment(line.deliveryDate).isValid()
                            ? moment(line.deliveryDate).format('DD/MM/YYYY')
                            : line.deliveryDate
                                  ?.toString()
                                  ?.replaceAll(',', '.')
                                  ?.replaceAll(/(\r\n|\n|\r)/gm, ''),
                        line.paymentStatus === 'paid' ? 'Yes' : 'No',
                        line.trackingStatus,
                        line.deliveryTrackingID,
                    ])
                }

                downloadCSV(
                    [
                        'N',
                        'Order ID',
                        'Name',
                        ...pageId.packs,
                        'Phone',
                        'Delivery Address',
                        'City',
                        'Area',
                        'Comment',
                        'Delivery Date',
                        'Paid',
                        'Status',
                        'Tracking number',
                    ],
                    lines,
                    `Delivery tracking ${pageId.offerId}`
                )
            },
        },
    ],
})

const columns = (keyRequest) => [
    {
        title: <DynamicStrings id="OFFER_LIVE_N" />,
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        width: 40,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        dataIndex: 'orderID',
        key: 'orderID',
        width: 75,
    },
    {
        title: '',
        key: 'customer',
        width: 40,
        render: (order) => (
            <Avatar
                size="small"
                src={
                    order?.customer?.profilePicture?.uri
                        ? `${SERVER.baseURL}/storage/customer-picture/${order?.customer?.profilePicture?.uri}`
                        : Logo
                }
            />
        ),
    },
    {
        title: <DynamicStrings id="ROLES_NAME" />,
        key: 'customer',
        render: (order) =>
            order?.customer?.id
                ? order.customer.name
                : order.customerDaily?.customerName,
        width: 100,
    },
    {
        title: <LoadingOutlined />,
        key: 'packs',
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_PHONE" />,
        dataIndex: 'customerAddress',
        key: 'customerAddress',
        render: (customer) => customer?.phone,
        width: 125,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_ADDRESS" />,
        dataIndex: 'customerAddress',
        key: 'customerAddress',
        render: ({ detailedAddress }) => detailedAddress,
        width: 125,
    },
    {
        title: <DynamicStrings id="ROLES_CITY" />,
        dataIndex: 'customerAddress',
        key: 'customerAddress',
        render: ({ city }) => city,
        width: 100,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_AREA" />,
        dataIndex: 'customerAddress',
        key: 'customerAddress',
        render: ({ area }) => area?.name,
        ellipsis: true,
        width: 100,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
        key: 'comment',
        render: (e) => e.comment,
        width: 125,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_DATE" />,
        // dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        render: (order) => <DeliveryDate order={order} />,
        width: 100,
    },
    {
        title: <DynamicStrings id="paid" />,
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
        width: 50,
        render: (paymentStatus) =>
            paymentStatus === 'paid' ? (
                <CheckOutlined style={{ color: '#52C41A' }} />
            ) : (
                <CloseOutlined style={{ color: '#FF4D4F' }} />
            ),
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        key: '',
        width: 125,
        render: ({ trackingStatus, id }) =>
            trackingStatus === 'delivered' ? (
                <Badge
                    color="green"
                    text={<DynamicStrings id={trackingStatus} />}
                />
            ) : (
                <Status
                    status={trackingStatus}
                    fieldKey={'trackingStatus'}
                    requestName={'getOrders'}
                    keyRequest={keyRequest}
                    editRequest={EditOrdersStatus}
                    id={id}
                    config={[
                        { key: 'prepared', color: 'orange' },
                        { key: 'on_the_way', color: 'blue' },
                        { key: 'delayed', color: 'red' },
                        { key: 'delivered', color: 'green' },
                    ]}
                />
            ),
    },
    {
        title: <DynamicStrings id="DELIVERY_TRACKING_NUMBER" />,
        key: 'deliveryTrackingID',
        width: 175,
        render: (order) => (
            <TrackingInput
                order={order}
                keyRequest={keyRequest}
                oid={order.id}
            />
        ),
    },
    {
        title: <DynamicStrings id="DELIVERY_EXPORT_ORDER_ID" />,
        width: 75,
        render: (e) => (
            <Space className="centerMe">
                <Popover
                    content={
                        <Space>
                            <PDFDownloadLink
                                document={
                                    <MyPDFDocument
                                        noSupplier
                                        lang="en"
                                        order={e}
                                        type={'direct'}
                                    />
                                }
                                fileName={`${e.orderID}.pdf`}
                            >
                                {({ blob, url, loading, error, ...rest }) => (
                                    <Button
                                        type="primary"
                                        size="small"
                                        loading={loading}
                                        danger={error}
                                    >
                                        En
                                    </Button>
                                )}
                            </PDFDownloadLink>
                            {/* <PDFDownloadLink
                                document={
                                    <MyPDFDocument
                                        noSupplier
                                        lang="zh"
                                        order={e}
                                        type={'direct'}
                                    />
                                }
                                fileName={`${e.orderID}.pdf`}
                            >
                                {({ blob, url, loading, error }) => (
                                    <Button
                                        type="primary"
                                        size="small"
                                        loading={loading}
                                        danger={error}
                                    >
                                        Zh
                                    </Button>
                                )}
                            </PDFDownloadLink> */}
                        </Space>
                    }
                    title={null}
                    // trigger="click"
                >
                    <DownloadOutlined style={{ color: '#1890ff' }} />
                </Popover>
            </Space>
        ),
    },
]

const TrackingInput = ({ order, keyRequest, oid }) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()

    const debouncedSave = useCallback(
        debounce((value) => {
            EditOrders(value, token.token, order.id)
        }, 1500),
        [] // will be created only once initially
    )

    return (
        <Input
            defaultValue={order.deliveryTrackingID}
            size="small"
            onChange={(e) => {
                debouncedSave({ deliveryTrackingID: e.target.value })

                queryClient.setQueryData(keyRequest, (oldData) => {
                    let oldDataIndex = oldData.list.findIndex(
                        (e) => e.id === oid
                    )
                    oldData.list[oldDataIndex].deliveryTrackingID =
                        e.target.value
                    return oldData
                })
            }}
        />
    )
}

const DeliveryDate = ({ order }) => {
    const { token } = useContext(AppContext)
    let { offerId } = useParams()
    const [dateValue, setDateValue] = useState(order.deliveryDate)
    const dateFormat = 'YYYY-MM-DDTHH:mm:SS.sssZ'

    useEffect(() => {
        if (dateValue && moment(dateValue, dateFormat, true).isValid()) {
            setDateValue(moment(dateValue).format('ddd DD'))
        }
    }, [])

    const offerDetails = useQuery(
        [
            'getSingleOffer',
            {
                offerId,
                token: token.token,
            },
        ],
        getSingleOffer
    )

    const debouncedSave = useCallback(
        debounce((value) => {
            EditOrders(value, token.token, order.id)
        }, 1500),
        [] // will be created only once initially
    )

    const onChange = (e) => {
        if (e && moment(e, dateFormat, true).isValid()) {
            setDateValue(moment(e).format('ddd DD'))
        } else {
            setDateValue(e)
        }

        // setDateValue(e)
        debouncedSave({ deliveryDate: e })
    }

    const menu = (
        <Menu>
            {offerDetails?.data?.deliveryDates?.map((date) => (
                <Menu.Item
                    // onClick={() => onChange(moment(date).format('ddd DD'))}
                    onClick={() => onChange(date)}
                >
                    {moment(date).format('ddd DD')}
                </Menu.Item>
            ))}
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Input
                type="text"
                size="small"
                // className={
                //     order.paymentStatus === 'ccl'
                //         ? undefined
                //         : `${order?.deliveryDate ? '' : ' is-low'}${
                //               !!deliveryD?.find(
                //                   (e) =>
                //                       moment(e).format('ddd DD') ===
                //                       order?.deliveryDate
                //               )
                //                   ? ''
                //                   : ' is-highlight'
                //           }`
                // }
                // onFocus={() => setLineIsSelected(true)}
                // onBlur={() => setLineIsSelected(false)}
                // value={order?.deliveryDate}
                value={dateValue}
                // bordered={false}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
            />
        </Dropdown>
    )
}

export default DeliveryTracking
