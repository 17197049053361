import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { Space, Typography, Card } from 'antd'

import { CreateVoucher as request } from '../../network/API'
import { Voucher, VoucherRefund } from '../../constants/forms/Voucher'
import { AppContext } from '../../context/AppContext'

import { useTranslation } from 'react-i18next'

const { Text } = Typography

const CreateVoucher = ({ history }) => {
    const { user } = useContext(AppContext)
    const { t } = useTranslation()

    const customer = history?.location?.customer
    const refundOrder = history?.location?.refundOrder

    const refund = history?.location?.refund
    const reason = history?.location?.reason

    console.log(history?.location)

    if (!history?.location?.customer) {
        return <Redirect to={{ pathname: '/' }} />
    }

    return (
        <PageWrap
            goBack
            title="SERVICES_ORDER_LIST_CREATE_VOUCHER"
        >
            <FormWrap
                title="create_voucher"
                request={request}
                onSuccess={'/vouchers'}
                onCancel={'/vouchers'}
                data={{
                    teamMember: user.name,
                    // reason: !!refundOrder ? 'cancel_order' : undefined,
                    reason: !!refundOrder && !refund ? 'cancel_order' : (!!reason ? reason : undefined),
                }}
                cleanBeforeSending={(oldData) => {
                    oldData.teamMember = user?.id
                    oldData.customer = customer?.id
                    oldData.refund = refund?.id
                    oldData.refundOrder = refundOrder?.id

                    return oldData
                }}
            >
                <div className="SectionVerticalWrap">
                    {(!!refundOrder || !!customer) && (
                        <Card>
                            <Space size="large">
                                {!!customer && (
                                    <Text>
                                        <Text strong>{t('customer')}: </Text>
                                        {customer.name}
                                    </Text>
                                )}

                                {!!refundOrder && (
                                    <Text>
                                        <Text strong>Order: </Text>
                                        {refundOrder.orderID}
                                    </Text>
                                )}
                            </Space>
                        </Card>
                    )}

                    <FormBlock formTemplate={reason && reason === 'refund' ? VoucherRefund : Voucher} />
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateVoucher
