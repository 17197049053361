import { useState } from 'react'
import { Space, Button, Typography, message } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

const { Paragraph } = Typography

const Clipboard = ({ shortcuts }) => {
    const [open, setOpen] = useState(true)

    if (!shortcuts || shortcuts.length === 0) return null

    return (
        <Space size={0} className={`clipboard wrap${open ? '' : ' closed'}`}>
            <div onClick={() => setOpen(!open)} className="close-arrow">
                {/* <CaretLeftOutlined /> */}
                <CaretRightOutlined />
            </div>
            <Space className="buttonWrap">
                {shortcuts?.map((shortcut, i) => (
                    <Button
                        key={i}
                        className="button"
                        onClick={() => {
                            navigator.clipboard.writeText(shortcut.text)
                            message.info(`${shortcut.shortcut} copied`)
                        }}
                        type="primary"
                    >
                        <Paragraph className="buttonText" ellipsis={true}>
                            {shortcut.shortcut}
                        </Paragraph>
                    </Button>
                ))}
            </Space>
        </Space>
    )
}

export default Clipboard
