import { UploadOutlined } from '@ant-design/icons'
import { Upload, Button, message } from 'antd'
import { useMemo, useContext, useState } from 'react'
import { SERVER, upload } from '../../../network/API'
import { useMutation } from 'react-query'
import { AppContext } from '../../../context/AppContext'

const UploadField = ({ value, onChange, multiple, storage }) => {
    const { token } = useContext(AppContext)
    const [pendingFiles, setPendingFiles] = useState([])

    const uploadProps = multiple
        ? {
              maxCount: 6,
              multiple: false,
          }
        : {
              maxCount: 1,
              multiple: false,
          }

    const mutation = useMutation(
        (file) => upload(file, `/files/${storage}`, token.token),
        {
            onMutate: (file) => {
                setPendingFiles((e) => [
                    ...e,
                    {
                        uid: file.uid,
                        name: file.name,
                        status: 'uploading',
                        percent: 50,
                    },
                ])
            },
            onSuccess: (data, file) => {
                if (multiple) {
                    // console.log('kkkk onSuccess', data, file, value, watchedValue)
                    setPendingFiles((e) => e.filter((e) => e.uid !== file.uid))
                    onChange([...(value || []), data])
                } else {
                    setPendingFiles([])
                    onChange(data)
                }

                onChange(!multiple ? data : [...(value || []), data])
            },
            onError: (err, file) => {
                message.error('An error occurs uploading')

                setPendingFiles((e) => {
                    const index = e.findIndex((e) => e.uid === file.uid)

                    if (index === -1) return e

                    e[index].status = 'error'

                    return [...e]
                })
            },
        }
    )

    const fileList = useMemo(() => {
        if (!multiple) {
            if (!value) return []

            return [
                {
                    uid: '0',
                    name: value.name,
                    status: 'done',
                    url: `${SERVER.baseURL}/storage/${storage}/${value.uri}`,
                },
            ]
        }

        if (!value?.length) return []

        return value?.map((e, id) => ({
            uid: `${id}`,
            name: e.name,
            status: 'done',
            url: `${SERVER.baseURL}/storage/${storage}/${e.uri}`,
        }))
    }, [value])

    return (
        <Upload
            {...uploadProps}
            disabled={mutation.isLoading}
            listType="picture"
            fileList={[...fileList, ...pendingFiles]}
            showUploadList={{ showPreviewIcon: false }}
            customRequest={(e) => mutation.mutate(e.file)}
            onRemove={(e) => {
                if (multiple) {
                    e.uri = e.url?.replace(
                        `${SERVER.baseURL}/storage/${storage}/`,
                        ''
                    )
    
                    const indexOnValue = value.findIndex(
                        (el) => el.uri === e.uri
                    )

                    if (indexOnValue !== -1) {
                        const list = [...value]
                        list.splice(indexOnValue, 1)
                        onChange(list)
                        return
                    }

                    const indexOnPending = pendingFiles.findIndex(
                        (el) => el.uid === e.uid
                    )

                    if (indexOnPending !== -1) {
                        const list = [...pendingFiles]
                        list.splice(indexOnPending, 1)
                        setPendingFiles(list)
                        return
                    }
                } else {
                    onChange(null)
                }
            }}
        >
            <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
    )
}

export default UploadField
