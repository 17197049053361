import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Form } from 'antd'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import DeleteButton from '../../components/DeleteButton'

import {
    TemplateDescription,
    TemplateArea,
    cleanBeforeSendingTemplate,
} from '../../constants/forms/Delivery'
import {
    EditDeliveryTemplates,
    getSingleDeliveryTemplates,
    DeleteTemplate,
} from '../../network/API'

const CreateDeliveryTemplate = () => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()
    let { templateId } = useParams()
    const [isDeleted, setIsDeleted] = useState(false)

    const templateDetails = useQuery(
        [
            'getSingleDeliveryTemplates',
            {
                templateId: templateId,
                token: token.token,
            },
        ],
        getSingleDeliveryTemplates,
        {
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (oldData) => {
        const newAreas = oldData.areas.map((area) => {
            let newCities = []
            let cities = { ...area.area }
            while (cities) {
                newCities.push(cities.id)
                cities = cities.parent
            }

            newCities.reverse()
            return { ...area, area: newCities }
        })

        return { ...oldData, areas: newAreas }
    }

    const getDeleteResult = (result) => {
        setIsDeleted(result)
    }

    return (
        <PageWrap
            goBack
            isLoading={templateDetails.isLoading}
            title={templateDetails.data?.name}
            actionOptions={[
                <DeleteButton
                    onSuccess="/delivery"
                    request={DeleteTemplate}
                    id={templateId}
                    invalidate={['getDeliveryAreas']}
                    isDeleted={getDeleteResult}
                />,
            ]}
        >
            {templateDetails.isError ? (
                <Error retry={() => templateDetails.refetch()} />
            ) : templateDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    title="create_user"
                    request={EditDeliveryTemplates}
                    id={templateId}
                    onSuccess="/delivery"
                    onCancel="/delivery"
                    data={formatData(templateDetails.data)}
                    cleanBeforeSending={cleanBeforeSendingTemplate}
                    invalidate={[
                        'getDeliveryTemplates',
                        'getSingleDeliveryTemplates',
                    ]}
                    isDeleted={isDeleted}
                >
                    <div className="SectionVerticalWrap">
                        <FormBlock
                            form={form}
                            formTemplate={TemplateDescription}
                        />
                        <FormBlock formTemplate={TemplateArea} />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default CreateDeliveryTemplate
