import { getCommunities } from '../../network/API'
import { getCustomers } from '../../network/Customers'

const defaultCommunities = [
    '60541eab7b87f1126ecf53e1',
    '60541eb37b87f1126ecf53e2',
    '60541eb87b87f1126ecf53e3',
    '60541ebc7b87f1126ecf53e4',
]

const PersonalInformation = {
    title: 'CUSTOMERS_PERSONAL_INFORMATION',
    key: [],
    sections: [
        {
            columns: 4,
            fields: [
                {
                    label: 'CUSTOMERS_CUSTOMER_NAME',
                    isRequired: true,
                    key: ['name'],

                    type: 'search&update',
                    options: getCustomers,
                    searchKey: 'name',
                    formatOnRecieved: (customer) => {
                        return {
                            name: customer.name
                        }
                    },

                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    type: 'select',
                    label: 'CUSTOMERS_LEVEL',
                    default: 'tourist',
                    options: [
                        { key: 'tourist', label: 'tourist' },
                        {
                            key: 'curious_wanderer',
                            label: 'curious_wanderer',
                        },
                        {
                            key: 'humble_resident',
                            label: 'humble_resident',
                        },
                        {
                            key: 'chummy_villager',
                            label: 'chummy_villager',
                        },
                        {
                            key: 'lord_furnace',
                            label: 'lord_furnace',
                        },
                        {
                            key: 'secret_circle',
                            label: 'secret_circle',
                        },
                    ],
                    key: ['level'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_WECHAT_ID',
                    // isRequired: true,
                    // isRequired: true,
                    key: ['wechatID'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'OFFERS_STATUS',
            isRequired: true,
            columns: 1,
            fields: [
                {
                    key: ['status'],
                    type: 'basicRadio',
                    default: 'available',
                    options: [
                        { label: 'available', key: 'available' },
                        { label: 'discontinued', key: 'discontinued' },
                    ],
                },
            ],
        },
    ],
}

const Contacts = {
    title: 'CUSTOMERS_CONTACT_PERSONS',
    key: [],
    sections: [
        {
            canAdd: true,
            key: ['contacts'],
            columns: 4,
            deleteBtn: {
                isInactive: true,
                label: 'ROLES_DELETE',
                style: 'line',
                pos: {
                    col: 3,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: {
                isInactive: true,
                label: 'CUSTOMERS_ADD_PHONE_NUMBER',
                style: 'dashed',
            },
            fields: [
                {
                    label: 'CUSTOMERS_CONTACT_NAME',
                    // isRequired: true,
                    isInactive: true,
                    key: ['name'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_PHONE_NUMBER',
                    // isRequired: true,
                    isInactive: true,
                    key: ['phone'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Address = {
    title: 'CUSTOMERS_ADDRESS',
    key: [],
    sections: [
        {
            canAdd: true,
            defaultOpen: true,
            addSuffix: 'number',
            title: 'CUSTOMERS_ADDRESS',
            key: ['addresses'],
            columns: 4,
            deleteBtn: { label: 'ROLES_DELETE', style: 'title' },
            addBtn: { label: 'CUSTOMERS_ADD_ADDRESS', style: 'dashed' },
            fields: [
                {
                    label: 'CUSTOMERS_ADDRESS_TYPE',
                    isRequired: true,
                    key: ['type'],
                    type: 'select',
                    options: [
                        { label: 'Home', key: 'home' },
                        { label: 'Office', key: 'office' },
                        { label: 'Other', key: 'other' },
                    ],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_CITY',
                    isRequired: true,
                    key: ['city'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_ZIPCODE',
                    // isRequired: true,
                    key: ['zipCode'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_AREA',
                    type: 'area',
                    isRequired: true,
                    key: ['area'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_DETAILED_ADDRESS',
                    type: 'text',
                    isRequired: true,
                    key: ['detailedAddress'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 4,
                    },
                },
                // {
                //     label: 'DIRECT_SALES_PHONE_NUMBER',
                //     isRequired: true,
                //     key: ['phone'],
                //     pos: {
                //         col: 1,
                //         row: 3,
                //         size: 1,
                //     },
                // },
                {
                    label: 'OFFER_LIVE_COMMENT',
                    key: ['comment'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 3,
                    },
                },
                {
                    label: 'DIRECT_SALES_PHONE_NUMBER',
                    // isRequired: true,
                    key: ['phone'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_CONTACT_NAME',
                    // isRequired: true,
                    key: ['contact'],
                    pos: {
                        col: 2,
                        row: 4,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Fapiao = {
    title: 'OFFER_LIVE_FAPIAO',
    key: [],
    sections: [
        {
            columns: 1,
            fields: [
                {
                    label: 'DIRECT_SALES_FAPIAO_INFORMATION',
                    type: 'text',
                    key: ['fapiaoInformation'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_FAPIAO_UPLOAD',
                    key: ['fapiaoImages'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    requestURI: '/files/customer-fapiao-image',
                    storageURI: '/storage/customer-fapiao-image',
                    type: 'multiupload',
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
            ],
        },
        {
            canAdd: true,
            key: ['fapiaoEmails'],
            columns: 4,
            deleteBtn: {
                label: 'ROLES_DELETE',
                style: 'line',
                pos: {
                    col: 3,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: {
                label: 'CUSTOMERS_ADD_ADDRESS',
                style: 'dashed',
            },
            noMargin: true,
            fields: [
                {
                    label: 'ROLES_EMAIL',
                    isRequired: true,
                    key: ['email'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const WeChatGroups = {
    title: 'OFFER_LIVE_WECHAT_GROUP',
    key: [],
    sections: [
        {
            isList: true,
            length: 4,
            columns: 2,
            key: ['wechatGroups'],
            fields: [
                {
                    label: 'ROLES_COMMUNITY',
                    isInactive: true,
                    className: 'wechat-group-name',
                    key: ['community'],
                    type: 'select',
                    requestOption: getCommunities,
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_WECHAT_GROUP',
                    type: 'number',
                    isInt: true,
                    placeholder: '-',
                    key: ['groupNumber'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Pets = {
    title: 'CUSTOMERS_PET_INFORMATION',
    key: [],
    sections: [
        {
            canAdd: true,
            title: 'CUSTOMERS_PET',
            addSuffix: 'number',
            key: ['pets'],
            columns: 4,
            deleteBtn: { label: 'ROLES_DELETE', style: 'title' },
            addBtn: { label: 'CUSTOMERS_ADD_PET', style: 'dashed' },
            fields: [
                {
                    label: 'ROLES_NAME',
                    isRequired: true,
                    key: ['name'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'CUSTOMERS_PET_TYPE',
                    isRequired: true,
                    key: ['type'],
                    type: 'select',
                    options: [
                        { label: 'CUSTOMERS_CAT', key: 'cat' },
                        { label: 'CUSTOMERS_DOG', key: 'dog' },
                    ],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_PET_SIZE',
                    isRequired: true,
                    key: ['size'],
                    type: 'select',
                    options: [
                        { label: 'CUSTOMERS_SMALL', key: 'small' },
                        { label: 'CUSTOMERS_MEDIUM', key: 'middle' },
                        { label: 'CUSTOMERS_BIG', key: 'large' },
                    ],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    oldData.flagged = false
    oldData.addresses = oldData.addresses?.map((address) => ({
        ...address,
        area: address.area[address.area.length - 1],
    }))

    let newGroups = []
    for (const group of oldData.wechatGroups) {
        if (group.groupNumber) newGroups.push(group)
    }
    oldData.wechatGroups = newGroups

    if (oldData.fapiaoImages) {
        const otherPic = oldData.fapiaoImages.fileList
            ? oldData.fapiaoImages?.fileList
            : oldData.fapiaoImages
        oldData.fapiaoImages = otherPic.map((file) =>
            file.response ? file.response : file
        )
    } else {
        oldData.fapiaoImages = []
    }

    return oldData
}

export {
    PersonalInformation,
    Contacts,
    Fapiao,
    WeChatGroups,
    Address,
    Pets,
    defaultCommunities,
    cleanBeforeSending,
}
