import moment from 'moment'
import { getProductsDirectSales } from '../../network/Products'
import { getDirectSalesSuppliers } from '../../network/Suppliers'
import set from 'lodash.set'

const PurchaseOrdersCreate = {
    title: 'PURCHASE_ORDER_DETAIL_PO_INFORMATION',
    key: [],
    sections: [
        {
            title: 'DIRECT_SALES_GENERAL_INFORMATION',
            columns: 6,
            fields: [
                {
                    label: 'supplier',
                    isRequired: true,
                    type: 'select',
                    requestOption: getDirectSalesSuppliers,
                    key: ['supplier'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'PURCHASE_ORDER_DETAIL_PURCHASE_DATE',
                    isRequired: true,
                    type: 'date',
                    key: ['date'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const PurchaseOrdersEdit = {
    title: 'PURCHASE_ORDER_DETAIL_PO_INFORMATION',
    key: [],
    sections: [
        {
            title: 'DIRECT_SALES_GENERAL_INFORMATION',
            columns: 6,
            fields: [
                {
                    label: 'supplier',
                    isInactive: true,
                    type: 'select',
                    requestOption: getDirectSalesSuppliers,
                    key: ['supplier'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'PURCHASE_ORDER_DETAIL_PURCHASE_DATE',
                    default: moment(),
                    type: 'date',
                    key: ['date'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Product = {
    title: 'DIRECT_SALES_PRODUCTS',
    key: [],
    sections: [
        {
            canAdd: true,
            key: ['purchaseOrderItems'],
            noDivider: true,
            columns: 6,
            deleteBtn: {
                label: 'DIRECT_SALES_DELETE_PRODUCT',
                style: 'line',
                pos: {
                    col: 4,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: {
                label: 'CREATE_OFFER_ADD_PRODUCT',
                style: 'primary',
            },
            fields: [
                {
                    label: 'CREATE_OFFER_SELECT_PRODUCT',
                    isRequired: true,
                    type: 'select',
                    requestOption: getProductsDirectSales,
                    conditionalFilter: (form) => {
                        let sup = form.getFieldValue(['supplier'])
                        return { supplier: sup ? sup : null }
                    },
                    onChange: (form, name, e, elem) => {
                        const values = form.getFieldValue()
                        set(
                            values,
                            `purchaseOrderItems.${name[0]}.unitPrice`,
                            elem.price
                        )
                        set(
                            values,
                            `purchaseOrderItems.${name[0]}.vatPercentage`,
                            elem.supplier.vat
                        )
                        form.setFieldsValue(values)
                    },
                    key: ['product'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 3,
                    },
                },
                {
                    label: 'CREATE_OFFER_QUANTITY',
                    isRequired: true,
                    type: 'number',
                    isInt: true,
                    key: ['quantity'],
                    onChange: (form, name, e) => {
                        const values = form.getFieldValue()
                        const index = name[0]

                        const qty = e
                        const unitPrice =
                            values.purchaseOrderItems[index].unitPrice || 0
                        const vat =
                            values.purchaseOrderItems[index].vatPercentage || 0

                        const totalWT = qty * unitPrice
                        // const totalTax = totalWT * (vat / 100)
                        const totalTax= totalWT * (vat / 100) / ((100 + vat) / 100)

                        const total = totalWT - totalTax
                        set(
                            values,
                            `purchaseOrderItems.${index}.totalPrice`,
                            Math.round((total + Number.EPSILON) * 100) / 100
                        )
                        form.setFieldsValue(values)
                    },
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'PURCHASE_ORDER_DETAIL_UNIT_PRICE_INCL_VAT',
                    isRequired: true,
                    type: 'number',
                    key: ['unitPrice'],
                    onChange: (form, name, e) => {
                        const values = form.getFieldValue()
                        const index = name[0]

                        const qty =
                            values.purchaseOrderItems[index].quantity || 0
                        const unitPrice = e

                        const vat =
                            values.purchaseOrderItems[index].vatPercentage || 0

                        const totalWT = qty * unitPrice
                        // const totalTax = totalWT * (vat / 100)
                        const totalTax= totalWT * (vat / 100) / ((100 + vat) / 100)

                        const total = totalWT - totalTax
                        set(
                            values,
                            `purchaseOrderItems.${index}.totalPrice`,
                            Math.round((total + Number.EPSILON) * 100) / 100
                        )
                        form.setFieldsValue(values)
                    },
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_TOTAL_EXC_VAT',
                    isRequired: true,
                    type: 'number',
                    key: ['totalPrice'],
                    isInactive: true,
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'SUPPLIERS_VAT',
                    suffixLabel: '%',
                    isRequired: true,
                    type: 'number',
                    max: 100,
                    key: ['vatPercentage'],
                    onChange: (form, name, e) => {
                        const values = form.getFieldValue()
                        const index = name[0]

                        const qty =
                            values.purchaseOrderItems[index].quantity || 0
                        const unitPrice =
                            values.purchaseOrderItems[index].unitPrice || 0
                        const vat = e

                        const totalWT = qty * unitPrice
                        // const totalTax = totalWT * (vat / 100)
                        const totalTax= totalWT * (vat / 100) / ((100 + vat) / 100)

                        const total = totalWT - totalTax
                        set(
                            values,
                            `purchaseOrderItems.${index}.totalPrice`,
                            Math.round((total + Number.EPSILON) * 100) / 100
                        )
                        form.setFieldsValue(values)
                    },
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    let newData = JSON.parse(JSON.stringify(oldData))

    let purchaseOrderItems = [
        //     {
        //         product: '618a4e251e1c58b456967ecb',
        //         quantity: 0,
        //         totalPrice: 0,
        //         unitPrice: 0,
        //         vatPercentage: 0,
        //     },
    ]

    for (const item of newData.purchaseOrderItems) {
        const qty = item.quantity || 0
        const vat = item.vatPercentage || 0
        const unit = item.unitPrice || 0

        const totalWT = qty * unit
        // const totalWOT = totalWT / ((vat + 100) / 100)
        const totalWOT = totalWT - totalWT * (vat / 100) / ((100 + vat) / 100)

        // console.log('koko', {
        //     product: item.product,
        //     quantity: item.quantity,
        //     totalPrice: totalWOT,
        //     unitPrice: item.unitPrice,
        //     vatPercentage: item.vatPercentage,
        //     // totalPrice: totalWOT,
        // })

        purchaseOrderItems = [
            ...purchaseOrderItems,
            {
                product: item.product,
                quantity: item.quantity,
                totalPrice: totalWOT,
                unitPrice: item.unitPrice,
                vatPercentage: item.vatPercentage,
                // totalPrice: totalWOT,
            },
        ]
    }

    return { ...oldData, purchaseOrderItems }
}

export { PurchaseOrdersCreate, PurchaseOrdersEdit, Product, cleanBeforeSending }
