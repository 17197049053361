const numberToLetter = (index) => {
    const toLetter = (nb) => (nb + 10).toString(36).toUpperCase()

    const nbTimesIsIn = Math.floor(index / 26)
    const fromBeginning = index - nbTimesIsIn * 26

    return `${!!nbTimesIsIn ? toLetter(nbTimesIsIn - 1) : ''}${toLetter(
        fromBeginning
    )}`
}

export default numberToLetter
