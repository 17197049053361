/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useState,
    useMemo,
    useContext,
    useEffect,
    useReducer,
    useCallback,
} from 'react'
import { Space, Spin } from 'antd'
import { getDeliveryAreas } from '../../network/API'

import { OfferLiveWX } from '../../context/OfferLiveWX'
import { WSOrders } from '../../context/WSOrders'

import PacksStatLive from './PacksStatsLive'
import Clipboard from '../../components/Clipboard'
import OrderLine from './OrderLine'
import NewOrderLine from './NewOrderLine'
import TableHeader from './TableHeader'
import { AppContext } from 'src/context/AppContext'

const newOrdersReducer = (state, value) => {
    if (!state.find((e) => e.id === value.id)) {
        return [value, ...state].sort((a, b) => {
            if (a.orderNumber > b.orderNumber) {
                return -1
            } else {
                return 1
            }
        })
    }
    return state
}

const WxGroup = ({
    orders,
    bourse,
    // ws,
    offerId,
    fees,
    supplierId,
    clipboard,
    isLoading,
    community,
    packs,
    items,
    deliveryDates,
    minimumCartItems,
    minimumOrderAmount,
    canCreate,
    freeFall,
}) => {
    const [selectedLine, setSelectedLine] = useState()
    const [selectedColumn, setSelectedColumn] = useState()
    // const [newOrders, setNewOrders] = useState([])
    const [newOrders, setNewOrders] = useReducer(newOrdersReducer, [])
    const { wsOffer } = useContext(WSOrders)
    const { token } = useContext(AppContext)
    const [availableAreas, setAvailableAreas] = useState([])
    const [allAreas, setAllAreas] = useState([])
    const [areaOptions, setAreaOptions] = useState([])

    useEffect(() => {
        try {
            wsOffer.addEventListener('message', (message) => {
                let data = JSON.parse(message.data)
                // console.log('WebSocket - Message recieved line', data)

                switch (data?.type) {
                    case 'NEW_ORDER':
                        if (data.order.channel === 'wechat_group') {
                            setNewOrders(data.order)
                        }
                        break
                    // case 'UPDATE_ORDER':
                    //     if (data.order.id === order.id) {
                    //         updateLine({ type: 'update', payload: data.order })
                    //     }
                    //     break
                    default:
                        break
                }
            })
        } catch (error) {
            return error
        }

        getDeliveryAreas({
            queryKey: ['getDeliveryAreas', { token: token.token }],
        }).then((data) => {
            let areaList = [...data.list]
            if (fees && fees.length) {
                let feeAreas = [...fees]
                let newAreaList = []
                let topParents = areaList.filter((area) => !area.parent)
                while (topParents.length) {
                    for (const area of topParents) {
                        const inFeeId = feeAreas.findIndex((e) => e === area.id)
                        const inAreaId = areaList.findIndex(
                            (e) => e.id === area.id
                        )

                        if (inFeeId === -1) {
                            areaList = areaList.map((e) =>
                                area.id === e.parent
                                    ? { ...e, parent: undefined }
                                    : e
                            )
                            areaList.splice(inAreaId, 1)
                        } else {
                            newAreaList = [
                                ...newAreaList,
                                ...areaList.splice(inAreaId, 1),
                            ]
                        }
                    }

                    topParents = areaList.filter((area) => !area.parent)
                }

                areaList = [...areaList, ...newAreaList]
            }

            setAvailableAreas(areaList)
            setAllAreas(data.list)
            getAreas(undefined, areaList).then((e) => setAreaOptions(e))
        })
    }, [])

    const getAreas = async (parentId, allAreas) => {
        let newAreas = []
        const filteredAreas = allAreas.filter((e) => e.parent === parentId)

        if (filteredAreas.length) {
            for (const line of filteredAreas) {
                let childs = await getAreas(line.id, allAreas)

                newAreas.push({
                    value: line.id,
                    label: line.name,
                    children: childs.length ? childs : undefined,
                })
            }
        }

        return newAreas
    }

    const sanitizedPack = useMemo(() => {
        if (packs) {
            let newPacks = [...packs]

            newPacks = newPacks.map((pack) => ({
                ...pack,
                products: pack.products.map((product) => ({
                    ...product,
                    product: product.product.id,
                })),
            }))

            return newPacks
        }

        return packs
    }, [])

    return (
        <>
            <Space
                direction="vertical"
                style={{
                    marginTop: '24px',
                    padding: '0px 24px',
                    width: '100%',
                }}
            >
                <PacksStatLive
                    packs={packs}
                    items={items}
                    freeFall={freeFall}
                    bourse={bourse}
                />
                {/* <p>Line: {selectedLine}</p>
                <p>Col: {selectedColumn}</p> */}
                <OfferLiveWX.Provider
                    value={{
                        selectedLine,
                        setSelectedLine,
                        selectedColumn,
                        setSelectedColumn,
                    }}
                >
                    <table
                        style={{ width: '100%' }}
                        className="tableWrap offer-live ant-table ant-table-bordered"
                    >
                        <thead className="ant-table-thead">
                            {offerId && (
                                <TableHeader
                                    packs={[...packs, ...items]}
                                    // ws={ws}
                                />
                            )}
                        </thead>
                        <tbody className="ant-table-tbody">
                            {offerId && canCreate && (
                                <NewOrderLine
                                    orders={[...newOrders, ...orders]}
                                    deliveryDates={deliveryDates}
                                    packs={sanitizedPack}
                                    items={items}
                                    areaOptions={areaOptions}
                                    allAreas={allAreas}
                                    availableAreas={availableAreas}
                                    feeAreas={fees}
                                    offerId={offerId}
                                    supplierId={supplierId}
                                    community={community}
                                    handleNewOrder={(order) =>
                                        setNewOrders(order)
                                    }
                                />
                            )}
                            {!isLoading &&
                                newOrders.map((order, i) => (
                                    <OrderLine
                                        key={order.id}
                                        lineOrder={order}
                                        deliveryDates={deliveryDates}
                                        packs={sanitizedPack}
                                        items={items}
                                        areaOptions={areaOptions}
                                        allAreas={allAreas}
                                        availableAreas={availableAreas}
                                        feeAreas={fees}
                                        community={community}
                                        // ws={ws}
                                        minimumCartItems={minimumCartItems}
                                        minimumOrderAmount={minimumOrderAmount}
                                    />
                                ))}
                            {!isLoading &&
                                orders.map((order, i) => (
                                    <OrderLine
                                        key={order.id}
                                        lineOrder={order}
                                        deliveryDates={deliveryDates}
                                        packs={sanitizedPack}
                                        items={items}
                                        areaOptions={areaOptions}
                                        allAreas={allAreas}
                                        availableAreas={availableAreas}
                                        feeAreas={fees}
                                        community={community}
                                        // ws={ws}
                                        minimumCartItems={minimumCartItems}
                                        minimumOrderAmount={minimumOrderAmount}
                                    />
                                ))}
                        </tbody>
                    </table>
                </OfferLiveWX.Provider>
                {isLoading && (
                    <div className="spin-loader">
                        <Spin />
                    </div>
                )}
            </Space>
            <Clipboard shortcuts={clipboard} />
        </>
    )
}

export default WxGroup
