import { useContext } from 'react'
import { Button, Typography } from 'antd'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'
import { AppContext } from '../context/AppContext'

import { getUsers } from '../network/Users'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const ZikoTeam = ({ history }) => {
    return (
        <PageWrap title="MENU_ZIKO_TEAM">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getUsers'}
                request={getUsers}
                otherFilters={{ role: { $ne: 'super-admin' } }}
                pageSize={80}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push(`/users/${record.id}`)
                        },
                    };
                }}
            />
        </PageWrap>
    )
}

const filters = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'ROLES_TEAM_MEMBER',
            key: 'name',
        },
    ],
    actions: [
        // {
        //     label: 'ROLES_CREATE_TEAM_MEMBER',
        //     isActive: true,
        //     action: () => history.push('/users/create'),
        // },
        {
            label: 'ROLES_CREATE_TEAM_MEMBER',
            component: () => <BtnCreate history={history} />,
        },
    ],
})

const BtnCreate = ({ history }) => {
    const { user } = useContext(AppContext)

    if (
        user.role === 'logistic' ||
        user.role === 'customer-hero' ||
        user.role === 'community-manager'
    ) {
        return null
    }

    return (
        <Button type="primary" onClick={() => history.push('/users/create')}>
            <DynamicStrings id={'ROLES_CREATE_TEAM_MEMBER'} />
        </Button>
    )
}

const columns = (goTo) => [
    {
        title: <DynamicStrings id="ROLES_NAME" />,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_ROLE" />,
        dataIndex: 'role',
        render: (e) => <DynamicStrings id={e} />,
        key: 'role',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'communities',
        render: (communities) => <Commu communities={communities} />,
        key: 'communities',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_CITY" />,
        dataIndex: 'city',
        key: 'city',
        render: (e) => <DynamicStrings id={e} />,
        sorter: true,
    },
    // {
    //     title: '',
    //     width: 1,
    //     render: (e) => (
    //         <Button onClick={() => goTo(`/users/${e.id}`)} type="primary">
    //             <DynamicStrings id="ROLES_EDIT" />
    //         </Button>
    //     ),
    //     key: 'action',
    // },
]

const Commu = ({ communities }) => {
    const { t } = useTranslation()
    return (
        <Text>
            {communities.map(
                (community, i) => (i === 0 ? '' : ', ') + t(community.name)
            )}
        </Text>
    )
}

export default ZikoTeam
