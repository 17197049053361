import React from 'react'
import { Card } from 'antd'
import { Pie } from '@ant-design/charts'
import { useTranslation } from 'react-i18next'
import { formatNb } from 'src/utils/utils'

const ChartPie = ({ data, title }) => {
    const { t } = useTranslation()

    let total = 0
    for (const stat of data) {
        total += stat.totalAmount
    }

    data = data.map((stat) => ({
        ...stat,
        _id: t(stat._id),
        legend: `${t(stat._id)}  |  ${formatNb(
            (stat.totalAmount * 100) / total
        )}%  ¥${formatNb(stat.totalAmount)} `,
    }))
    var config = {
        appendPadding: 24,
        data: data,
        angleField: 'totalAmount',
        colorField: '_id',
        radius: 0.8,
        label: null,
        legend: {
            offsetX: -75,
            offsetY: -35,
            itemName: {
                formatter: (text, item, index) => data[index].legend,
            },
        },
        tooltip: false,
        interactions: [
            // { type: 'pie-legend-active' },
            // { type: 'element-active' },
        ],
    }
    return (
        <Card style={{ width: 638 }} className="chart-card" title={title}>
            <Pie className="analitycsPie" style={{ height: 250 }} {...config} />
        </Card>
    )
}

export default ChartPie
