import { useContext, useState } from 'react'
import { Space, Table, Button, AutoComplete, Select, Tabs } from 'antd'
import { useQuery, useQueryClient } from 'react-query'
import { formatDate, downloadCSV } from '../../utils/utils'

import { getOrders, lotteryAsign } from '../../network/API'
import i18next from 'i18next'

import { getOfferLottery } from '../../network/Offers'
import { AppContext } from '../../context/AppContext'

import DynamicStrings from '../../components/DynamicStrings'
import { useTranslation } from 'react-i18next'

const { Option } = AutoComplete
const { TabPane } = Tabs

const Lottery = ({ offerId, offerName, offer }) => {
    const { token } = useContext(AppContext)
    const [parsedDraws, setParsedDraws] = useState([])
    const [channelFilter, setChannelFilter] = useState('miniprogram')
    const [sortBy, setSortBy] = useState(['createdAt', 'ascend'])

    const { t } = useTranslation()

    const queryKey = [
        'getOfferLottery',
        {
            offerId,
            channel: channelFilter,
            token: token.token,
        },
    ]

    const draws = useQuery(queryKey, getOfferLottery, {
        onSuccess: (data) => {
            const parsed = []
            for (const lottery of data) {
                for (const winner of lottery.winners) {
                    parsed.push({
                        createdAt: lottery.createdAt,
                        nbWinner: lottery.winners.length,
                        drawId: lottery.offerDrawId,
                        id: lottery.id,
                        ...winner,
                    })
                }
            }

            setParsedDraws(parsed)
        },
    })

    const sortDraws = (a, b) => {
        switch (sortBy[0]) {
            case 'name': {
                const nameA = a.order?.customer
                    ? a.order?.customer?.name
                    : a.order?.customerDaily?.customerName

                const nameB = b.order?.customer
                    ? b.order?.customer?.name
                    : b.order?.customerDaily?.customerName

                const res = nameA?.localeCompare(nameB)
                if (res === 0) return res
                return sortBy[1] === 'ascend' && res === 1 ? 1 : -1
            }

            case 'winNumber':
                if (a.nbWinner > b.nbWinner) {
                    return sortBy[1] === 'ascend' ? -1 : 1
                } else if (a.nbWinner < b.nbWinner) {
                    return sortBy[1] === 'ascend' ? 1 : -1
                }
                return 0

            case 'channel': {
                const res = a.order?.channel?.localeCompare(b.order?.channel)
                if (res === 0) return res
                return sortBy[1] === 'ascend' && res === 1 ? 1 : -1
            }

            case 'prize': {
                const res = a.gift.type?.localeCompare(b.gift.type)
                if (res === 0) return res
                return sortBy[1] === 'ascend' && res === 1 ? 1 : -1
            }

            default:
                if (new Date(a.createdAt) > new Date(b.createdAt)) {
                    return sortBy[1] === 'ascend' ? 1 : -1
                } else if (new Date(a.createdAt) < new Date(b.createdAt)) {
                    return sortBy[1] === 'ascend' ? -1 : 1
                }
                return 0
        }
    }

    const handleExport = () => {
        const csv = []

        for (const draw of parsedDraws) {
            let drawNb = ''
            let mp = offer?.miniprogram?.lottery?.draws?.findIndex(
                (dr) => draw?.drawId === dr?._id
            )

            if ((mp && mp !== -1) || mp === 0) drawNb = mp + 1

            let wg = offer?.wechatGroup?.lottery?.draws?.findIndex(
                (dr) => draw?.drawId === dr?._id
            )

            if ((wg && wg !== -1) || wg === 0) drawNb = wg + 1

            csv.push([
                drawNb,
                getCleanGift(draw.gift),
                draw?.order?.customer?.name ||
                    draw?.order?.customerDaily?.customerName,
                draw.order?.orderID,
            ])
        }

        downloadCSV(
            ['Draw Number', 'Prize description', 'Winner', 'Order ID'],
            [...csv],
            `${offerName} - Lottery ${
                channelFilter === 'miniprogram' ? 'miniprogram' : 'group'
            }`
        )
    }

    return (
        <Space
            direction="vertical"
            size={15}
            style={{ width: '100%', paddingBottom: 15 }}
        >
            <div className="customer-voucher-button-wrap">
                <Button onClick={handleExport} type="primary">
                    <DynamicStrings id="ANALYTICS_EXPORT_CSV" />
                </Button>
            </div>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
                <div className="lottery-tab">
                    <Tabs
                        defaultActiveKey="miniprogram"
                        onChange={setChannelFilter}
                    >
                        <TabPane tab={t('miniprogram')} key="miniprogram" />
                        <TabPane tab={t('wechat_group')} key="wechat_group" />
                    </Tabs>
                </div>
                <Table
                    dataSource={parsedDraws.sort(sortDraws)}
                    columns={column(offerId, queryKey, channelFilter, offer)}
                    loading={draws.isLoading}
                    className="tableWrap"
                    style={{
                        padding: '0px 24px',
                    }}
                    pagination={false}
                    onChange={(pagination, filters, sorter) =>
                        setSortBy([sorter.columnKey, sorter.order])
                    }
                />
            </Space>
        </Space>
    )
}

const getCleanGift = (gift) => {
    switch (gift.type) {
        case 'custom':
            return gift.custom[i18next.language]
        case 'free_delivery':
            return 'Free delivery'
        case 'pack':
            return `Pack ${gift.pack}`
        case 'add_on':
            return `Add on ${gift.singleItem}`
        case 'discount':
            return `Discount (${gift.discountAmount} %)`
        case 'voucher':
            return `Voucher (${gift.voucherValue} ¥)`
        default:
            break
    }
}

const column = (offerId, queryKey, channelFilter, offer) => [
    {
        title: <DynamicStrings id="LOTTERY_MANAGEMENT_DRAW_NUMBER" />,
        // dataIndex: 'winnerNumber',
        render: (e) => {
            let mp = offer?.miniprogram?.lottery?.draws?.findIndex(
                (dr) => e?.drawId === dr?._id
            )

            if ((mp && mp !== -1) || mp === 0) return mp + 1

            let wg = offer?.wechatGroup?.lottery?.draws?.findIndex(
                (dr) => e?.drawId === dr?._id
            )

            if ((wg && wg !== -1) || wg === 0) return wg + 1

            return ''
        },
        key: 'drawNumber',
    },
    {
        title: <DynamicStrings id="LOTTERY_PRIZE_DESC" />,
        dataIndex: 'gift',
        render: (gift) => getCleanGift(gift),
        key: 'prize',
        sorter: true,
    },
    {
        title: <DynamicStrings id="LOTTERY_WINNER" />,
        // dataIndex: 'order',
        render: (draw) => (
            <SetOrderId
                draw={draw}
                offerId={offerId}
                offer={offer}
                queryKey={queryKey}
                channelFilter={channelFilter}
            />
        ),
    },

    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        // dataIndex: 'order',
        render: (draw) => draw?.order?.orderID,
    },
]

export default Lottery

const SetOrderId = ({ draw, offerId, queryKey, channelFilter, offer }) => {
    const { token } = useContext(AppContext)
    // const [text, setText] = useState('')
    const queryClient = useQueryClient()

    const orders = useQuery(
        [
            `getOrders`,
            {
                filter: {
                    offer: offerId,
                    channel: channelFilter,
                    paymentStatus: { $nin: ['ccl', 'rfd'] },
                    ticketAmount: { $ne: 0 },
                },
                token: token.token,
                sort: ['orderID', 'ASC'],
            },
        ],
        getOrders
    )

    return (
        <Select
            showSearch
            value={draw?.order?.id}
            style={{ width: 350 }}
            optionFilterProp="children"
            filterOption={(input, option) => {
                const name = option.children.join('').toString().toLowerCase()

                return name.indexOf(input.toString().toLowerCase()) >= 0
            }}
            filterSort={(optionA, optionB) => {
                const nameA = optionA.children[0].toString().toLowerCase()
                const nameB = optionB.children[0].toString().toLowerCase()

                return nameA.localeCompare(nameB)
            }}
            onSelect={(e) => {
                const selectedOrder = orders.data.list.find(
                    (elem) => elem.id === e
                )

                let drawNb = 'lottery'
                let mp = offer?.miniprogram?.lottery?.draws?.findIndex(
                    (dr) => draw?.drawId === dr?._id
                )

                if ((mp && mp !== -1) || mp === 0) drawNb = mp + 1

                let wg = offer?.wechatGroup?.lottery?.draws?.findIndex(
                    (dr) => draw?.drawId === dr?._id
                )

                if ((wg && wg !== -1) || wg === 0) drawNb = wg + 1

                lotteryAsign(draw.id, draw.winnerNumber, e, drawNb, token.token)

                queryClient.setQueryData(queryKey, (oldData) => {
                    const drawsIndex = oldData.findIndex(
                        (elem) => elem.id === draw.id
                    )

                    const winnerIndex = oldData[drawsIndex].winners.findIndex(
                        (elem) => elem.winnerNumber === draw.winnerNumber
                    )

                    oldData[drawsIndex].winners[winnerIndex].order =
                        selectedOrder
                    return oldData
                })
            }}
        >
            {orders?.data?.list
                ?.sort((a, b) => {
                    const nameA = (
                        a?.customer?.name || a?.customerDaily?.customerName
                    )
                        .toString()
                        .toLowerCase()
                    const nameB = (
                        b?.customer?.name || b?.customerDaily?.customerName
                    )
                        .toString()
                        .toLowerCase()

                    return nameA.localeCompare(nameB)
                })
                .map((order) => (
                    <Option value={order.id}>
                        {order?.customer?.name ||
                            order?.customerDaily?.customerName}{' '}
                        {`(${order?.orderID})`}
                    </Option>
                ))}
        </Select>
    )

    // const setOrderToDraws = async (id, winner, order) => {
    //     lotteryAsign(id, winner, order, token.token)
    //     return
    // }

    // return (
    //     <AutoComplete
    //         onChange={(e) => setText(e)}
    //         size="small"
    //         style={{ width: '100%' }}
    //         onSelect={(e) => {
    //             const value = JSON.parse(e)
    //             setText(value.orderID)
    //             setOrderToDraws(draw.id, draw.winnerNumber, value.id)
    //             queryClient.setQueryData(queryKey, (oldData) => {
    //                 const drawsIndex = oldData.findIndex(
    //                     (e) => e.id === draw.id
    //                 )
    //                 const winnerIndex = oldData[drawsIndex].winners.findIndex(
    //                     (e) => e.winnerNumber === draw.winnerNumber
    //                 )

    //                 oldData[drawsIndex].winners[winnerIndex].order = value
    //                 return oldData
    //             })
    //         }}
    //     >
    //         {orders?.data?.list?.map((res) => (
    //             <Option key={res.id} value={JSON.stringify(res)}>
    //                 {res.orderID}
    //             </Option>
    //         ))}
    //     </AutoComplete>
    // )
}
