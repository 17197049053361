import { Tabs, Form } from 'antd'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'

import { CreateCustomer } from '../../network/Customers'
import {
    PersonalInformation,
    Contacts,
    Fapiao,
    WeChatGroups,
    Address,
    Pets,
    defaultCommunities,
    cleanBeforeSending,
} from '../../constants/forms/Customers'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

const CustomersDetail = () => {
    const [form] = Form.useForm()
    const { t } = useTranslation()

    let wechatGroups = defaultCommunities.map((community) => ({
        community,
        groupNumber: undefined,
    }))

    return (
        <PageWrap goBack title="CUSTOMERS_CREATE_CUSTOMER">
            <Tabs
                defaultActiveKey="1"
                className="customerDetailTab"
                style={{ backgroundColor: '#fff', padding: '0px' }}
            >
                <TabPane tab={t('CUSTOMERS_INFORMATION')} key="1">
                    <FormWrap
                        form={form}
                        title="create_recipe"
                        request={CreateCustomer}
                        onSuccess={'/customers'}
                        onCancel={'/customers'}
                        data={{ wechatGroups }}
                        cleanBeforeSending={cleanBeforeSending}
                        invalidate={['getCustomers']}
                    >
                        <div className="SectionHorizontalWrap">
                            <div
                                className="SectionVerticalWrap"
                                style={{ flex: 2 }}
                            >
                                <FormBlock
                                    form={form}
                                    formTemplate={PersonalInformation}
                                />
                                <FormBlock form={form} formTemplate={Address} />
                                <FormBlock
                                    form={form}
                                    formTemplate={Contacts}
                                />
                                <FormBlock form={form} formTemplate={Fapiao} />
                            </div>

                            <div
                                className="SectionVerticalWrap"
                                style={{ flex: 1 }}
                            >
                                <FormBlock
                                    form={form}
                                    formTemplate={WeChatGroups}
                                />
                                <FormBlock form={form} formTemplate={Pets} />
                            </div>
                        </div>
                    </FormWrap>
                </TabPane>
                <TabPane tab={t('MENU_ORDERS')} key="2" disabled />
                <TabPane tab={t('CUSTOMERS_VOUCHERS')} key="3" disabled />
                <TabPane tab={t('MENU_CLAIMS')} key="4" disabled />
                <TabPane tab={t('MENU_ANALYTICS')} key="5" disabled />
            </Tabs>
        </PageWrap>
    )
}

export default CustomersDetail
