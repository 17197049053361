const TemplateDescription = {
    title: 'ROLES_DESCRIPTION',
    key: [],
    sections: [
        {
            columns: 6,
            fields: [
                {
                    label: 'DELIVERY_TEMPLATES_DELIVERY_TEMPLATE_NAME',
                    isRequired: true,
                    key: ['name'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const TemplateArea = {
    title: 'OFFER_LIVE_AREA',
    key: [],
    sections: [
        {
            canAdd: true,
            key: ['areas'],
            addSuffix: 'number',
            columns: 6,
            noDivider: true,
            title: 'OFFER_LIVE_AREA',
            deleteBtn: { label: 'DELIVERY_TEMPLATES_DELETE_AREA', style: 'title' },
            addBtn: { label: 'CREATE_OFFER_ADD_AN_AREA', style: 'primary' },
            fields: [
                {
                    label: 'CREATE_OFFER_AREA_NAME',
                    key: ['area'],
                    type: 'area',
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
            sections: [
                {
                    canAdd: true,
                    key: ['rules'],
                    columns: 12,
                    noDivider: true,
                    isRequired: true,
                    deleteBtn: {
                        label: 'ROLES_DELETE',
                        style: 'line',
                    },
                    addBtn: { label: 'CREATE_OFFER_ADD_RULE', style: 'dashed' },
                    fields: [
                        {
                            label: 'CREATE_OFFER_RULE',
                            type: 'select',
                            key: ['type'],
                            isRequired: true,
                            options: [
                                {
                                    key: 'flat',
                                    label: 'flat'
                                },
                                {
                                    key: 'up_to',
                                    label: 'up_to',
                                },
                                {
                                    key: 'free_after_quantity',
                                    label: 'free_after_quantity',
                                },
                                {
                                    key: 'free_after_value',
                                    label: 'free_after_value',
                                },
                            ],
                            pos: {
                                col: 1,
                                row: 1,
                                size: 2,
                            },
                        },
                        {
                            label: 'CREATE_OFFER_ITEMS_QTY',
                            isConditional: true,
                            isRequired: true,
                            parentKey: ['type'],
                            displayCondition: (parentValue) =>
                                parentValue === 'up_to' ||
                                parentValue === 'free_after_quantity'
                                    ? true
                                    : false,
                            key: ['quantity'],
                            pos: {
                                col: 3,
                                row: 1,
                                size: 1,
                            },
                        },
                        {
                            label: 'OFFER_LIVE_DELIVERY_FEE',
                            suffixLabel: '¥',
                            type: 'number',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'flat' ? true : false,
                            parentKey: ['type'],
                            key: ['fee'],
                            pos: {
                                col: 3,
                                row: 1,
                                size: 1,
                            },
                        },
                        {
                            label: 'OFFER_LIVE_DELIVERY_FEE',
                            suffixLabel: '¥',
                            type: 'number',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'up_to' ? true : false,
                            parentKey: ['type'],
                            key: ['fee'],
                            pos: {
                                col: 4,
                                row: 1,
                                size: 1,
                            },
                        },
                        {
                            label: 'CREATE_OFFER_ORDER_VALUE',
                            suffixLabel: '¥',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'free_after_value'
                                    ? true
                                    : false,
                            parentKey: ['type'],
                            key: ['quantity'],
                            pos: {
                                col: 3,
                                row: 1,
                                size: 1,
                            },
                        },
                    ],
                },
            ],
        },
    ],
}

const AreaDescription = {
    title: 'ROLES_DESCRIPTION',
    key: [],
    sections: [
        {
            columns: 6,
            fields: [
                {
                    label: 'DELIVERY_TEMPLATES_DELIVERY_AREA_NAME',
                    key: ['name'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'DELIVERY_TEMPLATES_PARENT',
                    type: 'area',
                    isRequired: true,
                    key: ['parent'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const cleanBeforeSendingTemplate = (oldData) => {
    const newArea = oldData.areas?.map((area) => ({
        ...area,
        area: area.area[area.area.length - 1],
    }))

    return { ...oldData, areas: newArea }
}

const cleanBeforeSendingArea = (oldData) => {
    oldData.parent = oldData.parent[oldData.parent.length - 1]
    return oldData
}

export {
    TemplateDescription,
    TemplateArea,
    AreaDescription,
    cleanBeforeSendingTemplate,
    cleanBeforeSendingArea,
}
