import { useContext } from 'react'
import { MoreOutlined, DownloadOutlined } from '@ant-design/icons'
import i18next from 'i18next'
import { Space, Popover, Button, Tooltip, Menu, Dropdown } from 'antd'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { AppContext } from '../../context/AppContext'

import { formatDate, formatNb, displayOrderDetails } from '../../utils/utils'
import { getOrders, getCommunities } from '../../network/API'
import { getSuppliers } from '../../network/Suppliers'

import TableBlock from '../../components/tables/TableBlock'
import { OrderStatus } from '../../components/tables/cells/Status'
import DynamicStrings from '../../components/DynamicStrings'
import MyPDFDocument from 'src/components/MyPDF'
import moment from 'moment'

const CustomersOrders = ({ userId, user }) => {
    const ctx = useContext(AppContext)

    const commuFilter =
        ctx.user.role === 'admin' || ctx.user.role === 'super-admin'
            ? {}
            : { id: { $in: user.communities } }

    return (
        <div style={{ paddingTop: 15, backgroundColor: '#fff' }}>
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getOrders'}
                request={getOrders}
                pageId={{ user, commuFilter }}
                otherParams={{ customer: userId }}
                otherFilters={{ valid: true }}
                pageSize={80}
            />
        </div>
    )
}

const filters = (history, lineSelect, loading, id) => ({
    fields: [
        {
            type: 'text',
            label: 'OFFER_LIVE_ORDER_ID',
            key: 'orderID',
        },
        {
            type: 'select',
            label: 'SUPPLIERS_SUPPLIER_NAME',
            key: 'supplier',
            options: getSuppliers,
        },
        {
            type: 'select',
            label: 'ROLES_COMMUNITY',
            key: 'community',
            options: getCommunities,
            otherParams: id.commuFilter,
        },
        {
            type: 'daterange',
            label: 'OFFERS_OFFER_DATE',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    actions: [
        {
            label: 'DIRECT_SALES_CREATE_DAILY_ORDER',
            isActive: true,
            // action: () => history.push(`/daily-orders/create/${history.userId}`),
            action: () =>
                history.push({
                    pathname: '/daily-orders/create',
                    user: id.user,
                }),
        },
    ],
})

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        dataIndex: 'orderID',
        key: 'orderID',
        // ellipsis: true,
    },
    {
        title: <DynamicStrings id="order_date" />,
        dataIndex: 'orderDate',
        render: (date) => formatDate(date),
        key: 'orderDate',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_OFFER" />,
        dataIndex: 'offer',
        render: (offer) => offer?.name[i18next.language],
        key: 'offer',
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'community',
        render: (community) => community?.name ? <DynamicStrings id={community.name} /> : '',
        key: 'community',
    },
    {
        title: <DynamicStrings id="MENU_SUPPLIERS" />,
        // dataIndex: 'supplier',
        render: (e) => e?.supplier?.name || e?.offer?.supplier?.name,
        key: 'supplier',
        sorter: true,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_ORDER_DETAILS" />,
        dataIndex: '',
        render: (e) => {
            const toDisplay = [
                ...e.packs
                    ?.filter((e) => e.amount)
                    ?.map((pack, i) => `${pack.amount} ${pack.shortName}`),
                ...e.singleItems
                    ?.filter((e) => e.amount)
                    ?.map(
                        (item) =>
                            `${item.amount} ${
                                item.shortName ||
                                item?.product?.name[i18next.language]
                            }`
                    ),
            ].join(', ')

            return <Tooltip title={displayOrderDetails(e)}>{toDisplay}</Tooltip>
        },
        key: 'packs',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CREATE_OFFER_CHANNEL" />,
        dataIndex: 'channel',
        render: (e) => <DynamicStrings id={e} />,
        key: 'channel',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_PHONE" />,
        dataIndex: 'customerAddress',
        render: (customerAddress) => customerAddress?.phone,
        key: 'customerAddress.phone',
    },
    {
        title: <DynamicStrings id="CUSTOMERS_ADDRESS" />,
        dataIndex: 'customerAddress',
        render: ({ type }) => <DynamicStrings id={type} />,
        key: 'customerAddress.type',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
        dataIndex: 'comment',
        key: 'comment',
        sorter: true,
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_DATE" />,
        dataIndex: 'deliveryDate',
        // render: (date) =>
        //     date && moment(date).isValid() ? formatDate(date) : date,
        render: (date) => {
            const dateFormat = "YYYY-MM-DDTHH:mm:SS.sssZ"

            if (date && moment(date, dateFormat, true).isValid()) {
                return formatDate(date)
            } else {
                return date
            }
        },

        key: 'deliveryDate',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_FEE" suffix="(¥)" />,
        dataIndex: 'deliveryFee',
        render: (nb) => formatNb(nb) || 0,
        key: 'deliveryFee',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_VOUCHER" suffix="(¥)" />,
        dataIndex: 'vouchers',
        render: (vouchers) =>
            vouchers?.length ? vouchers[0]?.amount || '-' : '-',
        key: 'vouchers',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_TICKET" />,
        dataIndex: 'ticketAmount',
        key: 'ticketAmount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_LOTTERY_PRIZE" />,
        dataIndex: '',
        render: (order) => {
            return order?.gifts
                .filter((e) => e.origin === 'lottery')
                ?.map((gift) => {
                    switch (gift.type) {
                        case 'custom':
                            return gift.custom[i18next.language]
                        case 'pack':
                            return `Pack ${gift.pack}`
                        case 'add_on':
                            return `Add on ${gift.singleItem}`
                        case 'discount':
                            return `Discount ${gift.discountAmount}%`
                        case 'voucher':
                            return `Voucher ${gift.voucherValue}¥`
                        default:
                            return ''
                    }
                })
                .join(', ')
        },
        // render: (data) => {
        //     let draws =
        //         data.channel === 'wechat_group'
        //             ? data?.offer?.wechatGroup?.lottery?.draws
        //             : data?.offer?.miniprogram?.lottery?.draws
        //     return draws?.map((draw, i) =>
        //         draw?.gifts?.map(
        //             (gift, j) =>
        //                 (i === 0 && j === 0 ? '' : ', ') +
        //                 (gift.type === 'custom'
        //                     ? gift?.custom[i18next.language]
        //                     : gift.type === 'voucher'
        //                     ? `${gift.type} ${gift.voucherValue}¥`
        //                     : `${gift.type} ${gift.pack}`)
        //         )
        //     )
        // },
        key: 'lottery',
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_TOTAL" suffix="(¥)" />,
        dataIndex: 'actualAmount',
        render: (stock) => formatNb(stock),
        key: 'totalAmount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        render: ({ status, trackingStatus, paymentStatus, id, actualAmount, ...order }) => (
            <OrderStatus
                status={status}
                trackingStatus={trackingStatus}
                paymentStatus={paymentStatus}
                id={id}
                keyRequest={keyRequest}
                actualAmount={actualAmount}
                order={order}
            />
        ),
        key: 'paymentStatus',
        sorter: true,
    },
    // {
    //     title: <DynamicStrings id="CUSTOMERS_SO" />,
    //     // dataIndex: 'paymentStatus',
    //     render: (order) => (
    //         <Space className="centerMe">
    //             <Popover
    //                 content={
    //                     <Space>
    //                         <PDFDownloadLink
    //                             document={
    //                                 <MyPDFDocument
    //                                     noSupplier
    //                                     lang="en"
    //                                     order={order}
    //                                     type={'direct'}
    //                                 />
    //                             }
    //                             fileName={`${order.orderID}.pdf`}
    //                         >
    //                             {({ blob, url, loading, error }) => (
    //                                 <Button
    //                                     type="primary"
    //                                     size="small"
    //                                     loading={loading}
    //                                     danger={error}
    //                                 >
    //                                     En
    //                                 </Button>
    //                             )}
    //                         </PDFDownloadLink>
    //                         {/* <PDFDownloadLink
    //                             document={
    //                                 <MyPDFDocument
    //                                     noSupplier
    //                                     lang="zh"
    //                                     order={order}
    //                                     type={'direct'}
    //                                 />
    //                             }
    //                             fileName={`${order.orderID}.pdf`}
    //                         >
    //                             {({ blob, url, loading, error }) => (
    //                                 <Button
    //                                     type="primary"
    //                                     size="small"
    //                                     loading={loading}
    //                                     danger={error}
    //                                 >
    //                                     Zh
    //                                 </Button>
    //                             )}
    //                         </PDFDownloadLink> */}
    //                     </Space>
    //                 }
    //                 title={null}
    //                 // trigger="click"
    //             >
    //                 <DownloadOutlined style={{ color: '#1890ff' }} />
    //             </Popover>
    //         </Space>
    //     ),
    //     key: 'so',
    //     width: 40,
    // },
    {
        width: 50,
        render: (e) => {
            const menu = (
                <Menu>
                    <Menu.Item key="0">
                        <PDFDownloadLink
                            document={
                                <MyPDFDocument
                                    noSupplier
                                    lang="en"
                                    order={e}
                                    type={'direct'}
                                />
                            }
                            fileName={`${e.orderID}.pdf`}
                        >
                            {({ blob, url, loading, error }) =>
                                <DynamicStrings
                                    id={
                                        loading
                                            ? 'DIRECT_SALES_LOADING_PDF'
                                            : 'DIRECT_SALES_DOWNLOAD_PDF_EN'
                                        }
                                />
                            }
                        </PDFDownloadLink>
                    </Menu.Item>

                    <Menu.Item
                        key="1"
                        disabled={
                            e.paymentStatus === 'rfd' ||
                            !!e.refundVouchers?.length
                        }
                    >
                        <a
                            onClick={() =>
                                goTo({
                                    pathname: '/vouchers/create',
                                    customer: e.customer,
                                    refundOrder: e,
                                })
                            }
                        >
                            <DynamicStrings
                                id={
                                    !!e.refundVouchers?.length
                                        ? 'SERVICES_ORDER_GOT_VOUCHER'
                                        : 'SERVICES_ORDER_LIST_CREATE_VOUCHER'
                                }
                            />
                        </a>
                    </Menu.Item>

                    <Menu.Item key="2">
                        <a
                            onClick={() =>
                                goTo({
                                    pathname: '/claims/create',
                                    order: e.id,
                                })
                            }
                        >
                            <DynamicStrings id="CUSTOMER_SERVICE_CREATE_CLAIM" />
                        </a>
                    </Menu.Item>

                    {e.type === 'daily' && (
                        <Menu.Item key="1">
                            <a
                                onClick={() =>
                                    goTo('/daily-orders/' + e.id)
                                }
                            >
                                <DynamicStrings id="ROLES_EDIT" />
                            </a>
                        </Menu.Item>
                    )}
                </Menu>
            )

            return (
                <Space className="centerMe">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                        >
                            <MoreOutlined />
                        </a>
                    </Dropdown>
                </Space>
            )
        },
    },
]

export default CustomersOrders
