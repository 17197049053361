import React from 'react'
import { Card } from 'antd'
import { Column } from '@ant-design/charts'

const ChartColumn = ({ data, title }) => {
    var config = {
        data: data,
        xField: '_id',
        yField: 'amount',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        meta: {
            type: { alias: 'category' },
            sales: { alias: 'sales' },
        },
    }
    return (
        <Card title={title} className="chart-card" style={{ width: 638 }}>
            <Column style={{ height: 250 }} {...config} />
        </Card>
    )
}

export default ChartColumn
