import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import moment from 'moment'
import { Form } from 'antd'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import { formatDate } from 'src/utils/utils'

import { AppContext } from '../../context/AppContext'

import {
    GeneralInformationEdit,
    RefundDetail,
    RefundDetailAmountDisabled,
    RefundDetailFinance,
    checkValidity,
} from '../../constants/forms/Refunds'
import { EditRefund as request, getSingleRefund } from '../../network/Refunds'
import { useTranslation } from 'react-i18next'

const EditRefunds = ({ history }) => {
    const [form] = Form.useForm()
    const { user, token } = useContext(AppContext)
    let { refundId } = useParams()
    const { t } = useTranslation()

    const refundDetails = useQuery(
        [
            'getSingleRefund',
            {
                refundId: refundId,
                token: token.token,
            },
        ],
        getSingleRefund,
        {
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (oldData) => {
        let detail = ''
        for (const pack of oldData.order?.packs) {
            detail += pack.amount
                ? `${pack.amount} Pack ${pack.shortName},`
                : ''
        }

        for (const item of oldData.order?.singleItems) {
            detail += item.amount
                ? `${item.amount} Single Item ${item.shortName},`
                : ''
        }

        return {
            order: oldData.order?.id,
            customer: oldData.customer?.id,
            community: oldData.community?.id,
            offer: oldData.offer?.id,
            supplier: oldData.supplier?.id,
            teamMember: oldData.teamMember,
            outTradeNo: oldData.outTradeNo,
            outRefundNo: oldData.outRefundNo,
            refundId: oldData.refundId,
            refundAmount: oldData.refundAmount,
            orderInfos: {
                orderID: oldData.order?.orderID,
                community: oldData.community?.name,
                customer:
                    oldData.customer?.name ||
                    oldData.order.customerDaily?.customerName,
                supplier: oldData.supplier?.name,
                offer: oldData.offer?.internalName,
                date: moment(oldData.createdAt),
                orderDate: moment(oldData.createdAt),
                actualAmount: oldData.order?.actualAmount,
                city: oldData.order?.customerAddress?.city,
                detailedAddress:
                    oldData.order?.customerAddress?.detailedAddress,
                phone: oldData.order?.customerAddress?.phone,
                deliveryTrackingID: oldData.order?.deliveryTrackingID,
                deliveryDate:
                    oldData.order?.deliveryDate &&
                        moment(oldData.order?.deliveryDate).isValid()
                        ? formatDate(oldData.order?.deliveryDate)
                        : oldData.order?.deliveryDate,
                detail: detail,
                paymentMethod: oldData.order?.paymentMethod,
            },
            resp: oldData.resp,
            issue: oldData.issue,
            actionETA: moment(oldData.actionETA),
            comment: oldData.comment,
            issueDetail: oldData.issueDetail,
            status: oldData.status,
        }
    }

    return (
        <PageWrap
            goBack
            isLoading={refundDetails.isLoading}
            title={`${t('REFUNDS_REFUND_DETAIL')} ${refundDetails.data?.order?.orderID}`}
        >
            {refundDetails.isError ? (
                <Error retry={() => refundDetails.refetch()} />
            ) : refundDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    id={refundId}
                    form={form}
                    title="edit_refund"
                    request={request}
                    data={formatData(refundDetails.data)}
                    onSuccess={'/refunds'}
                    onCancel={'/refunds'}
                    invalidate={[
                        'getRefunds',
                        'getSingleRefund',
                        'getRefundsStats',
                    ]}
                    checkValidity={checkValidity}
                >
                    <div className="SectionVerticalWrap">
                        <FormBlock
                            form={form}
                            formTemplate={GeneralInformationEdit}
                        />
                        <FormBlock
                            formTemplate={
                                user.role === 'finance' ? RefundDetailFinance :
                                    (refundDetails.data?.status !== 'new'
                                        ? RefundDetailAmountDisabled : RefundDetail)
                            }
                        />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default EditRefunds
