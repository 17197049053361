import { useContext } from 'react'
import { Button, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../context/AppContext'

import DynamicStrings from './DynamicStrings'

const DeleteButton = ({
    onSuccess,
    invalidate,
    request,
    id,
    disabled = false,
    isDeleted,
}) => {
    let history = useHistory()
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const mutation = useMutation((values) => request(...values), {
        onSuccess: (data, variables, context) => {
            message.success('Deleted successfully')
            if (invalidate) {
                for (const key of invalidate) {
                    queryClient.invalidateQueries(key)
                }
            }
            if (onSuccess) history.push(onSuccess)
        },
        onError: () => message.error('An error occurs'),
    })

    return (
        <Button
            disabled={disabled}
            loading={mutation.isLoading}
            // onClick={() => mutation.mutate([id, token.token])}
            onClick={() => {
                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    okText: t('CONFIRM'),
                    cancelText: t('CANCEL'),
                    content: t('LEAVE_PAGE'),
                    onOk() {
                        if (isDeleted) {
                            isDeleted(true)
                        }
                        mutation.mutate([id, token.token])
                    },
                });
            }}
            danger
        >
            <DynamicStrings id="ROLES_DELETE" />
        </Button>
    )
}

export default DeleteButton
