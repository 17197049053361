import { useContext, useState } from 'react'
import {
    Button,
    Modal,
    Badge,
    Form,
    Input,
    InputNumber,
    message,
    Select,
} from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import moment from 'moment'

import { formatDate } from '../../utils/utils'
import { getStocks, getStockMovements, moveStock } from '../../network/API'
import { EditPurchaseOrderDetail } from '../../network/PurchaseOrders'

import PageWrap from '../../components/layout/PageWrap'
import TableBlock from '../../components/tables/TableBlock'
import DynamicStrings from '../../components/DynamicStrings'
import { AppContext } from '../../context/AppContext'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const InventoryDetails = ({ goTo, history }) => {
    const productId = useParams().id

    return (
        <PageWrap
            goBack
            title="INVENTORY_STOCK_MOVEMENTS"
        >
            <TableBlock
                elemId={productId}
                columns={columns}
                filters={filters}
                name={'getStockMovements'}
                request={getStockMovements}
                otherParams={{ productId: productId }}
                pageSize={80}
            />
        </PageWrap>
    )
}

const ModalBtn = ({
    title,
    type,
    fieldLabel,
    request,
    reqKeys,
    okText,
    elemId,
}) => {
    const [visible, setVisible] = useState(false)
    const [fieldValue, setFieldValue] = useState(0)
    const [mode, setMode] = useState('paid')
    const [comment, setComment] = useState('')
    const productId = useParams().id
    const queryClient = useQueryClient()
    const { token, user } = useContext(AppContext)
    const { t } = useTranslation()

    const mutation = useMutation(
        () =>
            request(
                productId,
                fieldValue,
                type === 'remove' ? 'manual_remove' : 'manual_add',
                type === 'remove' ? 'out' : 'in',
                mode,
                comment,
                token.token
            ),
        {
            onSuccess: (data, variables, context) => {
                message.success(
                    `${type === 'remove' ? 'Removed' : 'Added'} successfully`
                )
                queryClient.setQueryData(reqKeys, (oldData) => {
                    if (oldData.list.length === 10) oldData.list.pop()
                    const newList = {
                        range: oldData.range,
                        list: [data, ...oldData.list],
                    }

                    return newList
                })

                queryClient.setQueryData(
                    [
                        'getStocks',
                        {
                            productId: productId,
                            token: token.token,
                        },
                    ],
                    (oldData) => {
                        return {
                            ...oldData,
                            list: [
                                {
                                    freeStock:
                                        mode === 'paid'
                                            ? oldData.list[0].freeStock
                                            : type === 'remove'
                                            ? oldData.list[0].freeStock -
                                              data.movementStock
                                            : oldData.list[0].freeStock +
                                              data.movementStock,
                                    stock:
                                        mode === 'free'
                                            ? oldData.list[0].stock
                                            : type === 'remove'
                                            ? oldData.list[0].stock -
                                              data.movementStock
                                            : oldData.list[0].stock +
                                              data.movementStock,
                                },
                            ],
                        }
                    }
                )
                // stock.refetch()
                // queryClient.invalidateQueries('getStocks')
                setFieldValue(0)
                setMode('paid')
                setComment('')
                setVisible(false)
            },
            onError: () => {
                message.error('An error occurs')
                setFieldValue(0)
                setVisible(false)
            },
        }
    )

    const stock = useQuery(
        [
            'getStocks',
            {
                productId: productId,
                token: token.token,
            },
        ],
        getStocks,
        {
            refetchOnWindowFocus: false,
        }
    )

    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const elemStock =
        !stock.isLoading && stock.data?.list?.length
            ? stock.data?.list[0].stock
            : null

    const elemFreeStock =
        !stock.isLoading && stock.data?.list?.length
            ? stock.data?.list[0].freeStock
            : null

    if (
        user.role !== 'super-admin' &&
        user.role !== 'admin' &&
        user.role !== 'product-manager' &&
        user.role !== 'logistic' &&
        user.role !== 'finance'
    ) {
        return null
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                <DynamicStrings id={title} />
            </Button>
            <Modal
                title={<DynamicStrings id={title} />}
                visible={visible}
                onOk={mutation.mutate}
                okText={<DynamicStrings id={okText} />}
                confirmLoading={mutation.isLoading}
                onCancel={handleCancel}
                okButtonProps={{ disabled: !fieldValue }}
            >
                <Form layout={'vertical'} className="form-wrapper-card">
                    <div className="form-field-wrapper nb-columns-2">
                        <Form.Item
                            loading={stock.isLoading}
                            label={t('INVENTORY_PAID_STOCK')}
                            className="col-1 size-1"
                        >
                            <Input value={elemStock} disabled />
                        </Form.Item>
                        <Form.Item
                            loading={stock.isLoading}
                            label={t('INVENTORY_FREE_STOCK')}
                            className="col-2 size-1"
                        >
                            <Input value={elemFreeStock} disabled />
                        </Form.Item>
                        <Form.Item
                            label={t('INVENTORY_SELECT_STOCK_FROM')}
                            className="col-1 row-2 size-1"
                        >
                            <Select
                                value={mode}
                                onChange={(value) => setMode(value)}
                                style={{ width: '100%' }}
                            >
                                <Select.Option value="paid">{t('Paid')}</Select.Option>
                                <Select.Option value="free">{t('Free')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={fieldLabel}
                            className="col-2 row-2 size-1"
                        >
                            <InputNumber
                                value={fieldValue}
                                onChange={(e) => {
                                    setFieldValue(e)
                                }}
                                min={0}
                                max={
                                    type === 'remove'
                                        ? (mode === 'free'
                                              ? elemFreeStock
                                              : elemStock) || 0
                                        : null
                                }
                            />
                        </Form.Item>
                    </div>
                    <Form.Item label={t('INVENTORY_COMMENT')} className="row-3 size-2">
                        <Input.TextArea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder={t('INVENTORY_PLEASE_ADD_A_COMMENT')}
                            autoSize={{ minRows: 6, maxRows: 6 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const ModalStockBtn = ({
    buttonLabel,
    buttonDisabled,
    keyRequest,
    poId,
    request,
    valueKey,
    modifValue,
    title,
    content,
}) => {
    const [visible, setVisible] = useState(false)
    const queryClient = useQueryClient()
    const { token } = useContext(AppContext)

    const mutation = useMutation(
        () => request({ [valueKey]: modifValue }, token.token, poId),
        {
            onSuccess: (data, variables, context) => {
                queryClient.setQueryData(keyRequest, (oldData) => {
                    const matchPO = oldData.list.filter(
                        (e) =>
                            e.type === 'purchase_order' &&
                            e.purchaseOrder?.id === data.id
                    )

                    for (const po of matchPO) {
                        let poIndex = oldData.list.findIndex(
                            (e) => e.id === po.id
                        )

                        oldData.list[poIndex].purchaseOrder[valueKey] =
                            data[valueKey]

                        oldData.list[poIndex].status = 'in'
                    }

                    return oldData
                })
                setVisible(false)
            },
            onError: () => {
                message.error('An error occurs')
                setVisible(false)
            },
        }
    )

    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    return (
        <>
            <Button
                type="primary"
                disabled={buttonDisabled}
                onClick={showModal}
            >
                <DynamicStrings id={buttonLabel} />
            </Button>
            <Modal
                title={title}
                centered
                visible={visible}
                onOk={mutation.mutate}
                onCancel={handleCancel}
                okText={<DynamicStrings id="OFFER_LIVE_YES" />}
                cancelText={<DynamicStrings id="OFFER_LIVE_NO" />}
            >
                {content}
            </Modal>
        </>
    )
}

const filters = (goTo, selectedKeys, isLoading) => ({
    fields: [
        {
            type: 'text',
            label: 'INVENTORY_CUSTOMER_ORDER_ID',
            key: 'customerOrder',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'INVENTORY_PO_NUMBER',
            key: 'purchaseOrder',
            noRegex: true,
        },
        {
            type: 'select',
            label: 'INVENTORY_MOVEMENT_TYPE',
            key: 'type',
            options: [
                { key: 'manual_add', label: 'manual_add' },
                { key: 'manual_remove', label: 'manual_remove' },
                { key: 'customer_order', label: 'customer_order' },
                { key: 'purchase_order', label: 'purchase_order' },
            ],
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    actions: [
        {
            label: 'INVENTORY_REMOVE_STOCK',
            component: (elemId, reqKeys) => (
                <ModalBtn
                    elemId={elemId}
                    reqKeys={reqKeys}
                    type="remove"
                    title="INVENTORY_REMOVE_STOCK"
                    fieldLabel={
                        <DynamicStrings id={'INVENTORY_QUANTITY_TO_REMOVE'} />
                    }
                    okText="INVENTORY_REMOVE_STOCK"
                    request={moveStock}
                />
            ),
        },
        {
            label: 'INVENTORY_ADD_STOCK',
            component: (elemId, reqKeys) => (
                <ModalBtn
                    elemId={elemId}
                    reqKeys={reqKeys}
                    type="add"
                    title="INVENTORY_ADD_STOCK"
                    fieldLabel={
                        <DynamicStrings id="INVENTORY_QUANTITY_TO_ADD" />
                    }
                    okText="INVENTORY_ADD_STOCK"
                    request={moveStock}
                />
            ),
        },
        {
            label: 'ANALYTICS_EXPORT_CSV',
            isActive: !isLoading,
            csv: {
                header: [
                    'Movement type',
                    'Customer ID',
                    'PO number',
                    'Date',
                    'Stock movements',
                    'Status',
                ],
                rowFilter: (order, i) => {
                    let rows = []
                    rows.push(order.type.replace('_', ' '))
                    rows.push(
                        order.type === 'customer_order'
                            ? order.customerOrder.orderID
                            : '-'
                    )
                    rows.push(
                        order.type === 'purchase_order'
                            ? order.purchaseOrder.poId
                            : '-'
                    )
                    rows.push(formatDate(order.date))
                    rows.push(order.movementStock)
                    rows.push(order.status.replace('_', ' '))
                    return rows
                },
                name: 'inventory-detail-' + moment().format('YYYY-MM-DD'),
            },
        },
    ],
})

const columns = (goTo, keyRequest, dataList) => [
    {
        title: <DynamicStrings id="INVENTORY_MOVEMENT_TYPE" />,
        key: 'type',
        render: (e) => <DynamicStrings id={e.type} />,
        sorter: true,
    },
    {
        title: <DynamicStrings id="INVENTORY_CUSTOMER_ORDER_ID" />,
        key: 'customerOrder',
        render: (e) => {
            if (e.type === 'customer_order') {
                return e.customerOrder.orderID
            } else {
                return '-'
            }
        },
    },
    {
        title: <DynamicStrings id="INVENTORY_PO_NUMBER" />,
        key: 'purchaseOrder',
        render: (e) => {
            if (e.type === 'purchase_order') {
                return e.purchaseOrder.poId
            } else {
                return '-'
            }
        },
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_DATE" />,
        dataIndex: 'date',
        render: (date) => formatDate(date),
        key: 'date',
        sorter: true,
    },
    {
        width: 450,
        title: <DynamicStrings id="INVENTORY_COMMENT" />,
        dataIndex: 'comment',
        render: (comment) => <DynamicStrings id={comment} />,
        key: 'comment',
        sorter: false,
    },
    {
        title: <DynamicStrings id="INVENTORY_STOCK_MOVEMENTS" />,
        // dataIndex: 'movementStock',
        render: (e) =>
            `${e.status === 'out' || e.status === 'on_delivery' ? '-' : '+'}${
                e.movementStock
            }`,
        key: 'movementStock',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,

        dataIndex: 'status',
        render: (e) => {
            let status = {
                in: 'green',
                out: 'red',
                purchased: 'blue',
                on_delivery: 'orange',
            }

            return <Badge color={status[e]} text={<DynamicStrings id={e} />} />
        },

        key: 'status',
        sorter: true,
    },
    {
        key: 'action',
        width: 1,
        render: (e, record, index) => {
            if (e.type !== 'purchase_order') {
                return null
            }

            const idx = dataList.findIndex(
                (or) => or?.purchaseOrder?.id === e.purchaseOrder.id
            )

            if (idx !== index) {
                return null
            }

            return (
                <ModalStockBtn
                    poId={e.purchaseOrder.id}
                    keyRequest={keyRequest}
                    buttonDisabled={
                        e.purchaseOrder.stock_status === 'received'
                            ? true
                            : false
                    }
                    buttonLabel={
                        e.purchaseOrder.stock_status === 'received'
                            ? 'DIRECT_SALES_STOCK_UPDATED'
                            : 'DIRECT_SALES_STOCK_RECEIVED'
                    }
                    title={<DynamicStrings id="DIRECT_SALES_STOCK_RECEIVED" />}
                    content={
                        <DynamicStrings
                            id={'DIRECT_SALES_SURE_TO_VALIDATE_RECEPTION_STOCK'}
                        />
                    }
                    request={EditPurchaseOrderDetail}
                    modifValue="received"
                    valueKey="stock_status"
                />
            )
        },
    },

    // {
    //     title: 'Product ID',
    //     dataIndex: 'product',
    //     key: 'productID',
    //     render: (product) => product?.productId,
    //     sorter: true,
    // },
    // {
    //     title: 'Name (English)',
    //     dataIndex: 'product',
    //     key: 'name_en',
    //     render: (product) => product?.name?.en,
    //     sorter: true,
    // },
    // {
    //     title: 'Product Name (中文)',
    //     dataIndex: 'product',
    //     key: 'name_zh',
    //     render: (product) => product?.name?.zh,
    // },
    // {
    //     title: 'Product type',
    //     dataIndex: 'product',
    //     key: 'productType',
    //     render: (product) => product?.productType?.name,
    //     sorter: true,
    // },
    // {
    //     title: 'Actual Stock',
    //     dataIndex: 'stock',
    //     key: 'stock',
    //     render: (stock) => formatNb(stock),
    //     sorter: true,
    // },
    // {
    //     title: 'Purchased (not received)',
    //     dataIndex: 'purchasedStock',
    //     key: 'purchasedStock',
    //     render: (stock) => formatNb(stock),
    //     sorter: true,
    // },
    // {
    //     title: 'On Delivery ',
    //     dataIndex: 'onDeliveryStock',
    //     key: 'onDeliveryStock',
    //     render: (stock) => formatNb(stock),
    //     sorter: true,
    // },
    // {
    //     title: 'Forecast Stock',
    //     dataIndex: '',
    //     render: (stock) =>
    //         formatNb(
    //             stock?.stock + stock?.purchasedStock - stock?.onDeliveryStock
    //         ),
    //     // [Current stock]+[Arriving stock]-[Leaving stock]
    //     key: 'productType',
    //     sorter: true,
    // },
    // {
    //     title: 'Forecast Stock Value(¥)',
    //     dataIndex: '',
    //     key: 'productType',
    //     render: (stock) => formatNb(stock.stock * stock?.product?.price),
    //     sorter: true,
    // },
]

export default InventoryDetails
