import { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { AppContext } from '../../context/AppContext'

const PrivateRoute = ({
    component,
    roles,
    slug,
    children: Childs,
    ...rest
}) => {
    const { user } = useContext(AppContext)
    let history = useHistory()

    return roles?.indexOf(user.role) === -1 ? (
        <Route path={slug}>
            <Redirect to={{ pathname: '/' }} />
        </Route>
    ) : (
        <Route {...rest} path={slug}>
            <Childs history={history} />
        </Route>
    )
}

export default PrivateRoute
