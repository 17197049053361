const en = {
    MENU_ANALYTICS: 'Analytics',
    MENU_OFFERS: 'Offers',
    MENU_OFFER_LIST: 'Offer list',
    MENU_CREATE_AN_OFFER: 'Create an offer',
    MENU_DELIVERY_TEMPLATES: 'Delivery templates',
    MENU_ORDERS: 'Orders',
    MENU_SERVICES_ORDER_LIST: 'Services order list',
    MENU_DIRECT_SALES_ORDER_LIST: 'Direct sales order list',
    MENU_CUSTOMERS: 'Customers',
    MENU_SUPPLIERS: 'Suppliers',
    MENU_PRODUCTS: 'Products',
    MENU_INVENTORY: 'Inventory',
    MENU_PURCHASE_ORDERS: 'Purchase orders',
    MENU_CUSTOMER_SERVICE: 'Customer service',
    MENU_CLAIMS: 'Claims',
    MENU_VOUCHER_MANAGEMENT: 'Voucher',
    MENU_RECIPES: 'Recipes',
    MENU_LOTTERY_MANAGEMENT: 'Lottery management',
    MENU_FINANCE: 'Finance',
    MENU_OVERVIEW: 'Overview',
    MENU_DIRECT_DIRECT_SALES: 'Direct sales',
    MENU_SERVICES: 'Services',
    MENU_ZIKO_TEAM: 'Ziko Team',

    admin: 'Admin',
    'product-manager': 'Product manager',
    'community-manager': 'Community manager',
    'customer-hero': 'Customer hero',
    designer: 'Designer',
    logistic: 'Logistic ',
    finance: 'Finance',
    ROLES_TEAM_MEMBER: 'Team member',
    ROLES_NAME: 'Name',
    ROLES_ROLES: 'Roles',
    ROLES_COMMUNITY: 'Community',
    All: 'All',
    Shanghai: 'Shanghai',
    Guangzhou: 'Guangzhou',
    Kitchen: 'Ziko Kitchen',
    Garden: 'Ziko Garden',
    Farm: 'Ziko Farm',
    Cellar: 'Ziko Cellar',
    Pet: 'Ziko Pet corner',
    ROLES_CITY: 'City',
    ROLES_CREATE_TEAM_MEMBER: 'Create team member',
    ROLES_ROLE: 'Role',
    ROLES_EDIT: 'Edit',
    ROLES_DESCRIPTION: 'Description',
    ROLES_EMAIL: 'Email',
    ROLES_PASSWORD: 'Password',
    ROLES_SAVE: 'Save',
    ROLES_CANCEL: 'Cancel',
    ROLES_DELETE: 'Delete',

    ANALYTICS_GENERAL_ANALYTICS: 'General analytics',
    ANALYTICS_DATES: 'Dates',
    ANALYTICS_START_DATE: 'Start date',
    ANALYTICS_END_DATE: 'End date',
    ANALYTICS_SALES: 'Sales',
    ANALYTICS_SALES_CHANNEL_RATIO: 'Sales Channel ratio',
    miniprogram: 'Miniprogram',
    wechat_group: 'Group',
    daily: 'Daily',
    ANALYTICS_ZIKOLAND_SERVICE_FEE_COMMUNITY:
        'Zikoland service fee by community',
    ANALYTICS_TOTAL_ZIKOLAND_SERVICE_FEE: 'Total Zikoland service fee',
    ANALYTICS_GRAND_TOTAL_SALES: 'Grand total sales',
    ANALYTICS_TOTAL_SUPPLIER_SHARE: 'Total supplier share',
    ANALYTICS_EXPORT_CSV: 'Export CSV',
    ANALYTICS_TOTAL_OFFERS: 'Total Offers',
    ANALYTICS_TOTAL_ORDERS: 'Total orders',
    ANALYTICS_CUSTOMER_SATISFACTION_RATE: 'Customer satisfaction rate',
    ANALYTICS_TOTAL_CLAIMS: 'Total claims',
    ANALYTICS_TOTAL_VOUCHERS: 'Total vouchers',
    ANALYTICS_TOTAL_REFUND: 'Total refund',
    ANALYTICS_STOCK: 'Stock',
    ANALYTICS_TOTAL_STOCK: 'Total stock',
    ANALYTICS_STOCK_VALUE: 'Stock value',
    ANALYTICS_GROSS_MARGIN: 'Gross Margin',
    ANALYTICS_LAST_TIME_RMB: 'Last Time ¥',
    ANALYTICS_LAST_TIME: 'Last Time ',

    OFFERS_OFFER_ID: 'Offer ID',
    OFFERS_OFFER_DATE: 'Offer date',
    OFFERS_LIVE: 'Live',
    OFFERS_ALL: 'All',
    OFFERS_COMING: 'Coming',
    OFFERS_OVER: 'Over',
    OFFERS_OFFER_NAME: 'Offer name',
    OFFERS_OFFER_NAME_INTERNAL: 'Offer name (internal)',
    OFFERS_NB_OF_ORDERS: 'Nb of orders',
    OFFERS_SALES_TARGET: 'Sales target',
    OFFERS_PENDING_ORDERS: 'Pending orders',
    OFFERS_TOTAL_PAID_WECHAT: 'Total paid Wechat',
    OFFERS_TOTAL_PAID_ALIPAY: 'Total paid Alipay',
    OFFERS_TARGET_INCOME_RATE: 'Target income rate',
    OFFERS_ACTUAL_INCOME: 'Actual income',
    OFFERS_TIME_LEFT: 'Time left',
    OFFERS_STATUS: 'Status',
    OFFERS_EDIT_OFFER: 'Edit offer',
    OFFERS_DELIVERY: 'Delivery',
    OFFERS_UNAVAILABLE_PRODUCT:
        'Your order contains discontinued products, please choose available products.',
    OFFERS_GOT_IT: 'Got it',

    OFFER_LIVE: 'Offer live',
    OFFER_LIVE_TARGET_RATE: 'Target rate',
    OFFER_LIVE_TOTAL_SALES_DELIVERY: 'Total sales (w/o delivery)',
    OFFER_LIVE_WECHAT_TOTAL: 'Total paid Wechat',
    OFFER_LIVE_ALIPAY_TOTAL: 'Total paid Alipay',
    OFFER_LIVE_AVG_SPENDING: 'Avg spending',
    OFFER_LIVE_ITEMS: 'Items',
    OFFER_LIVE_SELECTION: 'Selection',
    OFFER_LIVE_SINGLE_ITEM: 'Single item',
    lottery: 'Lottery',
    OFFER_LIVE_WECHAT_GROUP: 'Wechat Group',
    OFFER_LIVE_N: 'N',
    OFFER_LIVE_ORDER_ID: 'Order ID',
    OFFER_LIVE_GRP: 'Grp',
    OFFER_LIVE_ABC: 'A,B,C..',
    OFFER_LIVE_PHONE: 'Phone',
    OFFER_LIVE_DELIVERY_ADDRESS: 'Delivery address',
    OFFER_LIVE_AREA: 'Area',
    OFFER_LIVE_COMMENT: 'Comment',
    OFFER_LIVE_DELIVERY_DATE: 'Delivery date',
    OFFER_LIVE_DELIVERY_FEE: 'Delivery fee',
    OFFER_LIVE_FAPIAO: 'Fapiao',
    OFFER_LIVE_VOUCHER: 'Voucher',
    OFFER_LIVE_TICKET: 'Ticket (TK)',
    OFFER_LIVE_TOTAL: 'Total',
    OFFER_LIVE_PAYMENTS_STATUS: 'Payments status',
    OFFER_LIVE_QR: 'QR',
    OFFER_LIVE_CANCEL_ORDER: 'Cancel order',
    OFFER_LIVE_REALLY_WANT_CANCEL_ORDER:
        'Do you really want to cancel this order?',
    OFFER_LIVE_YES_NO: 'Yes/No',
    OFFER_LIVE_YES: 'Yes',
    OFFER_LIVE_NO: 'No',
    OFFER_LIVE_CHAT: 'Chat',
    OFFER_LIVE_ITEM_SELECTION: 'Item selection',
    OFFER_LIVE_ADD_ITEM: 'Add item',
    OFFER_LIVE_NAME: 'Name',
    OFFER_LIVE_QT_AV: 'Qt Av.',
    OFFER_LIVE_SOLD: 'Sold',
    OFFER_LIVE_PRICE_CNY: 'Price (CNY)',

    OFFER_ANALYTICS_TOTAL_SALES: 'Total sales',
    OFFER_ANALYTICS_AVERAGE_SPENDING: 'Average spending',
    OFFER_ANALYTICS_SALES_PER_GROUP: 'Sales per group',
    OFFER_ANALYTICS_VOLUME: 'Volume',
    OFFER_ANALYTICS_VALUE: 'Value',
    OFFER_ANALYTICS_PRODUCTS_SALES_CHANNEL_RATIO:
        'Products sales channel ratio',
    OFFER_ANALYTICS_QTY: 'Qty',
    OFFER_ANALYTICS_CNY: 'CNY',
    OFFER_ANALYTICS_PRODUCTS_VOLUME: 'Products Volume/Value',
    OFFER_ANALYTICS_PRODUCTS_REVENUE: 'Products revenue',
    OFFER_ANALYTICS_POTENTIAL: 'Potential',
    OFFER_ANALYTICS_ACTUAL: 'Actual',
    OFFER_ANALYTICS_GROSS_MARGIN: 'Gross margin',
    OFFER_ANALYTICS_REORDER_RATE: 'Reorder rate',
    OFFER_ANALYTICS_NEW_CUSTOMERS: 'New customers',
    OFFER_ANALYTICS_COMPARISON: 'Comparison',
    OFFER_ANALYTICS_SALES_PER_CITY: 'Sales per city',
    OFFER_ANALYTICS_ITEM: 'Item',
    OFFER_ANALYTICS_DESC_GROUPS: 'Description groups',
    OFFER_ANALYTICS_DESC_MP: 'Description MP',

    LOTTERY_PRIZE_DESC: 'Prize description',
    LOTTERY_WINNER: 'Winner',

    DELIVERY_DELIVERY_TRACKING: 'Delivery tracking',
    on_the_way: 'On the way',
    prepared: 'Prepared',
    delivered: 'Delivered',
    delayed: 'Delayed',
    DELIVERY_PROCESS: 'Process',
    customer: 'Customer',
    DELIVERY_TRACKING_NUMBER: 'Tracking number',
    DELIVERY_EXPORT_ORDER_ID: 'Export Order ID',

    CREATE_OFFER_CHANNEL: 'Channel',
    CREATE_OFFER_PUBLIC_NAME_ENGLISH: 'Public name (English)',
    CREATE_OFFER_PUBLIC_NAME_CHINESE: 'Public name (中文)',
    CREATE_OFFER_GHOST_ID: 'Ghost ID',
    CREATE_OFFER_BANNER_ENGLISH: 'Banner (English)',
    CREATE_OFFER_BANNER_CHINESE: 'Banner (中文）',
    CREATE_OFFER_CARD_OFFER_IMG_EN: 'Card offer images (English)',
    CREATE_OFFER_CARD_OFFER_IMG_CN: 'Card offer images (Chinese)',
    CREATE_OFFER_PAGE_IMG_EN: 'Offer page images (English)',
    CREATE_OFFER_PAGE_IMG_CN: 'Offer page images (Chinese)',
    CREATE_OFFER_ADD_PICTURES: 'Add pictures',
    CREATE_OFFER_UPLOAD: 'Upload',
    CREATE_OFFER_DELETE_OFFER: 'Delete offer',
    CREATE_OFFER_DUPLICATE_OFFER: 'Duplicate offer',
    CREATE_OFFER_SHORTCUT: 'Shortcut',
    CREATE_OFFER_SHORTCUT_TEXT: 'Shortcut text',
    CREATE_OFFER_CLIPBOARD: 'Clipboard',
    CREATE_OFFER_ADD_SELECTION: 'Add selection',
    CREATE_OFFER_DELETE_SELECTION: 'Delete selection',
    CREATE_OFFER_THUMBNAIL_PAYMENT: 'Thumbnail (Payment)',
    CREATE_OFFER_THUMBNAIL_EN: 'Thumbnail',
    CREATE_OFFER_THUMBNAIL_CN: 'Thumbnail',
    CREATE_OFFER_PACK_PICTURES: 'Pack pictures',
    CREATE_OFFER_ENGLISH_NAME: 'English name',
    CREATE_OFFER_CHINESE_NAME: 'Chinese name',
    CREATE_OFFER_STOCK_AVAILABLE: 'Stock available',
    CREATE_OFFER_ACTUAL_PRICE: 'Actual price',
    CREATE_OFFER_CROSSED_ORIGINAL_PRICE: 'Crossed original price',
    CREATE_OFFER_PRICE: 'Price',
    CREATE_OFFER_TARGET_SALES: 'Target sales',
    CREATE_OFFER_SELECT_PRODUCT: 'Select product',
    CREATE_OFFER_QUANTITY: 'Quantity',
    CREATE_OFFER_WEIGHT_PER_UNIT: 'Weight / unit',
    CREATE_OFFER_UNIT: 'Unit',
    CREATE_OFFER_GRAM: 'Gram',
    CREATE_OFFER_ML: 'Milliliter',
    CREATE_OFFER_PIECES: 'Pieces',
    CREATE_OFFER_WEIGHT: 'Weight',
    CREATE_OFFER_ADD_PRODUCT: 'Add product',
    CREATE_OFFER_ADD_SINGLE_ITEM: 'Add single item',
    CREATE_OFFER_DELETE_SINGLE_ITEM: 'Delete single item',
    CREATE_OFFER_ADD_ON: 'Add on',
    CREATE_OFFER_ZIKO_SPECIALS: 'Ziko specials',
    CREATE_OFFER_ADD_SPECIAL: 'Add special',
    CREATE_OFFER_PRICE_DROP: 'Price drop',
    CREATE_OFFER_LOWEST_PRICE_LIMIT: 'Lowest price limit',
    CREATE_OFFER_UNIT_PRICE: 'Unit price',
    CREATE_OFFER_FROM: 'From (Qty)',
    CREATE_OFFER_TO: 'To (Qty)',
    CREATE_OFFER_BOURSE_RULE: 'Bourse rule',
    CREATE_OFFER_OFFER_DETAILS: 'Offer details',
    CREATE_OFFER_DURATION: 'Time and Duration',
    CREATE_OFFER_STARTING_DATE: 'Starting date',
    CREATE_OFFER_STARTING_TIME: 'Starting time',
    CREATE_OFFER_ENDING_DATE: 'Ending date',
    CREATE_OFFER_ENDING_TIME: 'Ending time',
    CREATE_OFFER_OFFERY_TYPE: 'Offery type',
    CREATE_OFFER_PRICE_RULE: 'Price rule',
    CREATE_AN_OFFER_REGULAR: 'Regular',
    CREATE_OFFER_FREE_FALL: 'Free fall',
    CREATE_OFFER_MULTIPLE_ITEMS: 'Multiple items',
    CREATE_OFFER_BOURSE: 'Bourse',
    CREATE_OFFER_CLEARANCE: 'Clearance',
    CREATE_OFFER_CAVE: 'Cave',
    CREATE_OFFER_OFFER_TARGETS: 'Offer targets',
    CREATE_OFFER_COMISSION_RATE: 'Comission rate',
    CREATE_OFFER_MINIMUM_ORDERS: 'Minimum orders',
    CREATE_OFFER_CART_AMOUNT: 'Cart amount',
    CREATE_OFFER_MINIMUM_CART_ITEMS: 'Minimum add ons',
    CREATE_OFFER_AREA_NAME: 'Area name',
    CREATE_OFFER_RULE: 'Rule',
    up_to: 'Up to (items quantity)',
    free_after_quantity: 'Free after (items quantity)',
    free_after_value: 'Free after (order value)',
    CREATE_OFFER_ITEMS_QTY: 'Items qty',
    CREATE_OFFER_ORDER_VALUE: 'Order value',
    CREATE_OFFER_ADD_RULE: 'Add rule',
    CREATE_OFFER_DELIVERY_TYPE: 'Delivery type',
    CREATE_OFFER_DELETE_RULE: 'Delete rule',
    flat: 'Flat',
    CREATE_OFFER_ADD_AN_AREA: 'Add an area',
    CREATE_OFFER_SELECT_MULTIPLE_DATES: 'Select multiple dates',
    CREATE_OFFER_MANUAL: 'Manual',
    CREATE_OFFER_SHUNFENG: 'Shunfeng',
    CREATE_OFFER_BUY_ITEMS: 'Buy (items)',
    CREATE_OFFER_NONE: 'None',
    CREATE_OFFER_PER_ITEMS_SOLD: 'Per items sold (Qt)',
    CREATE_OFFER_CARD_BANNER_ENGLISH:
    'Card offer images (English) (345px * 144px)',
    CREATE_OFFER_CARD_BANNER_CHINESE:
        'Card offer images (Chinese) (345px * 144px)',
    CREATE_OFFER_OFFER_PAGE_IMAGES: 'Offer page images (375px * 256px)',
    CREATE_OFFER_OFFER_PAGE_IMAGES_ENGLISH:
        'Offer page images (English) (375px * 256px)',
    CREATE_OFFER_OFFER_PAGE_IMAGES_CHINESE:
        'Offer page images (Chinese) (375px * 256px)',
    CREATE_OFFER_SHOW_DETAIL: 'Show offer details on MP',
    CREATE_OFFER_SHOW: 'Show',
    CREATE_OFFER_HIDE: 'Hide',
    Home: 'Home',
    Office: 'Office',
    Other: 'Other',
    Others: 'Others',
    Free: 'Free',
    Paid: 'Paid',
    Pack: 'Pack',
    others: 'Others',
    fapiao: 'Fapiao',
    cancel_order: 'Cancel order',
    free_order: 'Free order',
    office: 'Office',
    home: 'Home',
    temporary: 'Temporary',
    ziko_special: 'Ziko specials',

    ZIKO_SPECIALS_SPECIAL: 'Special "X"',
    ZIKO_SPECIALS_FIRST_ORDER: 'First orders',
    ZIKO_SPECIALS_NUMBER_OF_ORDERS_REACH: 'Number of orders reach',
    ZIKO_SPECIALS_OFER_FOR_AMOUNT_GET: 'Order for (amount) get',
    ZIKO_SPECIALS_X_TOTAL_SOLD_ITEMS: '"X" total sold items',
    ZIKO_SPECIALS_ITEM_X_IN_CART: 'Item "X" in cart',
    ZIKO_SPECIALS_AMOUNT_QUANTITY: 'Amount / Quantity',
    ZIKO_SPECIALS_HOW_MUCH: 'How much',
    ZIKO_SPECIALS_GET: 'Get',
    ZIKO_SPECIALS_PRICE_REDUCTION: 'Price reduction',
    ZIKO_SPECIALS_DISCOUNT_RATE: 'discount rate',
    ZIKO_SPECIALS_DELETE_SPECIAL: 'Delete special',
    ZIKO_SPECIALS_PACK: 'Pack',
    ZIKO_SPECIALS_ADD_ONS: 'Add ons',
    ZIKO_SPECIALS_CUSTOM: 'Custom',
    ZIKO_SPECIALS_DISCOUNT: 'Discount',
    ZIKO_SPECIALS_FREE_DELIVERY: 'Free delivery',
    ZIKO_SPECIALS_TICKETS: 'Tickets',
    ZIKO_SPECIALS_1_TICKET_EVERY: '1 ticket every',
    ZIKO_SPECIALS_SPEND: 'Spend',
    ZIKO_SPECIALS_BUY: 'Buy',
    ZIKO_SPECIALS_X_PACK_BOUGHT: '"X" pack bought',
    ZIKO_SPECIALS_EXTRA_TICKET: 'Extra ticket',
    ZIKO_SPECIALS_BUY_FOR: 'Buy for...',
    ZIKO_SPECIALS_NONE: 'None',
    ZIKO_SPECIALS_DRAW: 'Draw',
    ZIKO_SPECIALS_ADD_DRAW: 'Add draw',
    ZIKO_SPECIALS_DELETE_DRAW: 'Delete draw',
    ZIKO_SPECIALS_UNLOCK_CONDITION: 'Unlock condition',
    ZIKO_SPECIALS_GET_ONE_TICKET_FOR: 'Get 1 ticket for',
    ZIKO_SPECIALS_SPENT: 'Spent(¥)',
    ZIKO_SPECIALS_BUY_ITEMS: 'Buy items',
    ZIKO_SPECIALS_ORDER: 'Order',
    ZIKO_SPECIALS_NUMBER_OF_ITEMS_REACH: 'Number of items reach',
    ZIKO_SPECIALS_NUMBER_OF_ITEMS_SOLD_REACH: 'Number of items sold reach',
    ZIKO_SPECIALS_CUSTOM_ITEM: 'Custom item',
    ZIKO_SPECIALS_ILLUSTRATION: 'Illustration',
    ZIKO_SPECIALS_NB_OF_WINNERS: 'Nb of winners',
    ZIKO_SPECIALS_ADD_GIFT: 'Add gift',
    ZIKO_SPECIALS_ITEM_NAME_ENGLISH: 'Item name (English)',
    ZIKO_SPECIALS_EXPIRATION_DATE: 'Expiration date',
    ZIKO_SPECIALS_CREATION_DATE: 'Creation date',

    SERVICES_ORDER_LIST_TRANSACTION_NUMBER: 'Transaction number',
    pending: 'Pending',
    paid: 'Paid',
    ccl: 'Cancelled',
    rfd: 'Refund',
    refund: 'Refund',
    SERVICES_ORDER_LIST_DATE: 'Date',
    SERVICES_ORDER_LIST_ORDER_DETAILS: 'Order details',
    SERVICES_ORDER_LIST_CREATE_VOUCHER: 'Create voucher',
    SERVICES_ORDER_GOT_VOUCHER: 'Got voucher!',
    SERVICES_ORDER_TIME: 'Time',

    DIRECT_SALES_ALL_CUSTOMER_ORDERS: 'All customer orders',
    DIRECT_SALES_DELIVERY_TRACKING_NUMBER: 'Delivery tracking number',
    DIRECT_SALES_DELIVERED: 'Delivered',
    DIRECT_SALES_DOWNLOAD: 'Download',
    DIRECT_SALES_CREATE_DAILY_ORDER: 'Create daily order',
    DIRECT_SALES_DAILY_ORDER_INFORMATION: 'Daily order information',
    DIRECT_SALES_GENERAL_INFORMATION: 'General information',
    DIRECT_SALES_CHOOSE_SUPPLIER: 'Choose supplier',
    DIRECT_SALES_CUSTOMER_INFORMATION: 'Customer information',
    DIRECT_SALES_PHONE_NUMBER: 'Phone number',
    DIRECT_SALES_ADDRESS_NAME: 'Address name',
    DIRECT_SALES_OFFICE: 'Office',
    DIRECT_SALES_HOME: 'Home',
    DIRECT_SALES_ZIPCODE: 'Zipcode',
    DIRECT_SALES_DETAILED_ADDRESS: 'Detailed address',
    DIRECT_SALES_FAPIAO_INFORMATION: 'Fapiao information',
    DIRECT_SALES_PRODUCTS: 'Products',
    DIRECT_SALES_CHOOSE_PRODUCT: 'Choose product',
    DIRECT_SALES_COST_PRICE: 'Cost price (incl VAT)',
    DIRECT_SALES_RETAIL_PRICE: 'Retail price (incl VAT)',
    DIRECT_SALES_WEIGHT_PER_UNIT: 'Weight / Unit',
    DIRECT_SALES_UNIT: 'Unit',
    DIRECT_SALES_GRAM: 'Gram',
    DIRECT_SALES_ML: 'Milliliter',
    DIRECT_SALES_PIECES: 'Pieces',
    DIRECT_SALES_DELETE_PRODUCT: 'Delete product',
    DIRECT_SALES_DOWNLOAD_PDF_EN: 'Download PDF (EN)',
    DIRECT_SALES_DELIVERY: 'Delivery',
    DIRECT_SALES_LOADING_PDF: 'Loading PDF',

    CUSTOMERS_LAST_ORDER: 'Last order',
    CUSTOMERS_NEVER_ORDERED: 'Never ordered',
    CUSTOMERS_DAYS_AGO: 'days ago',
    CUSTOMERS_WEEKS_AGO: 'weeks ago',
    CUSTOMERS_MONTHS_AGO: 'months ago',
    CUSTOMERS_YEARS_AGO: 'years ago',
    CUSTOMERS_CREATE_CUSTOMER: 'Create customer',
    CUSTOMERS_INFORMATION: 'Information',
    CUSTOMERS_PERSONAL_INFORMATION: 'Personal information',
    CUSTOMERS_CUSTOMER_NAME: 'Customer name',
    CUSTOMERS_LEVEL: 'Level',
    tourist: 'TOURIST',
    curious_wanderer: 'CURIOUS WANDERER ',
    humble_resident: 'HUMBLE RESIDENT',
    chummy_villager: 'CHUMMY VILLAGER ',
    lord_furnace: 'LORD FURNACE',
    secret_circle: 'ZIKO SECRET CIRCLE',
    CUSTOMERS_ADDRESS: 'Address',
    CUSTOMERS_ADDRESS_TYPE: 'Address type',
    CUSTOMERS_ADDRESS_NAME: 'Address name',
    CUSTOMERS_ADD_ADDRESS: 'Add address',
    CUSTOMERS_PET_INFORMATION: 'Pet information',
    CUSTOMERS_PET: 'Pet',
    CUSTOMERS_PET_1: 'Pet 1',
    CUSTOMERS_PET_TYPE: 'Pet type',
    CUSTOMERS_CAT: 'Cat',
    CUSTOMERS_DOG: 'Dog',
    CUSTOMERS_PET_SIZE: 'Pet size',
    CUSTOMERS_SMALL: 'Small',
    CUSTOMERS_BIG: 'Big',
    CUSTOMERS_MEDIUM: 'Medium',
    CUSTOMERS_ADD_PET: 'Add pet',
    CUSTOMERS_LOTTERY_PRIZE: 'Lottery prize',
    CUSTOMERS_CCL: 'CANC',
    CUSTOMERS_RFD: 'RFD',
    CUSTOMERS_SO: 'SO',
    CUSTOMERS_OFFER: 'Offer',
    CUSTOMERS_ORDER_DATE: 'Order date',
    CUSTOMERS_UNCLAIMED_VOUCHERS: 'Unclaimed vouchers',
    CUSTOMERS_VOUCHER_ID: 'Voucher ID',
    CUSTOMERS_VOUCHER_REASON: 'Voucher reason',
    CUSTOMERS_SPECIFIC_OFFER_VOUCHER_AVAILABLE:
        'Specific offer for this voucher to be used',
    CUSTOMERS_CANCELLED_ORDER: 'Cancelled order',
    CUSTOMERS_VALIDATED: 'Validated',
    CUSTOMERS_USED_VOUCHERS: 'Used vouchers',
    used: 'Used ',
    CUSTOMERS_ORDER_NUMBER: 'Order number',
    CUSTOMERS_USE_DATE: 'Use date',
    voucher_information: 'Voucher information',
    CUSTOMERS_VOUCHER_AMOUNT: 'Voucher amount',
    amount: 'Amount',
    claim: 'Claim',
    gift: 'Gift',
    gift_giving: 'Gift',
    other: 'Other',
    supplier: 'Supplier',
    delivery_company: 'Delivery company',
    ziko: 'Zikoland',
    claim_date: 'Claim date',
    order_date: 'Order date',
    resend: 'Resend',
    CUSTOMERS_VOUCHERS: 'Vouchers',
    CUSTOMERS_WECHAT_ID: 'WeChat ID',
    CUSTOMERS_CONTACT_PERSONS:
        'Contact persons(Due to the new update, this section is for information only)',
    CUSTOMERS_ADD_PHONE_NUMBER: 'Add phone number',
    CUSTOMERS_CONTACT_NAME: 'Contact name',
    CUSTOMERS_TOTAL_SALES_BY_COMMUNITY: 'Total sales by community',
    CUSTOMERS_TOTAL_SPENT: 'Total spent(¥)',
    CUSTOMERS_REORDER_RATE: 'Reorder rate',
    CUSTOMERS_FAN_OF: 'Fan of',
    CUSTOMERS_FAPIAO_UPLOAD: 'Fapiao upload',

    SUPPLIERS_SUPPLIER_NAME: 'Supplier name',
    SUPPLIERS_TYPE: 'Type',
    direct_sales: 'Direct sales',
    services: 'Services',
    available: 'Available',
    discontinued: 'Discontinued',
    SUPPLIERS_CREATE_SUPPLIER: 'Create supplier',
    SUPPLIERS_SUPPLIER_INFORMATION: 'Supplier information',
    SUPPLIERS_CONTACT: 'Contact',
    SUPPLIERS_ADD_CONTACT: 'Add contact',
    SUPPLIERS_JOB_TITLE: 'Job title',
    SUPPLIERS_BANK_INFORMATION: 'Bank information',
    SUPPLIERS_SAVE_SUPPLIER: 'Save supplier',
    SUPPLIERS_FINANCIAL: 'Financial',
    SUPPLIERS_PAYMENT_TERMS: 'Payment terms',
    SUPPLIERS_CASH_BEFORE_DELIVERY: 'Cash before delivery',
    SUPPLIERS_CASH_ON_DELIVERY: 'Cash on delivery',
    SUPPLIERS_END_OF_THE_MONTH: 'End of the month',
    SUPPLIERS_30_DAYS: '30 days',
    SUPPLIERS_35_DAYS: '35 days',
    SUPPLIERS_60_DAYS: '60 days',
    SUPPLIERS_VAT: 'VAT',
    SUPPLIERS_FINANCE_INFORMATION: 'Finance information',
    SUPPLIERS_COMMISSIONS_RATE: 'Commissions rate',

    PRODUCT_LIST: 'Product list',
    PRODUCT_LIST_PRODUCT_ID: 'Product ID',
    PRODUCT_LIST_PRODUCT_TYPE: 'Product type',
    PRODUCT_LIST_FOOD_STORAGE: 'Food storage',
    PRODUCT_LIST_RETAIL_PRICE: 'Retail price',
    PRODUCT_LIST_CREATE_PRODUCT: 'Create product',
    PRODUCT_LIST_COST_PRICE: 'Cost price',
    PRODUCT_LIST_PRODUCT_DETAILS: 'Product details',
    PRODUCT_LIST_CHOOSE_TYPE: 'Choose type',
    none: 'None',
    fresh: 'Fresh',
    dry: 'Dry',
    frozen: 'Frozen',
    Food: 'Food',
    DRY_FOOD: 'Dry (Food)',
    SEAFOOD_AND_FISH_FOOD: 'Seafood & Fish (Food)',
    MEAT_FOOD: 'Meat (Food)',
    DAIRY_FOOD: 'Dairy (Food)',
    BAKERY_AND_PASTRY_FOOD: 'Bakery and Pastry (Food)',
    VEGETABLES_AND_FRUITS_FOOD: 'Vegetables & Fruits (Food)',
    SNACK_AND_DIP_FOOD: 'Snack & Dip (Food)',
    SWEETS_FOOD: 'Sweets (Food)',
    COLD_CUT_FOOD: 'Cold Cut (Food)',
    SEASONINGS_FOOD: 'Seasonings (Food)',
    POULTRY_FOOD: 'Poultry (Food)',
    OTHERS_FOOD: 'Others (Food)',
    BEVERAGE: 'Beverage',
    WINE_BEVERAGE: 'Wine (Beverage)',
    LIQUOR_SPIRITS_BEVERAGE: 'Liquor & Spirits (Beverage)',
    BEER_CIDER_BEVERAGE: 'Beer & Cider (Beverage)',
    SOFT_ENERGY_DRINKS_BEVERAGE: 'Soft & energy drinks (Beverage)',
    SIRUP_BEVERAGE: 'Sirup (Beverage)',
    OTHERS_BEVERAGE: 'Others (Beverage)',
    FRESH_FOOD_PET: 'Fresh food (Pet)',
    DRY_FOOD_PET: 'Dry food (Pet)',
    WET_FOOD_PET: 'Wet food (Pet)',
    TREATS_PET: 'Treats (Pet)',
    SKIN_CARE_PET: 'Skin care (Pet)',
    ACCESSORIES_PET: 'Accessories (Pet)',
    LITTER_PET: 'Litter (Pet)',
    CLOTHES_PET: 'Clothes (Pet)',
    Diet: 'Diet',
    vegan: 'Vegan',
    pa: 'PA',
    'gluten-free': 'Gluten-free',
    'no-hormones': 'No hormones',
    PRODUCT_LIST_TOTAL_WEIGHT: 'Total weight',
    PRODUCT_LIST_SALES_PER_CITY: 'Sales per city',
    PRODUCT_LIST_UNIT_PRICE: 'Unit price (incl VAT)',
    PRODUCT_LIST_MEDIA: 'Media',
    PRODUCT_LIST_THUMBNAIL: 'Thumbnail',
    PRODUCT_LIST_THUMBNAIL_EN: 'Thumbnail',
    PRODUCT_LIST_THUMBNAIL_CN: 'Thumbnail',
    PRODUCT_LIST_TECHNICAL_SHEET: 'Technical sheet',
    PRODUCT_LIST_EN_TECHNICAL_SHEET: 'English Technical sheet',
    PRODUCT_LIST_CN_TECHNICAL_SHEET: 'Chinese Technical sheet',
    PRODUCT_LIST_PRODUCT_PICTURES: 'Product pictures',

    INVENTORY_TOTAL_STOCK_VOLUME: 'Total stock volume (units)',
    INVENTORY_TOTAL_STOCK_VALUE: 'Total stock value',
    INVENTORY_PRODUCT_NAME: 'Product name',
    INVENTORY_PRODUCT: 'Product',
    INVENTORY_ACTUAL_STOCK: 'Actual stock',
    purchased: 'Purchased (not received)',
    INVENTORY_PAID_STOCK: 'Paid stock',
    INVENTORY_FREE_STOCK: 'Free stock',
    INVENTORY_TOTAL: 'Total',
    INVENTORY_MERGE: 'Merge',
    INVENTORY_FORECAST_STOCK: 'Forecast stock',
    INVENTORY_FORECAST_STOCK_VALUE: 'Forecast stock value',
    INVENTORY_VIEW_DETAILS: 'View details',
    INVENTORY_STOCK_MOVEMENTS: 'Stock movements',
    INVENTORY_CUSTOMER_ORDER_ID: 'Customer Order ID',
    customer_order: 'Customer Order',
    INVENTORY_PO_NUMBER: 'PO number',
    INVENTORY_MOVEMENT_TYPE: 'Movement type',
    manual_add: 'Manual add',
    manual_remove: 'Manual remove',
    purchase_order: 'Purchase order',
    in: 'In',
    out: 'Out',
    on_delivery: 'On delivery',
    INVENTORY_ADD_STOCK: 'Add stock',
    INVENTORY_SELECT_STOCK_FROM: 'Select stock from',
    INVENTORY_COMMENT: 'Comment',
    INVENTORY_PLEASE_ADD_A_COMMENT: 'Please add a comment',
    INVENTORY_REMOVE_STOCK: 'Remove stock',
    INVENTORY_OWNED_STOCK: 'Owned stock',
    INVENTORY_QUANTITY_TO_ADD: 'Quantity to add',
    INVENTORY_QUANTITY_TO_REMOVE: 'Quantity to remove',
    merge: 'Merged stock',
    AUTOMATIC_MERGE_OF_THE_FREE_STOCK: 'Automatic merge of the free stock',
    field_required: 'Field Required',

    PURCHASE_ORDERS_EXPORT_PDF: 'Export PDF',
    PURCHASE_ORDERS_EDIT_PURCHASE_ORDER: 'Edit purchase order',

    CUSTOMER_SERVICE_EXPORT_CLAIMS: 'Export claims',
    CUSTOMER_SERVICE_ORDER_DATE: 'Order date',
    CUSTOMER_SERVICE_CLAIM_DATE: 'Claim date',
    CUSTOMER_SERVICE_RESEND: 'Resend',
    CUSTOMER_SERVICE_EDIT_CLAIM: 'Edit claim',
    CUSTOMER_SERVICE_SELECT_TYPE: 'Select type',
    CUSTOMER_SERVICE_SOLVED_RATIO: 'Solved ratio',
    CUSTOMER_SERVICE_ONGOING_RATIO: 'Ongoing ratio',
    ongoing: 'Ongoing',
    new: 'New',
    CUSTOMER_SERVICE_RESEND_RATIO: 'Resend ratio',
    CUSTOMER_SERVICE_TOTAL_VOUCHERS: 'Total vouchers',
    CUSTOMER_SERVICE_RESPONSIBILITY: 'Responsibility',
    CUSTOMER_SERVICE_DELIVERY_SERVICE: 'Delivery service',
    CUSTOMER_SERVICE_ISSUE: 'Issue',
    wrong_address: 'Wrong address',
    packaging: 'Packaging',
    defrost: 'Defrost / Melted',
    product_default: 'Product damaged',
    product_damaged: 'Product damaged',
    item_missing: 'Item missing',
    delay: 'Delay',
    customer_mistake: 'Customer mistake',
    wrong_time: 'Wrong time delivery',
    wrong_item: 'Wrong item',
    CUSTOMER_SERVICE_ACTION: 'Action',
    CUSTOMER_SERVICE_GIFT_NEXT_ORDER: 'Gift next order',
    CUSTOMER_SERVICE_ORDER_FOR_FREE: 'Order for free',
    CUSTOMER_SERVICE_FREE_ORDER: 'Free order',
    standby: 'Stand by',
    solved: 'Solved',
    failed: 'Failed',
    CUSTOMER_SERVICE_ACTION_ETA: 'Action ETA',
    CUSTOMER_SERVICE_CUSTOMER_LIVE_CHAT: 'Customer live chat',
    CUSTOMER_SERVICE_CREATE_CLAIM: 'Create claim',
    CUSTOMER_SERVICE_CLAIM_DETAIL: 'Claim detail',
    CUSTOMER_SERVICE_ISSUE_DETAIL: 'Issue detail',
    CUSTOMER_SERVICE_STEP: 'Step',
    CUSTOMER_SERVICE_DECLINE: 'Decline',
    CUSTOMER_SERVICE_VALIDATE: 'Validate',
    declined: 'Declined',
    validated: 'Validated',
    delivery: 'Delivery',
    refund: 'Refund',
    voucher: 'Voucher',

    RECIPES_RECIPE_NAME: 'Recipe name',
    RECIPES_PUBLISHED: 'Published',
    RECIPES_NOT_PUBLISHED: 'Not published',
    RECIPES_CREATE_RECIPE: 'Create recipe',
    RECIPES_SERVINGS: 'Servings',
    RECIPES_PREPARATION_TIME: 'Preparation time (min)',
    RECIPES_BAKING_TIME: 'Baking time (min)',
    RECIPES_CALORIES: 'Calories',
    RECIPES_ADD_MORE_PICTURES_OR_VIDEOS: 'Add more pictures or videos',
    RECIPES_TAGS: 'Tags',
    RECIPES_MEAL_DIET: 'Meal & Diet',
    'Apetizers and Snacks': 'Apetizers and Snacks',
    Lunch: 'Lunch',
    Dinner: 'Dinner',
    Sides: 'Sides',
    Breakfast: 'Breakfast',
    Desert: 'Desert',
    Brunch: 'Brunch',

    Healthy: 'Healthy',
    Pastry: 'Pastry',
    Bakery: 'Bakery',
    'Food allergy': 'Food allergy',
    'Wheat Free': 'Wheat Free',
    'Dairy Free': 'Dairy Free',
    'Peanut Free': 'Peanut Free',
    'Soy Free': 'Soy Free',
    'Sesame Free': 'Sesame Free',
    'Seafood Free': 'Seafood Free',
    'Gluten Free': 'Gluten Free',
    CUISINE: 'Cuisine',
    French: 'French',
    Chinese: 'Chinese',
    Japanese: 'Japanese',
    Thai: 'Thai',
    American: 'American',
    Fusion: 'Fusion',
    Italian: 'Italian',
    TIME_LEVEL: 'Time & Level',
    TIME_LEVEL_EASY: 'Easy',
    TIME_LEVEL_INTERMEDIATE: 'Intermediate',
    TIME_LEVEL_ADVANCED: 'Advanced',
    TIME_LEVEL_QUICKIE: 'Quickie (under 30 minutes)',
    COMMUNITY: 'Community',
    COMMUNITY_BY_ZIKO: 'By Ziko',
    COMMUNITY_COMMUNITY: 'Community',
    COMMUNITY_ZIKO_GUESTS: 'Ziko guests',
    COMMUNITY_CHEFS: 'Chefs',
    INGREDIENTS: 'Ingredients',
    Barramundi: 'Barramundi',
    Beef: 'Beef',
    Capon: 'Capon',
    Cheese: 'Cheese',
    Chicken: 'Chicken',
    Chocolate: 'Chocolate',
    Cod: 'Cod',
    Coffee: 'Coffee',
    Duck: 'Duck',
    'Duck Liver': 'Duck Liver',
    'Goose Liver': 'Goose Liver',
    Hadock: 'Hadock',
    Halibut: 'Halibut',
    Ham: 'Ham',
    Iberico: 'Iberico',
    Lamb: 'Lamb',
    Mackerel: 'Mackerel',
    Mussels: 'Mussels',
    Nuts: 'Nuts',
    Octopus: 'Octopus',
    Oysters: 'Oysters',
    Pasta: 'Pasta',
    Pigeon: 'Pigeon',
    Pork: 'Pork',
    Prawns: 'Prawns',
    'Red fish': 'Red fish',
    Salmon: 'Salmon',
    Sausages: 'Sausages',
    Scallop: 'Scallop',
    Scampi: 'Scampi',
    Seafood: 'Seafood',
    Shrimps: 'Shrimps',
    Snowcrab: 'Snowcrab',
    Squid: 'Squid',
    Toothfish: 'Toothfish',
    Truffles: 'Truffles',
    Tuna: 'Tuna',
    Turkey: 'Turkey',
    Veal: 'Veal',
    'Veal Liver': 'Veal Liver',
    Vegetables: 'Vegetables',
    Venison: 'Venison',
    Wagyu: 'Wagyu',
    RECIPES_MEDIA: 'Media',
    RECIPES_MAIN_PICTURE_EN: 'Main Picture',
    RECIPES_MAIN_PICTURE_CN: 'Main Picture',
    RECIPES_OTHER_MEDIA: 'Other media',
    RECIPES_EXTENSION_ALLOWED:
        'Extension allowed(jpg, jpeg, png, gif, mp4), Recommendation size(375px * 256px)',
    DIET: 'Diet',
    Vegetarian: 'Vegetarian',
    Vegan: 'Vegan',
    'Gluten-free': 'Gluten-free',
    'Recipe details': 'Recipe details',
    'Link product': 'Link product',
    'Delete link': 'Delete link',
    'Add more': 'Add more',
    'Pin on the top': 'Pin on the top',
    Publication: 'Publication',

    LOTTERY_MANAGEMENT_DRAW_NUMBER: 'Draw number',
    LOTTERY_MANAGEMENT_NUMBER_WINNERS: 'Number of winners',
    LOTTERY_MANAGEMENT_PRIZE: 'Prize',
    LOTTERY_MANAGEMENT_WINNER_NAME: 'Winner name',
    LOTTERY_MANAGEMENT_WIN_TIME: 'Win time',

    merge: 'Merged stock',
    invoice: 'Invoice',
    FINANCE_GENERAL_PAYMENT_COLLECTED_SUPPLIER:
        'Total payment collected for supplier',
    FINANCE_GENERAL_PURCHASE_INCL_VAT: 'Total purchase incl VAT',
    unpaid: 'Unpaid',
    FINANCE_GENERAL_SEE_DETAIL: 'See detail',

    DIRECT_SALES_TOTAL_EXC_VAT: 'Total exc VAT',
    DIRECT_SALES_TOTAL_INCL_VAT: 'Total incl VAT',
    DIRECT_SALES_STOCK_UPDATED: 'Stock updated',
    DIRECT_SALES_RECEIVED_PRODUCTS: 'Received products',
    DIRECT_SALES_CREATE_PO: 'Create PO',
    DIRECT_SALES_PAYMENT_DONE: 'Payment done',
    DIRECT_SALES_SURE_TO_VALIDATE_PAYMENT:
        'Are you sure to validate the payment?',
    DIRECT_SALES_STOCK_RECEIVED: 'Stock received',
    DIRECT_SALES_SURE_TO_VALIDATE_RECEPTION_STOCK:
        'Are you sure to validate the reception of stock?',
    DIRECT_SALES_CHANGE_PAID_STATUS: 'Change paid status',
    DIRECT_SALES_SURE_TO_VALIDATE_PAYMENT_RECEIVED:
        'Are you sure to validate that the payment have been received?',

    PURCHASE_ORDER_DETAIL_PURCHASE_ORDER_DETAIL: 'Purchase order detail',
    PURCHASE_ORDER_DETAIL_UNIT_PRICE_EXCL_VAT: 'Unit price excl VAT',
    PURCHASE_ORDER_DETAIL_UNIT_PRICE_INCL_VAT: 'Unit price incl VAT',
    PURCHASE_ORDER_DETAIL_SUBTOTAL: 'Subtotal',
    PURCHASE_ORDER_DETAIL_CREATE_PURCHASE_ORDER: 'Create purchase order',
    PURCHASE_ORDER_DETAIL_PO_INFORMATION: 'PO information',
    PURCHASE_ORDER_DETAIL_PURCHASE_DATE: 'Purchase date',
    PURCHASE_ORDER_DETAIL_SAVE_PO: 'Save PO',

    SERVICES_INVOICES: 'Invoices',
    SERVICES_TTL_SALES_W_DELIVERY: 'Ttl Sales w/ delivery',
    SERVICES_TTL_SALES_WO_DELIVERY: 'Ttl Sales w/o delivery',
    SERVICES_FEE_PERCENT: 'Service fee (%)',
    SERVICES_FEE_RMB: 'Service fee(¥)',
    SERVICES_ZIKOLAND_COMISSION: 'Zikoland comission',
    SERVICES_INVOICE_ID: 'Invoice ID',
    SERVICES_SEE_OFFER: 'See offer',
    SERVICES_GENERATE_INVOICE: 'Generate invoice',
    SERVICES_DOWNLOAD_INVOICE: 'Download invoice',
    SERVICES_BANK_TRANSFER_ID: 'Bank transfer ID',

    DELIVERY_TEMPLATES_CREATE_AREA: 'Create area',
    DELIVERY_TEMPLATES_TEMPLATE_NAME: 'Template name',
    DELIVERY_TEMPLATES_TEMPLATES: 'Templates',
    DELIVERY_TEMPLATES_AREAS: 'Areas',
    DELIVERY_TEMPLATES_CREATED_DATE: 'Created date',
    DELIVERY_TEMPLATES_CREATE_TEMPLATE: 'Create template',
    DELIVERY_TEMPLATES_DELIVERY_TEMPLATE_NAME: 'Delivery template name',
    DELIVERY_TEMPLATES_DELETE_AREA: 'Delete area',
    DELIVERY_TEMPLATES_DELIVERY_AREA_NAME: 'Delivery area name',
    DELIVERY_TEMPLATES_PARENT: 'Parent',

    LOGIN: 'Login ',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    SIGN_IN: 'Sign in',
    FORGOT_PASS: 'Forgot password',
    WRONG_LOGIN: 'Wrong login or password',
    RESET_PASSWORD: 'Fill in the email to reset the password',
    PASSWORD_RESET_SUCCESS: 'Your password has been reset successfully',
    FILL_PASSWORD: 'Fill in your new password',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
    LOGOUT: 'Logout',

    LEAVE_PAGE:
        'Are you sure you want to leave this page? All your modifications will be lost',

    SHIPPING_ADDRESS: 'Shipping address',
    ORDER: 'Order',
    ORDER_DATE: 'Order date',
    ORDER_REFERENCE: 'Order reference',
    PRODUCT: 'Product',
    TOTAL_PRICE: 'Total price',
    WEBSITE: 'Website',
    PACK: 'Pack',
    ADD_ON: 'Add-on',
    CUSTOM: 'Custom',
    ITEM: 'Item',
    Item: 'Item',
    TOTAL_WITHOUT_TAXES: 'Total without taxes',
    TAXES: 'Taxes',
    DELIVERY: 'Delivery',

    MENU_REFUND: 'Refund',
    REFUNDS_REFUND_RATE: 'Refund Rate',
    REFUNDS_TOTAL_REFUNDS: 'Total refunds',
    REFUNDS_REFUNDED_RATIO: 'Refunded ratio',
    REFUNDS_ONGOING_REFUNDS: 'Ongoing refunds',
    REFUNDS_REFUSED_RATIO: 'Refused ratio',
    REFUNDS_ORDER_TOTAL: 'Order total (¥)',
    REFUNDS_REFUND_AMOUNT: 'Refund Amount (¥)',
    REFUNDS_PAYMENT: 'Payment',
    REFUNDS_PAYMENT_MERCHANT_ID: 'Payment Merchant ID',
    REFUNDS_EXPORT_REFUNDS: 'Export refunds',
    REFUNDS_CREATE_REFUND: 'Create refund',
    REFUNDS_SAVE_REFUND: 'Save refund',
    REFUNDS_REFUND_DETAIL: 'Refund detail',
    REFUNDS_MISSING_PAYMENT_INFO: 'Payment info is missing. Please refund this order manually.',
    REFUNDS_SURE_TO_VALIDATE_REFUND: 'Are you sure you want to refund this order manually?',
    refused: 'Refused',
    refunded: 'Refunded',
    wechat: 'Wechat',
    alipay: 'Alipay',
    CREATE_NEW_CUSTOMER: 'Create new customer?',
    CONFRIM_TO_CREATE_NEW_CUSTOMER: 'Please confirm that you want to create a new customer profile',
    CREATE_OFFER_CHECK_PRICE: 'Original price shouldn\'t be equal or less than the current price',
    SUCCESSFULLY_SAVED: 'successfully saved',
    CREATE_REFUND_NO_MORE_THAN_ACTUAL_AMOUNT: 'No more than actual amount',
    CREATE_REFUND_SELECT_A_VALID_ORDER: 'Please select a valid Order',
}

export default en
