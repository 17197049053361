import { INSTANCE, INSTANCELIST } from './API'

export const getRecipes = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/recipes',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateRecipe = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/recipes',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const EditRecipe = (form, token, userId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/recipes/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleRecipe = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { recipeId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/recipes/${recipeId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const PostImage = (data, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/files/recipe-description',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data,
        })
            .then(resolve)
            .catch(reject)
    })
