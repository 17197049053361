import moment from 'moment'
import {
    getCommunities,
    getDeliveryTemplates,
    getSingleDeliveryTemplates,
} from '../../network/API'
import {
    getProductsBasic,
    getStocksWithProductDetails,
} from '../../network/Products'
import { getStocks } from '../../network/API'
import { numberToLetter } from '../../utils/utils'
import set from 'lodash.set'
import { Typography } from 'antd'

const { Text } = Typography

const Settings = {
    key: [],
    sections: [
        {
            columns: 8,
            fields: [
                {
                    label: 'OFFERS_OFFER_NAME',
                    suffixLabel: 'internal',
                    key: ['internalName'],
                    isRequired: true,
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'COMMUNITY_COMMUNITY',
                    key: ['community'],
                    isRequired: true,
                    type: 'select',
                    requestOption: getCommunities,
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFERS_OFFER_ID',
                    key: ['offerID'],
                    isInactive: true,
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_CHANNEL',
                    key: ['channel'],
                    isRequired: true,
                    type: 'select',
                    options: [
                        { label: 'OFFERS_ALL', key: 'all' },
                        {
                            label: 'OFFER_LIVE_WECHAT_GROUP',
                            key: 'wechat_group',
                        },
                        { label: 'miniprogram', key: 'miniprogram' },
                    ],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_SHOW_DETAIL',
                    key: ['showDetail'],
                    // isRequired: true,
                    type: 'select',
                    default: 'hide',
                    options: [
                        { label: 'CREATE_OFFER_SHOW', key: 'show' },
                        { label: 'CREATE_OFFER_HIDE', key: 'hide' },
                    ],
                    pos: {
                        col: 5,
                        row: 1,
                        size: 1,
                    },
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue !== 'wechat_group',
                    staticParentKey: ['channel'],
                },
                // ],
            ],
        },
    ],
}

const Description = {
    title: 'ROLES_DESCRIPTION',
    key: [],
    sections: [
        {
            key: [],
            columns: 6,
            fields: [
                {
                    label: 'CREATE_OFFER_PUBLIC_NAME_ENGLISH',
                    key: ['name', 'en'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'CREATE_OFFER_PUBLIC_NAME_CHINESE',
                    key: ['name', 'zh'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'CREATE_OFFER_GHOST_ID',
                    key: ['ghostID'],
                    field: 'offerID',
                    // type: 'search&update',
                    // request: getSimpleOffers,
                    // cleanResponse: (data) => {
                    //     const times = (n) => (f) => {
                    //         let iter = (i) => {
                    //             if (i === n) return
                    //             f(i)
                    //             iter(i + 1)
                    //         }
                    //         return iter(0)
                    //     }

                    //     let clipboard = []
                    //     times(10)((i) => {
                    //         clipboard.push(
                    //             i < data.clipboard.length
                    //                 ? data.clipboard[i]
                    //                 : {
                    //                       shortcut: undefined,
                    //                       text: undefined,
                    //                   }
                    //         )
                    //     })
                    //     data.clipboard = clipboard

                    //     data.startingDate = moment(new Date(data.startingDate))
                    //     data.endingDate = moment(new Date(data.startingDate))

                    //     return data
                    // },
                    pos: {
                        col: 5,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'ROLES_DESCRIPTION',
                    suffixLabel: 'English',
                    key: ['description', 'en'],
                    type: 'text',
                    pos: {
                        col: 1,
                        row: 2,
                        size: 3,
                    },
                },
                {
                    label: 'ROLES_DESCRIPTION',
                    suffixLabel: '中文',
                    key: ['description', 'zh'],
                    type: 'text',
                    pos: {
                        col: 4,
                        row: 2,
                        size: 3,
                    },
                },
                {
                    label: 'CREATE_OFFER_CARD_OFFER_IMG_EN',
                    // suffixLabel: 'English',
                    key: ['banners', 'en'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    requestURI: '/files/offer-banner',
                    storageURI: '/storage/offer-banner',
                    type: 'multiupload',
                    isConditional: true,
                    staticParentKey: ['channel'],
                    displayCondition: (parentValue) => {
                        return parentValue !== 'wechat_group'
                    },
                    pos: {
                        col: 1,
                        row: 3,
                        size: 3,
                    },
                },
                {
                    label: 'CREATE_OFFER_CARD_OFFER_IMG_CN',
                    // suffixLabel: '中文',
                    key: ['banners', 'zh'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    requestURI: '/files/offer-banner',
                    storageURI: '/storage/offer-banner',
                    type: 'multiupload',
                    isConditional: true,
                    staticParentKey: ['channel'],
                    displayCondition: (parentValue) => {
                        return parentValue !== 'wechat_group'
                    },
                    pos: {
                        col: 4,
                        row: 3,
                        size: 3,
                    },
                },
                // {
                //     label: 'CREATE_OFFER_OFFER_PAGE_IMAGES',
                //     key: ['media'],
                //     btn_label: 'CREATE_OFFER_UPLOAD',
                //     requestURI: '/files/offer-media',
                //     storageURI: '/storage/offer-media',
                //     type: 'multiupload',
                //     isConditional: true,
                //     staticParentKey: ['channel'],
                //     displayCondition: (parentValue) => {
                //         if (parentValue === 'wechat_group') {
                //             return false
                //         } else {
                //             return true
                //         }
                //     },
                //     pos: {
                //         col: 1,
                //         row: 4,
                //         size: 3,
                //     },
                // },
                {
                    label: 'CREATE_OFFER_PAGE_IMG_EN',
                    key: ['medias', 'en'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    requestURI: '/files/offer-media',
                    storageURI: '/storage/offer-media',
                    type: 'multiupload',
                    isConditional: true,
                    staticParentKey: ['channel'],
                    displayCondition: (parentValue) => {
                        return parentValue !== 'wechat_group'
                    },
                    pos: {
                        col: 1,
                        row: 4,
                        size: 3,
                    },
                },
                {
                    label: 'CREATE_OFFER_PAGE_IMG_CN',
                    key: ['medias', 'zh'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    requestURI: '/files/offer-media',
                    storageURI: '/storage/offer-media',
                    type: 'multiupload',
                    isConditional: true,
                    staticParentKey: ['channel'],
                    displayCondition: (parentValue) => {
                        return parentValue !== 'wechat_group'
                    },
                    pos: {
                        col: 4,
                        row: 4,
                        size: 3,
                    },
                },
            ],
        },
    ],
}

const ClipboardForm = {
    title: 'CREATE_OFFER_CLIPBOARD',
    key: [],
    sections: [
        {
            isList: true,
            addLabelNb: true,
            length: 10,
            columns: 5,
            key: ['clipboard'],
            fields: [
                {
                    label: 'CREATE_OFFER_SHORTCUT',
                    key: ['shortcut'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_SHORTCUT_TEXT',
                    key: ['text'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 4,
                    },
                },
            ],
        },
    ],
}

const AddSelectionForm = {
    title: 'CREATE_OFFER_ADD_SELECTION',
    key: [],
    sections: [
        {
            canAdd: true,
            key: ['packs'],
            addSuffix: 'letter',
            columns: 5,
            title: 'OFFER_LIVE_SELECTION',
            deleteBtn: {
                label: 'CREATE_OFFER_DELETE_SELECTION',
                style: 'title',
                pos: {
                    col: 6,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: {
                label: 'CREATE_OFFER_ADD_SELECTION',
                style: 'primary',
            },
            fields: [
                // {
                //     label: 'Illustation',
                //     key: ['illustation'],
                //     btn_label: 'CREATE_OFFER_UPLOAD',
                //     requestURI: '/files/pack-illustration',
                //     storageURI: '/storage/pack-illustration',
                //     type: 'upload',
                //     pos: {
                //         col: 1,
                //         row: 1,
                //         size: 3,
                //     },
                // },
                {
                    label: 'CREATE_OFFER_THUMBNAIL_EN',
                    suffixLabel: 'English',
                    type: 'upload',
                    requestURI: '/files/pack-illustration',
                    storageURI: '/storage/pack-illustration',
                    isConditional: true,
                    getPrevFirstElement: true,
                    displayCondition: (parentValue) => {
                        return parentValue === 'miniprogram'
                    },
                    key: ['mainPicture', 'en'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_THUMBNAIL_PAYMENT',
                    type: 'upload',
                    requestURI: '/files/pack-illustration',
                    storageURI: '/storage/pack-illustration',
                    isConditional: true,
                    getPrevFirstElement: true,
                    displayCondition: (parentValue) => {
                        return parentValue !== 'miniprogram'
                    },
                    key: ['mainPicture', 'en'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_THUMBNAIL_CN',
                    suffixLabel: '中文',
                    type: 'upload',
                    requestURI: '/files/pack-illustration',
                    storageURI: '/storage/pack-illustration',
                    isConditional: true,
                    getPrevFirstElement: true,
                    displayCondition: (parentValue) => {
                        return parentValue === 'miniprogram'
                    },
                    key: ['mainPicture', 'zh'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_PACK_PICTURES',
                    type: 'multiupload',
                    requestURI: '/files/pack-illustration',
                    storageURI: '/storage/pack-illustration',
                    isConditional: true,
                    getPrevFirstElement: true,
                    displayCondition: (parentValue) => {
                        return parentValue === 'miniprogram'
                    },
                    key: ['otherMedia'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_ENGLISH_NAME',
                    autoFocus: true,
                    key: ['name', 'en'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_CHINESE_NAME',
                    key: ['name', 'zh'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_STOCK_AVAILABLE',
                    type: 'number',
                    isRequired: true,
                    isInt: true,
                    key: ['stock'],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_ACTUAL_PRICE',
                    suffixLabel: '¥',
                    isRequired: true,
                    type: 'number',
                    key: ['price'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_CROSSED_ORIGINAL_PRICE',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['formerPrice'],
                    pos: {
                        col: 2,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_TARGET_SALES',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['targetSales'],
                    pos: {
                        col: 3,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_PRICE_DROP',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['freeFall', 'dropAmount'],
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'free_fall',
                    staticParentKey: ['type'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_PER_ITEMS_SOLD',
                    type: 'number',
                    isInt: true,
                    key: ['freeFall', 'quantityTrigger'],
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'free_fall',
                    staticParentKey: ['type'],
                    pos: {
                        col: 2,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_LOWEST_PRICE_LIMIT',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['freeFall', 'lowestPrice'],
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'free_fall',
                    staticParentKey: ['type'],
                    pos: {
                        col: 3,
                        row: 4,
                        size: 1,
                    },
                },
            ],
            sections: [
                {
                    canAdd: true,
                    key: ['products'],
                    noDivider: true,
                    columns: 5,
                    deleteBtn: {
                        label: 'ROLES_DELETE',
                        style: 'line',
                        pos: {
                            col: 4,
                            row: 1,
                            size: 1,
                        },
                    },
                    addBtn: {
                        label: 'CREATE_OFFER_ADD_PRODUCT',
                        style: 'dashed',
                    },
                    fields: [
                        {
                            label: 'CREATE_OFFER_SELECT_PRODUCT',
                            key: ['product'],
                            autoFocus: true,
                            isRequired: true,
                            type: 'select&update',
                            options: getProductsBasic,
                            renderOption: (product, lang, t) => (
                                <Text>
                                    {`${t(product?.name?.en || '')} `}
                                    <Text strong type="secondary">{`(${
                                        (product?.stock?.stock || 0) +
                                        (product?.stock?.freeStock || 0)
                                    } in stock)`}</Text>
                                </Text>
                            ),
                            update: getStocksWithProductDetails,
                            updateKey: 'productId',
                            formatOnRecieved: (
                                oldData,
                                fieldKey,
                                form,
                                channel,
                                prev
                            ) => {
                                const flatChannel = channel[0]
                                let values = form.getFieldValue(flatChannel)

                                set(
                                    values,
                                    `packs.${prev[2]}.products.${prev[4]}.weight`,
                                    oldData.list?.weight
                                )
                                set(
                                    values,
                                    `packs.${prev[2]}.products.${prev[4]}.weightType`,
                                    oldData.list?.weightType
                                )

                                return {
                                    [flatChannel]: values,
                                }
                            },
                            pos: {
                                col: 1,
                                row: 1,
                                size: 3,
                            },
                        },
                        {
                            label: 'CREATE_OFFER_QUANTITY',
                            type: 'number',
                            isRequired: true,
                            isInt: true,
                            key: ['quantity'],
                            pos: {
                                col: 1,
                                row: 2,
                                size: 1,
                            },
                        },
                        {
                            // suffixLabel: 'g',
                            label: 'CREATE_OFFER_WEIGHT_PER_UNIT',
                            type: 'number',
                            isInt: true,
                            key: ['weight'],
                            pos: {
                                col: 2,
                                row: 2,
                                size: 1,
                            },
                        },
                        {
                            label: 'CREATE_OFFER_UNIT',
                            key: ['weightType'],
                            isRequired: true,
                            type: 'select',
                            options: [
                                { label: 'CREATE_OFFER_GRAM', key: 'g' },
                                { label: 'CREATE_OFFER_ML', key: 'ml' },
                                { label: 'CREATE_OFFER_PIECES', key: 'piece' },
                            ],
                            pos: {
                                col: 3,
                                row: 2,
                                size: 1,
                            },
                        },
                    ],
                },
            ],
        },
    ],
}

const AddSingleItem = {
    title: 'CREATE_OFFER_ADD_SINGLE_ITEM',
    key: [],
    sections: [
        {
            canAdd: true,
            key: ['items'],
            addSuffix: 'letter',
            contextualSuffix: ['packs'],
            columns: 5,
            title: 'CREATE_OFFER_ADD_ON',
            deleteBtn: {
                label: 'CREATE_OFFER_DELETE_SINGLE_ITEM',
                style: 'title',
            },
            addBtn: {
                label: 'CREATE_OFFER_ADD_SINGLE_ITEM',
                style: 'primary',
            },
            fields: [
                {
                    label: 'CREATE_OFFER_SELECT_PRODUCT',
                    key: ['product'],
                    type: 'select&update',
                    options: getProductsBasic,
                    renderOption: (product, lang, t) => (
                        <Text>
                            {`${t(product?.name?.en || '')} `}
                            <Text strong type="secondary">{`(${
                                (product?.stock?.stock || 0) +
                                (product?.stock?.freeStock || 0)
                            } in stock)`}</Text>
                        </Text>
                    ),
                    update: getStocksWithProductDetails,
                    updateKey: 'productId',
                    formatOnRecieved: (oldData, fieldKey, form, channel) => {
                        console.log('kkkk1')
                        const stock = oldData?.list?.stock || 0
                        const freeStock = oldData?.list?.freeStock || 0
                        console.log('kkkk2')

                        const total = stock + freeStock

                        const flatChannel = channel[0]
                        let values = form.getFieldValue(flatChannel)

                        set(values, `items.${fieldKey[0]}.stock`, total)

                        set(
                            values,
                            `items.${fieldKey[0]}.weight`,
                            oldData.list?.weight
                        )
                        set(
                            values,
                            `items.${fieldKey[0]}.weightType`,
                            oldData.list?.weightType
                        )

                        return {
                            [flatChannel]: values,
                        }
                    },
                    pos: {
                        col: 1,
                        row: 1,
                        size: 3,
                    },
                },
                {
                    label: 'CREATE_OFFER_QUANTITY',
                    type: 'number',
                    isInt: true,
                    isRequired: true,
                    key: ['quantity'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_WEIGHT_PER_UNIT',
                    // suffixLabel: 'g',
                    type: 'number',
                    isInt: true,
                    key: ['weight'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },

                {
                    label: 'CREATE_OFFER_UNIT',
                    key: ['weightType'],
                    isRequired: true,
                    type: 'select',
                    options: [
                        { label: 'CREATE_OFFER_GRAM', key: 'g' },
                        { label: 'CREATE_OFFER_ML', key: 'ml' },
                        { label: 'CREATE_OFFER_PIECES', key: 'piece' },
                    ],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_STOCK_AVAILABLE',
                    type: 'number',
                    isRequired: true,
                    isInt: true,
                    key: ['stock'],
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_PRICE',
                    suffixLabel: '¥',
                    isRequired: true,
                    type: 'number',
                    key: ['price'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_CROSSED_ORIGINAL_PRICE',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['formerPrice'],
                    pos: {
                        col: 2,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_TARGET_SALES',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['targetSales'],
                    pos: {
                        col: 3,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_PRICE_DROP',
                    suffixLabel: '¥',
                    key: ['freeFall', 'dropAmount'],
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'free_fall',
                    staticParentKey: ['type'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_PER_ITEMS_SOLD',
                    key: ['freeFall', 'quantityTrigger'],
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'free_fall',
                    staticParentKey: ['type'],
                    pos: {
                        col: 2,
                        row: 4,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_LOWEST_PRICE_LIMIT',
                    suffixLabel: '¥',
                    key: ['freeFall', 'lowestPrice'],
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'free_fall',
                    staticParentKey: ['type'],
                    pos: {
                        col: 3,
                        row: 4,
                        size: 1,
                    },
                },
            ],
            sections: [
                {
                    canAdd: true,
                    addOnCondition: (parentValue) =>
                        parentValue === 'multiple_items',
                    staticParentKey: ['type'],
                    key: ['multipleItem'],
                    noDivider: true,
                    columns: 5,
                    title: '',
                    deleteBtn: {
                        label: 'ROLES_DELETE',
                        style: 'line',
                        pos: {
                            col: 3,
                            row: 1,
                            size: 1,
                        },
                    },
                    addBtn: {
                        label: 'CREATE_OFFER_ADD_RULE',
                        style: 'dashed',
                    },
                    fields: [
                        {
                            label: 'CREATE_OFFER_ITEMS_QTY',
                            type: 'number',
                            autoFocus: true,
                            isInt: true,
                            key: ['quantity'],
                            pos: {
                                col: 1,
                                row: 1,
                                size: 1,
                            },
                        },
                        {
                            label: 'CREATE_OFFER_UNIT_PRICE',
                            suffixLabel: '¥',
                            type: 'number',
                            key: ['unitPrice'],
                            pos: {
                                col: 2,
                                row: 1,
                                size: 1,
                            },
                        },
                    ],
                },
            ],
        },
    ],
}

const BourseRule = {
    title: 'CREATE_OFFER_BOURSE_RULE',
    key: [],
    sections: [
        {
            canAdd: true,
            key: ['bourses'],
            noDivider: true,
            columns: 5,
            deleteBtn: {
                label: 'CREATE_OFFER_DELETE_RULE',
                style: 'line',
                pos: {
                    col: 4,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: {
                label: 'CREATE_OFFER_ADD_RULE',
                style: 'dashed',
            },
            fields: [
                {
                    label: 'CREATE_OFFER_FROM',
                    // suffixLabel: 'Qty',
                    autoFocus: true,
                    type: 'number',
                    isInt: true,
                    key: ['from'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_TO',
                    // suffixLabel: 'Qty',
                    type: 'number',
                    isInt: true,
                    key: ['to'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_PRICE',
                    suffixLabel: '¥',
                    isRequired: true,
                    type: 'number',
                    key: ['unitPrice'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const ZokiSpecials = {
    title: 'CREATE_OFFER_ZIKO_SPECIALS',
    key: [],
    sections: [
        {
            canAdd: true,
            key: ['zikoSpecials'],
            noDivider: true,
            columns: 5,
            addBtn: {
                label: 'CREATE_OFFER_ADD_SPECIAL',
                style: 'primary',
            },
            deleteBtn: {
                label: 'ZIKO_SPECIALS_DELETE_SPECIAL',
                style: 'line',
                pos: {
                    col: 5,
                    row: 1,
                    size: 1,
                },
            },
            fields: [
                {
                    label: 'ZIKO_SPECIALS_SPECIAL',
                    key: ['conditionType'],
                    autoFocus: true,
                    isRequired: true,
                    type: 'select',
                    options: [
                        {
                            label: 'ZIKO_SPECIALS_FIRST_ORDER',
                            key: 'first_order',
                        },
                        {
                            label: 'ZIKO_SPECIALS_NUMBER_OF_ORDERS_REACH',
                            key: 'number_of_order',
                        },
                        {
                            label: 'ZIKO_SPECIALS_OFER_FOR_AMOUNT_GET',
                            key: 'order_for_amount',
                        },
                        {
                            label: 'ZIKO_SPECIALS_X_TOTAL_SOLD_ITEMS',
                            key: 'x_total_sold_items',
                        },
                        {
                            label: 'ZIKO_SPECIALS_ITEM_X_IN_CART',
                            key: 'item_x_in_cart',
                        },
                    ],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },

                {
                    label: 'ZIKO_SPECIALS_AMOUNT_QUANTITY',
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'first_order' ||
                        parentValue === 'number_of_order' ||
                        parentValue === 'x_total_sold_items' ||
                        !parentValue,
                    parentKey: ['conditionType'],
                    type: 'number',
                    isInt: true,
                    isRequired: true,
                    key: ['conditionValue'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_HOW_MUCH',
                    suffixLabel: '¥',
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'order_for_amount',
                    parentKey: ['conditionType'],
                    type: 'number',
                    key: ['conditionValue'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'Item',
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'item_x_in_cart',
                    parentKey: ['conditionType'],
                    type: 'allPackList',
                    key: ['conditionPack'],
                    options: [],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },

                {
                    label: 'ZIKO_SPECIALS_GET',
                    key: ['gift', 'type'],
                    isRequired: true,
                    type: 'select',
                    options: [
                        { label: 'ZIKO_SPECIALS_PACK', key: 'pack' },
                        { label: 'ZIKO_SPECIALS_ADD_ONS', key: 'add_on' },
                        { label: 'ZIKO_SPECIALS_CUSTOM', key: 'custom' },
                        { label: 'OFFER_LIVE_VOUCHER', key: 'voucher' },
                        { label: 'ZIKO_SPECIALS_DISCOUNT', key: 'discount' },
                        {
                            label: 'ZIKO_SPECIALS_FREE_DELIVERY',
                            key: 'free_delivery',
                        },
                    ],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },

                {
                    label: 'ZIKO_SPECIALS_PACK',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) => parentValue === 'pack',
                    parentKey: ['gift', 'type'],
                    type: 'packList',
                    key: ['gift', 'pack'],
                    options: [],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_ADD_ONS',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) => parentValue === 'add_on',
                    parentKey: ['gift', 'type'],
                    type: 'itemList',
                    key: ['gift', 'singleItem'],
                    options: [],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_NAME',
                    suffixLabel: '中文',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) => parentValue === 'custom',
                    parentKey: ['gift', 'type'],
                    key: ['gift', 'custom', 'zh'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_NAME',
                    suffixLabel: 'English',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) => parentValue === 'custom',
                    parentKey: ['gift', 'type'],
                    key: ['gift', 'custom', 'en'],
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },

                {
                    label: 'ZIKO_SPECIALS_ILLUSTRATION',
                    isConditional: true,
                    isRequired: true,
                    parentKey: ['gift', 'type'],
                    // displayCondition: (parentValue) =>
                    //     parentValue === 'custom' ? true : false,
                    getPrevFirstElement: true,
                    displayCondition: (parentValue, firstEle) => {
                        return (
                            parentValue === 'custom' &&
                            firstEle !== 'wechatGroup'
                        )
                    },
                    key: ['gift', 'customImage'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    requestURI: '/files/gift-image',
                    storageURI: '/storage/gift-image',
                    type: 'upload',
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'amount',
                    suffixLabel: '¥',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'voucher',
                    parentKey: ['gift', 'type'],
                    type: 'number',
                    key: ['gift', 'voucherValue'],
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_EXPIRATION_DATE',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'voucher',
                    parentKey: ['gift', 'type'],
                    type: 'date',
                    key: ['gift', 'voucherExpiration'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'amount',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'discount',
                    parentKey: ['gift', 'type'],
                    type: 'number',
                    max: 100,
                    key: ['gift', 'discountAmount'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Lottery = {
    title: 'lottery',
    isOptional: ['lotteryEnable'],
    key: ['lottery'],
    sections: [
        {
            title: 'ZIKO_SPECIALS_TICKETS',
            columns: 5,
            fields: [
                // {
                //     label: 'LOTTERY_MANAGEMENT_NUMBER_WINNERS',
                //     type: 'number',
                //     isInt: true,
                //     key: ['extraConditionValue'],
                //     pos: {
                //         col: 1,
                //         row: 1,
                //         size: 1,
                //     },
                // },
                {
                    label: 'ZIKO_SPECIALS_GET_ONE_TICKET_FOR',
                    key: ['conditionType'],
                    isRequired: true,
                    type: 'select',
                    options: [
                        {
                            label: 'ZIKO_SPECIALS_SPENT',
                            key: 'spend',
                        },
                        {
                            label: 'ZIKO_SPECIALS_BUY_ITEMS',
                            key: 'buy',
                        },
                        {
                            label: 'ZIKO_SPECIALS_ORDER',
                            key: 'order',
                        },
                    ],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'amount',
                    suffixLabel: '¥',
                    type: 'number',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'spend' || !parentValue,
                    staticParentKey: ['conditionType'],
                    key: ['conditionValue'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_ANALYTICS_VALUE',
                    // suffixLabel: '¥',
                    type: 'number',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'buy' || parentValue === 'order',
                    staticParentKey: ['conditionType'],
                    key: ['conditionValue'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                // {
                //     label: 'LOTTERY_MANAGEMENT_DRAW_NUMBER',
                //     type: 'number',
                //     isInt: true,
                //     isConditional: true,
                //     displayCondition: (parentValue) =>
                //         parentValue === 'buy' ? true : false,
                //     staticParentKey: ['conditionType'],
                //     key: ['conditionPackId'],
                //     pos: {
                //         col: 2,
                //         row: 2,
                //         size: 1,
                //     },
                // },
                {
                    label: 'ZIKO_SPECIALS_EXTRA_TICKET',
                    key: ['extraConditionType'],
                    type: 'select',
                    isConditional: true,
                    // isRequired: true,
                    displayCondition: (parentValue) => parentValue === 'buy',
                    staticParentKey: ['conditionType'],
                    options: [
                        {
                            label: 'ZIKO_SPECIALS_SPENT',
                            key: 'spend_for',
                        },
                        {
                            label: 'CREATE_OFFER_NONE',
                            key: null,
                        },
                    ],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_EXTRA_TICKET',
                    key: ['extraConditionType'],
                    type: 'select',
                    isConditional: true,
                    // isRequired: true,
                    displayCondition: (parentValue) => parentValue === 'spend',
                    staticParentKey: ['conditionType'],
                    options: [
                        {
                            label: 'CREATE_OFFER_BUY_ITEMS',
                            key: 'buy_for',
                        },
                        {
                            label: 'CREATE_OFFER_NONE',
                            key: null,
                        },
                    ],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_EXTRA_TICKET',
                    key: ['extraConditionType'],
                    type: 'select',
                    isConditional: true,
                    // isRequired: true,
                    displayCondition: (parentValue) => parentValue === 'order',
                    staticParentKey: ['conditionType'],
                    options: [
                        {
                            label: 'ZIKO_SPECIALS_BUY_ITEMS',
                            key: 'buy_for',
                        },
                        {
                            label: 'ZIKO_SPECIALS_SPENT',
                            key: 'spend_for',
                        },
                        {
                            label: 'CREATE_OFFER_NONE',
                            key: null,
                        },
                    ],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'amount',
                    suffixLabel: '¥',
                    type: 'number',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'spend_for',
                    staticParentKey: ['extraConditionType'],
                    key: ['extraConditionValue'],
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_ANALYTICS_VALUE',
                    // suffixLabel: '¥',
                    type: 'number',
                    isConditional: true,
                    isRequired: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'buy_for',
                    staticParentKey: ['extraConditionType'],
                    key: ['extraConditionValue'],
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
            ],
        },
        {
            canAdd: true,
            defaultOpen: true,
            title: 'ZIKO_SPECIALS_DRAW',
            key: ['draws'],
            addSuffix: 'number',
            columns: 5,
            deleteBtn: {
                label: 'ZIKO_SPECIALS_DELETE_DRAW',
                style: 'title',
            },
            addBtn: {
                label: 'ZIKO_SPECIALS_ADD_DRAW',
                style: 'primary',
            },
            fields: [
                {
                    label: 'ZIKO_SPECIALS_UNLOCK_CONDITION',
                    key: ['conditionType'],
                    autoFocus: true,
                    isRequired: true,
                    type: 'select',
                    options: [
                        {
                            label: 'ZIKO_SPECIALS_NUMBER_OF_ORDERS_REACH',
                            key: 'number_of_order',
                        },
                        {
                            label: 'ZIKO_SPECIALS_NUMBER_OF_ITEMS_REACH',
                            key: 'x_item_sold',
                        },
                    ],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_ANALYTICS_VALUE',
                    type: 'number',
                    isInt: true,
                    isRequired: true,
                    key: ['conditionValue'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
            sections: [
                {
                    canAdd: true,
                    key: ['gifts'],
                    noDivider: true,
                    columns: 5,
                    deleteBtn: {
                        label: 'ROLES_DELETE',
                        style: 'line',
                        pos: {
                            col: 5,
                            row: 1,
                            size: 1,
                        },
                    },
                    addBtn: {
                        label: 'ZIKO_SPECIALS_ADD_GIFT',
                        style: 'dashed',
                    },
                    fields: [
                        {
                            label: 'ZIKO_SPECIALS_GET',
                            key: ['type'],
                            autoFocus: true,
                            isRequired: true,
                            type: 'select',
                            options: [
                                { label: 'ZIKO_SPECIALS_PACK', key: 'pack' },
                                {
                                    label: 'ZIKO_SPECIALS_ADD_ONS',
                                    key: 'add_on',
                                },
                                {
                                    label: 'ZIKO_SPECIALS_CUSTOM',
                                    key: 'custom',
                                },
                                { label: 'OFFER_LIVE_VOUCHER', key: 'voucher' },
                                {
                                    label: 'ZIKO_SPECIALS_DISCOUNT',
                                    key: 'discount',
                                },
                                // {
                                //     label: 'Free Delivery',
                                //     key: 'free_delivery',
                                // },
                            ],
                            pos: {
                                col: 1,
                                row: 1,
                                size: 1,
                            },
                        },
                        // {
                        //     label: 'ZIKO_SPECIALS_NB_OF_WINNERS',
                        //     isConditional: true,
                        //     isRequired: true,
                        //     displayCondition: (parentValue) =>
                        //         parentValue === 'free_delivery' ? true : false,
                        //     parentKey: ['type'],
                        //     type: 'number',
                        //     isInt: true,
                        //     min: 1,
                        //     key: ['winnerNumber'],
                        //     pos: {
                        //         col: 2,
                        //         row: 1,
                        //         size: 1,
                        //     },
                        // },
                        {
                            label: 'ZIKO_SPECIALS_PACK',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'pack',
                            parentKey: ['type'],
                            type: 'packList',
                            key: ['pack'],
                            options: [],
                            pos: {
                                col: 2,
                                row: 1,
                                size: 1,
                            },
                        },
                        {
                            label: 'ZIKO_SPECIALS_ADD_ONS',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'add_on',
                            parentKey: ['type'],
                            type: 'itemList',
                            key: ['singleItem'],
                            options: [],
                            pos: {
                                col: 2,
                                row: 1,
                                size: 1,
                            },
                        },

                        {
                            label: 'ZIKO_SPECIALS_NB_OF_WINNERS',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'custom' ||
                                parentValue === 'pack' ||
                                parentValue === 'discount' ||
                                parentValue === 'add_on',
                            parentKey: ['type'],
                            type: 'number',
                            isInt: true,
                            min: 1,
                            key: ['winnerNumber'],
                            pos: {
                                col: 3,
                                row: 1,
                                size: 1,
                            },
                        },

                        {
                            label: 'ROLES_NAME',
                            suffixLabel: '中文',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'custom',
                            parentKey: ['type'],
                            key: ['custom', 'zh'],
                            pos: {
                                col: 2,
                                row: 1,
                                size: 1,
                            },
                        },
                        {
                            label: 'ROLES_NAME',
                            suffixLabel: 'English',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'custom',
                            parentKey: ['type'],
                            key: ['custom', 'en'],
                            pos: {
                                col: 2,
                                row: 2,
                                size: 1,
                            },
                        },
                        {
                            label: 'ZIKO_SPECIALS_ILLUSTRATION',
                            isConditional: true,
                            isRequired: true,
                            parentKey: ['type'],
                            // displayCondition: (parentValue) =>
                            //     parentValue === 'custom' ? true : false,
                            getPrevFirstElement: true,
                            displayCondition: (parentValue, firstEle) => {
                                return (
                                    parentValue === 'custom' &&
                                    firstEle !== 'wechatGroup'
                                )
                            },
                            key: ['customImage'],
                            btn_label: 'CREATE_OFFER_UPLOAD',
                            requestURI: '/files/gift-image',
                            storageURI: '/storage/gift-image',
                            type: 'upload',
                            pos: {
                                col: 1,
                                row: 2,
                                size: 1,
                            },
                        },
                        {
                            label: 'amount',
                            suffixLabel: '¥',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'voucher',
                            parentKey: ['type'],
                            type: 'number',
                            key: ['voucherValue'],
                            pos: {
                                col: 2,
                                row: 1,
                                size: 1,
                            },
                        },
                        {
                            label: 'ZIKO_SPECIALS_EXPIRATION_DATE',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'voucher',
                            parentKey: ['type'],
                            type: 'date',
                            key: ['voucherExpiration'],
                            pos: {
                                col: 3,
                                row: 1,
                                size: 1,
                            },
                        },

                        {
                            label: 'ZIKO_SPECIALS_NB_OF_WINNERS',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'voucher',
                            parentKey: ['type'],
                            type: 'number',
                            isInt: true,
                            min: 1,
                            key: ['winnerNumber'],
                            pos: {
                                col: 4,
                                row: 1,
                                size: 1,
                            },
                        },

                        {
                            label: 'amount',
                            isConditional: true,
                            isRequired: true,
                            displayCondition: (parentValue) =>
                                parentValue === 'discount',
                            parentKey: ['type'],
                            type: 'number',
                            key: ['discountAmount'],
                            max: 100,
                            pos: {
                                col: 2,
                                row: 1,
                                size: 1,
                            },
                        },
                    ],
                },
            ],
        },
    ],
}

const OfferDetails = {
    title: 'CREATE_OFFER_OFFER_DETAILS',
    key: [],
    sections: [
        {
            title: 'CREATE_OFFER_DURATION',
            columns: 4,
            fields: [
                {
                    label: 'CREATE_OFFER_STARTING_DATE',
                    key: ['startingDate'],
                    isRequired: true,
                    disabledDate: (date) => {
                        const dateNow = moment().set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                        })

                        const currentDate = moment(date).set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 1,
                        })

                        return dateNow > currentDate
                    },
                    type: 'date',
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_STARTING_TIME',
                    key: ['startingDate'],
                    disabledHours: (form) => {
                        const startValue = form.getFieldValue('startingDate')
                        const endValue = form.getFieldValue('endingDate')
                        const timeNow = moment().set({
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                        })

                        let disabledHoursList = []
                        for (let i = 0; i < 24; i++) {
                            if (!startValue) {
                                disabledHoursList.push(i)
                            } else {
                                const startDate = moment(startValue).set({
                                    hour: i,
                                    minute: 0,
                                    second: 0,
                                    millisecond: 0,
                                })
                                const endDate = moment(endValue).set({
                                    minute: 1,
                                    second: 0,
                                    millisecond: 0,
                                })

                                let date = moment(startValue).set({
                                    hour: i,
                                    minute: 1,
                                    second: 0,
                                    millisecond: 0,
                                })

                                if (
                                    timeNow > date ||
                                    (!!endValue && startDate >= endDate)
                                ) {
                                    disabledHoursList.push(i)
                                }
                            }
                        }

                        return disabledHoursList
                    },
                    disabledMinutes: (selectedHour, form) => {
                        const startValue = form.getFieldValue('startingDate')
                        const endValue = form.getFieldValue('endingDate')
                        const timeNow = moment().set({
                            second: 0,
                            millisecond: 0,
                        })

                        let disabledMinutesList = []
                        for (let i = 0; i < 60; i = i + 5) {
                            if (!startValue) {
                                disabledMinutesList.push(i)
                            } else {
                                const startDate = moment(startValue).set({
                                    hour: selectedHour,
                                    minute: i,
                                    second: 1,
                                    millisecond: 0,
                                })
                                const endDate = moment(endValue).set({
                                    second: 0,
                                    millisecond: 0,
                                })

                                let date = moment(startValue).set({
                                    hour: selectedHour,
                                    minute: i,
                                    second: 1,
                                    millisecond: 0,
                                })

                                if (
                                    timeNow > date ||
                                    (endValue && startDate >= endDate)
                                ) {
                                    disabledMinutesList.push(i)
                                }
                            }
                        }

                        return disabledMinutesList
                    },
                    isRequired: true,
                    type: 'time',
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_ENDING_DATE',
                    key: ['endingDate'],
                    isRequired: true,
                    disabledDate: (date, form) => {
                        const value = form.getFieldValue('startingDate')
                        if (!value) return true

                        const dateStart = moment(value).set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                        })

                        const currentDate = moment(date).set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 1,
                        })

                        return dateStart > currentDate
                    },
                    type: 'date',
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_ENDING_TIME',
                    key: ['endingDate'],
                    disabledHours: (form) => {
                        const startValue = form.getFieldValue('startingDate')
                        const endValue = form.getFieldValue('endingDate')

                        if (!startValue || !endValue)
                            return [
                                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                            ]

                        let disabledHoursList = []
                        for (let i = 0; i < 24; i++) {
                            const startDate = moment(startValue).set({
                                minute: 0,
                                second: 0,
                                millisecond: 0,
                            })
                            const endDate = moment(endValue).set({
                                hour: i,
                                minute: 1,
                                second: 0,
                                millisecond: 0,
                            })

                            if (startDate >= endDate) {
                                disabledHoursList.push(i)
                            }
                        }

                        return disabledHoursList
                    },
                    disabledMinutes: (selectedHour, form) => {
                        const startValue = form.getFieldValue('startingDate')
                        const endValue = form.getFieldValue('endingDate')

                        if (!startValue || !endValue)
                            return [
                                0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                            ]

                        let disabledMinutesList = []
                        for (let i = 0; i < 60; i = i + 5) {
                            const endDate = moment(endValue).set({
                                hour: selectedHour,
                                minute: i,
                                second: 0,
                                millisecond: 0,
                            })

                            if (startValue >= endDate) {
                                disabledMinutesList.push(i)
                            }
                        }

                        return disabledMinutesList
                    },
                    isRequired: true,
                    type: 'time',
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'CREATE_OFFER_PRICE_RULE',
            fields: [
                {
                    type: 'radio',
                    key: ['type'],
                    default: 'regular',
                    columns: 1,
                    options: [
                        {
                            label: 'CREATE_AN_OFFER_REGULAR',
                            key: 'regular',
                        },
                        {
                            label: 'CREATE_OFFER_FREE_FALL',
                            key: 'free_fall',
                        },
                        {
                            label: 'CREATE_OFFER_MULTIPLE_ITEMS',
                            key: 'multiple_items',
                        },
                        {
                            label: 'CREATE_OFFER_BOURSE',
                            key: 'bourse',
                        },
                        {
                            label: 'CREATE_OFFER_CLEARANCE',
                            key: 'cave',
                        },
                    ],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'CREATE_OFFER_OFFER_TARGETS',
            columns: 2,
            fields: [
                {
                    label: 'CREATE_OFFER_TARGET_SALES',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['targetSales'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_COMISSION_RATE',
                    suffixLabel: '%',
                    type: 'number',
                    key: ['commissionRate'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'CREATE_OFFER_MINIMUM_ORDERS',
            columns: 2,
            fields: [
                {
                    label: 'CREATE_OFFER_CART_AMOUNT',
                    suffixLabel: '¥',
                    type: 'number',
                    key: ['minimumOrderAmount'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CREATE_OFFER_MINIMUM_CART_ITEMS',
                    type: 'number',
                    isInt: true,
                    key: ['minimumCartItems'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'CREATE_OFFER_DELIVERY_TYPE',
            columns: 1,
            fields: [
                {
                    type: 'radio',
                    key: ['deliveryType'],
                    options: [
                        { label: 'CREATE_OFFER_MANUAL', key: 'manual' },
                        { label: 'CREATE_OFFER_SHUNFENG', key: 'shunfeng' },
                    ],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'MENU_DELIVERY_TEMPLATES',
            suffixLabel: '¥',
            columns: 2,
            fields: [
                {
                    key: ['feeTemplate'],
                    type: 'select&update',
                    options: getDeliveryTemplates,
                    update: getSingleDeliveryTemplates,
                    updateKey: 'templateId',
                    formatOnRecieved: (oldData) => {
                        return {
                            fees: oldData?.areas?.map((fee) => {
                                let newCities = []
                                let cities = { ...fee.area }
                                while (cities) {
                                    newCities.push(cities.id)
                                    cities = cities.parent
                                }

                                newCities.reverse()

                                return {
                                    ...fee,
                                    area: newCities,
                                }
                            }),
                        }
                    },
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
            sections: [
                {
                    canAdd: true,
                    key: ['fees'],
                    columns: 2,
                    addSuffix: 'number',
                    noDivider: true,
                    title: 'OFFER_LIVE_AREA',
                    deleteBtn: {
                        label: 'DELIVERY_TEMPLATES_DELETE_AREA',
                        style: 'title',
                    },
                    addBtn: {
                        label: 'CREATE_OFFER_ADD_AN_AREA',
                        style: 'primary',
                    },
                    fields: [
                        {
                            label: 'CREATE_OFFER_AREA_NAME',
                            key: ['area'],
                            autoFocus: true,
                            isRequired: true,
                            type: 'area',
                            pos: {
                                col: 1,
                                row: 1,
                                size: 1,
                            },
                        },
                    ],
                    sections: [
                        {
                            canAdd: true,
                            key: ['rules'],
                            defaultOpen: true,
                            columns: 4,
                            noDivider: true,
                            deleteBtn: {
                                label: 'ROLES_DELETE',
                                style: 'line',
                                pos: {
                                    col: 1,
                                    row: 2,
                                    size: 1,
                                },
                            },
                            addBtn: {
                                label: 'CREATE_OFFER_ADD_RULE',
                                style: 'dashed',
                            },
                            fields: [
                                {
                                    label: 'CREATE_OFFER_RULE',
                                    type: 'select',
                                    key: ['type'],
                                    // autoFocus: true,
                                    isRequired: true,
                                    options: [
                                        { key: 'flat', label: 'flat' },
                                        {
                                            key: 'up_to',
                                            label: 'up_to',
                                        },
                                        {
                                            key: 'free_after_quantity',
                                            label: 'free_after_quantity',
                                        },
                                        {
                                            key: 'free_after_value',
                                            label: 'free_after_value',
                                        },
                                    ],
                                    pos: {
                                        col: 1,
                                        row: 1,
                                        size: 2,
                                    },
                                },
                                {
                                    label: 'CREATE_OFFER_QUANTITY',
                                    isConditional: true,
                                    isRequired: true,
                                    parentKey: ['type'],
                                    displayCondition: (parentValue) =>
                                        parentValue === 'up_to' ||
                                        parentValue === 'free_after_quantity',
                                    key: ['quantity'],
                                    pos: {
                                        col: 3,
                                        row: 1,
                                        size: 1,
                                    },
                                },
                                {
                                    label: 'OFFER_LIVE_DELIVERY_FEE',
                                    suffixLabel: '¥',
                                    type: 'number',
                                    isConditional: true,
                                    isRequired: true,
                                    displayCondition: (parentValue) =>
                                        parentValue === 'flat',
                                    parentKey: ['type'],
                                    key: ['fee'],
                                    pos: {
                                        col: 3,
                                        row: 1,
                                        size: 1,
                                    },
                                },
                                {
                                    label: 'OFFER_LIVE_DELIVERY_FEE',
                                    suffixLabel: '¥',
                                    type: 'number',
                                    isConditional: true,
                                    isRequired: true,
                                    displayCondition: (parentValue) =>
                                        parentValue === 'up_to',
                                    parentKey: ['type'],
                                    key: ['fee'],
                                    pos: {
                                        col: 4,
                                        row: 1,
                                        size: 1,
                                    },
                                },
                                {
                                    label: 'CREATE_OFFER_ORDER_VALUE',
                                    isConditional: true,
                                    isRequired: true,
                                    displayCondition: (parentValue) =>
                                        parentValue === 'free_after_value',
                                    parentKey: ['type'],
                                    key: ['quantity'],
                                    pos: {
                                        row: 1,
                                        col: 3,
                                        size: 1,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'OFFER_LIVE_DELIVERY_DATE',
            fields: [
                // [
                {
                    type: 'multidate',
                    key: ['deliveryDates'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                // ],
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    if (oldData.channel === 'wechat_group') {
        oldData.miniprogram = null
    }
    if (oldData.channel === 'miniprogram') {
        oldData.wechatGroup = null
    }

    let newClipboard = []
    if (!oldData.clipboard) {
        oldData.clipboard = []
    }
    const savedClipboard = [...oldData.clipboard]

    for (const clip of savedClipboard) {
        if (clip?.shortcut || clip?.text) newClipboard.push(clip)
    }
    oldData.clipboard = newClipboard

    oldData.fees = oldData.fees?.map((fee) => ({
        ...fee,
        area: fee.area ? fee.area[fee.area.length - 1] : undefined,
    }))

    //format pictures
    if (oldData.banners?.en) {
        let mainEn = oldData.banners.en.fileList
            ? oldData.banners.en?.fileList
            : oldData.banners.en

        oldData.banners.en = mainEn.map((file) =>
            file.response ? file.response : file
        )
    } else {
        if (!oldData.banners) {
            oldData.banners = {}
        }
        oldData.banners.en = []
    }

    if (oldData.banners?.zh) {
        let mainZh = oldData.banners.zh.fileList
            ? oldData.banners.zh?.fileList
            : oldData.banners.zh

        oldData.banners.zh = mainZh.map((file) =>
            file.response ? file.response : file
        )
    } else {
        oldData.banners.zh = []
    }

    // if (oldData.media) {
    //     const otherPic = oldData.media.fileList
    //         ? oldData.media?.fileList
    //         : oldData.media
    //     oldData.media = otherPic.map((file) =>
    //         file.response ? file.response : file
    //     )
    // } else {
    //     oldData.media = []
    // }

    if (oldData.medias?.en) {
        let mainEn = oldData.medias.en.fileList
            ? oldData.medias.en?.fileList
            : oldData.medias.en

        oldData.medias.en = mainEn.map((file) =>
            file.response ? file.response : file
        )
    } else {
        if (!oldData.medias) {
            oldData.medias = {}
        }
        oldData.medias.en = []
    }
    if (oldData.medias?.zh) {
        let mainZh = oldData.medias.zh.fileList
            ? oldData.medias.zh?.fileList
            : oldData.medias.zh

        oldData.medias.zh = mainZh.map((file) =>
            file.response ? file.response : file
        )
    } else {
        oldData.medias.zh = []
    }

    if (oldData.wechatGroup) {
        let shortNameCountMP = 0
        if (oldData.wechatGroup.packs) {
            const newWXPacks = []
            for (const pack of oldData.wechatGroup.packs) {
                shortNameCountMP += 1

                // let illustation
                // if (pack.illustation) {
                //     illustation = pack.illustation.fileList
                //         ? pack.illustation?.fileList
                //         : pack.illustation
                //
                //     if (!Array.isArray(illustation)) {
                //         illustation = [illustation]
                //     }
                //     const newZh = illustation.map((file) =>
                //         file.response ? file.response : file
                //     )
                //
                //     illustation = newZh.length ? newZh[0] : {}
                // } else {
                //     illustation = {}
                // }

                if (pack.mainPicture?.en) {
                    let mainEn = pack.mainPicture?.en.fileList
                        ? pack.mainPicture.en?.fileList
                        : pack.mainPicture.en

                    if (!Array.isArray(mainEn)) {
                        mainEn = [mainEn]
                    }
                    const newEn = mainEn.map((file) =>
                        file.response ? file.response : file
                    )

                    pack.mainPicture.en = newEn.length ? newEn[0] : {}
                } else {
                    set(pack, 'mainPicture.en ', {})
                }

                if (pack.mainPicture.zh) {
                    let mainZh = pack.mainPicture.zh.fileList
                        ? pack.mainPicture.zh?.fileList
                        : pack.mainPicture.zh

                    if (!Array.isArray(mainZh)) {
                        mainZh = [mainZh]
                    }
                    const newZh = mainZh.map((file) =>
                        file.response ? file.response : file
                    )

                    pack.mainPicture.zh = newZh.length ? newZh[0] : {}
                } else {
                    pack.mainPicture.zh = {}
                }

                if (pack.otherMedia) {
                    const otherPic = pack.otherMedia.fileList
                        ? pack.otherMedia?.fileList
                        : pack.otherMedia
                    pack.otherMedia = otherPic.map((file) =>
                        file.response ? file.response : file
                    )
                } else {
                    pack.otherMedia = []
                }

                newWXPacks.push({
                    ...pack,
                    shortName: numberToLetter(shortNameCountMP - 1),
                    // illustation,
                    mainPicture: pack.mainPicture,
                    otherMedia: pack.otherMedia,
                })
            }
            oldData.wechatGroup.packs = newWXPacks
        }

        if (oldData.wechatGroup.items) {
            const newWXItems = []
            for (const item of oldData.wechatGroup.items) {
                shortNameCountMP += 1
                newWXItems.push({
                    ...item,
                    shortName: numberToLetter(shortNameCountMP - 1),
                })
            }
            oldData.wechatGroup.items = newWXItems
        }

        if (!oldData.wechatGroup.lotteryEnable) {
            oldData.wechatGroup.lotteryEnable = false
            oldData.wechatGroup.lottery = null
        } else {
            if (oldData.wechatGroup.lottery.draws) {
                const newMPDraws = []
                for (const draw of oldData.wechatGroup.lottery.draws) {
                    const newMPGifts = []

                    if (draw?.gifts?.length > 0) {
                        for (const gift of draw.gifts) {
                            let customImage = {}

                            // if (gift.type === 'custom') {
                            //     customImage = gift?.customImage?.fileList
                            //         ? gift.customImage?.fileList
                            //         : gift.customImage
                            //     if (!Array.isArray(customImage)) {
                            //         customImage = [customImage]
                            //     }
                            //     const newImg = customImage.map((file) =>
                            //         file.response ? file.response : file
                            //     )
                            //     customImage = newImg.length ? newImg[0] : {}
                            // }
                            newMPGifts.push({
                                ...gift,
                                customImage,
                            })
                        }
                    }

                    newMPDraws.push({ ...draw, gifts: newMPGifts })
                }
                oldData.wechatGroup.lottery.draws = newMPDraws
            }
        }

        if (oldData?.wechatGroup?.zikoSpecials) {
            const newWXSpecials = []
            for (const special of oldData.wechatGroup.zikoSpecials) {
                let customImage = {}
                console.log(special.gift)

                // if (special.gift && special.gift.type === 'custom') {
                //     customImage = special.gift?.customImage?.fileList
                //         ? special.gift.customImage?.fileList
                //         : special.gift.customImage
                //     if (!Array.isArray(customImage)) {
                //         customImage = [customImage]
                //     }
                //     const newImg = customImage.map((file) =>
                //         file.response ? file.response : file
                //     )
                //     customImage = newImg.length ? newImg[0] : {}
                // }

                newWXSpecials.push({
                    ...special,
                    gift: { ...special.gift, customImage },
                })
            }
            oldData.wechatGroup.zikoSpecials = newWXSpecials
        }
    }
    if (oldData.miniprogram) {
        let shortNameCountMP = 0
        const newWXPacks = []
        if (oldData.miniprogram.packs) {
            for (const pack of oldData.miniprogram.packs) {
                shortNameCountMP += 1

                // let illustation
                // if (pack.illustation) {
                //     illustation = pack.illustation.fileList
                //         ? pack.illustation?.fileList
                //         : pack.illustation
                //
                //     if (!Array.isArray(illustation)) {
                //         illustation = [illustation]
                //     }
                //     const newZh = illustation.map((file) =>
                //         file.response ? file.response : file
                //     )
                //
                //     illustation = newZh.length ? newZh[0] : {}
                // } else {
                //     illustation = {}
                // }

                if (pack.mainPicture?.en) {
                    let mainEn = pack.mainPicture?.en.fileList
                        ? pack.mainPicture.en?.fileList
                        : pack.mainPicture.en

                    if (!Array.isArray(mainEn)) {
                        mainEn = [mainEn]
                    }
                    const newEn = mainEn.map((file) =>
                        file.response ? file.response : file
                    )

                    pack.mainPicture.en = newEn.length ? newEn[0] : {}
                } else {
                    set(pack, 'mainPicture.en ', {})
                }

                if (pack.mainPicture.zh) {
                    let mainZh = pack.mainPicture.zh.fileList
                        ? pack.mainPicture.zh?.fileList
                        : pack.mainPicture.zh

                    if (!Array.isArray(mainZh)) {
                        mainZh = [mainZh]
                    }
                    const newZh = mainZh.map((file) =>
                        file.response ? file.response : file
                    )

                    pack.mainPicture.zh = newZh.length ? newZh[0] : {}
                } else {
                    pack.mainPicture.zh = {}
                }

                if (pack.otherMedia) {
                    const otherPic = pack.otherMedia.fileList
                        ? pack.otherMedia?.fileList
                        : pack.otherMedia
                    pack.otherMedia = otherPic.map((file) =>
                        file.response ? file.response : file
                    )
                } else {
                    pack.otherMedia = []
                }

                newWXPacks.push({
                    ...pack,
                    shortName: numberToLetter(shortNameCountMP - 1),
                    // illustation,
                    mainPicture: pack.mainPicture,
                    otherMedia: pack.otherMedia,
                })
            }
            oldData.miniprogram.packs = newWXPacks
        }

        if (oldData.miniprogram.items) {
            const newWXItems = []
            for (const item of oldData.miniprogram.items) {
                shortNameCountMP += 1
                newWXItems.push({
                    ...item,
                    shortName: numberToLetter(shortNameCountMP - 1),
                })
            }
            oldData.miniprogram.items = newWXItems
        }

        if (!oldData.miniprogram.lotteryEnable) {
            oldData.miniprogram.lotteryEnable = false
            oldData.miniprogram.lottery = null
        } else {
            if (oldData.miniprogram.lottery.draws) {
                const newMPDraws = []
                for (const draw of oldData.miniprogram.lottery.draws) {
                    const newMPGifts = []

                    if (draw?.gifts?.length > 0) {
                        for (const gift of draw?.gifts) {
                            let customImage = {}

                            if (gift.type === 'custom') {
                                customImage = gift?.customImage?.fileList
                                    ? gift.customImage?.fileList
                                    : gift.customImage
                                if (!Array.isArray(customImage)) {
                                    customImage = [customImage]
                                }
                                const newImg = customImage.map((file) =>
                                    file.response ? file.response : file
                                )
                                customImage = newImg.length ? newImg[0] : {}
                            }
                            newMPGifts.push({
                                ...gift,
                                customImage,
                            })
                        }
                    }

                    newMPDraws.push({ ...draw, gifts: newMPGifts })
                }
                oldData.miniprogram.lottery.draws = newMPDraws
            }
        }

        if (oldData.miniprogram?.zikoSpecials) {
            const newMPSpecials = []
            for (const special of oldData.miniprogram?.zikoSpecials) {
                let customImage = {}

                if (special.gift && special.gift.type === 'custom') {
                    customImage = special.gift?.customImage?.fileList
                        ? special.gift.customImage?.fileList
                        : special.gift.customImage
                    if (!Array.isArray(customImage)) {
                        customImage = [customImage]
                    }
                    const newImg = customImage.map((file) =>
                        file.response ? file.response : file
                    )
                    customImage = newImg.length ? newImg[0] : {}
                }

                newMPSpecials.push({
                    ...special,
                    gift: { ...special.gift, customImage },
                })
            }
            oldData.miniprogram.zikoSpecials = newMPSpecials
        }
    }

    return oldData
}

// NOT IN USE
// const checkValidity = (values) => {
//     if (
//         !values?.miniprogram?.packs?.length &&
//         !values?.miniprogram?.items?.length &&
//         !values?.wechatGroup?.packs?.length &&
//         !values?.wechatGroup?.items?.length
//     ) {
//         return 'Need a least one pack or item'
//     }

//     let isOkProductsInPack = true
//     values?.miniprogram?.packs?.forEach((e) =>
//         !e.products || !e.products.length ? (isOkProductsInPack = false) : null
//     )
//     values?.wechatGroup?.packs?.forEach((e) =>
//         !e.products || !e.products.length ? (isOkProductsInPack = false) : null
//     )
//     if (!isOkProductsInPack) {
//         return 'Selections need at least one item'
//     }

//     let isOkPrice = true
//     values?.miniprogram?.items?.forEach((e) =>
//         e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
//     )
//     values?.miniprogram?.packs?.forEach((e) =>
//         e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
//     )
//     values?.wechatGroup?.packs?.forEach((e) =>
//         e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
//     )
//     values?.wechatGroup?.items?.forEach((e) =>
//         e.formerPrice && e.formerPrice <= e.price ? (isOkPrice = false) : null
//     )
//     if (!isOkPrice) {
//         return 'Original price shouldn\'t be less than current price'
//     }

//     let isOkStock = true
//     values?.miniprogram?.items?.forEach((e) =>
//         e.stock < 0 ? (isOkStock = false) : null
//     )
//     values?.miniprogram?.packs?.forEach((e) =>
//         e.stock < 0 ? (isOkStock = false) : null
//     )
//     values?.wechatGroup?.packs?.forEach((e) =>
//         e.stock < 0 ? (isOkStock = false) : null
//     )
//     values?.wechatGroup?.items?.forEach((e) =>
//         e.stock < 0 ? (isOkStock = false) : null
//     )
//     if (!isOkStock) {
//         return 'Stock can not be empty'
//     }

//     if (!values.fees || !values.fees.length) {
//         return 'Need a least one area on delivery fees'
//     }
//     for (const fee of values.fees) {
//         if (!fee.rules || !fee.rules.length) {
//             return 'Rules need a least one rules on each fees area'
//         }
//     }
//     if (!values.deliveryDates || !values.deliveryDates.length) {
//         return 'Need a least one area on delivery date'
//     }

//     return false
// }

export {
    Settings,
    Description,
    ClipboardForm,
    AddSelectionForm,
    AddSingleItem,
    ZokiSpecials,
    Lottery,
    OfferDetails,
    BourseRule,
    cleanBeforeSending,
    // checkValidity,
}
