import { getProducts } from '../../network/Products'
import { getTags } from '../../network/API'
import i18next from 'i18next'

const Description = {
    title: 'ROLES_DESCRIPTION',
    key: [],
    sections: [
        {
            columns: 4,
            fields: [
                {
                    label: 'RECIPES_RECIPE_NAME',
                    suffixLabel: 'English',
                    isRequired: true,
                    key: ['name', 'en'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'RECIPES_RECIPE_NAME',
                    suffixLabel: '中文',
                    isRequired: true,
                    key: ['name', 'zh'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'RECIPES_SERVINGS',
                    type: 'number',
                    isInt: true,
                    key: ['serving'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'RECIPES_PREPARATION_TIME',
                    // suffixLabel: 'min',
                    isInt: true,
                    type: 'number',
                    isRequired: true,
                    key: ['preparationTime'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    // suffixLabel: 'min',
                    label: 'RECIPES_BAKING_TIME',
                    type: 'number',
                    isInt: true,
                    key: ['bakingTime'],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'RECIPES_CALORIES',
                    type: 'number',
                    isInt: true,
                    key: ['calories'],
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    type: 'richtext',
                    label: 'ROLES_DESCRIPTION',
                    suffixLabel: 'English',
                    isRequired: true,
                    key: ['description', 'en'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 4,
                    },
                },
                {
                    type: 'richtext',
                    label: 'ROLES_DESCRIPTION',
                    suffixLabel: '中文',
                    isRequired: true,
                    key: ['description', 'zh'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 4,
                    },
                },
            ],
        },
    ],
}

const Media = {
    title: 'RECIPES_MEDIA',
    key: [],
    sections: [
        {
            title: 'CREATE_OFFER_ADD_PICTURES',
            columns: 2,
            fields: [
                {
                    label: 'RECIPES_MAIN_PICTURE_EN',
                    suffixLabel: 'English',
                    isRequired: true,
                    type: 'upload',
                    requestURI: '/files/recipe-cover',
                    storageURI: '/storage/recipe-cover',
                    key: ['mainPicture', 'en'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'RECIPES_MAIN_PICTURE_CN',
                    suffixLabel: '中文',
                    isRequired: true,
                    type: 'upload',
                    requestURI: '/files/recipe-cover',
                    storageURI: '/storage/recipe-cover',
                    key: ['mainPicture', 'zh'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'RECIPES_OTHER_MEDIA',
            columns: 2,
            fields: [
                {
                    label: 'RECIPES_EXTENSION_ALLOWED',
                    type: 'multiupload',
                    requestURI: '/files/recipe-other-media',
                    storageURI: '/storage/recipe-other-media',
                    key: ['otherMedia'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const Tags = {
    title: 'RECIPES_TAGS',
    key: [],
    sections: [
        // {
        //     title: 'RECIPES_MEAL_DIET',
        //     columns: 1,
        //     fields: [
        //         {
        //             type: 'multicheck',
        //             requestOption: getTags,
        //             category: '60ebff531c65c1bbe3a75f31',
        //             key: ['tags_diet'],
        //         },
        //     ],
        // },
        // {
        //     title: 'Food allergy',
        //     columns: 1,
        //     fields: [
        //         {
        //             type: 'multicheck',
        //             requestOption: getTags,
        //             category: '60ebff711c65c1bbe3a75f32',
        //             key: ['tags_allergy'],
        //         },
        //     ],
        // },
        {
            title: 'RECIPES_MEAL_DIET',
            columns: 1,
            fields: [
                {
                    type: 'multicheck',
                    requestOption: getTags,
                    category: '62c2ccf257a3c8e2fdab7b13',
                    key: ['tags_meal'],
                },
            ],
        },
        {
            title: 'Food allergy',
            columns: 1,
            fields: [
                {
                    type: 'multicheck',
                    requestOption: getTags,
                    category: '62c2ccf257a3c8e2fdab7b14',
                    key: ['tags_allergy'],
                },
            ],
        },
        {
            title: 'CUISINE',
            columns: 1,
            fields: [
                {
                    type: 'multicheck',
                    requestOption: getTags,
                    category: '62c2ccf257a3c8e2fdab7b15',
                    key: ['tags_cuisine'],
                },
            ],
        },
        {
            title: 'TIME_LEVEL',
            columns: 1,
            fields: [
                {
                    type: 'multicheck',
                    requestOption: getTags,
                    category: '62c2ccf257a3c8e2fdab7b16',
                    key: ['tags_time'],
                },
            ],
        },
        {
            title: 'COMMUNITY',
            columns: 1,
            fields: [
                {
                    type: 'multicheck',
                    requestOption: getTags,
                    category: '62c2ccf257a3c8e2fdab7b17',
                    key: ['tags_community'],
                },
            ],
        },
        {
            title: 'INGREDIENTS',
            columns: 1,
            fields: [
                {
                    type: 'multicheck',
                    requestOption: getTags,
                    category: '62c2ccf257a3c8e2fdab7b18',
                    key: ['tags_ingredients'],
                },
            ],
        },
        {
            title: 'Diet',
            columns: 1,
            fields: [
                {
                    type: 'multicheck',
                    requestOption: getTags,
                    category: '62c2ccf257a3c8e2fdab7b19',
                    key: ['tags_diet'],
                },
            ],
        },
    ],
}

const RecipeDetails = {
    title: 'Recipe details',
    key: [],
    sections: [
        {
            canAdd: true,
            noDivider: true,
            mainTitle: 'Link product',
            key: ['products'],
            columns: 4,
            deleteBtn: {
                label: 'Delete link',
                style: 'line',
                pos: {
                    col: 4,
                    row: 1,
                    size: 1,
                },
            },
            addBtn: { label: 'Add more', style: 'dashed' },
            fields: [
                {
                    type: 'select',
                    label: 'CREATE_OFFER_SELECT_PRODUCT',
                    requestOption: getProducts,
                    renderOption: (e) =>
                        `${e?.name[i18next.language]} (${e?.supplier?.name})`,
                    key: [],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 3,
                    },
                },
            ],
        },
        {
            title: 'Pin on the top',
            columns: 1,
            fields: [
                {
                    type: 'basicRadio',
                    default: false,
                    options: [
                        { label: 'OFFER_LIVE_YES', key: true },
                        { label: 'OFFER_LIVE_NO', key: false },
                    ],
                    key: ['pinTop'],
                },
            ],
        },
        {
            title: 'Publication',
            columns: 1,
            fields: [
                {
                    type: 'basicRadio',
                    default: 'available',
                    options: [
                        { label: 'RECIPES_PUBLISHED', key: 'available' },
                        { label: 'RECIPES_NOT_PUBLISHED', key: 'discontinued' },
                    ],
                    key: ['status'],
                },
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    if (!oldData.pinTop) {
        oldData.pinTop = false
    }

    if (oldData.mainPicture.en) {
        let mainEn = oldData.mainPicture.en.fileList
            ? oldData.mainPicture.en?.fileList
            : oldData.mainPicture.en

        if (!Array.isArray(mainEn)) {
            mainEn = [mainEn]
        }
        const newEn = mainEn.map((file) =>
            file.response ? file.response : file
        )

        oldData.mainPicture.en = newEn.length ? newEn[0] : {}
    } else {
        oldData.mainPicture.en = {}
    }

    if (oldData.mainPicture.zh) {
        let mainZh = oldData.mainPicture.zh.fileList
            ? oldData.mainPicture.zh?.fileList
            : oldData.mainPicture.zh

        if (!Array.isArray(mainZh)) {
            mainZh = [mainZh]
        }
        const newZh = mainZh.map((file) =>
            file.response ? file.response : file
        )

        oldData.mainPicture.zh = newZh.length ? newZh[0] : {}
    } else {
        oldData.mainPicture.zh = {}
    }

    if (oldData.otherMedia) {
        const otherPic = oldData.otherMedia.fileList
            ? oldData.otherMedia?.fileList
            : oldData.otherMedia
        oldData.otherMedia = otherPic.map((file) =>
            file.response ? file.response : file
        )
    } else {
        oldData.otherMedia = []
    }

    const tags = [
        'tags_meal',
        'tags_allergy',
        'tags_cuisine',
        'tags_time',
        'tags_community',
        'tags_ingredients',
        'tags_diet',
    ]

    let newTags = []

    for (const keyTag of tags) {
        if (oldData[keyTag]) newTags = [...newTags, ...new Set(oldData[keyTag])]
    }

    return { ...oldData, tags: newTags }
}

export { Description, Media, Tags, RecipeDetails, cleanBeforeSending }
