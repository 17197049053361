import { useContext } from 'react'
import { useQuery } from 'react-query'
import moment from 'moment'
import { formatDate } from '../../utils/utils'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import { Form } from 'antd'

import {
    GeneralInformation,
    ClaimDetailRefund,
    cleanBeforeSending,
    // checkValidity,
    checkValidityRefund,
} from '../../constants/forms/Claims'
import { CreateClaim as request, getOrders } from '../../network/API'
import { AppContext } from 'src/context/AppContext'

const CreateClaim = ({ history }) => {
    const [form] = Form.useForm()
    const { user, token } = useContext(AppContext)
    const orderId = history?.location?.order

    const orderDetails = useQuery(
        [
            'orders',
            {
                id: orderId,
                token: token.token,
            },
        ],
        getOrders,
        {
            enabled: !!orderId,
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (order) => {
        if (!orderId || !order) return {}

        let detail = ''
        for (const pack of order.packs) {
            detail += pack.amount
                ? `${pack.amount} Pack ${pack.shortName},`
                : ''
        }

        for (const item of order.singleItems) {
            detail += item.amount
                ? `${item.amount} Single Item ${item.shortName},`
                : ''
        }

        const values = {
            teamMember: user.id,
            status: 'new',
            // order: oldData.order?.id,
            // customer: oldData.customer?.id,
            // community: oldData.community?.id,
            // offer: oldData.offer?.id,
            // supplier: oldData.supplier?.id,
            // orderInfos: {
            //     orderID: oldData.order?.orderID,
            //     community: oldData.community?.name,
            //     customer:
            //         oldData.customer?.name ||
            //         oldData.order.customerDaily?.customerName,
            //     supplier: oldData.supplier?.name,
            //     offer: oldData.offer?.internalName,
            //     date: moment(oldData.createdAt),
            //     orderDate: moment(oldData.createdAt),
            //     totalAmount: oldData.order?.actualAmount,
            //     city: oldData.order?.customerAddress?.city,
            //     detailedAddress:
            //         oldData.order?.customerAddress?.detailedAddress,
            //     phone: oldData.order?.customerAddress?.phone,
            //     deliveryTrackingID: oldData.order?.deliveryTrackingID,
            //     deliveryDate:
            //         oldData.order?.deliveryDate &&
            //         moment(oldData.order?.deliveryDate).isValid()
            //             ? formatDate(oldData.order?.deliveryDate)
            //             : oldData.order?.deliveryDate,
            //     detail: detail,
            // },
            // resp: oldData.resp,
            // issue: oldData.issue,
            // step: oldData.step,
            // action: oldData.action,
            // actionETA: moment(oldData.actionETA),
            // comment: oldData.comment,
            // issueDetail: oldData.issueDetail,

            order: order.id,
            customer: order.customer?.id,
            community: order.community?.id,
            offer: order.offer?.id,
            supplier: order.supplier?.id,
            orderInfos: {
                orderID: order.orderID,
                community: order.community?.name,
                customer:
                    order.customer?.name || order.customerDaily?.customerName,
                supplier: order.supplier?.name,
                offer: order.offer?.internalName,
                // date: moment(),
                orderDate: moment(order.createdAt),
                totalAmount: Math.round(order.actualAmount),
                city: order.customerAddress?.city,
                detailedAddress: order.customerAddress?.detailedAddress,
                phone: order.customerAddress?.phone,
                deliveryTrackingID: order.deliveryTrackingID,
                deliveryDate: moment(order.deliveryDate).isValid()
                    ? formatDate(order.deliveryDate)
                    : order.deliveryDate,
                detail: detail,
                paymentMethod: order.paymentMethod,
            },
        }

        return values
    }

    return (
        <PageWrap goBack title="CUSTOMER_SERVICE_CREATE_CLAIM">
            {orderDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    form={form}
                    title="create_claim"
                    request={request}
                    data={formatData(orderDetails?.data?.list[0])}
                    onSuccess={'/claims'}
                    onCancel={'/claims'}
                    invalidate={['getClaims', 'getClaimsStats']}
                    cleanBeforeSending={cleanBeforeSending}
                    checkValidity={checkValidityRefund}
                >
                    <div className="SectionVerticalWrap">
                        <FormBlock
                            form={form}
                            formTemplate={GeneralInformation}
                        />
                        <FormBlock formTemplate={ClaimDetailRefund} />
                    </div>
                    {/* <FormBlock formTemplate={GeneralInformation} /> */}
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default CreateClaim
