import { useContext } from 'react'
import { List, Typography } from 'antd'
import moment from 'moment'
import { getCommunities } from '../network/API'
import { useQuery } from 'react-query'

import { AppContext } from '../context/AppContext'

const Welcome = () => {
    const { token, user } = useContext(AppContext)
    const communities = useQuery(
        ['getCommunities', { token: token.token }],
        getCommunities
    )

    return (
        <>
            <List style={{ margin: 24 }}>
                <List.Item>
                    <Typography.Text>{`Name: ${user.name}`}</Typography.Text>
                </List.Item>
                <List.Item>
                    <Typography.Text>{`Email: ${user.email}`}</Typography.Text>
                </List.Item>
                <List.Item>
                    <Typography.Text>
                        {`Communities: ${
                            communities.isLoading
                                ? ''
                                : user.communities
                                      .map(
                                          (com, i) =>
                                              communities.data?.list?.find(
                                                  (e) => e.id === com
                                              )?.name
                                      )
                                      .join(', ')
                        }`}
                    </Typography.Text>
                </List.Item>
                <List.Item>
                    <Typography.Text>{`Role: ${user.role}`}</Typography.Text>
                </List.Item>
                <List.Item>
                    <Typography.Text>
                        {`Last update: ${moment(
                            new Date(user.updatedAt)
                        ).fromNow()}`}
                    </Typography.Text>
                </List.Item>
            </List>
        </>
    )
}

export default Welcome
