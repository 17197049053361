import { useContext } from 'react'
import { Button, Input, Typography, Space, Form } from 'antd'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../context/AppContext'
import { LOGIN } from '../reducers/Reducers'
import { TOKEN_SET } from '../reducers/Reducers'

import Background from '../images/login_background.png'
import Logo from '../images/zikoland_logo.png'

import { login } from '../network/API'

const { Title, Text } = Typography

const Login = () => {
    const { dispatchUser, dispatchToken } = useContext(AppContext)
    const { t } = useTranslation()

    const mutation = useMutation(
        (values) => login(values.email, values.password),
        {
            onSuccess: (data, variables, context) => {
                dispatchToken({
                    type: TOKEN_SET,
                    payload: {
                        token: data.token,
                        expireAt: data.expireAt,
                    },
                })
                dispatchUser({
                    type: LOGIN,
                    payload: data.user,
                })
                localStorage.setItem('zikoUser', JSON.stringify(data.user))
                localStorage.setItem(
                    'zikoToken',
                    JSON.stringify({
                        token: data.token,
                        expireAt: data.expireAt,
                    })
                )
                localStorage.setItem(
                    'zikoRefreshToken',
                    JSON.stringify({
                        token: data.refreshToken,
                        expireAt: data.refreshExpireAt,
                    })
                )
            },
            onError: (error, variables, context) => {},
        }
    )

    const onFinish = (values) => {
        mutation.mutate(values)
    }

    return (
        <div
            className="loginContainer"
            style={{ backgroundImage: `url(${Background})` }}
        >
            <div className="window">
                {/* <Space direction="vertical">
                    <div> */}
                {/* <div
                    style={{

                    }}
                >
                    <img src={Logo} alt="" />
                    <Title level={3}>'login'}</Title>

                </div> */}
                {/* </Space> */}
                <Space
                    wrap={false}
                    align="center"
                    size={'middle'}
                    direction="vertical"
                    className="login-content"
                >
                    <img src={Logo} alt="" />
                    <Title level={3}>{t('LOGIN')}</Title>
                    <Form name="login" onFinish={onFinish}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: false,
                                    message: 'Please input your E-mail!',
                                },
                            ]}
                        >
                            <Input placeholder={t('USERNAME')} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder={t('PASSWORD')} />
                        </Form.Item>
                        {mutation.isError && (
                            <Text type="danger">Wrong credentials</Text>
                        )}
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={mutation.isLoading}
                        >
                            {t('SIGN_IN')}
                        </Button>
                    </Form>
                    {/* <Text disabled underline>
                        {t('FORGOT_PASS')}
                    </Text> */}
                </Space>
            </div>
        </div>
    )
}

export default Login
