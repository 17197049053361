import { Input, Select, Typography, Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useQuery, useQueryClient } from 'react-query'
import { getProductsBasic } from '../../../network/Products'
import { useContext } from 'react'

import { AppContext } from '../../../context/AppContext'

const { Text } = Typography

const ProductsSelect = ({ value, onChange, disabled }) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()

    const products = useQuery(
        ['getProductsBasic', { token: token.token }],
        getProductsBasic,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    )

    return (
        <Input.Group compact>
            <Select
                showSearch
                filterOption={(input, option) => {
                    const label = option?.children?.[0]

                    return (
                        label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                }}
                value={value}
                onSelect={(e) => {
                    const product = products.data?.list.find(
                        (product) => product.id === e
                    )

                    const stock = product?.stock?.stock || 0
                    const freeStock = product?.stock?.freeStock || 0
                    const weight = product?.weight || 0
                    const weightType = product?.weightType || ''
                    const price = product?.retailPrice || 0

                    onChange([
                        e,
                        { stock: stock + freeStock, weight, weightType, price },
                    ])
                }}
                disabled={disabled}
                loading={products.isLoading}
                style={{
                    width: 'calc(100% - 33px)',
                }}
            >
                {products.data?.list.map((e) => (
                    <Select.Option
                        key={e.id}
                        value={e.id}
                        disabled={e.status !== 'available'}
                    >
                        {e.name?.en}
                        <Text type="secondary">
                            {` (${
                                (e?.stock?.stock || 0) +
                                (e?.stock?.freeStock || 0)
                            } in stock)`}
                        </Text>
                    </Select.Option>
                ))}
            </Select>
            <Button
                style={{ marginLeft: 1 }}
                icon={<ReloadOutlined />}
                disabled={products.isLoading || disabled}
                onClick={() =>
                    queryClient.invalidateQueries('getProductsBasic')
                }
            />
        </Input.Group>
    )
}

export default ProductsSelect
