import { getSuppliersDetailed } from '../../network/Suppliers'

const Information = {
    title: 'SUPPLIERS_SUPPLIER_INFORMATION',
    key: [],
    sections: [
        {
            title: 'DIRECT_SALES_GENERAL_INFORMATION',
            columns: 4,
            fields: [
                {
                    label: 'SUPPLIERS_TYPE',
                    isRequired: true,
                    key: ['type'],
                    type: 'select',
                    options: [
                        { label: 'MENU_SERVICES', key: 'services' },
                        {
                            label: 'MENU_DIRECT_DIRECT_SALES',
                            key: 'direct_sales',
                        },
                    ],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'SUPPLIERS_SUPPLIER_NAME',
                    isRequired: true,
                    key: ['name'],

                    type: 'search&update',
                    options: getSuppliersDetailed,
                    searchKey: 'name',
                    formatOnRecieved: (supplier) => {
                        return {
                            name: supplier.name
                        }
                    },

                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_PHONE',
                    // isRequired: true,
                    key: ['phone'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_WECHAT_ID',
                    // isRequired: true,
                    // isRequired: true,
                    key: ['wechatID'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'CUSTOMERS_ADDRESS',
            columns: 4,
            fields: [
                {
                    label: 'DIRECT_SALES_ADDRESS_NAME',
                    // isRequired: true,
                    key: ['addressName'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_CITY',
                    // isRequired: true,
                    key: ['addressCity'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_ZIPCODE',
                    // isRequired: true,
                    key: ['addressZipCode'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_AREA',
                    // isRequired: true,
                    key: ['addressArea'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_ADDRESS',
                    type: 'text',
                    key: ['addressDetails'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 4,
                    },
                },
            ],
        },
        {
            title: 'SUPPLIERS_CONTACT',
            columns: 4,
            canAdd: true,
            key: ['contacts'],
            deleteBtn: {
                label: 'ROLES_DELETE',
                style: 'title',
            },
            addBtn: {
                label: 'SUPPLIERS_ADD_CONTACT',
                style: 'primary',
            },
            fields: [
                {
                    label: 'ROLES_NAME',
                    isRequired: true,
                    key: ['contactName'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_PHONE',
                    // isRequired: true,
                    key: ['contactPhone'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_EMAIL',
                    // isRequired: true,
                    key: ['contactEmail'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_WECHAT_ID',
                    // isRequired: true,
                    key: ['contactWechatID'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'SUPPLIERS_JOB_TITLE',
                    key: ['contactJobTitle'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_COMMENT',
                    type: 'text',
                    key: ['contactComment'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 4,
                    },
                },
                {
                    label: 'SUPPLIERS_BANK_INFORMATION',
                    type: 'text',
                    key: ['bankInformation'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 4,
                    },
                },
            ],
        },
    ],
}

const Other = {
    title: 'other',
    key: [],
    sections: [
        {
            title: 'SUPPLIERS_PAYMENT_TERMS',
            columns: 2,
            fields: [
                {
                    label: 'SUPPLIERS_TYPE',
                    isRequired: true,
                    key: ['paymentTerm'],
                    type: 'select',
                    options: [
                        {
                            label: 'SUPPLIERS_CASH_BEFORE_DELIVERY',
                            key: 'cash_before_delivery',
                        },
                        {
                            label: 'SUPPLIERS_CASH_ON_DELIVERY',
                            key: 'cash_on_delivery',
                        },
                        {
                            label: 'SUPPLIERS_END_OF_THE_MONTH',
                            key: 'end_of_the_month',
                        },
                        { label: 'SUPPLIERS_30_DAYS', key: '30days' },
                        { label: 'SUPPLIERS_35_DAYS', key: '35days' },
                        { label: 'SUPPLIERS_60_DAYS', key: '60days' },
                    ],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'SUPPLIERS_VAT',
                    // isRequired: true,
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'direct_sales',
                    staticParentKey: ['type'],
                    type: 'number',
                    key: ['vat'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'SUPPLIERS_COMMISSIONS_RATE',
                    // isRequired: true,
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'services',
                    staticParentKey: ['type'],
                    suffixLabel: '%',
                    type: 'number',
                    key: ['commission'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'OFFERS_STATUS',
            isRequired: true,
            columns: 1,
            fields: [
                {
                    key: ['status'],
                    type: 'basicRadio',
                    default: 'available',
                    options: [
                        { label: 'available', key: 'available' },
                        { label: 'ongoing', key: 'ongoing' },
                        { label: 'discontinued', key: 'discontinued' },
                    ],
                },
            ],
        },
        {
            // title: 'OFFERS_STATUS',
            // isRequired: true,
            columns: 1,
            fields: [
                // {
                //     label: 'CREATE_OFFER_BANNER_CHINESE',
                //     // suffixLabel: '中文',
                //     key: ['banner', 'zh'],
                //     btn_label: 'CREATE_OFFER_UPLOAD',
                //     requestURI: '/files/offer-banner',
                //     storageURI: '/storage/offer-banner',
                //     type: 'upload',
                //     pos: {
                //         col: 4,
                //         row: 3,
                //         size: 3,
                //     },
                // },
                {
                    label: 'SUPPLIERS_FINANCE_INFORMATION',
                    key: ['financeInformation'],
                    btn_label: 'CREATE_OFFER_UPLOAD',
                    requestURI: '/files/finance-information',
                    storageURI: '/storage/finance-information',
                    type: 'multiupload',
                    pos: {
                        col: 1,
                        row: 4,
                        size: 3,
                    },
                },
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    if (oldData.financeInformation) {
        const otherPic = oldData.financeInformation.fileList
            ? oldData.financeInformation?.fileList
            : oldData.financeInformation
        oldData.financeInformation = otherPic.map((file) =>
            file.response ? file.response : file
        )
    } else {
        oldData.financeInformation = []
    }

    return oldData
}

export { Information, Other, cleanBeforeSending }
