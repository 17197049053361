import { useState, useEffect, useContext } from 'react'
import { Layout, Modal } from 'antd'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import NavigationDrawer from './NavigationDrawer'
import PrivateRoute from './PrivateRoute'
import Welcome from '../../pages/Welcome'
import KeyboardEventHandler from 'react-keyboard-event-handler'

import menuList from '../../constants/Menu'

import { AppContext } from '../../context/AppContext'
import { getRefunds } from 'src/network/Refunds'

import CreateOfferNew, { EditOfferNew } from '../../pages/create/CreateOfferNew'

const Dashboard = () => {
    const { user, token } = useContext(AppContext)

    // Use to open and close the nav bar
    const [collapsed, setCollapsed] = useState(false)
    const onCollapse = (collapsed) => setCollapsed(collapsed)

    // Get refunds count
    const [refundsCount, setRefundsCount] = useState(0)

    useEffect(() => {
        let filter = {
            status: '',
        }
        if (user.role === 'admin' || user.role === 'super-admin') {
            filter.status = { $in: ['new', 'validated'] }
        } else {
            if (user.role === 'finance') {
                filter.status = 'validated'
                filter.community = { $in: user.communities }
            } else if (
                user.role === 'community-manager' ||
                user.role === 'customer-hero'
            ) {
                filter.status = { $in: ['refunded', 'refused'] }
                filter.community = { $in: user.communities }
            }
        }

        getRefunds({
            queryKey: [
                'getRefunds',
                {
                    token: token.token,
                    filter: filter,
                },
            ],
        })
            .then((data) => {
                if (
                    user.role === 'community-manager' ||
                    user.role === 'customer-hero'
                ) {
                    const readedRefundsCount =
                        localStorage.getItem('readedRefundsCount') || 0
                    setRefundsCount(data?.list?.length - readedRefundsCount)
                } else {
                    setRefundsCount(data?.list?.length)
                }
            })
            .catch()
    })

    return (
        <>
            <Router>
                <Layout style={{ minHeight: '100%' }}>
                    <NavigationDrawer
                        collapsed={collapsed}
                        onCollapse={onCollapse}
                        refundsCount={refundsCount}
                    />
                    <Layout
                        className="site-layout"
                        style={{
                            marginLeft: collapsed ? 80 : 240,
                        }}
                    >
                        <Switch>
                            <Route exact path="/">
                                <Welcome />
                            </Route>
                            {/* Analytics */}
                            <PrivateRoute
                                exact
                                roles={menuList[0].roles}
                                path={menuList[0].slug}
                            >
                                {menuList[0].component}
                            </PrivateRoute>
                            {/* Offers */}
                            <PrivateRoute
                                exact
                                roles={menuList[1].roles}
                                path={menuList[1].slug}
                            >
                                {menuList[1].component}
                            </PrivateRoute>

                            <PrivateRoute
                                exact
                                roles={menuList[1].ext[0].roles}
                                path={menuList[1].ext[0].slug}
                            >
                                {menuList[1].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={[
                                    'super-admin',
                                    'admin',
                                    'product-manager',
                                    'community-manager',
                                ]}
                                path="/offers/create-new"
                            >
                                {CreateOfferNew}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={[
                                    'super-admin',
                                    'admin',
                                    'product-manager',
                                    'community-manager',
                                ]}
                                path="/offers/:offerId/edit-new"
                            >
                                {EditOfferNew}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[1].ext[1].roles}
                                path={menuList[1].ext[1].slug}
                            >
                                {menuList[1].ext[1].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[1].ext[2].roles}
                                path={menuList[1].ext[2].slug}
                            >
                                {menuList[1].ext[2].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[1].ext[3].roles}
                                path={menuList[1].ext[3].slug}
                            >
                                {menuList[1].ext[3].component}
                            </PrivateRoute>

                            <PrivateRoute
                                exact
                                roles={menuList[2].roles}
                                path={menuList[2].slug}
                            >
                                {menuList[2].component}
                            </PrivateRoute>

                            <PrivateRoute
                                exact
                                roles={menuList[2].ext[0].roles}
                                path={menuList[2].ext[0].slug}
                            >
                                {menuList[2].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[2].ext[1].roles}
                                path={menuList[2].ext[1].slug}
                            >
                                {menuList[2].ext[1].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[2].ext[2].roles}
                                path={menuList[2].ext[2].slug}
                            >
                                {menuList[2].ext[2].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[2].ext[3].roles}
                                path={menuList[2].ext[3].slug}
                            >
                                {menuList[2].ext[3].component}
                            </PrivateRoute>
                            {/* Orders */}
                            <PrivateRoute
                                exact
                                roles={menuList[3].sub[0].roles}
                                path={menuList[3].sub[0].slug}
                            >
                                {menuList[3].sub[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[3].sub[1].roles}
                                path={menuList[3].sub[1].slug}
                            >
                                {menuList[3].sub[1].component}
                            </PrivateRoute>

                            <PrivateRoute
                                roles={menuList[3].ext[0].roles}
                                path={menuList[3].ext[0].slug}
                            >
                                {menuList[3].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[3].ext[1].roles}
                                path={menuList[3].ext[1].slug}
                            >
                                {menuList[3].ext[1].component}
                            </PrivateRoute>
                            {/* Customers */}
                            <PrivateRoute
                                exact
                                roles={menuList[4].roles}
                                path={menuList[4].slug}
                            >
                                {menuList[4].component}
                            </PrivateRoute>

                            <PrivateRoute
                                roles={menuList[4].ext[0].roles}
                                path={menuList[4].ext[0].slug}
                            >
                                {menuList[4].ext[0].component}
                            </PrivateRoute>

                            <PrivateRoute
                                roles={menuList[4].ext[1].roles}
                                path={menuList[4].ext[1].slug}
                            >
                                {menuList[4].ext[1].component}
                            </PrivateRoute>
                            {/* <PrivateRoute
                            roles={menuList[3].ext[2].roles}
                            path={menuList[3].ext[2].slug}
                        >
                            {menuList[3].ext[2].component}
                        </PrivateRoute> */}
                            {/* <PrivateRoute
                            roles={menuList[3].ext[3].roles}
                            path={menuList[3].ext[3].slug}
                        >
                            {menuList[3].ext[3].component}
                        </PrivateRoute> */}
                            {/* Supplier */}
                            <PrivateRoute
                                exact
                                roles={menuList[5].roles}
                                path={menuList[5].slug}
                            >
                                {menuList[5].component}
                            </PrivateRoute>

                            <PrivateRoute
                                roles={menuList[5].ext[0].roles}
                                path={menuList[5].ext[0].slug}
                            >
                                {menuList[5].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[5].ext[1].roles}
                                path={menuList[5].ext[1].slug}
                            >
                                {menuList[5].ext[1].component}
                            </PrivateRoute>
                            {/* Products */}
                            <PrivateRoute
                                exact
                                roles={menuList[6].roles}
                                path={menuList[6].slug}
                            >
                                {menuList[6].component}
                            </PrivateRoute>

                            <PrivateRoute
                                roles={menuList[6].ext[0].roles}
                                path={menuList[6].ext[0].slug}
                            >
                                {menuList[6].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[6].ext[2].roles}
                                path={menuList[6].ext[2].slug}
                            >
                                {menuList[6].ext[2].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[6].ext[1].roles}
                                path={menuList[6].ext[1].slug}
                            >
                                {menuList[6].ext[1].component}
                            </PrivateRoute>
                            {/* Inventory */}
                            {/* <PrivateRoute
                                exact
                                roles={menuList[7].roles}
                                path={menuList[7].slug}
                            >
                                {menuList[7].component}
                            </PrivateRoute> */}
                            <PrivateRoute
                                exact
                                roles={menuList[7].sub[0].roles}
                                path={menuList[7].sub[0].slug}
                            >
                                {menuList[7].sub[0].component}
                            </PrivateRoute>
                            {/*<PrivateRoute*/}
                            {/*    exact*/}
                            {/*    roles={menuList[7].sub[1].roles}*/}
                            {/*    path={menuList[7].sub[1].slug}*/}
                            {/*>*/}
                            {/*    {menuList[7].sub[1].component}*/}
                            {/*</PrivateRoute>*/}
                            <PrivateRoute
                                roles={menuList[7].ext[0].roles}
                                path={menuList[7].ext[0].slug}
                            >
                                {menuList[7].ext[0].component}
                            </PrivateRoute>

                            {/* Purchase orders */}
                            <PrivateRoute
                                exact
                                roles={menuList[8].roles}
                                path={menuList[8].slug}
                            >
                                {menuList[8].component}
                            </PrivateRoute>

                            {/* Customer service */}
                            <PrivateRoute
                                exact
                                roles={menuList[9].sub[0].roles}
                                path={menuList[9].sub[0].slug}
                            >
                                {menuList[9].sub[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[9].sub[1].roles}
                                path={menuList[9].sub[1].slug}
                            >
                                {menuList[9].sub[1].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[9].sub[2].roles}
                                path={menuList[9].sub[2].slug}
                            >
                                {menuList[9].sub[2].component}
                            </PrivateRoute>
                            {/* Claims */}
                            <PrivateRoute
                                roles={menuList[9].ext[0].roles}
                                path={menuList[9].ext[0].slug}
                            >
                                {menuList[9].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[9].ext[1].roles}
                                path={menuList[9].ext[1].slug}
                            >
                                {menuList[9].ext[1].component}
                            </PrivateRoute>
                            {/* Voucher */}
                            <PrivateRoute
                                roles={menuList[9].ext[2].roles}
                                path={menuList[9].ext[2].slug}
                            >
                                {menuList[9].ext[2].component}
                            </PrivateRoute>
                            {/* <PrivateRoute
                            roles={menuList[7].ext[3].roles}
                            path={menuList[7].ext[3].slug}
                        >
                            {menuList[7].ext[3].component}
                        </PrivateRoute> */}
                            {/* Refund */}
                            <PrivateRoute
                                roles={menuList[9].ext[3].roles}
                                path={menuList[9].ext[3].slug}
                            >
                                {menuList[9].ext[3].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[9].ext[4].roles}
                                path={menuList[9].ext[4].slug}
                            >
                                {menuList[9].ext[4].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[10].roles}
                                path={menuList[10].slug}
                            >
                                {menuList[10].component}
                            </PrivateRoute>

                            <PrivateRoute
                                roles={menuList[10].ext[0].roles}
                                path={menuList[10].ext[0].slug}
                            >
                                {menuList[10].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[10].ext[1].roles}
                                path={menuList[10].ext[1].slug}
                            >
                                {menuList[10].ext[1].component}
                            </PrivateRoute>
                            {/* Lottery */}
                            <PrivateRoute
                                exact
                                roles={menuList[11].roles}
                                path={menuList[11].slug}
                            >
                                {menuList[11].component}
                            </PrivateRoute>
                            {/* Finance */}
                            <PrivateRoute
                                exact
                                roles={menuList[12].sub[0].roles}
                                path={menuList[12].sub[0].slug}
                            >
                                {menuList[12].sub[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[12].sub[1].roles}
                                path={menuList[12].sub[1].slug}
                            >
                                {menuList[12].sub[1].component}
                            </PrivateRoute>
                            {/* <PrivateRoute
                                exact
                                roles={menuList[11].sub[2].roles}
                                path={menuList[11].sub[2].slug}
                            >
                                {menuList[11].sub[2].component}
                            </PrivateRoute> */}
                            <PrivateRoute
                                exact
                                roles={menuList[12].ext[0].roles}
                                path={menuList[12].ext[0].slug}
                            >
                                {menuList[12].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[12].ext[1].roles}
                                path={menuList[12].ext[1].slug}
                            >
                                {menuList[12].ext[1].component}
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                roles={menuList[12].ext[2].roles}
                                path={menuList[12].ext[2].slug}
                            >
                                {menuList[12].ext[2].component}
                            </PrivateRoute>
                            {/* Team */}
                            <PrivateRoute
                                exact
                                roles={menuList[13].roles}
                                path={menuList[13].slug}
                            >
                                {menuList[13].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[13].ext[0].roles}
                                path={menuList[13].ext[0].slug}
                            >
                                {menuList[13].ext[0].component}
                            </PrivateRoute>
                            <PrivateRoute
                                roles={menuList[13].ext[1].roles}
                                path={menuList[13].ext[1].slug}
                            >
                                {menuList[13].ext[1].component}
                            </PrivateRoute>
                            <Route component={Welcome} />
                        </Switch>
                    </Layout>
                </Layout>
            </Router>
            <Konami />
        </>
    )
}

const Konami = () => {
    const [konamiCode, setKonamiCode] = useState([])
    const code = [
        'up',
        'up',
        'down',
        'down',
        'left',
        'right',
        'left',
        'right',
        'b',
        'a',
    ]

    // if (process.env.NODE_ENV === 'production') return null

    return (
        <KeyboardEventHandler
            handleKeys={['up', 'right', 'down', 'left', 'a', 'b']}
            handleFocusableElements={true}
            onKeyEvent={(key, e) => {
                let newCode = [...konamiCode, key]

                for (var i = 0; i < newCode.length; i++) {
                    if (newCode[i] !== code[i]) {
                        setKonamiCode([key])
                        return
                    }
                    setKonamiCode(newCode)
                }
                if (newCode.length === code.length)
                    Modal.success({
                        title: "Ssshhhh! That's a secret",
                        content: (
                            <img
                                style={{ width: '100%' }}
                                src="https://i.kym-cdn.com/entries/icons/facebook/000/021/557/conceit.jpg"
                            />
                        ),
                    })
            }}
        />
    )
}

export default Dashboard
