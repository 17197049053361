import { useContext } from 'react'
import i18next from 'i18next'
import moment from 'moment'

import { AppContext } from '../context/AppContext'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'

import { formatDate } from '../utils/utils'
import { getCommunities, getLottery } from '../network/API'

const LotteryManagement = ({ history }) => {
    const { user } = useContext(AppContext)

    const commuFilter =
        user.role === 'admin' || user.role === 'super-admin'
            ? {}
            : { id: { $in: user.communities } }

    return (
        <PageWrap title="MENU_LOTTERY_MANAGEMENT">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getLottery'}
                request={getLottery}
                pageId={commuFilter}
                pageSize={80}
            />
        </PageWrap>
    )
}

const filters = (goTo, selectedKeys, isLoading, id) => ({
    fields: [
        {
            type: 'text',
            label: 'OFFERS_OFFER_ID',
            key: 'offer.offerID',
        },
        {
            type: 'select',
            label: 'CREATE_OFFER_CHANNEL',
            default: '',
            key: 'channel',
            options: [
                { key: 'miniprogram', label: 'miniprogram' },
                { key: 'wechat_group', label: 'wechat_group' },
            ],
        },
        {
            type: 'select',
            label: 'ROLES_COMMUNITY',
            default: '',
            key: 'offer.community._id',
            options: getCommunities,
            otherParams: id,
        },
        {
            type: 'text',
            label: 'OFFERS_OFFER_NAME',
            key: 'offer.name.en',
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    actions: [
        {
            label: 'ANALYTICS_EXPORT_CSV',
            isActive: !isLoading,
            csv: {
                header: [
                    'Offer ID',
                    'Community',
                    'Offer name',
                    'Offer date',
                    'Supplier',
                    'Draw number',
                    'Number of winners',
                    'Prize',
                    'Winner name',
                    'Win time',
                ],
                rowFilter: (order, i) => {
                    let rows = []
                    rows.push(order.offer?.offerID)
                    rows.push(order.offer?.community?.name)
                    rows.push(order.offer?.name.en)
                    rows.push(formatDate(order.offer.createdAt))
                    rows.push(order?.offer?.supplier?.name)

                    let mp =
                        order?.offer?.miniprogram?.lottery?.draws?.findIndex(
                            (dr) => order?.offerDrawId === dr._id
                        )

                    let wg =
                        order?.offer?.wechatGroup?.lottery?.draws?.findIndex(
                            (dr) => order?.offerDrawId === dr._id
                        )

                    if ((mp && mp !== -1) || mp === 0) {
                        rows.push(mp + 1)
                    } else if ((wg && wg !== -1) || wg === 0) {
                        rows.push(wg + 1)
                    } else {
                        rows.push('')
                    }

                    rows.push(order.winners.length)
                    rows.push(
                        order.winners
                            .map(
                                (winner, i) =>
                                    (i === 0 ? '' : '. ') +
                                    (winner?.gift?.type === 'custom'
                                        ? winner?.gift?.custom[i18next.language]
                                        : winner?.gift?.type)
                            )
                            .join('')
                    )
                    rows.push(
                        order.winners
                            .map(
                                (winner, i) =>
                                    winner?.order?.customer?.name ||
                                    winner?.order?.customerDaily?.customerName
                            )
                            .filter((e) => !!e)
                            .join('. ')
                    )
                    rows.push(formatDate(order.createdAt))
                    return rows
                },
                name: 'lottery-' + moment().format('YYYY-MM-DD'),
            },
        },
    ],
})

const columns = (goTo) => [
    {
        title: <DynamicStrings id="OFFERS_OFFER_ID" />,
        dataIndex: 'offer',
        render: (offer) => offer?.offerID,
        key: 'offerID',
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'offer',
        render: (offer) => <DynamicStrings id={offer?.community?.name} />,
        key: 'name',
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_NAME" />,
        dataIndex: 'offer',
        render: (offer) => offer?.name.en,
        key: 'offer.name.en',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFERS_OFFER_DATE" />,
        dataIndex: 'offer',
        render: (offer) => formatDate(offer?.updatedAt),
        key: 'offer.createdAt',
        sorter: true,
    },
    {
        title: <DynamicStrings id="supplier" />,
        dataIndex: 'offer',
        render: (offer) => offer?.supplier?.name,
        key: 'offer.supplier.name',
        sorter: true,
    },
    {
        title: <DynamicStrings id="LOTTERY_MANAGEMENT_DRAW_NUMBER" />,
        // dataIndex: 'winners',
        render: (e) => {
            let mp = e?.offer?.miniprogram?.lottery?.draws?.findIndex(
                (dr) => e?.offerDrawId === dr._id
            )

            if ((mp && mp !== -1) || mp === 0) return mp + 1

            let wg = e?.offer?.wechatGroup?.lottery?.draws?.findIndex(
                (dr) => e?.offerDrawId === dr._id
            )

            if ((wg && wg !== -1) || wg === 0) return wg + 1

            return ''
        },
        key: 'name',
        // sorter: true,
    },
    {
        title: <DynamicStrings id="LOTTERY_MANAGEMENT_NUMBER_WINNERS" />,
        dataIndex: 'winners',
        render: (winners) => winners.length,
        key: 'winners',
    },
    {
        title: <DynamicStrings id="LOTTERY_MANAGEMENT_PRIZE" />,
        dataIndex: 'winners',
        ellipsis: true,
        render: (winners) =>
            winners.map(
                (winner, i) =>
                    (i === 0 ? '' : ', ') +
                    (winner?.gift?.type === 'custom'
                        ? winner?.gift?.custom[i18next.language]
                        : winner?.gift?.type)
            ),
        key: 'winners',
    },
    {
        title: <DynamicStrings id="LOTTERY_MANAGEMENT_WINNER_NAME" />,
        dataIndex: 'winners',
        render: (winners) =>
            winners
                .map(
                    (winner, i) =>
                        winner?.order?.customer?.name ||
                        winner?.order?.customerDaily?.customerName
                )
                .filter((e) => !!e)
                .join(', '),
        ellipsis: true,
        key: 'winners',
    },
    {
        title: <DynamicStrings id="LOTTERY_MANAGEMENT_WIN_TIME" />,
        dataIndex: 'createdAt',
        render: (createdAt) => formatDate(createdAt),
        key: 'createdAt',
    },
]

export default LotteryManagement
