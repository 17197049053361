import { getProductTypes, getProducts } from '../../network/Products'
import { getSuppliers } from '../../network/Suppliers'

const Description = {
    title: 'ROLES_DESCRIPTION',
    key: [],
    sections: [
        {
            columns: 10,
            fields: [
                {
                    label: 'INVENTORY_PRODUCT_NAME',
                    suffixLabel: 'English',
                    isRequired: true,
                    key: ['name', 'en'],

                    type: 'search&update',
                    options: getProducts,
                    searchKey: 'name.en',
                    formatOnRecieved: (product) => {
                        return {
                            name: {
                                en: product.name.en
                            }
                        }
                    },

                    pos: {
                        col: 1,
                        row: 1,
                        size: 4,
                    },
                },
                {
                    label: 'INVENTORY_PRODUCT_NAME',
                    suffixLabel: '中文',
                    isRequired: true,
                    key: ['name', 'zh'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 4,
                    },
                },
                {
                    label: 'PRODUCT_LIST_PRODUCT_ID',
                    isInactive: true,
                    key: ['productId'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    type: 'number',
                    label: 'PRODUCT_LIST_UNIT_PRICE',
                    isRequired: true,
                    key: ['price'],
                    suffixLabel: '¥',
                    pos: {
                        col: 3,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    type: 'number',
                    label: 'PRODUCT_LIST_RETAIL_PRICE',
                    // isRequired: true,
                    key: ['retailPrice'],
                    suffixLabel: '¥',
                    pos: {
                        col: 5,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    label: 'CREATE_OFFER_WEIGHT_PER_UNIT',
                    // suffixLabel: 'g',
                    type: 'number',
                    isInt: true,
                    key: ['weight'],
                    pos: {
                        col: 7,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    label: 'CREATE_OFFER_UNIT',
                    key: ['weightType'],
                    isRequired: true,
                    type: 'select',
                    options: [
                        { label: 'CREATE_OFFER_GRAM', key: 'g' },
                        { label: 'CREATE_OFFER_ML', key: 'ml' },
                        { label: 'CREATE_OFFER_PIECES', key: 'piece' },
                    ],
                    pos: {
                        col: 9,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    type: 'text',
                    label: 'ROLES_DESCRIPTION',
                    isRequired: true,
                    suffixLabel: 'English',
                    key: ['description', 'en'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 5,
                    },
                },
                {
                    type: 'text',
                    label: 'ROLES_DESCRIPTION',
                    isRequired: true,
                    suffixLabel: '中文',
                    key: ['description', 'zh'],
                    pos: {
                        col: 6,
                        row: 4,
                        size: 5,
                    },
                },
                {
                    type: 'text',
                    label: 'OFFER_LIVE_COMMENT',
                    key: ['comment'],
                    pos: {
                        col: 1,
                        row: 5,
                        size: 5,
                    },
                },
            ],
        },
    ],
}

const Media = {
    title: 'PRODUCT_LIST_MEDIA',
    key: [],
    sections: [
        {
            title: 'PRODUCT_LIST_THUMBNAIL',
            columns: 2,
            fields: [
                {
                    label: 'PRODUCT_LIST_THUMBNAIL_EN',
                    suffixLabel: 'English',
                    type: 'upload',
                    // isRequired: true,
                    requestURI: '/files/product-cover',
                    storageURI: '/storage/product-cover',
                    key: ['mainPicture', 'en'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'PRODUCT_LIST_THUMBNAIL_CN',
                    suffixLabel: '中文',
                    type: 'upload',
                    // isRequired: true,
                    requestURI: '/files/product-cover',
                    storageURI: '/storage/product-cover',
                    key: ['mainPicture', 'zh'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'PRODUCT_LIST_TECHNICAL_SHEET',
            columns: 2,
            fields: [
                {
                    label: 'PRODUCT_LIST_EN_TECHNICAL_SHEET',
                    type: 'upload',
                    // isRequired: true,
                    requestURI: '/files/product-tech-sheet',
                    storageURI: '/storage/product-tech-sheet',
                    key: ['technicalSheet', 'en'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'PRODUCT_LIST_CN_TECHNICAL_SHEET',
                    type: 'upload',
                    // isRequired: true,
                    requestURI: '/files/product-tech-sheet',
                    storageURI: '/storage/product-tech-sheet',
                    key: ['technicalSheet', 'zh'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'PRODUCT_LIST_PRODUCT_PICTURES',
            columns: 2,
            fields: [
                {
                    label: 'RECIPES_ADD_MORE_PICTURES_OR_VIDEOS',
                    type: 'multiupload',
                    requestURI: '/files/product-other-media',
                    storageURI: '/storage/product-other-media',
                    key: ['otherMedia'],
                },
            ],
        },
    ],
}

const ProductDetails = {
    title: 'PRODUCT_LIST_PRODUCT_DETAILS',
    key: [],
    sections: [
        {
            title: 'supplier',
            columns: 1,
            fields: [
                {
                    label: 'DIRECT_SALES_CHOOSE_SUPPLIER',
                    type: 'select',
                    isRequired: true,
                    requestOption: getSuppliers,
                    key: ['supplier'],
                    onChange: (form, name, id, e) => {
                        if (e.type === 'services') {
                            const price = form.getFieldValue('price')

                            if (!price && price !== 0) {
                                form.setFieldsValue({ price: 0 })
                            }
                        }
                    },
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
            ],
        },
        {
            title: 'SUPPLIERS_TYPE',
            columns: 2,
            fields: [
                {
                    label: 'PRODUCT_LIST_CHOOSE_TYPE',
                    type: 'select',
                    isRequired: true,
                    requestOption: getProductTypes,
                    key: ['productType'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'ZIKO_SPECIALS_EXPIRATION_DATE',
                    key: ['expirationDate'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'PRODUCT_LIST_FOOD_STORAGE',
                    default: 'none',
                    type: 'select',
                    options: [
                        { label: 'none', key: 'none' },
                        { label: 'fresh', key: 'fresh' },
                        { label: 'dry', key: 'dry' },
                        { label: 'frozen', key: 'frozen' },
                    ],
                    key: ['storageType'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    type: 'multicheck',
                    options: [
                        { label: 'vegan', key: 'vegan' },
                        { label: 'pa', key: 'pa' },
                        { label: 'gluten-free', key: 'gluten-free' },
                        { label: 'no-hormones', key: 'no-hormones' },
                    ],
                    key: ['diet'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 2,
                    },
                },
            ],
        },
        // {
        //     title: 'PRODUCT_LIST_DIET',
        //     columns: 1,
        //     fields: [
        //         {
        //             type: 'multicheck',
        //             options: [
        //                 { label: 'vegan', key: 'vegan' },
        //                 { label: 'pa', key: 'pa' },
        //                 { label: 'gluten-free', key: 'gluten-free' },
        //                 { label: 'no-hormones', key: 'no-hormones' },
        //             ],
        //             key: ['diet'],
        //         },
        //     ],
        //     pos: {
        //         col: 1,
        //         row: 1,
        //         size: 1,
        //     },
        // },
        {
            title: 'OFFERS_STATUS',
            columns: 1,
            fields: [
                {
                    type: 'basicRadio',
                    default: 'available',
                    options: [
                        { label: 'available', key: 'available' },
                        { label: 'discontinued', key: 'discontinued' },
                    ],
                    key: ['status'],
                },
            ],
            pos: {
                col: 1,
                row: 1,
                size: 1,
            },
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    if (oldData.mainPicture.en) {
        let mainEn = oldData.mainPicture.en.fileList
            ? oldData.mainPicture.en?.fileList
            : oldData.mainPicture.en

        if (!Array.isArray(mainEn)) {
            mainEn = [mainEn]
        }
        const newEn = mainEn.map((file) =>
            file.response ? file.response : file
        )

        oldData.mainPicture.en = newEn.length ? newEn[0] : {}
    } else {
        oldData.mainPicture.en = {}
    }

    if (oldData.mainPicture.zh) {
        let mainZh = oldData.mainPicture.zh.fileList
            ? oldData.mainPicture.zh?.fileList
            : oldData.mainPicture.zh

        if (!Array.isArray(mainZh)) {
            mainZh = [mainZh]
        }
        const newZh = mainZh.map((file) =>
            file.response ? file.response : file
        )

        oldData.mainPicture.zh = newZh.length ? newZh[0] : {}
    } else {
        oldData.mainPicture.zh = {}
    }
    //

    if (oldData.technicalSheet.en) {
        let mainEn = oldData.technicalSheet.en.fileList
            ? oldData.technicalSheet.en?.fileList
            : oldData.technicalSheet.en

        if (!Array.isArray(mainEn)) {
            mainEn = [mainEn]
        }
        const newEn = mainEn.map((file) =>
            file.response ? file.response : file
        )

        oldData.technicalSheet.en = newEn.length ? newEn[0] : {}
    } else {
        oldData.technicalSheet.en = {}
    }

    if (oldData.technicalSheet.zh) {
        let mainZh = oldData.technicalSheet.zh.fileList
            ? oldData.technicalSheet.zh?.fileList
            : oldData.technicalSheet.zh

        if (!Array.isArray(mainZh)) {
            mainZh = [mainZh]
        }
        const newZh = mainZh.map((file) =>
            file.response ? file.response : file
        )

        oldData.technicalSheet.zh = newZh.length ? newZh[0] : {}
    } else {
        oldData.technicalSheet.zh = {}
    }
    //

    if (oldData.otherMedia) {
        const otherPic = oldData.otherMedia.fileList
            ? oldData.otherMedia?.fileList
            : oldData.otherMedia
        oldData.otherMedia = otherPic.map((file) =>
            file.response ? file.response : file
        )
    } else {
        oldData.otherMedia = []
    }

    return oldData
}

export { Description, Media, ProductDetails, cleanBeforeSending }
