import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { Form } from 'antd'

import {
    Information,
    Other,
    cleanBeforeSending,
} from '../../constants/forms/Suppliers'
import { CreateSupplier as request, getSuppliersList } from '../../network/Suppliers'

import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'

const CreateSupplier = () => {
    const [form] = Form.useForm()
    const { token } = useContext(AppContext)
    const [suppliers, setSuppliers] = useState([])

    useEffect(() => {
        getSuppliersList(
            token.token
        ).then((data) => {
            setSuppliers(data.list)
        })
    }, [])

    return (
        <PageWrap goBack title="SUPPLIERS_CREATE_SUPPLIER">
            <FormWrap
                form={form}
                title="create_supplier"
                cleanBeforeSending={cleanBeforeSending}
                checkValidity={(values) => {
                    for (const i in suppliers) {
                        if (suppliers[i].name?.toLowerCase() === values.name?.toLowerCase()) {
                            return 'Supplier already exists'
                        }
                    }

                    return false
                }}
                request={request}
                onSuccess={'/suppliers'}
                invalidate={['getSuppliers']}
            >
                <div className="SectionHorizontalWrap">
                    <FormBlock weight={2} form={form} formTemplate={Information} />
                    <FormBlock form={form} formTemplate={Other} />
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateSupplier
