import { useState, useContext, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { Table, Statistic, Space, Typography, Card } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../context/AppContext'

import TableFilters from './TableFilters'
import Error from '../Error'

const { Title } = Typography

const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState('')
    const timerRef = useRef()

    useEffect(() => {
        timerRef.current = setTimeout(() => setDebouncedValue(value), delay)

        return () => {
            clearTimeout(timerRef.current)
        }
    }, [value, delay])

    return debouncedValue
}

const TableBlock = ({
    columns,
    filters,
    request,
    name,
    stats,
    statsRequest,
    summary,
    otherParams,
    otherFilters,
    rowSelection,
    components,
    infinite,
    className,
    elemId,
    pageId,
    pageSize,
    tableLayout,
    onRow,
    scroll,
}) => {
    let history = useHistory()
    const { token } = useContext(AppContext)

    const [range, setRange] = useState([0, pageSize ? pageSize - 1 : 9])
    const [sort, setSort] = useState(['createdAt', 'DESC'])
    const [filter, setFilter] = useState({})
    const debouncedFilter = useDebounce(filter, 500)
    const [tabFilter, setTabFilter] = useState({})
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isHydrated, setIsHydrated] = useState(false)

    const [current, setCurrent] = useState(1) // Current page num

    const requestKeys = [
        name,
        {
            sort,
            range: infinite ? null : range,
            filter: { ...otherFilters, ...debouncedFilter },
            token: token.token,
            tabFilter,
            ...otherParams,
        },
    ]

    const customers = useQuery(requestKeys, request)

    // To store the list after getting data
    var dataList = []
    if (customers.data) {
        if (customers.data.purchaseOrderItems) {
            dataList = customers.data.purchaseOrderItems
        } else {
            dataList = customers.data.list
        }
    }

    // To get the previous pagination and sort from url
    const paramsToObject = (entries) => {
        const result = {}
        for (const [key, value] of entries) {
            result[key] = value
        }
        return result
    }

    const objectToParamString = (paramObj) => {
        if (!paramObj) {
            return ''
        }
        let paramList = []
        Object.keys(paramObj) &&
            Object.keys(paramObj).forEach((key) => {
                let val = paramObj[key]
                if (val.constructor === Array) {
                    val.forEach((_val) => {
                        paramList.push(key + '=' + _val)
                    })
                } else {
                    paramList.push(key + '=' + val)
                }
            })
        return paramList.join('&')
    }

    const columnsRef = useRef(
        columns((e) => history.push(e), requestKeys, dataList)
    )

    useEffect(() => {
        let search = window.location.hash?.substring(1) || ''

        if (search != '') {
            const urlParams = new URLSearchParams(search)
            const entries = urlParams.entries()
            const params = paramsToObject(entries)

            if (params.current && params.current != 1) {
                setCurrent(params.current)
                setRange(
                    pageSize
                        ? [
                              params.current * pageSize - pageSize,
                              params.current * pageSize - 1,
                          ]
                        : [params.current * 10 - 10, params.current * 10 - 1]
                )
            }
            if (params.sort) {
                let paramsSort = params.sort.replaceAll(/\[|\]/g, '').split(',')
                setSort(paramsSort)
            }

            delete params.current
            delete params.sort
            setTabFilter(params)
        }
    }, [current])

    const handleTableChange = (pagination, filters, sorter) => {
        //formating the sort to match the API
        let currentSort =
            sorter.order === undefined
                ? ['createdAt', 'DESC']
                : [
                      sorter.columnKey,
                      sorter.order === 'descend' ? 'DESC' : 'ASC',
                  ]

        //checking if sorter are same as previous to reset the page to the first one
        if (
            currentSort.length === sort.length &&
            currentSort[0] === sort[0] &&
            currentSort[1] === sort[1]
        ) {
            setRange([
                pageSize
                    ? pagination.current * pageSize - pageSize
                    : pagination.current * 10 - 10,
                pageSize
                    ? pagination.current * pageSize - 1
                    : pagination.current * 10 - 1,
            ])
        } else {
            setSort(currentSort)
            setRange([0, pageSize ? pageSize - 1 : 9])
        }

        // To keep previous page's behavior
        setCurrent(pagination.current)
        let params = {
            current: pagination.current,
            sort: `[${currentSort[0]},${currentSort[1]}]`,
            ...tabFilter,
        }
        window.location.hash = objectToParamString(params)
    }

    useEffect(() => {
        if (!isHydrated) return
        const queryParams = new URLSearchParams(window.location.search)

        if (sort) {
            queryParams.set('sort', `${sort[0]},${sort[1]}`)
        }

        const newLocation = {
            pathname: window.location.pathname,
            search: queryParams.toString(),
        }

        history.replace(newLocation)
    }, [sort, isHydrated])

    /**
     * @dev originally this hook is for updating the url when the sort changes, but it's not working properly
     * need more attention on how to handle the condition where restored and switching column to sort
     * commented it for better experience
     **/
    // useEffect(() => {
    //     const queryParams = new URLSearchParams(window.location.search)
    //
    //     const sort = queryParams.get('sort')
    //     if (sort) {
    //         const [column, order] = sort.split(',')
    //         setSort([column, order])
    //
    //         const tmpCols = columnsRef.current
    //         const index = tmpCols.findIndex((col) => col.key === column)
    //         if (index !== -1) {
    //             tmpCols[index].sortOrder =
    //                 order === 'ASC' ? 'ascend' : 'descend'
    //         }
    //     }
    //
    //     setIsHydrated(true)
    // }, [])

    return (
        <div style={{ backgroundColor: '#fff', height: '100%' }}>
            {filters && (
                <TableFilters
                    selectedRowKeys={selectedRowKeys}
                    requestKeys={requestKeys}
                    resetSelected={() => setSelectedRowKeys([])}
                    elemId={elemId}
                    config={filters(
                        history,
                        selectedRowKeys.length ? selectedRowKeys : false,
                        customers.isLoading,
                        pageId,
                        customers.data?.list
                    )}
                    dataList={dataList}
                    onFiltersChange={(filters) => {
                        setFilter(filters)
                        setRange([0, pageSize ? pageSize - 1 : 9])
                    }}
                    onTabChange={(e) => {
                        setTabFilter(e)
                        setRange([0, pageSize ? pageSize - 1 : 9])

                        let params = {
                            current: current,
                            sort: `[${sort[0]},${sort[1]}]`,
                            ...e,
                        }
                        window.location.hash = objectToParamString(params)
                    }}
                />
            )}
            {stats && (
                <Stats
                    statSections={stats()}
                    statsRequest={statsRequest}
                    name={name}
                    filter={filter}
                />
            )}
            {customers.isError ? (
                <Error retry={() => customers.refetch()} />
            ) : (
                <Table
                    style={{
                        padding: '0px 24px',
                    }}
                    scroll={scroll}
                    className={'tableWrap ' + className ? className : ''}
                    // dataSource={customers.data?.list}
                    dataSource={dataList}
                    rowKey="id"
                    rowSelection={
                        rowSelection
                            ? {
                                  type: 'checkbox',
                                  selectedRowKeys,
                                  onChange: (selectedRowKeys, selectedRows) =>
                                      setSelectedRowKeys(selectedRowKeys),
                                  getCheckboxProps: rowSelection,
                              }
                            : false
                    }
                    components={components}
                    // columns={columnsRef.current}
                    columns={columns(
                        (e) => history.push(e),
                        requestKeys,
                        dataList
                    )}
                    loading={customers.isLoading}
                    onChange={handleTableChange}
                    onRow={onRow}
                    pagination={
                        infinite
                            ? {
                                  hideOnSinglePage: true,
                                  pageSize: customers?.data?.list?.length,
                              }
                            : {
                                  total: customers.data?.range,
                                  pageSize: pageSize ? pageSize : 10,
                                  showSizeChanger: false,
                                  current: pageSize
                                      ? range[0] / pageSize + 1
                                      : range[0] / 10 + 1,
                                  showTotal: (total, range) =>
                                      `${range[0]}-${range[1]} of ${total}`,
                              }
                    }
                    summary={summary}
                    tableLayout={tableLayout}
                />
            )}
        </div>
    )
}

const Stats = ({ statSections, statsRequest, name, filter }) => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const stats = useQuery(
        [name + 'Stats', { filter, token: token.token }],
        statsRequest
    )

    return (
        <Space
            style={{
                padding: '5px 24px',
                marginBottom: 7,
            }}
            size={32}
        >
            {statSections.map((section) => (
                <Space direction="vertical" size={0}>
                    {section.title && (
                        <Title style={{ marginBottom: 7 }} level={5}>
                            {t(section.title)}
                        </Title>
                    )}
                    {section.isWrap ? (
                        <Card>
                            {section.stats.map((stat) => (
                                <Statistic
                                    title={t(stat.label)}
                                    value={
                                        stats.isLoading
                                            ? ''
                                            : stat.getValue(stats.data)
                                    }
                                    loading={stats.isLoading}
                                    //coloring the color depending the value
                                    valueStyle={
                                        stats.isLoading
                                            ? {}
                                            : {
                                                  color:
                                                      parseFloat(
                                                          stat.getValue(
                                                              stats.data
                                                          )
                                                      ) > 90
                                                          ? '#3f8600'
                                                          : parseFloat(
                                                                stat.getValue(
                                                                    stats.data
                                                                )
                                                            ) > 51
                                                          ? '#FAAD14'
                                                          : '#FF4D4F',
                                              }
                                    }
                                    suffix={stat.suffix}
                                    precision={2}
                                />
                            ))}
                        </Card>
                    ) : (
                        <Space size={32}>
                            {section.stats.map((stat) => (
                                <Statistic
                                    title={t(stat.label)}
                                    value={
                                        stats.isLoading
                                            ? ''
                                            : stat.getValue(stats.data)
                                    }
                                    loading={stats.isLoading}
                                    suffix={stat.suffix}
                                    precision={0}
                                />
                            ))}
                        </Space>
                    )}
                </Space>
            ))}
        </Space>
    )
}

export default TableBlock
