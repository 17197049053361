import {
    useFieldArray,
    Controller,
    useWatch,
    useFormContext,
} from 'react-hook-form'
import {
    Select,
    Form,
    Card,
    Typography,
    Button,
    Space,
    InputNumber,
} from 'antd'

import numberToLetter from './numberToLetter'
import ProductsSelect from './ProductsSelect'
import HiddenFields from './HiddenFields'

const { Title } = Typography

const Items = ({
    control,
    channel,
    isEdit,
    setValue,
    errors,
    type,
    packs,
    clearErrors,
}) => {
    const { getValues, reset } = useFormContext()
    const name = `${channel}.items`
    const { fields, append, remove } = useFieldArray({
        // control,
        name,
    })

    const onRemove = (index) => {
        const values = getValues()
        values[channel].items.splice(index, 1)
        reset(values, { isDirty: true })
    }

    return (
        <Card
            bordered={false}
            title="Add single item"
            bodyStyle={{ paddingTop: 12 }}
        >
            {fields.map((field, index) => (
                <ItemLine
                    type={type}
                    key={field.id}
                    packs={packs}
                    itemIndex={index}
                    control={control}
                    channel={channel}
                    isEdit={isEdit}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    errors={errors?.[index]}
                    onRemove={() => onRemove(index)}
                    // onRemove={() => remove(index)}
                />
            ))}
            <Button
                type="primary"
                onClick={() => {
                    clearErrors(`${name}.size`)
                    append({})
                }}
                style={{ marginTop: 12 }}
                disabled={isEdit}
            >
                Add single item
            </Button>
        </Card>
    )
}

const ItemLine = ({
    type,
    packs,
    itemIndex,
    control,
    channel,
    isEdit,
    setValue,
    errors,
    onRemove,
}) => {
    const name = `${channel}.items.${itemIndex}`
    const { getValues } = useFormContext()

    const hiddenFields = [
        {
            field: '_id',
            type: 'string',
        },
        {
            field: 'actualStock',
            type: 'number',
        },
        {
            field: 'shortName',
            type: 'string',
        },
    ]

    return (
        <div style={{ marginTop: 12 }}>
            <Space align="center" style={{ marginBottom: 12 }}>
                <Title level={5} style={{ margin: 0 }}>
                    {`Add on ${numberToLetter(itemIndex + packs)}`}
                </Title>
                <Button type="link" onClick={onRemove} disabled={isEdit}>
                    Delete single item
                </Button>
            </Space>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridGap: 8,
                }}
            >
                <Form.Item
                    label="Select product"
                    style={{
                        gridRow: 1,
                        gridColumn: '1 / 4',
                        marginBottom: 0,
                    }}
                    validateStatus={!!errors?.['product'] ? 'error' : undefined}
                    help={errors?.['product']?.message}
                >
                    <Controller
                        name={`${name}.product`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <ProductsSelect
                                value={value}
                                onChange={([id, product]) => {
                                    onChange(id)
                                    setValue(`${name}.weight`, product.weight)
                                    setValue(
                                        `${name}.weightType`,
                                        product.weightType
                                    )
                                    setValue(`${name}.stock`, product.stock)
                                    setValue(`${name}.price`, product.price)
                                }}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    label="Quantity"
                    style={{
                        gridRow: 2,
                        marginBottom: 0,
                    }}
                    validateStatus={
                        !!errors?.['quantity'] ? 'error' : undefined
                    }
                    help={errors?.['quantity']?.message}
                >
                    <Controller
                        name={`${name}.quantity`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Weight / unit"
                    style={{
                        gridRow: 2,
                        marginBottom: 0,
                    }}
                >
                    <Controller
                        name={`${name}.weight`}
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Unit"
                    style={{
                        gridRow: 2,
                        marginBottom: 0,
                    }}
                    validateStatus={
                        !!errors?.['weightType'] ? 'error' : undefined
                    }
                    help={errors?.['weightType']?.message}
                >
                    <Controller
                        name={`${name}.weightType`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            >
                                <Select.Option value="g">Gram</Select.Option>
                                <Select.Option value="ml">
                                    Milliliter
                                </Select.Option>
                                <Select.Option value="piece">
                                    Pieces
                                </Select.Option>
                            </Select>
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Stock available"
                    style={{
                        gridRow: 2,
                        marginBottom: 0,
                    }}
                    validateStatus={!!errors?.['stock'] ? 'error' : undefined}
                    help={errors?.['stock']?.message}
                >
                    <Controller
                        name={`${name}.stock`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                min={1}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Price (¥)"
                    style={{
                        gridRow: 3,
                        marginBottom: 0,
                    }}
                    validateStatus={!!errors?.['price'] ? 'error' : undefined}
                    help={errors?.['price']?.message}
                >
                    <Controller
                        name={`${name}.price`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                disabled={isEdit}
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Crossed original price (¥)"
                    style={{
                        gridRow: 3,
                        marginBottom: 0,
                    }}
                    validateStatus={
                        !!errors?.['formerPrice'] ? 'error' : undefined
                    }
                    help={errors?.['formerPrice']?.message}
                >
                    <Controller
                        name={`${name}.formerPrice`}
                        control={control}
                        rules={{
                            validate: {
                                tooLow: (value) => {
                                    const values = getValues()
                                    const priceValue =
                                        values?.[channel]?.items?.[itemIndex]
                                            ?.price

                                    if (
                                        !!priceValue &&
                                        !!value &&
                                        value <= priceValue
                                    )
                                        return "Original price shouldn't be equal or less than the current price"
                                },
                            },
                        }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Target sales (¥)"
                    style={{
                        gridRow: 3,
                        marginBottom: 0,
                    }}
                >
                    <Controller
                        name={`${name}.targetSales`}
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>

                {type === 'free_fall' && (
                    <>
                        <Form.Item
                            label="Price drop (¥)"
                            style={{
                                gridRow: 4,
                            }}
                        >
                            <Controller
                                name={`${name}.freeFall.dropAmount`}
                                control={control}
                                // defaultValue=""
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Per items sold (Qt)"
                            style={{
                                gridRow: 4,
                            }}
                        >
                            <Controller
                                name={`${name}.freeFall.quantityTrigger`}
                                control={control}
                                // defaultValue=""
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Lowest price limit (¥)"
                            style={{
                                gridRow: 4,
                            }}
                            validateStatus={
                                !!errors?.['freeFall']?.['lowestPrice']
                                    ? 'error'
                                    : undefined
                            }
                            help={
                                errors?.['freeFall']?.['lowestPrice']?.message
                            }
                        >
                            <Controller
                                name={`${name}.freeFall.lowestPrice`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                    </>
                )}
            </div>

            {type === 'multiple_items' && (
                <ItemRule
                    itemIndex={itemIndex}
                    control={control}
                    channel={channel}
                    isEdit={isEdit}
                />
            )}

            {isEdit &&
                hiddenFields.map((field) => (
                    <HiddenFields
                        control={control}
                        name={name}
                        field={field}
                    ></HiddenFields>
                ))}
        </div>
    )
}

const ItemRule = ({ itemIndex, control, channel, isEdit }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${channel}.items.${itemIndex}.multipleItem`,
    })

    return (
        <>
            {fields.map((field, index) => (
                <div
                    key={field.id}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(5, 1fr)',
                        gridGap: 8,
                        marginTop: 8,
                    }}
                >
                    <Form.Item label="Items qty" style={{ marginBottom: 0 }}>
                        <Controller
                            name={`${channel}.items.${itemIndex}.multipleItem.${index}.quantity`}
                            control={control}
                            // defaultValue=""
                            render={({ value, onChange }) => (
                                <InputNumber
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Unit price (¥)"
                        style={{ marginBottom: 0 }}
                    >
                        <Controller
                            name={`${channel}.items.${itemIndex}.multipleItem.${index}.unitPrice`}
                            control={control}
                            // defaultValue=""
                            render={({ value, onChange }) => (
                                <InputNumber
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    <Button
                        type="link"
                        onClick={() => remove(index)}
                        style={{ margin: 'auto auto 0 0' }}
                        disabled={isEdit}
                    >
                        Delete
                    </Button>
                </div>
            ))}
            <Button
                type="dashed"
                onClick={append}
                disabled={isEdit}
                style={{ marginTop: 12 }}
            >
                Add rule
            </Button>
        </>
    )
}

export default Items
