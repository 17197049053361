import { useContext } from 'react'
import { Button, Space, Avatar, Badge } from 'antd'
import moment from 'moment'
import ziko from '../images/ZIKOLAND4.png'
import { AppContext } from '../context/AppContext'

import { SERVER } from '../network/API'
import { getProductTypes } from '../network/Products'
import { getProducts } from '../network/Products'
import { getSuppliers } from '../network/Suppliers'
import { formatDate } from '../utils/utils'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'
import { inventoryKeyMap } from '../constants/lang'

const Products = ({ history }) => {
    return (
        <PageWrap title="PRODUCT_LIST">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getProducts'}
                request={getProducts}
                pageSize={80}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push({
                                pathname: `/products/${record.id}`,
                                previousHash: window.location.hash,
                            })
                        },
                    }
                }}
            />
        </PageWrap>
    )
}

const filters = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'INVENTORY_PRODUCT_NAME',
            key: 'name.en',
        },
        {
            type: 'selectSearch',
            label: 'SUPPLIERS_SUPPLIER_NAME',
            key: 'supplier',
            options: getSuppliers,
            noRegex: true,
        },
        {
            type: 'selectSearch',
            label: 'SUPPLIERS_TYPE',
            default: '',
            key: 'productType',
            noRegex: true,
            options: getProductTypes,
        },
        {
            type: 'text',
            label: 'PRODUCT_LIST_PRODUCT_ID',
            key: 'productId',
        },
        {
            type: 'select',
            label: 'OFFERS_STATUS',
            default: '',
            key: 'status',
            noRegex: true,
            options: [
                { key: 'available', label: 'available' },
                { key: 'discontinued', label: 'discontinued' },
            ],
        },
    ],
    actions: [
        {
            label: 'PRODUCT_LIST_CREATE_PRODUCT',
            component: () => <BtnCreate history={history} />,
        },
    ],
})

const BtnCreate = ({ history }) => {
    const { user } = useContext(AppContext)

    if (
        user.role !== 'super-admin' &&
        user.role !== 'admin' &&
        user.role !== 'product-manager' &&
        user.role !== 'community-manager' &&
        user.role !== 'designer' &&
        user.role !== 'logistic'
    ) {
        return null
    }

    return (
        <Button type="primary" onClick={() => history.push('/products/create')}>
            <DynamicStrings id={'PRODUCT_LIST_CREATE_PRODUCT'} />
        </Button>
    )
}

const columns = (goTo) => [
    {
        title: '',
        dataIndex: 'mainPicture',
        render: (pictures) =>
            pictures?.en?.uri || pictures?.zh?.uri ? (
                <Avatar
                    shape="square"
                    src={`${SERVER.baseURL}/storage/product-cover/${
                        pictures?.en?.uri || pictures?.zh?.uri
                    }`}
                />
            ) : (
                <Avatar shape="square" src={ziko} />
            ),

        key: 'name',
    },
    {
        title: <DynamicStrings id="PRODUCT_LIST_PRODUCT_ID" />,
        dataIndex: 'productId',
        key: 'name',
        onCell: () => {
            return {
                onClick: (e) => e.stopPropagation(),
            }
        },
    },
    {
        title: (
            <DynamicStrings id="INVENTORY_PRODUCT_NAME" suffix="(English)" />
        ),
        dataIndex: 'name',
        key: 'name',
        render: ({ en }) => en,
        sorter: true,
    },
    {
        title: <DynamicStrings id="INVENTORY_PRODUCT_NAME" suffix="(中文)" />,
        dataIndex: 'name',
        key: 'name',
        render: ({ zh }) => zh,
    },
    {
        title: <DynamicStrings id="supplier" />,
        dataIndex: 'supplier',
        render: (supplier) => supplier?.name,
        key: 'supplier',
        sorter: false,
    },
    {
        title: <DynamicStrings id="PRODUCT_LIST_PRODUCT_TYPE" />,
        dataIndex: 'productType',
        render: (type) => {
            if (type?.name === 'Others') {
                type.name = `${type.name}(${type.category})`
            }
            return <DynamicStrings id={inventoryKeyMap[type?.name]} />
        },
        key: 'productType',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        dataIndex: 'status',
        key: 'status',
        render: (e) => {
            const color = {
                discontinued: 'error',
                available: 'success',
            }

            return <Badge status={color[e]} text={<DynamicStrings id={e} />} />
        },
        sorter: true,
    },
    {
        title: <DynamicStrings id="PRODUCT_LIST_FOOD_STORAGE" />,
        dataIndex: 'storageType',
        key: 'storageType',
        render: (e) => <DynamicStrings id={e} />,
        sorter: true,
    },
    {
        title: <DynamicStrings id="ZIKO_SPECIALS_EXPIRATION_DATE" />,
        dataIndex: 'expirationDate',
        render: (date) =>
            moment(date).isValid() && date ? formatDate(date) : date,
    },
    {
        title: <DynamicStrings id="PRODUCT_LIST_RETAIL_PRICE" suffix="(¥)" />,
        dataIndex: 'retailPrice',
        key: 'retailPrice',
        sorter: true,
    },
    {
        title: '',
        key: 'action',
        width: 1,
        render: (e) => <Btns e={e} goTo={goTo} />,
    },
]

const Btns = ({ e, goTo }) => {
    const { user } = useContext(AppContext)

    return (
        <Space size="small">
            {user.role !== 'logistic' && (
                <Button
                    onClick={(event) => {
                        event.stopPropagation()
                        goTo(`products/${e.id}/analytics`)
                    }}
                    type="secondary"
                >
                    <DynamicStrings id="MENU_ANALYTICS" />
                </Button>
            )}
            {/*<Button onClick={() => goTo(`products/${e.id}`)} type="primary">*/}
            {/* <Button onClick={() => goTo({
                pathname: `products/${e.id}`,
                previousHash: window.location.hash,
            })} type="primary">
                <DynamicStrings id="ROLES_EDIT" />
            </Button> */}
        </Space>
    )
}

export default Products
