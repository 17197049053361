import { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Badge, Layout, Menu, Modal, Tooltip, Typography } from 'antd'
import {
    DoubleRightOutlined,
    DoubleLeftOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import { useTimer } from 'react-timer-hook'

import { LogoutOutlined, TranslationOutlined } from '@ant-design/icons'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import menuList from '../../constants/Menu'

import { AppContext } from '../../context/AppContext'
import { LOGOUT, TOKEN_SET } from '../../reducers/Reducers'
import { refresh } from 'src/network/API'

import Logo from '../../images/zikoland_logo_small.png'

const { Header, Content, Footer } = Layout

const { Sider } = Layout
const { SubMenu } = Menu
const { Text } = Typography

const NavigationDrawer = ({ collapsed, onCollapse, refundsCount }) => {
    let { pathname } = useLocation()
    const [openKeys, setOpenKeys] = useState([])
    const { t } = useTranslation()
    const {
        user,
        dispatchUser,
        // settings, dispatchSettings
        token,
        refreshToken,
        dispatchRefreshToken,
    } = useContext(AppContext)
    const { days, seconds, minutes, hours } = useTimer({
        expiryTimestamp: new Date(token.expireAt),
        autoStart: true,
        onExpire: () => {
            refresh(refreshToken)
                .then((data) => {
                    dispatchRefreshToken({
                        type: TOKEN_SET,
                        payload: {
                            token: data.token,
                            expireAt: data.expireAt,
                        },
                    })
                })
                .catch(() => dispatchUser({ type: LOGOUT }))
        },
    })

    const onOpenChange = (keys) => {
        setOpenKeys(keys)
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
        setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }

    return (
        <Sider
            style={{
                // overflow: 'hidden',
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                backgroundColor: '#fff',
                borderRight: '1px solid #f0f0f0',
                zIndex: 1,
            }}
            width={240}
            collapsible
            trigger={null}
            collapsed={collapsed}
            onCollapse={onCollapse}
        >
            <Layout style={{ minHeight: '100%', backgroundColor: '#fff' }}>
                <Header
                    style={{
                        backgroundColor: '#fff',
                        padding: 0,
                        width: '100%',
                    }}
                >
                    <div
                        className={`navIconWrap ${
                            collapsed ? 'close' : 'open'
                        }`}
                        onClick={() => onCollapse(!collapsed)}
                    >
                        {!collapsed && <img src={Logo} alt="" />}
                        {collapsed ? (
                            <DoubleRightOutlined className="collapseIcon" />
                        ) : (
                            <DoubleLeftOutlined className="collapseIcon" />
                        )}
                    </div>
                </Header>
                <Content
                    // className="menu-list"
                    style={{
                        flex: 1,
                    }}
                >
                    <Menu
                        defaultSelectedKeys={`/${pathname.split('/')[1]}`}
                        selectedKeys={`/${pathname.split('/')[1]}`}
                        mode="inline"
                        style={{ borderRight: 0 }}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                    >
                        {menuList.map((menuItem, topIndex) =>
                            menuItem?.sub?.length ? (
                                menuItem.roles.indexOf(user.role) !== -1 &&
                                menuItem.showInMenu ? (
                                    <SubMenu
                                        key={topIndex}
                                        icon={menuItem.icon}
                                        // title={t(menuItem.label)}
                                        title={
                                            <>
                                                {t(menuItem.label)}
                                                {menuItem.slug ===
                                                '/customer-services' ? (
                                                    <Badge
                                                        count={refundsCount}
                                                        offset={[10, -2]}
                                                    />
                                                ) : null}
                                            </>
                                        }
                                    >
                                        {menuItem.sub.map(
                                            (subMenu, subIndex) => {
                                                if (
                                                    subMenu.slug === '/refunds'
                                                ) {
                                                    return subMenu.roles.indexOf(
                                                        user.role
                                                    ) !== -1 &&
                                                        subMenu.showInMenu ? (
                                                        <Menu.Item
                                                            key={subMenu.slug}
                                                        >
                                                            <Link
                                                                to={
                                                                    subMenu.slug
                                                                }
                                                            >
                                                                {t(
                                                                    subMenu.label
                                                                )}
                                                                <Badge
                                                                    count={
                                                                        refundsCount
                                                                    }
                                                                    offset={[
                                                                        10, -2,
                                                                    ]}
                                                                />
                                                            </Link>
                                                        </Menu.Item>
                                                    ) : null
                                                } else {
                                                    return subMenu.roles.indexOf(
                                                        user.role
                                                    ) !== -1 &&
                                                        subMenu.showInMenu ? (
                                                        <Menu.Item
                                                            key={subMenu.slug}
                                                        >
                                                            <Link
                                                                to={
                                                                    subMenu.slug
                                                                }
                                                            >
                                                                {t(
                                                                    subMenu.label
                                                                )}
                                                            </Link>
                                                        </Menu.Item>
                                                    ) : null
                                                }
                                            }
                                        )}
                                    </SubMenu>
                                ) : null
                            ) : menuItem.roles.indexOf(user.role) !== -1 &&
                              menuItem.showInMenu ? (
                                <Menu.Item
                                    key={menuItem.slug}
                                    icon={menuItem.icon}
                                >
                                    <Link to={menuItem.slug}>
                                        {t(menuItem.label)}
                                    </Link>
                                </Menu.Item>
                            ) : null
                        )}
                    </Menu>
                </Content>
                <Footer
                    style={{
                        // padding: 5,
                        padding: '0px 10px',
                        zIndex: 1,
                        backgroundColor: '#fff',
                        borderTop: '1px solid #f0f0f0',
                    }}
                >
                    <Menu
                        selectedKeys={['']}
                        style={{
                            borderRight: 0,
                        }}
                    >
                        <Menu.Item
                            onClick={() => {
                                if (i18next.language === 'en') {
                                    i18next.changeLanguage('zh')
                                    moment.locale('zh-cn')
                                    localStorage.setItem('lang', 'zh')
                                } else {
                                    i18next.changeLanguage('en')
                                    moment.locale('en')
                                    localStorage.setItem('lang', 'en')
                                }
                            }}
                            // className="navOptions"
                            key="lang"
                            icon={<TranslationOutlined />}
                        >
                            <Text
                                strong={i18next.language === 'en'}
                                disabled={i18next.language !== 'en'}
                            >
                                EN
                            </Text>
                            <Text> / </Text>
                            <Text
                                strong={i18next.language === 'zh'}
                                disabled={i18next.language !== 'zh'}
                            >
                                中文
                            </Text>
                        </Menu.Item>
                        <Menu.Item
                            // onClick={() => dispatchUser({ type: LOGOUT })}
                            onClick={() => {
                                const form =
                                    document.getElementsByClassName('form-page')
                                if (form.length > 0) {
                                    Modal.confirm({
                                        icon: <ExclamationCircleOutlined />,
                                        okText: t('CONFIRM'),
                                        cancelText: t('CANCEL'),
                                        content: t('LEAVE_PAGE'),
                                        onOk() {
                                            dispatchUser({ type: LOGOUT })
                                        },
                                    })
                                } else {
                                    dispatchUser({ type: LOGOUT })
                                }
                            }}
                            key="logout"
                            icon={<LogoutOutlined />}
                        >
                            <Tooltip
                                title={`${
                                    days * 24 + hours
                                }:${minutes}:${seconds}`}
                            >
                                {t('LOGOUT')}
                            </Tooltip>
                        </Menu.Item>
                    </Menu>
                </Footer>
            </Layout>
        </Sider>
    )
}

export default NavigationDrawer
