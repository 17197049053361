import { useReducer, useEffect } from 'react'

import 'antd/dist/antd.css'
import './App.css'
import './styles/Shared.css'
import 'braft-editor/dist/index.css'
import 'moment/locale/zh-cn'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import { Font } from '@react-pdf/renderer'

import { AppContext } from './context/AppContext'
import { userReducer, tokenReducer } from './reducers/Reducers'
import { LOGIN, LOGOUT } from './reducers/Reducers'
import { TOKEN_SET } from './reducers/Reducers'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { refresh } from './network/API'
import Dashbaord from './components/layout/Dashboard'
import Login from './pages/Login'
import en from './constants/lang/en'
import zh from './constants/lang/zh'

import NotoSansSCRegular from './assets/NotoSansSC-Regular.ttf';
import NotoSansSCBold from './assets/NotoSansSC-Bold.ttf';


// Font.register({
//     family: 'Hiragino',
//     fonts: [
//         {
//             family: 'Hiragino',
//             src: process.env.PUBLIC_URL + '/pdffont-W03.ttf',
//             fontStyle: 'normal',
//             fontWeight: 'normal',
//         },
//         {
//             family: 'HiraginoBold',
//             src: process.env.PUBLIC_URL + '/pdffont-W05.ttf',
//             fontStyle: 'normal',
//             fontWeight: 'bold',
//         },
//     ],
// })

Font.register({
    family: 'NotoSansSC',
    fonts: [
        {
            family: 'NotoSansSC',
            src: NotoSansSCRegular,
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        {
            family: 'NotoSansSCBold',
            src: NotoSansSCBold,
            fontStyle: 'normal',
            fontWeight: 'bold',
        },
    ],
})

const queryClient = new QueryClient()
moment.locale(localStorage.getItem('lang') || 'en')
i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: {
                    ...en,
                },
            },
            zh: {
                translation: {
                    ...zh,
                },
            },
        },
        // if you're using a language detector, do not define the lng option
        lng: localStorage.getItem('lang') || 'en',
        fallbackLng: ['en', 'zh'],
        interpolation: {
            escapeValue: false,
        },
    })

const App = () => {
    const [user, dispatchUser] = useReducer(userReducer, null)
    const [token, dispatchToken] = useReducer(tokenReducer, null)
    const [refreshToken, dispatchRefreshToken] = useReducer(tokenReducer, null)
    // const [settings, dispatchSettings] = useReducer(settingsReducer, {
    //     lang: 'en',
    // })

    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem('zikoUser'))
        const savedToken = JSON.parse(localStorage.getItem('zikoToken'))
        const savedRefreshToken = JSON.parse(
            localStorage.getItem('zikoRefreshToken')
        )

        // checking if token is expired
        if (
            savedUser &&
            savedToken &&
            new Date() <= new Date(savedToken.expireAt)
        ) {
            dispatchUser({ type: LOGIN, payload: savedUser })
            dispatchToken({
                type: TOKEN_SET,
                payload: savedToken,
            })
        } else if (
            savedUser &&
            savedToken &&
            savedRefreshToken &&
            new Date() <= new Date(savedRefreshToken.expireAt)
        ) {
            refresh(refreshToken)
                .then((data) => {
                    dispatchRefreshToken({
                        type: TOKEN_SET,
                        payload: {
                            token: data.token,
                            expireAt: data.expireAt,
                        },
                    })
                })
                .catch(() => {
                    localStorage.clear()
                    dispatchUser({ type: LOGOUT })
                })
        } else {
            localStorage.clear()
        }
    }, [])

    return (
        <AppContext.Provider
            value={{
                user,
                dispatchUser,
                // settings,
                // dispatchSettings,
                token,
                dispatchToken,
                refreshToken,
                dispatchRefreshToken,
            }}
        >
            {/* <PDFDownloadLink
                document={<Document style={{ fontFamily: 'Hiragino' }} />}
                fileName={`needtofixbug.pdf`}
            >
                hello
            </PDFDownloadLink> */}
            <QueryClientProvider client={queryClient}>
                <ConfigProvider
                    locale={i18next.language === 'en' ? enUS : zhCN}
                >
                    <div className="App">
                        {user ? <Dashbaord /> : <Login />}
                    </div>
                </ConfigProvider>
                <ReactQueryDevtools initialIsOpen={false} position="top-left" />
            </QueryClientProvider>
        </AppContext.Provider>
    )
}

export default App
