import { INSTANCE } from './API'

export const CreateVoucher = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/vouchers',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const EditVoucher = (form, token, voucherId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/vouchers/${voucherId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const ValidateVoucher = (status, token, voucherId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: `/vouchers/${voucherId}/${status}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })
