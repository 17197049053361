import {
    Controller,
} from 'react-hook-form'
import {
    Form,
    Input,
    InputNumber,
} from 'antd'

const HiddenFields = ({ control, name, field }) => {
    return (
        <Form.Item
            label="Hidden Field"
            style={{
                display: 'none',
            }}
        >
            <Controller
                name={`${name}.${field.field}`}
                control={control}
                render={({ value, onChange }) => {
                    return (
                        <>
                            {field.type === 'string' && (
                                <Input
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    value={value}
                                />
                            )}
                            {field.type === 'number' && (
                                <InputNumber
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    value={value}
                                />
                            )}
                        </>
                    )
                }}
            />
        </Form.Item>
    )
}

export default HiddenFields