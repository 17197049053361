import moment from 'moment'
import { useContext, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { PDFDownloadLink, pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { Badge, Button, Modal, Popover, Space, message } from 'antd'
import MyPDFDocument from 'src/components/MyPDF'
import DynamicStrings from '../components/DynamicStrings'
import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import { AppContext } from '../context/AppContext'
import {
    EditPurchaseOrderDetail,
    getDirectSalesList,
} from '../network/PurchaseOrders'
import { getDirectSuppliers } from '../network/Suppliers'
import { formatDate, keepTwoDecimals } from '../utils/utils'

const DirectSales = ({ goTo, history }) => {
    const { user } = useContext(AppContext)

    const configFilter = (history, selectedKeys, isLoading) => ({
        fields: [
            {
                type: 'selectSearch',
                default: '',
                label: 'SUPPLIERS_SUPPLIER_NAME',
                options: getDirectSuppliers,
                key: 'supplier',
                isTab: true,
            },
            {
                type: 'text',
                label: 'INVENTORY_PO_NUMBER',
                key: 'poId',
            },
            {
                type: 'daterange',
                label: 'ANALYTICS_DATES',
                key: ['dateStart', 'dateEnd'],
            },
        ],
        actions: [
            {
                label: 'ANALYTICS_EXPORT_CSV',
                isActive: !isLoading,
                csv: {
                    header: [
                        'PO ID',
                        'Supplier name',
                        'Total exc VAT',
                        'Total incl VAT',
                        'Date',
                        'Status',
                    ],
                    rowFilter: (order) => {
                        let rows = []
                        rows.push(order.poId)
                        rows.push(order.supplier.name)

                        var totalPrice = 0
                        order.purchaseOrderItems?.forEach((item, i) => {
                            const qty = item.quantity || 0
                            const unitPrice = item.unitPrice || 0
                            const vat = item.vatPercentage || 0

                            const totalWT = qty * unitPrice
                            const totalTax = totalWT * (vat / 100)

                            totalPrice += totalWT - totalTax
                        })
                        // rows.push(totalPrice)
                        rows.push(keepTwoDecimals(totalPrice))

                        var totalPriceVAT = 0
                        order.purchaseOrderItems?.forEach((item, i) => {
                            const qty = item.quantity || 0
                            const unitPrice = item.unitPrice || 0

                            const totalWT = qty * unitPrice

                            totalPriceVAT += totalWT
                        })
                        // rows.push(formatNb(totalPriceVAT))
                        rows.push(keepTwoDecimals(totalPriceVAT))

                        rows.push(formatDate(order.date))
                        rows.push(order.payment_status)
                        return rows
                    },
                    name: 'Direct Sales ' + moment().format('YYYY-MM-DD'),
                },
            },
            {
                label: 'DIRECT_SALES_CREATE_PO',
                isActive: true,
                action: () => history.push('purchase/create'),
            },
        ],
    })

    const columns = (goTo, keyRequest) => [
        {
            title: <DynamicStrings id="PO ID" />,
            dataIndex: 'poId',
            key: 'poId',
            sorter: true,
            onCell: () => {
                return {
                    onClick: (e) => e.stopPropagation(),
                }
            },
        },
        {
            title: <DynamicStrings id="SUPPLIERS_SUPPLIER_NAME" />,
            dataIndex: 'supplier',
            render: (supplier) => supplier?.name,
            key: 'supplier',
            sorter: false,
        },
        {
            title: <DynamicStrings id="DIRECT_SALES_TOTAL_EXC_VAT" />,
            dataIndex: 'purchaseOrderItems',
            render: (purchaseOrderItems) => {
                var totalPrice = 0

                purchaseOrderItems?.forEach((items, i) => {
                    const qty = items.quantity || 0
                    const unitPrice = items.unitPrice

                    const vat = items.vatPercentage || 0

                    const totalWT = qty * unitPrice
                    // const totalTax = totalWT * (vat / 100)
                    const totalTax =
                        (totalWT * (vat / 100)) / ((100 + vat) / 100)

                    const total = totalWT - totalTax
                    totalPrice += total
                })

                return keepTwoDecimals(totalPrice) || 0
            },
            key: 'totalPrice',
            sorter: false,
        },
        {
            title: <DynamicStrings id="DIRECT_SALES_TOTAL_INCL_VAT" />,
            dataIndex: 'purchaseOrderItems',
            render: (purchaseOrderItems) => {
                var totalPrice = 0

                purchaseOrderItems?.forEach((items, i) => {
                    const qty = items.quantity || 0
                    const unitPrice = items.unitPrice

                    totalPrice += qty * unitPrice
                })

                return keepTwoDecimals(totalPrice) || 0
            },
            key: 'totalPriceVAT',
            sorter: false,
        },
        {
            title: <DynamicStrings id="SERVICES_ORDER_LIST_DATE" />,
            dataIndex: 'date',
            render: (date) => formatDate(date),
            key: 'date',
            sorter: true,
        },
        {
            title: <DynamicStrings id="OFFERS_STATUS" />,
            key: 'payment_status',
            sorter: true,
            render: (e) => {
                let status = { color: 'default', text: 'unpaid' }

                if (e?.payment_status === 'paid') {
                    status = { color: 'success', text: 'paid' }
                }

                return (
                    <Badge
                        status={status.color}
                        text={<DynamicStrings id={status.text} />}
                    />
                )
            },
        },
        {
            key: 'action',
            width: 1,
            render: (e) => {
                const generatePdfDocument = async (Document, fileName) => {
                    const blob = await pdf(Document).toBlob()
                    saveAs(blob, fileName)
                }

                return (
                    <Space size="small" className="btnToTheEnd">
                        <Popover
                            content={
                                <Space>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            generatePdfDocument(
                                                <MyPDFDocument
                                                    lang="en"
                                                    order={e}
                                                    type="po"
                                                />,
                                                `${e.poId}.pdf`
                                            )
                                        }}
                                    >
                                        EN
                                    </Button>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            generatePdfDocument(
                                                <MyPDFDocument
                                                    lang="zh"
                                                    order={e}
                                                    type="po"
                                                />,
                                                `${e.poId}.pdf`
                                            )
                                        }}
                                    >
                                        ZH
                                    </Button>
                                </Space>
                            }
                            title={null}
                            // trigger="click"
                        >
                            <Button>
                                <DynamicStrings
                                    id={'PURCHASE_ORDERS_EXPORT_PDF'}
                                />
                            </Button>
                        </Popover>

                        <ModalBtn
                            poId={e.id}
                            keyRequest={keyRequest}
                            buttonDisabled={
                                e.stock_status === 'received' ? true : false
                            }
                            buttonLabel={
                                e.stock_status === 'received'
                                    ? 'DIRECT_SALES_STOCK_UPDATED'
                                    : 'DIRECT_SALES_STOCK_RECEIVED'
                            }
                            title={
                                <DynamicStrings id="DIRECT_SALES_STOCK_RECEIVED" />
                            }
                            content={
                                <DynamicStrings
                                    id={
                                        'DIRECT_SALES_SURE_TO_VALIDATE_RECEPTION_STOCK'
                                    }
                                />
                            }
                            request={EditPurchaseOrderDetail}
                            modifValue="received"
                            valueKey="stock_status"
                        />

                        <ModalBtn
                            poId={e.id}
                            keyRequest={keyRequest}
                            buttonDisabled={
                                // e.payment_status === 'paid' ? true : false
                                user.role === 'logistic'
                                    ? true
                                    : e.payment_status === 'paid'
                                    ? true
                                    : false
                            }
                            buttonLabel="paid"
                            title={
                                <DynamicStrings id="DIRECT_SALES_PAYMENT_DONE" />
                            }
                            content={
                                <DynamicStrings
                                    id={'DIRECT_SALES_SURE_TO_VALIDATE_PAYMENT'}
                                />
                            }
                            request={EditPurchaseOrderDetail}
                            modifValue="paid"
                            valueKey="payment_status"
                        />
                        {/* <Button
                            onClick={() => goTo(`/finance/${e.id}`)}
                            type="primary"
                        >
                            <DynamicStrings id={'INVENTORY_VIEW_DETAILS'} />
                        </Button> */}
                    </Space>
                )
            },
        },
    ]

    return (
        <PageWrap title="MENU_PURCHASE_ORDERS">
            <TableBlock
                columns={columns}
                filters={configFilter}
                name={'getDirectSalesList'}
                request={getDirectSalesList}
                pageSize={80}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push(`/finance/${record.id}`)
                        },
                    }
                }}
            />
        </PageWrap>
    )
}

const ModalBtn = ({
    buttonLabel,
    buttonDisabled,
    keyRequest,
    poId,
    request,
    valueKey,
    modifValue,
    title,
    content,
}) => {
    const [visible, setVisible] = useState(false)
    const queryClient = useQueryClient()
    const { token } = useContext(AppContext)

    const mutation = useMutation(
        () => request({ [valueKey]: modifValue }, token.token, poId),
        {
            onSuccess: (data, variables, context) => {
                queryClient.setQueryData(keyRequest, (oldData) => {
                    let oldDataIndex = oldData.list.findIndex(
                        (e) => e.id === data.id
                    )
                    oldData.list[oldDataIndex][valueKey] = data[valueKey]
                    return oldData
                })
                setVisible(false)
            },
            onError: () => {
                message.error('An error occurs')
                setVisible(false)
            },
        }
    )

    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    return (
        <>
            <Button
                type="primary"
                disabled={buttonDisabled}
                onClick={(event) => {
                    event.stopPropagation()
                    showModal()
                }}
            >
                <DynamicStrings id={buttonLabel} />
            </Button>
            <Modal
                title={title}
                centered
                visible={visible}
                onOk={(event) => {
                    event.stopPropagation()
                    mutation.mutate()
                }}
                onCancel={(event) => {
                    event.stopPropagation()
                    handleCancel()
                }}
                okText={<DynamicStrings id="OFFER_LIVE_YES" />}
                cancelText={<DynamicStrings id="OFFER_LIVE_NO" />}
            >
                {content}
            </Modal>
        </>
    )
}

export default DirectSales
