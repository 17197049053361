import { Statistic, Card, Typography } from 'antd'

const { Text } = Typography

const StatCard = ({ subtitle, ...props }) => (
    <Card className={subtitle ? 'has-subtitle' : ''} style={{ width: 315 }}>
        <Statistic {...props} />
        {subtitle && <Text>{subtitle}</Text>}
    </Card>
)

export default StatCard
