import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Form } from 'antd'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

import {
    Information,
    Other,
    cleanBeforeSending,
} from '../../constants/forms/Suppliers'
import { EditSupplier, getSingleSupplier, getSuppliersList } from '../../network/Suppliers'

const SupplierDetails = ({ history }) => {
    const [form] = Form.useForm()
    const { token } = useContext(AppContext)
    const [suppliers, setSuppliers] = useState([])

    useEffect(() => {
        getSuppliersList(
            token.token
        ).then((data) => {
            setSuppliers(data.list)
        })
    }, [])

    let { supplierId } = useParams()
    const supplierDetails = useQuery(
        [
            'getSingleSupplier',
            {
                supplierId: supplierId,
                token: token.token,
            },
        ],
        getSingleSupplier,
        {
            refetchOnWindowFocus: false,
        }
    )

    return (
        <PageWrap
            goBack
            isLoading={supplierDetails.isLoading}
            title={supplierDetails.data?.name}
        >
            {supplierDetails.isError ? (
                <Error retry={() => supplierDetails.refetch()} />
            ) : supplierDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    form={form}
                    title="create_offer"
                    request={EditSupplier}
                    cleanBeforeSending={cleanBeforeSending}
                    checkValidity={(values) => {
                        for (const i in suppliers) {
                            if (supplierId !== suppliers[i].id) {
                                if (suppliers[i].name?.toLowerCase() === values.name?.toLowerCase()) {
                                    return 'Supplier already exists'
                                }
                            }
                        }

                        return false
                    }}
                    id={supplierId}
                    data={supplierDetails.data}
                    onSuccess="/suppliers"
                    onCancel="/suppliers"
                    invalidate={['getSingleSupplier', 'getSuppliers']}
                >
                    <div className="SectionHorizontalWrap">
                        <FormBlock
                            weight={2}
                            form={form}
                            formTemplate={Information}
                            data={supplierDetails.data}
                        />
                        <FormBlock
                            form={form}
                            formTemplate={Other}
                            data={supplierDetails.data}
                        />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default SupplierDetails
