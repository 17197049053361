import { useMemo, useEffect, useState } from 'react'
import { Space, Spin, Typography, message } from 'antd'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { formatNb } from '../../utils/utils'

// const { Paragraph } = Typography

const titleWrap = {
    width: '88px',
    minHeight: '26px',
    paddingLeft: '5px',
    fontSize: '11px',
    fontWeight: 'bold',
    borderRight: '1px solid #F0F0F0',
    display: 'flex',
    alignItems: 'center',
}

const titleText = {
    fontSize: '11px',
    fontWeight: 'bold',
}

// pack.actualStock < 6
//     ? ' is-very-low'
//     : pack.actualStock < 16
//     ? ' is-low'
//     : ''

const caseWrap = (stock, isDark, isTextDark) => ({
    width: '113px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #F0F0F0',

    color: isTextDark
        ? undefined
        : stock < 6
        ? 'rgba(255, 77, 79, 1)'
        : stock < 16
        ? 'rgba(255, 122, 69, 1)'
        : undefined,

    backgroundColor:
        stock < 6
            ? isDark
                ? 'rgba(255, 225, 223, 1)'
                : 'rgba(255, 241, 240, 1)'
            : stock < 16
            ? isDark
                ? 'rgba(252, 229, 219, 1)'
                : 'rgba(253, 240, 235, 1)'
            : undefined,
})

const caseText = {
    width: '100%',
    fontSize: '11px',
    textAlign: 'center',
}

const lineWrap = {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '11px',
}

const Title = ({ title }) => (
    <div style={titleWrap}>
        <span style={titleText}>{title}</span>
    </div>
)

const getFFPrice = (item) => {
    if (!item) return undefined

    const sold = item?.stock - item?.actualStock
    const dividable = sold - (sold % item?.freeFall?.quantityTrigger)

    const dropedPrice =
        (dividable / item?.freeFall?.quantityTrigger) *
        item?.freeFall?.dropAmount

    if (item?.price - dropedPrice < item?.freeFall?.lowestPrice) {
        return item?.freeFall?.lowestPrice
    }

    return item?.price - dropedPrice
}

const getBouseMinMax = (bourse) => {
    let min = null
    let max = null

    for (let i = 0; i < bourse.length; i++) {
        if (min === null || bourse[i]?.from < min?.from) {
            min = bourse[i]
        }

        if (max === null || bourse[i]?.to > max?.from) {
            max = bourse[i]
        }
    }

    return { max, min }
}

const getBoursePrice = (items, bourse, index) => {
    if (!items) return '-'
    let totalPacks = 0

    for (const item of items) {
        totalPacks += item?.stock - item?.actualStock
    }

    const minmax = getBouseMinMax(bourse)

    if (totalPacks === 0) {
        return minmax?.min?.unitPrice
    }

    if (totalPacks > minmax.max.to) {
        return minmax?.max?.unitPrice
    }

    for (const price of bourse) {
        if (totalPacks >= price?.from && totalPacks <= price?.to) {
            return price?.unitPrice
        }
    }

    return !!index || index === 0 ? items[index]?.price : '-'
}

const PacksStatLive = ({ packs, items, freeFall, bourse }) => {
    const { t } = useTranslation()
    const [oldItem, setOldItem] = useState(items)

    useEffect(() => {
        const ffMessage = async () => {
            for (let i = 0; i < items.length; i++) {
                const nvPrice = getFFPrice(items[i])

                if (nvPrice !== getFFPrice(oldItem[i])) {
                    message.warning(
                        `add on ${items[i]?.shortName} new price is ${nvPrice?.toFixed(2)}¥`
                    )
                }
            }

            setOldItem(items)
        }

        const bMessage = async () => {
            const nvPrice = getBoursePrice(items, bourse)

            if (nvPrice !== getBoursePrice(oldItem, bourse)) {
                message.warning(`New Bourse price is ${nvPrice?.toFixed(2)}¥`)
            }

            setOldItem(items)
        }

        if (freeFall && oldItem !== items) ffMessage()

        if (!!bourse && oldItem !== items) bMessage()
    }, [items])

    return (
        <div style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
            {!packs && !items ? (
                <div className="spin-loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <div style={lineWrap}>
                        <Title title={t('OFFER_LIVE_ITEMS')} />

                        {packs?.map((pack) => (
                            <div
                                key={pack._id}
                                style={caseWrap(pack.actualStock)}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                    }}
                                >
                                    {`${t('OFFER_LIVE_SELECTION')} ${
                                        pack.shortName
                                    }`}
                                </span>
                            </div>
                        ))}
                        {items?.map((item) => (
                            <div
                                key={item._id}
                                style={caseWrap(item.actualStock)}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                    }}
                                >
                                    {`${t('OFFER_LIVE_SINGLE_ITEM')} ${
                                        item.shortName
                                    }`}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            ...lineWrap,
                            backgroundColor: '#fafafa',
                        }}
                    >
                        <Title title={t('OFFER_LIVE_NAME')} />

                        {packs?.map((pack) => (
                            <div
                                key={pack._id}
                                style={caseWrap(pack.actualStock, true)}
                            >
                                <span style={caseText}>
                                    {pack?.name
                                        ? pack.name[i18next.language]
                                        : ''}
                                </span>
                            </div>
                        ))}
                        {items?.map((item) => (
                            <div
                                key={item._id}
                                style={caseWrap(item.actualStock, true)}
                            >
                                <span style={caseText}>
                                    {item.product?.name
                                        ? item.product?.name[i18next.language]
                                        : ''}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div style={lineWrap}>
                        <Title title={t('OFFER_LIVE_QT_AV')} />

                        {packs?.map((pack) => (
                            <div
                                key={pack._id}
                                style={caseWrap(pack.actualStock)}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                    }}
                                >
                                    {pack.actualStock === 0
                                        ? 'Sold out'
                                        : pack.actualStock}
                                </span>
                            </div>
                        ))}
                        {items?.map((item) => (
                            <div
                                key={item._id}
                                style={caseWrap(item.actualStock)}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                    }}
                                >
                                    {item.actualStock === 0
                                        ? 'Sold out'
                                        : item.actualStock}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            ...lineWrap,
                            backgroundColor: '#fafafa',
                        }}
                    >
                        <Title title={t('OFFER_LIVE_SOLD')} />

                        {packs?.map((pack) => (
                            <div
                                key={pack._id}
                                style={caseWrap(pack.actualStock, true, true)}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                    }}
                                >
                                    {pack.stock - pack.actualStock}
                                </span>
                            </div>
                        ))}
                        {items?.map((item) => (
                            <div
                                key={item._id}
                                style={caseWrap(item.actualStock, true, true)}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                    }}
                                >
                                    {item.stock - item.actualStock}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div style={lineWrap}>
                        <Title title={t('OFFER_LIVE_PRICE_CNY')} />

                        {packs?.map((pack) => (
                            <div
                                key={pack._id}
                                style={caseWrap(pack.actualStock, false, true)}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                    }}
                                >
                                    {`${formatNb(pack.price)}¥`}
                                </span>
                            </div>
                        ))}
                        {items?.map((item, i) => (
                            <div
                                key={item._id}
                                style={{
                                    ...caseWrap(item.actualStock, false, true),
                                    alignItems: 'start',
                                }}
                            >
                                <span
                                    style={{
                                        ...caseText,
                                        padding: '5px 0px',
                                    }}
                                >
                                    {!!bourse ? (
                                        getBoursePrice(items, bourse, i)
                                    ) : freeFall ? (
                                        <>
                                            {item.stock - item.actualStock !==
                                                0 && (
                                                <span
                                                    style={{
                                                        textDecoration:
                                                            'line-through',
                                                    }}
                                                >
                                                    {`${formatNb(item.price)}¥`}
                                                    <br />
                                                </span>
                                            )}

                                            {`${formatNb(getFFPrice(item))}¥`}
                                        </>
                                    ) : !!item?.multipleItem?.length ? (
                                        item?.multipleItem?.map((b, i) => (
                                            <>
                                                {`${b.quantity}: ${formatNb(b.unitPrice)}¥`}
                                                <br />
                                            </>
                                        ))
                                    ) : (
                                        `${formatNb(item.price)}¥`
                                    )}

                                    {/* {item.stock - item.actualStock} */}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default PacksStatLive
