import { INSTANCE, INSTANCELIST } from './API'

export const getCustomers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/customers/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getCustomersWithAreas = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/customers/areas',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify({
                    ...filter,
                    status: { $ne: 'discontinued' }
                }),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getCustomersVoucher = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { filter, sort, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/vouchers/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                filter: JSON.stringify(filter),
                sort: JSON.stringify(sort),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CustomerDetails = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { userId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/customers/${userId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateCustomer = (form, token) =>
    new Promise((resolve, reject) => {
        form.flagged = false

        const cleanGroup = []
        form.wechatGroups.map((commu) =>
            commu.groupNumber ? cleanGroup.push(commu) : null
        )
        form.wechatGroups = cleanGroup

        INSTANCE({
            method: 'post',
            url: '/customers',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const EditCustomer = (form, token, customerId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/customers/${customerId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })
