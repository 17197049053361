import { useFieldArray, Controller, useWatch } from 'react-hook-form'
import { Form, Card, Button, InputNumber } from 'antd'

const Bourses = ({ control, channel, isEdit, errors }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${channel}.bourses`,
    })

    const typeValue = useWatch({
        control,
        name: 'type',
    })

    if (typeValue !== 'bourse') return null

    return (
        <Card bordered={false} title="Bourse rule">
            {fields.map((field, index) => (
                <div
                    key={field.id}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(5, 1fr)',
                        gridGap: 8,
                    }}
                >
                    <Form.Item label="From (Qty)">
                        <Controller
                            name={`${channel}.bourses.${index}.from`}
                            control={control}
                            // defaultValue=""
                            render={({ value, onChange }) => (
                                <InputNumber
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    <Form.Item label="To (Qty)">
                        <Controller
                            name={`${channel}.bourses.${index}.to`}
                            control={control}
                            // defaultValue=""
                            render={({ value, onChange }) => (
                                <InputNumber
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Price (¥)"
                        validateStatus={
                            !!errors?.[index]?.['unitPrice']
                                ? 'error'
                                : undefined
                        }
                        help={errors?.[index]?.['unitPrice']?.message}
                    >
                        <Controller
                            name={`${channel}.bourses.${index}.unitPrice`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <InputNumber
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    <Button
                        type="link"
                        onClick={() => remove(index)}
                        style={{ margin: 'auto auto auto 0' }}
                        disabled={isEdit}
                    >
                        Delete rule
                    </Button>
                </div>
            ))}

            <Button type="dashed" onClick={append} disabled={isEdit}>
                Add rule
            </Button>
        </Card>
    )
}

export default Bourses
