import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { AppContext } from '../../context/AppContext'
import { Form } from 'antd'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

import {
    PurchaseOrdersEdit,
    cleanBeforeSending,
    Product,
} from '../../constants/forms/PurchaseOrders'
import {
    EditPurchaseOrderDetail,
    getSinglePurchaseOrder,
} from '../../network/PurchaseOrders'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const EditPurchaseOrder = () => {
    const [form] = Form.useForm()
    const { token } = useContext(AppContext)
    const poId = useParams().id
    const { t } = useTranslation()

    const purchaseOrderDetails = useQuery(
        [
            'getSinglePurchaseOrder',
            {
                poId: poId,
                token: token.token,
            },
        ],
        getSinglePurchaseOrder,
        {
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (oldData) => {
        if (oldData.date) {
            oldData.date = moment(oldData.date)
        }
        return oldData
    }

    return (
        <PageWrap
            goBack
            isLoading={purchaseOrderDetails.isLoading}
            title={t('PURCHASE_ORDERS_EDIT_PURCHASE_ORDER')}
        >
            {purchaseOrderDetails.isError ? (
                <Error retry={() => purchaseOrderDetails.refetch()} />
            ) : purchaseOrderDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    form={form}
                    title="edit_purchase_order"
                    request={EditPurchaseOrderDetail}
                    // cleanBeforeSending={cleanBeforeSending}
                    id={poId}
                    data={formatData(purchaseOrderDetails.data)}
                    onSuccess={`/finance/${poId}`}
                    onCancel={'/direct-sales'}
                    invalidate={[
                        'getDirectSalesList',
                        'getSinglePurchaseOrder',
                    ]}
                >
                    <div className="SectionHorizontalWrap">
                        <div
                            className="SectionVerticalWrap"
                            style={{ flex: 1 }}
                        >
                            <FormBlock
                                form={form}
                                formTemplate={PurchaseOrdersEdit}
                            />
                            <FormBlock form={form} formTemplate={Product} />
                        </div>
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default EditPurchaseOrder
