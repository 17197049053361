import {
    useFieldArray,
    Controller,
    useWatch,
    useFormContext,
} from 'react-hook-form'
import {
    Input,
    Form,
    Card,
    Button,
    Space,
    Checkbox,
    Select,
    Typography,
    Divider,
    InputNumber,
    DatePicker,
} from 'antd'
import { useState, useEffect } from 'react'

import numberToLetter from './numberToLetter'
import UploadField from './Upload'

const { Title, Text } = Typography

const Lottery = ({
    packs,
    items,
    /* draws, */ control,
    channel,
    isEdit,
    errors,
    clearErrors,
}) => {
    const { getValues, reset } = useFormContext()

    const lotteryEnable = useWatch({
        control,
        name: `${channel}.lotteryEnable`,
    })

    const conditionType = useWatch({
        control,
        name: `${channel}.lottery.conditionType`,
    })

    const extraConditionType = useWatch({
        control,
        name: `${channel}.lottery.extraConditionType`,
    })

    return (
        <Card
            bordered={false}
            title={
                <Space>
                    Lottery
                    <Controller
                        name={`${channel}.lotteryEnable`}
                        control={control}
                        render={({ value, onChange }) => (
                            <Checkbox
                                checked={value}
                                // onChange={(e) => onChange(e.target.checked)}
                                onChange={(e) => {
                                    if (e.target.checked === true) {
                                        const values = getValues()
                                        values[channel].lotteryEnable = true
                                        values[channel].lottery = {
                                            draws: [],
                                        }
                                        reset(values, { isDirty: true })
                                    }
                                    onChange(e.target.checked)
                                }}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Space>
            }
        >
            {lotteryEnable && (
                <>
                    <Title level={5}>Tickets</Title>

                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                            gridGap: 8,
                        }}
                    >
                        <Form.Item
                            label="Get 1 ticket for"
                            style={{ margin: 0 }}
                            validateStatus={
                                !!errors?.['conditionType']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.['conditionType']?.message}
                        >
                            <Controller
                                name={`${channel}.lottery.conditionType`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    >
                                        <Select.Option value="spend">
                                            Spend (¥)
                                        </Select.Option>
                                        <Select.Option value="buy">
                                            Buy items
                                        </Select.Option>
                                        <Select.Option value="order">
                                            Order
                                        </Select.Option>
                                    </Select>
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                conditionType === 'spend'
                                    ? 'Amount (¥)'
                                    : 'Value'
                            }
                            style={{ margin: 0 }}
                            validateStatus={
                                !!errors?.['conditionValue']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.['conditionValue']?.message}
                        >
                            <Controller
                                name={`${channel}.lottery.conditionValue`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item label="Extra ticket" style={{ margin: 0 }}>
                            <Controller
                                name={`${channel}.lottery.extraConditionType`}
                                control={control}
                                // defaultValue={null}
                                render={({ value, onChange }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    >
                                        {(conditionType === 'spend' ||
                                            conditionType === 'order') && (
                                            <Select.Option value="buy_for">
                                                Buy items
                                            </Select.Option>
                                        )}
                                        {(conditionType === 'buy' ||
                                            conditionType === 'order') && (
                                            <Select.Option value="spend_for">
                                                Spend (¥)
                                            </Select.Option>
                                        )}
                                        <Select.Option value={null}>
                                            None
                                        </Select.Option>
                                    </Select>
                                )}
                            />
                        </Form.Item>
                        {extraConditionType !== null && (
                            <Form.Item
                                label={
                                    extraConditionType === 'spend_for'
                                        ? 'Amount (¥)'
                                        : 'Value'
                                }
                                style={{ margin: 0 }}
                                validateStatus={
                                    !!errors?.['extraConditionValue']
                                        ? 'error'
                                        : undefined
                                }
                                help={errors?.['extraConditionValue']?.message}
                            >
                                <Controller
                                    name={`${channel}.lottery.extraConditionValue`}
                                    control={control}
                                    rules={{ required: 'Required' }}
                                    render={({ value, onChange }) => (
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            value={value}
                                            onChange={onChange}
                                            disabled={isEdit}
                                        />
                                    )}
                                />
                            </Form.Item>
                        )}
                    </div>

                    <Draws
                        control={control}
                        channel={channel}
                        totalPacks={packs}
                        totalItems={items}
                        isEdit={isEdit}
                        errors={errors?.['draws']}
                        clearErrors={clearErrors}
                    />
                </>
            )}
        </Card>
    )
}

const Draws = ({
    control,
    channel,
    totalPacks,
    totalItems,
    isEdit,
    errors,
    clearErrors,
}) => {
    const { getValues, reset } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name: `${channel}.lottery.draws`,
    })

    const onRemove = (index) => {
        const values = getValues()
        values[channel].lottery.draws.splice(index, 1)
        reset(values, { isDirty: true })
    }

    return (
        <>
            {fields.map((field, index) => (
                <div key={field.id}>
                    <Divider style={{ marginBottom: 12 }} />
                    <Space align="center">
                        <Title level={5} style={{ margin: 0 }}>{`Draw ${
                            index + 1
                        }`}</Title>
                        <Button
                            type="link"
                            onClick={() => onRemove(index)}
                            // onClick={() => remove(index)}
                            style={{ margin: 'auto auto auto 0' }}
                            disabled={isEdit}
                        >
                            Delete draw
                        </Button>
                    </Space>

                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                            gridGap: 8,
                        }}
                    >
                        <Form.Item
                            label="Unlock condition"
                            validateStatus={
                                !!errors?.[index]?.['conditionType']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.[index]?.['conditionType']?.message}
                        >
                            <Controller
                                name={`${channel}.lottery.draws.${index}.conditionType`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    >
                                        <Select.Option value="number_of_order">
                                            Number of orders reach
                                        </Select.Option>
                                        <Select.Option value="x_item_sold">
                                            Number of items reach
                                        </Select.Option>
                                    </Select>
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Value"
                            validateStatus={
                                !!errors?.[index]?.['conditionValue']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.[index]?.['conditionValue']?.message}
                        >
                            <Controller
                                name={`${channel}.lottery.draws.${index}.conditionValue`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                    </div>

                    <Gifts
                        // gift={draws?.[index]?.gifts}
                        drawIndex={index}
                        control={control}
                        channel={channel}
                        totalPacks={totalPacks}
                        totalItems={totalItems}
                        isEdit={isEdit}
                        errors={errors?.[index]}
                        clearErrors={clearErrors}
                    />
                </div>
            ))}
            <Button
                type="primary"
                onClick={append}
                style={{ marginTop: 12 }}
                disabled={isEdit}
            >
                Add draw
            </Button>
        </>
    )
}

const Gifts = ({
    drawIndex,
    control,
    channel,
    totalPacks,
    totalItems,
    isEdit,
    errors,
    clearErrors,
}) => {
    const { getValues, reset } = useFormContext()

    const name = `${channel}.lottery.draws.${drawIndex}`
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${name}.gifts`,
    })

    const onRemove = (index) => {
        const values = getValues()
        values[channel].lottery.draws[drawIndex].gifts.splice(index, 1)
        reset(values, { isDirty: true })
    }

    return (
        <div>
            {fields.map((field, index) => (
                <GiftLine
                    key={field.id}
                    drawIndex={drawIndex}
                    giftIndex={index}
                    control={control}
                    channel={channel}
                    totalPacks={totalPacks}
                    totalItems={totalItems}
                    remove={() => onRemove(index)}
                    // remove={() => remove(index)}
                    isEdit={isEdit}
                    errors={errors?.gifts?.[index]}
                />
            ))}
            <Button
                type="dashed"
                onClick={() => {
                    clearErrors(`${name}.size`)
                    append({})
                }}
                disabled={isEdit}
            >
                Add gift
            </Button>
            {!!errors?.size && (
                <div style={{ marginTop: 12 }}>
                    <Text type="danger">{errors?.size?.message}</Text>
                </div>
            )}
        </div>
    )
}

const GiftLine = ({
    drawIndex,
    giftIndex,
    control,
    channel,
    totalPacks,
    totalItems,
    remove,
    isEdit,
    errors,
    // type,
}) => {
    const { getValues } = useFormContext()
    const [giftType, setGiftType] = useState()
    // const giftType = useWatch({
    //     control,
    //     name: `${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.type`,
    // })

    useEffect(() => {
        const values = getValues()

        setGiftType(
            values?.[channel]?.lottery?.draws?.[drawIndex]?.gifts?.[giftIndex]
                ?.type
        )
    }, [])

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(5, 1fr)',
                gridGap: 8,
            }}
        >
            <Form.Item
                label="Get"
                validateStatus={!!errors?.['type'] ? 'error' : undefined}
                help={errors?.['type']?.message}
            >
                <Controller
                    name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.type`}
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ value, onChange }) => {
                        const handleChange = (e) => {
                            onChange(e)
                            setGiftType(e)
                        }

                        return (
                            <Select
                                value={value}
                                onChange={handleChange}
                                disabled={isEdit}
                            >
                                <Select.Option value="pack">Pack</Select.Option>
                                <Select.Option value="add_on">
                                    Add ons
                                </Select.Option>
                                <Select.Option value="custom">
                                    Custom
                                </Select.Option>
                                <Select.Option value="voucher">
                                    Voucher
                                </Select.Option>
                                <Select.Option value="discount">
                                    Discount
                                </Select.Option>
                            </Select>
                        )
                    }}
                />
            </Form.Item>

            {giftType === 'pack' && (
                <Form.Item
                    label="Pack"
                    validateStatus={!!errors?.['pack'] ? 'error' : undefined}
                    help={errors?.['pack']?.message}
                >
                    <Controller
                        name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.pack`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            >
                                {Array.from(Array(totalPacks).keys()).map(
                                    (e, index) => (
                                        <Select.Option
                                            key={index}
                                            value={numberToLetter(index)}
                                        >
                                            {numberToLetter(index)}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        )}
                    />
                </Form.Item>
            )}
            {giftType === 'add_on' && (
                <Form.Item
                    label="Add ons"
                    validateStatus={
                        !!errors?.['singleItem'] ? 'error' : undefined
                    }
                    help={errors?.['singleItem']?.message}
                >
                    <Controller
                        name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.singleItem`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            >
                                {Array.from(Array(totalItems).keys()).map(
                                    (e, index) => (
                                        <Select.Option
                                            key={index}
                                            value={numberToLetter(
                                                totalPacks + index
                                            )}
                                        >
                                            {numberToLetter(totalPacks + index)}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        )}
                    />
                </Form.Item>
            )}
            {giftType === 'custom' && (
                <>
                    <Form.Item
                        label="Name (中文)"
                        validateStatus={
                            !!errors?.['custom']?.['zh'] ? 'error' : undefined
                        }
                        help={errors?.['custom']?.['zh']?.message}
                    >
                        <Controller
                            name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.custom.zh`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    {channel === 'miniprogram' && (
                        <Form.Item
                            label="Illustration"
                            style={{ gridRow: 2 }}
                            validateStatus={
                                !!errors?.['customImage'] ? 'error' : undefined
                            }
                            help={errors?.['customImage']?.message}
                        >
                            <Controller
                                name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.customImage`}
                                control={control}
                                rules={{ required: 'Required' }}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="gift-image"
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Name (English)"
                        style={{ gridRow: 2, gridColumn: 2 }}
                        validateStatus={
                            !!errors?.['custom']?.['en'] ? 'error' : undefined
                        }
                        help={errors?.['custom']?.['en']?.message}
                    >
                        <Controller
                            name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.custom.en`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                </>
            )}
            {giftType === 'voucher' && (
                <>
                    <Form.Item
                        label="Amount (¥)"
                        validateStatus={
                            !!errors?.['voucherValue'] ? 'error' : undefined
                        }
                        help={errors?.['voucherValue']?.message}
                    >
                        <Controller
                            name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.voucherValue`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <InputNumber
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Expiration date"
                        validateStatus={
                            !!errors?.['voucherExpiration']
                                ? 'error'
                                : undefined
                        }
                        help={errors?.['voucherExpiration']?.message}
                    >
                        <Controller
                            name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.voucherExpiration`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <DatePicker
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    format={'YYYY-MM-DD'}
                                    allowClear
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                </>
            )}
            {giftType === 'discount' && (
                <Form.Item
                    label="Amount"
                    validateStatus={
                        !!errors?.['discountAmount'] ? 'error' : undefined
                    }
                    help={errors?.['discountAmount']?.message}
                >
                    <Controller
                        name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.discountAmount`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            )}

            <Form.Item
                label="Nb of winners"
                validateStatus={
                    !!errors?.['winnerNumber'] ? 'error' : undefined
                }
                help={errors?.['winnerNumber']?.message}
            >
                <Controller
                    name={`${channel}.lottery.draws.${drawIndex}.gifts.${giftIndex}.winnerNumber`}
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ value, onChange }) => (
                        <InputNumber
                            style={{ width: '100%' }}
                            value={value}
                            onChange={onChange}
                            disabled={isEdit}
                        />
                    )}
                />
            </Form.Item>

            <Button
                type="link"
                onClick={remove}
                style={{ margin: 'auto auto auto 0' }}
                disabled={isEdit}
            >
                Delete
            </Button>
        </div>
    )
}

export default Lottery
