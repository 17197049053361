import { Form } from 'antd'

import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'

import { EditCustomer } from '../../network/Customers'
import {
    PersonalInformation,
    Contacts,
    Fapiao,
    WeChatGroups,
    Address,
    Pets,
    cleanBeforeSending,
} from '../../constants/forms/Customers'

const CustomersInformations = ({ customer }) => {
    const [form] = Form.useForm()

    return (
        <FormWrap
            form={form}
            title="create_recipe"
            request={EditCustomer}
            onSuccess="/customers"
            id={customer.id}
            data={customer}
            cleanBeforeSending={cleanBeforeSending}
            invalidate={['CustomerDetails', 'getCustomers']}
        >
            <div className="SectionHorizontalWrap">
                <div className="SectionVerticalWrap" style={{ flex: 2 }}>
                    <FormBlock form={form} formTemplate={PersonalInformation} />
                    <FormBlock form={form} formTemplate={Address} />
                    <FormBlock form={form} formTemplate={Contacts} />
                    <FormBlock form={form} formTemplate={Fapiao} />
                </div>

                <div className="SectionVerticalWrap" style={{ flex: 1 }}>
                    <FormBlock form={form} formTemplate={WeChatGroups} />
                    <FormBlock form={form} formTemplate={Pets} />
                </div>
            </div>
        </FormWrap>
    )
}
export default CustomersInformations
