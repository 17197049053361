import React, { useContext } from 'react'
import { Space } from 'antd'
import { useQuery } from 'react-query'
import { AppContext } from '../../context/AppContext'
import { useTranslation } from 'react-i18next'

import { CustomerAnalytics } from '../../network/API'

import Loader from '../../components/Loader'
import Error from '../../components/Error'
import StatCard from '../../components/StatCard'
import ChartPie from '../../components/ChartPie'
import DynamicStrings from '../../components/DynamicStrings'
import moment from 'moment'

import { formatNb } from '../../../src/utils/utils'

const CustomersAnalytics = ({ userId, createdAt }) => {
    const { t } = useTranslation()
    const { token } = useContext(AppContext)
    const analytics = useQuery(
        [
            'getCustomerAnalytics',
            {
                userId,
                token: token.token,
            },
        ],
        CustomerAnalytics
    )

    const getAverageOrderRate = (createdAt, orderCount) => {
        if (orderCount === 0) return t('CUSTOMERS_NEVER_ORDERED')

        var create = moment(createdAt)
        var now = moment()
        let nbDays = now.diff(create, 'days') + 1

        let orderRate = Math.round(nbDays / orderCount)

        if (orderRate > 90) {
            return Math.round(orderRate / 30) + ' Months'
        } else if (orderRate > 30) {
            return Math.round(orderRate / 7) + ' Weeks'
        }

        return orderRate + ' days'
    }

    const totalVoucher = () => {
        let total = 0

        analytics.data?.voucherAmount.forEach((e) => (total += e.totalAmount))
        return formatNb(total)
    }

    const totalOrder = () => {
        let total = 0

        analytics.data?.orderByChannel?.forEach((e) => (total += e.spent))
        return formatNb(total)
    }

    // const dateFromLastOrder = () => {
    //     if (analytics.data.lastOrder?.length) {
    //         const today = new Date()
    //         let lastOrderDate = new Date(analytics.data.lastOrder[0].orderDate)

    //         var Difference_In_Time = today.getTime() - lastOrderDate.getTime()
    //         var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

    //         return Math.round(Difference_In_Days) + ' days'
    //     }
    //     return 'Never ordered'
    // }

    return analytics.isError ? (
        <Error retry={() => analytics.refetch()} />
    ) : (
        <div style={{ backgroundColor: '#f0f2f5' }}>
            {analytics.isLoading ? (
                <Loader />
            ) : (
                <Space
                    direction="vertical"
                    style={{ padding: '24px 0px 50px 24px' }}
                >
                    <Space>
                        <ChartPie
                            title={t('ANALYTICS_SALES_CHANNEL_RATIO')}
                            data={analytics.data?.orderByChannel?.map((e) => ({
                                _id: e._id,
                                totalAmount: e.spent,
                            }))}
                        />
                        <ChartPie
                            title={t('CUSTOMERS_TOTAL_SALES_BY_COMMUNITY')}
                            data={analytics.data?.orderByCommunity?.map(
                                (e) => ({
                                    _id: e._id,
                                    totalAmount: e.spent,
                                })
                            )}
                        />
                        <Space direction="vertical">
                            <StatCard
                                title={
                                    <DynamicStrings id="CUSTOMERS_LAST_ORDER" />
                                }
                                value={
                                    // dateFromLastOrder()(
                                    analytics.data.lastOrder?.length
                                        ? moment(
                                              analytics.data.lastOrder[0]
                                                  .orderDate
                                          ).fromNow()
                                        : t('CUSTOMERS_NEVER_ORDERED')
                                }
                            />
                            <StatCard
                                title={<DynamicStrings id="CUSTOMERS_REORDER_RATE" />}
                                value={getAverageOrderRate(
                                    // createdAt,
                                    analytics.data.firstOrder?.length ? moment(analytics.data.firstOrder[0].orderDate) : createdAt,
                                    analytics?.data?.orderCount
                                )}
                            />
                            <StatCard
                                title={t('CUSTOMERS_FAN_OF')}
                                value={
                                    analytics?.data?.orderByOfferInternalName
                                        ?.length
                                        ? analytics?.data
                                              ?.orderByOfferInternalName[0]._id
                                        : '-'
                                }
                            />
                        </Space>
                    </Space>
                    <Space>
                        <StatCard
                            title={
                                <DynamicStrings id="ANALYTICS_TOTAL_ORDERS" />
                            }
                            value={analytics.data?.orderCount}
                        />
                        <StatCard
                            title={<DynamicStrings id="CUSTOMERS_TOTAL_SPENT" />}
                            value={totalOrder()}
                        />
                        <StatCard
                            title={
                                <DynamicStrings id="ANALYTICS_TOTAL_CLAIMS" />
                            }
                            value={analytics.data?.claimCount}
                        />
                        {/* OFFER_ANALYTICS_VOLUME
                        OFFER_ANALYTICS_VALUE */}
                        <StatCard
                            title={
                                <DynamicStrings
                                    id="ANALYTICS_TOTAL_VOUCHERS"
                                    suffix="(value)"
                                />
                            }
                            value={totalVoucher()}
                        />
                        <StatCard
                            title={
                                <DynamicStrings
                                    id="ANALYTICS_TOTAL_VOUCHERS"
                                    suffix="(volume)"
                                />
                            }
                            value={analytics.data?.voucherCount}
                        />
                    </Space>
                </Space>
            )}
        </div>
    )
}

export default CustomersAnalytics
