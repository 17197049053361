import numberToLetter from './numberToLetter'

const cleanBeforeSending = (oldData) => {
    if (oldData.channel === 'wechat_group') {
        oldData.miniprogram = null
    }
    if (oldData.channel === 'miniprogram') {
        oldData.wechatGroup = null
    }

    let newClipboard = []
    if (!oldData.clipboard) {
        oldData.clipboard = []
    }
    const savedClipboard = [...oldData.clipboard]

    for (const clip of savedClipboard) {
        if (clip?.shortcut || clip?.text) newClipboard.push(clip)
    }
    oldData.clipboard = newClipboard

    oldData.fees = oldData.fees?.map((fee) => ({
        ...fee,
        area: fee.area ? fee.area[fee.area.length - 1] : undefined,
    }))

    //format pictures
    // if (oldData.banners?.en) {
    //     let mainEn = oldData.banners.en.fileList
    //         ? oldData.banners.en?.fileList
    //         : oldData.banners.en

    //     oldData.banners.en = mainEn.map((file) =>
    //         file.response ? file.response : file
    //     )
    // } else {
    //     if (!oldData.banners) {
    //         oldData.banners = {}
    //     }
    //     oldData.banners.en = []
    // }

    // if (oldData.banners?.zh) {
    //     let mainZh = oldData.banners.zh.fileList
    //         ? oldData.banners.zh?.fileList
    //         : oldData.banners.zh

    //     oldData.banners.zh = mainZh.map((file) =>
    //         file.response ? file.response : file
    //     )
    // } else {
    //     oldData.banners.zh = []
    // }

    // if (oldData.medias?.en) {
    //     let mainEn = oldData.medias.en.fileList
    //         ? oldData.medias.en?.fileList
    //         : oldData.medias.en

    //     oldData.medias.en = mainEn.map((file) =>
    //         file.response ? file.response : file
    //     )
    // } else {
    //     if (!oldData.medias) {
    //         oldData.medias = {}
    //     }
    //     oldData.medias.en = []
    // }
    // if (oldData.medias?.zh) {
    //     let mainZh = oldData.medias.zh.fileList
    //         ? oldData.medias.zh?.fileList
    //         : oldData.medias.zh

    //     oldData.medias.zh = mainZh.map((file) =>
    //         file.response ? file.response : file
    //     )
    // } else {
    //     oldData.medias.zh = []
    // }

    if (oldData.wechatGroup) {
        let shortNameCountMP = 0
        if (oldData.wechatGroup.packs) {
            const newWXPacks = []
            for (const pack of oldData.wechatGroup.packs) {
                shortNameCountMP += 1

                // if (pack.mainPicture?.en) {
                //     let mainEn = pack.mainPicture?.en.fileList
                //         ? pack.mainPicture.en?.fileList
                //         : pack.mainPicture.en

                //     if (!Array.isArray(mainEn)) {
                //         mainEn = [mainEn]
                //     }
                //     const newEn = mainEn.map((file) =>
                //         file.response ? file.response : file
                //     )

                //     pack.mainPicture.en = newEn.length ? newEn[0] : {}
                // } else {
                //     set(pack, 'mainPicture.en ', {})
                // }

                // if (pack.mainPicture.zh) {
                //     let mainZh = pack.mainPicture.zh.fileList
                //         ? pack.mainPicture.zh?.fileList
                //         : pack.mainPicture.zh

                //     if (!Array.isArray(mainZh)) {
                //         mainZh = [mainZh]
                //     }
                //     const newZh = mainZh.map((file) =>
                //         file.response ? file.response : file
                //     )

                //     pack.mainPicture.zh = newZh.length ? newZh[0] : {}
                // } else {
                //     pack.mainPicture.zh = {}
                // }

                // if (pack.otherMedia) {
                //     const otherPic = pack.otherMedia.fileList
                //         ? pack.otherMedia?.fileList
                //         : pack.otherMedia
                //     pack.otherMedia = otherPic.map((file) =>
                //         file.response ? file.response : file
                //     )
                // } else {
                //     pack.otherMedia = []
                // }

                newWXPacks.push({
                    ...pack,
                    shortName: numberToLetter(shortNameCountMP - 1),
                    // mainPicture: pack.mainPicture,
                    // otherMedia: pack.otherMedia,
                })
            }
            oldData.wechatGroup.packs = newWXPacks
        }

        if (oldData.wechatGroup.items) {
            const newWXItems = []
            for (const item of oldData.wechatGroup.items) {
                shortNameCountMP += 1
                newWXItems.push({
                    ...item,
                    shortName: numberToLetter(shortNameCountMP - 1),
                })
            }
            oldData.wechatGroup.items = newWXItems
        }

        if (!oldData.wechatGroup.lotteryEnable) {
            oldData.wechatGroup.lotteryEnable = false
            oldData.wechatGroup.lottery = null
        } else {
            // if (oldData.wechatGroup.lottery.draws) {
            //     const newMPDraws = []
            //     for (const draw of oldData.wechatGroup.lottery.draws) {
            //         const newMPGifts = []
            //         if (draw?.gifts?.length > 0) {
            //             for (const gift of draw.gifts) {
            //                 let customImage = {}
            //                 newMPGifts.push({
            //                     ...gift,
            //                     customImage,
            //                 })
            //             }
            //         }
            //         newMPDraws.push({ ...draw, gifts: newMPGifts })
            //     }
            //     oldData.wechatGroup.lottery.draws = newMPDraws
            // }
        }

        if (oldData?.wechatGroup?.zikoSpecials) {
            // const newWXSpecials = []
            // for (const special of oldData.wechatGroup.zikoSpecials) {
            //     let customImage = {}
            //     console.log(special.gift)
            //     newWXSpecials.push({
            //         ...special,
            //         gift: { ...special.gift, customImage },
            //     })
            // }
            // oldData.wechatGroup.zikoSpecials = newWXSpecials
        }
    }
    if (oldData.miniprogram) {
        let shortNameCountMP = 0
        const newWXPacks = []
        if (oldData.miniprogram.packs) {
            for (const pack of oldData.miniprogram.packs) {
                shortNameCountMP += 1

                // if (pack.mainPicture?.en) {
                //     let mainEn = pack.mainPicture?.en.fileList
                //         ? pack.mainPicture.en?.fileList
                //         : pack.mainPicture.en

                //     if (!Array.isArray(mainEn)) {
                //         mainEn = [mainEn]
                //     }
                //     const newEn = mainEn.map((file) =>
                //         file.response ? file.response : file
                //     )

                //     pack.mainPicture.en = newEn.length ? newEn[0] : {}
                // } else {
                //     set(pack, 'mainPicture.en ', {})
                // }

                // if (pack.mainPicture.zh) {
                //     let mainZh = pack.mainPicture.zh.fileList
                //         ? pack.mainPicture.zh?.fileList
                //         : pack.mainPicture.zh

                //     if (!Array.isArray(mainZh)) {
                //         mainZh = [mainZh]
                //     }
                //     const newZh = mainZh.map((file) =>
                //         file.response ? file.response : file
                //     )

                //     pack.mainPicture.zh = newZh.length ? newZh[0] : {}
                // } else {
                //     pack.mainPicture.zh = {}
                // }

                // if (pack.otherMedia) {
                //     const otherPic = pack.otherMedia.fileList
                //         ? pack.otherMedia?.fileList
                //         : pack.otherMedia
                //     pack.otherMedia = otherPic.map((file) =>
                //         file.response ? file.response : file
                //     )
                // } else {
                //     pack.otherMedia = []
                // }

                newWXPacks.push({
                    ...pack,
                    shortName: numberToLetter(shortNameCountMP - 1),
                    // illustation,
                    // mainPicture: pack.mainPicture,
                    // otherMedia: pack.otherMedia,
                })
            }
            oldData.miniprogram.packs = newWXPacks
        }

        if (oldData.miniprogram.items) {
            const newWXItems = []
            for (const item of oldData.miniprogram.items) {
                shortNameCountMP += 1
                newWXItems.push({
                    ...item,
                    shortName: numberToLetter(shortNameCountMP - 1),
                })
            }
            oldData.miniprogram.items = newWXItems
        }

        if (!oldData.miniprogram.lotteryEnable) {
            oldData.miniprogram.lotteryEnable = false
            oldData.miniprogram.lottery = null
        } else {
            // if (oldData.miniprogram.lottery.draws) {
            //     const newMPDraws = []
            //     for (const draw of oldData.miniprogram.lottery.draws) {
            //         const newMPGifts = []
            //         if (draw?.gifts?.length > 0) {
            //             for (const gift of draw?.gifts) {
            //                 let customImage = {}
            //                 if (gift.type === 'custom') {
            //                     customImage = gift?.customImage?.fileList
            //                         ? gift.customImage?.fileList
            //                         : gift.customImage
            //                     if (!Array.isArray(customImage)) {
            //                         customImage = [customImage]
            //                     }
            //                     const newImg = customImage.map((file) =>
            //                         file.response ? file.response : file
            //                     )
            //                     customImage = newImg.length ? newImg[0] : {}
            //                 }
            //                 newMPGifts.push({
            //                     ...gift,
            //                     customImage,
            //                 })
            //             }
            //         }
            //         newMPDraws.push({ ...draw, gifts: newMPGifts })
            //     }
            //     oldData.miniprogram.lottery.draws = newMPDraws
            // }
        }

        if (oldData.miniprogram?.zikoSpecials) {
            // const newMPSpecials = []
            // for (const special of oldData.miniprogram?.zikoSpecials) {
            //     let customImage = {}
            //     if (special.gift && special.gift.type === 'custom') {
            //         customImage = special.gift?.customImage?.fileList
            //             ? special.gift.customImage?.fileList
            //             : special.gift.customImage
            //         if (!Array.isArray(customImage)) {
            //             customImage = [customImage]
            //         }
            //         const newImg = customImage.map((file) =>
            //             file.response ? file.response : file
            //         )
            //         customImage = newImg.length ? newImg[0] : {}
            //     }
            //     newMPSpecials.push({
            //         ...special,
            //         gift: { ...special.gift, customImage },
            //     })
            // }
            // oldData.miniprogram.zikoSpecials = newMPSpecials
        }
    }

    return oldData
}

export default cleanBeforeSending
