/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react'
import {
    Typography,
    Avatar,
    Space,
    Tag,
    Popover,
    Badge,
    Button,
    message,
    Modal,
    Tooltip,
} from 'antd'
import { useQueryClient } from 'react-query'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Logo from '../../images/Ellipse.svg'

import { AppContext } from '../../context/AppContext'
import { CustomerQuickInfos, SERVER } from '../../network/API'
import { CreateCustomer } from '../../network/Customers'

const { Text, Link } = Typography

const ImageCustomer = ({ order, onUserCreated }) => {
    const [enabled, setEnabled] = useState(false)
    const queryClient = useQueryClient()
    const { t } = useTranslation()
    let history = useHistory()
    const { token } = useContext(AppContext)
    const infos = useQuery(
        [
            'getCustomersStatsLite',
            {
                customer: order?.customer?.id,
                token: token.token,
            },
        ],
        CustomerQuickInfos,
        {
            enabled,
        }
    )

    // const mutation = useMutation(
    //     (values) => CreateCustomer(values, token.token),
    //     {
    //         onSuccess: (data) => onUserCreated(data),
    //         onError: () => message.error('An error occurs'),
    //     }
    // )

    const showPromiseConfirm = () => {
        Modal.confirm({
            centered: true,
            title: t('CREATE_NEW_CUSTOMER'),
            icon: <ExclamationCircleOutlined />,
            content: t('CONFRIM_TO_CREATE_NEW_CUSTOMER'),
            okText: t('OFFER_LIVE_YES'),
            cancelText: t('OFFER_LIVE_NO'),
            onOk() {
                return CreateCustomer(
                    {
                        name: order.customerDaily?.customerName,
                        addresses: [
                            {
                                city: order.customerAddress?.city,
                                area: order.customerAddress?.area,
                                type: 'home',
                                detailedAddress:
                                    order.customerAddress?.detailedAddress,
                                // phone: '00000',
                                phone: order.customerAddress?.phone,
                                contact: order.customerDaily?.customerName,
                            },
                        ],
                        // contacts: [
                        //     {
                        //         phone: order.customerAddress?.phone,
                        //         name: order.customerDaily?.customerName,
                        //     },
                        // ],
                        wechatGroups: [],
                        pets: null,
                        // flagged: false,
                    },
                    token.token
                )
                    .then((data) => {
                        queryClient.invalidateQueries('getClaimsAnalytics')
                        onUserCreated(data)
                    })
                    .catch(() => message.error('An error occurs'))
            },
            onCancel() {},
        })
    }

    const disabled =
        !order.customerDaily?.customerName ||
        !order.customerAddress?.phone ||
        !order.customerAddress?.detailedAddress ||
        !order.customerAddress?.city ||
        !order.customerAddress?.area

    if (!order?.customer) {
        if (disabled) {
            return (
                <Tooltip
                    title={`${
                        !order.customerDaily?.customerName
                            ? 'Name'
                            : !order.customerAddress?.phone
                            ? 'Phone'
                            : !order.customerAddress?.detailedAddress
                            ? 'Address'
                            : !order.customerAddress?.city
                            ? 'City'
                            : 'Area'
                    } not filled`}
                >
                    <Button
                        onClick={showPromiseConfirm}
                        // onClick={() =>
                        //     mutation.mutate({
                        //         name: order.customerDaily?.customerName,
                        //         addresses: [
                        //             {
                        //                 city: order.customerAddress?.city,
                        //                 area: order.customerAddress?.area,
                        //                 type: 'home',
                        //                 detailedAddress:
                        //                     order.customerAddress?.detailedAddress,
                        //                 // phone: '00000',
                        //             },
                        //         ],
                        //         contacts: [
                        //             {
                        //                 phone: order.customerAddress?.phone,
                        //                 name: order.customerDaily?.customerName,
                        //             },
                        //         ],
                        //         wechatGroups: [],
                        //         pets: null,
                        //         // flagged: false,
                        //     })
                        // }
                        type="primary"
                        disabled={true}
                        // icon={<PlusOutlined />}
                        size="small"
                    >
                        +
                    </Button>
                </Tooltip>
            )
        }

        return (
            <Button onClick={showPromiseConfirm} type="primary" size="small">
                +
            </Button>
        )
    }
    return (
        <Popover
            placement="bottom"
            content={
                infos.isLoading ? (
                    <p>is loading</p>
                ) : (
                    <Space direction="vertical">
                        <Tag color="gold">{t(infos?.data?.level)}</Tag>
                        <span>
                            <Text>Vouchers </Text>
                            <Link
                                underline
                                onClick={() =>
                                    history.push({
                                        pathname:
                                            '/customers/' + order.customer?.id,
                                        defaultTab: 'vouchers',
                                    })
                                }
                            >
                                {infos?.data?.voucherCount}
                            </Link>
                        </span>
                        <span>
                            <Text>Claims </Text>
                            <Link
                                underline
                                onClick={() =>
                                    history.push({
                                        pathname:
                                            '/customers/' + order.customer?.id,
                                        defaultTab: 'claims',
                                    })
                                }
                            >
                                {infos?.data?.claimCount}
                            </Link>
                        </span>
                        <span>
                            <Text>Monthly orders </Text>
                            <Link
                                underline
                                onClick={() =>
                                    history.push({
                                        pathname:
                                            '/customers/' + order.customer?.id,
                                        defaultTab: 'analytics',
                                    })
                                }
                            >
                                {Math.round(infos?.data?.monthlyOrder * 2) / 2}
                            </Link>
                        </span>
                    </Space>
                )
            }
            trigger="hover"
        >
            <div onMouseEnter={() => setEnabled(true)}>
                <Badge size="large" dot={order?.customer?.giftNextOffer}>
                    <Avatar
                        size="small"
                        src={
                            order?.customer?.profilePicture?.uri
                                ? `${SERVER.baseURL}/storage/customer-picture/${order?.customer?.profilePicture?.uri}`
                                : Logo
                        }
                    />
                </Badge>
            </div>
        </Popover>
    )
}

export default ImageCustomer
