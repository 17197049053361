import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from '@react-pdf/renderer'

import { formatDate } from 'src/utils/utils'

import KITCHEN from '../images/KITCHEN.png'
import GARDEN from '../images/GARDEN.png'
import Farm from '../images/FARM.png'
import PET from '../images/PET.png'
import CELLAR from '../images/CELLAR.png'
import ZIKOLAND from '../images/ZIKOLAND.png'
import QRF from '../images/QRF.jpeg'

import NotoSansSCRegular from '../assets/NotoSansSC-Regular.ttf'
import NotoSansSCBold from '../assets/NotoSansSC-Bold.ttf'

const getTaxTotal = (packs, items, taxRate) => {
    let totalTax = 0
    let totalWOTax = 0

    for (let i = 0; i < packs?.length; i++) {
        const PTotalPrice = packs[i].price * packs[i].amount
        const PTotalWO = PTotalPrice / ((taxRate + 100) / 100)
        const PTotalTax = PTotalPrice - PTotalWO

        totalTax += PTotalTax
        totalWOTax += PTotalWO

        // const taxAmout = packs[i].price * packs[i].amount * (taxRate / 100)

        // console.log('p taxAmout', taxAmout)
        // console.log('p price', i, packs[i].price)
        // console.log('p amount', i, packs[i].amount)
        // totalTax += taxAmout
        // totalWOTax += packs[i].price * packs[i].amount - taxAmout
    }

    for (let i = 0; i < items?.length; i++) {
        const ITotalPrice = items[i].price * items[i].amount
        const ITotalWO = ITotalPrice / ((taxRate + 100) / 100)
        const ITotalTax = ITotalPrice - ITotalWO

        totalTax += ITotalTax
        totalWOTax += ITotalWO

        // console.log('i total', i, ITotalPrice, ITotalWO, ITotalTax)

        // const taxAmout = items[i].price * items[i].amount * (taxRate / 100)

        // console.log('i taxAmout', taxAmout)
        // console.log('i price', i, items[i].price)
        // console.log('i amount', i, items[i].amount)
        // totalTax += taxAmout
        // totalWOTax += items[i].price * items[i].amount - taxAmout
    }

    return { totalTax, totalWOTax }
}

const PDFHeader = ({ community, lang }) => {
    var img

    switch (community) {
        case 'Kitchen':
            img = <Image style={styles.headerImage} src={KITCHEN} />
            break
        case 'Cellar':
            img = <Image style={styles.headerImage} src={CELLAR} />
            break
        case 'Garden':
            img = <Image style={styles.headerImage} src={GARDEN} />
            break
        case 'Farm':
            img = <Image style={styles.headerImage} src={Farm} />
            break
        case 'Pet':
            img = <Image style={styles.headerImage} src={PET} />
            break
        default:
            img = <Image style={styles.headerImage} src={ZIKOLAND} />
            break
    }

    return (
        <View style={styles.headerWrap} fixed>
            <View style={styles.headerInfos}>
                {img}
                <View style={{ alignItems: 'flex-end' }}>
                    <Text style={[styles.text, styles.headerText]}>
                        ZIKOLAND
                    </Text>
                    <Text style={[styles.text, styles.headerText]}>
                        安布尔贸易(上海)有限公司
                    </Text>
                    <Text style={[styles.text, styles.headerText]}>
                        地址: 上海市普陀区中山北路1715号浦发财富广场E座2405室
                    </Text>
                </View>
            </View>
        </View>
    )
}

const PDFFooter = ({ lang }) => (
    <View style={styles.footerWrap} fixed>
        <View style={styles.footerInfos}>
            <View>
                <Text
                    style={[
                        styles.text,
                        styles.footerText,
                        { fontWeight: 'bold' },
                    ]}
                >
                    ZIKOLAND
                </Text>
                <Text
                    style={[
                        styles.text,
                        styles.footerText,
                        { fontWeight: 'bold' },
                    ]}
                >
                    Email: customerhero@zikoland.cn - Website:
                    http://www.zikoland.cn
                </Text>

                <Text style={[styles.text, styles.headerText, { fontSize: 8 }]}>
                    {
                        'TIN: 91310000088549996R • (Ziko) ABC Lu Wan 中国农业银行股份有限公司, 上海卢湾支行: Ziko Wechat Pay • (Ziko) ABC Lu Wan 中国农业银行股份有限公司, 上海卢湾支行: Ziko Cash'
                    }
                </Text>
            </View>
            <Image style={styles.headerImage} src={QRF} />
        </View>
    </View>
)

const BillInfo = ({
    name,
    lang,
    address,
    city,
    phone,
    supplier,
    date,
    orderId,
    title,
}) => {
    return (
        <View style={styles.infoWrap}>
            <View style={styles.infoTop}>
                <View style={styles.customerWrap}>
                    <Text style={styles.title}>
                        {lang === 'en' ? 'Shipping address' : '收货地址'}
                    </Text>
                    {name && <Text style={styles.text}>{name}</Text>}
                    {address && <Text style={styles.text}>{address}</Text>}
                    {city && <Text style={styles.text}>{city}</Text>}
                    {phone && <Text style={styles.text}>{phone}</Text>}
                </View>
                {supplier && (
                    <View style={styles.supplierWrap}>
                        <Text style={styles.title}>
                            {lang === 'en' ? 'FROM' : '从'}
                        </Text>
                        <Text style={styles.text}>{supplier}</Text>
                    </View>
                )}
            </View>

            <View style={styles.orderWrap}>
                <Text style={styles.title}>
                    {title}
                    {orderId}
                </Text>
                {date && (
                    <Text style={styles.text}>
                        {lang === 'en' ? 'Order date' : '订单日期'}: {date}
                    </Text>
                )}
                {orderId && (
                    <Text style={styles.text}>
                        {lang === 'en' ? 'Order reference' : '订单编号'}: #
                        {orderId}
                    </Text>
                )}
            </View>
        </View>
    )
}

const OrderList = ({ products, type, lang, isCellar, suppTax }) => {
    return (
        <View style={styles.orderListWrap}>
            <View style={styles.orderListHeader}>
                <Text style={[styles.text, styles.productText]}>
                    {lang === 'en' ? 'Product' : '产品'}
                </Text>

                <Text style={[styles.text, styles.columnText]}>
                    {lang === 'en' ? 'Quantity' : '数量'}
                </Text>
                <Text style={[styles.text, styles.columnText]}>
                    {lang === 'en' ? 'Unit price' : '单价'}
                </Text>
                {type === 'po' || isCellar ? (
                    <Text style={[styles.text, styles.columnText]}>
                        {lang === 'en' ? 'VAT' : '增值税'}
                    </Text>
                ) : null}
                <Text style={[styles.text, styles.columnText]}>
                    {isCellar
                        ? lang === 'en'
                            ? 'Total Price'
                            : '总价'
                        : lang === 'en'
                        ? 'Net price'
                        : '净价'}
                </Text>
            </View>

            {products.map((product) =>
                !product.amount && !product.quantity ? null : (
                    <>
                        <View
                            key={product._id}
                            style={styles.orderListProducts}
                            wrap={false}
                        >
                            <Text style={[styles.text, styles.productName]}>
                                {product.shortName
                                    ? `[${
                                          product.products
                                              ? lang === 'en'
                                                  ? 'Pack'
                                                  : '套餐'
                                              : lang === 'en'
                                              ? 'Add-on'
                                              : '附加套餐'
                                      } ${product.shortName}] ${
                                          product?.name
                                              ? product?.name[lang]
                                              : product.product?.name[lang]
                                      }`
                                    : `[${product.product?.productId}] ${product.product?.name[lang]}`}
                            </Text>

                            {type === 'po' ? (
                                <>
                                    <Text
                                        style={[
                                            styles.text,
                                            styles.productValue,
                                        ]}
                                    >{`${product.quantity?.toFixed(0)}`}</Text>

                                    <Text
                                        style={[
                                            styles.text,
                                            styles.productValue,
                                        ]}
                                    >{`${product.unitPrice?.toFixed(
                                        2
                                    )} ¥`}</Text>
                                    <Text
                                        style={[
                                            styles.text,
                                            styles.productValue,
                                        ]}
                                    >
                                        {`${product.vatPercentage?.toFixed(
                                            2
                                        )} %`}
                                    </Text>
                                    <Text
                                        style={[
                                            styles.text,
                                            styles.productValue,
                                        ]}
                                    >
                                        {/*{`${(product.quantity * product.unitPrice).toFixed(2)} ¥`}*/}
                                        {`${(
                                            (product.quantity *
                                                product.unitPrice) /
                                            ((100 + product.vatPercentage) /
                                                100)
                                        ).toFixed(2)} ¥`}
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Text
                                        style={[
                                            styles.text,
                                            styles.productValue,
                                        ]}
                                    >{`${
                                        product.products
                                            ? product.amount?.toFixed(0)
                                            : product.amount?.toFixed(0) *
                                              (product.quantity?.toFixed(0) ||
                                                  1)
                                    }`}</Text>

                                    {/* <Text
                                        style={[styles.text, styles.productValue]}
                                    >{`${product.price?.toFixed(2)} ¥`}</Text> */}
                                    {isCellar ? (
                                        <>
                                            <Text
                                                style={[
                                                    styles.text,
                                                    styles.productValue,
                                                ]}
                                            >{`${product.price?.toFixed(
                                                2
                                            )} ¥`}</Text>
                                            <Text
                                                style={[
                                                    styles.text,
                                                    styles.productValue,
                                                ]}
                                            >
                                                {`${suppTax?.toFixed(2)} %`}
                                            </Text>
                                        </>
                                    ) : (
                                        <Text
                                            style={[
                                                styles.text,
                                                styles.productValue,
                                            ]}
                                        >{`${product.price?.toFixed(
                                            2
                                        )} ¥`}</Text>
                                    )}
                                    <Text
                                        style={[
                                            styles.text,
                                            styles.productValue,
                                        ]}
                                    >{`${(
                                        product.amount * product.price
                                    ).toFixed(2)} ¥`}</Text>
                                </>
                            )}
                        </View>
                        {product.products ? (
                            product.products.map((packItem, index) => (
                                <View
                                    style={styles.orderListProducts}
                                    wrap={false}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            styles.productName,
                                        ]}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {`(${index + 1}) ${
                                            packItem.product?.name[lang]
                                        } * ${packItem.quantity?.toFixed(
                                            0
                                        )} per pack`}
                                    </Text>
                                    <>
                                        <Text
                                            style={[
                                                styles.text,
                                                styles.productValue,
                                            ]}
                                        >
                                            {`${(
                                                product.amount *
                                                packItem.quantity
                                            ).toFixed(0)}`}
                                        </Text>
                                        <Text
                                            style={[
                                                styles.text,
                                                styles.productValue,
                                            ]}
                                        ></Text>
                                        <Text
                                            style={[
                                                styles.text,
                                                styles.productValue,
                                            ]}
                                        ></Text>
                                        {type === 'po' || isCellar ? (
                                            <Text
                                                style={[
                                                    styles.text,
                                                    styles.productValue,
                                                ]}
                                            ></Text>
                                        ) : null}
                                    </>
                                </View>
                            ))
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </View>
    )
}

const OrderTotal = ({
    actual,
    totalWo,
    voucher,
    delivery,
    tax,
    lang,
    subTitle = lang === 'en' ? 'Total without taxes' : '不含税总价',
    isCellar,
    suppTax,
    discount,
}) => {
    return (
        <>
            <View style={styles.totalContainer}>
                <View style={styles.totalWrap}>
                    {totalWo || isCellar ? (
                        <View style={styles.totalLine}>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalLineTitle,
                                    { fontWeight: 'bold' },
                                ]}
                            >
                                {isCellar
                                    ? lang === 'en'
                                        ? 'Total without taxes'
                                        : '不含税总价'
                                    : subTitle}
                            </Text>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalLineValue,
                                    {
                                        fontWeight: 'bold',
                                        width: '33%',
                                        textAlign: 'right',
                                    },
                                ]}
                            >
                                {`${
                                    isCellar
                                        ? suppTax?.totalWOTax?.toFixed(2)
                                        : totalWo.toFixed(2)
                                } ¥`}
                            </Text>
                        </View>
                    ) : null}

                    {tax || isCellar ? (
                        <View style={styles.totalLine}>
                            <Text style={[styles.text, styles.totalLineTitle]}>
                                {lang === 'en' ? 'Taxes' : '税'}
                            </Text>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalLineValue,
                                    {
                                        width: '33%',
                                        textAlign: 'right',
                                    },
                                ]}
                            >
                                {`${
                                    isCellar
                                        ? suppTax?.totalTax?.toFixed(2)
                                        : tax.toFixed(2)
                                } ¥`}
                            </Text>
                        </View>
                    ) : null}

                    {discount && (
                        <View style={styles.totalLine}>
                            <Text style={[styles.text, styles.totalLineTitle]}>
                                {lang === 'en' ? 'Discount' : '折扣'}
                            </Text>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalLineValue,
                                    {
                                        width: '33%',
                                        textAlign: 'right',
                                    },
                                ]}
                            >
                                {`${discount?.discountAmount?.toFixed(2)} %`}
                            </Text>
                        </View>
                    )}

                    {delivery ? (
                        <View style={styles.totalLine}>
                            <Text style={[styles.text, styles.totalLineTitle]}>
                                {lang === 'en' ? 'Delivery' : '发货、运输'}
                            </Text>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalLineValue,
                                    {
                                        width: '33%',
                                        textAlign: 'right',
                                    },
                                ]}
                            >
                                {`${delivery.toFixed(2)} ¥`}
                            </Text>
                        </View>
                    ) : null}

                    {voucher ? (
                        <View style={styles.totalLine}>
                            <Text style={[styles.text, styles.totalLineTitle]}>
                                {lang === 'en' ? 'Vouchers' : '代金券'}
                            </Text>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalLineValue,
                                    {
                                        width: '33%',
                                        textAlign: 'right',
                                    },
                                ]}
                            >
                                {`-${voucher.toFixed(2)} ¥`}
                            </Text>
                        </View>
                    ) : null}
                </View>
            </View>

            {actual ? (
                <View style={styles.totalFinalContainer}>
                    <View style={styles.totalFinalWrap}>
                        <View style={styles.totalFinalLine}>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalFinalLineTitle,
                                    {
                                        fontWeight: 'bold',
                                    },
                                ]}
                            >
                                {lang === 'en' ? 'Total' : '总计'}
                            </Text>
                            <Text
                                style={[
                                    styles.text,
                                    styles.totalFinalLineValue,
                                    {
                                        fontWeight: 'bold',
                                        width: '33%',
                                        textAlign: 'right',
                                    },
                                ]}
                            >
                                {`${actual.toFixed(2)} ¥`}
                            </Text>
                        </View>
                    </View>
                </View>
            ) : null}
        </>
    )
}

const GiftList = ({ gifts, lang }) => {
    if (!gifts || !gifts.length) return null

    return (
        <View style={styles.giftsWrap}>
            {gifts.map((gift) => (
                <View
                    key={gift._id}
                    style={styles.orderListProducts}
                    wrap={false}
                >
                    {gift.type === 'custom' && (
                        <Text style={[styles.text, styles.productName]}>
                            {gift.custom[lang]}
                        </Text>
                    )}

                    {gift.type === 'pack' && (
                        <Text style={[styles.text, styles.productName]}>
                            {`${lang === 'en' ? 'Pack' : '套餐'} ${gift.pack}`}
                        </Text>
                    )}

                    {gift.type === 'add_on' && (
                        <Text style={[styles.text, styles.productName]}>
                            {`${lang === 'en' ? 'Item' : '产品'} ${
                                gift.singleItem
                            }`}
                        </Text>
                    )}

                    {gift.type === 'discount' && (
                        <Text style={[styles.text, styles.productName]}>
                            {`${lang === 'en' ? 'Discount' : '折扣'} ${
                                gift.discountAmount
                            } ¥`}
                        </Text>
                    )}

                    {gift.type === 'voucher' && (
                        <Text style={[styles.text, styles.productName]}>
                            {`${lang === 'en' ? 'Voucher' : '代金券'} (${
                                gift.voucherValue
                            } ¥)`}
                        </Text>
                    )}
                </View>
            ))}
        </View>
    )
}

const MyPDFDocument = ({ order, type, noSupplier, lang = 'en' }) => {
    Font.register({
        family: 'NotoSansSC',
        fonts: [
            {
                family: 'NotoSansSC',
                src: NotoSansSCRegular,
                fontStyle: 'normal',
                fontWeight: 'normal',
            },
            {
                family: 'NotoSansSCBold',
                src: NotoSansSCBold,
                fontStyle: 'normal',
                fontWeight: 'bold',
            },
        ],
    })

    var totVoucher = 0
    var tax = 0
    var products = []

    let poTax = 0
    let poTotal = 0
    let productsLineTotal = 0

    if (order.vouchers?.length) {
        for (const voucher of order.vouchers) {
            totVoucher += voucher.amount
        }
    }

    if (order.packs?.length) {
        // products.concat(order.packs)
        products = [...products, ...order.packs]
        for (const i in order.packs) {
            productsLineTotal += order.packs[i].products?.length || 0
        }
    }

    if (order.singleItems?.length) {
        // products.concat(order.singleItems)
        products = [...products, ...order.singleItems]
    }

    if (order.purchaseOrderItems?.length) {
        // products.concat(order.singleItems)
        products = [...products, ...order.purchaseOrderItems]
    }

    if (type === 'po' && order.purchaseOrderItems) {
        for (const item of order.purchaseOrderItems) {
            // const tot = item.quantity * item.unitPrice

            const qty = item.quantity || 0
            const unitPrice = item.unitPrice || 0
            const vat = item.vatPercentage || 0

            const totalWT = qty * unitPrice
            // const totalTax = totalWT * (vat / 100)
            const totalTax = (totalWT * (vat / 100)) / ((100 + vat) / 100)

            poTotal += totalWT
            poTax += totalTax
        }
    }

    const gifts = order?.gifts?.filter(
        (e) =>
            e.type === 'voucher' ||
            e.type === 'pack' ||
            e.type === 'add_on' ||
            e.type === 'custom'
    )

    const discount = order?.gifts?.find((e) => e.type === 'discount')

    productsLineTotal += (gifts?.length || 0) + (products?.length || 0)

    return (
        <Document>
            <Page style={styles.page}>
                <PDFHeader community={order.community?.name} />
                {type === 'po' ? (
                    <BillInfo
                        lang={lang}
                        name={'ZIKOLAND'}
                        address={'上海市普陀区中山北路1715号浦发财富广场E座'}
                        city={'2405室 200040 Shanghai'}
                        // phone={order.supplier?.phone}
                        supplier={order.supplier?.name}
                        title={
                            lang === 'en'
                                ? 'PURCHASE ORDER CONFIRMATION #'
                                : '采购订单 #'
                        }
                        orderId={order.poId}
                        date={formatDate(order.date)}
                    />
                ) : (
                    <BillInfo
                        lang={lang}
                        name={
                            order.customer?.name ||
                            order.customerDaily?.customerName ||
                            ' '
                        }
                        address={order.customerAddress?.detailedAddress}
                        city={`${order.customerAddress?.zipCode || ' '} ${
                            order.customerAddress?.city || ' '
                        }`}
                        phone={order.customerAddress?.phone}
                        // supplier={noSupplier ? null : order.supplier?.name}
                        title={lang === 'en' ? 'ORDER #' : '订单 #'}
                        orderId={order.orderID}
                        date={formatDate(order.createdAt)}
                    />
                )}

                <OrderList
                    lang={lang}
                    products={products}
                    type={type}
                    isCellar={
                        order?.community?.id === '60541eb87b87f1126ecf53e3'
                    }
                    suppTax={
                        order?.community?.id === '60541eb87b87f1126ecf53e3'
                            ? order?.supplier?.vat || 0
                            : null
                    }
                />
                <GiftList lang={lang} gifts={gifts} />

                {/* {productsLineTotal < 6 && */}
                {type === 'po' ? (
                    <OrderTotal
                        lang={lang}
                        actual={poTotal}
                        totalWo={poTotal - poTax}
                        tax={poTax}
                        discount={discount}
                    />
                ) : (
                    <OrderTotal
                        lang={lang}
                        actual={order.actualAmount}
                        totalWo={
                            order.totalAmount -
                            totVoucher -
                            tax -
                            order.deliveryFee
                        }
                        voucher={totVoucher}
                        subTitle={lang === 'en' ? 'Subtotal' : '小计'}
                        delivery={order.deliveryFee}
                        isCellar={
                            order?.community?.id === '60541eb87b87f1126ecf53e3'
                        }
                        suppTax={
                            order?.community?.id === '60541eb87b87f1126ecf53e3'
                                ? getTaxTotal(
                                      order?.packs,
                                      order?.singleItems,
                                      order?.supplier?.vat || 0
                                  )
                                : null
                        }
                        discount={discount}
                    />
                )}
                {/* )} */}
                <PDFFooter />
            </Page>
            {/* {productsLineTotal >= 6 && (
                <Page style={styles.body}>
                    <PDFHeader lang={lang} community={order.community?.name} />
                    {type === 'po' ? (
                        <OrderTotal
                            lang={lang}
                            // actual={poTotal + poTax}
                            // totalWo={poTotal}
                            actual={poTotal}
                            totalWo={poTotal - poTax}
                            tax={poTax}
                            discount={discount}
                        />
                    ) : (
                        <OrderTotal
                            lang={lang}
                            actual={order.actualAmount}
                            totalWo={
                                order.totalAmount -
                                totVoucher -
                                tax -
                                order.deliveryFee
                            }
                            voucher={totVoucher}
                            delivery={order.deliveryFee}
                            isCellar={
                                order?.community?.id ===
                                '60541eb87b87f1126ecf53e3'
                            }
                            suppTax={
                                order?.community?.id ===
                                '60541eb87b87f1126ecf53e3'
                                    ? getTaxTotal(
                                          order?.packs,
                                          order?.singleItems,
                                          order?.supplier?.vat || 0
                                      )
                                    : null
                            }
                            discount={discount}
                        />
                    )}
                    <PDFFooter />
                </Page>
            )} */}
        </Document>
    )
}

export default MyPDFDocument

const styles = StyleSheet.create({
    // text: {
    //     fontFamily: 'Hiragino',
    //     fontSize: 11,
    //     lineHeight: 1.6,
    // },
    // title: {
    //     fontFamily: 'Hiragino',
    //     fontWeight: 'bold',
    //     fontSize: 12,
    //     marginBottom: 5,
    // },

    page: {
        fontFamily: 'NotoSansSC',
        paddingBottom: 80,
    },
    text: {
        fontFamily: 'NotoSansSC',
        fontSize: 11,
        lineHeight: 1.6,
    },
    title: {
        fontFamily: 'NotoSansSC',
        fontWeight: 'bold',
        fontSize: 12,
        marginBottom: 5,
    },

    headerWrap: {
        // position: 'absolute',
        width: '100%',
        padding: '15px 15px 0px 15px',
        height: '75px',
    },
    headerInfos: {
        border: '#000 solid',
        paddingBottom: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerImage: {
        height: '100%',
        width: 'auto',
    },
    headerText: {
        color: '#ccc',
        fontFamily: 'NotoSansSC',
    },

    footerWrap: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        padding: '0px 15px 15px 15px',
        height: '80px',
        fontFamily: 'NotoSansSC',
    },
    footerInfos: {
        border: '#000 solid',
        paddingTop: 10,
        borderTopWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'NotoSansSC',
    },
    footerImage: {
        height: '100%',
    },
    footerText: {
        // fontSize: 11,
        fontFamily: 'NotoSansSC',
    },

    infoWrap: {
        marginTop: 15,
        padding: '0px 15px',
    },
    infoTop: {
        flexDirection: 'row',
        width: '100%',
    },
    customerWrap: {
        flex: 1,
    },
    supplierWrap: {
        flex: 1,
    },
    orderWrap: {
        flex: 1,
        margin: '70px 0px',
    },

    orderListWrap: {
        padding: '0px 15px',
    },
    giftsWrap: {
        border: '#000 solid',
        borderTopWidth: 1,
        margin: '8px 15px 0px 15px',
    },
    orderListHeader: {
        border: '#000 solid',
        paddingBottom: 5,
        marginBottom: 5,
        borderBottomWidth: 1,
        flexDirection: 'row',
    },
    productText: {
        flex: 1,
        fontWeight: 'bold',
        fontFamily: 'NotoSansSC',
    },
    columnText: {
        width: '12.5%',
        fontWeight: 'bold',
        textAlign: 'right',
        fontFamily: 'NotoSansSC',
    },

    orderListProducts: {
        flexDirection: 'row',
        marginTop: 4,
        fontFamily: 'NotoSansSC',
    },
    productName: {
        flex: 1,
        fontFamily: 'NotoSansSC',
    },
    productValue: {
        width: '12.5%',
        textAlign: 'right',
        fontFamily: 'NotoSansSC',
    },

    totalContainer: {
        alignItems: 'flex-end',
        padding: '15px 15px 0px 15px',
    },
    totalWrap: {
        alignItems: 'flex-end',
        border: '#000 solid',
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        width: '37.5%',
    },
    totalLine: {
        marginTop: 11,
        flexDirection: 'row',
    },
    totalLineTitle: {
        textAlign: 'right',
        fontFamily: 'NotoSansSC',
    },

    totalFinalContainer: {
        alignItems: 'flex-end',
        padding: '0px 15px',
    },
    totalFinalWrap: {
        alignItems: 'flex-end',
        width: '37.5%',
    },
    totalFinalLine: {
        marginTop: 11,
        flexDirection: 'row',
    },
    totalFinalLineTitle: {
        textAlign: 'right',
        fontFamily: 'NotoSansSC',
    },
})
