import { Button, Tabs } from 'antd'

import { formatDate } from '../utils/utils'
import { getDeliveryTemplates, getDeliveryAreas } from '../network/API'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'
import { useQuerySync } from "../hooks/useQuerySync";

const { TabPane } = Tabs

const DeliveryTemplates = ({ history }) => {
    const { value, onChange } = useQuerySync('tabState');

    return (
        <PageWrap title="MENU_DELIVERY_TEMPLATES">
            <Tabs
                className="tableTabs"
                activeKey={value || 'templates'}
                onChange={(e) => onChange(e)}
            >
                <TabPane
                    tab={<DynamicStrings id="DELIVERY_TEMPLATES_TEMPLATES" />}
                    key="templates"
                >
                    <TableBlock
                        columns={columnsTemplates}
                        filters={filtersTemplates}
                        name={'getDeliveryTemplates'}
                        request={getDeliveryTemplates}
                        otherFilters={{ status: { $ne: 'discontinued' } }}
                        pageSize={80}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    history.push(`/delivery/template/${record.id}`)
                                },
                            };
                        }}
                    />
                </TabPane>
                <TabPane
                    tab={<DynamicStrings id="DELIVERY_TEMPLATES_AREAS" />}
                    key="areas"
                >
                    <TableBlock
                        columns={columnsAreas}
                        filters={filtersAreas}
                        name={'getDeliveryAreas'}
                        request={getDeliveryAreas}
                        // otherFilters={{ status: { $ne: 'discontinued' } }}
                        pageSize={80}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    history.push(`/delivery/areas/${record.id}`)
                                },
                            };
                        }}
                    />
                </TabPane>
            </Tabs>
        </PageWrap>
    )
}

const filtersTemplates = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'DELIVERY_TEMPLATES_TEMPLATE_NAME',
            key: 'name',
        },
    ],
    actions: [
        {
            label: 'DELIVERY_TEMPLATES_CREATE_TEMPLATE',
            isActive: true,
            action: () => history.push('/delivery/template/create'),
        },
    ],
})

const columnsTemplates = (goTo) => [
    {
        title: <DynamicStrings id="DELIVERY_TEMPLATES_TEMPLATE_NAME" />,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: <DynamicStrings id="DELIVERY_TEMPLATES_CREATED_DATE" />,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => (date ? formatDate(date) : null),
        sorter: true,
    },
    // {
    //     title: '',
    //     key: 'action',
    //     width: 1,
    //     render: (e) => (
    //         <Button
    //             onClick={() => goTo(`/delivery/template/${e.id}`)}
    //             type="primary"
    //         >
    //             <DynamicStrings id="ROLES_EDIT" />
    //         </Button>
    //     ),
    // },
]

const filtersAreas = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'CREATE_OFFER_AREA_NAME',
            key: 'name',
        },
    ],
    actions: [
        {
            label: 'DELIVERY_TEMPLATES_CREATE_AREA',
            isActive: true,
            action: () => history.push('/delivery/areas/create'),
        },
    ],
})

const columnsAreas = (goTo) => [
    {
        title: <DynamicStrings id="CREATE_OFFER_AREA_NAME" />,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: <DynamicStrings id="DELIVERY_TEMPLATES_CREATED_DATE" />,
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (date) => (date ? formatDate(date) : null),
    },
    // {
    //     title: '',
    //     key: 'action',
    //     width: 1,
    //     render: (e) => (
    //         <Button
    //             disabled={!e.parent}
    //             onClick={() => goTo(`/delivery/areas/${e.id}`)}
    //             type="primary"
    //         >
    //             <DynamicStrings id="ROLES_EDIT" />
    //         </Button>
    //     ),
    // },
]

export default DeliveryTemplates
