import { getOrders } from '../../network/API'
import moment from 'moment'
import { formatDate } from 'src/utils/utils'

const GeneralInformation = {
    title: 'DIRECT_SALES_GENERAL_INFORMATION',
    key: [],
    sections: [
        {
            title: 'DIRECT_SALES_GENERAL_INFORMATION',
            columns: 6,
            fields: [
                {
                    label: 'OFFER_LIVE_ORDER_ID',
                    key: ['orderInfos', 'orderID'],
                    isRequired: true,

                    type: 'search&update',
                    options: getOrders,
                    searchKey: 'orderID',
                    formatOnRecieved: (order) => {
                        let detail = ''
                        for (const pack of order.packs) {
                            detail += pack.amount
                                ? `${pack.amount} Pack ${pack.shortName},`
                                : ''
                        }

                        for (const item of order.singleItems) {
                            detail += item.amount
                                ? `${item.amount} Single Item ${item.shortName},`
                                : ''
                        }

                        return {
                            order: order.id,
                            customer: order.customer?.id,
                            community: order.community?.id,
                            offer: order.offer?.id,
                            supplier: order.supplier?.id,
                            orderInfos: {
                                orderID: order.orderID,
                                community: order.community?.name,
                                customer:
                                    order.customer?.name ||
                                    order.customerDaily?.customerName,
                                supplier: order.supplier?.name,
                                offer: order.offer?.internalName,
                                // date: moment(),
                                orderDate: moment(order.createdAt),
                                // totalAmount: Math.round(order.actualAmount),
                                totalAmount: order.actualAmount,
                                city: order.customerAddress?.city,
                                detailedAddress:
                                    order.customerAddress?.detailedAddress,
                                phone: order.customerAddress?.phone,
                                deliveryTrackingID: order.deliveryTrackingID,
                                deliveryDate: moment(
                                    order.deliveryDate
                                ).isValid()
                                    ? formatDate(order.deliveryDate)
                                    : order.deliveryDate,
                                detail: detail,
                                paymentStatus: order.paymentStatus,
                                paymentMethod: order.paymentMethod,
                            },
                        }
                    },
                    OnType: {
                        order: undefined,
                        orderInfos: undefined,
                    },
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },

                {
                    key: ['order'],
                    type: 'hidden',
                },
                {
                    key: ['customer'],
                    type: 'hidden',
                },
                {
                    key: ['teamMember'],
                    type: 'hidden',
                },
                {
                    key: ['community'],
                    type: 'hidden',
                },
                {
                    key: ['offer'],
                    type: 'hidden',
                },
                {
                    key: ['supplier'],
                    type: 'hidden',
                },
                // {
                //     key: ['status'],
                //     type: 'hidden',
                // },

                {
                    label: 'COMMUNITY_COMMUNITY',
                    isInactive: true,
                    key: ['orderInfos', 'community'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_CUSTOMER_NAME',
                    isInactive: true,
                    key: ['orderInfos', 'customer'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'SUPPLIERS_SUPPLIER_NAME',
                    isInactive: true,
                    key: ['orderInfos', 'supplier'],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFERS_OFFER_NAME',
                    isInactive: true,
                    key: ['orderInfos', 'offer'],
                    pos: {
                        col: 4,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'claim_date',
                    isInactive: true,
                    type: 'date',
                    default: moment(),
                    key: ['date'],
                    pos: {
                        col: 5,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMERS_ORDER_DATE',
                    isInactive: true,
                    type: 'date',
                    key: ['orderInfos', 'orderDate'],
                    pos: {
                        col: 6,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_TOTAL',
                    isInactive: true,
                    // type: 'number',
                    key: ['orderInfos', 'totalAmount'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_CITY',
                    isInactive: true,
                    key: ['orderInfos', 'city'],
                    pos: {
                        col: 2,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'DIRECT_SALES_DETAILED_ADDRESS',
                    isInactive: true,
                    key: ['orderInfos', 'detailedAddress'],
                    pos: {
                        col: 3,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_PHONE',
                    isInactive: true,
                    key: ['orderInfos', 'phone'],
                    pos: {
                        col: 4,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'DELIVERY_TRACKING_NUMBER',
                    isInactive: true,
                    key: ['orderInfos', 'deliveryTrackingID'],
                    pos: {
                        col: 5,
                        row: 3,
                        size: 1,
                    },
                },
                {
                    label: 'OFFER_LIVE_DELIVERY_DATE',
                    isInactive: true,
                    key: ['orderInfos', 'deliveryDate'],
                    pos: {
                        col: 6,
                        row: 3,
                        size: 1,
                    },
                },

                {
                    label: 'SERVICES_ORDER_LIST_ORDER_DETAILS',
                    isInactive: true,
                    type: 'text',
                    key: ['orderInfos', 'detail'],
                    pos: {
                        col: 1,
                        row: 4,
                        size: 2,
                    },
                },
            ],
        },
    ],
}

const ClaimDetail = {
    title: 'CUSTOMER_SERVICE_CLAIM_DETAIL',
    key: [],
    sections: [
        {
            title: 'CUSTOMER_SERVICE_CLAIM_DETAIL',
            columns: 6,
            fields: [
                {
                    label: 'CUSTOMER_SERVICE_RESPONSIBILITY',
                    type: 'select',
                    isRequired: true,
                    options: [
                        {
                            key: 'supplier',
                            label: 'supplier',
                        },
                        {
                            key: 'delivery',
                            label: 'DIRECT_SALES_DELIVERY',
                        },
                        {
                            key: 'ziko',
                            label: 'ziko',
                        },
                        {
                            key: 'customer',
                            label: 'customer',
                        },
                    ],
                    key: ['resp'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ISSUE',
                    isRequired: true,
                    type: 'select',
                    options: [
                        {
                            key: 'wrong_address',
                            label: 'wrong_address',
                        },
                        {
                            key: 'packaging',
                            label: 'packaging',
                        },
                        {
                            key: 'defrost',
                            label: 'defrost',
                        },
                        {
                            key: 'product_default',
                            label: 'product_damaged',
                        },
                        {
                            key: 'item_missing',
                            label: 'item_missing',
                        },
                        {
                            key: 'delay',
                            label: 'delay',
                        },
                        {
                            key: 'customer_mistake',
                            label: 'customer_mistake',
                        },
                        {
                            key: 'wrong_time',
                            label: 'wrong_time',
                        },
                        {
                            key: 'wrong_item',
                            label: 'wrong_item',
                        },
                        {
                            key: 'fapiao',
                            label: 'OFFER_LIVE_FAPIAO',
                        },
                        {
                            key: 'others',
                            label: 'Others',
                        },
                    ],
                    key: ['issue'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_STEP',
                    type: 'number',
                    isInactive: true,
                    default: 1,
                    key: ['step'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ACTION',
                    isRequired: true,
                    type: 'select',
                    options: [
                        {
                            key: 'resend',
                            label: 'resend',
                        },
                        {
                            key: 'refund',
                            label: 'rfd',
                        },
                        {
                            key: 'voucher',
                            label: 'OFFER_LIVE_VOUCHER',
                        },
                        {
                            key: 'gift_giving',
                            label: 'CUSTOMER_SERVICE_GIFT_NEXT_ORDER',
                        },
                        {
                            key: 'free_order',
                            label: 'CUSTOMER_SERVICE_FREE_ORDER',
                        },
                        {
                            key: 'other',
                            label: 'other',
                        },
                    ],
                    key: ['action'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ACTION_ETA',
                    isRequired: true,
                    type: 'date',
                    key: ['actionETA'],
                    pos: {
                        col: 5,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ISSUE_DETAIL',
                    type: 'text',
                    key: ['issueDetail'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 2,
                    },
                },
                {
                    label: 'OFFER_LIVE_COMMENT',
                    type: 'text',
                    key: ['comment'],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 2,
                    },
                },
            ],
        },
    ],
}

const ClaimDetailRefund = {
    title: 'CUSTOMER_SERVICE_CLAIM_DETAIL',
    key: [],
    sections: [
        {
            title: 'CUSTOMER_SERVICE_CLAIM_DETAIL',
            columns: 6,
            fields: [
                {
                    label: 'CUSTOMER_SERVICE_RESPONSIBILITY',
                    type: 'select',
                    isRequired: true,
                    options: [
                        {
                            key: 'supplier',
                            label: 'supplier',
                        },
                        {
                            key: 'delivery',
                            label: 'DIRECT_SALES_DELIVERY',
                        },
                        {
                            key: 'ziko',
                            label: 'ziko',
                        },
                        {
                            key: 'customer',
                            label: 'customer',
                        },
                    ],
                    key: ['resp'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ISSUE',
                    isRequired: true,
                    type: 'select',
                    options: [
                        {
                            key: 'wrong_address',
                            label: 'wrong_address',
                        },
                        {
                            key: 'packaging',
                            label: 'packaging',
                        },
                        {
                            key: 'defrost',
                            label: 'defrost',
                        },
                        {
                            key: 'product_default',
                            label: 'product_damaged',
                        },
                        {
                            key: 'item_missing',
                            label: 'item_missing',
                        },
                        {
                            key: 'delay',
                            label: 'delay',
                        },
                        {
                            key: 'customer_mistake',
                            label: 'customer_mistake',
                        },
                        {
                            key: 'wrong_time',
                            label: 'wrong_time',
                        },
                        {
                            key: 'wrong_item',
                            label: 'wrong_item',
                        },
                        {
                            key: 'fapiao',
                            label: 'OFFER_LIVE_FAPIAO',
                        },
                        {
                            key: 'others',
                            label: 'Others',
                        },
                    ],
                    key: ['issue'],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_STEP',
                    type: 'number',
                    isInactive: true,
                    default: 1,
                    key: ['step'],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ACTION',
                    isRequired: true,
                    type: 'select',
                    options: [
                        {
                            key: 'resend',
                            label: 'resend',
                        },
                        {
                            key: 'refund',
                            label: 'rfd',
                        },
                        {
                            key: 'voucher',
                            label: 'OFFER_LIVE_VOUCHER',
                        },
                        {
                            key: 'gift_giving',
                            label: 'CUSTOMER_SERVICE_GIFT_NEXT_ORDER',
                        },
                        {
                            key: 'free_order',
                            label: 'CUSTOMER_SERVICE_FREE_ORDER',
                        },
                        {
                            key: 'other',
                            label: 'other',
                        },
                    ],
                    key: ['action'],
                    pos: {
                        col: 4,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ACTION_ETA',
                    isRequired: true,
                    type: 'date',
                    key: ['actionETA'],
                    pos: {
                        col: 5,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'REFUNDS_REFUND_AMOUNT',
                    type: 'number',
                    isRequired: true,
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'refund',
                    parentKey: ['action'],
                    key: ['refundAmount'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'REFUNDS_PAYMENT_MERCHANT_ID',
                    isConditional: true,
                    isInactive: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'refund',
                    parentKey: ['action'],
                    key: ['outTradeNo'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'REFUNDS_PAYMENT',
                    isRequired: true,
                    isInactive: true,
                    type: 'select',
                    isConditional: true,
                    displayCondition: (parentValue) =>
                        parentValue === 'refund',
                    parentKey: ['action'],
                    key: ['orderInfos', 'paymentMethod'],
                    options: [
                        {
                            key: 'wechat',
                            label: 'Wechat'
                        },
                        {
                            key: 'alipay',
                            label: 'Alipay'
                        }
                    ],
                    pos: {
                        col: 3,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    label: 'CUSTOMER_SERVICE_ISSUE_DETAIL',
                    type: 'text',
                    key: ['issueDetail'],
                    pos: {
                        col: 1,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    label: 'OFFER_LIVE_COMMENT',
                    type: 'text',
                    key: ['comment'],
                    pos: {
                        col: 3,
                        row: 3,
                        size: 2,
                    },
                },
                {
                    key: ['orderInfos', 'paymentStatus'],
                    type: 'hidden',
                },
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    const user = JSON.parse(localStorage.getItem('zikoUser'))

    if (!oldData.teamMember) {
        oldData = {
            ...oldData,
            teamMember: user?.id,
        }
    }

    return oldData
}

const checkValidity = (values) => {
    if (!values.order) {
        return 'CREATE_REFUND_SELECT_A_VALID_ORDER'
    }
    return false
}

const checkValidityRefund = (values) => {
    if (!values.order) {
        return 'CREATE_REFUND_SELECT_A_VALID_ORDER'
    }
    if (values.action === 'refund') {
        if (values.orderInfos.paymentStatus !== 'paid') {
            return `You cannot apply a refund to this order. The order you selected is ${values.orderInfos.paymentStatus}.`
        }
        if (values.refundAmount && values.refundAmount > values.orderInfos.totalAmount) {
            return 'CREATE_REFUND_NO_MORE_THAN_ACTUAL_AMOUNT'
        }
    }

    return false
}

export {
    GeneralInformation,
    ClaimDetail,
    ClaimDetailRefund,
    cleanBeforeSending,
    checkValidity,
    checkValidityRefund
}
