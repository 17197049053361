import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Form } from 'antd'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

import {
    Description,
    Media,
    Tags,
    RecipeDetails as Details,
    cleanBeforeSending,
} from '../../constants/forms/Recipes'
import { EditRecipe, getSingleRecipe } from '../../network/Recipes'

const RecipeDetails = ({ history }) => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()

    let { recipeId } = useParams()
    const recipeDetails = useQuery(
        [
            'getSingleRecipe',
            {
                recipeId: recipeId,
                token: token.token,
            },
        ],
        getSingleRecipe,
        {
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (oldData) => {
        return {
            ...oldData,
            tags_meal: oldData.tags,
            tags_allergy: oldData.tags,
            tags_cuisine: oldData.tags,
            tags_time: oldData.tags,
            tags_community: oldData.tags,
            tags_ingredients: oldData.tags,
            tags_diet: oldData.tags,
        }
    }

    return (
        <PageWrap
            goBack
            isLoading={recipeDetails.isLoading}
            title={recipeDetails.data?.name?.en}
        >
            {recipeDetails.isError ? (
                <Error retry={() => recipeDetails.refetch()} />
            ) : recipeDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    title="create_recipe"
                    request={EditRecipe}
                    id={recipeId}
                    form={form}
                    onSuccess="/recipes"
                    onCancel="/recipes"
                    cleanBeforeSending={cleanBeforeSending}
                    data={formatData(recipeDetails.data)}
                    invalidate={['getRecipes', 'getSingleRecipe']}
                    checkValidity={(values) => {
                        if (
                            !values.mainPicture?.en?.uri ||
                            !values.mainPicture?.zh?.uri
                        ) {
                            return 'Please set main pictures'
                        }

                        return false
                    }}
                >
                    <div className="SectionHorizontalWrap">
                        <div
                            className="SectionVerticalWrap"
                            style={{ flex: 2 }}
                        >
                            <FormBlock form={form} formTemplate={Description} />
                            <FormBlock form={form} formTemplate={Media} />
                        </div>

                        <div
                            className="SectionVerticalWrap"
                            style={{ flex: 1 }}
                        >
                            <FormBlock formTemplate={Tags} />
                            <FormBlock formTemplate={Details} />
                        </div>
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default RecipeDetails
