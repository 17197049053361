import React from 'react'
import { useEffect, useContext, useState, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { Spin } from 'antd'

import { getSingleOffer } from '../../network/Offers'
import { formatNb } from '../../utils/utils'
import { getOrders } from '../../network/API'

import { WSOrders } from '../../context/WSOrders'

import { AppContext } from '../../context/AppContext'
import PageWrap from '../../components/layout/PageWrap'
import WxGroup from './WxGroup'
import Miniprogram from './Miniprogram'
import Analytics from './Analytics'
import Lottery from './Lottery'
import LagRadar from 'react-lag-radar'

const client = new W3CWebSocket(process.env.REACT_APP_WS_URL)

function wxOrderReducer(state, action) {
    let tmpOrders = [...state]

    switch (action.type) {
        case 'init':
            return action.payload
        case 'line-edit':
            const lineIndex = state.findIndex((e) => e.id === action.payload.id)
            tmpOrders[lineIndex] = action.payload
            return tmpOrders
        case 'ws-edit':
            const wsIndex = state.findIndex((e) => e.id === action.payload.id)
            tmpOrders[wsIndex] = action.payload
            return tmpOrders
        case 'ws-add':
            const newWsIndex = state.findIndex(
                (e) => e.id === action.payload.id
            )
            if (newWsIndex > -1) {
                tmpOrders[newWsIndex] = action.payload
                return tmpOrders
            }
            return [action.payload, ...state]
        case 'line-add':
            return [action.payload, ...state]
        default:
            break
    }
}

function mpOrderReducer(state, action) {
    let tmpOrders = [...state]

    switch (action.type) {
        case 'init':
            return action.payload
        case 'ws-edit':
            const wsIndex = state.findIndex((e) => e.id === action.payload.id)
            tmpOrders[wsIndex] = action.payload
            return tmpOrders
        case 'ws-add':
            return [action.payload, ...state]
        default:
            break
    }
}

function offerReducer(state, action) {
    switch (action.type) {
        case 'init':
            return action.payload
        case 'ws-edit':
            return action.payload
        default:
            break
    }
}

const canAnalytics = (role) => {
    if (
        role !== 'super-admin' &&
        role !== 'admin' &&
        role !== 'product-manager' &&
        role !== 'community-manager' &&
        role !== 'finance'
    ) {
        return true
    }
    return false
}

const canLottery = (role) => {
    if (
        role !== 'super-admin' &&
        role !== 'admin' &&
        role !== 'product-manager' &&
        role !== 'community-manager' &&
        role !== 'logistic'
    ) {
        return true
    }
    return false
}

const OfferOrders = ({ history }) => {
    const { offerId } = useParams()
    const { token, user } = useContext(AppContext)
    const [wxOrders, dispatchWXOrders] = useReducer(wxOrderReducer, [])
    const [prevWXOrders, setPrevWXOrders] = useState([])
    const [mpOrders, dispatchMPOrders] = useReducer(mpOrderReducer, [])
    const [offer, dispatchOffer] = useReducer(offerReducer, null)
    const [offerIsLoading, setOfferIsLoading] = useState(true)
    // const [ws, setWs] = useReducer((state, payload) => payload)
    const [wxOrdersIsLoading, setWXOrdersIsLoading] = useState(true)
    const [mpOrdersIsLoading, setMPOrdersIsLoading] = useState(true)
    const [wsStatus, setWsStatus] = useState('#ccc')
    const [keyPage, setKeyPage] = useState(
        history?.location?.defaultTab || 'wechat_group'
    )

    const initWS = (initialWSs) => {
        const initialWS = new W3CWebSocket(process.env.REACT_APP_WS_URL)
        initialWS.onopen = () => {
            console.log('WebSocket Client Connected')
            initialWS.send(
                JSON.stringify({
                    type: 'offer',
                    id: offerId,
                    token: token.token,
                })
            )
        }

        initialWS.onclose = () => {
            console.log('WebSocket Client Closed')
            setWsStatus('#f50')
            setTimeout(function () {
                // reconnect()
            }, 1000)
        }

        return initialWS
    }

    const reconnect = () => {
        const initialWS = new W3CWebSocket(process.env.REACT_APP_WS_URL)
        setWsStatus('#108ee9')
        initialWS.onopen = () => {
            console.log('WebSocket Client Connected')
            initialWS.send(
                JSON.stringify({
                    type: 'offer',
                    id: offerId,
                    token: token.token,
                })
            )
        }

        initialWS.onclose = () => {
            console.log('WebSocket Client Closed')
            setWsStatus('#f50')
            setTimeout(function () {
                reconnect()
            }, 1000)
        }

        dispatchWS(initialWS)
    }

    const wsReducer = (state, action) => {
        return action
    }

    const [wsOffer, dispatchWS] = useReducer(wsReducer, client, initWS)

    const keyOrdersQuery = (channel) => [
        'getWSOrders',
        {
            token: token.token,
            filter: {
                channel,
                offer: offerId,
            },
            sort: ['orderDate', 'DESC'],
            unlimited: true,
            vouchers: true,
            valid: true,
        },
    ]

    const keyOfferQuery = [
        'getSingleOffer',
        {
            offerId: offerId,
            token: token.token,
        },
    ]

    useEffect(() => {
        updateWithWS()

        getSingleOffer({ queryKey: keyOfferQuery }).then((offer) => {
            if (
                offer?.channel === 'miniprogram' &&
                !history?.location?.defaultTab
            ) {
                setKeyPage('miniprogram')
            }
            dispatchOffer({ type: 'init', payload: offer })
            setOfferIsLoading(false)
        })

        getOrders({ queryKey: keyOrdersQuery('wechat_group') }).then(
            (orders) => {
                dispatchWXOrders({ type: 'init', payload: orders.list })
                setPrevWXOrders(orders.list)
                setWXOrdersIsLoading(false)
            }
        )

        getOrders({ queryKey: keyOrdersQuery('miniprogram') }).then(
            (orders) => {
                dispatchMPOrders({ type: 'init', payload: orders.list })
                setMPOrdersIsLoading(false)
            }
        )
    }, [])

    useEffect(() => {
        if (keyPage === 'wechat_group') {
            setWXOrdersIsLoading(true)
            getOrders({ queryKey: keyOrdersQuery('wechat_group') }).then(
                (orders) => {
                    dispatchWXOrders({ type: 'init', payload: orders.list })
                    setPrevWXOrders(orders.list)
                    setWXOrdersIsLoading(false)
                }
            )
        }

        if (keyPage === 'miniprogram') {
            setMPOrdersIsLoading(true)
            getOrders({ queryKey: keyOrdersQuery('miniprogram') }).then(
                (orders) => {
                    dispatchMPOrders({ type: 'init', payload: orders.list })
                    setMPOrdersIsLoading(false)
                }
            )
        }
    }, [keyPage])

    const updateWithWS = () => {
        wsOffer.addEventListener('message', (message) => {
            try {
                let data = JSON.parse(message.data)
                console.log('WebSocket - Message recieved global', data)

                switch (data?.type) {
                    case 'UPDATE_ORDER':
                        if (data.order.channel === 'wechat_group') {
                            dispatchWXOrders({
                                type: 'ws-edit',
                                payload: data.order,
                            })
                        } else {
                        }
                        break
                    case 'UPDATE_OFFER':
                        dispatchOffer({ type: 'ws-edit', payload: data.offer })
                        break
                    case 'NEW_ORDER':
                        if (data.order.channel === 'wechat_group') {
                            dispatchWXOrders({
                                type: 'ws-add',
                                payload: data.order,
                            })
                        } else {
                            dispatchMPOrders({
                                type: 'ws-add',
                                payload: data.order,
                            })
                        }
                        break
                    case 'ack':
                        setWsStatus('#87d068')
                        break
                    default:
                        break
                }
            } catch (error) {
                return
            }
        })
    }

    const isFieldDisabled = (channel, key) =>
        channel === 'all' || channel === key ? false : true

    // stat for header about the offer
    // const ordersToStats = (wxOrders, mpOrders, target) => {
    const ordersToStats = (wxOrders, mpOrders, targetSales) => {
        let target
        if (targetSales && targetSales !== null) {
            target = targetSales
        }

        let stats = {
            targetRate: '-',
            totalSales: '-',
            avgSpending: '-',
        }

        let totalMP = 0
        let totalWX = 0
        let totalOrders = 0
        let totalDelivery = 0
        for (const order of wxOrders) {
            if (
                !(
                    order.status === 'discontinued' &&
                    order.paymentStatus === 'pending'
                )
            ) {
                let totalDiscount = 0
                for (const gift of order.gifts) {
                    if (gift.type === 'discount')
                        totalDiscount += gift.discountAmount
                    if (totalDiscount > 100) break
                }
                if (totalDiscount > 100) totalDiscount = 100

                // totalOrders++
                if (order.paymentStatus !== 'rfd') {
                    totalOrders++
                }

                // totalWX += order.totalAmount
                // totalWX += order.actualAmount

                // Keep the same formula as the one in offer list
                // Only pending/paid/cancelled/refunding orders will be counted
                // For partly refund orders, only count the paid part
                if (order.paymentStatus === 'pending' || order.paymentStatus === 'paid' || order.paymentStatus === 'ccl' || order.paymentStatus === 'refunding') {
                    totalWX += order.actualAmount
                } else if (order.paymentStatus === 'rfd') {
                    const refundAmount = order.refundAmount ? order.refundAmount : order.actualAmount
                    totalWX += order.actualAmount - refundAmount
                }
                totalDelivery += order.deliveryFee * (1 - totalDiscount / 100)
            }
        }
        for (const order of mpOrders) {
            if (
                !(
                    order.status === 'discontinued' &&
                    order.paymentStatus === 'pending'
                )
            ) {
                let totalDiscount = 0
                for (const gift of order.gifts) {
                    if (gift.type === 'discount')
                        totalDiscount += gift.discountAmount
                    if (totalDiscount > 100) break
                }
                if (totalDiscount > 100) totalDiscount = 100

                // totalOrders++
                if (order.paymentStatus !== 'rfd') {
                    totalOrders++
                }

                // totalMP += order.totalAmount
                // totalMP += order.actualAmount

                // Keep the same formula as the one in offer list
                // Only pending/paid/cancelled/refunding orders will be counted
                // For partly refund orders, only count the paid part
                if (order.paymentStatus === 'pending' || order.paymentStatus === 'paid' || order.paymentStatus === 'ccl' || order.paymentStatus === 'refunding') {
                    totalMP += order.actualAmount
                } else if (order.paymentStatus === 'rfd') {
                    const refundAmount = order.refundAmount ? order.refundAmount : order.actualAmount
                    totalWX += order.actualAmount - refundAmount
                }
                totalDelivery += order.deliveryFee * (1 - totalDiscount / 100)
            }
        }

        // stats.totalSales = formatNb(totalMP + totalWX - totalDelivery) || '0'
        stats.totalSales = formatNb(totalMP + totalWX) || '0'
        stats.avgSpending = formatNb((totalMP + totalWX) / totalOrders) || '0'
        stats.targetRate =
            // formatNb(((totalMP + totalWX - totalDelivery) * 100) / target) ||
            formatNb(((totalMP + totalWX) * 100) / target) ||
            '0'

        // stats.orders = wxOrders.length + mpOrders.length
        stats.orders = totalOrders

        return stats
    }

    return (
        <PageWrap
            isLoading={offerIsLoading}
            radioValue={keyPage}
            radioOptions={
                offerIsLoading
                    ? null
                    : [
                          {
                              label: 'OFFER_LIVE_WECHAT_GROUP',
                              key: 'wechat_group',
                              disabled: isFieldDisabled(
                                  offer?.channel,
                                  'wechat_group'
                              ),
                          },
                          {
                              label: 'miniprogram',
                              key: 'miniprogram',
                              disabled: isFieldDisabled(
                                  offer?.channel,
                                  'miniprogram'
                              ),
                          },
                          {
                              label: 'lottery',
                              key: 'lottery',
                              disabled: canLottery(user.role),
                          },
                          {
                              label: 'MENU_ANALYTICS',
                              key: 'analytics',
                              disabled: canAnalytics(user.role),
                              //   disabled:
                              //       new Date(offer.startingDate) > new Date(),
                          },
                      ]
            }
            handleRadioChange={(key) => {
                if (key === 'wechat_group') {
                    setWXOrdersIsLoading(true)
                    getOrders({
                        queryKey: keyOrdersQuery('wechat_group'),
                    }).then((orders) => {
                        dispatchWXOrders({ type: 'init', payload: orders.list })
                        setPrevWXOrders(orders.list)
                        setWXOrdersIsLoading(false)
                    })
                }
                setKeyPage(key)
            }}
            offer={offer}
            network={wsStatus}
            offerStats={ordersToStats(wxOrders, mpOrders, offer?.targetSales)}
        >
            {(!process.env.NODE_ENV ||
                process.env.NODE_ENV === 'development') && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                        zIndex: 2,
                    }}
                >
                    <LagRadar />
                </div>
            )}

            <WSOrders.Provider value={{ wsOffer, dispatchWS }}>
                {keyPage === 'wechat_group' && offer?.wechatGroup && (
                    <WxGroup
                        orders={prevWXOrders}
                        bourse={
                            offer.type === 'bourse'
                                ? offer?.wechatGroup?.bourses
                                : null
                        }
                        freeFall={offer.type === 'free_fall'}
                        isLoading={wxOrdersIsLoading}
                        offerId={offer?.id}
                        supplierId={offer?.supplier}
                        community={offer?.community}
                        packs={offer?.wechatGroup?.packs}
                        items={offer?.wechatGroup?.items}
                        fees={offer?.fees?.map((fee) =>
                            fee.area?.id ? fee.area.id : fee.area
                        )}
                        deliveryDates={offer?.deliveryDates}
                        clipboard={offer?.clipboard}
                        minimumCartItems={offer?.minimumCartItems}
                        minimumOrderAmount={offer?.minimumOrderAmount}
                        canCreate={
                            true /*new Date(offer.endingDate) > new Date()*/
                        }
                    />
                )}
                {keyPage === 'miniprogram' && offer?.miniprogram && (
                    <Miniprogram
                        orders={mpOrders}
                        bourse={
                            offer.type === 'bourse'
                                ? offer?.miniprogram?.bourses
                                : null
                        }
                        freeFall={offer.type === 'free_fall'}
                        packs={offer?.miniprogram?.packs}
                        items={offer?.miniprogram?.items}
                        isLoading={mpOrdersIsLoading}
                    />
                )}

                {keyPage === 'analytics' && (
                    <Analytics
                        offer={offer}
                        wxOrders={wxOrders}
                        mpOrders={mpOrders}
                    />
                )}

                {keyPage === 'lottery' && (
                    <Lottery
                        offerId={offer?.id}
                        offerName={offer?.internalName}
                        offer={offer}
                        wxOrders={wxOrders}
                        mpOrders={mpOrders}
                    />
                )}
            </WSOrders.Provider>
        </PageWrap>
    )
}

export default OfferOrders
