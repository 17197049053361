import { useContext, useRef, useEffect } from 'react'
import { Form, Select } from 'antd'
import { useQuery } from 'react-query'

import { AppContext } from '../context/AppContext'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const LoadingSelect = ({
    onChange,
    request,
    speKey,
    label,
    isSearch,
    autoFocus,
    noAll,
    defaultValue,
    value,
    otherParams = {},
    multiple = false,
}) => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const inputRef = useRef(null)
    const options = useQuery(
        [
            // format naming to find request in react query
            `get${speKey.charAt(0).toUpperCase() + speKey.slice(1)}Select`,
            { token: token.token, filter: { ...otherParams } },
        ],
        request
    )

    useEffect(() => {
        if (inputRef?.current) {
            if (autoFocus) {
                inputRef.current.focus({
                    cursor: 'start',
                })
            }
        }
    }, [])

    return (
        <Form.Item
            label={label}
            style={{ margin: 0 }}
            validateStatus={options.status}
        >
            <Select
                mode={ multiple ? 'multiple' : '' }
                defaultOpen={autoFocus}
                ref={inputRef}
                showSearch={isSearch}
                loading={options.isLoading}
                defaultValue={defaultValue}
                value={value}
                onChange={(e) => onChange(e)}
                style={{ width: multiple ? 285 : 190 }}
                optionFilterProp={!isSearch ? null : 'children'}
                filterOption={
                    !isSearch
                        ? null
                        : (input, option) =>
                              option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={
                    !isSearch
                        ? null
                        : (optionA, optionB) =>
                              optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                }
            >
                {!noAll && (
                    <Option key={''} value={''}>
                        {t('OFFERS_ALL')}
                    </Option>
                )}
                {options.data?.list.map((option) => (
                    <Option key={option.id} value={option.id}>
                        {t(option.name)}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export default LoadingSelect
