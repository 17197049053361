/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react'
import { Space, Typography, Menu, Dropdown, Badge } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { downloadCSV, formatDate, formatNb } from '../utils/utils'

import {
    getClaims,
    getClaimsAnalytics,
    EditClaims,
    ClaimNextStep,
} from '../network/API'
import { getSuppliers } from '../network/Suppliers'
import { AppContext } from 'src/context/AppContext'
import { useMutation, useQueryClient } from 'react-query'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import Status from '../components/tables/cells/Status'
import DynamicStrings from '../components/DynamicStrings'

const { Text } = Typography

const Claims = ({ history }) => {
    const { user } = useContext(AppContext)

    const commuFilter = (key) =>
        user.role === 'admin' || user.role === 'super-admin'
            ? {}
            : { [key]: { $in: user.communities } }

    return (
        <PageWrap title="MENU_CLAIMS">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getClaims'}
                request={getClaims}
                className="table-claims"
                stats={stats}
                // components={{
                //     body: {
                //         cell: ({ children, ...restProps }) => {
                //             return <td {...restProps}>{children}</td>
                //         },
                //     },
                // }}
                statsRequest={getClaimsAnalytics}
                rowSelection={(offer) => ({})}
                pageSize={80}
                otherFilters={{
                    ...commuFilter('community'),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (
                                record?.status !== 'solved' &&
                                record?.status !== 'failed'
                            ) {
                                history.push({
                                    pathname: `/claims/${record?.id}`,
                                    previousHash: window.location.hash,
                                })
                            } else {
                                return false
                            }
                        },
                    }
                }}
            />
        </PageWrap>
    )
}

const filters = (history, selectedLines, loading, id, list) => ({
    fields: [
        {
            type: 'select',
            label: 'OFFERS_STATUS',
            default: '',
            key: 'status',
            options: [
                { key: 'new', label: 'new' },
                { key: 'ongoing', label: 'ongoing' },
                { key: 'standby', label: 'standby' },
                { key: 'solved', label: 'solved' },
                { key: 'failed', label: 'failed' },
            ],
        },
        {
            type: 'select',
            label: 'SUPPLIERS_SUPPLIER_NAME',
            default: '',
            key: 'supplier',
            options: getSuppliers,
        },
        {
            type: 'text',
            label: 'CUSTOMERS_CUSTOMER_NAME',
            key: 'customer.name',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'ROLES_TEAM_MEMBER',
            key: 'teamMember.name',
            noRegex: true,
        },
        {
            type: 'select',
            label: 'CREATE_OFFER_CHANNEL',
            default: '',
            key: 'order.channel',
            options: [
                { key: 'wechat_group', label: 'wechat_group' },
                { key: 'miniprogram', label: 'miniprogram' },
            ],
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    actions: [
        {
            label: 'CUSTOMER_SERVICE_EXPORT_CLAIMS',
            isActive: selectedLines,
            lineAction: async () => {
                const toExport = list.filter((e) =>
                    selectedLines.includes(e?.id)
                )

                downloadCSV(
                    [
                        'Order ID',
                        'Team member',
                        'Community',
                        'Date',
                        'Customer name',
                        'Supplier name',
                        'Responsability',
                        'Issue',
                        'Issue detail',
                        'Step',
                        'Action',
                        'Action ETA',
                        'Comment',
                        'Status',
                    ],
                    [
                        ...toExport.map((e) => [
                            e?.order?.orderID,
                            e?.teamMember?.name,
                            e?.community?.name,
                            formatDate(e?.date, 'long'),
                            e?.customer?.name,
                            e?.supplier?.name,
                            e?.resp,
                            e?.issue,
                            e?.issueDetail,
                            e?.step,
                            e?.action,
                            formatDate(e?.actionETA),
                            e?.comment,
                            e?.status,
                        ]),
                    ],
                    `Claims ${formatDate(new Date())}`
                )
            },
        },
        {
            label: 'CUSTOMER_SERVICE_CREATE_CLAIM',
            isActive: true,
            action: () => history.push('/claims/create'),
        },
    ],
})

const stats = () => [
    {
        isWrap: true,
        stats: [
            {
                label: 'ANALYTICS_CUSTOMER_SATISFACTION_RATE',
                colored: true,
                getValue: (e) => e?.satisfaction || 0,
                isSpe: true,
                suffix: '%',
            },
        ],
    },
    {
        title: 'MENU_ANALYTICS',
        stats: [
            {
                label: 'ANALYTICS_TOTAL_CLAIMS',
                getValue: (e) => e?.totalAmount,
            },
            {
                label: 'CUSTOMER_SERVICE_SOLVED_RATIO',
                getValue: (e) => {
                    const solvedIndex = e?.claimsByStatus?.findIndex(
                        (e) => e?._id === 'solved'
                    )

                    if (
                        !e?.claimsByStatus[solvedIndex]?.amount ||
                        !e?.totalAmount
                    )
                        return '0'

                    return solvedIndex || solvedIndex > -1
                        ? (e?.claimsByStatus[solvedIndex]?.amount /
                              e?.totalAmount) *
                              100
                        : '0'
                },
                suffix: '%',
            },
            {
                label: 'CUSTOMER_SERVICE_ONGOING_RATIO',
                getValue: (e) => {
                    const onGoingIndex = e?.claimsByStatus?.findIndex(
                        (e) => e?._id === 'ongoing'
                    )

                    if (
                        !e?.claimsByStatus[onGoingIndex]?.amount ||
                        !e?.totalAmount
                    )
                        return '0'

                    return onGoingIndex || onGoingIndex > -1
                        ? (e?.claimsByStatus[onGoingIndex]?.amount /
                              e?.totalAmount) *
                              100
                        : '0'
                },
                suffix: '%',
            },
            {
                label: 'CUSTOMER_SERVICE_RESEND_RATIO',
                getValue: (e) => {
                    const resendIndex = e?.claimsByAction?.findIndex(
                        (e) => e?._id === 'resend'
                    )

                    if (
                        !e?.claimsByAction[
                            e?.claimsByAction?.findIndex(
                                (e) => e?._id === 'resend'
                            )
                        ]?.amount ||
                        !e?.totalAmount
                    )
                        return '0'

                    return resendIndex || resendIndex > -1
                        ? (e?.claimsByAction[
                              e?.claimsByAction?.findIndex(
                                  (e) => e?._id === 'resend'
                              )
                          ]?.amount /
                              e?.totalAmount) *
                              100
                        : '0'
                },
                suffix: '%',
            },
            {
                label: 'CUSTOMER_SERVICE_TOTAL_VOUCHERS',
                getValue: (e) => {
                    const voucherIndex = e?.claimsByAction?.findIndex(
                        (e) => e?._id === 'voucher'
                    )

                    return voucherIndex || voucherIndex > -1
                        ? e?.claimsByAction[voucherIndex]?.amount
                        : 0
                },
            },
        ],
    },
    {
        title: 'CUSTOMER_SERVICE_RESPONSIBILITY',
        stats: [
            {
                label: 'ziko',
                getValue: (e) => {
                    const zikoIndex = e?.claimByResp?.findIndex(
                        (e) => e?._id === 'ziko'
                    )

                    return zikoIndex || zikoIndex > -1
                        ? e?.claimByResp[zikoIndex]?.amount
                        : 0
                },
            },
            {
                label: 'customer',
                getValue: (e) => {
                    const deliveryIndex = e?.claimByResp?.findIndex(
                        (e) => e?._id === 'customer'
                    )

                    return deliveryIndex || deliveryIndex > -1
                        ? e?.claimByResp[deliveryIndex]?.amount
                        : 0
                },
            },
            {
                label: 'CUSTOMER_SERVICE_DELIVERY_SERVICE',
                getValue: (e) => {
                    const deliveryIndex = e?.claimByResp?.findIndex(
                        (e) => e?._id === 'delivery'
                    )

                    return deliveryIndex || deliveryIndex > -1
                        ? e?.claimByResp[deliveryIndex]?.amount
                        : 0
                },
            },
            {
                label: 'supplier',
                getValue: (e) => {
                    const deliveryIndex = e?.claimByResp?.findIndex(
                        (e) => e?._id === 'supplier'
                    )

                    return deliveryIndex || deliveryIndex > -1
                        ? e?.claimByResp[deliveryIndex]?.amount
                        : 0
                },
            },
        ],
    },
]

const isRed = (claim) =>
    claim.status !== 'solved' &&
    claim.status !== 'failed' &&
    Date?.now(claim.createdAt) < Date?.now() - 1000 * 60 * 60 * 24 * 7
        ? 'urgent-claim'
        : 'claim'

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        // dataIndex: 'order',
        render: (claim) => (
            <div className={isRed(claim)}>{claim.order?.orderID}</div>
        ),
        key: 'orderID',
    },
    {
        title: <DynamicStrings id="ROLES_TEAM_MEMBER" />,
        // dataIndex: 'teamMember',
        key: 'teamMember',
        render: (claim) => (
            <div className={isRed(claim)}>{claim.teamMember?.name}</div>
        ),
        sorter: false,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        // dataIndex: 'community',
        key: 'community',
        render: (claim) => (
            <div className={isRed(claim)}>
                <DynamicStrings id={claim.community?.name} />
            </div>
        ),
        sorter: false,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_DATE" />,
        // dataIndex: 'createdAt',
        key: 'createdAt',
        render: (claim) => (
            <div className={isRed(claim)}>{formatDate(claim.date, 'long')}</div>
        ),
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_CUSTOMER_NAME" />,
        // dataIndex: 'customer',
        key: 'customerName',
        render: (claim) => (
            <div className={isRed(claim)}>{claim.customer?.name}</div>
        ),
        sorter: false,
    },
    {
        title: <DynamicStrings id="SUPPLIERS_SUPPLIER_NAME" />,
        // dataIndex: 'supplier',
        key: 'supplierName',
        render: (claim) => (
            <div className={isRed(claim)}>{claim.supplier?.name}</div>
        ),
        sorter: false,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_RESPONSIBILITY" />,
        // dataIndex: 'resp',
        key: 'resp',
        render: (claim) => (
            <div className={isRed(claim)}>
                <DynamicStrings id={claim.resp} />
            </div>
        ),
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ISSUE" />,
        // dataIndex: 'issue',
        render: (claim) => (
            <div className={isRed(claim)}>
                <DynamicStrings id={claim.issue} />
            </div>
        ),
        key: 'issue',
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ISSUE_DETAIL" />,
        // dataIndex: 'issueDetail',
        key: 'issueDetail',
        render: (claim) => (
            <div className={isRed(claim)}>{claim.issueDetail}</div>
        ),
        ellipsis: true,
        // render: (text) => (
        //     <Popover content={text} trigger="hover">
        //         <Paragraph style={{ width: 125, margin: 0 }} ellipsis={true}>
        //             {text}
        //         </Paragraph>
        //     </Popover>
        // ),
    },
    {
        title: <DynamicStrings id="REFUNDS_ORDER_TOTAL" />,
        key: 'order.actualAmount',
        render: (claim) => (
            <div className={isRed(claim)}>
                {formatNb(claim.order?.actualAmount)}
            </div>
        ),
        sorter: false,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_STEP" />,
        // dataIndex: 'step',
        render: (claim) => <div className={isRed(claim)}>{claim.step}</div>,
        key: 'step',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ACTION" />,
        // dataIndex: 'action',
        render: (claim) => (
            <div className={isRed(claim)}>
                <DynamicStrings id={claim.action} />
            </div>
        ),
        key: 'action',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ACTION_ETA" />,
        // dataIndex: 'actionETA',
        key: 'actionETA',
        render: (claim) => (
            <div className={isRed(claim)}>{formatDate(claim.actionETA)}</div>
        ),
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
        // dataIndex: 'comment',
        key: 'comment',
        render: (claim) => <div className={isRed(claim)}>{claim.comment}</div>,

        ellipsis: true,
        sorter: false,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        // dataIndex: 'status',
        render: (claim) => (
            <div className={isRed(claim)} onClick={(e) => e.stopPropagation()}>
                <ClaimStatus
                    statusProps={{
                        status: claim.status,
                        requestName: 'getClaims',
                        fieldKey: 'status',
                        keyRequest: keyRequest,
                        editRequest: EditClaims,
                        id: claim.id,
                        step: claim.step,
                        config: [
                            { key: 'new', color: 'cyan' },
                            { key: 'ongoing', color: 'yellow' },
                            { key: 'standby', color: 'orange' },
                            { key: 'solved', color: 'green' },
                            { key: 'failed', color: 'red' },
                        ],
                    }}
                />
            </div>
        ),
        key: 'status',
        sorter: false,
    },
    {
        title: '',
        key: 'action',
        width: 50,
        render: (e) => {
            const menu = (
                <Menu>
                    <Menu.Item key="0">
                        <a onClick={() => goTo(`/customers/${e?.customer.id}`)}>
                            <DynamicStrings id="DIRECT_SALES_CUSTOMER_INFORMATION" />
                        </a>
                    </Menu.Item>
                    <Menu.Item key="1">
                        <a
                            onClick={() =>
                                goTo({
                                    pathname: '/vouchers/create',
                                    customer: e?.customer,
                                })
                            }
                        >
                            <DynamicStrings id="SERVICES_ORDER_LIST_CREATE_VOUCHER" />
                        </a>
                    </Menu.Item>
                    {/* {e?.status !== 'solved' && e?.status !== 'failed' && (
                        <Menu.Item key="1">
                            <a onClick={() => goTo({
                                pathname: `/claims/${e?.id}`,
                                previousHash: window.location.hash,
                            })}>
                                <DynamicStrings id="ROLES_EDIT" />
                            </a>
                        </Menu.Item>
                    )} */}
                </Menu>
            )
            return (
                <div className={isRed(e)} onClick={(e) => e?.stopPropagation()}>
                    <Space className="btnCenter">
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a
                                className="ant-dropdown-link"
                                onClick={(e) => e?.stopPropagation()}
                            >
                                <MoreOutlined />
                            </a>
                        </Dropdown>
                    </Space>
                </div>
            )
        },
    },
]

const ClaimStatus = ({ step, statusProps }) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const mutation = useMutation((values) => ClaimNextStep(...values), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(statusProps.keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e?.id === statusProps.id
                )
                oldData.list[oldDataIndex].status = 'failed'
                return oldData
            })
            queryClient.invalidateQueries('getClaims')
            queryClient.invalidateQueries('getClaimsAnalytics')
            queryClient.invalidateQueries('CustomerClaims')
        },
    })

    if (statusProps.status === 'solved')
        return <Badge color={'green'} text={t('solved')} />
    if (statusProps.status === 'failed')
        return <Badge color={'red'} text={t('failed')} />

    return (
        <Status
            {...statusProps}
            onChange={(e) => mutation.mutate([statusProps.id, token.token])}
            onChangeKey={statusProps.step === 3 ? '' : 'failed'}
            isLoading={mutation.isLoading}
        />
    )
}

export default Claims
