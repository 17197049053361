const zh = {
    MENU_ANALYTICS: '分析',
    MENU_OFFERS: '服务订单',
    MENU_OFFER_LIST: '服务订单列表',
    MENU_CREATE_AN_OFFER: '创建服务订单',
    MENU_DELIVERY_TEMPLATES: '送货模板',
    MENU_ORDERS: '订单',
    MENU_SERVICES_ORDER_LIST: '服务订单列表',
    MENU_DIRECT_SALES_ORDER_LIST: '销售订单列表',
    MENU_CUSTOMERS: '客户',
    MENU_SUPPLIERS: '供应商',
    MENU_PRODUCTS: '产品',
    MENU_INVENTORY: '库存',
    MENU_PURCHASE_ORDERS: '采购订单',
    MENU_CUSTOMER_SERVICE: '客户服务',
    MENU_CLAIMS: '投诉',
    MENU_VOUCHER_MANAGEMENT: '代金券管理',
    MENU_RECIPES: '食谱',
    MENU_LOTTERY_MANAGEMENT: '抽奖管理',
    MENU_FINANCE: '财务',
    MENU_OVERVIEW: '概览',
    MENU_DIRECT_DIRECT_SALES: '销售 ',
    MENU_SERVICES: '服务 ',
    MENU_ZIKO_TEAM: 'Ziko 团队',

    admin: '管理员',
    'product-manager': '产品经理',
    'community-manager': '社区经理',
    'customer-hero': '客户服务',
    designer: '设计师',
    logistic: '物流',
    finance: '财务',
    ROLES_TEAM_MEMBER: '团队成员',
    ROLES_NAME: '名字',
    ROLES_ROLES: '角色',
    ROLES_COMMUNITY: '社区',
    All: '全部',
    Shanghai: '上海',
    Guangzhou: '广州',
    Kitchen: '厨房',
    Garden: '菜园',
    Farm: '农场',
    Cellar: '酒窖',
    Pet: '宠物',
    ROLES_CITY: '城市',
    ROLES_CREATE_TEAM_MEMBER: '创建团队成员',
    ROLES_ROLE: '角色',
    ROLES_EDIT: '编辑',
    ROLES_DESCRIPTION: '描述',
    ROLES_EMAIL: '邮箱',
    ROLES_PASSWORD: '密码',
    ROLES_SAVE: '保存',
    ROLES_CANCEL: '取消',
    ROLES_DELETE: '删除',

    ANALYTICS_GENERAL_ANALYTICS: '一般分析',
    ANALYTICS_DATES: '日期',
    ANALYTICS_START_DATE: '开始日期',
    ANALYTICS_END_DATE: '结束日期',
    ANALYTICS_SALES: '销售',
    ANALYTICS_SALES_CHANNEL_RATIO: '销售途径比例',
    miniprogram: '小程序',
    wechat_group: '微信群',
    daily: '日常订单',
    ANALYTICS_ZIKOLAND_SERVICE_FEE_COMMUNITY: '组别服务费',
    ANALYTICS_TOTAL_ZIKOLAND_SERVICE_FEE: '总服务费',
    ANALYTICS_GRAND_TOTAL_SALES: '销售总计',
    ANALYTICS_TOTAL_SUPPLIER_SHARE: '供应商总货款',
    ANALYTICS_EXPORT_CSV: '文件导出',
    ANALYTICS_TOTAL_OFFERS: '总服务订单数量',
    ANALYTICS_TOTAL_ORDERS: '总订单数量',
    ANALYTICS_CUSTOMER_SATISFACTION_RATE: '客户满意度',
    ANALYTICS_TOTAL_CLAIMS: '总索赔、投诉',
    ANALYTICS_TOTAL_VOUCHERS: '总抵用券',
    ANALYTICS_TOTAL_REFUND: '总退款',
    ANALYTICS_STOCK: '库存',
    ANALYTICS_TOTAL_STOCK: '总库存',
    ANALYTICS_STOCK_VALUE: '库存价值',
    ANALYTICS_GROSS_MARGIN: '毛利',
    ANALYTICS_LAST_TIME_RMB: '上次 ¥',
    ANALYTICS_LAST_TIME: '上次 ',

    OFFERS_OFFER_ID: '服务订单编码',
    OFFERS_OFFER_DATE: '服务订单日期',
    OFFERS_LIVE: '进行中',
    OFFERS_ALL: '全部',
    OFFERS_COMING: '即将开始',
    OFFERS_OVER: '结束 ',
    OFFERS_OFFER_NAME: '服务订单名称',
    OFFERS_OFFER_NAME_INTERNAL: '服务订单内部用名',
    OFFERS_NB_OF_ORDERS: '服务订单编号',
    OFFERS_SALES_TARGET: '销售目标',
    OFFERS_PENDING_ORDERS: '待确认订单',
    OFFERS_TOTAL_PAID_WECHAT: '微信支付总额',
    OFFERS_TOTAL_PAID_ALIPAY: '支付宝支付总额',
    OFFERS_TARGET_INCOME_RATE: '目标收入率',
    OFFERS_ACTUAL_INCOME: '实际收入',
    OFFERS_TIME_LEFT: '剩余时间',
    OFFERS_STATUS: '状态',
    OFFERS_EDIT_OFFER: '编辑服务订单',
    OFFERS_DELIVERY: '配送',
    OFFERS_UNAVAILABLE_PRODUCT: '您的订单包含售罄产品，请选择可用产品',
    OFFERS_GOT_IT: '明白了',

    OFFER_LIVE: '进行中的服务订单',
    OFFER_LIVE_TARGET_RATE: '完成率',
    OFFER_LIVE_TOTAL_SALES_DELIVERY: '总销售（含运费）',
    OFFER_LIVE_WECHAT_TOTAL: '微信支付总额',
    OFFER_LIVE_ALIPAY_TOTAL: '支付宝支付总额',
    OFFER_LIVE_AVG_SPENDING: '平均消费',
    OFFER_LIVE_ITEMS: '产品',
    OFFER_LIVE_SELECTION: '精选',
    OFFER_LIVE_SINGLE_ITEM: '单个产品',
    lottery: '抽奖 ',
    OFFER_LIVE_WECHAT_GROUP: '微信群 ',
    OFFER_LIVE_N: 'N',
    OFFER_LIVE_ORDER_ID: '订单编码',
    OFFER_LIVE_GRP: '群',
    OFFER_LIVE_ABC: 'A,B,C..',
    OFFER_LIVE_PHONE: '手机',
    OFFER_LIVE_DELIVERY_ADDRESS: '地址',
    OFFER_LIVE_AREA: '区域',
    OFFER_LIVE_COMMENT: '要求',
    OFFER_LIVE_DELIVERY_DATE: '配送日期',
    OFFER_LIVE_DELIVERY_FEE: '运费',
    OFFER_LIVE_FAPIAO: '发票',
    OFFER_LIVE_VOUCHER: '抵用券',
    OFFER_LIVE_TICKET: '票券(TK)',
    OFFER_LIVE_TOTAL: '总计',
    OFFER_LIVE_PAYMENTS_STATUS: '付款状态',
    OFFER_LIVE_QR: '付款码',
    OFFER_LIVE_CANCEL_ORDER: '取消订单',
    OFFER_LIVE_REALLY_WANT_CANCEL_ORDER: '确定要取消订单吗？',
    OFFER_LIVE_YES_NO: '是/否',
    OFFER_LIVE_YES: '是',
    OFFER_LIVE_NO: '否',
    OFFER_LIVE_CHAT: '聊天中',
    OFFER_LIVE_ITEM_SELECTION: '产品精选',
    OFFER_LIVE_ADD_ITEM: '添加产品',
    OFFER_LIVE_NAME: '名称',
    OFFER_LIVE_QT_AV: '可用数量',
    OFFER_LIVE_SOLD: '卖出数量',
    OFFER_LIVE_PRICE_CNY: '单价',

    OFFER_ANALYTICS_TOTAL_SALES: '总销售额',
    OFFER_ANALYTICS_AVERAGE_SPENDING: '平均消费',
    OFFER_ANALYTICS_SALES_PER_GROUP: '每组别销售',
    OFFER_ANALYTICS_VOLUME: '量',
    OFFER_ANALYTICS_VALUE: '价值',
    OFFER_ANALYTICS_PRODUCTS_SALES_CHANNEL_RATIO: '产品销售途径比例',
    OFFER_ANALYTICS_QTY: '数量',
    OFFER_ANALYTICS_CNY: '人民币',
    OFFER_ANALYTICS_PRODUCTS_VOLUME: '产品数量/价值',
    OFFER_ANALYTICS_PRODUCTS_REVENUE: '产品收益',
    OFFER_ANALYTICS_POTENTIAL: '潜在',
    OFFER_ANALYTICS_ACTUAL: '实际 ',
    OFFER_ANALYTICS_GROSS_MARGIN: '毛利',
    OFFER_ANALYTICS_REORDER_RATE: '复购率',
    OFFER_ANALYTICS_NEW_CUSTOMERS: '新客户',
    OFFER_ANALYTICS_COMPARISON: '比较对照',
    OFFER_ANALYTICS_SALES_PER_CITY: '按照城市看销售',
    OFFER_ANALYTICS_ITEM: '产品',
    OFFER_ANALYTICS_DESC_GROUPS: '群类型',
    OFFER_ANALYTICS_DESC_MP: '小程序类型',

    LOTTERY_PRIZE_DESC: '奖品描述',
    LOTTERY_WINNER: '获奖者',

    DELIVERY_DELIVERY_TRACKING: '发货跟踪',
    on_the_way: '在运输中',
    prepared: '准备运输',
    delivered: '已签收',
    delayed: '延迟',
    DELIVERY_PROCESS: '进展',
    customer: '客户',
    DELIVERY_TRACKING_NUMBER: '跟踪号',
    DELIVERY_EXPORT_ORDER_ID: '导出订单编码',

    CREATE_OFFER_CHANNEL: '渠道',
    CREATE_OFFER_PUBLIC_NAME_ENGLISH: '名称（英文）',
    CREATE_OFFER_PUBLIC_NAME_CHINESE: '名称（中文）',
    CREATE_OFFER_GHOST_ID: '编码',
    CREATE_OFFER_BANNER_ENGLISH: '广告（英文）',
    CREATE_OFFER_BANNER_CHINESE: '广告（中文）',
    CREATE_OFFER_CARD_OFFER_IMG_EN: '传单订单图片(英文)',
    CREATE_OFFER_CARD_OFFER_IMG_CN: '传单订单图片(中文)',
    CREATE_OFFER_PAGE_IMG_EN: '订单页面图片(英文)',
    CREATE_OFFER_PAGE_IMG_CN: '订单页面图片(中文)',
    CREATE_OFFER_ADD_PICTURES: '增加图片',
    CREATE_OFFER_UPLOAD: '上传',
    CREATE_OFFER_DELETE_OFFER: '删除订单',
    CREATE_OFFER_DUPLICATE_OFFER: '复制订单',
    CREATE_OFFER_SHORTCUT: '快捷',
    CREATE_OFFER_SHORTCUT_TEXT: '快捷文本',
    CREATE_OFFER_CLIPBOARD: '剪贴板',
    CREATE_OFFER_ADD_SELECTION: '添加选择',
    CREATE_OFFER_DELETE_SELECTION: '删除所选项',
    CREATE_OFFER_THUMBNAIL_PAYMENT: '缩略图(支付)',
    CREATE_OFFER_THUMBNAIL_EN: '缩略图',
    CREATE_OFFER_THUMBNAIL_CN: '缩略图',
    CREATE_OFFER_PACK_PICTURES: '套餐图片',
    CREATE_OFFER_ENGLISH_NAME: '英文名称',
    CREATE_OFFER_CHINESE_NAME: '中文名称',
    CREATE_OFFER_STOCK_AVAILABLE: '可用库存',
    CREATE_OFFER_ACTUAL_PRICE: '实际价格',
    CREATE_OFFER_CROSSED_ORIGINAL_PRICE: '原价划线',
    CREATE_OFFER_PRICE: '价格',
    CREATE_OFFER_TARGET_SALES: '销售目标',
    CREATE_OFFER_SELECT_PRODUCT: '选择产品',
    CREATE_OFFER_QUANTITY: '数量',
    CREATE_OFFER_WEIGHT_PER_UNIT: '重量/个',
    CREATE_OFFER_UNIT: '单个',
    CREATE_OFFER_GRAM: '克',
    CREATE_OFFER_ML: '毫升',
    CREATE_OFFER_PIECES: '块/片/个',
    CREATE_OFFER_WEIGHT: '重量 (g)',
    CREATE_OFFER_ADD_PRODUCT: '增加产品 ',
    CREATE_OFFER_ADD_SINGLE_ITEM: '添加一项',
    CREATE_OFFER_DELETE_SINGLE_ITEM: '删除该项',
    CREATE_OFFER_ADD_ON: '附加',
    CREATE_OFFER_ZIKO_SPECIALS: '特别供应项',
    CREATE_OFFER_ADD_SPECIAL: '增加特别项',
    CREATE_OFFER_PRICE_DROP: '减价',
    CREATE_OFFER_LOWEST_PRICE_LIMIT: '最低价格',
    CREATE_OFFER_UNIT_PRICE: '单价',
    CREATE_OFFER_FROM: '从(Qty)',
    CREATE_OFFER_TO: '到(Qty)',
    CREATE_OFFER_BOURSE_RULE: '交易规则',
    CREATE_OFFER_OFFER_DETAILS: '订单详情',
    CREATE_OFFER_DURATION: '持续时间',
    CREATE_OFFER_STARTING_DATE: '开始日期',
    CREATE_OFFER_STARTING_TIME: '开始时间',
    CREATE_OFFER_ENDING_DATE: '结束日期',
    CREATE_OFFER_ENDING_TIME: '结束时间',
    CREATE_OFFER_OFFERY_TYPE: '服务订单类型',
    CREATE_OFFER_PRICE_RULE: '价格条款',
    CREATE_AN_OFFER_REGULAR: '定期',
    CREATE_OFFER_FREE_FALL: '自由降价',
    CREATE_OFFER_MULTIPLE_ITEMS: '多个物品',
    CREATE_OFFER_BOURSE: '交易 ',
    CREATE_OFFER_CLEARANCE: '清库存',
    CREATE_OFFER_CAVE: '酒窖',
    CREATE_OFFER_OFFER_TARGETS: '服务订单目标',
    CREATE_OFFER_COMISSION_RATE: '服务费比例',
    CREATE_OFFER_MINIMUM_ORDERS: '最小订单数量',
    CREATE_OFFER_CART_AMOUNT: '购物车总数',
    CREATE_OFFER_MINIMUM_CART_ITEMS: '购物车最少商品数量',
    CREATE_OFFER_AREA_NAME: '区域名称',
    CREATE_OFFER_RULE: '规则',
    up_to: '取决于（产品数量）',
    free_after_quantity: '之后免费（产品数量）',
    free_after_value: '之后免费（订单价值）',
    CREATE_OFFER_ITEMS_QTY: '产品数量',
    CREATE_OFFER_ORDER_VALUE: '订单价值',
    CREATE_OFFER_ADD_RULE: '增加规则',
    CREATE_OFFER_DELIVERY_TYPE: '运输方式',
    CREATE_OFFER_DELETE_RULE: '删除规则',
    flat: '平台、平坦',
    CREATE_OFFER_ADD_AN_AREA: '增加区域',
    CREATE_OFFER_SELECT_MULTIPLE_DATES: '选择多个日期',
    CREATE_OFFER_MANUAL: '手工',
    CREATE_OFFER_SHUNFENG: '顺丰',
    CREATE_OFFER_BUY_ITEMS: '购买(套餐)',
    CREATE_OFFER_NONE: '无',
    CREATE_OFFER_PER_ITEMS_SOLD: '每售出商品数量',
    CREATE_OFFER_CARD_BANNER_ENGLISH: '服务卡片横幅图像 (英文) (345px * 144px)',
    CREATE_OFFER_CARD_BANNER_CHINESE: '服务卡片横幅图像 (中文) (345px * 144px)',
    CREATE_OFFER_OFFER_PAGE_IMAGES: '服务页面图像 (375px * 256px)',
    CREATE_OFFER_OFFER_PAGE_IMAGES_ENGLISH:
        '服务页面图像 (英文) (375px * 256px)',
    CREATE_OFFER_OFFER_PAGE_IMAGES_CHINESE:
        '服务页面图像 (中文) (375px * 256px)',
    CREATE_OFFER_SHOW_DETAIL: '在微信小程序展示详情',
    CREATE_OFFER_SHOW: '展示',
    CREATE_OFFER_HIDE: '隐藏',
    Home: '家',
    Office: '办公室',
    Other: '其他',
    Others: '其他',
    Free: '免费',
    Paid: '付费',
    Pack: '套餐',
    others: '其他',
    fapiao: '发票',
    cancel_order: '取消订单',
    free_order: '免费订单',
    office: '办公室',
    home: '家',
    temporary: '临时',
    ziko_special: '特别供应项',

    ZIKO_SPECIALS_SPECIAL: '特供"X"',
    ZIKO_SPECIALS_FIRST_ORDER: '第一个订单',
    ZIKO_SPECIALS_NUMBER_OF_ORDERS_REACH: '订单数量达到',
    ZIKO_SPECIALS_OFER_FOR_AMOUNT_GET: '订单（金额）得到',
    ZIKO_SPECIALS_X_TOTAL_SOLD_ITEMS: '"X"总销售项目',
    ZIKO_SPECIALS_ITEM_X_IN_CART: '购物车中的"X"',
    ZIKO_SPECIALS_AMOUNT_QUANTITY: '总金额/数量',
    ZIKO_SPECIALS_HOW_MUCH: '总金额 ',
    ZIKO_SPECIALS_GET: '获得',
    ZIKO_SPECIALS_PRICE_REDUCTION: '降价',
    ZIKO_SPECIALS_DISCOUNT_RATE: '折扣率',
    ZIKO_SPECIALS_DELETE_SPECIAL: '删除特供',
    ZIKO_SPECIALS_PACK: '套餐',
    ZIKO_SPECIALS_ADD_ONS: '附加',
    ZIKO_SPECIALS_CUSTOM: '习惯（经常性的顾客）',
    ZIKO_SPECIALS_DISCOUNT: '折扣',
    ZIKO_SPECIALS_FREE_DELIVERY: '免运费',
    ZIKO_SPECIALS_TICKETS: '票券',
    ZIKO_SPECIALS_1_TICKET_EVERY: '1张票/每单',
    ZIKO_SPECIALS_SPEND: '消费',
    ZIKO_SPECIALS_BUY: '购买',
    ZIKO_SPECIALS_X_PACK_BOUGHT: '"X套餐购买',
    ZIKO_SPECIALS_EXTRA_TICKET: '额外票券',
    ZIKO_SPECIALS_BUY_FOR: '为购买',
    ZIKO_SPECIALS_NONE: '没有',
    ZIKO_SPECIALS_DRAW: '抽奖 ',
    ZIKO_SPECIALS_ADD_DRAW: '添加抽奖',
    ZIKO_SPECIALS_DELETE_DRAW: '删除抽奖',
    ZIKO_SPECIALS_UNLOCK_CONDITION: '开放条件',
    ZIKO_SPECIALS_GET_ONE_TICKET_FOR: '获得一个票券',
    ZIKO_SPECIALS_SPENT: '消费（个）',
    ZIKO_SPECIALS_BUY_ITEMS: '购买（套餐）',
    ZIKO_SPECIALS_ORDER: '订单',
    ZIKO_SPECIALS_NUMBER_OF_ITEMS_REACH: '套餐数量达到',
    ZIKO_SPECIALS_NUMBER_OF_ITEMS_SOLD_REACH: '售出套餐数量达到',
    ZIKO_SPECIALS_CUSTOM_ITEM: '自定义项',
    ZIKO_SPECIALS_ILLUSTRATION: '说明',
    ZIKO_SPECIALS_NB_OF_WINNERS: '得奖者编号',
    ZIKO_SPECIALS_ADD_GIFT: '添加奖品',
    ZIKO_SPECIALS_ITEM_NAME_ENGLISH: '项目名称（英文）',
    ZIKO_SPECIALS_EXPIRATION_DATE: '过期日期',
    ZIKO_SPECIALS_CREATION_DATE: '创建日期',

    SERVICES_ORDER_LIST_TRANSACTION_NUMBER: '交易编号',
    pending: '待确认',
    paid: '已付',
    ccl: '取消',
    rfd: '退款',
    refund: '退款',
    SERVICES_ORDER_LIST_DATE: '日期',
    SERVICES_ORDER_LIST_ORDER_DETAILS: '订单详情',
    SERVICES_ORDER_LIST_CREATE_VOUCHER: '新建代金券',
    SERVICES_ORDER_GOT_VOUCHER: '已有代金券!',
    SERVICES_ORDER_TIME: '时间',

    DIRECT_SALES_ALL_CUSTOMER_ORDERS: '所有客户订单',
    DIRECT_SALES_DELIVERY_TRACKING_NUMBER: '发货跟踪单号',
    DIRECT_SALES_DELIVERED: '已发出',
    DIRECT_SALES_DOWNLOAD: '下载',
    DIRECT_SALES_CREATE_DAILY_ORDER: '新建日常订单',
    DIRECT_SALES_DAILY_ORDER_INFORMATION: '日常订单信息',
    DIRECT_SALES_GENERAL_INFORMATION: '一般信息',
    DIRECT_SALES_CHOOSE_SUPPLIER: '选择供应商',
    DIRECT_SALES_CUSTOMER_INFORMATION: '客户信息',
    DIRECT_SALES_PHONE_NUMBER: '电话',
    DIRECT_SALES_ADDRESS_NAME: '地址名称（城市）',
    DIRECT_SALES_OFFICE: '办公室',
    DIRECT_SALES_HOME: '家',
    DIRECT_SALES_ZIPCODE: '邮编',
    DIRECT_SALES_DETAILED_ADDRESS: '详细地址',
    DIRECT_SALES_FAPIAO_INFORMATION: '发票信息',
    DIRECT_SALES_PRODUCTS: '产品',
    DIRECT_SALES_CHOOSE_PRODUCT: '选择产品',
    DIRECT_SALES_COST_PRICE: '成本价 (含税)',
    DIRECT_SALES_RETAIL_PRICE: '零售单价（含税）',
    DIRECT_SALES_WEIGHT_PER_UNIT: '重量/个',
    DIRECT_SALES_UNIT: '单个',
    DIRECT_SALES_GRAM: '克',
    DIRECT_SALES_ML: '毫升',
    DIRECT_SALES_PIECES: '块/片/个',
    DIRECT_SALES_DELETE_PRODUCT: '删除产品',
    DIRECT_SALES_DOWNLOAD_PDF_EN: '下载PDF（英文）',
    DIRECT_SALES_DELIVERY: '发货、运输',
    DIRECT_SALES_LOADING_PDF: '加载PDF',

    CUSTOMERS_LAST_ORDER: '最后一次订单',
    CUSTOMERS_NEVER_ORDERED: '未下过订单',
    CUSTOMERS_DAYS_AGO: '天以前',
    CUSTOMERS_WEEKS_AGO: '周以前',
    CUSTOMERS_MONTHS_AGO: '月以前',
    CUSTOMERS_YEARS_AGO: '年以前',
    CUSTOMERS_CREATE_CUSTOMER: '新建客户',
    CUSTOMERS_INFORMATION: '信息资料',
    CUSTOMERS_PERSONAL_INFORMATION: '个人信息',
    CUSTOMERS_CUSTOMER_NAME: '客户名字',
    CUSTOMERS_LEVEL: '等级',
    tourist: '游客',
    curious_wanderer: '好奇的流浪者',
    humble_resident: '谦逊的居民',
    chummy_villager: '可爱的村民',
    lord_furnace: '主熔炉',
    secret_circle: 'Ziko 秘密圈子',
    CUSTOMERS_ADDRESS: '地址',
    CUSTOMERS_ADDRESS_TYPE: '地址类型',
    CUSTOMERS_ADDRESS_NAME: '地址名称',
    CUSTOMERS_ADD_ADDRESS: '添加地址',
    CUSTOMERS_PET_INFORMATION: '宠物信息',
    CUSTOMERS_PET: '宠物',
    CUSTOMERS_PET_1: '宠物 1',
    CUSTOMERS_PET_TYPE: '宠物类型',
    CUSTOMERS_CAT: '猫',
    CUSTOMERS_DOG: '狗',
    CUSTOMERS_PET_SIZE: '宠物尺寸',
    CUSTOMERS_SMALL: '小的',
    CUSTOMERS_BIG: '大的',
    CUSTOMERS_MEDIUM: '中等的',
    CUSTOMERS_ADD_PET: '添加宠物',
    CUSTOMERS_LOTTERY_PRIZE: '抽奖奖品',
    CUSTOMERS_CCL: '？',
    CUSTOMERS_RFD: '？',
    CUSTOMERS_SO: '销售订单',
    CUSTOMERS_OFFER: '服务订单',
    CUSTOMERS_ORDER_DATE: '订单日期',
    CUSTOMERS_UNCLAIMED_VOUCHERS: '未被领取的代金券',
    CUSTOMERS_VOUCHER_ID: '代金券编号',
    CUSTOMERS_VOUCHER_REASON: '代金券领取原因',
    CUSTOMERS_SPECIFIC_OFFER_VOUCHER_AVAILABLE: '特别提供的代金券待使用',
    CUSTOMERS_CANCELLED_ORDER: '取消的订单',
    CUSTOMERS_VALIDATED: '已生效',
    CUSTOMERS_USED_VOUCHERS: '已用代金券',
    used: '已用',
    CUSTOMERS_ORDER_NUMBER: '订单号',
    CUSTOMERS_USE_DATE: '代金券使用日期',
    voucher_information: '代金券信息',
    CUSTOMERS_VOUCHER_AMOUNT: '代金券总金额',
    amount: '金额',
    claim: '声明',
    gift: '奖品',
    gift_giving: '奖品',
    other: '其他',
    supplier: '供应商',
    delivery_company: '快递公司',
    ziko: 'Zikoland',
    claim_date: '投诉日期',
    order_date: '订单日期',
    resend: '重发',
    CUSTOMERS_VOUCHERS: '代金券',
    CUSTOMERS_WECHAT_ID: '微信号',
    CUSTOMERS_CONTACT_PERSONS: '联系人(出于更新原因，此功能仅用于了解客户信息)',
    CUSTOMERS_ADD_PHONE_NUMBER: '添加电话',
    CUSTOMERS_CONTACT_NAME: '联系人姓名',
    CUSTOMERS_TOTAL_SALES_BY_COMMUNITY: '按照群体总销售额',
    CUSTOMERS_TOTAL_SPENT: '总花费(¥)',
    CUSTOMERS_REORDER_RATE: '复购比例',
    CUSTOMERS_FAN_OF: '喜好',
    CUSTOMERS_FAPIAO_UPLOAD: '发票上传',

    SUPPLIERS_SUPPLIER_NAME: '供应商名称',
    SUPPLIERS_TYPE: '类型',
    direct_sales: '直销',
    services: '服务',
    available: '可用的',
    discontinued: '已停产',
    SUPPLIERS_CREATE_SUPPLIER: '创建供应商',
    SUPPLIERS_SUPPLIER_INFORMATION: '供应商信息',
    SUPPLIERS_CONTACT: '联系方式',
    SUPPLIERS_ADD_CONTACT: '添加联系人',
    SUPPLIERS_JOB_TITLE: '职位',
    SUPPLIERS_BANK_INFORMATION: '银行信息',
    SUPPLIERS_SAVE_SUPPLIER: '保存供应商',
    SUPPLIERS_FINANCIAL: '财务',
    SUPPLIERS_PAYMENT_TERMS: '付款条件',
    SUPPLIERS_CASH_BEFORE_DELIVERY: '先付款后交货',
    SUPPLIERS_CASH_ON_DELIVERY: '货到付款',
    SUPPLIERS_END_OF_THE_MONTH: '月底',
    SUPPLIERS_30_DAYS: '30天',
    SUPPLIERS_35_DAYS: '35天',
    SUPPLIERS_60_DAYS: '60天',
    SUPPLIERS_VAT: '增值税',
    SUPPLIERS_FINANCE_INFORMATION: '财务信息',
    SUPPLIERS_COMMISSIONS_RATE: '佣金率',

    PRODUCT_LIST: '产品列表',
    PRODUCT_LIST_PRODUCT_ID: '产品编号',
    PRODUCT_LIST_PRODUCT_TYPE: '产品类别',
    PRODUCT_LIST_FOOD_STORAGE: '食物贮藏',
    PRODUCT_LIST_RETAIL_PRICE: '零售价格',
    PRODUCT_LIST_CREATE_PRODUCT: '创建产品',
    PRODUCT_LIST_COST_PRICE: '成本价',
    PRODUCT_LIST_PRODUCT_DETAILS: '产品详情',
    PRODUCT_LIST_CHOOSE_TYPE: '选择类型',
    none: '无',
    fresh: '新鲜的',
    dry: '干燥的',
    frozen: '冷冻',
    Food: '食品',
    DRY_FOOD: '干燥的 (食品)',
    SEAFOOD_AND_FISH_FOOD: '海鲜和鱼 (食品)',
    MEAT_FOOD: '肉 (食品)',
    DAIRY_FOOD: '乳制品 (食品)',
    BAKERY_AND_PASTRY_FOOD: '面包和糕点 (食品)',
    VEGETABLES_AND_FRUITS_FOOD: '蔬菜和水果 (食品)',
    SNACK_AND_DIP_FOOD: '小吃和蘸酱 (食品)',
    SWEETS_FOOD: '糖果 (食品)',
    COLD_CUT_FOOD: '冷切 (食品)',
    SEASONINGS_FOOD: '调料 (食品)',
    POULTRY_FOOD: '家禽 (食品)',
    OTHERS_FOOD: '其他 (食品)',
    Beverage: '饮料',
    WINE_BEVERAGE: '葡萄酒 (饮料)',
    LIQUOR_SPIRITS_BEVERAGE: '白酒和烈酒 (饮料)',
    BEER_CIDER_BEVERAGE: '啤酒和苹果酒 (饮料)',
    SOFT_ENERGY_DRINKS_BEVERAGE: '软饮料和能量饮料 (饮料)',
    SIRUP_BEVERAGE: '糖浆 (饮料)',
    OTHERS_BEVERAGE: '其他 (饮料)',
    FRESH_FOOD_PET: '生鲜 (宠物)',
    DRY_FOOD_PET: '干粮 (宠物)',
    WET_FOOD_PET: '湿粮 (宠物)',
    TREATS_PET: '款待 (宠物)',
    SKIN_CARE_PET: '皮肤护理 (宠物)',
    ACCESSORIES_PET: '配件 (宠物)',
    LITTER_PET: '垃圾 (宠物)',
    CLOTHES_PET: '衣服 (宠物)',
    Diet: '日常饮食',
    vegan: '素食',
    pa: 'PA',
    'gluten-free': '无麸质',
    'no-hormones': '无激素',
    PRODUCT_LIST_TOTAL_WEIGHT: '总重量',
    PRODUCT_LIST_SALES_PER_CITY: '销售按照城市统计',
    PRODUCT_LIST_UNIT_PRICE: '单价(incl VAT)',
    PRODUCT_LIST_MEDIA: '媒体',
    PRODUCT_LIST_THUMBNAIL: '缩略图',
    PRODUCT_LIST_THUMBNAIL_EN: '缩略图',
    PRODUCT_LIST_THUMBNAIL_CN: '缩略图',
    PRODUCT_LIST_TECHNICAL_SHEET: '产品简介表',
    PRODUCT_LIST_EN_TECHNICAL_SHEET: '英文产品简介表',
    PRODUCT_LIST_CN_TECHNICAL_SHEET: '中文产品简介表',
    PRODUCT_LIST_PRODUCT_PICTURES: '产品图片',

    INVENTORY_TOTAL_STOCK_VOLUME: '总库存量（单位）',
    INVENTORY_TOTAL_STOCK_VALUE: '股票总价值',
    INVENTORY_PRODUCT_NAME: '产品名称',
    INVENTORY_PRODUCT: '产品',
    INVENTORY_ACTUAL_STOCK: '实际库存',
    purchased: '已购（尚未收到）',
    INVENTORY_PAID_STOCK: '已付库存',
    INVENTORY_FREE_STOCK: '未付库存',
    INVENTORY_TOTAL: '总量',
    INVENTORY_MERGE: '合并',
    INVENTORY_FORECAST_STOCK: '预测库存',
    INVENTORY_FORECAST_STOCK_VALUE: '预测股票价值',
    INVENTORY_VIEW_DETAILS: '查看详情',
    INVENTORY_STOCK_MOVEMENTS: '库存走势',
    INVENTORY_CUSTOMER_ORDER_ID: '客户订单编号',
    customer_order: '客户订单',
    INVENTORY_PO_NUMBER: '订单号',
    INVENTORY_MOVEMENT_TYPE: '移动类型',
    manual_add: '手动添加',
    manual_remove: '手动移除',
    in: '入库',
    out: '出库',
    on_delivery: '投递中',
    INVENTORY_ADD_STOCK: '添加库存',
    INVENTORY_SELECT_STOCK_FROM: '选择库存',
    INVENTORY_COMMENT: '备注',
    INVENTORY_PLEASE_ADD_A_COMMENT: '请添加备注',
    INVENTORY_REMOVE_STOCK: '移除库存',
    INVENTORY_OWNED_STOCK: '自由库存',
    INVENTORY_QUANTITY_TO_ADD: '待添加数量',
    INVENTORY_QUANTITY_TO_REMOVE: '待移除数量',
    merged: '合并的库存',
    AUTOMATIC_MERGE_OF_THE_FREE_STOCK: '剩余库存自动合并',
    field_required: '此项为必填项',

    PURCHASE_ORDERS_EXPORT_PDF: '导出PDF',
    PURCHASE_ORDERS_EDIT_PURCHASE_ORDER: '编辑采购订单',

    CUSTOMER_SERVICE_EXPORT_CLAIMS: '导出投诉理赔',
    CUSTOMER_SERVICE_ORDER_DATE: '订单日期',
    CUSTOMER_SERVICE_CLAIM_DATE: '投诉日期',
    CUSTOMER_SERVICE_RESEND: '重发',
    CUSTOMER_SERVICE_EDIT_CLAIM: '编辑投诉',
    CUSTOMER_SERVICE_SELECT_TYPE: '选择种类',
    CUSTOMER_SERVICE_SOLVED_RATIO: '解决比率',
    CUSTOMER_SERVICE_ONGOING_RATIO: '进行总比率',
    ongoing: '进行',
    new: '新的',
    CUSTOMER_SERVICE_RESEND_RATIO: '重发比率',
    CUSTOMER_SERVICE_TOTAL_VOUCHERS: '代金券总数',
    CUSTOMER_SERVICE_RESPONSIBILITY: '责任',
    CUSTOMER_SERVICE_DELIVERY_SERVICE: '送货服务',
    CUSTOMER_SERVICE_ISSUE: '问题',
    wrong_address: '错误地址',
    packaging: '包装',
    defrost: '解冻/融化',
    product_default: '产品损坏',
    product_damaged: '产品损坏',
    item_missing: '物品丢失',
    delay: '延迟',
    customer_mistake: '客户失误',
    wrong_time: '错误的交货时间',
    wrong_item: '错误的项目',
    CUSTOMER_SERVICE_ACTION: '行动',
    CUSTOMER_SERVICE_GIFT_NEXT_ORDER: '下单送礼',
    CUSTOMER_SERVICE_ORDER_FOR_FREE: '免费订购',
    CUSTOMER_SERVICE_FREE_ORDER: '免费订购',
    standby: '支持',
    solved: '已解决',
    failed: '已失败',
    CUSTOMER_SERVICE_ACTION_ETA: '行动预计到达时间',
    CUSTOMER_SERVICE_CUSTOMER_LIVE_CHAT: '客户实时聊天',
    CUSTOMER_SERVICE_CREATE_CLAIM: '创建声明',
    CUSTOMER_SERVICE_CLAIM_DETAIL: '索赔详情',
    CUSTOMER_SERVICE_ISSUE_DETAIL: '问题详情',
    CUSTOMER_SERVICE_STEP: '步骤',
    CUSTOMER_SERVICE_DECLINE: '拒绝',
    CUSTOMER_SERVICE_VALIDATE: '证实',
    declined: '已拒绝',
    validated: '已证实',
    delivery: '配送',
    refund: '退款',
    voucher: '抵用券',

    RECIPES_RECIPE_NAME: '食谱名称',
    RECIPES_PUBLISHED: '已发布',
    RECIPES_NOT_PUBLISHED: '未发布',
    RECIPES_CREATE_RECIPE: '创建食谱',
    RECIPES_SERVINGS: '份量',
    RECIPES_PREPARATION_TIME: '准备时间（分钟）',
    RECIPES_BAKING_TIME: '烘烤时间（分钟）',
    RECIPES_CALORIES: '卡路里',
    RECIPES_ADD_MORE_PICTURES_OR_VIDEOS: '添加更多图片或视频',
    RECIPES_TAGS: '标签',
    RECIPES_MEAL_DIET: '餐食和饮食',
    'Apetizers and Snacks': '开胃菜和小食',
    Lunch: '午餐',
    Dinner: '晚餐',
    Sides: '配菜',
    Breakfast: '早餐',
    Desert: '甜点',
    Brunch: '早午餐',

    Pastry: '糕点',
    Bakery: '面包店',
    'Food allergy': '食物过敏',
    'Wheat Free': '不含小麦',
    'Dairy Free': '不含乳制品',
    'Peanut Free': '不含花生',
    'Soy Free': '不含豆类',
    'Sesame Free': '不含芝麻',
    'Seafood Free': '不含海鲜',
    'Gluten Free': '无麸质',
    CUISINE: '美食',
    French: '法餐',
    Chinese: '中餐',
    Japanese: '日料',
    Thai: '泰式',
    American: '美式',
    Fusion: '融合',
    Italian: '意式',
    TIME_LEVEL: '时间和难易程度',
    TIME_LEVEL_EASY: '入门级',
    TIME_LEVEL_INTERMEDIATE: '中级',
    TIME_LEVEL_ADVANCED: '高级',
    TIME_LEVEL_QUICKIE: '快速（30分钟以内）',
    COMMUNITY: '社区',
    COMMUNITY_BY_ZIKO: 'Ziko 掌厨',
    COMMUNITY_COMMUNITY: '社区',
    COMMUNITY_ZIKO_GUESTS: 'Ziko 的客人',
    COMMUNITY_CHEFS: '大厨',
    INGREDIENTS: '原料',
    Barramundi: '尖吻鲈',
    Beef: '牛肉',
    Capon: '公鸡',
    Cheese: '奶酪',
    Chicken: '鸡',
    Chocolate: '巧克力',
    Cod: '鳕鱼',
    Coffee: '咖啡',
    'Cold Cut': '冷切肉',
    Duck: '鸭子',
    'Duck Liver': '鸭肝',
    'Goose Liver': '鹅肝',
    Hadock: '黑线鳕',
    Halibut: '大比目鱼',
    Ham: '火腿',
    Iberico: '伊比利亚猪肉',
    Lamb: '羊肉',
    Mackerel: '青花鱼',
    Meat: '肉',
    Mussels: '淡菜',
    Nuts: '坚果',
    Octopus: '章鱼',
    Oysters: '生蚝',
    Pasta: '意大利面',
    Pigeon: '鸽子',
    Pork: '猪肉',
    Prawns: '对虾',
    'Red fish': '红鱼',
    Salmon: '三文鱼',
    Sausages: '香肠',
    Scallop: '带子',
    Scampi: '炸大虾',
    Seafood: '海鲜',
    Shrimps: '虾',
    Snowcrab: '雪蟹',
    Squid: '鱿鱼',
    Toothfish: '齿鱼',
    Truffles: '松露',
    Tuna: '金枪鱼',
    Turkey: '火鸡',
    Veal: '小牛肉',
    'Veal Liver': '小牛肝',
    Vegetables: '蔬菜',
    Venison: '鹿肉',
    Wagyu: '和牛',
    RECIPES_MEDIA: '媒体',
    RECIPES_MAIN_PICTURE_EN: '主图',
    RECIPES_MAIN_PICTURE_CN: '主图',
    RECIPES_OTHER_MEDIA: '其他媒体',
    RECIPES_EXTENSION_ALLOWED:
        '接受格式(jpg, jpeg, png, gif, mp4), 推荐尺寸(375px * 256px)',
    DIET: '日常饮食',
    Vegetarian: '素食',
    Vegan: '纯素',
    'Gluten-free': '无麸质',
    Healthy: '健康的',
    'Recipe details': '食谱详情',
    'Link product': '链接产品',
    'Delete link': '删除链接',
    'Add more': '添加更多',
    'Pin on the top': '固定在顶部',
    Publication: '出版物',

    LOTTERY_MANAGEMENT_DRAW_NUMBER: '开奖号码',
    LOTTERY_MANAGEMENT_NUMBER_WINNERS: '获奖人数',
    LOTTERY_MANAGEMENT_PRIZE: '奖品',
    LOTTERY_MANAGEMENT_WINNER_NAME: '获奖者姓名',
    LOTTERY_MANAGEMENT_WIN_TIME: '获奖时间',

    merge: '合并的库存',
    purchase_order: '采购订单',
    invoice: '发票',
    FINANCE_GENERAL_PAYMENT_COLLECTED_SUPPLIER: '为供应商收取的总付款',
    FINANCE_GENERAL_PURCHASE_INCL_VAT: '总购买量（含增值税）',
    unpaid: '尚未付款',
    FINANCE_GENERAL_SEE_DETAIL: '查看详情',

    DIRECT_SALES_TOTAL_EXC_VAT: '销售总额（不含增值税）',
    DIRECT_SALES_TOTAL_INCL_VAT: '销售总额（含增值税）',
    DIRECT_SALES_STOCK_UPDATED: '库存已更新',
    DIRECT_SALES_RECEIVED_PRODUCTS: '已收货品',
    DIRECT_SALES_CREATE_PO: '创建采购订单',
    DIRECT_SALES_PAYMENT_DONE: '付款完成',
    DIRECT_SALES_SURE_TO_VALIDATE_PAYMENT: '您确定要付款吗？',
    DIRECT_SALES_STOCK_RECEIVED: '产品已入库',
    DIRECT_SALES_SURE_TO_VALIDATE_RECEPTION_STOCK: '您确定要接受产品入库吗？',
    DIRECT_SALES_CHANGE_PAID_STATUS: '更改付费状态',
    DIRECT_SALES_SURE_TO_VALIDATE_PAYMENT_RECEIVED: '您确认已收到付款吗？',

    PURCHASE_ORDER_DETAIL_PURCHASE_ORDER_DETAIL: '采购订单明细',
    PURCHASE_ORDER_DETAIL_UNIT_PRICE_EXCL_VAT: '单价(不含增值税)',
    PURCHASE_ORDER_DETAIL_UNIT_PRICE_INCL_VAT: '单价(含增值税)',
    PURCHASE_ORDER_DETAIL_SUBTOTAL: '小计',
    PURCHASE_ORDER_DETAIL_CREATE_PURCHASE_ORDER: '创建采购订单',
    PURCHASE_ORDER_DETAIL_PO_INFORMATION: '采购订单信息',
    PURCHASE_ORDER_DETAIL_PURCHASE_DATE: '购买日期',
    PURCHASE_ORDER_DETAIL_SAVE_PO: '保存采购订单',

    SERVICES_INVOICES: '发票',
    SERVICES_TTL_SALES_W_DELIVERY: '总销售额（包含快递费）',
    SERVICES_TTL_SALES_WO_DELIVERY: '总销售额（不含快递费）',
    SERVICES_FEE_PERCENT: '服务费(%)',
    SERVICES_FEE_RMB: '服务费(¥)',
    SERVICES_ZIKOLAND_COMISSION: 'Zikoland 佣金',
    SERVICES_INVOICE_ID: '发票编号',
    SERVICES_SEE_OFFER: '查看报价',
    SERVICES_GENERATE_INVOICE: '生成发票',
    SERVICES_DOWNLOAD_INVOICE: '下载发票',
    SERVICES_BANK_TRANSFER_ID: '银行转账 ID',

    DELIVERY_TEMPLATES_CREATE_AREA: '创建区域',
    DELIVERY_TEMPLATES_TEMPLATE_NAME: '模板 姓名 ',
    DELIVERY_TEMPLATES_TEMPLATES: '模板',
    DELIVERY_TEMPLATES_AREAS: '地区',
    DELIVERY_TEMPLATES_CREATED_DATE: '已建 日期',
    DELIVERY_TEMPLATES_CREATE_TEMPLATE: '新建 模板',
    DELIVERY_TEMPLATES_DELIVERY_TEMPLATE_NAME: '快递 模板 姓名',
    DELIVERY_TEMPLATES_DELETE_AREA: '删除 区域',
    DELIVERY_TEMPLATES_DELIVERY_AREA_NAME: '快递 区域 姓名',
    DELIVERY_TEMPLATES_PARENT: '上一级区域',

    LOGIN: '登录',
    USERNAME: '用户名',
    PASSWORD: '密码',
    SIGN_IN: '登入',
    FORGOT_PASS: '忘记密码',
    WRONG_LOGIN: '登录名或密码错误',
    RESET_PASSWORD: '填写邮箱以重置密码',
    PASSWORD_RESET_SUCCESS: '您的密码已成功重置',
    FILL_PASSWORD: '输入新密码',
    CONFIRM: '确认',
    CANCEL: '取消',
    LOGOUT: '',

    LEAVE_PAGE: '您确定要离开此页面吗？您所做的所有修改都将丢失.',

    SHIPPING_ADDRESS: '收货地址',
    ORDER: '订单',
    ORDER_DATE: '订单日期',
    ORDER_REFERENCE: '订单编号',
    PRODUCT: '产品',
    TOTAL_PRICE: '总价',
    WEBSITE: '网站',
    PACK: '套餐',
    ADD_ON: '附加套餐',
    CUSTOM: '自定义',
    ITEM: '项目',
    Item: '项目',
    TOTAL_WITHOUT_TAXES: '总价（不含税）',
    TAXES: '税',
    DELIVERY: '发货、运输',

    MENU_REFUND: '退款',
    REFUNDS_REFUND_RATE: '退款率',
    REFUNDS_TOTAL_REFUNDS: '总退款数',
    REFUNDS_REFUNDED_RATIO: '退款比例',
    REFUNDS_ONGOING_REFUNDS: '进行中的退款',
    REFUNDS_REFUSED_RATIO: '拒绝比例',
    REFUNDS_ORDER_TOTAL: '订单总额（¥）',
    REFUNDS_REFUND_AMOUNT: '退款金额（¥）',
    REFUNDS_PAYMENT: '付款',
    REFUNDS_PAYMENT_MERCHANT_ID: '付款商户ID',
    REFUNDS_EXPORT_REFUNDS: '导出退款',
    REFUNDS_CREATE_REFUND: '创建退款',
    REFUNDS_SAVE_REFUND: '保存退款',
    REFUNDS_REFUND_DETAIL: '退款详情',
    REFUNDS_MISSING_PAYMENT_INFO: '付款信息缺失。请手动退款此订单',
    REFUNDS_SURE_TO_VALIDATE_REFUND: '您确定要手动退款此订单吗？',
    refused: '拒绝',
    refunded: '已退款',
    wechat: '微信',
    alipay: '支付宝',
    CREATE_NEW_CUSTOMER: '创建新客户？',
    CONFRIM_TO_CREATE_NEW_CUSTOMER: '请确认您要创建一个新的客户档案',
    CREATE_OFFER_CHECK_PRICE: '原始价格不应等于或低于当前价格',
    SUCCESSFULLY_SAVED: '保存成功',
    CREATE_REFUND_NO_MORE_THAN_ACTUAL_AMOUNT: '不得超过实际金额',
    CREATE_REFUND_SELECT_A_VALID_ORDER: '请选择一个有效的订单',
}

export default zh
