import { getCommunities } from '../../network/API'

const Description = {
    title: 'ROLES_DESCRIPTION',
    key: [],
    sections: [
        {
            columns: 6,
            fields: [
                {
                    label: 'ROLES_NAME',
                    isRequired: true,
                    key: ['name'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_ROLE',
                    isRequired: true,
                    key: ['role'],
                    type: 'select',
                    options: [
                        // { label: 'Super Admin', key: 'super-admin' },
                        { label: 'admin', key: 'admin' },
                        {
                            label: 'product-manager',
                            key: 'product-manager',
                        },
                        {
                            label: 'community-manager',
                            key: 'community-manager',
                        },
                        { label: 'customer-hero', key: 'customer-hero' },
                        { label: 'logistic', key: 'logistic' },
                        { label: 'designer', key: 'designer' },
                        { label: 'finance', key: 'finance' },
                    ],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_CITY',
                    key: ['city'],
                    type: 'select',
                    options: [
                        { label: 'All', key: 'All' },
                        { label: 'Shanghai', key: 'Shanghai' },
                        { label: 'Guangzhou', key: 'Guangzhou' },
                    ],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_COMMUNITY',
                    key: ['communities'],
                    type: 'multicheck',
                    requestOption: getCommunities,
                    pos: {
                        col: 4,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'ROLES_EMAIL',
                    isRequired: true,
                    ruleType: 'email',
                    key: ['email'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    type: 'password',
                    placeholder: '**********',
                    label: 'ROLES_PASSWORD',
                    key: ['password'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const DescriptionCreate = {
    title: 'ROLES_DESCRIPTION',
    key: [],
    sections: [
        {
            columns: 6,
            fields: [
                {
                    label: 'ROLES_NAME',
                    isRequired: true,
                    key: ['name'],
                    pos: {
                        col: 1,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_ROLE',
                    isRequired: true,
                    key: ['role'],
                    type: 'select',
                    options: [
                        // { label: 'Super Admin', key: 'super-admin' },
                        { label: 'admin', key: 'admin' },
                        {
                            label: 'product-manager',
                            key: 'product-manager',
                        },
                        {
                            label: 'community-manager',
                            key: 'community-manager',
                        },
                        { label: 'customer-hero', key: 'customer-hero' },
                        { label: 'logistic', key: 'logistic' },
                        { label: 'designer', key: 'designer' },
                        { label: 'finance', key: 'finance' },
                    ],
                    pos: {
                        col: 2,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_CITY',
                    key: ['city'],
                    type: 'select',
                    options: [
                        { label: 'All', key: 'All' },
                        { label: 'Shanghai', key: 'Shanghai' },
                        { label: 'Guangzhou', key: 'Guangzhou' },
                    ],
                    pos: {
                        col: 3,
                        row: 1,
                        size: 1,
                    },
                },
                {
                    label: 'ROLES_COMMUNITY',
                    key: ['communities'],
                    type: 'multicheck',
                    requestOption: getCommunities,
                    pos: {
                        col: 4,
                        row: 1,
                        size: 2,
                    },
                },
                {
                    label: 'ROLES_EMAIL',
                    isRequired: true,
                    ruleType: 'email',
                    key: ['email'],
                    pos: {
                        col: 1,
                        row: 2,
                        size: 1,
                    },
                },
                {
                    type: 'password',
                    placeholder: '**********',
                    isRequired: true,
                    label: 'ROLES_PASSWORD',
                    key: ['password'],
                    pos: {
                        col: 2,
                        row: 2,
                        size: 1,
                    },
                },
            ],
        },
    ],
}

const cleanBeforeSending = (oldData) => {
    if (!oldData.communities) {
        oldData.communities = []
    }

    return oldData
}

const checkValidity = (data) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!re.test(String(data.email).toLowerCase())) {
        return 'Not valid Email'
    }
    return false
}

export { Description, DescriptionCreate, cleanBeforeSending, checkValidity }

// {
//     "role": "customer-hero",
//     "communities": [
//         "60541eab7b87f1126ecf53e1",
//         "60541eb37b87f1126ecf53e2",
//         "60541eb87b87f1126ecf53e3"
//     ],
//     "city": "All",
//     "email": "antoine.nw.geraud@gmail.com",
//     "password": "28YuYaoRoad",
//     "disabled": false,
//     "name": "Antoine Geraud"
// }
