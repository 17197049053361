import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import moment from 'moment'
import { Form } from 'antd'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import { formatDate } from 'src/utils/utils'

import { AppContext } from '../../context/AppContext'

import {
    GeneralInformation,
    ClaimDetail,
    checkValidity,
} from '../../constants/forms/Claims'
import { EditClaims as request, getSingleClaim } from '../../network/API'
import { useTranslation } from 'react-i18next'

const EditClaims = ({ history }) => {
    const [form] = Form.useForm()
    const { token } = useContext(AppContext)
    let { claimId } = useParams()
    const { t } = useTranslation()

    const claimDetails = useQuery(
        [
            'getSingleClaim',
            {
                claimId: claimId,
                token: token.token,
            },
        ],
        getSingleClaim,
        {
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (oldData) => {
        let detail = ''
        for (const pack of oldData.order?.packs) {
            detail += pack.amount
                ? `${pack.amount} Pack ${pack.shortName},`
                : ''
        }

        for (const item of oldData.order?.singleItems) {
            detail += item.amount
                ? `${item.amount} Single Item ${item.shortName},`
                : ''
        }

        return {
            order: oldData.order?.id,
            customer: oldData.customer?.id,
            community: oldData.community?.id,
            offer: oldData.offer?.id,
            supplier: oldData.supplier?.id,
            teamMember: oldData.teamMember,
            orderInfos: {
                orderID: oldData.order?.orderID,
                community: oldData.community?.name,
                customer:
                    oldData.customer?.name ||
                    oldData.order.customerDaily?.customerName,
                supplier: oldData.supplier?.name,
                offer: oldData.offer?.internalName,
                date: moment(oldData.createdAt),
                orderDate: moment(oldData.createdAt),
                totalAmount: oldData.order?.actualAmount,
                city: oldData.order?.customerAddress?.city,
                detailedAddress:
                    oldData.order?.customerAddress?.detailedAddress,
                phone: oldData.order?.customerAddress?.phone,
                deliveryTrackingID: oldData.order?.deliveryTrackingID,
                deliveryDate:
                    oldData.order?.deliveryDate &&
                    moment(oldData.order?.deliveryDate).isValid()
                        ? formatDate(oldData.order?.deliveryDate)
                        : oldData.order?.deliveryDate,
                detail: detail,
            },
            resp: oldData.resp,
            issue: oldData.issue,
            step: oldData.step,
            action: oldData.action,
            actionETA: moment(oldData.actionETA),
            comment: oldData.comment,
            issueDetail: oldData.issueDetail,
            status: oldData.status,
        }
    }

    return (
        <PageWrap
            goBack
            isLoading={claimDetails.isLoading}
            title={`${t('CUSTOMER_SERVICE_EDIT_CLAIM')} ${claimDetails.data?.order?.orderID}`}
        >
            {claimDetails.isError ? (
                <Error retry={() => claimDetails.refetch()} />
            ) : claimDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    id={claimId}
                    form={form}
                    title="edit_claim"
                    request={request}
                    data={formatData(claimDetails.data)}
                    onSuccess={'/claims'}
                    onCancel={'/claims'}
                    invalidate={[
                        'getClaims',
                        'getSingleClaim',
                        'getClaimsStats',
                    ]}
                    checkValidity={checkValidity}
                >
                    <div className="SectionVerticalWrap">
                        <FormBlock
                            form={form}
                            formTemplate={GeneralInformation}
                        />
                        <FormBlock formTemplate={ClaimDetail} />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default EditClaims
