import { INSTANCE, INSTANCELIST } from './API'

export const getUnrefundedOrders = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter, ...rest } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/orders/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify({
                    ...filter,
                    paymentStatus: 'paid',
                }),
                ...tabFilter,
                ...rest,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleUnrefundedOrder = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { orderId, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/orders/${orderId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getRefunds = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/refunds/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CustomerRefunds = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { userId, sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/refunds/details?customer=${userId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateRefund = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/refunds',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleRefund = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { refundId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/refunds/${refundId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getRefundsAnalytics = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: '/refunds/analytics',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const EditRefund = (form, token, refundId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/refunds/${refundId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })
