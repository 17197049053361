import { Form } from 'antd'
import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'

import {
    AreaDescription,
    cleanBeforeSendingArea,
} from '../../constants/forms/Delivery'
import { CreateDeliveryAreas as request } from '../../network/API'

const CreateArea = () => {
    const [form] = Form.useForm()

    return (
        <PageWrap goBack title="DELIVERY_TEMPLATES_CREATE_TEMPLATE">
            <FormWrap
                title="create_template"
                request={request}
                onSuccess={'/delivery'}
                onCancel={'/delivery'}
                cleanBeforeSending={cleanBeforeSendingArea}
                invalidate={['getDeliveryAreas']}
            >
                <div className="SectionVerticalWrap">
                    <FormBlock form={form} formTemplate={AreaDescription} />
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateArea
