import { Controller, useWatch } from 'react-hook-form'
import { Input, Select, Form } from 'antd'
import { useQuery } from 'react-query'
import { getCommunities } from '../../../network/API'
import { useContext } from 'react'
import { AppContext } from '../../../context/AppContext'

const Infos = ({ control, isEdit, errors }) => {
    const { token } = useContext(AppContext)
    const communities = useQuery(
        ['getCommunities', { token: token.token }],
        getCommunities,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    )

    const typeValue = useWatch({
        control,
        name: 'channel',
    })

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(8, 1fr)',
                gridGap: 8,
            }}
        >
            <Form.Item
                label="Offer name (internal)"
                validateStatus={
                    !!errors?.['internalName'] ? 'error' : undefined
                }
                help={errors?.['internalName']?.message}
            >
                <Controller
                    name="internalName"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ value, onChange }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            disabled={isEdit}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                label="Community"
                validateStatus={!!errors?.['community'] ? 'error' : undefined}
                help={errors?.['community']?.message}
            >
                <Controller
                    name="community"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ value, onChange }) => (
                        <Select
                            value={!communities.isLoading && value}
                            onChange={onChange}
                            disabled={isEdit}
                            loading={communities.isLoading}
                        >
                            {communities.data?.list.map((e) => (
                                <Select.Option key={e.id} value={e.id}>
                                    {e.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                />
            </Form.Item>

            <Form.Item label="Offer ID">
                <Controller
                    name="offerID"
                    control={control}
                    render={({ value, onChange }) => (
                        <Input value={value} onChange={onChange} disabled />
                    )}
                />
            </Form.Item>

            <Form.Item
                label="Channel"
                hasFeedback
                validateStatus={!!errors?.['channel'] ? 'error' : undefined}
                help={errors?.['channel']?.message}
            >
                <Controller
                    name="channel"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ value, onChange }) => (
                        <Select
                            value={value}
                            onChange={onChange}
                            disabled={isEdit}
                        >
                            <Select.Option value="all">All</Select.Option>
                            <Select.Option value="wechat_group">
                                Wechat group
                            </Select.Option>
                            <Select.Option value="miniprogram">
                                Miniprogram
                            </Select.Option>
                        </Select>
                    )}
                />
            </Form.Item>

            {typeValue !== 'wechat_group' && (
                <Form.Item label="Show offer details on MP">
                    <Controller
                        name="showDetail"
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <Select value={value} onChange={onChange}>
                                <Select.Option value="show">Show</Select.Option>
                                <Select.Option value="hide">Hide</Select.Option>
                            </Select>
                        )}
                    />
                </Form.Item>
            )}
        </div>
    )
}

export default Infos
