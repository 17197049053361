/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Select, AutoComplete } from 'antd'
import { useQuery } from 'react-query'
import { getCustomers } from '../../network/Customers'

const { Option } = Select

export const AutoCompleteName = ({
    id,
    value,
    community,
    token,
    className,
    availableAreas,
    canAutoUpdate,
    myRef,
    ...restProps
}) => {
    const [enabled, setEnabled] = useState(false)
    const customers = useQuery(
        [
            'getCustomers' + id,
            {
                filter: { name: { $regex: value, $options: 'i' }, status: { $ne: 'discontinued' } },
                token: token,
                range: [0, 4],
                sort: ['name', 'ASC'],
            },
        ],
        getCustomers,
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if (canAutoUpdate) updateUserAutocomplete(data.list)
            },
            enabled: enabled && value?.length > 0,
        }
    )

    const updateUserAutocomplete = (list) => {
        for (const customer of list) {
            if (customer.name === value) {
                restProps.onSelect(
                    JSON.stringify({
                        id: customer.id,
                        name: customer.name,
                        addresses: customer?.addresses,
                        contacts: customer?.contacts,
                        wechatGroup: customer.wechatGroups.find(
                            (e) => e.community === community.id
                        )?.groupNumber,
                        profilePicture: customer?.profilePicture?.uri,
                        fapiaoEmails: customer?.fapiaoEmails,
                    })
                )
            }
        }
    }

    useEffect(() => {
        if (customers?.data?.list && canAutoUpdate)
            updateUserAutocomplete(customers?.data?.list)
    }, [value])

    return (
        <>
            <AutoComplete
                value={value}
                className={className}
                // onFocus={(e) => {
                //     setIsEnabled(true)
                //     restProps.onFocus(e)
                // }}
                // onFocus={() => {
                //     if (restProps.onFocus) restProps.onFocus()
                // }}
                // onBlur={() => {
                //     if (restProps.onBlur) restProps.onBlur()
                // }}
                ref={myRef}
                onDropdownVisibleChange={(e) => setEnabled(e)}
                // onSelect={(e) => onSelect(customers.data.list[e].id)}
                {...restProps}
            >
                {customers?.data?.list?.map((customer) => (
                    <Option
                        key={customer.id}
                        value={JSON.stringify({
                            id: customer.id,
                            name: customer.name,
                            addresses: customer?.addresses,
                            contacts: customer?.contacts,
                            wechatGroup: customer.wechatGroups.find(
                                (e) => e.community === community
                            )?.groupNumber,
                            profilePicture: customer?.profilePicture?.uri,
                            fapiaoEmails: customer?.fapiaoEmails,
                        })}
                    >
                        {customer.name}
                    </Option>
                ))}
            </AutoComplete>
        </>
    )
}

export default AutoCompleteName
