import moment from 'moment'
import i18next from 'i18next'

const formatNb = (nb) => {
    if (!nb && nb !== 0) return ''
    nb = nb.toFixed(2)
    let value = new Intl.NumberFormat('fr-FR', {}).format(nb)
    return value.replaceAll(/(\t)/gm, ' ').replaceAll(' ', ' ') //this a special space
}

const keepTwoDecimals = (nb, min = 2, max = 2) => {
    if (!nb) return ''
    nb = nb.toFixed(2)
    let value = new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    }).format(nb)
    return value.replaceAll(/(\t)/gm, ' ').replaceAll(' ', ' ') //this a special space
}

const noInfinite = (nb) => {
    var roundedString = nb.toFixed(2)
    return Number(roundedString)
}

const formatDate = (date, type = 'short') => {
    if (type === 'long') {
        return moment(date).format('YYYY-MM-DD h:mm')
    } else {
        return moment(date).format('YYYY-MM-DD')
    }
}

function objectIndex(obj, is, value) {
    if (typeof is === 'string') return objectIndex(obj, is.split('.'), value)
    else if (is.length === 1 && value !== undefined) return (obj[is[0]] = value)
    else if (is.length === 0) return obj
    else return objectIndex(obj[is[0]], is.slice(1), value)
}

function downloadCSV(header, data, name) {
    const utf8 = require('utf8')
    let csvContent = 'data:text/csv;charset=utf-8,'

    if (header) {
        header = header
            .map(
                (row) =>
                    // utf8.encode(
                    row
                        ?.toString()
                        ?.replaceAll(/(\r\n|\n|\r)/gm, '')
                        ?.replaceAll(',', '.')
                // )
            )
            .join(',')
            .replaceAll(' ', ' ')
        csvContent += header + '\r\n'
    }

    data.forEach(function (rowArray) {
        let row = rowArray
            .map(
                (row) =>
                    // utf8.encode(
                    row
                        ?.toString()
                        ?.replaceAll(/(\r\n|\n|\r)/gm, '')
                        ?.replaceAll(',', '.')
                // )
            )
            .join(',')
            .replaceAll(' ', ' ')
        csvContent += row + '\r\n'
    })

    var hiddenElement = document.createElement('a')
    hiddenElement.href = '' + encodeURI(csvContent)
    hiddenElement.target = '_blank'

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `${name}.csv`
    hiddenElement.click()
}

const displayOrderDetails = (order) => {
    return [
        ...order.packs
            ?.filter((e) => e.amount)
            ?.map((pack, i) =>
                pack?.products
                    ?.map((product, j) =>
                        pack.amount
                            ? `${product.quantity * pack.amount || ''}${
                                  product.weight
                                      ? 'x' +
                                        product.weight +
                                        (product.weightType || 'g')
                                      : ''
                              } ${product?.product?.name[i18next.language]}`
                            : ''
                    )
                    .join(', ')
            ),
        ...order.singleItems
            ?.filter((e) => e.amount)
            ?.map((item) =>
                item.amount
                    ? `${item.amount * (item.quantity || 1)}${
                          item.weight
                              ? 'x' + item.weight + (item.weightType || 'g')
                              : ''
                      } ${item?.product?.name[i18next.language]}`
                    : ''
            ),
    ].join(', ')
}

const numberToLetter = (index) => {
    const toLetter = (nb) => (nb + 10).toString(36).toUpperCase()

    const nbTimesIsIn = Math.floor(index / 26)
    const fromBeginning = index - nbTimesIsIn * 26

    return `${!!nbTimesIsIn ? toLetter(nbTimesIsIn - 1) : ''}${toLetter(
        fromBeginning
    )}`
}

export {
    numberToLetter,
    formatNb,
    keepTwoDecimals,
    noInfinite,
    formatDate,
    objectIndex,
    downloadCSV,
    displayOrderDetails,
}
