import { useReducer, useContext, useEffect } from 'react'
import { OfferLiveWX } from '../../context/OfferLiveWX'

import { WSOrders } from '../../context/WSOrders'
import DynamicStrings from '../../components/DynamicStrings'

function packsReducer(state, action) {
    switch (action.type) {
        case 'update':
            return action.payload
        default:
            break
    }
}

const TableHeader = ({ packs }) => {
    const { selectedColumn } = useContext(OfferLiveWX)
    const [packsHeader, updatePacksHeader] = useReducer(packsReducer, packs)
    const { wsOffer } = useContext(WSOrders)

    useEffect(() => {
        try {
            wsOffer.addEventListener('message', (message) => {
                let data = JSON.parse(message.data)
                // console.log('WebSocket - Message recieved header', data)

                switch (data?.type) {
                    case 'UPDATE_OFFER':
                        if (
                            packsHeader !==
                            [
                                ...data.offer.wechatGroup?.packs,
                                ...data.offer.wechatGroup?.items,
                            ]
                        ) {
                            updatePacksHeader({
                                type: 'update',
                                payload: [
                                    ...data.offer.wechatGroup?.packs,
                                    ...data.offer.wechatGroup?.items,
                                ],
                            })
                        }
                        break
                    default:
                        break
                }
            })
        } catch (error) {
            return error
        }
    }, [])

    return (
        <tr>
            {columnsTitlePart1.map((column) => (
                <th
                    key={column.key}
                    className={
                        column.key === selectedColumn ? 'head-selected' : 'head'
                    }
                    style={{ width: column.width }}
                >
                    {column.title}
                </th>
            ))}
            {packsHeader.map((pack) => (
                <th
                    key={pack.shortName}
                    style={{ width: 37 }}
                    className={
                        pack.shortName === selectedColumn
                            ? 'head-selected'
                            : 'head'
                    }
                >
                    <p
                        className={`stats-text-wrap total ${
                            pack?.actualStock < 6
                                ? ' is-very-low'
                                : pack?.actualStock < 16
                                ? ' is-low'
                                : ''
                        }`}
                        style={{ textAlign: 'center', margin: 0 }}
                    >
                        {pack?.shortName}
                        <br />({pack?.actualStock})
                    </p>
                </th>
            ))}
            {columnsTitlePart2.map((column) => (
                <th
                    key={column.key}
                    className={
                        column.key === selectedColumn ? 'head-selected' : 'head'
                    }
                    // className={
                    //     column.key === selectedColumn
                    //         ? ' column-selected'
                    //         : ' cell'
                    // }
                    style={{ width: column.width }}
                >
                    {column.title}
                </th>
            ))}
        </tr>
    )
}

export default TableHeader

const columnsTitlePart1 = [
    {
        width: 24.5,
        key: '01',
    },
    {
        width: 30,
        key: '02',
        title: <DynamicStrings id="OFFER_LIVE_N" />,
    },
    {
        width: 50,
        key: '03',
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
    },
    {
        width: 35,
        key: '04',
    },
    {
        width: 150,
        key: 'name',
        title: <DynamicStrings id="ROLES_NAME" />,
    },
    {
        width: 37,
        key: 'grp',
        title: <DynamicStrings id="OFFER_LIVE_GRP" />,
    },
]
const columnsTitlePart2 = [
    {
        width: 107,
        key: '07',
        title: <DynamicStrings id="OFFER_LIVE_PHONE" />,
    },
    {
        width: 134,
        key: 'address',
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_ADDRESS" />,
    },
    {
        width: 95,
        key: '09',
        title: <DynamicStrings id="ROLES_CITY" />,
    },
    {
        width: 90,
        key: 'area',
        title: <DynamicStrings id="OFFER_LIVE_AREA" />,
    },
    {
        width: 134,
        key: 'comment',
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
    },
    {
        width: 55,
        key: '11',
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_DATE" />,
    },
    {
        width: 55,
        key: '12',
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_FEE" />,
    },
    // {
    //     width: 35,
    //     key: 'fapiao',
    //     title: <DynamicStrings id="OFFER_LIVE_FAPIAO" />,
    // },
    {
        width: 62,
        key: 'voucher',
        title: <DynamicStrings id="OFFER_LIVE_VOUCHER" />,
    },
    {
        width: 29,
        key: '15',
        title: <DynamicStrings id="OFFER_LIVE_TICKET" />,
    },
    {
        width: 58,
        key: 'total',
        title: <DynamicStrings id="OFFER_LIVE_TOTAL" suffix="¥" />,
    },
    {
        width: 66,
        key: '17',
        title: <DynamicStrings id="OFFER_LIVE_PAYMENTS_STATUS" />,
    },
    {
        width: 36,
        key: '18',
        title: <DynamicStrings id="OFFER_LIVE_QR" />,
    },
    {
        width: 90,
        key: 'fapiao',
        title: <DynamicStrings id="OFFER_LIVE_FAPIAO" />,
    },
]
