import { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'

import {
    CustomerInformation,
    DailyOrderInformation,
    Delivery,
    // cleanBeforeSending,
    DailyOrderProducts,
    Comment,
} from '../../constants/forms/DailyOrders'
import { CreateDailyOrder as request, EditOrders } from '../../network/API'
import { CreateCustomer } from '../../network/Customers'
import { Form, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { AppContext } from '../../context/AppContext'
import moment from 'moment/moment'

const CreateDailyOrder = ({ history }) => {
    const loginUser = useContext(AppContext).user
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const [form] = Form.useForm()

    useEffect(() => {
        if (loginUser.communities && loginUser.communities.length == 1) {
            form.setFieldsValue({ community: loginUser.communities[0] })
        }
    }, [])

    const getDefault = () => {
        if (!history?.location?.user) {
            return {}
        }
        const user = history?.location?.user

        let newData = {
            customerDaily: {
                customerName: user.name,
                fapiaoInformation: user.fapiaoInformation,
            },
            customer: user.id,
        }
        if (user.addresses.length) {
            newData.customerAddress = {
                phone: user.addresses[0].phone,
                type: user.addresses[0].type,
                city: user.addresses[0].city,
                zipCode: user.addresses[0].zipCode,
                detailedAddress: user.addresses[0].detailedAddress,
            }

            let newCities = []
            let cities = { ...user.addresses[0].area }
            while (cities) {
                newCities.push(cities.id)
                cities = cities.parent
            }

            newCities.reverse()
            newData.customerAddress.area = newCities
        }
        return newData
    }

    const cleanBeforeSending = (oldData) => {
        if (!oldData.orderDate) {
            oldData = {
                ...oldData,
                orderDate: moment(),
            }
        }
        if (oldData.customer) {
            return {
                ...oldData,
                customerDaily: {
                    ...oldData.customerDaily,
                    customerName: undefined,
                },
            }
        } else {
            const values = form.getFieldValue()

            return {
                ...oldData,
                customerDaily: {
                    ...oldData.customerDaily,
                    customerName: values.customerDaily?.customerName || '',
                },
            }
        }

        return oldData
    }

    const createCustomer = (order) => {
        const values = form.getFieldValue()

        if (!order.customer) {
            let area = order.customerAddress?.area

            Modal.confirm({
                centered: true,
                title: t('CREATE_NEW_CUSTOMER'),
                icon: <ExclamationCircleOutlined />,
                content: t('CONFRIM_TO_CREATE_NEW_CUSTOMER'),
                okText: t('OFFER_LIVE_YES'),
                cancelText: t('OFFER_LIVE_NO'),
                onOk() {
                    return CreateCustomer(
                        {
                            name:
                                order.customerDaily?.customerName ||
                                values.customerDaily?.customerName,
                            addresses: [
                                {
                                    city: order.customerAddress?.city,
                                    zipCode: order.customerAddress?.zipCode,
                                    area: area,
                                    type: order.customerAddress?.type,
                                    detailedAddress:
                                        order.customerAddress?.detailedAddress,
                                    phone: order.customerAddress?.phone,
                                    contact: order.customerDaily?.customerName,
                                },
                            ],
                            wechatGroups: [],
                            pets: null,
                        },
                        token.token
                    )
                        .then((data) => {
                            EditOrders(
                                {
                                    customer: data.id,
                                },
                                token.token,
                                order.id
                            )
                                .then(() => {
                                    message.success('User created')
                                })
                                .catch(() => {
                                    message.error('An error occurs')
                                })
                        })
                        .catch(() => {
                            message.error('An error occurs')
                        })
                },
                onCancel() {},
            })
        }
    }

    return (
        <PageWrap goBack title="DIRECT_SALES_CREATE_DAILY_ORDER">
            <FormWrap
                form={form}
                title="DIRECT_SALES_CREATE_DAILY_ORDER"
                request={request}
                // id={customer.id}
                data={getDefault()}
                onSuccess={'/orders-direct-sales'}
                onCancel={'/orders-direct-sales'}
                cleanBeforeSending={cleanBeforeSending}
                beforeOnSuccess={createCustomer}
            >
                <div className="SectionHorizontalWrap">
                    <div className="SectionVerticalWrap" style={{ flex: 2 }}>
                        <FormBlock
                            form={form}
                            formTemplate={DailyOrderInformation}
                        />
                        <FormBlock
                            form={form}
                            formTemplate={CustomerInformation}
                        />
                        <FormBlock
                            form={form}
                            formTemplate={DailyOrderProducts}
                        />
                    </div>
                    <div className="SectionVerticalWrap" style={{ flex: 1 }}>
                        <FormBlock form={form} formTemplate={Delivery} />
                        <FormBlock form={form} formTemplate={Comment} />
                    </div>
                </div>
            </FormWrap>
        </PageWrap>
    )
}

export default CreateDailyOrder
