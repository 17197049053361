const axios = require('axios')

export const SERVER = { baseURL: process.env.REACT_APP_API_URL }

export const INSTANCE = axios.create(SERVER)
export const INSTANCELIST = axios.create(SERVER)

INSTANCE.interceptors.response.use(
    async (response) => {
        console.log('Response:', response)

        return response.data
    },
    (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
            localStorage.clear()
            window.location.href = '/'
        }
        return Promise.reject(error)
    }
)

INSTANCELIST.interceptors.response.use(
    async (response) => {
        // console.log('Response:', response)

        return {
            range: response.headers['content-range']?.split('/')[1],
            list: response.data,
        }
    },
    (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
            localStorage.clear()
            window.location.href = '/'
        }
        return Promise.reject(error)
    }
)

export const login = (email, password) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/auth/login',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email: email,
                password: password,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const refresh = (refreshToken) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/auth/login',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                refreshToken,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getLottery = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter, ...rest } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/lottery-draws/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
                ...rest,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const lotteryAsign = (
    drawId,
    winnerNumber,
    orderID,
    drawNumber,
    token
) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: `/lottery-draws/${drawId}/assign`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                winnerNumber,
                orderID,
                drawNumber,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getInventory = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/product-stocks/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getStocks = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { productId, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/product-stocks?product=${productId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const getStocksAnalytics = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: 'product-stocks/analytics',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getStockMovements = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { productId, sort, range, filter, token, tabFilter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/stock-movements/details?product=${productId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const moveStock = (
    productId,
    amount,
    type,
    status,
    stockType,
    comment,
    token
) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: `/stock-movements`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                stockType,
                comment,
                type,
                status,
                date: new Date(),
                product: productId,
                movementStock: amount,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getClaims = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/claims/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const EditClaims = (form, token, claimsId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/claims/${claimsId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getVouchers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/vouchers/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateVoucher = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/vouchers',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getCommunities = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, filter } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/communities',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getTags = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const [, { token, category }] = queryKey
        // console.log('fielddd', queryKey)
        INSTANCELIST({
            method: 'get',
            url: '/recipe-tags',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                category: category,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getOrders = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter, ...rest } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/orders/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
                ...rest,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleOrder = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { orderId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/orders/${orderId}/daily`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const EditOrders = (form, token, ordersId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/orders/${ordersId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const CustomerQuickInfos = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { customer, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/customers/${customer}/stats-lite`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const EditOrdersStatus = (form, token, ordersId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/orders/${ordersId}/tracking`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateOrder = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/orders',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const CustomerVouchers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { userId, sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/vouchers/details?customer=${userId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CustomerClaims = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { userId, sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: `/claims/details?customer=${userId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateClaim = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/claims',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleClaim = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { claimId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/claims/${claimId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CustomerAnalytics = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { userId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/customers/${userId}/analytics`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getAnalytics = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, ...tabFilter } = queryKey[1]

        if (!tabFilter.dateStart && !tabFilter.dateEnd) {
            const today = new Date()
            tabFilter.dateStart = new Date(
                today.getFullYear(),
                today.getMonth(),
                1
            )
            tabFilter.dateEnd = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0
            )
        }

        INSTANCE({
            method: 'get',
            url: '/analytics/general',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                ...tabFilter,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getClaimsAnalytics = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: '/claims/analytics',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getDeliveryTemplates = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/delivery-templates/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify({
                    ...filter,
                    status: { $ne: 'discontinued' },
                }),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateDeliveryTemplates = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/delivery-templates',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const EditDeliveryTemplates = (form, token, templateId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/delivery-templates/${templateId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleDeliveryTemplates = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { templateId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/delivery-templates/${templateId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getDeliveryAreas = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/delivery-areas',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getAreasFromParent = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, parentId } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/delivery-areas?parent=${parentId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateDeliveryAreas = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/delivery-areas',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const EditDeliveryAreas = (form, token, areaId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/delivery-areas/${areaId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleDeliveryArea = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { areaId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/delivery-areas/${areaId}/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const poToInvoice = (offers, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/purchase-orders/generate',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                offers,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getInvoices = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token, tabFilter, ...rest } = queryKey[1]
        filter.type = 'invoice'

        INSTANCELIST({
            method: 'get',
            url: `/purchase-orders/details`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
                ...tabFilter,
                ...rest,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const EditInvoice = (form, token, invoiceId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/purchase-orders/${invoiceId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateDailyOrder = (form, token) =>
    new Promise((resolve, reject) => {
        form.type = 'daily'
        form.channel = 'daily'
        form.customerDaily.customerName =
            form.customer?.name || form.customerDaily.customerName
        form.customerAddress.area = form.customerAddress?.area[0]

        console.log(form)

        INSTANCE({
            method: 'post',
            url: '/orders',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const DeleteArea = (areaId, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/delivery-areas/${areaId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                status: 'discontinued',
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const DeleteTemplate = (templateId, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/delivery-templates/${templateId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                status: 'discontinued',
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const ClaimNextStep = (claimId, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: `/claims/${claimId}/next`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getOrderExcel = (id, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'get',
            url: `/offers/${id}/excel`,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const upload = (file, uri, token) =>
    new Promise((resolve, reject) => {
        var formData = new FormData()
        formData.append('file', file)

        INSTANCE({
            method: 'post',
            url: uri,
            headers: { Authorization: `Bearer ${token}` },
            data: formData,
        })
            .then(resolve)
            .catch(reject)
    })
