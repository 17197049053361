import {
    useFieldArray,
    Controller,
    useWatch,
    useFormContext,
} from 'react-hook-form'
import {
    Input,
    Select,
    Form,
    Card,
    Button,
    InputNumber,
    DatePicker,
} from 'antd'
import { useState, useEffect } from 'react'

import numberToLetter from './numberToLetter'
import UploadField from './Upload'

const Specials = ({ packs, items, control, channel, isEdit, errors }) => {
    const { getValues, reset } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${channel}.zikoSpecials`,
    })

    const onRemove = (index) => {
        const values = getValues()
        values[channel].zikoSpecials.splice(index, 1)
        reset(values, { isDirty: true })
    }

    return (
        <Card bordered={false} title="Ziko specials">
            {fields.map((field, index) => (
                <SpecialLine
                    key={field.id}
                    fields={fields}
                    specialIndex={index}
                    control={control}
                    channel={channel}
                    totalPacks={packs}
                    totalItems={items}
                    remove={() => onRemove(index)}
                    // remove={() => remove(index)}
                    isEdit={isEdit}
                    errors={errors?.[index]}
                />
            ))}
            <Button type="primary" onClick={append} disabled={isEdit}>
                Add special
            </Button>
        </Card>
    )
}

const SpecialLine = ({
    specialIndex,
    control,
    channel,
    totalPacks,
    totalItems,
    remove,
    isEdit,
    errors,
}) => {
    const { getValues } = useFormContext()
    const [conditionValue, setConditionValue] = useState()
    const [giftTypeValue, setGiftTypeValue] = useState()
    // const conditionValue = useWatch({
    //     control,
    //     name: `${channel}.zikoSpecials.${specialIndex}.conditionType`,
    // })

    // const giftTypeValue = useWatch({
    //     control,
    //     name: `${channel}.zikoSpecials.${specialIndex}.gift.type`,
    // })

    useEffect(() => {
        const values = getValues()

        const specialLineValues =
            values?.[channel]?.zikoSpecials?.[specialIndex]

        setConditionValue(specialLineValues?.conditionType)
        setGiftTypeValue(specialLineValues?.gift?.type)
    }, [])

    const isValue =
        conditionValue === 'first_order' ||
        conditionValue === 'number_of_order' ||
        conditionValue === 'x_total_sold_items' ||
        conditionValue === 'order_for_amount'

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(5, 1fr)',
                gridGap: 8,
            }}
        >
            <Form.Item
                label='Special "X"'
                validateStatus={
                    !!errors?.['conditionType'] ? 'error' : undefined
                }
                help={errors?.['conditionType']?.message}
            >
                <Controller
                    name={`${channel}.zikoSpecials.${specialIndex}.conditionType`}
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ value, onChange }) => {
                        const handleChange = (e) => {
                            onChange(e)
                            setConditionValue(e)
                        }

                        return (
                            <Select
                                value={value}
                                onChange={handleChange}
                                disabled={isEdit}
                            >
                                <Select.Option value="first_order">
                                    First orders
                                </Select.Option>
                                <Select.Option value="number_of_order">
                                    Number of orders reach
                                </Select.Option>
                                <Select.Option value="order_for_amount">
                                    Order for (amount) get
                                </Select.Option>
                                <Select.Option value="x_total_sold_items">
                                    "X" total sold items
                                </Select.Option>
                                <Select.Option value="item_x_in_cart">
                                    Item "X" in cart
                                </Select.Option>
                            </Select>
                        )
                    }}
                />
            </Form.Item>
            {isValue && (
                <Form.Item
                    label={
                        conditionValue === 'order_for_amount'
                            ? 'How much (¥)'
                            : 'Amount / Quantity'
                    }
                    validateStatus={
                        !!errors?.['conditionValue'] ? 'error' : undefined
                    }
                    help={errors?.['conditionValue']?.message}
                >
                    <Controller
                        name={`${channel}.zikoSpecials.${specialIndex}.conditionValue`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            )}
            {!isValue && (
                <Form.Item
                    label="Item"
                    validateStatus={
                        !!errors?.['conditionPack'] ? 'error' : undefined
                    }
                    help={errors?.['conditionPack']?.message}
                >
                    <Controller
                        name={`${channel}.zikoSpecials.${specialIndex}.conditionPack`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            >
                                {Array.from(
                                    Array(totalPacks + totalItems).keys()
                                ).map((e, index) => (
                                    <Select.Option
                                        key={index}
                                        value={numberToLetter(index)}
                                    >
                                        {numberToLetter(index)}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    />
                </Form.Item>
            )}
            <Form.Item
                label="Get"
                validateStatus={
                    !!errors?.['gift']?.['type'] ? 'error' : undefined
                }
                help={errors?.['gift']?.['type']?.message}
            >
                <Controller
                    name={`${channel}.zikoSpecials.${specialIndex}.gift.type`}
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ value, onChange }) => {
                        const handleChange = (e) => {
                            onChange(e)
                            setGiftTypeValue(e)
                        }

                        return (
                            <Select
                                value={value}
                                onChange={handleChange}
                                disabled={isEdit}
                            >
                                <Select.Option value="pack">Pack</Select.Option>
                                <Select.Option value="add_on">
                                    Add ons
                                </Select.Option>
                                <Select.Option value="custom">
                                    Custom
                                </Select.Option>
                                <Select.Option value="voucher">
                                    Voucher
                                </Select.Option>
                                <Select.Option value="discount">
                                    Discount
                                </Select.Option>
                                <Select.Option value="free_delivery">
                                    Free delivery
                                </Select.Option>
                            </Select>
                        )
                    }}
                />
            </Form.Item>

            {giftTypeValue === 'pack' && (
                <Form.Item
                    label="Pack"
                    validateStatus={
                        !!errors?.['gift']?.['pack'] ? 'error' : undefined
                    }
                    help={errors?.['gift']?.['pack']?.message}
                >
                    <Controller
                        name={`${channel}.zikoSpecials.${specialIndex}.gift.pack`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            >
                                {Array.from(Array(totalPacks).keys()).map(
                                    (e, index) => (
                                        <Select.Option
                                            key={index}
                                            value={numberToLetter(index)}
                                            disabled={isEdit}
                                        >
                                            {numberToLetter(index)}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        )}
                    />
                </Form.Item>
            )}

            {giftTypeValue === 'add_on' && (
                <Form.Item
                    label="Add ons"
                    validateStatus={
                        !!errors?.['gift']?.['singleItem'] ? 'error' : undefined
                    }
                    help={errors?.['gift']?.['singleItem']?.message}
                >
                    <Controller
                        name={`${channel}.zikoSpecials.${specialIndex}.gift.singleItem`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            >
                                {Array.from(Array(totalItems).keys()).map(
                                    (e, index) => (
                                        <Select.Option
                                            key={index}
                                            value={numberToLetter(
                                                index + totalPacks
                                            )}
                                        >
                                            {numberToLetter(index + totalPacks)}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        )}
                    />
                </Form.Item>
            )}

            {giftTypeValue === 'custom' && (
                <>
                    <Form.Item
                        label="Name (中文)"
                        validateStatus={
                            !!errors?.['gift']?.['custom']?.['zh']
                                ? 'error'
                                : undefined
                        }
                        help={errors?.['gift']?.['custom']?.['zh']?.message}
                    >
                        <Controller
                            name={`${channel}.zikoSpecials.${specialIndex}.gift.custom.zh`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                    {channel === 'miniprogram' && (
                        <Form.Item
                            label="Illustration"
                            style={{ gridRow: 2, gridColumn: 3 }}
                            validateStatus={
                                !!errors?.['gift']?.['customImage']
                                    ? 'error'
                                    : undefined
                            }
                            help={errors?.['gift']?.['customImage']?.message}
                        >
                            <Controller
                                name={`${channel}.zikoSpecials.${specialIndex}.gift.customImage`}
                                control={control}
                                rules={{ required: 'Required' }}
                                // defaultValue={null}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="gift-image"
                                        disabled={isEdit}
                                    />
                                )}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Name (English)"
                        style={{ gridRow: 2, gridColumn: 4 }}
                        validateStatus={
                            !!errors?.['gift']?.['custom']?.['en']
                                ? 'error'
                                : undefined
                        }
                        help={errors?.['gift']?.['custom']?.['en']?.message}
                    >
                        <Controller
                            name={`${channel}.zikoSpecials.${specialIndex}.gift.custom.en`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                </>
            )}

            {giftTypeValue === 'voucher' && (
                <>
                    <Form.Item
                        label="Expiration date"
                        validateStatus={
                            !!errors?.['gift']?.['voucherExpiration']
                                ? 'error'
                                : undefined
                        }
                        help={errors?.['gift']?.['voucherExpiration']?.message}
                    >
                        <Controller
                            name={`${channel}.zikoSpecials.${specialIndex}.gift.voucherExpiration`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <DatePicker
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={onChange}
                                    format={'YYYY-MM-DD'}
                                    disabled={isEdit}
                                    allowClear
                                />
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Amount (¥)"
                        style={{ gridRow: 2, gridColumn: 4 }}
                        validateStatus={
                            !!errors?.['gift']?.['voucherValue']
                                ? 'error'
                                : undefined
                        }
                        help={errors?.['gift']?.['voucherValue']?.message}
                    >
                        <Controller
                            name={`${channel}.zikoSpecials.${specialIndex}.gift.voucherValue`}
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({ value, onChange }) => (
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Form.Item>
                </>
            )}

            {giftTypeValue === 'discount' && (
                <Form.Item
                    label="Amount"
                    validateStatus={
                        !!errors?.['gift']?.['discountAmount']
                            ? 'error'
                            : undefined
                    }
                    help={errors?.['gift']?.['discountAmount']?.message}
                >
                    <Controller
                        name={`${channel}.zikoSpecials.${specialIndex}.gift.discountAmount`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ value, onChange }) => (
                            <InputNumber
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            )}

            <Button
                type="link"
                onClick={remove}
                style={{ margin: 'auto auto auto 0' }}
                disabled={isEdit}
            >
                Delete special
            </Button>
        </div>
    )
}

export default Specials
