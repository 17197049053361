import { useContext } from 'react'
import { useQuery } from 'react-query'
import moment from 'moment'
import { formatDate } from '../../utils/utils'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import { Form } from 'antd'

import {
    GeneralInformation,
    RefundDetail,
    cleanBeforeSending,
    checkValidity
} from '../../constants/forms/Refunds'
import { CreateRefund as request, getUnrefundedOrders } from '../../network/Refunds'
import { AppContext } from '../../context/AppContext'

const CreateRefund = ({ history }) => {
    const [form] = Form.useForm()
    const { user, token } = useContext(AppContext)
    const orderId = history?.location?.order

    const orderDetails = useQuery(
        [
            'orders',
            {
                id: orderId,
                token: token.token,
            },
        ],
        getUnrefundedOrders,
        {
            enabled: !!orderId,
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (order) => {
        if (!orderId || !order) return {}

        let detail = ''
        for (const pack of order.packs) {
            detail += pack.amount
                ? `${pack.amount} Pack ${pack.shortName},`
                : ''
        }

        for (const item of order.singleItems) {
            detail += item.amount
                ? `${item.amount} Single Item ${item.shortName},`
                : ''
        }

        const values = {
            teamMember: user.id,
            status: 'new',
            order: order.id,
            customer: order.customer?.id,
            community: order.community?.id,
            offer: order.offer?.id,
            supplier: order.supplier?.id,
            orderInfos: {
                orderID: order.orderID,
                community: order.community?.name,
                customer:
                    order.customer?.name || order.customerDaily?.customerName,
                supplier: order.supplier?.name,
                offer: order.offer?.internalName,
                orderDate: moment(order.createdAt),
                actualAmount: order.actualAmount,
                city: order.customerAddress?.city,
                detailedAddress: order.customerAddress?.detailedAddress,
                phone: order.customerAddress?.phone,
                deliveryTrackingID: order.deliveryTrackingID,
                deliveryDate: moment(order.deliveryDate).isValid()
                    ? formatDate(order.deliveryDate)
                    : order.deliveryDate,
                detail: detail,
                paymentMethod: order.paymentMethod,
            },
            refundAmount: order.actualAmount,
        }

        return values
    }

    return (
        <PageWrap goBack title="REFUNDS_CREATE_REFUND">
            {orderDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    form={form}
                    title="create_refund"
                    request={request}
                    data={formatData(orderDetails?.data?.list[0])}
                    onSuccess={'/refunds'}
                    onCancel={'/refund'}
                    invalidate={['getRefunds', 'getRefundsStats']}
                    cleanBeforeSending={cleanBeforeSending}
                    checkValidity={checkValidity}
                >
                    <div className="SectionVerticalWrap">
                        <FormBlock
                            form={form}
                            formTemplate={GeneralInformation}
                        />
                        <FormBlock formTemplate={RefundDetail} />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default CreateRefund
