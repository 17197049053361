import { useContext } from 'react'
import {
    Tag,
    Typography,
    Avatar,
    Space,
    Checkbox,
    Popover,
    Badge,
    Spin,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import Logo from '../../images/Ellipse.svg'
import { useQuery } from 'react-query'

import { SERVER, getDeliveryAreas } from '../../network/API'
import { AppContext } from '../../context/AppContext'
import { formatNb } from '../../utils/utils'

import PacksStatLive from './PacksStatsLive'
import DynamicStrings from '../../components/DynamicStrings'
import ImageCustomer from './ImageCustomer'

const { Text } = Typography

const mergePacksToColumn = (columns, packs, items, orders) => {
    const CustomTitle = ({ pack }) => {
        return (
            <p
                className={`stats-text-wrap total ${
                    pack?.actualStock < 6
                        ? ' is-very-low'
                        : pack?.actualStock < 16
                        ? ' is-low'
                        : ''
                }`}
                style={{ textAlign: 'center', margin: 0 }}
            >
                {pack?.shortName}
                <br />({pack?.actualStock})
            </p>
        )
    }

    let tempColumns = [...columns]
    let packsColumnIndex = columns.findIndex((e) => e.key === 'packs')
    let packsCol = []
    let itemsCol = []
    if (packs && packs.length) {
        packsCol = packs.map((pack) => ({
            width: 37,
            ellipsis: true,
            title: <CustomTitle pack={pack} orders={orders} />,
            key: pack._id,
            render: (order) => {
                const exist = order.packs?.find((e) => e._id === pack._id)
                return exist?.amount
            },
        }))
    }

    if (items && items.length) {
        itemsCol = items.map((item) => ({
            width: 37,
            ellipsis: true,
            title: <CustomTitle pack={item} orders={orders} />,
            key: item._id,
            render: (order) => {
                const exist = order.singleItems?.find((e) => e._id === item._id)
                return exist?.amount
            },
        }))
    }
    tempColumns.splice(packsColumnIndex, 1, ...packsCol, ...itemsCol)
    return tempColumns
}

const Miniprogram = ({ orders, packs, items, isLoading, bourse, freeFall }) => {
    const { token } = useContext(AppContext)
    const areas = useQuery(
        ['getDeliveryAreas', { token: token.token }],
        getDeliveryAreas,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    )

    const columns = [
        {
            width: 70,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_N" />,
            render: (order, t, i) => order.orderNumber,
        },
        {
            width: 72,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
            render: (order) => order.orderID,
        },
        {
            width: 35,
            textWrap: 'word-break',
            render: (order) => <ImageCustomer order={order} />,
        },
        {
            width: 150,
            textWrap: 'word-break',
            title: <DynamicStrings id="ROLES_NAME" />,
            render: (order) =>
                order?.customer?.id
                    ? order.customer.name
                    : order.customerDaily?.customerName,
        },
        {
            width: 37,
            textWrap: 'word-break',
            key: 'packs',
            title: <LoadingOutlined />,
        },
        {
            width: 107,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_PHONE" />,
            render: (order) => order.customerAddress?.phone,
        },
        {
            width: 134,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_DELIVERY_ADDRESS" />,
            render: (order) => order.customerAddress?.detailedAddress,
        },
        {
            width: 95,
            textWrap: 'word-break',
            title: <DynamicStrings id="ROLES_CITY" />,
            render: (order) => order.customerAddress?.city,
        },
        {
            width: 90,
            textWrap: 'word-break',
            key: 'area',
            title: <DynamicStrings id={'OFFER_LIVE_AREA'} />,
            render: (order) => {
                const area = areas.data?.list?.find(
                    (e) => e.id === order.customerAddress?.area
                )
                return area?.name || ''
            },
        },
        {
            width: 134,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
            render: (order) => order.comment,
        },
        {
            width: 79,
            textWrap: 'word-break',
            key: '11',
            title: <DynamicStrings id="OFFER_LIVE_DELIVERY_DATE" />,
            render: (order) =>
                order.deliveryDate && moment(order.deliveryDate).isValid()
                    ? moment(order.deliveryDate).format('ddd DD')
                    : order.deliveryDate,
        },
        {
            width: 74,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_DELIVERY_FEE" />,
            render: (order) => order.deliveryFee,
        },
        // {
        //     width: 45,
        //     textWrap: 'word-break',
        //     title: <DynamicStrings id="OFFER_LIVE_FAPIAO" />,
        //     className: (order) => (order.fapiao ? ' checked' : ' unchecked'),
        //     render: (order) =>
        //         order.id === 'new' ? null : <Checkbox checked={order.fapiao} />,
        // },
        {
            width: 62,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_VOUCHER" />,
            render: (order) =>
                order.vouchers?.length ? order.vouchers[0]?.amount || '-' : '-',
        },
        {
            width: 29,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_TICKET" />,
            render: (order) => order.ticketAmount,
        },
        {
            width: 58,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_TOTAL" suffix="¥" />,
            className: (order) =>
                order.paymentMethod === 'alipay' ? ' is-alipay' : '',
            render: (order) => formatNb(order.actualAmount),
        },
        {
            width: 66,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_PAYMENTS_STATUS" />,
            render: (order) => order.paymentStatus,
        },
        {
            width: 36,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_CHAT" />,
            key: '18',
        },
        {
            width: 45,
            textWrap: 'word-break',
            title: <DynamicStrings id="OFFER_LIVE_FAPIAO" />,
            render: (order) => order.fapiaoEmail,
        },
    ]

    const newColumns = mergePacksToColumn(columns, packs, items, orders)

    return (
        <Space
            direction="vertical"
            style={{
                marginTop: '24px',
                padding: '0px 24px',
                width: '100%',
            }}
        >
            <PacksStatLive
                packs={packs}
                items={items}
                freeFall={freeFall}
                bourse={bourse}
            />
            <table
                style={{ width: '100%' }}
                className="tableWrap offer-live ant-table ant-table-bordered mp-table"
            >
                <thead className="ant-table-thead">
                    <tr>
                        {newColumns.map((column) => (
                            <th
                                id={column.key}
                                className="cell"
                                style={{ width: column.width }}
                            >
                                {column.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="ant-table-tbody">
                    {isLoading && <Spin />}
                    {orders.map((order, i) => (
                        <tr className="line">
                            {newColumns.map((column) => (
                                <td
                                    id={column.key + order.id}
                                    className="cell"
                                    style={{ width: column.width }}
                                >
                                    {column.render
                                        ? column.render(order, null, i)
                                        : null}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Space>
    )
}

export default Miniprogram
