/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react'
import {
    Space,
    Typography,
    Menu,
    Dropdown,
    Badge,
    Select,
    Form,
    message,
    Popover,
    Modal,
    DatePicker,
    Input,
    InputNumber
} from 'antd'
import { DownOutlined, LoadingOutlined, CheckOutlined, EnterOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { formatNb } from '../../../utils/utils'

import { EditOrders } from '../../../network/API'
import { CreateRefund } from '../../../network/Refunds'

import { AppContext } from '../../../context/AppContext'
import DynamicStrings from '../../../components/DynamicStrings'

import moment from "moment";

const Status = ({
    status,
    id,
    config,
    keyRequest,
    fieldKey,
    editRequest,
    onChange,
    onChangeKey,
    isLoading,
    supData,
}) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const badgeColor = (key) => config.find((e) => e.key === key)?.color || ''

    const mutation = useMutation((values) => editRequest(...values), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e.id === data.id
                )
                oldData.list[oldDataIndex][fieldKey] = data[fieldKey]
                return oldData
            })
        },
    })

    return (
        <Select
            bordered={false}
            className="status-select"
            loading={mutation.isLoading || isLoading}
            value={status}
            onChange={(e) => {
                if (onChangeKey !== e) {
                    if (supData) {
                        mutation.mutate([
                            { [fieldKey]: e, ...supData(e) },
                            token.token,
                            id,
                        ])
                    } else {
                        mutation.mutate([{ [fieldKey]: e }, token.token, id])
                    }
                } else {
                    onChange(e)
                }
            }}
        >
            {config?.map((status) => (
                <Select.Option
                    disabled={status.disabled}
                    key={status.key}
                    value={status.key}
                >
                    <Badge
                        color={badgeColor(status.key)}
                        text={t(status.key)}
                    />
                </Select.Option>
            ))}
        </Select>
    )
}

// export const OrderStatus = ({
//     status,
//     paymentStatus,
//     trackingStatus,
//     id,
//     keyRequest,
// }) => {
//     const { t } = useTranslation()
//     const { token, user } = useContext(AppContext)
//     const isAdmin = user?.role === 'admin' || user?.role === 'super-admin'
//     const queryClient = useQueryClient()

//     // let config = [
//     //     { key: 'pending', color: 'grey', disabled: true },
//     //     { key: 'paid', color: 'green' },
//     //     { key: 'ccl', color: 'red' },
//     //     { key: 'rfd', color: 'blue' },
//     // ]

//     // if (paymentStatus === 'ccl') {
//     //     return <Badge color={'red'} text={t(paymentStatus)} />
//     // }

//     // if (trackingStatus === 'delivered') {
//     //     if (paymentStatus === 'paid') {
//     //         config = [{ key: 'paid', color: 'green' }]
//     //         return <Badge color={'green'} text={t('paid')} />
//     //     } else {
//     //         config = [
//     //             { key: 'pending', color: 'grey', disabled: true },
//     //             { key: 'paid', color: 'green' },
//     //             { key: 'ccl', color: 'red', disabled: true },
//     //             { key: 'rfd', color: 'blue', disabled: true },
//     //         ]
//     //     }
//     // }

//     const mutation = useMutation((values) => EditOrders(...values), {
//         onSuccess: (data, variables) => {
//             if (
//                 data.status === 'discontinued' &&
//                 data.paymentStatus === 'pending'
//             ) {
//                 queryClient.invalidateQueries('getOrders')
//             }

//             queryClient.setQueryData(keyRequest, (oldData) => {
//                 let oldDataIndex = oldData.list.findIndex(
//                     (e) => e.id === data.id
//                 )

//                 oldData.list[oldDataIndex].paymentStatus = data.paymentStatus
//                 oldData.list[oldDataIndex].actualAmount = data.actualAmount
//                 return oldData
//             })
//         },
//     })

//     const isFieldDisabled =
//         (paymentStatus !== 'pending' && paymentStatus !== 'paid') ||
//         status === 'discontinued' ||
//         paymentStatus === 'rfd'

//     return (
//         // <Status
//         //     status={paymentStatus}
//         //     fieldKey={'paymentStatus'}
//         //     requestName={'getOrders'}
//         //     keyRequest={keyRequest}
//         //     editRequest={EditOrders}
//         //     supData={(e) =>
//         //         e === 'ccl'
//         //             ? {
//         //                   status: 'discontinued',
//         //                   packs: [],
//         //                   singleItems: [],
//         //                   vouchers: [],
//         //               }
//         //             : {}
//         //     }
//         //     id={id}
//         //     config={config}
//         // />

//         <Select
//             disabled={isFieldDisabled && !isAdmin}
//             bordered={false}
//             value={paymentStatus}
//             onChange={(e) => {
//                 let newOrder = { paymentStatus: e }
//                 if (e === 'ccl' || e === 'rfd') {
//                     if (paymentStatus === 'pending') {
//                         if (e === 'ccl') {
//                             newOrder.paymentStatus = 'pending'
//                         }
//                         newOrder.packs = []
//                         newOrder.singleItems = []
//                         newOrder.deliveryFee = 0
//                         newOrder.deliverySelection = 'manual'
//                     }

//                     newOrder.status = 'discontinued'
//                 } else if (e === 'paid') {
//                     newOrder.status = 'available'
//                 }

//                 mutation.mutate([
//                     {
//                         ...newOrder,
//                     },
//                     token.token,
//                     id,
//                 ])
//             }}
//         >
//             {paymentStatus === 'pending' && (
//                 <Select.Option key={'pending'}>
//                     <Badge color={'grey'} text={t('pending')} />
//                 </Select.Option>
//             )}
//             {(paymentStatus === 'paid' || paymentStatus === 'rfd') && (
//                 <Select.Option key={'rfd'}>
//                     <Badge color={'blue'} text={t('rfd')} />
//                 </Select.Option>
//             )}
//             {(paymentStatus === 'pending' ||
//                 paymentStatus === 'paid' ||
//                 isAdmin) && (
//                 <Select.Option key={'paid'}>
//                     <Badge color={'green'} text={t('paid')} />
//                 </Select.Option>
//             )}

//             <Select.Option key={'ccl'}>
//                 <Badge color={'red'} text={t('ccl')} />
//             </Select.Option>

//             {/* <Option key={'pending'}>pending</Option> */}
//         </Select>
//     )
// }

export const OrderStatus = ({
    status,
    paymentStatus,
    trackingStatus,
    id,
    keyRequest,
    actualAmount,
    ...order
}) => {
    const { t } = useTranslation()
    const { token, user } = useContext(AppContext)
    const isAdmin = user?.role === 'admin' || user?.role === 'super-admin'
    const queryClient = useQueryClient()

    const [showModal, setShowModal] = useState(false)
    const [showSelect, setShowSelect] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const [refundAmount, setRefundAmount] = useState(0)
    const [actionETA, setActionETA] = useState('')
    const [resp, setResp] = useState('')
    const [issue, setIssue] = useState('')
    const [issueDetail, setIssueDetail] = useState('')
    const [comment, setComment] = useState('')

    const orderInfo = order?.order

    const mutation = useMutation((values) => EditOrders(...values), {
        onSuccess: (data, variables) => {
            if (
                data.status === 'discontinued' &&
                data.paymentStatus === 'pending'
            ) {
                queryClient.invalidateQueries('getOrders')
            }

            queryClient.setQueryData(keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e.id === data.id
                )

                oldData.list[oldDataIndex].paymentStatus = data.paymentStatus
                oldData.list[oldDataIndex].actualAmount = data.actualAmount
                return oldData
            })
        },
    })

    const mutationRefund = useMutation(
        () =>
            CreateRefund(
                {
                    order: id,
                    customer: orderInfo.customer?.id,
                    teamMember: user.id,
                    community: orderInfo.community?.id,
                    offer: orderInfo.offer?.id,
                    supplier: orderInfo.supplier?.id,

                    date: moment(),
                    actionETA,
                    refundAmount,
                    resp,
                    issue,
                    issueDetail,
                    comment,
                    status: 'new'
                },
                token.token
            ),
        {
            onSuccess: (data, variables, context) => {
                setRefundAmount(0)
                setResp('')
                setIssue('')
                setIssueDetail('')
                setComment('')
                setActionETA('')
                setShowModal(false)

                let newOrder = {
                    paymentStatus: 'refunding',
                    // status: 'discontinued'
                }

                mutation.mutate([
                    newOrder,
                    token.token,
                    id,
                ])
            },
            onError: () => {
                message.error('An error occurs')

                setRefundAmount(0)
                setResp('')
                setIssue('')
                setIssueDetail('')
                setComment('')
                setActionETA('')
                setShowModal(false)
            },
        }
    )

    const isFieldDisabled =
        (paymentStatus !== 'pending' && paymentStatus !== 'paid') ||
        status === 'discontinued' ||
        paymentStatus === 'refunding' ||
        paymentStatus === 'rfd'

    if (
        !(user.role === 'super-admin' || user.role === 'admin' || user.role === 'finance' ||
            user.role === 'community-manager' || user.role === 'customer-hero')
    ) {
        if (paymentStatus === 'refunding') {
            return (
                <Badge color={'orange'} text={t('rfd')} />
            )
        }
        if (paymentStatus === 'rfd') {
            return (
                <Popover
                    placement="bottom"
                    content={() => {
                        return (
                            <>
                                <EnterOutlined
                                    style={{ transform: 'scale(-1, 1)', color: '#BBBBBB', marginRight: 5 }}
                                />
                                {formatNb(orderInfo.refundAmount)}
                            </>
                        )
                    }}
                >
                    <Badge color={'#BB4DFF'} text={t('refunded')} />
                </Popover>
            )
        }
    }
    
    return (
        <>
            <Select
                className="status-select"
                disabled={isFieldDisabled && !isAdmin}
                bordered={false}
                value={paymentStatus}
                onChange={(e) => {
                    let newOrder = { paymentStatus: e }
                    if (e === 'ccl' || e === 'refunding' || e === 'rfd') {
                        if (paymentStatus === 'pending') {
                            if (e === 'ccl') {
                                newOrder.paymentStatus = 'pending'
                            }
                            newOrder.packs = []
                            newOrder.singleItems = []
                            newOrder.deliveryFee = 0
                            newOrder.deliverySelection = 'manual'
                        }

                        newOrder.status = 'discontinued'
                    } else if (e === 'paid') {
                        newOrder.status = 'available'
                    }

                    if (e === 'refunding') {
                        setRefundAmount(actualAmount.toFixed(2))
                        setShowModal(true)
                        return
                    }

                    mutation.mutate([
                        {
                            ...newOrder,
                        },
                        token.token,
                        id,
                    ])
                }}
                onDropdownVisibleChange={() => setShowSelect(true)}
                onBlur={() => setShowSelect(false)}
            >
                {paymentStatus === 'pending' && (
                    <Select.Option key={'pending'}>
                        <Badge color={'grey'} text={t('pending')} />
                    </Select.Option>
                )}
                {(user.role === 'super-admin' || user.role === 'admin' || user.role === 'finance' ||
                    user.role === 'community-manager' || user.role === 'customer-hero') && (
                        paymentStatus === 'paid' || paymentStatus === 'refunding') && (
                    <Select.Option key={'refunding'}>
                        <Badge color={'orange'} text={t('rfd')} />
                    </Select.Option>
                )}
                {(paymentStatus !== 'rfd' && paymentStatus !== 'refunding') && (
                    paymentStatus === 'pending' || paymentStatus === 'paid' || isAdmin) && (
                        <Select.Option key={'paid'}>
                            <Badge color={'green'} text={t('paid')} />
                        </Select.Option>
                    )}
                {(paymentStatus !== 'rfd' && paymentStatus !== 'refunding') && (
                    <Select.Option key={'ccl'}>
                        <Badge color={'red'} text={t('ccl')} />
                    </Select.Option>
                )}

                {(paymentStatus === 'rfd') && (
                    <Select.Option key={'rfd'}>
                        <Popover
                            placement="bottom"
                            content={() => {
                                return (
                                    <>
                                        <EnterOutlined
                                            style={{ transform: 'scale(-1, 1)', color: '#BBBBBB', marginRight: 5 }}
                                        />
                                        {formatNb(orderInfo.refundAmount)}
                                    </>
                                )
                            }}
                            visible={!showSelect && showPopover}
                            onMouseEnter={() => setShowPopover(true)}
                            onMouseLeave={() => setShowPopover(false)}
                        >
                            <Badge color={'#BB4DFF'} text={t('refunded')} />
                        </Popover>
                    </Select.Option>
                )}
            </Select>
            <Modal
                title={<DynamicStrings id={'REFUNDS_CREATE_REFUND'} />}
                visible={showModal}
                onOk={mutationRefund.mutate}
                okText={<DynamicStrings id={'REFUNDS_SAVE_REFUND'} />}
                confirmLoading={mutationRefund.isLoading}
                onCancel={() => setShowModal(false)}
                okButtonProps={{ disabled: !refundAmount || !resp || !issue || !actionETA }}
            >
                <Form layout={'vertical'} className="form-wrapper-card">
                    <div className="form-field-wrapper nb-columns-2">
                        <Form.Item
                            label={t('CUSTOMER_SERVICE_RESPONSIBILITY')}
                            className="col-1 size-1"
                        >
                            <Select
                                value={resp}
                                onChange={(value) => setResp(value)}
                                style={{ width: '100%' }}
                            >
                                <Select.Option value="supplier">{t('supplier')}</Select.Option>
                                <Select.Option value="delivery">{t('CUSTOMER_SERVICE_DELIVERY_SERVICE')}</Select.Option>
                                <Select.Option value="customer">{t('customer')}</Select.Option>
                                <Select.Option value="ziko">{t('ziko')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={t('CUSTOMER_SERVICE_ISSUE')}
                            className="col-2 row-1 size-1"
                        >
                            <Select
                                value={issue}
                                onChange={(value) => setIssue(value)}
                                style={{ width: '100%' }}
                            >
                                <Select.Option value="wrong_address">{t('wrong_address')}</Select.Option>
                                <Select.Option value="packaging">{t('packaging')}</Select.Option>
                                <Select.Option value="defrost">{t('defrost')}</Select.Option>
                                <Select.Option value="product_default">{t('product_default')}</Select.Option>
                                <Select.Option value="item_missing">{t('item_missing')}</Select.Option>
                                <Select.Option value="delay">{t('delay')}</Select.Option>
                                <Select.Option value="customer_mistake">{t('customer_mistake')}</Select.Option>
                                <Select.Option value="wrong_time">{t('wrong_time')}</Select.Option>
                                <Select.Option value="wrong_item">{t('wrong_item')}</Select.Option>
                                <Select.Option value="fapiao">{t('fapiao')}</Select.Option>
                                <Select.Option value="others">{t('others')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={t('CUSTOMER_SERVICE_ACTION_ETA')}
                            className="col-1 row-2 size-1"
                        >
                            <DatePicker
                                value={actionETA}
                                format={'YYYY-MM-DD'}
                                allowClear={true}
                                onChange={(e) => {
                                    setActionETA(e)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('REFUNDS_REFUND_AMOUNT')}
                            className="col-2 row-2 size-1"
                        >
                            <InputNumber
                                value={refundAmount}
                                onChange={(e) => {
                                    setRefundAmount(e)
                                }}
                                min={0}
                                max={actualAmount.toFixed(2)}
                                defaultValue={actualAmount.toFixed(2)}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item
                        label={t('CUSTOMER_SERVICE_ISSUE_DETAIL')}
                        className="row-3 size-2"
                    >
                        <Input.TextArea
                            value={issueDetail}
                            onChange={(e) => setIssueDetail(e.target.value)}
                            autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('INVENTORY_COMMENT')}
                        className="row-4 size-2"
                    >
                        <Input.TextArea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}


export const PaymentMethod = ({
    actualAmount,
    id,
    keyRequest
}) => {
    const { t } = useTranslation()
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()

    const mutation = useMutation((values) => EditOrders(...values), {
        onSuccess: (data, variables) => {
            if (
                data.status === 'discontinued' &&
                data.paymentStatus === 'pending'
            ) {
                queryClient.invalidateQueries('getOrders')
            }

            queryClient.setQueryData(keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e.id === data.id
                )

                oldData.list[oldDataIndex].paymentMethod = data.paymentMethod
                return oldData
            })
        },
    })

    return (
        <Select
            bordered={false}
            value={actualAmount}
            onChange={(e) => {
                mutation.mutate([
                    {
                        paymentMethod: e,
                    },
                    token.token,
                    id,
                ])
            }}
        >
            <Select.Option key={'alipay'}>
                {t('Alipay')}
            </Select.Option>
            <Select.Option key={'wechat'}>
                {t('Wechat pay')}
            </Select.Option>
        </Select>
    )
}

export default Status
