/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import {
    Space,
    Badge,
    message,
    Popover,
    Button,
    Menu,
    Dropdown,
    Tooltip,
    Avatar,
} from 'antd'
import {
    DownloadOutlined,
    LoadingOutlined,
    MoreOutlined,
} from '@ant-design/icons'
import i18next from 'i18next'
import moment from 'moment'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { AppContext } from '../context/AppContext'

import { formatNb, formatDate, displayOrderDetails } from '../utils/utils'

import { getOrders, EditOrders, getCommunities } from '../network/API'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import { OrderStatus, PaymentMethod } from '../components/tables/cells/Status'
import DynamicStrings from '../components/DynamicStrings'
import MyPDFDocument from 'src/components/MyPDF'

import QRCode from 'qrcode'
import kitchenqrbg from '../images/kitchenqrbg.png'
import gardenqrbg from '../images/gardenqrbg.png'
import farmqrbg from '../images/farmqrbg.png'
import cellarqrbg from '../images/cellarqrbg.png'
import petqrbg from '../images/petqrbg.png'
import mergeImages from 'merge-images'
import { useTranslation } from 'react-i18next'

const DirectSalesOrder = ({ history }) => {
    const { user } = useContext(AppContext)
    const { t } = useTranslation()

    const commuFilter =
        user.role === 'admin' || user.role === 'super-admin'
            ? {}
            : { community: { $in: user.communities } }

    return (
        <PageWrap title={t('DIRECT_SALES_ALL_CUSTOMER_ORDERS')}>
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getOrders'}
                request={getOrders}
                otherFilters={{
                    // status: { $ne: 'discontinued' },
                    type: { $in: ['direct_sale', 'daily'] },
                    valid: true,
                    ...commuFilter,
                }}
                pageSize={80}
            />
        </PageWrap>
    )
}

const filters = (history) => ({
    fields: [
        {
            type: 'select',
            label: 'ROLES_COMMUNITY',
            key: 'community',
            options: getCommunities,
        },
        {
            type: 'spe',
            label: 'ROLES_NAME',
            key: 'customer.name',
            noRegex: true,
        },
        {
            type: 'text',
            label: 'OFFER_LIVE_ORDER_ID',
            key: 'orderID',
            onCell: () => {
                return {
                    onClick: (e) => e.stopPropagation(),
                }
            },
        },
        // {
        //     type: 'text',
        //     label: 'SERVICES_ORDER_LIST_TRANSACTION_NUMBER',
        //     key: 'transactionID',
        // },
        {
            type: 'text',
            label: 'DIRECT_SALES_DELIVERY_TRACKING_NUMBER',
            key: 'deliveryTrackingID',
        },
        {
            type: 'daterange',
            label: 'ANALYTICS_DATES',
            key: ['dateStart', 'dateEnd'],
        },
    ],
    actions: [
        {
            label: 'DIRECT_SALES_CREATE_DAILY_ORDER',
            isActive: true,
            action: () => history.push('/daily-orders/create'),
        },
    ],
    tabs: {
        key: 'paymentStatus',
        default: '',
        options: [
            { key: '', label: 'OFFERS_ALL' },
            { key: 'pending', label: 'pending' },
            { key: 'paid', label: 'paid' },
            { key: 'ccl', label: 'ccl' },
            { key: 'refunding', label: 'refund' },
            { key: 'rfd', label: 'refunded' },
        ],
    },
})

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        dataIndex: 'orderID',
        key: 'orderID',
    },
    {
        title: <DynamicStrings id="ROLES_NAME" />,
        key: 'customer',
        render: (order) =>
            order?.customer?.id
                ? order.customer.name
                : order.customerDaily?.customerName,
        sorter: true,
    },
    {
        title: <DynamicStrings id="DIRECT_SALES_PHONE_NUMBER" />,
        // dataIndex: 'customer',
        render: (e) => e.customerAddress?.phone,
        key: 'customer',
        sorter: true,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_DATE" />,
        dataIndex: 'orderDate',
        render: (date) => formatDate(date),
        key: 'orderDate',
        sorter: true,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_TIME" />,
        dataIndex: 'orderDate',
        render: (date) => moment(date).format('HH:mm'),
        key: 'orderDate',
        // sorter: true,
    },
    {
        title: <DynamicStrings id="SERVICES_ORDER_LIST_ORDER_DETAILS" />,
        // dataIndex: 'packs',
        render: (e) => {
            const toDisplay = [
                ...e.packs
                    ?.filter((e) => e.amount)
                    ?.map((pack, i) => `${pack.amount} ${pack.shortName}`),
                ...e.singleItems
                    ?.filter((e) => e.amount)
                    ?.map(
                        (item) =>
                            `${item.amount} ${
                                item.shortName ||
                                item?.product?.name[i18next.language]
                            }`
                    ),
            ].join(', ')

            return <Tooltip title={displayOrderDetails(e)}>{toDisplay}</Tooltip>
        },

        key: 'packs',
    },
    // {
    //     title: <DynamicStrings id="SERVICES_ORDER_LIST_TRANSACTION_NUMBER" />,
    //     dataIndex: 'transactionID',
    //     key: 'transactionID',
    // },
    {
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
        dataIndex: 'comment',
        key: 'comment',
    },
    {
        title: <DynamicStrings id="DIRECT_SALES_DELIVERY_TRACKING_NUMBER" />,
        dataIndex: 'deliveryTrackingID',
        key: 'deliveryTrackingID',
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_DATE" />,
        dataIndex: 'deliveryDate',
        // render: (deliveryDate) =>
        //     deliveryDate && moment(deliveryDate).isValid()
        //         ? formatDate(deliveryDate)
        //         : deliveryDate,
        render: (deliveryDate) => {
            const dateFormat = 'YYYY-MM-DDTHH:mm:SS.sssZ'

            if (
                deliveryDate &&
                moment(deliveryDate, dateFormat, true).isValid()
            ) {
                return formatDate(deliveryDate)
            } else {
                return deliveryDate
            }
        },
        key: 'deliveryDate',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_DELIVERY_FEE" suffix={'(¥)'} />,
        dataIndex: 'deliveryFee',
        key: 'deliveryFee',
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_VOUCHER" />,
        dataIndex: 'vouchers',
        render: (vouchers) =>
            vouchers.length
                ? vouchers?.map(
                      (vouchers, i) => (i === 0 ? '' : ', ') + vouchers.amount
                  )
                : '0',
        key: 'vouchers',
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_TOTAL" suffix="(¥)" />,
        render: ({ actualAmount, id }) => (
            <PaymentMethod
                actualAmount={formatNb(actualAmount)}
                id={id}
                keyRequest={keyRequest}
            />
        ),
        onCell: (record, rowIndex) => {
            if (record.paymentMethod === 'alipay') {
                return {
                    className: 'is-alipay',
                }
            }
        },
        key: 'actualAmount',
        sorter: true,
    },
    {
        // title: 'Paid',
        // dataIndex: 'paymentStatus',
        // render: (paymentStatus) => (
        //     <Space
        //         className={`centerMe ${
        //             paymentStatus === 'paid' ? 'isBlue' : ''
        //         }`}
        //     >
        //         <Checkbox checked={paymentStatus === 'paid'} />
        //     </Space>
        // ),
        // key: 'paymentStatus',

        title: <DynamicStrings id="OFFERS_STATUS" />,
        render: ({
            status,
            paymentStatus,
            trackingStatus,
            id,
            actualAmount,
            ...order
        }) => (
            <OrderStatus
                status={status}
                paymentStatus={paymentStatus}
                trackingStatus={trackingStatus}
                id={id}
                keyRequest={keyRequest}
                actualAmount={actualAmount}
                order={order}
            />
        ),
        key: 'paymentStatus',
        sorter: true,
    },
    {
        title: <DynamicStrings id="DIRECT_SALES_DELIVERED" />,
        dataIndex: 'trackingStatus',
        render: (e) => {
            const color = {
                delivered: 'green',
                on_the_way: 'blue',
                process: 'orange',
                prepared: 'yellow',
                delayed: 'red',
            }

            return <Badge color={color[e]} text={<DynamicStrings id={e} />} />
        },
        key: '',
    },
    {
        width: 50,
        render: (e) => {
            const menu = (
                <Menu>
                    <Menu.Item key="0">
                        <PDFDownloadLink
                            document={
                                <MyPDFDocument
                                    noSupplier
                                    lang="en"
                                    order={e}
                                    type={'direct'}
                                />
                            }
                            fileName={`${e.orderID}.pdf`}
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? (
                                    <DynamicStrings
                                        id={'DIRECT_SALES_LOADING_PDF'}
                                    />
                                ) : (
                                    <DynamicStrings
                                        id={'DIRECT_SALES_DOWNLOAD_PDF_EN'}
                                    />
                                )
                            }
                        </PDFDownloadLink>
                    </Menu.Item>

                    <Menu.Item
                        key="1"
                        disabled={
                            e.paymentStatus === 'rfd' ||
                            !!e.refundVouchers?.length ||
                            !e.customer
                        }
                    >
                        <a
                            onClick={() =>
                                goTo({
                                    pathname: '/vouchers/create',
                                    customer: e.customer,
                                    refundOrder: e,
                                })
                            }
                        >
                            <DynamicStrings
                                id={
                                    !!e.refundVouchers?.length
                                        ? 'SERVICES_ORDER_GOT_VOUCHER'
                                        : 'SERVICES_ORDER_LIST_CREATE_VOUCHER'
                                }
                            />
                        </a>
                    </Menu.Item>

                    {e.type === 'daily' && (
                        <Menu.Item key="1">
                            <a onClick={() => goTo('/daily-orders/' + e.id)}>
                                <DynamicStrings id="ROLES_EDIT" />
                            </a>
                        </Menu.Item>
                    )}
                    {e.type !== 'daily' && e.channel === 'wechat_group' && (
                        <Menu.Item key="1">
                            <a
                                onClick={() =>
                                    goTo(`/offers/${e.offer?.id}/orders`)
                                }
                            >
                                <DynamicStrings id="ROLES_EDIT" />
                            </a>
                        </Menu.Item>
                    )}
                </Menu>
            )

            return (
                <Space className="centerMe">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                        >
                            <MoreOutlined />
                        </a>
                    </Dropdown>
                </Space>
            )
        },
    },
    {
        width: 36,
        title: 'QR',
        key: '18',
        render: (order) => {
            if (order.type == 'daily') {
                return (
                    <PopUpQR
                        url={`${process.env.REACT_APP_PAYMENT_URL}/${order.id}`}
                        community={order.community?.id}
                    />
                )
            } else {
                return null
            }
        },
    },
]

const PopUpQR = ({ url, community }) => {
    const [qr, setQr] = useState()
    const [img, setImg] = useState()
    var opts = {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 1,
        width: 79,
        qzone: 4,
        margin: 0,
        color: {
            dark: '#000',
            light: '#fff',
        },
    }

    useEffect(() => {
        QRCode.toDataURL(url, opts, function (err, uri) {
            if (err) throw err

            setQr(uri)
        })

        let srcIMG
        switch (community) {
            case '60541eab7b87f1126ecf53e1':
                srcIMG = kitchenqrbg
                break
            case '60541eb37b87f1126ecf53e2':
                srcIMG = farmqrbg
                break
            case '60541eb87b87f1126ecf53e3':
                srcIMG = cellarqrbg
                break

            default:
                srcIMG = petqrbg
                break
        }

        if (srcIMG && qr) {
            mergeImages(
                [
                    {
                        src: srcIMG,
                    },

                    {
                        src: qr,
                        x: 155,
                        y: 438,
                    },
                ],
                { format: 'image/jpeg' }
            ).then((b64) => setImg(b64))
        }
    })

    // return fee
    return img ? <Avatar shape="square" src={img} /> : null
}

export default DirectSalesOrder
