import { useContext } from 'react'
import { Button, Space, Descriptions, Table, Typography } from 'antd'

import { formatDate, formatNb, keepTwoDecimals } from '../../utils/utils'
import { getSinglePurchaseOrderDetailSort } from '../../network/PurchaseOrders'

import PageWrap from '../../components/layout/PageWrap'
import TableBlock from '../../components/tables/TableBlock'
import { useQuery } from 'react-query'
import { AppContext } from '../../context/AppContext'
import { useParams } from 'react-router-dom'
import DynamicStrings from '../../components/DynamicStrings'

const { Text } = Typography

const FinanceDetails = ({ goTo, history }) => {
    const { token } = useContext(AppContext)
    const poId = useParams().id

    const purchaseOrderDetails = useQuery(
        [
            'getSinglePurchaseOrderDetailSort',
            {
                poId: poId,
                token: token.token,
            },
        ],
        getSinglePurchaseOrderDetailSort,
        {
            refetchOnWindowFocus: false,
        }
    )

    const summary = () => {
        let totalQuantity = 0
        let totalVAT = 0
        let subtotal = 0

        purchaseOrderDetails.data?.purchaseOrderItems?.forEach(
            ({ quantity, unitPrice, vatPercentage }) => {
                totalQuantity = totalQuantity + quantity

                const totWO = unitPrice / ((vatPercentage + 100) / 100)
                const total = unitPrice
                const vat = total - totWO

                totalVAT = totalVAT + vat * quantity
                subtotal = subtotal + total * quantity
            }
        )
        totalVAT = formatNb(totalVAT)
        subtotal = keepTwoDecimals(subtotal)

        return (
            <>
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        {<DynamicStrings id="OFFER_LIVE_TOTAL" />}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                        <Text>{totalQuantity}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                        <Text>{totalVAT}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                        <Text>{subtotal}</Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </>
        )
    }

    return (
        <PageWrap
            goBack='/direct-sales'
            title="PURCHASE_ORDER_DETAIL_PURCHASE_ORDER_DETAIL"
        >
            <Space
                direction="vertical"
                size={0}
                style={{
                    display: 'flex',
                    padding: '0px 24px',
                    backgroundColor: '#fff',
                }}
            >
                <Space
                    style={{
                        display: 'flex',
                        paddingBottom: 15,
                        justifyContent: 'space-between',
                    }}
                >
                    {purchaseOrderDetails.data && (
                        <Space>
                            <Descriptions layout="vertical">
                                <Descriptions.Item
                                    label={
                                        <DynamicStrings id="SUPPLIERS_SUPPLIER_NAME" />
                                    }
                                >
                                    {purchaseOrderDetails.data.supplier?.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <DynamicStrings id="SERVICES_ORDER_LIST_DATE" />
                                    }
                                >
                                    {formatDate(
                                        purchaseOrderDetails.data.date,
                                        'long'
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Space>
                    )}
                    <Space>
                        <Button
                            disabled={false}
                            onClick={() =>
                                // goTo.history.push(`/finance/${poId}/edit`)
                                history.push(`/finance/${poId}/edit`)
                            }
                            type="primary"
                        >
                            {<DynamicStrings id="ROLES_EDIT" />}
                        </Button>
                    </Space>
                </Space>
            </Space>

            <TableBlock
                columns={columns}
                // filters={filters}
                name={'getSinglePurchaseOrderDetailSort'}
                request={getSinglePurchaseOrderDetailSort}
                summary={summary}
                otherParams={{ poId: poId }}
                pageSize={80}
            />
        </PageWrap>
    )
}

const columns = (goTo) => [
    {
        title: <DynamicStrings id="INVENTORY_PRODUCT_NAME" />,
        dataIndex: 'product',
        render: (product) => product?.name.en,
        key: 'productName',
        sorter: true,
    },
    {
        title: <DynamicStrings id="PRODUCT_LIST_PRODUCT_ID" />,
        dataIndex: 'product',
        render: (product) => product?.productId,
        key: 'product',
    },
    {
        title: <DynamicStrings id="CREATE_OFFER_QUANTITY" />,
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: true,
    },
    {
        title: (
            <DynamicStrings id="PURCHASE_ORDER_DETAIL_UNIT_PRICE_EXCL_VAT" />
        ),
        // render: ({ unitPrice }) => formatNb(unitPrice),
        render: ({ unitPrice, vatPercentage }) => {
            const totalWOT = unitPrice / ((vatPercentage + 100) / 100)

            return keepTwoDecimals(totalWOT) || 0
        },
        key: 'unitPrice',
        sorter: true,
    },
    {
        title: (
            <DynamicStrings id="PURCHASE_ORDER_DETAIL_UNIT_PRICE_INCL_VAT" />
        ),
        // render: ({unitPrice, vatPercentage}) => vatPercentage ? (unitPrice * (1 + vatPercentage / 100)) : 0,
        render: ({ unitPrice }) => keepTwoDecimals(unitPrice) || 0,
        key: 'unitPriceInclVAT',
        sorter: true,
    },
    {
        title: <DynamicStrings id="SUPPLIERS_VAT" suffix="%" />,
        dataIndex: 'vatPercentage',
        key: 'vatPercentage',
        sorter: true,
    },
    {
        title: (
            <DynamicStrings id="PURCHASE_ORDER_DETAIL_SUBTOTAL" suffix="¥" />
        ),
        // render: ({unitPrice, quantity}) =>  unitPrice ? (unitPrice * quantity) : 0,
        render: ({ unitPrice, quantity }) => {
            var subtotal = 0
            if (unitPrice) {
                subtotal = unitPrice * quantity
            }
            // return formatNb(subtotal)
            return keepTwoDecimals(subtotal) || 0
        },
        key: 'subtotal',
        sorter: true,
    },
]

export default FinanceDetails
