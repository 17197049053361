import { INSTANCE, INSTANCELIST } from './API'

export const getSuppliers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/suppliers',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            // .then(resolve)
            .then((results) => {
                results?.list.sort(function (a, b) {
                    if (a?.name.toLowerCase() > b?.name.toLowerCase()) return 1;
                    if (a?.name.toLowerCase() < b?.name.toLowerCase()) return -1;
                    return 0;
                });
                resolve(results)
            })
            .catch(reject)
    })

export const getDirectSalesSuppliers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/suppliers',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                filter: { type: 'direct_sales' },
            },
        })
            // .then(resolve)
            .then((results) => {
                results?.list.sort(function (a, b) {
                    if (a?.name.toLowerCase() > b?.name.toLowerCase()) return 1;
                    if (a?.name.toLowerCase() < b?.name.toLowerCase()) return -1;
                    return 0;
                });
                resolve(results)
            })
            .catch(reject)
    })

export const getServicesSuppliers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/suppliers',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                filter: { type: 'services' },
            },
        })
            // .then(resolve)
            .then((results) => {
                results?.list.sort(function (a, b) {
                    if (a?.name.toLowerCase() > b?.name.toLowerCase()) return 1;
                    if (a?.name.toLowerCase() < b?.name.toLowerCase()) return -1;
                    return 0;
                });
                resolve(results)
            })
            .catch(reject)
    })

export const getDirectSuppliers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/suppliers',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                filter: { type: 'direct_sales' },
            },
        })
            // .then(resolve)
            .then((results) => {
                results?.list.sort(function (a, b) {
                    if (a?.name.toLowerCase() > b?.name.toLowerCase()) return 1;
                    if (a?.name.toLowerCase() < b?.name.toLowerCase()) return -1;
                    return 0;
                });
                resolve(results)
            })
            .catch(reject)
    })

export const getSuppliersDetailed = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, token } = queryKey[1]

        INSTANCELIST({
            method: 'get',
            url: '/suppliers/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: JSON.stringify(sort),
                range: JSON.stringify(range),
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const CreateSupplier = (form, token) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/suppliers',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSingleSupplier = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { supplierId, token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/suppliers/${supplierId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const EditSupplier = (form, token, supplierId) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'put',
            url: `/suppliers/${supplierId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify(form),
        })
            .then(resolve)
            .catch(reject)
    })

export const getSuppliersList = (token) =>
    new Promise((resolve, reject) => {
        INSTANCELIST({
            method: 'get',
            url: '/suppliers',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })