import { Spin } from 'antd'

const Loader = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 200,
        }}
    >
        <Spin size="large" />
    </div>
)

export default Loader
