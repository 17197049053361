import { useMemo, useContext } from 'react'
import { Space, Typography, Table, Card, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { Column, Pie, BidirectionalBar } from '@ant-design/charts'
import { useQuery } from 'react-query'
import i18next from 'i18next'

import { AppContext } from '../../context/AppContext'
import { getOfferAnalytics } from '../../network/Offers'
import { getCommunities } from '../../network/API'

import StatCard from '../../components/StatCard'
import { formatNb, noInfinite, downloadCSV } from 'src/utils/utils'
import { useTranslation } from 'react-i18next'

const { Title } = Typography

const Analytics = ({ offer, wxOrders, mpOrders }) => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const communities = useQuery(
        ['getCommunitySelect', { token: token.token }],
        getCommunities
    )

    const analytics = useQuery(
        [
            'getOfferAnalytics',
            {
                offerId: offer?.id,
                token: token.token,
            },
        ],
        getOfferAnalytics,
        {
            enabled: !!offer?.id,
        }
    )

    const title = {
        marginTop: 15,
    }

    const stats = useMemo(() => {
        if (!offer || !wxOrders || !mpOrders) return {}
        let totalMP = 0
        let totalWX = 0
        let totalWoDelivery = 0
        let totalOrders = 0
        let newCustomers = 0
        let grossMarging = 0

        let ratio_data = []
        let longestPack = []
        let groupValue = []
        // let valueData = []
        let valueDataMP = []
        let valueDataGroup = []
        if (offer?.channel === 'all') {
            let oldMP = []
            if (offer?.miniprogram?.packs?.length) {
                oldMP.concat(offer?.miniprogram?.packs)
            }
            if (offer?.miniprogram?.items?.length) {
                oldMP.concat(offer?.miniprogram?.items)
            }

            let oldWX = []
            if (offer?.wechatGroup?.packs?.length) {
                oldMP.concat(offer?.wechatGroup?.packs)
            }
            if (offer?.wechatGroup?.items?.length) {
                oldMP.concat(offer?.wechatGroup?.items)
            }

            longestPack = oldMP.length > oldWX.length ? oldMP : oldWX
        } else if (offer?.channel === 'miniprogram') {
            longestPack = []
            if (offer?.miniprogram?.packs?.length) {
                longestPack.concat(offer?.miniprogram?.packs)
            }
            if (offer?.miniprogram?.items?.length) {
                longestPack.concat(offer?.miniprogram?.items)
            }
        } else {
            let longestPack = []
            if (offer?.wechatGroup?.packs?.length) {
                longestPack.concat(offer?.wechatGroup?.packs)
            }
            if (offer?.wechatGroup?.items?.length) {
                longestPack.concat(offer?.wechatGroup?.items)
            }
        }
        for (const pack of longestPack) {
            ratio_data.push({
                shortName: pack.shortName,
                name: t('miniprogram'),
                amount: 0,
            })
            ratio_data.push({
                shortName: pack.shortName,
                name: t('wechat_group'),
                amount: 0,
            })
        }

        let citiesData = [
            {
                type: 'others',
                value: 0,
            },
        ]

        for (const order of wxOrders) {
            if (
                !(
                    order.status === 'discontinued' &&
                    order.paymentStatus === 'pending'
                )
            ) {
                totalOrders++

                let totalDiscount = 0
                for (const gift of order.gifts) {
                    if (gift.type === 'discount')
                        totalDiscount += gift.discountAmount
                    if (totalDiscount > 100) break
                }
                if (totalDiscount > 100) totalDiscount = 100

                totalWX += order.actualAmount
                totalWoDelivery +=
                    order.actualAmount -
                    order.deliveryFee * (1 - totalDiscount / 100)
                if (!order.customer) newCustomers++

                if (order.customerAddress.city) {
                    let cityId = citiesData.findIndex(
                        (e) =>
                            e.type.toLowerCase().trim() ===
                            order.customerAddress.city.toLowerCase().trim()
                    )

                    if (cityId >= 0) {
                        citiesData[cityId].value += order.actualAmount
                    } else {
                        citiesData.push({
                            type: order.customerAddress.city.trim(),
                            value: order.actualAmount,
                        })
                    }
                } else {
                    citiesData[0].value += order.actualAmount
                }

                if (order?.packs) {
                    for (const pack of order?.packs) {
                        const packIndex = ratio_data.findIndex(
                            (e) =>
                                e.shortName === pack.shortName &&
                                e.name === 'Group'
                        )
                        const priceAmount =
                            offer?.wechatGroup?.packs?.find(
                                (e) => e._id === pack._id
                            )?.price * pack.amount

                        if (packIndex >= 0) {
                            ratio_data[packIndex].amount += priceAmount
                        } else {
                            ratio_data.push({
                                shortName: pack.shortName,
                                name: t('wechat_group'),
                                amount: priceAmount,
                            })
                        }

                        const packIndexVolume = valueDataGroup.findIndex(
                            (e) => e.shortName === pack.shortName
                        )

                        if (packIndexVolume !== -1) {
                            valueDataGroup[packIndexVolume].Volume +=
                                pack.amount
                            valueDataGroup[packIndexVolume].Value += priceAmount
                        } else {
                            valueDataGroup.push({
                                shortName: pack.shortName,
                                Volume: pack.amount,
                                Value: priceAmount,
                            })
                        }
                    }
                }
                if (order?.singleItems) {
                    for (const item of order?.singleItems) {
                        grossMarging +=
                            (item.price -
                                offer?.wechatGroup?.items.find(
                                    (e) => e._id === item._id
                                )?.product.price || 0) * item.amount

                        const packIndex = ratio_data.findIndex(
                            (e) =>
                                e.shortName === item.shortName &&
                                e.name === 'Group'
                        )
                        const priceAmount =
                            offer?.wechatGroup?.items?.find(
                                (e) => e._id === item._id
                            )?.price * item.amount

                        if (packIndex >= 0) {
                            ratio_data[packIndex].amount += priceAmount
                        } else {
                            ratio_data.push({
                                shortName: item.shortName,
                                name: t('wechat_group'),
                                amount: priceAmount,
                            })
                        }

                        const packIndexVolume = valueDataGroup.findIndex(
                            (e) => e.shortName === item.shortName
                        )

                        if (packIndexVolume !== -1) {
                            valueDataGroup[packIndexVolume].Volume +=
                                item.amount
                            valueDataGroup[packIndexVolume].Value += priceAmount
                        } else {
                            valueDataGroup.push({
                                shortName: item.shortName,
                                Volume: item.amount,
                                Value: priceAmount,
                            })
                        }
                    }
                }

                if (order.groupNumber || order.groupNumber === 0) {
                    const grpId = groupValue.findIndex(
                        (e) => e.group === order.groupNumber.toString()
                    )

                    if (grpId !== -1) {
                        groupValue[grpId].Value += order.actualAmount
                        groupValue[grpId].Volume += 1
                    } else {
                        groupValue.push({
                            group: order.groupNumber.toString(),
                            Value: order.actualAmount,
                            Volume: 1,
                        })
                    }
                }
            }
        }
        groupValue.sort((firstEl, secondEl) =>
            parseInt(firstEl.group) < parseInt(secondEl.group) ? -1 : 1
        )

        for (const order of mpOrders) {
            if (
                !(
                    order.status === 'discontinued' &&
                    order.paymentStatus === 'pending'
                )
            ) {
                totalOrders++

                let totalDiscount = 0
                for (const gift of order.gifts) {
                    if (gift.type === 'discount')
                        totalDiscount += gift.discountAmount
                    if (totalDiscount > 100) break
                }
                if (totalDiscount > 100) totalDiscount = 100

                totalMP += order.actualAmount
                totalWoDelivery +=
                    order.actualAmount -
                    order.deliveryFee * (1 - totalDiscount / 100)
                if (!order.customer) newCustomers++

                // let totalVolume = 0

                if (order.customerAddress.city) {
                    let cityId = citiesData.findIndex(
                        (e) =>
                            e.type.toLowerCase().trim() ===
                            order.customerAddress.city.toLowerCase().trim()
                    )

                    if (cityId >= 0) {
                        citiesData[cityId].value += order.actualAmount
                    } else {
                        citiesData.push({
                            type: order.customerAddress.city.trim(),
                            value: order.actualAmount,
                        })
                    }
                } else {
                    citiesData[0].value += order.actualAmount
                }

                if (order?.packs) {
                    for (const pack of order?.packs) {
                        // totalVolume += pack.amount

                        const packIndex = ratio_data.findIndex(
                            (e) =>
                                e.shortName === pack.shortName &&
                                e.name === 'Miniprogram'
                        )
                        const priceAmount =
                            offer?.miniprogram?.packs?.find(
                                (e) => e._id === pack._id
                            )?.price * pack.amount

                        if (packIndex >= 0) {
                            ratio_data[packIndex].amount += priceAmount
                        } else {
                            ratio_data.push({
                                shortName: pack.shortName,
                                name: t('miniprogram'),
                                amount: priceAmount,
                            })
                        }

                        // const packIndexVolume = valueDataMP.findIndex(
                        //     (e) => e.shortName === pack.shortName
                        // )

                        // if (packIndexVolume !== -1) {
                        //     valueDataMP[packIndexVolume].Volume += pack.amount
                        //     valueDataMP[packIndexVolume].Value += priceAmount
                        // } else {
                        //     valueDataMP.push({
                        //         shortName: pack.shortName,
                        //         Volume: pack.amount,
                        //         Value: priceAmount,
                        //     })
                        // }
                    }
                }
                if (order?.singleItems) {
                    for (const item of order?.singleItems) {
                        grossMarging +=
                            (item.price -
                                offer?.miniprogram?.items.find(
                                    (e) => e._id === item._id
                                )?.product.price || 0) * item.amount

                        const packIndex = ratio_data.findIndex(
                            (e) =>
                                e.shortName === item.shortName &&
                                e.name === 'Miniprogram'
                        )
                        const priceAmount =
                            offer?.miniprogram?.items?.find(
                                (e) => e._id === item._id
                            )?.price * item.amount

                        if (packIndex >= 0) {
                            ratio_data[packIndex].amount += priceAmount
                        } else {
                            ratio_data.push({
                                shortName: item.shortName,
                                name: t('miniprogram'),
                                amount: priceAmount,
                            })
                        }

                        // const packIndexVolume = valueDataMP.findIndex(
                        //     (e) => e.shortName === item.shortName
                        // )

                        // if (packIndexVolume !== -1) {
                        //     valueDataMP[packIndexVolume].Volume += item.amount
                        //     valueDataMP[packIndexVolume].Value += priceAmount
                        // } else {
                        //     valueDataMP.push({
                        //         shortName: item.shortName,
                        //         Volume: item.amount,
                        //         Value: priceAmount,
                        //     })
                        // }
                    }
                }

                // if (order.groupNumber || order.groupNumber === 0) {
                //     const grpId = groupValue.findIndex(
                //         (e) => e.group === order.groupNumber
                //     )

                //     if (grpId >= 0) {
                //         groupValue[grpId].value += order.actualAmount
                //         groupValue[grpId].volume += totalVolume
                //     } else {
                //         groupValue.push({
                //             group: order.groupNumber.toString(),
                //             Value: order.actualAmount,
                //             Volume: totalVolume,
                //         })
                //     }
                // }
            }
        }

        let averageSpend = (totalMP + totalWX) / totalOrders

        if (citiesData[0].value === 0) {
            citiesData.shift()
        }
        citiesData.sort((firstEl, secondEl) =>
            firstEl.value > secondEl.value ? -1 : 1
        )

        const products = []
        // const packsData = []
        const packsDataMP = []
        if (offer?.miniprogram) {
            for (const pack of offer?.miniprogram?.packs) {
                const pId = products.findIndex(
                    (e) => e.shortName === pack.shortName
                )
                if (pId >= 0) {
                    products[pId].descMP = pack.products
                        .map(
                            (e, i) =>
                                `${e.quantity ? e.quantity + 'x' : ''}${
                                    e.weight ? e.weight + 'g' : ''
                                } ${
                                    e.product?.name
                                        ? e.product?.name[i18next.language]
                                        : ''
                                }`
                        )
                        .join(', ')
                } else {
                    products.push({
                        shortName: pack.shortName,
                        descMP: pack.products
                            .map(
                                (e, i) =>
                                    `${e.quantity ? e.quantity + 'x' : ''}${
                                        e.weight ? e.weight + 'g' : ''
                                    } ${
                                        e.product?.name
                                            ? e.product?.name[i18next.language]
                                            : ''
                                    }`
                            )
                            .join(', '),
                    })
                }
                packsDataMP.push({
                    shortName: pack.shortName,
                    name: t('OFFER_ANALYTICS_POTENTIAL'),
                    amount: pack.stock * pack.price,
                })
                packsDataMP.push({
                    shortName: pack.shortName,
                    name: t('OFFER_ANALYTICS_ACTUAL'),
                    amount: (pack.stock - pack.actualStock) * pack.price,
                })
                valueDataMP.push({
                    shortName: pack.shortName,
                    Volume: pack.stock - pack.actualStock,
                    Value: (pack.stock - pack.actualStock) * pack.price,
                })
            }
            for (const item of offer?.miniprogram?.items) {
                const pId = products.findIndex(
                    (e) => e.shortName === item.shortName
                )
                if (pId >= 0) {
                    products[pId].descMP = `${
                        item?.quantity ? item?.quantity + 'x' : ''
                    }${item.weight ? item.weight + 'g' : ''} ${
                        item.product?.name
                            ? item.product?.name[i18next.language]
                            : ''
                    }`
                } else {
                    products.push({
                        shortName: item.shortName,
                        descMP: `${item?.quantity ? item?.quantity + 'x' : ''}${
                            item.weight ? item.weight + 'g' : ''
                        } ${
                            item.product?.name
                                ? item.product?.name[i18next.language]
                                : ''
                        }`,
                    })
                }
                packsDataMP.push({
                    shortName: item.shortName,
                    name: t('OFFER_ANALYTICS_POTENTIAL'),
                    amount: item.stock * item.price,
                })
                packsDataMP.push({
                    shortName: item.shortName,
                    name: t('OFFER_ANALYTICS_ACTUAL'),
                    amount: (item.stock - item.actualStock) * item.price,
                })
                valueDataMP.push({
                    shortName: item.shortName,
                    Volume: item.stock - item.actualStock,
                    Value: (item.stock - item.actualStock) * item.price,
                })
            }
        }

        const packsDataGroup = []
        if (offer?.wechatGroup) {
            for (const pack of offer?.wechatGroup?.packs) {
                const pId = products.findIndex(
                    (e) => e.shortName === pack.shortName
                )

                if (pId >= 0) {
                    products[pId].descWX = pack.products
                        .map(
                            (e, i) =>
                                `${e.quantity ? e.quantity + 'x' : ''}${
                                    e.weight ? e.weight + 'g' : ''
                                } ${
                                    e.product?.name
                                        ? e.product?.name[i18next.language]
                                        : ''
                                }`
                        )
                        .join(', ')
                } else {
                    products.push({
                        shortName: pack.shortName,
                        descWX: pack.products
                            .map(
                                (e, i) =>
                                    `${e.quantity ? e.quantity + 'x' : ''}${
                                        e.weight ? e.weight + 'g' : ''
                                    } ${
                                        e.product?.name
                                            ? e.product?.name[i18next.language]
                                            : ''
                                    }`
                            )
                            .join(', '),
                    })
                }
                packsDataGroup.push({
                    shortName: pack.shortName,
                    name: t('OFFER_ANALYTICS_POTENTIAL'),
                    amount: pack.stock * pack.price,
                })
                packsDataGroup.push({
                    shortName: pack.shortName,
                    name: t('OFFER_ANALYTICS_ACTUAL'),
                    amount: (pack.stock - pack.actualStock) * pack.price,
                })
                valueDataGroup.push({
                    shortName: pack.shortName,
                    Volume: pack.stock - pack.actualStock,
                    Value: (pack.stock - pack.actualStock) * pack.price,
                })
            }
            for (const item of offer?.wechatGroup?.items) {
                const pId = products.findIndex(
                    (e) => e.shortName === item.shortName
                )
                if (pId >= 0) {
                    products[pId].descWX = `${
                        item?.quantity ? item?.quantity + 'x' : ''
                    }${item.weight ? item.weight + 'g' : ''} ${
                        item.product?.name
                            ? item.product?.name[i18next.language]
                            : ''
                    }`
                } else {
                    products.push({
                        shortName: item.shortName,
                        descWX: `${item?.quantity ? item?.quantity + 'x' : ''}${
                            item.weight ? item.weight + 'g' : ''
                        } ${
                            item.product?.name
                                ? item.product?.name[i18next.language]
                                : ''
                        }`,
                    })
                }
                packsDataGroup.push({
                    shortName: item.shortName,
                    name: t('OFFER_ANALYTICS_POTENTIAL'),
                    amount: item.stock * item.price,
                })
                packsDataGroup.push({
                    shortName: item.shortName,
                    name: t('OFFER_ANALYTICS_ACTUAL'),
                    amount: (item.stock - item.actualStock) * item.price,
                })
                valueDataGroup.push({
                    shortName: item.shortName,
                    Volume: item.stock - item.actualStock,
                    Value: (item.stock - item.actualStock) * item.price,
                })
            }
        }

        valueDataMP = valueDataMP.sort(
            (a, b) =>
                a.shortName.charCodeAt(0) -
                97 -
                (b.shortName.charCodeAt(0) - 97)
        )

        valueDataGroup = valueDataGroup.sort(
            (a, b) =>
                a.shortName.charCodeAt(0) -
                97 -
                (b.shortName.charCodeAt(0) - 97)
        )

        ratio_data = ratio_data.sort(
            (a, b) =>
                a.shortName.charCodeAt(0) -
                97 -
                (b.shortName.charCodeAt(0) - 97)
        )

        return {
            total: totalMP + totalWX,
            grossMarging,
            totalMP,
            totalWX,
            totalWoDelivery,
            averageSpend,
            totalOrders,
            newCustomers,
            products,
            // packsData,
            packsDataMP,
            packsDataGroup,
            // valueData,
            valueDataMP,
            valueDataGroup,
            ratioData: ratio_data,
            groupValue,
            channelRatio: [
                {
                    type: t('miniprogram'),
                    value: totalMP,
                },
                {
                    type: t('wechat_group'),
                    value: totalWX,
                },
            ],
            citiesData,
        }
    }, [offer, wxOrders, mpOrders])

    var configPie = {
        appendPadding: 10,
        data: [
            {
                type: '分类一',
                value: 27,
            },
            {
                type: '分类二',
                value: 25,
            },
        ],
        color: ['#1890FF', '#FFA940', '#52C41A', '#13C2C2', '#ccc'],
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: false,
        interactions: [{ type: 'element-active' }],
    }

    var config = {
        isGroup: true,
        xField: 'shortName',
        color: ['#1890FF', '#EB2F96'],
        dodgePadding: 0,
        intervalPadding: 20,

        maxColumnWidth: 60,
        label: false,
        legend: {
            layout: 'vertical',
            position: 'top',
        },
    }

    return (
        <Space direction="vertical" style={{ padding: 24, width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Title style={title} level={4}>
                    {t('ANALYTICS_SALES')}
                </Title>
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                        generateCSV(
                            stats,
                            offer,
                            analytics?.data,
                            communities?.data?.list?.find(
                                (c) => c.name.toLowerCase() === 'cellar'
                            )?.id
                        )
                    }
                    disabled={analytics.isLoading}
                >
                    {t('DIRECT_SALES_DOWNLOAD')}
                </Button>
            </div>
            <Space direction="vertical">
                <Space>
                    <StatCard
                        title={t('OFFER_ANALYTICS_TOTAL_SALES')}
                        value={
                            stats?.total ? '¥' + formatNb(stats?.total) : '-'
                        }
                        subtitle={
                            analytics?.data?.ghostStats?.orderTotalAmount
                                ?.length
                                ? t('ANALYTICS_LAST_TIME_RMB') +
                                  formatNb(
                                      analytics.data.ghostStats
                                          .orderTotalAmount[0].totalAmount
                                  )
                                : null
                        }
                    />
                    <StatCard
                        title={t('OFFER_LIVE_TOTAL_SALES_DELIVERY')}
                        value={
                            stats?.totalWoDelivery
                                ? '¥' + formatNb(stats?.totalWoDelivery)
                                : '-'
                        }
                        subtitle={
                            analytics?.data?.ghostStats?.orderTotalAmount
                                ?.length
                                ? t('ANALYTICS_LAST_TIME_RMB') +
                                  formatNb(
                                      analytics.data.ghostStats
                                          .orderTotalAmount[0].totalAmount -
                                          analytics.data.ghostStats
                                              .orderTotalAmount[0]
                                              .totalAmountDelivery
                                  )
                                : null
                        }
                    />
                    <StatCard
                        title={t('OFFER_ANALYTICS_AVERAGE_SPENDING')}
                        value={
                            stats?.averageSpend
                                ? '¥' + formatNb(stats?.averageSpend)
                                : '-'
                        }
                        subtitle={
                            analytics?.data?.ghostStats?.orderTotalAmount
                                ?.length
                                ? t('ANALYTICS_LAST_TIME_RMB') +
                                  formatNb(
                                      analytics.data.ghostStats
                                          .orderTotalAmount[0].averageSpending
                                  )
                                : null
                        }
                    />
                    <StatCard
                        title={t('ANALYTICS_TOTAL_ZIKOLAND_SERVICE_FEE')}
                        value={
                            stats?.totalWoDelivery && offer?.commissionRate
                                ? '¥' +
                                  formatNb(
                                      stats?.totalWoDelivery *
                                          (offer?.commissionRate / 100)
                                  )
                                : '-'
                        }
                        subtitle={
                            analytics?.data?.ghostStats?.offer
                                ?.commissionRate &&
                            analytics?.data?.ghostStats?.orderTotalAmount
                                ?.length
                                ? t('ANALYTICS_LAST_TIME_RMB') +
                                  formatNb(
                                      (analytics.data.ghostStats
                                          .orderTotalAmount[0].totalAmount -
                                          analytics.data.ghostStats
                                              .orderTotalAmount[0]
                                              .totalAmountDelivery) *
                                          (analytics?.data?.ghostStats?.offer
                                              ?.commissionRate /
                                              100)
                                  )
                                : null
                        }
                    />
                    <StatCard
                        title={t('ANALYTICS_TOTAL_SUPPLIER_SHARE')}
                        value={
                            stats?.totalWoDelivery && offer?.commissionRate
                                ? '¥' +
                                  formatNb(
                                      stats?.total -
                                          stats?.totalWoDelivery *
                                              (offer?.commissionRate / 100)
                                  )
                                : '-'
                        }
                        //Total sales (without delivery fee) + sum delivery fee - Zikoland com amount.
                        //stats?.total -
                        subtitle={
                            analytics?.data?.ghostStats?.orderTotalAmount
                                ?.length &&
                            analytics?.data.ghostStats?.orderTotalAmount
                                ?.length &&
                            analytics?.data?.ghostStats?.offer?.commissionRate
                                ? t('ANALYTICS_LAST_TIME_RMB') +
                                  formatNb(
                                      analytics.data.ghostStats
                                          .orderTotalAmount[0].totalAmount -
                                          (analytics.data.ghostStats
                                              .orderTotalAmount[0].totalAmount -
                                              analytics.data.ghostStats
                                                  .orderTotalAmount[0]
                                                  .totalAmountDelivery) *
                                              (analytics?.data?.ghostStats
                                                  ?.offer?.commissionRate /
                                                  100)
                                  )
                                : null
                        }
                    />
                </Space>
                {offer?.community ===
                    communities?.data?.list?.find(
                        (c) => c.name.toLowerCase() === 'cellar'
                    )?.id && (
                    <Space>
                        <StatCard
                            title={t('OFFER_ANALYTICS_GROSS_MARGIN')}
                            value={
                                stats?.grossMarging
                                    ? '¥' + formatNb(stats?.grossMarging)
                                    : '-'
                            }
                        />
                    </Space>
                )}
            </Space>

            <Title style={title} level={4}>
                {t('MENU_ORDERS')}
            </Title>
            <Space>
                <StatCard
                    title={t('OFFER_ANALYTICS_REORDER_RATE')}
                    value={
                        stats?.totalOrders &&
                        analytics?.data?.ghostStats?.orderNumber
                            ? noInfinite(
                                  (stats?.totalOrders /
                                      analytics?.data?.ghostStats
                                          ?.orderNumber) *
                                      100
                              ) + '%'
                            : '-'
                    }
                    subtitle={null}
                />
                <StatCard
                    title={t('ANALYTICS_CUSTOMER_SATISFACTION_RATE')}
                    value={
                        analytics?.data?.claims && stats?.totalOrders
                            ? noInfinite(
                                  100 -
                                      (100 * analytics?.data?.claims) /
                                          stats?.totalOrders
                              ) + '%'
                            : '100%'
                    }
                    subtitle={
                        analytics?.data?.ghostStats?.claims &&
                        analytics?.data?.ghostStats?.orderNumber
                            ? t('ANALYTICS_LAST_TIME') +
                              noInfinite(
                                  100 -
                                      (100 *
                                          analytics?.data?.ghostStats?.claims) /
                                          analytics?.data?.ghostStats
                                              ?.orderNumber
                              ) +
                              '%'
                            : null
                    }
                />
                <StatCard
                    title={t('ANALYTICS_TOTAL_ORDERS')}
                    value={formatNb(stats?.totalOrders)}
                    subtitle={
                        analytics?.data?.ghostStats?.orderNumber
                            ? t('ANALYTICS_LAST_TIME') +
                              analytics?.data?.ghostStats?.orderNumber
                            : null
                    }
                />
                <StatCard
                    title={t('OFFER_ANALYTICS_NEW_CUSTOMERS')}
                    value={formatNb(stats?.newCustomers)}
                    subtitle={
                        analytics?.data?.ghostStats?.newCustomerCount
                            ? t('ANALYTICS_LAST_TIME') +
                              analytics?.data?.ghostStats?.newCustomerCount
                            : null
                    }
                />
                <StatCard
                    title={t('ANALYTICS_TOTAL_CLAIMS')}
                    value={analytics?.data?.claims}
                    subtitle={
                        analytics?.data?.ghostStats?.claims
                            ? t('ANALYTICS_LAST_TIME') +
                              analytics?.data?.ghostStats?.claims
                            : null
                    }
                />
            </Space>

            <Title style={title} level={4}>
                {t('OFFER_ANALYTICS_COMPARISON')}
            </Title>
            <div
                className="statsstats"
                style={{
                    width: '100%',
                    display: 'grid',
                    gap: 16,
                    gridAutoRows: 'minmax(auto, auto)',
                    alignItems: 'baseline',

                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}
            >
                {offer?.channel === 'all' ? (
                    <Card
                        title={t('ANALYTICS_SALES_CHANNEL_RATIO')}
                        style={{ width: '100%' }}
                    >
                        {offer && (
                            <Pie
                                style={{ height: 230 }}
                                {...configPie}
                                tooltip={false}
                                legend={{
                                    offsetX: -55,
                                    offsetY: -35,
                                    itemName: {
                                        formatter: (text, item, index) =>
                                            `${text}  |  ${noInfinite(
                                                (stats?.channelRatio[index]
                                                    ?.value *
                                                    100) /
                                                    stats?.total
                                            )}%  ${noInfinite(
                                                stats?.channelRatio[index]
                                                    ?.value
                                            )}¥`,
                                    },
                                }}
                                data={stats?.channelRatio}
                            />
                        )}
                    </Card>
                ) : null}
                <Card
                    title={t('OFFER_ANALYTICS_SALES_PER_CITY')}
                    style={{ width: '100%' }}
                >
                    {offer && (
                        <Pie
                            style={{ height: 230 }}
                            {...configPie}
                            tooltip={false}
                            legend={{
                                offsetX: -55,
                                offsetY: -35,
                                itemName: {
                                    formatter: (text, item, index) =>
                                        `${text}  |  ${noInfinite(
                                            (stats?.citiesData[index]?.value *
                                                100) /
                                                stats?.total
                                        )}%  ${noInfinite(
                                            stats?.citiesData[index]?.value
                                        )}¥`,
                                },
                            }}
                            data={stats?.citiesData}
                        />
                    )}
                </Card>
                {offer?.channel === 'all' ||
                offer?.channel === 'wechat_group' ? (
                    <Card
                        title={t('OFFER_ANALYTICS_SALES_PER_GROUP') + ' #'}
                        style={{ width: '100%' }}
                    >
                        {offer && stats?.groupValue?.length ? (
                            <BidirectionalBar
                                color={['#1890FF', '#EB2F96']}
                                style={{ height: 230 }}
                                data={stats?.groupValue}
                                xField="group"
                                xAxis={{ position: 'bottom' }}
                                yField={[
                                    t('OFFER_ANALYTICS_VOLUME'),
                                    t('OFFER_ANALYTICS_VALUE'),
                                ]}
                                tooltip={{
                                    formatter: (datum) => ({
                                        name: datum['series-field-key'],
                                        value: datum.Volume
                                            ? formatNb(datum.Volume)
                                            : formatNb(datum.Value),
                                    }),
                                }}
                            />
                        ) : null}
                    </Card>
                ) : null}
            </div>

            <Title style={title} level={4}>
                {t('DIRECT_SALES_PRODUCTS')}
            </Title>
            <div
                className="statsstats"
                style={{
                    width: '100%',
                    display: 'grid',
                    gap: 16,
                    gridAutoRows: 'minmax(auto, auto)',
                    alignItems: 'baseline',

                    gridTemplateColumns: 'repeat(4, 1fr)',
                }}
            >
                <Table
                    pagination={false}
                    style={{ width: '100%' }}
                    className="list-stats"
                    dataSource={stats?.products}
                    columns={[
                        {
                            title: t('OFFER_ANALYTICS_ITEM'),
                            dataIndex: 'shortName',
                            key: 'shortName',
                        },
                        {
                            title: t('OFFER_ANALYTICS_DESC_GROUPS'),
                            dataIndex: 'descWX',
                            key: 'descWX',
                        },
                        {
                            title: t('OFFER_ANALYTICS_DESC_MP'),
                            dataIndex: 'descMP',
                            key: 'descMP',
                        },
                    ]}
                />
                <Space direction="vertical">
                    {offer?.channel === 'all' ||
                    offer?.channel === 'wechat_group' ? (
                        <Card
                            title={`${t(
                                'OFFER_ANALYTICS_PRODUCTS_REVENUE'
                            )} (${t('wechat_group')})`}
                            style={{ width: '100%' }}
                        >
                            {offer && (
                                <Column
                                    style={{ height: 230 }}
                                    {...config}
                                    yField="amount"
                                    seriesField="name"
                                    data={stats?.packsDataGroup}
                                    tooltip={{
                                        formatter: (datum) => ({
                                            name: datum.name,
                                            value: formatNb(datum.amount),
                                        }),
                                    }}
                                />
                            )}
                        </Card>
                    ) : null}
                    {offer?.channel === 'all' ||
                    offer?.channel === 'miniprogram' ? (
                        <Card
                            title={`${t(
                                'OFFER_ANALYTICS_PRODUCTS_REVENUE'
                            )} (${t('miniprogram')})`}
                            style={{ width: '100%' }}
                        >
                            {offer && (
                                <Column
                                    style={{ height: 230 }}
                                    {...config}
                                    yField="amount"
                                    seriesField="name"
                                    data={stats?.packsDataMP}
                                    tooltip={{
                                        formatter: (datum) => ({
                                            name: datum.name,
                                            value: formatNb(datum.amount),
                                        }),
                                    }}
                                />
                            )}
                        </Card>
                    ) : null}
                </Space>
                <Space direction="vertical">
                    {offer?.channel === 'all' ||
                    offer?.channel === 'wechat_group' ? (
                        <Card
                            title={`${t(
                                'OFFER_ANALYTICS_PRODUCTS_VOLUME'
                            )} (${t('wechat_group')})`}
                            style={{ width: '100%' }}
                        >
                            {offer && stats?.valueDataGroup?.length && (
                                <BidirectionalBar
                                    color={['#1890FF', '#EB2F96']}
                                    style={{ height: 230 }}
                                    data={stats?.valueDataGroup}
                                    xField="shortName"
                                    xAxis={{ position: 'bottom' }}
                                    yField={[
                                        t('OFFER_ANALYTICS_VOLUME'),
                                        t('OFFER_ANALYTICS_VALUE'),
                                    ]}
                                    tooltip={{
                                        formatter: (datum) => ({
                                            name: datum['series-field-key'],
                                            value: datum.Volume
                                                ? formatNb(datum.Volume)
                                                : formatNb(datum.Value),
                                        }),
                                    }}
                                />
                            )}
                        </Card>
                    ) : null}
                    {offer?.channel === 'all' ||
                    offer?.channel === 'miniprogram' ? (
                        <Card
                            title={`${t(
                                'OFFER_ANALYTICS_PRODUCTS_VOLUME'
                            )} (${t('miniprogram')})`}
                            style={{ width: '100%' }}
                        >
                            {offer && stats?.valueDataMP?.length && (
                                <BidirectionalBar
                                    color={['#1890FF', '#EB2F96']}
                                    style={{ height: 230 }}
                                    data={stats?.valueDataMP}
                                    xField="shortName"
                                    xAxis={{ position: 'bottom' }}
                                    yField={[
                                        t('OFFER_ANALYTICS_VOLUME'),
                                        t('OFFER_ANALYTICS_VALUE'),
                                    ]}
                                    tooltip={{
                                        formatter: (datum) => ({
                                            name: datum['series-field-key'],
                                            value: datum.Volume
                                                ? formatNb(datum.Volume)
                                                : formatNb(datum.Value),
                                        }),
                                    }}
                                />
                            )}
                        </Card>
                    ) : null}
                </Space>

                {offer?.channel === 'all' ? (
                    <Card
                        title={t(
                            'OFFER_ANALYTICS_PRODUCTS_SALES_CHANNEL_RATIO'
                        )}
                        style={{ width: '100%' }}
                    >
                        {offer && (
                            <Column
                                style={{ height: 230 }}
                                {...config}
                                yField="amount"
                                seriesField="name"
                                data={stats?.ratioData}
                                tooltip={{
                                    formatter: (datum) => ({
                                        name: datum.name,
                                        value: formatNb(datum.amount),
                                    }),
                                }}
                            />
                        )}
                    </Card>
                ) : null}
            </div>
        </Space>
    )
}

export default Analytics

const generateCSV = (stats, offer, analytics, cellarId) => {
    let channelRatio = stats.channelRatio.map((rat) => [
        rat.type,
        formatNb(rat.value),
    ])

    let citiesData = stats.citiesData.map((rat) => [
        rat.type,
        formatNb(rat.value),
    ])

    let groupValue = []
    for (const group of stats.groupValue) {
        const id = groupValue.findIndex((e) => e[0] === group.group)

        if (id > -1) {
            groupValue[id] = [
                group.group,
                formatNb(group.Value + groupValue[id][1]),
                formatNb(group.Volume + groupValue[id][2]),
            ]
        } else {
            groupValue.push([group.group, formatNb(group.Value), group.Volume])
        }
    }

    let packsDataMP = []
    for (const pack of stats.packsDataMP) {
        const id = packsDataMP.findIndex((e) => e[0] === pack.shortName)
        if (id === -1) {
            if (pack.name === 'Actual') {
                packsDataMP.push([pack.shortName, '', formatNb(pack.amount)])
            } else {
                packsDataMP.push([pack.shortName, formatNb(pack.amount), ''])
            }
        } else {
            if (pack.name === 'Actual') {
                packsDataMP[id][2] = formatNb(pack.amount)
            } else {
                packsDataMP[id][1] = formatNb(pack.amount)
            }
        }
    }
    let packsDataGroup = []
    for (const pack of stats.packsDataGroup) {
        const id = packsDataGroup.findIndex((e) => e[0] === pack.shortName)
        if (id === -1) {
            if (pack.name === 'Actual') {
                packsDataGroup.push([pack.shortName, '', formatNb(pack.amount)])
            } else {
                packsDataGroup.push([pack.shortName, formatNb(pack.amount), ''])
            }
        } else {
            if (pack.name === 'Actual') {
                packsDataGroup[id][2] = formatNb(pack.amount)
            } else {
                packsDataGroup[id][1] = formatNb(pack.amount)
            }
        }
    }

    let products = stats.products.map((rat) => [
        rat.shortName,
        rat.descMP,
        rat.descWX,
    ])

    let newRatio = []
    for (const rat of stats?.ratioData) {
        const idR = newRatio.findIndex((e) => e.shortName === rat.shortName)

        if (idR > -1) {
            if (rat.name === 'Group') {
                newRatio[idR].group = rat.amount || 0
            } else {
                newRatio[idR].mp = rat.amount || 0
            }
        } else {
            newRatio.push(rat)
            if (rat.name === 'Group') {
                newRatio[newRatio.length - 1].group = rat.amount || 0
            } else {
                newRatio[newRatio.length - 1].mp = rat.amount || 0
            }
        }
    }

    downloadCSV(
        ['sales'],
        [
            [
                'Total Sales',
                'Total Sales (W/o Delivery)',
                'Average Spending',
                'Total Zikoland Service Fee',
                'Total Supplier Share',
                offer?.community === cellarId ? 'Gross Marging' : '',
            ],
            [
                stats?.total ? '¥' + formatNb(stats?.total) : '-',
                stats?.totalWoDelivery
                    ? '¥' + formatNb(stats?.totalWoDelivery)
                    : '-',
                stats?.averageSpend ? '¥' + formatNb(stats?.averageSpend) : '-',
                stats?.totalWoDelivery && offer?.commissionRate
                    ? '¥' +
                      formatNb(
                          stats?.totalWoDelivery * (offer?.commissionRate / 100)
                      )
                    : '-',
                stats?.totalWoDelivery && offer?.commissionRate
                    ? '¥' +
                      formatNb(
                          stats?.total -
                              stats?.totalWoDelivery *
                                  (offer?.commissionRate / 100)
                      )
                    : '-',
                offer?.community === cellarId
                    ? stats?.grossMarging
                        ? formatNb(stats.grossMarging)
                        : '-'
                    : '',
            ],
            [],
            ['Orders'],
            [
                'Reorder Rate',
                'Customer Satisfaction',
                'Total Orders',
                'New Customers',
                'Total Claims',
            ],
            [
                stats?.totalOrders && analytics.ghostStats?.orderNumber
                    ? noInfinite(
                          (stats?.totalOrders /
                              analytics?.ghostStats?.orderNumber) *
                              100
                      ) + '%'
                    : '-',
                analytics?.claims && stats?.totalOrders
                    ? noInfinite(
                          100 - (100 * analytics?.claims) / stats?.totalOrders
                      ) + '%'
                    : '-',
                formatNb(stats?.totalOrders),
                formatNb(stats?.newCustomers),
                analytics?.claims,
            ],
            [],
            ['Comparison'],
            ['Sales Channel Ratio'],
            ['Channel', 'Amount'],
            ...channelRatio,
            [],
            ['PRODUCT_LIST_SALES_PER_CITY'],
            ['City', 'Amount'],
            ...citiesData,
            [],
            ['Sales per Group N'],
            ['Group N', 'Value', 'Volume'],
            ...groupValue,
            [],
            ['Comparison'],
            ['Item', 'desc MP', 'desc WX'],
            ...products,
            [],
            ['Products Revenue (Miniprogram)'],
            ['Pack/Item', 'Potential', 'Actual'],
            ...packsDataMP,
            [],
            ['Products Revenue (Group)'],
            ['Pack/Item', 'Potential', 'Actual'],
            ...packsDataGroup,
            [],
            ['Products Volume/Value'],
            ['Pack/Item', 'Volume', 'Value'],
            ...(offer?.wechatGroup?.packs.map((pack) => [
                pack.shortName,
                pack.stock,
                pack.stock * pack.price,
            ]) || []),
            ...(offer?.wechatGroup?.items.map((pack) => [
                pack.shortName,
                pack.stock,
                pack.stock * pack.price,
            ]) || []),
            ...(offer?.miniprogram?.packs.map((pack) => [
                pack.shortName,
                pack.stock,
                pack.stock * pack.price,
            ]) || []),
            ...(offer?.miniprogram?.items.map((pack) => [
                pack.shortName,
                pack.stock,
                pack.stock * pack.price,
            ]) || []),
            [''],
            ['Products Sales Channel Ratio'],
            ['Pack/Item', 'Group', 'MP'],
            // ...stats?.ratioData?.map((e) => [
            //     e.shortName,
            //     e.name === 'Group' ? e.amount || '' : '',
            //     e.name !== 'Group' ? e.amount || '' : '',
            // ]),
            ...newRatio.map((e) => [e.shortName, e.group || '', e.mp || '']),
        ],
        'Analytics-' + offer?.offerID
    )
}
