import { useContext, useState, useEffect, useRef } from 'react'
import {
    Form,
    Input,
    Button,
    Select,
    Upload,
    DatePicker,
    TimePicker,
    Radio,
    Checkbox,
    Tag,
    Space,
    Cascader,
    InputNumber,
    AutoComplete,
    message,
} from 'antd'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import BraftEditor from 'braft-editor'
import { useQuery, useMutation } from 'react-query'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import { SERVER, getDeliveryAreas } from '../../network/API'
import { PostImage } from '../../network/Recipes'

import { AppContext } from '../../context/AppContext'
import { numberToLetter } from '../../utils/utils'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const { TextArea } = Input

// const upperCaseFirstChar = (str) =>
//     str
//         ? str?.charAt(0).toUpperCase() + str?.slice(1)
//         : (Math.random() + 1).toString(36).substring(7)

const FormField = (props) => {
    if (props.field.isConditional) {
        return (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    JSON.stringify(prevValues) !== JSON.stringify(currentValues)
                }
            >
                {({ getFieldValue }) => {
                    // let parentValue
                    let parentValue, firstEle

                    if (props.field.staticParentKey) {
                        parentValue = getFieldValue([
                            ...props.blockKey,
                            ...props.field.staticParentKey,
                        ])
                    } else if (
                        props.field.getPrevFirstElement == true &&
                        props.field.parentKey
                    ) {
                        parentValue = getFieldValue([
                            ...props.prev,
                            ...props.field.parentKey,
                        ])
                        firstEle = props.prev[0]
                    } else if (props.field.getPrevFirstElement == true) {
                        parentValue = props.prev[0]
                    } else {
                        parentValue = getFieldValue([
                            ...props.prev,
                            ...props.field.parentKey,
                        ])
                    }

                    return props.field.displayCondition(
                        parentValue,
                        firstEle
                    ) ? (
                        <FormFieldSub {...props} form={props.form} />
                    ) : null
                }}
            </Form.Item>
        )
    } else {
        return <FormFieldSub {...props} form={props.form} />
    }
}

const FormFieldSub = ({
    field,
    dataRecieved,
    sectionKey,
    blockKey = [],
    name,
    fieldKey,
    form,
    labelNb,
    channel,
    ...restField
}) => {
    const { token } = useContext(AppContext)
    const [searchValue, setSearchValue] = useState('')
    const { t } = useTranslation()
    const inputRef = useRef(null)
    const dynamicOptionConfig = [
        [
            // format naming to find request in react query
            `get${field.key?.length ? field.key[0] : sectionKey}Select`,
            {
                token: token.token,
                category: field.category,
                filter: field.conditionalFilter
                    ? field.conditionalFilter(form)
                    : {},
            },
        ],
        field.requestOption,
    ]

    useEffect(() => {
        if (inputRef?.current && field.autoFocus) {
            inputRef.current.focus({
                cursor: 'start',
            })
        }
    }, [])

    const dynamicSearchConfig = [
        [
            // format naming to find request in react query
            `get${field.key?.length ? field.key[0] : sectionKey}Search`,
            {
                [field.key]: searchValue,
                token: token.token,
            },
        ],
        field.requestSearch,
        {
            onSuccess: (data) => (data.length ? dataRecieved(data[0]) : null),
        },
    ]

    const requestProps =
        field.type === 'search'
            ? dynamicSearchConfig
            : field.requestOption !== undefined
            ? dynamicOptionConfig
            : ['', () => {}, { enabled: false }]

    const options = useQuery(...requestProps)
    const isSearchValid = options.data && options.data.length ? '' : 'error'

    const formProps = {
        name:
            name !== undefined
                ? [...blockKey, name, ...field.key]
                : [...blockKey, ...field.key],
        fieldKey:
            fieldKey !== undefined ? [fieldKey, ...field.key] : [...field.key],
        label:
            t(field.label) +
            (field.suffixLabel ? ` (${field.suffixLabel})` : '') +
            (labelNb ? labelNb : ''),
        rules: [
            {
                required: field.isRequired,
                message: t('field_required'),
                // type: field.ruleType,
            },
        ],
    }

    switch (field.type) {
        case 'richtext':
            return (
                <Wysiwyg
                    {...restField}
                    {...formProps}
                    form={form}
                    field={field}
                />
            )
        case 'select':
            return (
                <Form.Item {...formProps}>
                    <Select
                        ref={inputRef}
                        showSearch
                        filterOption={(input, option) => {
                            const label = option.children

                            if (typeof label === 'string') {
                                return (
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                )
                            } else {
                                return (
                                    label.props.children[0]
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                )
                            }
                        }}
                        defaultValue={field.default}
                        loading={options.isLoading}
                        disabled={field.isInactive}
                        onChange={(e) => {
                            if (!field.onChange) {
                                return null
                            }

                            return field.onChange(
                                form,
                                formProps.name,
                                e,
                                options.data?.list.find((o) => o.id === e)
                            )
                        }}
                    >
                        {field.options?.map((option) => (
                            <Option key={option.key} value={option.key}>
                                {t(option.label)}
                            </Option>
                        ))}
                        {options.data?.list.map((option) => (
                            <Option key={option.id} value={option.id}>
                                {field.renderOption
                                    ? field.renderOption(
                                          option,
                                          i18next.language,
                                          t
                                      )
                                    : t(option.name[i18next.language]) ||
                                      t(option.name)}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        case 'basicRadio':
            return (
                <Form.Item {...restField} {...formProps}>
                    <Radio.Group
                        initialValues={field.default}
                        defaultValue={field.default}
                        disabled={field.isInactive}
                        className="radio-group"
                    >
                        {field.options?.map((option) => (
                            <Radio key={option.key} value={option.key}>
                                {t(option.label)}
                            </Radio>
                        ))}
                        {options.data?.list.map((option) => (
                            <Radio key={option.id} value={option.id}>
                                {t(option.name[i18next.language]) ||
                                    t(option.name)}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
            )
        case 'radio':
            return (
                <Form.Item {...restField} {...formProps}>
                    <Radio.Group
                        initialValues={field.default}
                        defaultValue={field.default}
                        buttonStyle="solid"
                        disabled={field.isInactive}
                        className="radio-group"
                    >
                        {field.options?.map((option) => (
                            <Radio.Button key={option.key} value={option.key}>
                                {t(option.label)}
                            </Radio.Button>
                        ))}
                        {options.data?.list.map((option) => (
                            <Radio.Button key={option.id} value={option.id}>
                                {t(option.name[i18next.language]) ||
                                    t(option.name)}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
            )
        case 'upload':
        case 'multiupload':
            return (
                <UploadFile
                    {...restField}
                    {...formProps}
                    form={form}
                    field={field}
                    token={token.token}
                />
            )
        case 'date':
            return (
                <Form.Item {...restField} {...formProps}>
                    <DatePicker
                        disabled={field.isInactive}
                        disabledDate={
                            field.disabledDate
                                ? (date) => field.disabledDate(date, form)
                                : null
                        }
                        defaultValue={field.default}
                        format={'YYYY-MM-DD'}
                        allowClear={field.allowClear == false ? false : true}
                    />
                </Form.Item>
            )
        case 'time':
            return (
                <Form.Item {...restField} {...formProps}>
                    <TimePicker
                        disabled={field.isInactive}
                        disabledHours={
                            field.disabledHours
                                ? () => field.disabledHours(form)
                                : null
                        }
                        disabledMinutes={
                            field.disabledMinutes
                                ? (h) => field.disabledMinutes(h, form)
                                : null
                        }
                        minuteStep={5}
                        format={'HH:mm'}
                    />
                </Form.Item>
            )
        case 'daterange':
            return (
                <Form.Item {...restField} {...formProps}>
                    <RangePicker disabled={field.isInactive} />
                </Form.Item>
            )
        case 'multidate':
            return (
                <MultiDate
                    itemProps={{
                        ...restField,
                        ...formProps,
                    }}
                    field={field}
                    form={form}
                />
            )
        case 'area':
            return (
                <AreaLoader
                    itemProps={{
                        ...restField,
                        ...formProps,
                    }}
                    field={field}
                    form={form}
                />
            )
        case 'text':
            return (
                <Form.Item {...restField} {...formProps}>
                    <TextArea
                        rows={4}
                        disabled={field.isInactive}
                        autoSize={{ minRows: 4, maxRows: 6 }}
                    />
                </Form.Item>
            )
        case 'packList':
        case 'allPackList':
        case 'itemList':
            return (
                <PackList
                    options={options}
                    field={field}
                    form={form}
                    blockKey={blockKey}
                    channel={channel}
                    {...restField}
                    {...formProps}
                />
            )
        case 'multicheck':
            return (
                <MultiCheck
                    options={options}
                    field={field}
                    {...restField}
                    {...formProps}
                />
            )
        case 'number':
            return (
                <Form.Item {...restField} {...formProps}>
                    <InputNumber
                        ref={inputRef}
                        min={field.min || 0}
                        max={field.max}
                        formatter={(e) => {
                            let num

                            if (field.isInt) {
                                num = parseInt(e)
                            } else {
                                num = parseFloat(e)
                            }
                            if (isNaN(num)) {
                                return ''
                            }
                            return num
                        }}
                        onChange={(e) => {
                            if (!field.onChange) {
                                return null
                            }

                            return field.onChange(form, formProps.name, e)
                        }}
                        placeholder={field.placeholder}
                        disabled={field.isInactive}
                        defaultValue={field.default}
                    />
                </Form.Item>
            )
        case 'password':
            return (
                <Form.Item {...restField} {...formProps}>
                    <Input.Password
                        disabled={field.isInactive}
                        placeholder="••••••••••••••"
                    />
                </Form.Item>
            )
        case 'search':
            return (
                <Form.Item
                    validateStatus={
                        options.isLoading ? 'validating' : isSearchValid
                    }
                    {...restField}
                    {...formProps}
                >
                    <Input
                        prefix={field.pre}
                        disabled={field.isInactive}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </Form.Item>
            )
        case 'search&update':
            return (
                <SearchAndUpdate
                    itemProps={{
                        ...restField,
                        ...formProps,
                    }}
                    field={field}
                    form={form}
                />
            )
        case 'select&update':
            return (
                <SelectAndUpdate
                    channel={channel}
                    itemProps={{
                        ...restField,
                        ...formProps,
                    }}
                    field={field}
                    form={form}
                />
            )
        case 'hidden':
            return <Form.Item {...restField} {...formProps} />
        case 'checkbox':
            return (
                <Form.Item {...restField} {...formProps}>
                    <Checkbox disabled={field.isInactive} />
                </Form.Item>
            )
        default:
            return (
                <Form.Item {...restField} {...formProps}>
                    <Input
                        ref={inputRef}
                        prefix={field.pre}
                        placeholder={field.placeholder}
                        disabled={field.isInactive}
                        defaultValue={field.default}
                    />
                </Form.Item>
            )
    }
}

const MultiCheck = ({ field, options, ...restProps }) => {
    const { t } = useTranslation()

    return (
        <Form.Item {...restProps}>
            <Checkbox.Group disabled={field.isInactive} className="check-group">
                <Space>
                    {options.isLoading && <LoadingOutlined />}
                    {field.options?.map((option) => (
                        <Checkbox
                            key={option.key}
                            value={option.key}
                            style={{ lineHeight: '32px' }}
                        >
                            {t(option.label)}
                        </Checkbox>
                    ))}
                    {options.data?.list?.map((option) => (
                        <Checkbox
                            key={option.id}
                            value={option.id}
                            style={{ lineHeight: '32px' }}
                        >
                            {t(option.name[i18next.language]) || t(option.name)}
                        </Checkbox>
                    ))}
                </Space>
            </Checkbox.Group>
        </Form.Item>
    )
}

const MultiDate = ({ itemProps, field, form }) => {
    const [selectedDate, setSelectedDate] = useState(null)

    function onChange(date, dateString) {
        if (selectedDate.findIndex((e) => e === dateString) > -1) {
            message.warning('Date already selected!')
            return
        }
        const tempArray = [...selectedDate]
        tempArray.push(dateString)
        setSelectedDate(tempArray)
        form.setFieldsValue({ [itemProps.fieldKey]: tempArray })
    }

    function getValueFromEvent() {
        return selectedDate
    }

    function handleClose(value) {
        const tempArray = [...selectedDate]
        const elemtentIndex = tempArray.findIndex((e) => e === value)
        if (elemtentIndex > -1) tempArray.splice(elemtentIndex, 1)
        setSelectedDate(tempArray)
        form.setFieldsValue({ [itemProps.fieldKey]: tempArray })
    }

    return (
        <>
            <DatePicker
                onChange={onChange}
                value={null}
                disabled={field.isInactive}
            />
            <Form.Item
                {...itemProps}
                getValueFromEvent={getValueFromEvent}
                getValueProps={(e) => {
                    if (!selectedDate) {
                        if (e) {
                            let tempDate = e.map((date) =>
                                moment(date).format('YYYY-MM-DD')
                            )
                            setSelectedDate(tempDate)
                            form.setFieldsValue({
                                [itemProps.fieldKey]: tempDate,
                            })
                        } else {
                            setSelectedDate([])
                        }
                    }
                }}
                shouldUpdate={(prevValues, currentValues) =>
                    JSON.stringify(prevValues) !== JSON.stringify(currentValues)
                }
            >
                <Space direction="vertical">
                    <div className="multi-date-tags-wrap">
                        {selectedDate?.map((date, key) => (
                            <div className="multi-date-tags-item">
                                <Tag
                                    key={key}
                                    color={
                                        field.isInactive ? 'default' : 'blue'
                                    }
                                    closable={!field.isInactive}
                                    onClose={(e) => {
                                        e.preventDefault()
                                        handleClose(date)
                                    }}
                                >
                                    {date}
                                </Tag>
                            </div>
                        ))}
                    </div>
                    {/* <p>{selectedDate.join()}</p> */}
                </Space>
            </Form.Item>
        </>
    )
}

const SearchAndUpdate = ({ itemProps, field, form }) => {
    const { token } = useContext(AppContext)
    const [text, setText] = useState('')

    const results = useQuery(
        [
            `getFrom${field.key}`,
            {
                filter: { [field.searchKey]: { $regex: text, $options: 'i' } },
                token: token.token,
                range: [0, 4],
                sort: ['name', 'ASC'],
            },
        ],
        field.options,
        {
            onSuccess: (res) => {
                if (
                    res.list.length === 1 &&
                    res.list[0][field.searchKey]?.toLowerCase() ===
                        text?.toLowerCase()
                ) {
                    form.setFieldsValue(field.formatOnRecieved(res.list[0]))
                }
            },
            enabled: text !== '',
        }
    )

    const getValue = (res, str) => {
        return str.split('.').reduce((o, d) => o[d], res)
    }

    return (
        <Form.Item
            {...itemProps}
            hasFeedback
            validateStatus={results.isLoading}
        >
            <AutoComplete
                disabled={field.isInactive}
                onChange={(e) => {
                    setText(e)
                    if (field.OnType) {
                        form.setFieldsValue(field.OnType)
                    }
                }}
                onSelect={(e) => {
                    const value = JSON.parse(e)
                    setText(value[field.searchKey])
                    let values = field.formatOnRecieved(value)
                    form.setFieldsValue(values)
                }}
            >
                {results?.data?.list?.map((res) => (
                    <Option key={res.id} value={JSON.stringify(res)}>
                        {/* {res[field.searchKey]} */}
                        {getValue(res, field.searchKey)}
                    </Option>
                ))}
            </AutoComplete>
        </Form.Item>
    )
}

const SelectAndUpdate = ({ itemProps, field, form, channel }) => {
    const { t } = useTranslation()
    const { token } = useContext(AppContext)

    const options = useQuery(
        [`get${field.key.join('')}Select`, { token: token.token }],
        field.options
    )

    const mutation = useMutation(
        (value) =>
            field.update({
                queryKey: [
                    null,
                    { [field.updateKey]: value, token: token.token },
                ],
            }),
        {
            onSuccess: (data, variables, context) => {
                form.setFieldsValue(
                    field.formatOnRecieved(
                        data,
                        itemProps.fieldKey,
                        form,
                        channel,
                        itemProps.prev
                    )
                )
            },
            onError: () => message.error('An error occurs.'),
        }
    )

    return (
        <Form.Item {...itemProps}>
            <Select
                showSearch
                filterOption={(input, option) => {
                    const label = option.children

                    if (typeof label === 'string') {
                        return (
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        )
                    } else {
                        return (
                            label.props.children[0]
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        )
                    }
                }}
                loading={options.isLoading || mutation.isLoading}
                disabled={field.isInactive}
                onSelect={(e) => mutation.mutate(e)}
            >
                {options.data?.list.map((option) => (
                    <Option key={option.id} value={option.id}>
                        {field.renderOption
                            ? field.renderOption(option, i18next.language, t)
                            : t(option.name[i18next.language]) ||
                              t(option.name)}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    )
}

const Wysiwyg = ({ field, form, ...restField }) => {
    const { token } = useContext(AppContext)
    const value = BraftEditor.createEditorState(form?.getFieldValue(field.key))
    const getEditorContent = (e) => {
        return e.toHTML()
    }

    const controls = [
        'bold',
        'italic',
        'separator',
        'link',
        'separator',
        'list-ul',
        'list-ol',
        'separator',
        'blockquote',
        'media',
        'separator',
        'headings',
        'separator',
        'code',
    ]

    return (
        <Form.Item getValueFromEvent={getEditorContent} {...restField}>
            <BraftEditor
                defaultValue={value}
                controls={controls}
                language={i18next.language}
                media={{
                    uploadFn: (params) => myUploadFn(params, token.token),
                }}
                imageControls={[
                    'align-left',
                    'align-center',
                    'align-right',
                    'link',
                    'size',
                    'remove',
                ]}
            />
        </Form.Item>
    )
}

const UploadFile = ({ field, form, token, prev = [], ...restField }) => {
    const { t } = useTranslation()

    const uploadProps = {
        name: 'file',
        action: `${SERVER.baseURL}${field.requestURI}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const getFileList = () => {
        let savedPicture = form?.getFieldValue([...prev, ...field.key])

        savedPicture = savedPicture?.fileList
            ? savedPicture.fileList
            : savedPicture

        if (savedPicture) {
            if (!Array.isArray(savedPicture)) {
                savedPicture = [savedPicture]
            }

            return savedPicture.map((pic, i) => ({
                uid: '-' + (i + 1),
                name: pic.name,
                status: 'done',
                // uri: pic.uri,
                thumbUrl: `${SERVER.baseURL}${field.storageURI}/${pic.uri}`,
                response: pic,
            }))
        }

        return []
    }

    return (
        <Form.Item
            {...restField}
            valuePropName={field.key}
            // getValueFromEvent={normFile}
        >
            <Upload
                {...uploadProps}
                multiple={field.type === 'multiupload'}
                maxCount={field.type === 'multiupload' ? 6 : 1}
                listType="picture"
                disabled={field.isInactive}
                defaultFileList={getFileList()}
            >
                <Button disabled={field.isInactive} icon={<UploadOutlined />}>
                    {t('CREATE_OFFER_UPLOAD')}
                </Button>
            </Upload>
        </Form.Item>
    )
}

const AreaLoader = ({ itemProps, field, form }) => {
    const { token } = useContext(AppContext)
    const inputRef = useRef(null)
    const { t } = useTranslation()
    const [options, setOptions] = useState([])

    const areas = useQuery(
        ['getDeliveryAreas', { token: token.token }],
        getDeliveryAreas,
        {
            onSuccess: (data) => {
                setOptions(getAreas(undefined, data.list))
            },
        }
    )

    useEffect(() => {
        if (inputRef?.current && field.autoFocus) {
            inputRef.current.focus({
                cursor: 'start',
            })
        }
    }, [])

    const displayRender = (labels, selectedOptions) =>
        labels.length && options.length ? (
            <span>{t(labels[labels.length - 1])}</span>
        ) : null

    const getAreas = (parentId, allAreas) => {
        let newAreas = []
        const filteredAreas = allAreas.filter((e) => e.parent === parentId)

        if (filteredAreas.length) {
            for (const line of filteredAreas) {
                let childs = getAreas(line.id, allAreas)

                newAreas.push({
                    value: line.id,
                    label: line.name,
                    children: childs.length ? childs : undefined,
                })
            }
        }

        return newAreas
    }

    return (
        <Form.Item {...itemProps}>
            <Cascader
                ref={inputRef}
                disabled={field.isInactive}
                options={options}
                displayRender={displayRender}
                changeOnSelect
            />
        </Form.Item>
    )
}

const PackList = ({ field, options, form, channel, ...restProps }) => {
    const packs = form.getFieldValue([...channel, 'packs'])
    const items = form.getFieldValue([...channel, 'items'])

    if (field.type === 'packList') {
        return (
            <Form.Item
                {...restProps}
                shouldUpdate={(prevValues, currentValues) =>
                    JSON.stringify(prevValues) !== JSON.stringify(currentValues)
                }
            >
                <Select disabled={field.isInactive}>
                    {packs?.map((pack, i) => {
                        const newShort = numberToLetter(i)

                        return (
                            <Option key={newShort} value={newShort}>
                                {newShort}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>
        )
    }

    if (field.type === 'allPackList') {
        return (
            <Form.Item
                {...restProps}
                shouldUpdate={(prevValues, currentValues) =>
                    JSON.stringify(prevValues) !== JSON.stringify(currentValues)
                }
            >
                <Select disabled={field.isInactive}>
                    {packs?.map((pack, i) => {
                        const newShort = numberToLetter(i)

                        return (
                            <Option key={newShort} value={newShort}>
                                {newShort}
                            </Option>
                        )
                    })}
                    {items?.map((item, i) => {
                        const newShort = numberToLetter(
                            i + (packs?.length || 0)
                        )

                        return (
                            <Option key={newShort} value={newShort}>
                                {newShort}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>
        )
    }

    return (
        <Form.Item
            {...restProps}
            shouldUpdate={(prevValues, currentValues) =>
                JSON.stringify(prevValues) !== JSON.stringify(currentValues)
            }
        >
            <Select disabled={field.isInactive}>
                {items?.map((item, i) => {
                    const newShort = numberToLetter(i + (packs?.length || 0))

                    return (
                        <Option key={newShort} value={newShort}>
                            {newShort}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
}

export default FormField

const myUploadFn = (param, token) => {
    const fd = new FormData()
    fd.append('file', param.file)

    PostImage(fd, token)
        .then((response) => {
            param.success({
                url: `${SERVER.baseURL}/storage/recipe-description/${response.uri}`,
                meta: {
                    id: response.hash,
                    title: response.name,
                    alt: response.name,
                    loop: true, // 指定音视频是否循环播放
                    autoPlay: true, // 指定音视频是否自动播放
                    controls: true, // 指定音视频是否显示控制栏
                    poster: `${SERVER.baseURL}/storage/recipe-description/${response.uri}`, // 指定视频播放器的封面
                },
            })
        })
        .catch((response) => {
            param.error({
                msg: 'unable to upload.',
            })
        })
}
