import { Controller } from 'react-hook-form'
import { Input, Form, Card } from 'antd'
import UploadField from './Upload'

const Description = ({ control, channel, isEdit }) => {
    return (
        <Card bordered={false} style={{ flex: 1 }} title="Description">
            <div style={{ display: 'flex', gap: 8 }}>
                <Form.Item label="Public name (English)" style={{ flex: 1 }}>
                    <Controller
                        name="name.en"
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item label="Public name (中文)" style={{ flex: 1 }}>
                    <Controller
                        name="name.zh"
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item label="Ghost ID" style={{ flex: 1 }}>
                    <Controller
                        name="ghostID"
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                disabled={isEdit}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <div style={{ display: 'flex', gap: 8 }}>
                <Form.Item label="Description (English)" style={{ flex: 1 }}>
                    <Controller
                        name="description.en"
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <Input.TextArea
                                value={value}
                                onChange={onChange}
                                autoSize={{ minRows: 3, maxRows: 3 }}
                            />
                        )}
                    />
                </Form.Item>
                <Form.Item label="Description (中文)" style={{ flex: 1 }}>
                    <Controller
                        name="description.zh"
                        control={control}
                        // defaultValue=""
                        render={({ value, onChange }) => (
                            <Input.TextArea
                                value={value}
                                onChange={onChange}
                                autoSize={{ minRows: 3, maxRows: 3 }}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            {channel !== 'wechatGroup' && (
                <>
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Form.Item
                            label="Card offer images (English)"
                            style={{ flex: 1 }}
                        >
                            <Controller
                                name="banners.en"
                                control={control}
                                // defaultValue={[]}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        // name="banners.en"
                                        value={value}
                                        onChange={onChange}
                                        storage="offer-banner"
                                        multiple
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Card offer images (Chinese)"
                            style={{ flex: 1 }}
                        >
                            <Controller
                                name="banners.zh"
                                control={control}
                                // defaultValue={[]}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="offer-banner"
                                        multiple
                                    />
                                )}
                            />
                        </Form.Item>
                    </div>

                    <div style={{ display: 'flex', gap: 8 }}>
                        <Form.Item
                            label="Offer page images (English)"
                            style={{ flex: 1 }}
                        >
                            <Controller
                                name="medias.en"
                                control={control}
                                // defaultValue={[]}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="offer-media"
                                        multiple
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Offer page images (Chinese)"
                            style={{ flex: 1 }}
                        >
                            <Controller
                                name="medias.zh"
                                control={control}
                                // defaultValue={[]}
                                render={({ value, onChange }) => (
                                    <UploadField
                                        value={value}
                                        onChange={onChange}
                                        storage="offer-media"
                                        multiple
                                    />
                                )}
                            />
                        </Form.Item>
                    </div>
                </>
            )}
        </Card>
    )
}

export default Description
