/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Space, Badge } from 'antd'

import { formatNb, keepTwoDecimals } from '../utils/utils'
import { getPurchaseOrders } from '../network/PurchaseOrders'
import { getSuppliers } from '../network/Suppliers'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'

const Overviews = ({ history }) => (
    <PageWrap title="MENU_FINANCE">
        <TableBlock
            columns={columns}
            filters={filters}
            name={'getPurchaseOrders'}
            request={getPurchaseOrders}
            pageSize={80}
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push({
                            pathname:
                                record.type === 'purchase_order'
                                    ? `/finance/${record.id}`
                                    : 'services',
                            defaultTab: 'invoices',
                            supplier: record.supplier.id,
                            previousHash: window.location.hash,
                        })
                    }
                }
            }}
        />
    </PageWrap>
)

const filters = (goTo) => ({
    fields: [
        {
            type: 'select',
            label: 'SUPPLIERS_SUPPLIER_NAME',
            default: '',
            key: 'supplier',
            options: getSuppliers,
        },
        {
            type: 'text',
            label: ['SERVICES_INVOICE_ID', 'PO ID'],
            key: 'poId',
        },
    ],
})

const columns = (goTo) => [
    {
        title: <DynamicStrings id="SERVICES_INVOICE_ID" />,
        dataIndex: 'poId',
        key: 'poId',
        sorter: true,
    },
    {
        title: <DynamicStrings id="SUPPLIERS_TYPE" />,
        dataIndex: 'type',
        render: (type) => <DynamicStrings id={type} />,
        key: 'type',
        sorter: true,
    },
    {
        title: <DynamicStrings id="SUPPLIERS_SUPPLIER_NAME" />,
        dataIndex: 'supplier',
        render: (supplier) => supplier?.name,
        key: 'supplier',
        sorter: false,
    },
    {
        title: (
            <DynamicStrings
                id="FINANCE_GENERAL_PAYMENT_COLLECTED_SUPPLIER"
                suffix="(¥)"
            />
        ),
        // dataIndex: 'purchaseOrderItems',
        render: (po) =>
            // po.type === 'purchase_order' ? '-' : formatNb(po.totalSales),
            po.type === 'purchase_order' ? '-' : keepTwoDecimals(po.totalSales),
        key: 'totalPrice',
        sorter: false,
    },
    {
        title: <DynamicStrings id="FINANCE_GENERAL_PURCHASE_INCL_VAT" />,
        // dataIndex: 'purchaseOrderItems',
        render: (po) => {
            if (po.type === 'invoice') {
                return '-'
            }

            var totalPrice = 0

            po.purchaseOrderItems?.forEach((items, i) => {
                const qty = items.quantity || 0
                const unitPrice = items.unitPrice

                const totalWT = qty * unitPrice

                totalPrice += totalWT
            })

            return keepTwoDecimals(totalPrice)
        },
        key: 'totalPriceInclVAT',
        sorter: false,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        key: 'payment_status',
        render: (e) => {
            let status = { color: 'default', text: 'unpaid' }

            if (e.payment_status === 'paid') {
                status = { color: 'success', text: 'paid' }
            }

            return (
                <Badge
                    status={status.color}
                    text={<DynamicStrings id={status.text} />}
                />
            )
        },
    },
    // {
    //     key: 'action',
    //     width: 1,
    //     render: (e) => {
    //         return (
    //             <Space size="small" className="btnToTheEnd">
    //                 <Button
    //                     onClick={() =>
    //                         goTo({
    //                             pathname:
    //                                 e.type === 'purchase_order'
    //                                     ? `/finance/${e.id}`
    //                                     : 'services',
    //                             defaultTab: 'invoices',
    //                             supplier: e.supplier.id,
    //                             previousHash: window.location.hash,
    //                         })
    //                     }
    //                     type="primary"
    //                 >
    //                     <DynamicStrings id="FINANCE_GENERAL_SEE_DETAIL" />
    //                 </Button>
    //             </Space>
    //         )
    //     },
    // },
]

export default Overviews
