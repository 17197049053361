import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Form } from 'antd'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import DeleteButton from '../../components/DeleteButton'

import {
    AreaDescription,
    cleanBeforeSendingArea,
} from '../../constants/forms/Delivery'
import {
    EditDeliveryAreas,
    getSingleDeliveryArea,
    DeleteArea,
} from '../../network/API'

const AreaDetails = () => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()
    let { areaId } = useParams()

    const areaDetails = useQuery(
        [
            'getSingleArea',
            {
                areaId: areaId,
                token: token.token,
            },
        ],
        getSingleDeliveryArea,
        {
            refetchOnWindowFocus: false,
        }
    )

    const formatData = (oldData) => {
        let newParent = []
        let cities = { ...oldData }
        while (cities) {
            if (cities.parent) {
                newParent.push(cities.parent.id)
            }
            cities = cities.parent
        }
        newParent.reverse()

        return { ...oldData, parent: newParent }
    }

    return (
        <PageWrap
            goBack
            isLoading={areaDetails.isLoading}
            title={areaDetails.data?.name}
            // actionOptions={[
            //     <DeleteButton
            //         onSuccess="/delivery"
            //         request={DeleteArea}
            //         id={areaId}
            //         invalidate={['getSingleArea']}
            //     />,
            // ]}
        >
            {areaDetails.isError ? (
                <Error retry={() => areaDetails.refetch()} />
            ) : areaDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    title="create_user"
                    request={EditDeliveryAreas}
                    // request={() => {}}
                    onSuccess="/delivery"
                    onCancel="/delivery"
                    id={areaId}
                    cleanBeforeSending={cleanBeforeSendingArea}
                    data={formatData(areaDetails.data)}
                    invalidate={['getDeliveryAreas', 'getSingleArea']}
                >
                    <div className="SectionHorizontalWrap">
                        <FormBlock form={form} formTemplate={AreaDescription} />
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default AreaDetails
