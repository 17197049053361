import { useContext } from 'react'
import { Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import ziko from '../../images/ZIKOLAND4.png'

import { AppContext } from '../../context/AppContext'

import { SERVER } from '../../network/API'
import { CustomerDetails } from '../../network/Customers'
import { defaultCommunities } from '../../constants/forms/Customers'

import CustomersInformations from './CustomersInformation'
import CustomersOrders from './CustomersOrders'
import CustomersVouchers from './CustomersVouchers'
import CustomersClaims from './CustomersClaims'
import CustomersAnalytics from './CustomersAnalytics'
import Loader from '../../components/Loader'
import PageWrap from '../../components/layout/PageWrap'

const { TabPane } = Tabs

const CustomersDetail = ({ history }) => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    let { customerId } = useParams()

    function callback(key) {
        // console.log(key)
    }

    const formatData = (oldData) => {
        let newData = { ...oldData }
        newData.addresses = newData.addresses.map((address) => {
            let newArea = []
            let areas = { ...address.area }

            while (areas) {
                if (areas.parent) {
                    newArea.push(areas.id)
                }
                areas = areas.parent
            }
            newArea.reverse()

            return { ...address, area: newArea }
        })

        newData.wechatGroups = defaultCommunities.map((community) => {
            let exist = newData.wechatGroups.find(
                (e) => e.community.id === community
            )

            return {
                community,
                groupNumber: exist ? exist.groupNumber : undefined,
            }
        })

        newData.addresses = oldData.addresses?.map((address) => {
            let newCities = []
            let cities = { ...address.area }
            while (cities) {
                newCities.push(cities.id)
                cities = cities.parent
            }

            newCities.reverse()
            return { ...address, area: newCities }
        })

        return newData
    }

    const customerDetails = useQuery(
        [
            'CustomerDetails',
            {
                userId: customerId,
                token: token.token,
            },
        ],
        CustomerDetails
    )

    return (
        <PageWrap
            avatar={
                customerDetails.data?.profilePicture?.uri
                    ? `${SERVER.baseURL}/storage/customer-picture/${customerDetails.data?.profilePicture?.uri}`
                    : ziko
            }
            isLoading={customerDetails.isLoading}
            title={customerDetails.data?.name}
            isFlag={customerDetails.data?.flagged}
        >
            <Tabs
                defaultActiveKey={
                    history?.location?.defaultTab || 'information'
                }
                onChange={callback}
                className="customerDetailTab"
                style={{
                    backgroundColor: '#fff',
                    padding: '0px',
                }}
            >
                <TabPane tab={t('CUSTOMERS_INFORMATION')} key="information">
                    {customerDetails.isLoading ? (
                        <Loader />
                    ) : (
                        <CustomersInformations
                            customer={formatData(customerDetails.data)}
                        />
                    )}
                </TabPane>
                <TabPane tab={t('MENU_ORDERS')} key="orders">
                    <CustomersOrders
                        userId={customerId}
                        user={customerDetails.data}
                    />
                </TabPane>
                <TabPane tab={t('CUSTOMERS_VOUCHERS')} key="vouchers">
                    <CustomersVouchers
                        userId={customerId}
                        user={customerDetails.data}
                        history={history}
                    />
                </TabPane>

                <TabPane tab={t('MENU_CLAIMS')} key="claims">
                    <CustomersClaims userId={customerId} />
                </TabPane>

                <TabPane tab={t('MENU_ANALYTICS')} key="analytics">
                    <CustomersAnalytics
                        userId={customerId}
                        createdAt={customerDetails.data?.createdAt}
                    />
                </TabPane>
            </Tabs>
        </PageWrap>
    )
}

export default CustomersDetail
