import { useContext, useState, useEffect } from 'react'
import { Form, Button, message, Space, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Prompt, useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { AppContext } from '../../context/AppContext'
import { useMutation } from 'react-query'

import DynamicStrings from '../DynamicStrings'
import { useTranslation } from 'react-i18next'

const FormWrap = ({
    title,
    children,
    request,
    data,
    id,
    onSuccess,
    onCancel,
    invalidate,
    form,
    checkValidity,
    onValuesChange,
    cleanBeforeSending,
    beforeOnSuccess,
    success = 'SUCCESSFULLY_SAVED',
    isDeleted = false,
}) => {
    const [defaultForm] = Form.useForm()
    const queryClient = useQueryClient()
    const formIntance = form ? form : defaultForm
    let history = useHistory()
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const [isPrompt, setIsPrompt] = useState(false)

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const newOnValuesChange = (e) => {
        if (onValuesChange) {
            onValuesChange(e)
        }
        setIsPrompt(true)
    }

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };

    const mutation = useMutation((values) => request(...values), {
        onSuccess: (data, variables, context) => {
            message.success(t(success))
            if (invalidate) {
                for (const key of invalidate) {
                    queryClient.invalidateQueries(key)
                }
            }

            if (beforeOnSuccess) {
                beforeOnSuccess(data)
            }

            if (onSuccess) {
                setIsPrompt(false)
                history.push(onSuccess)
            }
        },
        onError: (error, variables, context) => {
            message.error(error?.response?.data?.message || 'An error occurs')
        },
    })

    const onFinish = (values) => {
        let newVals = JSON.parse(JSON.stringify(values))

        if (cleanBeforeSending) {
            newVals = cleanBeforeSending(newVals)
        }

        if (checkValidity) {
            let isNotOkay = checkValidity(newVals)
            if (typeof isNotOkay === 'string') {
                message.error(t(isNotOkay))
                return
            } else if (typeof isNotOkay === 'object') {
                return
            }
        }

        mutation.mutate([newVals, token.token, id])
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    // const onReset = () => {
    //     formIntance.resetFields()
    // }

    const onCancelClick = () => {
        if (onCancel) {
            history.push(onCancel)
        } else {
            formIntance.resetFields()
        }
    }

    return (
        <div className="form-page">
            <Form
                preserve
                onValuesChange={newOnValuesChange}
                form={formIntance}
                name={title}
                layout="vertical"
                autoComplete="off"
                scrollToFirstError
                onFinish={onFinish}
                initialValues={data}
                onFinishFailed={onFinishFailed}
                style={{ width: '100%', backgroundColor: '#f0f2f5', padding: 24 }}
                requiredMark={false}
            >
                {children}
                <Form.Item>
                    <Space
                        style={{
                            display: 'flex',
                            marginTop: 24,
                        }}
                        align="baseline"
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={mutation.isLoading}
                        >
                            <DynamicStrings
                                id={mutation.isLoading ? 'Sending' : 'ROLES_SAVE'}
                            />
                        </Button>
                        <Button
                            // onClick={onReset}
                            onClick={onCancelClick}
                            htmlType="button"
                            disabled={mutation.isLoading}
                        >
                            <DynamicStrings id="ROLES_CANCEL" />
                        </Button>
                    </Space>
                </Form.Item>
                <Prompt
                    when={isDeleted ? false : isPrompt}
                    message={(location) => {
                        Modal.confirm({
                            icon: <ExclamationCircleOutlined />,
                            okText: t('CONFIRM'),
                            cancelText: t('CANCEL'),
                            content: t('LEAVE_PAGE'),
                            onOk() {
                                setIsPrompt(false);
                                setTimeout(() => {
                                    history.push(location);
                                });
                            },
                        });
                        return false;
                    }}
                />
            </Form>
        </div>
    )
}

export default FormWrap
