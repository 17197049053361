import { useContext } from 'react'
import { Button, Badge } from 'antd'

import { formatNb } from '../utils/utils'
import { getSuppliersDetailed } from '../network/Suppliers'
import { AppContext } from '../context/AppContext'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'

const Suppliers = ({ history }) => {
    return (
        <PageWrap title="MENU_SUPPLIERS">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getSuppliers'}
                request={getSuppliersDetailed}
                pageSize={80}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push(`/suppliers/${record.id}`)
                        },
                    };
                }}
            />
        </PageWrap>
    )
}

const filters = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'ROLES_NAME',
            key: 'name',
        },
        {
            type: 'select',
            label: 'OFFERS_STATUS',
            default: '',
            key: 'status',
            options: [
                { key: 'available', label: 'available' },
                { key: 'discontinued', label: 'discontinued' },
                { key: 'ongoing', label: 'ongoing' },
            ],
        },
    ],
    actions: [
        // {
        //     label: 'SUPPLIERS_CREATE_SUPPLIER',
        //     isActive: true,
        //     action: () => history.push('/suppliers/create'),
        // },
        {
            label: 'SUPPLIERS_CREATE_SUPPLIER',
            component: () => <BtnCreate history={history} />,
        },
    ],
})

const BtnCreate = ({ history }) => {
    const { user } = useContext(AppContext)

    if (user.role === 'customer-hero' || user.role === 'community-manager') {
        return null
    }

    return (
        <Button
            type="primary"
            onClick={() => history.push('/suppliers/create')}
        >
            <DynamicStrings id={'SUPPLIERS_CREATE_SUPPLIER'} />
        </Button>
    )
}

const columns = (goTo) => [
    {
        title: <DynamicStrings id="MENU_SUPPLIERS" />,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: <DynamicStrings id="SUPPLIERS_TYPE" />,
        dataIndex: 'type',
        key: 'type',
        render: (e) => <DynamicStrings id={e} />,
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_PHONE" />,
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: <DynamicStrings id="MENU_CLAIMS" />,
        dataIndex: 'claimCount',
        render: (claims) => formatNb(claims) || 0,
        key: 'claimCount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="MENU_CLAIMS" suffix="%" />,
        dataIndex: '',
        render: (orders) =>
            (!orders.claimCount || !orders.orderCount
                ? 0
                : formatNb((orders.claimCount / orders.orderCount) * 100)) +
            '%',
        key: 'claimsPercent',
    },
    {
        title: <DynamicStrings id="MENU_OFFERS" />,
        dataIndex: 'offerCount',
        render: (offers) => formatNb(offers),
        key: 'offerCount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="MENU_PRODUCTS" />,
        dataIndex: 'productCount',
        render: (prod) => formatNb(prod),
        key: 'productCount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        dataIndex: 'status',
        render: (e) => {
            const color = {
                discontinued: 'error',
                available: 'success',
                ongoing: 'warning',
            }

            return <Badge status={color[e]} text={<DynamicStrings id={e} />} />
        },
        key: 'status',
        sorter: true,
    },
    // {
    //     title: '',
    //     key: 'action',
    //     width: 1,
    //     render: (e) => (
    //         <Button onClick={() => goTo(`/suppliers/${e.id}`)} type="primary">
    //             <DynamicStrings id="ROLES_EDIT" />
    //         </Button>
    //     ),
    // },
]

export default Suppliers
