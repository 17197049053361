import { Button, Space, Avatar, Badge } from 'antd'
import moment from 'moment'

import { getCustomers } from '../network/Customers'
import { SERVER } from '../network/API'
import ziko from '../images/ZIKOLAND4.png'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'

import { formatNb } from '../utils/utils'

const Customers = ({ history }) => {
    return (
        <PageWrap title="MENU_CUSTOMERS">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getCustomers'}
                request={getCustomers}
                pageSize={80}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push(`/customers/${record.id}`)
                        },
                    };
                }}
            />
        </PageWrap>
    )
}

const filters = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'ROLES_NAME',
            key: 'name',
        },
        {
            type: 'text',
            label: 'DIRECT_SALES_PHONE_NUMBER',
            key: 'phone',
        },
    ],
    actions: [
        {
            label: 'CUSTOMERS_CREATE_CUSTOMER',
            isActive: true,
            action: () => history.push('/customers/create'),
        },
    ],
})

const columns = (goTo) => [
    {
        render: (e) => (
            <Avatar
                size="small"
                src={
                    e?.profilePicture?.uri
                        ? `${SERVER.baseURL}/storage/customer-picture/${e?.profilePicture?.uri}`
                        : ziko
                }
            />
        ),
        width: 45,
    },
    {
        title: <DynamicStrings id="ROLES_NAME" />,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ellipsis: true,
        onCell: () => {
            return {
                onClick: (e) => e.stopPropagation(),
            }
        },
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_PHONE" />,
        dataIndex: 'addresses',
        render: (addresses) => (addresses?.length ? addresses[0].phone : null),
        // dataIndex: 'contacts',
        // render: (contacts) => (contacts?.length ? contacts[0].phone : null),
        key: 'phone',
    },
    {
        title: <DynamicStrings id="CUSTOMERS_ADDRESS" />,
        dataIndex: 'addresses',
        render: (addresses) =>
            addresses?.length
                ? `${addresses[0].detailedAddress}. ${
                      addresses[0].zipCode || ''
                  } ${addresses[0].city}`
                : null,
        key: 'address',
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="MENU_ORDERS" />,
        dataIndex: 'ordersCount',
        key: 'ordersCount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_ANALYTICS_TOTAL_SALES" />,
        dataIndex: 'ordersTotalSales',
        key: 'ordersTotalSales',
        render: (e) => formatNb(e),
        sorter: true,
    },
    {
        title: <DynamicStrings id="MENU_CLAIMS" />,
        dataIndex: 'claimCount',
        key: 'claimCount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_VOUCHER" />,
        dataIndex: 'voucherCount',
        key: 'voucherCount',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMERS_LAST_ORDER" />,
        dataIndex: 'lastOrderDate',
        key: 'lastOrderDate',
        render: (date) =>
            date ? (
                moment(date).fromNow()
            ) : (
                <DynamicStrings id="CUSTOMERS_NEVER_ORDERED" />
            ),
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        dataIndex: 'status',
        render: (e) => {
            const color = {
                discontinued: 'error',
                available: 'success',
            }

            return <Badge status={color[e]} text={<DynamicStrings id={e} />} />
        },
        key: 'status',
        sorter: true,
    },
    {
        title: '',
        key: 'action',
        width: 170,
        render: (e) => {
            return (
                <Space size="small" className="btnToTheEnd">
                    <Button
                        onClick={(event) => {
                            event.stopPropagation()
                            goTo({
                                pathname: `/customers/${e.id}`,
                                defaultTab: 'analytics',
                            })
                        }}
                    >
                        <DynamicStrings id="MENU_ANALYTICS" />
                    </Button>
                    {/* <Button
                        onClick={() => goTo(`/customers/${e.id}`)}
                        type="primary"
                    >
                        <DynamicStrings id="ROLES_EDIT" />
                    </Button> */}
                </Space>
            )
        },
    },
]

export default Customers
