import { Tabs } from 'antd'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Button, Form } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../context/AppContext'

import PageWrap from '../../components/layout/PageWrap'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import DeleteButton from '../../components/DeleteButton'

import { EditOffer, DeleteOffer, getSingleOffer } from '../../network/Offers'
import {
    Settings,
    Description,
    ClipboardForm,
    AddSelectionForm,
    AddSingleItem,
    ZokiSpecials,
    Lottery,
    OfferDetails as Details,
    BourseRule,
    checkValidity,
} from '../../constants/forms/OffersEdit'
import {
    Settings as UnlockedSettings,
    Description as UnlockedDescription,
    ClipboardForm as UnlockedClipboardForm,
    AddSelectionForm as UnlockedAddSelectionForm,
    AddSingleItem as UnlockedAddSingleItem,
    ZokiSpecials as UnlockedZokiSpecials,
    Lottery as UnlockedLottery,
    OfferDetails as UnlockedDetailsOfferDetails,
    BourseRule as UnlockedBourseRule,
    // checkValidity as UnlockedcheckValidity,
} from '../../constants/forms/Offers'
import { cleanBeforeSending } from '../../constants/forms/Offers'

const { TabPane } = Tabs

const OfferDetails = ({ history }) => {
    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [tabKey, setTabKey] = useState('wechat_group')
    const [channel, setChannel] = useState(null)
    const [isDeleted, setIsDeleted] = useState(false)

    let { offerId } = useParams()

    const handleChannel = (chan) => {
        if (chan === 'wechat_group' && tabKey === 'miniprogram') {
            setTabKey('wechat_group')
        } else if (chan === 'miniprogram' && tabKey === 'wechat_group') {
            setTabKey('miniprogram')
        }
        setChannel(chan)
    }

    const formatData = (oldData) => {
        // let oldData = { ...oldDatas }
        const times = (n) => (f) => {
            let iter = (i) => {
                if (i === n) return
                f(i)
                iter(i + 1)
            }
            return iter(0)
        }

        let clipboard = []
        times(10)((i) => {
            clipboard.push(
                i < oldData.clipboard.length
                    ? oldData.clipboard[i]
                    : { shortcut: undefined, text: undefined }
            )
        })

        oldData.clipboard = clipboard

        // Format date to moment() for datePickers
        if (oldData.startingDate) {
            oldData.startingDate = moment(oldData.startingDate)
            oldData.startingTime = moment(oldData.startingDate)
        }

        if (oldData.endingDate) {
            oldData.endingDate = moment(oldData.endingDate)
            oldData.endingTime = moment(oldData.endingDate)
        }

        let newWx = { ...oldData.wechatGroup }
        if (oldData.wechatGroup) {
            for (
                let drawsIndex = 0;
                drawsIndex < oldData.wechatGroup.lottery?.draws?.length;
                drawsIndex++
            ) {
                for (
                    let giftsIndex = 0;
                    giftsIndex <
                    oldData.wechatGroup.lottery.draws[drawsIndex].gifts?.length;
                    giftsIndex++
                ) {
                    const currentValue =
                        oldData.wechatGroup.lottery.draws[drawsIndex].gifts[
                            giftsIndex
                        ]

                    if (currentValue.voucherExpiration) {
                        newWx.lottery.draws[drawsIndex].gifts[
                            giftsIndex
                        ].voucherExpiration = moment(
                            currentValue.voucherExpiration
                        )
                    }
                }
            }

            newWx.packs = newWx.packs?.map((pack) => ({
                ...pack,
                products: pack.products.map((product) => ({
                    ...product,
                    product: product.product.id,
                })),
            }))

            newWx.items = newWx.items?.map((item) => ({
                ...item,
                product: item.product.id,
            }))

            newWx.zikoSpecials = newWx?.zikoSpecials?.map((special) => ({
                ...special,
                gift: {
                    ...special.gift,
                    voucherExpiration: special?.gift?.voucherExpiration
                        ? moment(special.gift.voucherExpiration)
                        : null,
                },
            }))
        }

        let newMP = { ...oldData.miniprogram }
        if (oldData.miniprogram) {
            for (
                let drawsIndex = 0;
                drawsIndex < oldData.miniprogram.lottery?.draws?.length;
                drawsIndex++
            ) {
                for (
                    let giftsIndex = 0;
                    giftsIndex <
                    oldData.miniprogram.lottery.draws[drawsIndex].gifts?.length;
                    giftsIndex++
                ) {
                    const currentValue =
                        oldData.miniprogram.lottery.draws[drawsIndex].gifts[
                            giftsIndex
                        ]

                    if (currentValue.voucherExpiration) {
                        newMP.lottery.draws[drawsIndex].gifts[
                            giftsIndex
                        ].voucherExpiration = moment(
                            currentValue.voucherExpiration
                        )
                    }
                }
            }

            newMP.packs = newMP.packs?.map((pack) => ({
                ...pack,
                products: pack.products.map((product) => ({
                    ...product,
                    product: product.product.id,
                })),
            }))

            newMP.items = newMP.items?.map((item) => ({
                ...item,
                product: item.product.id,
            }))

            newMP.zikoSpecials = newMP?.zikoSpecials?.map((special) => ({
                ...special,
                gift: {
                    ...special.gift,
                    voucherExpiration: special?.gift?.voucherExpiration
                        ? moment(special.gift.voucherExpiration)
                        : null,
                },
            }))
        }

        // let newFees = { ...oldData.fees }
        // newFees = oldData.fees?.map((fee) => {
        //     let newArea = []
        //     let areas = { ...fee.area }

        //     while (areas) {
        //         if (areas.parent) {
        //             newArea.push(areas.id)
        //         }
        //         areas = areas.parent
        //     }
        //     newArea.reverse()

        //     return { ...fee, area: newArea }
        // })

        let newFees = oldData.fees?.map((fee) => {
            let newCities = []
            let cities = { ...fee.area }
            while (cities) {
                newCities.push(cities.id)
                cities = cities.parent
            }

            newCities.reverse()
            return { ...fee, area: newCities }
        })

        return {
            ...oldData,
            miniprogram: newMP,
            wechatGroup: newWx,
            fees: newFees,
        }
    }

    const offerDetails = useQuery(
        [
            'getSingleOffer',
            {
                offerId: offerId,
                token: token.token,
            },
        ],
        getSingleOffer,
        {
            onSuccess: (data) => {
                if (data.channel) {
                    handleChannel(data.channel)
                }
            },
            refetchOnWindowFocus: false,
        }
    )

    const canDelete =
        !offerDetails.isLoading &&
        new Date(offerDetails.data?.startingDate) > new Date()

    const getDeleteResult = (result) => {
        setIsDeleted(result)
    }

    return (
        <PageWrap
            goBack
            isLoading={offerDetails.isLoading}
            title={offerDetails.data?.internalName}
            actionOptions={[
                <DeleteButton
                    onSuccess="/offers"
                    request={DeleteOffer}
                    disabled={!canDelete}
                    id={offerId}
                    invalidate={['getOffers', 'getOffersServices']}
                    isDeleted={getDeleteResult}
                />,
                <Button
                    onClick={() => {
                        history.push({
                            pathname: '/offers/create',
                            defaultValue: {
                                ...formatData(offerDetails.data),
                                ghostID: offerDetails.data.offerID,
                                offerID: undefined,
                                startingDate: undefined,
                                endingDate: undefined,
                            },
                        })
                    }}
                    type="primary"
                    disabled={offerDetails.isLoading}
                >
                    {t('CREATE_OFFER_DUPLICATE_OFFER')}
                </Button>,
            ]}
        >
            {offerDetails.isError ? (
                <Error retry={() => offerDetails.refetch()} />
            ) : offerDetails.isLoading ? (
                <Loader />
            ) : (
                <FormWrap
                    title="create_offer"
                    request={EditOffer}
                    form={form}
                    id={offerId}
                    onSuccess="/offers"
                    onCancel="/offers"
                    cleanBeforeSending={cleanBeforeSending}
                    data={formatData(offerDetails.data)}
                    onValuesChange={(e) => {
                        if (e.channel) {
                            handleChannel(e.channel)
                        }
                    }}
                    invalidate={[
                        'getSingleOffer',
                        'getOffers',
                        'getOffersServices',
                    ]}
                    checkValidity={checkValidity}
                    isDeleted={isDeleted}
                >
                    <div className="SectionVerticalWrap">
                        <div className="SectionHorizontalWrap">
                            <FormBlock.Basic
                                formTemplate={
                                    canDelete ? UnlockedSettings : Settings
                                }
                            />
                        </div>
                        <div className="SectionHorizontalWrap">
                            <FormBlock
                                form={form}
                                formTemplate={
                                    canDelete
                                        ? UnlockedDescription
                                        : Description
                                }
                            />
                            {offerDetails.data?.channel !== 'miniprogram' && (
                                <FormBlock
                                    formTemplate={
                                        canDelete
                                            ? UnlockedClipboardForm
                                            : ClipboardForm
                                    }
                                />
                            )}
                        </div>
                        <div className="SectionHorizontalWrap">
                            <Tabs
                                activeKey={tabKey}
                                onChange={(key) => setTabKey(key)}
                                className="customerDetailTab"
                                // defaultActiveKey={offerDetails.data?.channel}
                                style={{
                                    backgroundColor: '#fff',
                                    padding: '0px',
                                    flex: 2,
                                }}
                            >
                                <TabPane
                                    tab={t('wechat_group')}
                                    key="wechat_group"
                                    disabled={channel === 'miniprogram'}
                                >
                                    <div
                                        className="SectionVerticalWrap"
                                        style={{
                                            flex: 2,
                                        }}
                                    >
                                        <FormBlock
                                            form={form}
                                            blockKey={['wechatGroup']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedAddSelectionForm
                                                    : AddSelectionForm
                                            }
                                        />
                                        <FormBlock
                                            form={form}
                                            blockKey={['wechatGroup']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedAddSingleItem
                                                    : AddSingleItem
                                            }
                                        />
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(
                                                prevValues,
                                                currentValues
                                            ) =>
                                                JSON.stringify(prevValues) !==
                                                JSON.stringify(currentValues)
                                            }
                                        >
                                            {({ getFieldValue }) =>
                                                getFieldValue('type') ===
                                                'bourse' ? (
                                                    <FormBlock
                                                        blockKey={[
                                                            'wechatGroup',
                                                        ]}
                                                        formTemplate={
                                                            canDelete
                                                                ? UnlockedBourseRule
                                                                : BourseRule
                                                        }
                                                    />
                                                ) : null
                                            }
                                        </Form.Item>
                                        <FormBlock
                                            form={form}
                                            blockKey={['wechatGroup']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedZokiSpecials
                                                    : ZokiSpecials
                                            }
                                        />
                                        <FormBlock
                                            form={form}
                                            blockKey={['wechatGroup']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedLottery
                                                    : Lottery
                                            }
                                        />
                                    </div>
                                </TabPane>
                                <TabPane
                                    tab={t('miniprogram')}
                                    key="miniprogram"
                                    disabled={channel === 'wechat_group'}
                                >
                                    <div
                                        className="SectionVerticalWrap"
                                        style={{
                                            flex: 2,
                                        }}
                                    >
                                        <FormBlock
                                            form={form}
                                            blockKey={['miniprogram']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedAddSelectionForm
                                                    : AddSelectionForm
                                            }
                                        />
                                        <FormBlock
                                            form={form}
                                            blockKey={['miniprogram']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedAddSingleItem
                                                    : AddSingleItem
                                            }
                                        />
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(
                                                prevValues,
                                                currentValues
                                            ) =>
                                                JSON.stringify(prevValues) !==
                                                JSON.stringify(currentValues)
                                            }
                                        >
                                            {({ getFieldValue }) =>
                                                getFieldValue('type') ===
                                                'bourse' ? (
                                                    <FormBlock
                                                        blockKey={[
                                                            'miniprogram',
                                                        ]}
                                                        formTemplate={
                                                            canDelete
                                                                ? UnlockedBourseRule
                                                                : BourseRule
                                                        }
                                                    />
                                                ) : null
                                            }
                                        </Form.Item>
                                        <FormBlock
                                            form={form}
                                            blockKey={['miniprogram']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedZokiSpecials
                                                    : ZokiSpecials
                                            }
                                        />
                                        <FormBlock
                                            form={form}
                                            blockKey={['miniprogram']}
                                            formTemplate={
                                                canDelete
                                                    ? UnlockedLottery
                                                    : Lottery
                                            }
                                        />
                                    </div>
                                </TabPane>
                            </Tabs>
                            <FormBlock
                                form={form}
                                formTemplate={
                                    canDelete
                                        ? UnlockedDetailsOfferDetails
                                        : Details
                                }
                            />
                        </div>
                    </div>
                </FormWrap>
            )}
        </PageWrap>
    )
}

export default OfferDetails
