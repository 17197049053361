import { useContext } from 'react'
import { Space, Menu, Dropdown, Badge } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'src/context/AppContext'
import { useMutation, useQueryClient } from 'react-query'

import TableBlock from '../../components/tables/TableBlock'
import Status from '../../components/tables/cells/Status'
import { formatDate } from '../../utils/utils'
import { CustomerClaims, EditClaims, ClaimNextStep } from '../../network/API'

import DynamicStrings from '../../components/DynamicStrings'

const CustomersClaims = ({ userId }) => {
    return (
        <div style={{ paddingTop: 15, backgroundColor: '#fff' }}>
            <TableBlock
                columns={columns}
                filters={filters}
                name={'CustomerClaims'}
                request={CustomerClaims}
                otherParams={{ userId }}
                pageSize={80}
            />
        </div>
    )
}

const filters = (history) => ({
    fields: [],
    actions: [
        {
            label: 'CUSTOMER_SERVICE_CREATE_CLAIM',
            isActive: true,
            action: () => history.push('/claims/create'),
        },
    ],
})

const columns = (goTo, keyRequest) => [
    {
        title: <DynamicStrings id="ROLES_TEAM_MEMBER" />,
        dataIndex: 'teamMember',
        render: (teamMember) => teamMember?.name,
        key: 'teamMember.name',
        sorter: true,
    },
    {
        title: <DynamicStrings id="ROLES_COMMUNITY" />,
        dataIndex: 'community',
        render: (community) => community?.name ? <DynamicStrings id={community.name} /> : '',
        key: 'community',
        sorter: true,
    },
    {
        title: <DynamicStrings id="claim_date" />,
        dataIndex: 'date',
        render: (date) => formatDate(date),
        key: 'date',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_ORDER_ID" />,
        dataIndex: 'order',
        render: (order) => order?.orderID,
        key: 'orderID',
    },
    {
        title: <DynamicStrings id="supplier" />,
        dataIndex: 'supplier',
        render: (supplier) => supplier?.name,
        key: 'supplier',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_RESPONSIBILITY" />,
        dataIndex: 'resp',
        render: (e) => <DynamicStrings id={e} />,
        key: 'resp',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ISSUE" />,
        dataIndex: 'issue',
        render: (e) => <DynamicStrings id={e} />,
        key: 'issue',
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ISSUE_DETAIL" />,
        dataIndex: 'issueDetail',
        key: 'issueDetail',
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_STEP" />,
        dataIndex: 'step',
        key: 'step',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ACTION" />,
        dataIndex: 'action',
        render: (e) => <DynamicStrings id={e} />,
        key: 'action',
        sorter: true,
    },
    {
        title: <DynamicStrings id="CUSTOMER_SERVICE_ACTION_ETA" />,
        dataIndex: 'actionETA',
        render: (date) => formatDate(date),
        key: 'actionETA',
        sorter: true,
    },
    {
        title: <DynamicStrings id="OFFER_LIVE_COMMENT" />,
        dataIndex: 'comment',
        key: 'comment',
        ellipsis: true,
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        // dataIndex: 'status',
        render: ({ status, id, step }) => (
            <ClaimStatus
                statusProps={{
                    status,
                    requestName: 'getClaims',
                    fieldKey: 'status',
                    keyRequest: keyRequest,
                    editRequest: EditClaims,
                    step,
                    id,
                    config: [
                        { key: 'new', color: 'cyan' },
                        { key: 'ongoing', color: 'yellow' },
                        { key: 'standby', color: 'orange' },
                        { key: 'solved', color: 'green' },
                        { key: 'failed', color: 'red' },
                    ],
                }}
            />
        ),
        key: 'status',
        sorter: true,
    },
    {
        title: '',
        key: 'action',
        width: 50,
        render: (e) => {
            const menu = (
                <Menu>
                    <Menu.Item key="1" disabled={!!e.refundVouchers?.length}>
                        <a
                            onClick={() =>
                                goTo({
                                    pathname: '/vouchers/create',
                                    customer: e.customer,
                                })
                            }
                        >
                            <DynamicStrings id="SERVICES_ORDER_LIST_CREATE_VOUCHER" />
                        </a>
                    </Menu.Item>
                    <Menu.Item key="1">
                        <a onClick={() => goTo(`/claims/${e.id}`)}>
                            <DynamicStrings id="ROLES_EDIT" />
                        </a>
                    </Menu.Item>
                </Menu>
            )
            return (
                <Space className="btnCenter">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                        >
                            <MoreOutlined />
                        </a>
                    </Dropdown>
                </Space>
            )
        },
    },
]

const ClaimStatus = ({ step, statusProps }) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const mutation = useMutation((values) => ClaimNextStep(...values), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(statusProps.keyRequest, (oldData) => {
                let oldDataIndex = oldData.list.findIndex(
                    (e) => e.id === statusProps.id
                )
                oldData.list[oldDataIndex].status = 'failed'
                return oldData
            })
            queryClient.invalidateQueries('getClaims')
            queryClient.invalidateQueries('getClaimsAnalytics')
            queryClient.invalidateQueries('CustomerClaims')
        },
    })

    if (statusProps.status === 'solved')
        return <Badge color={'green'} text={t('solved')} />
    if (statusProps.status === 'failed')
        return <Badge color={'red'} text={t('failed')} />

    return (
        <Status
            {...statusProps}
            onChange={(e) => mutation.mutate([statusProps.id, token.token])}
            onChangeKey={statusProps.step === 3 ? '' : 'failed'}
            isLoading={mutation.isLoading}
        />
    )
}

export default CustomersClaims
