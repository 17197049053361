export const defaultValues = {
    internalName: '',
    community: null,
    offerID: null,
    channel: 'all',
    showDetail: 'hide',
    name: {
        en: '',
        zh: '',
    },
    ghostID: '',
    description: {
        en: '',
        zh: '',
    },
    banners: {
        en: [],
        zh: [],
    },
    medias: {
        en: [],
        zh: [],
    },
    clipboard: [
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
        { shortcut: '', text: '' },
    ],
    wechatGroup: {
        lotteryEnable: false,
    },
    miniprogram: {
        lotteryEnable: false,
    },
    startingDate: null,
    endingDate: null,
    type: 'regular',
    targetSales: null,
    commissionRate: null,
    minimumOrderAmount: null,
    minimumCartItems: null,
    deliveryType: 'manual',
    feeTemplate: null,
    deliveryDates: [],
}

export const defaultPacks = {
    price: 5,
}
