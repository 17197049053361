import { Button, Space, Badge, Avatar } from 'antd'

import { getRecipes } from '../network/Recipes'
import { SERVER } from '../network/API'
import ziko from '../images/ZIKOLAND4.png'

import PageWrap from '../components/layout/PageWrap'
import TableBlock from '../components/tables/TableBlock'
import DynamicStrings from '../components/DynamicStrings'

const Recipes = ({ history }) => {
    return (
        <PageWrap title="MENU_RECIPES">
            <TableBlock
                columns={columns}
                filters={filters}
                name={'getRecipes'}
                request={getRecipes}
                pageSize={80}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push({
                                pathname: `/recipes/${record.id}`,
                                previousHash: window.location.hash
                            })
                        },
                    };
                }}
            />
        </PageWrap>
    )
}

const filters = (history) => ({
    fields: [
        {
            type: 'text',
            label: 'RECIPES_RECIPE_NAME',
            key: 'name.en',
        },
        {
            type: 'select',
            label: 'OFFERS_STATUS',
            default: '',
            key: 'status',
            options: [
                { key: 'available', label: 'RECIPES_PUBLISHED' },
                { key: 'discontinued', label: 'RECIPES_NOT_PUBLISHED' },
            ],
        },
    ],
    actions: [
        {
            label: 'RECIPES_CREATE_RECIPE',
            isActive: true,
            action: () => history.push('/recipes/create'),
        },
    ],
})

const columns = (goTo) => [
    {
        title: <DynamicStrings id={'RECIPES_MAIN_PICTURE_EN'}/>,
        width: 1,
        dataIndex: 'mainPicture',
        render: (pictures) =>
            pictures?.en?.uri || pictures?.zh?.uri ? (
                <Avatar
                    shape="square"
                    src={`${SERVER.baseURL}/storage/recipe-cover/${
                        pictures?.en?.uri || pictures?.zh?.uri
                    }`}
                />
            ) : (
                <Avatar shape="square" src={ziko} />
            ),
        key: 'name',
    },
    {
        title: <DynamicStrings id="CREATE_OFFER_ENGLISH_NAME" />,
        dataIndex: 'name',
        key: 'name.en',
        render: ({ en }) => en,
        sorter: true,
    },
    {
        title: <DynamicStrings id="CREATE_OFFER_CHINESE_NAME" />,
        dataIndex: 'name',
        render: ({ zh }) => zh,
        key: 'name.zh',
    },
    {
        title: <DynamicStrings id="OFFERS_STATUS" />,
        dataIndex: 'status',
        render: (e) => {
            const color = {
                discontinued: { color: 'error', text: 'RECIPES_NOT_PUBLISHED' },
                available: { color: 'success', text: 'RECIPES_PUBLISHED' },
            }

            return (
                <Badge
                    status={color[e]?.color || 'default'}
                    text={<DynamicStrings id={color[e]?.text || 'No Status'} />}
                />
            )
        },
        key: 'status',
        sorter: true,
    },
    // {
    //     title: '',
    //     key: 'action',
    //     width: 1,
    //     render: (e) => {
    //         return (
    //             <Space size="middle">
    //                 <Button
    //                     // onClick={() => goTo(`/recipes/${e.id}`)}
    //                     onClick={() => goTo({
    //                         pathname: `/recipes/${e.id}`,
    //                         previousHash: window.location.hash,
    //                     })}
    //                     type="primary"
    //                 >
    //                     <DynamicStrings id="ROLES_EDIT" />
    //                 </Button>
    //             </Space>
    //         )
    //     },
    // },
]

export default Recipes
